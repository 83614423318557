import { useCallback } from 'react';
import { useUserInfo } from './useUserInfo';
import withGateOverride from '../utils/withGateOverride';
import memoizeOne from 'react-utils/memoizeOne';
const EMPTY_GATES = new Set();

// gates don't change over the lifetime of the app. use a memoized helper to
// ensure that all calls to the `useGetIsUngated` hook share one Set,
// instead of each hook call/component building its own
const memoizedToGatesSet = memoizeOne(gates => new Set(gates));
export const useGetIsUngated = () => {
  const userInfo = useUserInfo();
  const userInfoGates = !userInfo ? EMPTY_GATES : memoizedToGatesSet(userInfo.gates);
  return useCallback(gate => {
    return withGateOverride(gate, userInfoGates.has(gate));
  }, [userInfoGates]);
};