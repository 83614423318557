'use es6';

import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { RECEIVE_HAS_ARCHIVED, REQUEST_HAS_ARCHIVED } from 'email-management-lib/refactor/actions/actionTypes';
const initialState = false;
function hasArchived(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_HAS_ARCHIVED:
      if (action.error !== true) {
        return action.payload;
      }
      return initialState;
    default:
      return state;
  }
}
export default asyncResource(hasArchived, {
  requestActions: [REQUEST_HAS_ARCHIVED],
  responseActions: [RECEIVE_HAS_ARCHIVED],
  invalidatingActions: []
});