import { buildPropertyDefinitionWithFilteredOptions, getActiveRules, getParsedPropertyValueAsArray, getValidation, someValueHasNoRule } from '../conditionalPropertyOptionsRules/utils';
export const getActivePropertyDefinitionWithFilteredOptions = ({
  conditionalPropertyOptionsRulesForProperty,
  controllingFieldCurrentValue,
  controllingFieldPropertyDefinition,
  originalPropertyDefinition,
  value
}) => {
  const defaultReturn = {
    propertyDefinition: originalPropertyDefinition,
    error: false,
    validationMessage: null
  };

  // if the controlling property has been deleted, we don't filter options
  if (!controllingFieldPropertyDefinition) {
    return defaultReturn;
  }

  // no CPO logic can be set for these two controlling property values
  if (controllingFieldCurrentValue === undefined || controllingFieldCurrentValue === null) {
    return defaultReturn;
  }

  // starting with an array helps in case of multi-enums
  // we split values for multi-enum so that we can use same logic for single select and multi-enum
  const controllingFieldCurrentValues = getParsedPropertyValueAsArray(controllingFieldPropertyDefinition, controllingFieldCurrentValue);

  // if there is at-least one value with no rule for it, then we should all options - no rule means ALL options in dependent field
  if (someValueHasNoRule(controllingFieldCurrentValues, conditionalPropertyOptionsRulesForProperty)) {
    return defaultReturn;
  }
  const activeConditionalPropertyOptionsRules = getActiveRules(controllingFieldCurrentValues, conditionalPropertyOptionsRulesForProperty);
  const propertyDefinitionForActiveRules = buildPropertyDefinitionWithFilteredOptions(originalPropertyDefinition, activeConditionalPropertyOptionsRules);
  const {
    error,
    validationMessage
  } = getValidation({
    originalPropertyDefinition,
    propertyDefinitionForActiveRules,
    value,
    controllingFieldLabel: controllingFieldPropertyDefinition.label
  });
  return {
    propertyDefinition: propertyDefinitionForActiveRules,
    error,
    validationMessage
  };
};