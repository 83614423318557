import PortalIdParser from 'PortalIdParser';
import { refreshCookie } from 'hub-http/auth';
import Raven from 'raven-js';
import devLogger from 'react-utils/devLogger';
import * as tracker from 'ui-addon-upgrades/_core/common/eventTracking/tracker';
import { getUpgradeDataTrackingProperties } from 'ui-addon-upgrades/ums/utils';
import { getActivationFn, getActivationFnFromApiName } from '../common/constants/productActivation';
export const activateProduct = upgradeData => {
  const {
    upgradeProduct,
    apiName
  } = upgradeData;
  const activationFn = apiName ? getActivationFnFromApiName(apiName) : getActivationFn(upgradeProduct);
  if (!activationFn) {
    Raven.captureException(new Error('Could not find activation function for upgrade product'), {
      extra: {
        upgradeData
      }
    });
    return new Promise(() => {
      throw Error();
    });
  }
  return activationFn().then(() => {
    // ********** PUBLIC EVENT **********
    // Public Events help teams across HubSpot automate work and customize experiences based on user actions.
    // Speak with #product-insight and your PM before any shipping any changes to this event incl. event name, properties, values, and when it occurs.
    // Read more about Public Events on the wiki: https://wiki.hubspotcentral.net/display/PM/Public+Events+-+Amplitude+events+ready+for+HubSpot+team+use+and+automation
    tracker.track('productActivationInteraction', Object.assign({
      action: 'activation succeeded'
    }, getUpgradeDataTrackingProperties(upgradeData)));
  }).then(() => {
    return refreshCookie(PortalIdParser.get(), true);
  }).catch(e => {
    // ********** PUBLIC EVENT **********
    // Public Events help teams across HubSpot automate work and customize experiences based on user actions.
    // Speak with #product-insight and your PM before any shipping any changes to this event incl. event name, properties, values, and when it occurs.
    // Read more about Public Events on the wiki: https://wiki.hubspotcentral.net/display/PM/Public+Events+-+Amplitude+events+ready+for+HubSpot+team+use+and+automation
    tracker.track('productActivationInteraction', Object.assign({
      action: 'activation failed'
    }, getUpgradeDataTrackingProperties(upgradeData)));
    devLogger.warn({
      message: e,
      key: 'ProductActivationButton'
    });
    Raven.captureException(e);
    throw Error();
  });
};