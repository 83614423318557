import { CALYPSO_DARK, CALYPSO_LIGHT, CANDY_APPLE_DARK, CANDY_APPLE_LIGHT, FACEBOOK as FACEBOOK_COLOR, INSTAGRAM as INSTAGRAM_COLOR, LINKEDIN as LINKEDIN_COLOR, LORAX_DARK, LORAX_LIGHT, MARIGOLD_DARK, MARIGOLD_LIGHT, NORMAN_DARK, NORMAN_LIGHT, OZ_DARK, OZ_LIGHT, SORBET_DARK, SORBET_LIGHT, THUNDERDOME_DARK, THUNDERDOME_LIGHT, TWITTER as TWITTER_COLOR, YOUTUBE as YOUTUBE_COLOR } from 'HubStyleTokens/colors';
import { OBJECT_TYPE } from './objectTypes';
export const SIDEPANEL_WIDTH = 500;
export const SIDEBAR_WIDTH = 400;
export const POPOVER_WIDTH = 360;
export const CAROUSEL_IMAGE_WIDTH = 295;
export const CAROUSEL_IMAGE_HEIGHT = 180;

/**
 * Reference of the campaign association name for each of the asset types
 * on the map below.
 * Refer to the Framework Data Explorer to find the correct name for the asset type:
 * https://app.hubspot.com/framework-data-explorer/{PORTAL_ID/{OBJECT_TYPE_ID}/associations
 */
export const CAMPAIGN_ASSOCIATION_NAME = {
  [OBJECT_TYPE.CONTENT]: 'CONTENT_TO_CAMPAIGN',
  [OBJECT_TYPE.LANDING_PAGE]: 'LANDING_PAGE_TO_CAMPAIGN',
  [OBJECT_TYPE.FORM]: 'FORM_TO_CAMPAIGN',
  [OBJECT_TYPE.MARKETING_EMAIL]: 'MARKETING_EMAIL_TO_CAMPAIGN',
  [OBJECT_TYPE.AD_CAMPAIGN]: 'AD_CAMPAIGN_TO_CAMPAIGN',
  [OBJECT_TYPE.BLOG_POST]: 'BLOG_POST_TO_CAMPAIGN',
  [OBJECT_TYPE.SITE_PAGE]: 'SITE_PAGE_TO_CAMPAIGN',
  [OBJECT_TYPE.CTA]: 'CTA_TO_CAMPAIGN',
  [OBJECT_TYPE.WORKFLOW]: 'AUTOMATION_PLATFORM_FLOW_TO_CAMPAIGN',
  [OBJECT_TYPE.SOCIAL_BROADCAST]: 'SOCIAL_BROADCAST_TO_CAMPAIGN',
  [OBJECT_TYPE.WEB_INTERACTIVE]: 'WEB_INTERACTIVE_TO_CAMPAIGN',
  [OBJECT_TYPE.MARKETING_SMS]: 'MARKETING_SMS_TO_CAMPAIGN'
};

/**
 * Asset status
 */
export const STATUS = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  CANCELED: 'CANCELED',
  COMPLETED: 'COMPLETED',
  DEFAULT: 'DEFAULT',
  DELETED: 'DELETED',
  DONE: 'DONE',
  DRAFT: 'DRAFT',
  ENABLED: 'ENABLED',
  ERROR_ARCHIVE: 'ERROR_ARCHIVE',
  ERROR_FATAL: 'ERROR_FATAL',
  FLAGGED_FOR_REVIEW: 'FLAGGED_FOR_REVIEW',
  ONGOING: 'ONGOING',
  PAST: 'PAST',
  PAUSED: 'PAUSED',
  PENDING_REVIEW: 'PENDING_REVIEW',
  PUBLISHED_OR_SCHEDULED: 'PUBLISHED_OR_SCHEDULED',
  PUBLISHED: 'PUBLISHED',
  SCHEDULED: 'SCHEDULED',
  SECURITY_VIOLATION: 'SECURITY_VIOLATION',
  SENT: 'SENT',
  SUCCESS: 'SUCCESS',
  TODO: 'TODO',
  TURNED_OFF: 'TURNED_OFF',
  TURNED_ON: 'TURNED_ON',
  UPCOMING: 'UPCOMING',
  UPLOADED: 'UPLOADED',
  WAITING: 'WAITING',
  PROCESSING: 'PROCESSING',
  PRE_PROCESSING: 'PRE_PROCESSING',
  AUTOMATED: 'AUTOMATED',
  AUTOMATED_DRAFT: 'AUTOMATED_DRAFT',
  PLANNED: 'PLANNED',
  IN_PROGRESS: 'IN_PROGRESS'
};
export const ASSET_ICON = {
  [OBJECT_TYPE.LANDING_PAGE]: 'website',
  [OBJECT_TYPE.FORM]: 'forms',
  [OBJECT_TYPE.MARKETING_EMAIL]: 'email',
  '0-31-facebook': 'socialBlockFacebook',
  '0-31-google': 'socialGoogle',
  '0-32-linkedin': 'socialBlockLinkedin',
  [OBJECT_TYPE.CAMPAIGN]: 'campaigns',
  [OBJECT_TYPE.SITE_PAGE]: 'website',
  [OBJECT_TYPE.BLOG_POST]: 'blog',
  [OBJECT_TYPE.PUBLISHING_TASK]: 'tasks',
  [OBJECT_TYPE.TASK]: 'tasks',
  [OBJECT_TYPE.MARKETING_EVENT]: 'marketingEvents',
  [OBJECT_TYPE.SOCIAL_BROADCAST]: 'social',
  '0-71-facebook': 'socialBlockFacebook',
  '0-71-instagram': 'socialBlockInstagram',
  '0-71-linkedin': 'socialBlockLinkedin',
  '0-71-twitter': 'socialBlockTwitter',
  '0-71-youtube': 'socialBlockYoutube',
  [OBJECT_TYPE.WORKFLOW]: 'workflows',
  [OBJECT_TYPE.MARKETING_SMS]: 'sms',
  [OBJECT_TYPE.WEB_INTERACTIVE]: 'ctas'
};
export const ICON_COLOR_MAP = {
  socialBlockFacebook: FACEBOOK_COLOR,
  socialBlockInstagram: INSTAGRAM_COLOR,
  socialBlockLinkedin: LINKEDIN_COLOR,
  socialBlockTwitter: TWITTER_COLOR,
  socialBlockYoutube: YOUTUBE_COLOR
};
export const ICON_SIZE = {
  [OBJECT_TYPE.AD_CAMPAIGN]: 18,
  [OBJECT_TYPE.AD_GROUP]: 18,
  [OBJECT_TYPE.SOCIAL_BROADCAST]: 18
};
export const TAB = {
  DETAILS: 'DETAILS',
  ASSETS: 'ASSETS',
  PERFORMANCE: 'PERFORMANCE',
  ACTIVITY: 'ACTIVITY'
};
export const CAROUSEL_ICON_COLORS = {
  [OBJECT_TYPE.LANDING_PAGE]: {
    dark: THUNDERDOME_DARK,
    light: THUNDERDOME_LIGHT
  },
  [OBJECT_TYPE.FORM]: {
    dark: MARIGOLD_DARK,
    light: MARIGOLD_LIGHT
  },
  [OBJECT_TYPE.MARKETING_EMAIL]: {
    dark: CALYPSO_DARK,
    light: CALYPSO_LIGHT
  },
  [OBJECT_TYPE.CAMPAIGN]: {
    dark: NORMAN_DARK,
    light: NORMAN_LIGHT
  },
  [OBJECT_TYPE.SITE_PAGE]: {
    dark: THUNDERDOME_DARK,
    light: THUNDERDOME_LIGHT
  },
  [OBJECT_TYPE.BLOG_POST]: {
    dark: THUNDERDOME_DARK,
    light: THUNDERDOME_LIGHT
  },
  [OBJECT_TYPE.WORKFLOW]: {
    dark: CANDY_APPLE_DARK,
    light: CANDY_APPLE_LIGHT
  },
  [OBJECT_TYPE.MARKETING_SMS]: {
    dark: SORBET_DARK,
    light: SORBET_LIGHT
  },
  [OBJECT_TYPE.WEB_INTERACTIVE]: {
    dark: LORAX_DARK,
    light: LORAX_LIGHT
  },
  [OBJECT_TYPE.AD_GROUP]: {
    dark: OZ_DARK,
    light: OZ_LIGHT
  },
  [OBJECT_TYPE.AD_CAMPAIGN]: {
    dark: OZ_DARK,
    light: OZ_LIGHT
  },
  default: {
    dark: CALYPSO_DARK,
    light: CALYPSO_LIGHT
  }
};