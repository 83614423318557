// The reference types in this file are special cases that are not yet implemented
// in external options and therefore have to be resolved on the frontend. Our
// eventual goal is to remove all of these types so this file should not be
// added to unless absolutely necessary

export const EMAIL_CAMPAIGN = 'EMAIL_CAMPAIGN';
export const IMPORT_NAME = 'IMPORT_NAME';
export const INTEGRATION_NAME = 'INTEGRATION_NAME';
export const MARKETING_REASON = 'MARKETING_REASON';
export const SALESFORCE_CAMPAIGN = 'SALESFORCE_CAMPAIGN';
export const PIPELINE_STAGE_IN_PIPELINE = 'PIPELINE_STAGE_IN_PIPELINE';