import { useCallback, useEffect, useState } from 'react';
import listingLibObserver from 'framework-listing-lib/utils/listingLibObserver';
import { CLOSE_EXPORT_MODAL, OPEN_EXPORT_MODAL } from 'framework-listing-lib/constants/tableEvents';
export default function useRenderExportModal() {
  const [state, setState] = useState({
    modalProps: undefined,
    show: false
  });
  const handleCloseExportModal = useCallback(() => {
    setState(() => ({
      modalProps: undefined,
      show: false
    }));
  }, []);
  const handleOpenExportModal = useCallback(modalProps => {
    setState(() => ({
      modalProps,
      show: true
    }));
  }, []);
  useEffect(() => {
    listingLibObserver.on(CLOSE_EXPORT_MODAL, handleCloseExportModal);
    listingLibObserver.on(OPEN_EXPORT_MODAL, handleOpenExportModal);
    return () => {
      listingLibObserver.off(CLOSE_EXPORT_MODAL, handleCloseExportModal);
      listingLibObserver.off(OPEN_EXPORT_MODAL, handleOpenExportModal);
    };
  }, [handleCloseExportModal, handleOpenExportModal]);
  return state;
}