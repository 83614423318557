import { INVENTORY_RECEIVED } from 'email-management-lib/refactor/actions/actionTypes';
const INITIAL_STATE = {
  ownedProducts: [],
  bundles: []
};
const inventory = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INVENTORY_RECEIVED:
      return {
        ownedProducts: action.ownedProducts,
        bundles: action.bundles
      };
    default:
      return state;
  }
};
export default inventory;