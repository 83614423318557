import CampaignRecord from '../data/CampaignRecord';
import { toCampaignOption } from '../utils/campaignSelect';
import { getPropertyValue, getPropertyValueAsBoolean, getPropertyValueAsInt } from './campaignDaoUtils';
export const mapCrmSearchCampaignObjectToLegacyFull = ({
  portalId,
  properties
}) => {
  const campaignObject = {
    budgetTotalAmount: getPropertyValueAsInt(properties.hs_budget_items_sum_amount),
    currencyCode: getPropertyValue(properties.hs_currency_code),
    deleted: false,
    numericBudget: getPropertyValueAsInt(properties.hs_budget_amount),
    spendAmount: getPropertyValueAsInt(properties.hs_spend_amount),
    spendTotalAmount: getPropertyValueAsInt(properties.hs_spend_items_sum_amount),
    status: getPropertyValue(properties.hs_campaign_status),
    steps: null,
    actionMetadata: getPropertyValue(properties.hs_action_metadata),
    actualBudget: getPropertyValue(properties.hs_budget),
    attributionEnabled: getPropertyValueAsBoolean(properties.hs_attribution_enabled),
    audience: getPropertyValue(properties.hs_audience),
    businessUnitsInfo: {
      selectedBusinessUnits: [getPropertyValueAsInt(properties.hs_all_assigned_business_unit_ids)]
    },
    colorHex: getPropertyValue(properties.hs_color_hex),
    createdAt: getPropertyValueAsInt(properties.hs_created_at),
    createdBy: getPropertyValueAsInt(properties.hs_created_by_user_id),
    endedAt: getPropertyValueAsInt(properties.hs_end_date),
    goal: getPropertyValue(properties.hs_goal),
    goals: {
      contacts: null,
      customers: null,
      visits: null,
      sessions: getPropertyValueAsInt(properties.hs_session_goal),
      newContacts: getPropertyValueAsInt(properties.hs_new_contact_goal),
      influencedContacts: getPropertyValueAsInt(properties.hs_influenced_contact_goal),
      influencedDeals: getPropertyValueAsInt(properties.hs_influenced_closed_deal_goal),
      influencedRevenue: getPropertyValueAsInt(properties.hs_influenced_revenue)
    },
    guid: getPropertyValue(properties.hs_origin_asset_id),
    display_name: getPropertyValue(properties.hs_name),
    notes: getPropertyValue(properties.hs_notes),
    objectId: getPropertyValueAsInt(properties.hs_object_id),
    owner: getPropertyValueAsInt(properties.hs_owner),
    portalId,
    projectedBudget: getPropertyValue(properties.hs_projected_budget),
    revenue: getPropertyValue(properties.hs_revenue),
    startedAt: getPropertyValueAsInt(properties.hs_start_date),
    updatedAt: getPropertyValueAsInt(properties.hs_origin_asset_updated_at),
    templateGuid: getPropertyValue(properties.hs_template_guid),
    utm: getPropertyValue(properties.hs_utm)
  };
  return CampaignRecord.from(campaignObject);
};
export const mapCrmSearchCampaignsToLegacyFull = campaigns => {
  const results = campaigns.results.map(mapCrmSearchCampaignObjectToLegacyFull);
  const legacyCampaigns = Object.assign({}, campaigns, {
    results
  });
  return legacyCampaigns;
};
export const mapCrmSearchCampaignToCampaignSelectOptions = campaigns => {
  const results = mapCrmSearchCampaignsToLegacyFull(campaigns).results.map(campaign => toCampaignOption(campaign));
  const campaignSelectOptions = Object.assign({}, campaigns, {
    results
  });
  return campaignSelectOptions;
};