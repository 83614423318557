import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EMPTY_STATE_CHANGE } from 'framework-listing-lib/constants';
import listingLibObserver from 'framework-listing-lib/utils/listingLibObserver';
export default function useEmptyState() {
  const [emptyStateProps, setEmptyStateProps] = useState(undefined);

  /**
   * Tracks if the empty state has been already notified on the page from @useEmptyState hook.
   *
   * This is to prevent the "empty state API call" to be sent after first load (when, for example, changing views).
   * Used when the filters used by the initial load match the same filters as the ones used by the empty state.
   */
  const hasEverNotifiedEmptyStateRef = useRef(false);
  const handleEmptyStateChange = useCallback(emptyStateResult => {
    hasEverNotifiedEmptyStateRef.current = true;
    setEmptyStateProps(emptyStateResult);
  }, []);
  useEffect(() => {
    listingLibObserver.on(EMPTY_STATE_CHANGE, handleEmptyStateChange);
    return () => {
      listingLibObserver.off(EMPTY_STATE_CHANGE, handleEmptyStateChange);
    };
  }, [handleEmptyStateChange]);
  return useMemo(() => ({
    emptyStateResult: emptyStateProps,
    hasEverNotifiedEmptyState: hasEverNotifiedEmptyStateRef.current
  }), [emptyStateProps]);
}