// Mirror of:  https://git.hubteam.com/HubSpot/content4j/blob/master/ContentModel/src/main/java/com/hubspot/content/model/rss/RssResult.java

const RSS_RESULT = Object.freeze({
  // == Non-Retryable == handled failures
  PREVIOUSLY_SENT: 'PREVIOUSLY_SENT',
  NOT_ALLOWED_TO_SEND: 'NOT_ALLOWED_TO_SEND',
  MAILING_LIST_DELETED: 'MAILING_LIST_DELETED',
  UNEXPECTED_TQ_UID: 'UNEXPECTED_TQ_UID',
  EMAIL_DELETED: 'EMAIL_DELETED',
  EMAIL_NOT_PUBLISHED: 'EMAIL_NOT_PUBLISHED',
  BLOG_DELETED: 'BLOG_DELETED',
  BLOG_POST_DELETED: 'BLOG_POST_DELETED',
  BLOG_POST_NOT_PUBLISHED: 'BLOG_POST_NOT_PUBLISHED',
  RSS_TO_EMAIL_DISABLED: 'RSS_TO_EMAIL_DISABLED',
  RSS_TO_EMAIL_NOT_FOUND: 'RSS_TO_EMAIL_NOT_FOUND',
  RSS_BAD_TASK_UID_EXCEPTION: 'RSS_BAD_TASK_UID_EXCEPTION',
  BLOG_TASK_BACKDATED: 'BLOG_TASK_BACKDATED',
  BLOG_TASK_ALREADY_EXECUTED: 'BLOG_TASK_ALREADY_EXECUTED',
  ERROR_GENERATING_GUID: 'ERROR_GENERATING_GUID',
  // == Retryable Failures ==
  PUBLISHING_FAILED: 'PUBLISHING_FAILED',
  ENQUEUE_FAILED: 'ENQUEUE_FAILED',
  UNEXPECTED_EXCEPTION: 'UNEXPECTED_EXCEPTION',
  RSS_EMAIL_LOCK_EXCEPTION: 'RSS_EMAIL_LOCK_EXCEPTION',
  RSS_ENTRIES_NOT_RENDERED: 'RSS_ENTRIES_NOT_RENDERED',
  // Instant Blog only
  BLOG_TASK_LOCK_EXCEPTION: 'BLOG_TASK_LOCK_EXCEPTION',
  BLOG_TASK_COMPLETED_OR_PROCESSING: 'BLOG_TASK_COMPLETED_OR_PROCESSING',
  // == Max 3 retries ==
  ERROR_FETCHING_FEED: 'ERROR_FETCHING_FEED',
  // Shared handled success
  RETRY_SCHEDULED: 'RETRY_SCHEDULED',
  ATTEMPTS: 'ATTEMPTS',
  EMAIL_SENT: 'EMAIL_SENT',
  EMAIL_ENQUEUED: 'EMAIL_ENQUEUED',
  NO_RSS_ENTRIES_FOUND: 'NO_RSS_ENTRIES_FOUND'
});
export default RSS_RESULT;