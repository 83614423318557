'use es6';

const isExpired = removedAt => {
  const now = Date.now();
  return removedAt && removedAt < now;
};
export const buildTrialState = (createdAt, endsAt, removedAt, id) => {
  return {
    inTrial: !isExpired(removedAt) ? {
      createdTs: createdAt,
      expiresTs: endsAt
    } : null,
    hasTrialed: isExpired(removedAt) ? {
      lastTrialStarted: createdAt,
      lastTrialExpired: removedAt
    } : null,
    id
  };
};