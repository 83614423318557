// To be kept in sync with https://git.hubteam.com/HubSpot/IdentityBase/blob/f7388442493a78452e116edabd6ca06be2fa2e8c/src/main/protobuf/properties.proto#L11
// (link is to commit as of 7/25/2022, check file at latest master for updates)

export const ACADEMY = 'ACADEMY';
export const ADS = 'ADS';
export const AI_GROUP = 'AI_GROUP';
export const ANALYTICS = 'ANALYTICS';
export const API = 'API';
export const APPROVALS = 'APPROVALS';
export const ASSISTS = 'ASSISTS';
export const ASSOCIATIONS = 'ASSOCIATIONS';
export const AUTOMATION_PLATFORM = 'AUTOMATION_PLATFORM';
export const AVATARS_SERVICE = 'AVATARS_SERVICE';
export const BATCH_UPDATE = 'BATCH_UPDATE';
export const BCC_TO_CRM = 'BCC_TO_CRM';
export const BEHAVIORAL_EVENTS = 'BEHAVIORAL_EVENTS';
export const BET_ASSIGNMENT = 'BET_ASSIGNMENT';
export const BET_CRM_CONNECTOR = 'BET_CRM_CONNECTOR';
export const BIDEN = 'BIDEN'; // BIDEN property source is deprecated in favor of COMPANY_INSIGHTS
export const BOT = 'BOT';
export const CALCULATED = 'CALCULATED';
export const COMMUNICATOR = 'COMMUNICATOR';
export const COMPANIES = 'COMPANIES';
export const COMPANY_FAMILIES = 'COMPANY_FAMILIES';
export const COMPANY_INSIGHTS = 'COMPANY_INSIGHTS';
export const CONTACTS = 'CONTACTS';
export const CONTACTS_WEB = 'CONTACTS_WEB';
export const CONTENT_MEMBERSHIP = 'CONTENT_MEMBERSHIP';
export const CONVERSATIONS = 'CONVERSATIONS';
export const CRM_UI = 'CRM_UI';
export const CRM_UI_BULK_ACTION = 'CRM_UI_BULK_ACTION';
export const DEALS = 'DEALS';
/** @deprecated A default source provides no information to our customers and should not be used */
export const DEFAULT = 'DEFAULT';
export const EMAIL = 'EMAIL';
export const EMAIL_INTEGRATION = 'EMAIL_INTEGRATION';
export const ENGAGEMENTS = 'ENGAGEMENTS';
export const EXTENSION = 'EXTENSION';
export const FILE_MANAGER = 'FILE_MANAGER';
export const FORECASTING = 'FORECASTING';
export const FORM = 'FORM';
export const FORWARD_TO_CRM = 'FORWARD_TO_CRM';
export const GMAIL_INTEGRATION = 'GMAIL_INTEGRATION';
export const GOALS = 'GOALS';
export const HEISENBERG = 'HEISENBERG';
export const IMPORT = 'IMPORT';
export const INTEGRATION = 'INTEGRATION';
export const INTEGRATIONS_PLATFORM = 'INTEGRATIONS_PLATFORM';
export const INTEGRATIONS_SYNC = 'INTEGRATIONS_SYNC';
export const INTERNAL_PROCESSING = 'INTERNAL_PROCESSING';
export const LEADIN = 'LEADIN';
export const MARKETPLACE = 'MARKETPLACE';
export const MEETINGS = 'MEETINGS';
export const MERGE_COMPANIES = 'MERGE_COMPANIES';
export const MERGE_CONTACTS = 'MERGE_CONTACTS';
export const MERGE_OBJECTS = 'MERGE_OBJECTS';
export const MIGRATION = 'MIGRATION';
export const MOBILE_ANDROID = 'MOBILE_ANDROID';
export const MOBILE_IOS = 'MOBILE_IOS';
export const PAYMENTS = 'PAYMENTS';
export const PINNED_ACTIVITY = 'PINNED_ACTIVITY';
export const PIPELINE_SETTINGS = 'PIPELINE_SETTINGS';
export const PORTAL_OBJECT_SYNC = 'PORTAL_OBJECT_SYNC';
export const PORTAL_USER_ASSOCIATOR = 'PORTAL_USER_ASSOCIATOR';
export const PRESENTATIONS = 'PRESENTATIONS';
export const PROPERTY_SETTINGS = 'PROPERTY_SETTINGS';
export const QUOTAS = 'QUOTAS';
export const QUOTES = 'QUOTES';
export const RECYCLING_BIN = 'RECYCLING_BIN';
export const SALES = 'SALES';
export const SALES_MESSAGES = 'SALES_MESSAGES';
export const SALESFORCE = 'SALESFORCE';
export const SEQUENCES = 'SEQUENCES';
export const SETTINGS = 'SETTINGS';
export const SIDEKICK = 'SIDEKICK';
export const SIGNALS = 'SIGNALS';
export const SLACK_INTEGRATION = 'SLACK_INTEGRATION';
export const SOCIAL = 'SOCIAL';
export const SUCCESS = 'SUCCESS';
export const TALLY = 'TALLY';
export const TASK = 'TASK';
export const UNIVERSAL_TIMELINE = 'UNIVERSAL_TIMELINE';
export const WAL_INCREMENTAL = 'WAL_INCREMENTAL';
export const WORKFLOW_CONTACT_DELETE_ACTION = 'WORKFLOW_CONTACT_DELETE_ACTION';
export const WORKFLOWS = 'WORKFLOWS';