'use es6';

import { Record } from 'immutable';
const defaults = {
  portalId: null,
  campaignId: null,
  sendId: null,
  requestedBy: null,
  reasonMessage: null,
  insertedAt: null,
  cancellationSource: null
};
class CampaignCancellation extends Record(defaults) {
  static from(json) {
    return new CampaignCancellation(json);
  }
}
export default CampaignCancellation;