import { stringify } from 'hub-http/helpers/params';
import { INSIGHT_CONTEXT_QUERY_PARAM } from 'messaging-publishing-lib/Insights/components/InsightContextProviderBase';
export const getPerformanceRedirectParams = ({
  crmEmail,
  insightContext: {
    isDefaultValue,
    context
  },
  isUngatedForPublishDateForBatchEmailPinotStats
}) => {
  const emailStartDate = isUngatedForPublishDateForBatchEmailPinotStats ? crmEmail.publishDate : crmEmail.created;
  const includeEmailStartDate = crmEmail.isBatch() && !!emailStartDate;
  const insightContext = isDefaultValue ? null : context;
  const includeInsightContext = crmEmail.isBatch() || crmEmail.isAutomated();
  return stringify(Object.assign({}, includeEmailStartDate && {
    emailStartDate
  }, includeInsightContext && {
    [INSIGHT_CONTEXT_QUERY_PARAM]: insightContext
  }));
};