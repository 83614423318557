import { PRODUCT_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
const FRACTIONAL_CURRENCY_OBJECT_TYPE_IDS = new Set([PRODUCT_TYPE_ID]);

// Fractional currency is needed on Products because of its connection to Payments use cases.
// Products are used as templates to create line items, and we want to support the sale of
// massive numbers of extremely cheap objects (1 million pencil shavings @ 0.001 cents each)
// without losing precision.

export default function isFractionalCurrencySupported(objectTypeId) {
  return FRACTIONAL_CURRENCY_OBJECT_TYPE_IDS.has(objectTypeId);
}