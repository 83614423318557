import PortalIdParser from 'PortalIdParser';

// Check if the side panel will be auto-opened
// reference: https://git.hubteam.com/HubSpot/growth-side-panel/blob/master/growth-side-panel-test-ui/static/js/utils/iah.ts#L7
export const isGrowthSidePanelAutoOpenEnabled = () => {
  let expires = 0;
  try {
    const expiresStr = localStorage.getItem(`in-app-help-auto-open-widget:${PortalIdParser.get()}`);
    expires = expiresStr ? parseInt(expiresStr, 10) : 0;
  } catch (err) {
    // no-op
  }
  return Date.now() < expires;
};