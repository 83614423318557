import { useReducer } from 'react';
import promiseDone from 'hs-promise-utils/promiseDone';
import { useHttpClient } from '../../client/HttpClientContext';
import { useIsMounted } from './useIsMounted';
const reducer = (__state, action) => {
  switch (action.status) {
    case 'loading':
      return {
        loading: true,
        error: false,
        data: undefined
      };
    case 'error':
      return {
        loading: false,
        error: true,
        data: undefined
      };
    case 'success':
      return {
        loading: false,
        error: false,
        data: action.data
      };
    default:
      {
        const exhaustiveCheck = action;
        throw new Error(`Unhandled action: ${exhaustiveCheck}`);
      }
  }
};
let cache;
const fetchWithCaching = httpClient => {
  if (!cache) {
    cache = httpClient.get('multi-currency/v2/currencies/information');
  }
  return cache;
};

// exported for unit test usage only
export const _reset = () => {
  cache = undefined;
};

/**
 * Fetches the portal's home currency from the `multi-currency/v2` API.
 *
 * Currency is only fetched ONCE during the lifetime of the app, then globally cached.
 *
 * This hook follows the pattern of Apollo's `useQuery`. It can be replaced
 * with Apollo or data-fetching-client if customer-data-properties adds a
 * dependency on one of those.
 *
 * @example
 * const { loading, error, data } = useHomeCurrency();
 *
 * if (loading) {
 *   return <UILoadingSpinner />;
 * }
 *
 * if (error) {
 *   return <>{value}</>;
 * }
 *
 * return <FormattedCurrency currencyCode={data} value={value} />;
 */
export const useHomeCurrency = () => {
  const httpClient = useHttpClient();
  const isMounted = useIsMounted();
  const [state, dispatch] = useReducer(reducer, undefined);
  if (!state) {
    dispatch({
      status: 'loading'
    });
    promiseDone(fetchWithCaching(httpClient).then(response => {
      if (!isMounted.current) {
        return;
      }
      dispatch({
        status: 'success',
        data: response.homeCurrency.currencyCode
      });
    }).catch(() => {
      if (!isMounted.current) {
        return;
      }
      dispatch({
        status: 'error'
      });
    }));
    return {
      loading: true,
      error: false,
      data: undefined
    };
  }
  return {
    loading: state.loading,
    error: state.error,
    data: state.data
  };
};