'use es6';

import { createSelector } from 'reselect';
import { buildWorkflows } from 'EmailData/utils/workflowsUtils';
export const simpleWorkflowsSelector = state => {
  return state.getIn(['entities', 'simpleWorkflows']).getData();
};
export const simpleWorkflowsFromDashboard = primaryEmailId => createSelector([simpleWorkflowsSelector], simpleWorkflowsMap => buildWorkflows(simpleWorkflowsMap, primaryEmailId, null, {
  omit: 'JOURNEY_STAGE'
}));
export const areSimpleWorkflowsLoadingSelector = state => {
  return state.getIn(['entities', 'simpleWorkflows']).isLoading();
};