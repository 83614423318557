import { useCallback } from 'react';
import { useIsFoldersMode } from 'framework-listing-lib/internal/hooks/useUIMode';
import useCurrentFolderId from './useCurrentFolderId';
import useFolderMoveHistoryState from './useFolderMoveHistoryState';
export default function useIsMovingToAnotherFolder() {
  const currentFolderId = useCurrentFolderId();
  const [state] = useFolderMoveHistoryState();
  const isFoldersMode = useIsFoldersMode();
  return useCallback(objectId => {
    return isFoldersMode && currentFolderId in state && state[currentFolderId].includes(objectId);
  }, [currentFolderId, isFoldersMode, state]);
}