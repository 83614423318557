export const optInLink = 'https://api.hubapi.com/email/v1/optin/confirm/doi';
export const MAX_ALLOWED_SENDS_TO_MORE = 100;
export const SENDS_TO_MORE_WARNING_THRESHOLD = 90;
export const UNIQUE_LINKS_THRESHOLD = 100000;
export const MIN_CLICKS_CUTTOFF_WHEN_THROTTLED = 10;
export const DELAY_TO_SHOW_BOT_RATE_CSAT = 10000; //10 sec

export const EXCLUDING_BOT = 'excludingBot';
export const INCLUDING_BOT = 'includingBot';
export const reliableOpen = 'reliableOpen';
export const BOT_TOUR_LIGHT = 'bot-filter-status-onboarding';
export const BOT_TOUR_BAR = 'bot-filter-bar-onboarding';