import http from 'hub-http/clients/apiClient';
import { useQuery, registerQuery } from 'data-fetching-client';
import useCrmObjectTypeScopes from 'framework-listing-lib/internal/hooks/useCrmObjectTypeScopes';
import useFetchUserInfo from 'framework-listing-lib/internal/hooks/useFetchUserInfo';
import applyPermissionsOverride from 'framework-listing-lib/utils/applyPermissionsOverride';
const GET_USERS_FIELD_NAME = 'users';
const GET_USERS = registerQuery({
  fieldName: GET_USERS_FIELD_NAME,
  args: ['canViewAllAssets', 'canViewTeamOwnedAssets', 'userIdsFromUsersTeams'],
  fetcher({
    canViewAllAssets,
    canViewTeamOwnedAssets,
    userIdsFromUsersTeams
  }) {
    if (canViewAllAssets) {
      // If a user can view all assets, fetch all users
      return http.get(`app-users/v1/users`);
    } else if (canViewTeamOwnedAssets) {
      // If a user can view team-owned assets, return only users from the user's
      // teams in the table filter.
      return http.put(`users/v2/app/hub-users/by-ids`, {
        data: userIdsFromUsersTeams
      });
    } else {
      return Promise.resolve([]);
    }
  }
});
function makeUserWithOwnedAssetCount(user) {
  return {
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    ownedAssetCount: undefined
  };
}
function getUserIdsFromUsersTeams(userInfo) {
  const userIds = [];
  if (!userInfo || !userInfo.user) {
    return userIds;
  }
  userIds.push(userInfo.user.user_id);
  if (userInfo.user.teams) {
    userInfo.user.teams.forEach(team => {
      team.teammates.forEach(userId => {
        if (userIds.indexOf(userId) === -1) {
          userIds.push(userId);
        }
      });
    });
  }
  return [...new Set(userIds)];
}
export default function useFetchUsers({
  permissionsOverride,
  viewAllAssetsScope,
  viewTeamOwnedAssetsScope
}) {
  const scopes = useCrmObjectTypeScopes({
    viewAllAssetsScope,
    viewTeamOwnedAssetsScope
  });
  const finalScopes = applyPermissionsOverride(scopes, permissionsOverride);
  const {
    data: userInfo,
    loading: loadingUserInfo
  } = useFetchUserInfo();
  const userIdsFromUsersTeams = getUserIdsFromUsersTeams(userInfo);
  const {
    data,
    error,
    loading
  } = useQuery(GET_USERS, {
    variables: {
      canViewAllAssets: finalScopes.canViewAllAssets,
      canViewTeamOwnedAssets: finalScopes.canViewTeamOwnedAssets,
      userIdsFromUsersTeams
    },
    skip: loadingUserInfo
  });
  return {
    data: data && GET_USERS_FIELD_NAME in data ? data[GET_USERS_FIELD_NAME].map(makeUserWithOwnedAssetCount) : [],
    error,
    loading
  };
}