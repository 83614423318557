import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["businessUnitsInfo", "utm", "goals"],
  _excluded2 = ["goals", "steps"],
  _excluded3 = ["businessUnitsInfo"],
  _excluded4 = ["owner", "utms"];
import { Record as ImmutableRecord, Set as ImmutableSet } from 'immutable';
export function getBusinessUnitFromResponse(businessUnitsInfo) {
  if (businessUnitsInfo != null && typeof businessUnitsInfo === 'object') {
    // campaigns only supports one BU per campaign at the moment
    return {
      businessUnitsInfo: businessUnitsInfo.selectedBusinessUnits[0]
    };
  }
  return undefined;
}
const defaultGoalsObject = {
  contacts: null,
  customers: null,
  influencedContacts: null,
  influencedDeals: null,
  influencedRevenue: null,
  newContacts: null,
  sessions: null,
  visits: null
};
export const CAMPAIGN_DEFAULT_VALUES = {
  actionMetadata: null,
  actualBudget: null,
  attributionEnabled: false,
  audience: null,
  budgetTotalAmount: null,
  businessUnitsInfo: 0,
  colorHex: null,
  createdAt: null,
  createdBy: null,
  currencyCode: null,
  customProperties: {},
  deleted: false,
  endedAt: null,
  goal: null,
  goals: defaultGoalsObject,
  guid: null,
  display_name: '',
  notes: null,
  numericBudget: null,
  objectId: null,
  owner: null,
  portalId: null,
  projectedBudget: null,
  revenue: null,
  spendAmount: null,
  spendTotalAmount: null,
  startedAt: null,
  status: null,
  steps: null,
  updatedAt: null,
  templateGuid: null,
  utm: '',
  utms: ImmutableSet(['']),
  supportsAssetPlaceholders: false,
  promptInputs: null
};
class CampaignRecord extends ImmutableRecord(CAMPAIGN_DEFAULT_VALUES) {
  static from(_ref = {}) {
    let {
        businessUnitsInfo,
        utm = '',
        goals
      } = _ref,
      response = _objectWithoutPropertiesLoose(_ref, _excluded);
    return new CampaignRecord(Object.assign({}, response, getBusinessUnitFromResponse(businessUnitsInfo), {
      utm,
      utms: utm ? ImmutableSet([utm]) : ImmutableSet(['']),
      goals: goals || defaultGoalsObject
    }));
  }
  merge(campaign) {
    const campaignJS = campaign instanceof CampaignRecord ? campaign.toJS() : campaign;

    // Override merge method to ignore properties not defined in CampaignRecord above
    // Only necessary until we get to version 4 of immutable
    const _Object$keys$reduce = Object.keys(campaignJS).reduce((acc, key) => {
        if (this.has(key)) {
          acc[key] = campaign[key];
        }
        return acc;
      }, {}),
      {
        goals,
        steps
      } = _Object$keys$reduce,
      campaignFiltered = _objectWithoutPropertiesLoose(_Object$keys$reduce, _excluded2);
    return super.merge(campaignFiltered).withMutations(m => {
      // Making sure that goals is an object rather than a Immutable.Map
      m.set('goals', Object.assign({}, m.goals, goals));

      // Making sure that steps is an array rather than a Immutable.List
      if (steps) {
        m.set('steps', steps);
      }
      if (typeof campaignFiltered.utm === 'string') {
        // Adding the new utm to the utms list
        m.set('utms', m.utms.add(campaignFiltered.utm));
      }
    });
  }
  mergeWithResponse(_ref2) {
    let {
        businessUnitsInfo
      } = _ref2,
      response = _objectWithoutPropertiesLoose(_ref2, _excluded3);
    return this.merge(Object.assign({}, response, getBusinessUnitFromResponse(businessUnitsInfo)));
  }
  toPayload() {
    const _this$toObject = this.toObject(),
      {
        owner
        // omitting `utms` from the payload
      } = _this$toObject,
      payload = _objectWithoutPropertiesLoose(_this$toObject, _excluded4);
    return Object.assign({}, payload, {
      businessUnitsInfo: undefined,
      owner: owner ? parseInt(`${owner}`, 10) : null
    });
  }
}
export default CampaignRecord;