import I18n from 'I18n';
/**
 * Similar to I18N's `FormattedDuration`.
 *
 * Unfortunately I18n does not export a `formatDuration` helper. It uses a mix
 * of `moment.toNow` and I18n's `formatRelativeDuration` instead.
 *
 * `moment.toNow` is defined in terms of `duration.humanize` (see
 * https://github.com/moment/moment/blob/000ac1800e620f770f4eb31b5ae908f6167b0ab2/src/lib/moment/to.js)
 *
 * See https://git.hubteam.com/HubSpot/I18n/blob/313c90b6d57f9aafb5eeae1162047f69dd23d961/static/js/components/FormattedDuration.js
 *
 * @example
 * formatDuration('1234567890') // => '14 days'
 * formatDuration(null) // => null
 */
export const formatDuration = value => {
  if (value === null || value === undefined || isNaN(+value) || value === '') {
    return value;
  }
  return I18n.moment.duration(parseInt(value, 10)).humanize();
};