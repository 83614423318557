import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { parseQueryParams } from 'EmailData/utils/url';
function useRouter() {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const query = useMemo(() => parseQueryParams(location.search), [location.search]);
  return useMemo(() => ({
    history,
    location,
    params,
    query
  }), [params, location, history, query]);
}
export default useRouter;