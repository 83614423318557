import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["vid"];
import { Record } from 'immutable';
import I18n from 'I18n';
import formatName from 'I18n/utils/formatName';
import Raven from 'raven-js';
const defaults = {
  properties: {
    firstname: {
      value: ''
    },
    lastname: {
      value: ''
    },
    email: {
      value: ''
    }
  },
  deleted: false,
  vid: -1,
  'portal-id': -1,
  addedAt: -1,
  id: -1
};
class VidModel extends Record(defaults) {
  static from(_ref) {
    let {
        vid
      } = _ref,
      rest = _objectWithoutPropertiesLoose(_ref, _excluded);
    try {
      return new VidModel(Object.assign({}, rest, {
        vid,
        id: vid
      }));
    } catch (error) {
      Raven.captureMessage('Error when converting to VidModel', {
        extra: Object.assign({
          error,
          vid
        }, rest)
      });
      return null;
    }
  }
  getComputedName() {
    const properties = this.get('properties');
    const {
      firstname,
      lastname,
      email
    } = properties;
    const maybeEmail = email && email.value ? email.value : I18n.text('EmailData.placeholder.noEmailAvailable');
    const firstnameValue = this.getPropertyValue(firstname);
    const lastnameValue = this.getPropertyValue(lastname);
    return [formatName({
      firstName: firstnameValue,
      lastName: lastnameValue
    }), `<${maybeEmail}>`].filter(property => property.length > 0).join(' ');
  }
  getMinComputedName() {
    const properties = this.get('properties');
    const {
      firstname,
      lastname
    } = properties;
    const firstnameValue = this.getPropertyValue(firstname);
    const lastnameValue = this.getPropertyValue(lastname);
    if (firstname) {
      return formatName({
        firstName: firstnameValue,
        lastName: lastnameValue
      });
    }
    return this.getEmail();
  }
  getEmail() {
    const email = this.get('properties').email;
    return this.getPropertyValue(email);
  }
  getPropertyValue(property) {
    return property && property.value ? property.value : '';
  }
  getUrl() {
    return `/contacts/${this.get('portal-id')}/contact/${this.get('vid')}`;
  }
  isDeleted() {
    return this.get('deleted') === true;
  }
  getAsSelectOption() {
    return {
      value: this.get('vid'),
      text: this.getComputedName(),
      email: this.getEmail(),
      name: this.getMinComputedName()
    };
  }
}
export default VidModel;