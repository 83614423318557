'use es6';

import debounce from 'transmute/debounce';
import get from 'transmute/get';
import isEmpty from 'transmute/isEmpty';
import invariant from 'react-utils/invariant';
import { BatchRequestClientError } from '../error/BatchRequestClientError';

/**
 * makeRequestBody - A function that, given an object of requestKeys to
 * requestOptions (the options passed to `fetch`), returns a value that will be
 * passed to the API and used to build the request. Note that APIs can modifiy
 * this value after it's received, this just builds the parameter that's passed
 * to `api`.
 *
 * makeRequestKey - A pure function that, given requestOptions, returns a unique
 * key for a request. This key will be used to index the cache and to identify
 * duplicate requests. This key must always be the same for a given input of
 * request options.
 *
 * api - The API to call with the batched requests. This API should always return
 * data in the format described below. It will received a single object as an
 * argument with a key in it called requestBody which is the request body
 * created by makeRequestBody
 *
 * Required api return format example:
 * {
 *   [requestKey]: <value that will be returned by the request matching the requestKey>
 * }
 *
 * requestKey in the above example is the same value that's returned by
 * makeRequestKey. The client requires the result of the API to be mapped to
 * existing requestKey's so it knows which value to resolve each promise with.
 *
 * options - An object of options to configure the client.
 *   - MAX_REQUEST_SIZE - The maximum number of requests the client will queue
 *     before it fires a request to the batch api. Defaults to 50.
 */
export class BatchRequestClient {
  constructor({
    api,
    makeRequestBody,
    makeRequestKey,
    options: {
      MAX_REQUEST_SIZE
    } = {
      MAX_REQUEST_SIZE: 50
    }
  }) {
    this.fetchQueuedRequests = () => {
      if (isEmpty(this.queuedRequests)) {
        return;
      }
      const requestOptionsByKey = Object.keys(this.queuedRequests).reduce((acc, key) => {
        acc[key] = this.queuedRequests[key].requestOptions;
        return acc;
      }, {});
      this.requestsInProgress = Object.assign({}, this.requestsInProgress, this.queuedRequests);
      this.queuedRequests = {};
      const requestBody = this.makeRequestBody(requestOptionsByKey);
      this.api({
        requestBody
      }).then(response => Object.keys(response).forEach(requestKey => {
        const request = get(requestKey, this.requestsInProgress);
        if (request) {
          const results = response[requestKey];
          request.entry.resolve(results);
          this.cache[requestKey] = results;
          delete this.requestsInProgress[requestKey];
        }
      })).catch(err => {
        if (err instanceof BatchRequestClientError) {
          err.requestKeys.forEach(requestKey => {
            const request = get(requestKey, this.requestsInProgress);
            if (request) {
              request.entry.reject(err);
              delete this.requestsInProgress[requestKey];
            }
          });
        } else {
          setTimeout(() => {
            throw err;
          });
        }
      });
    };
    this.debouncedFetchQueuedRequests = debounce(500, this.fetchQueuedRequests);
    this.clearCache = () => {
      this.cache = {};
    };
    this.clearRequestsInProgress = () => {
      this.requestsInProgress = {};
    };
    this.queueRequest = (requestKey, requestOptions) => {
      if (this.cache[requestKey]) {
        return Promise.resolve(this.cache[requestKey]);
      }
      const existingRequest = this.requestsInProgress[requestKey] || this.queuedRequests[requestKey];

      // If the request already exists just return the existing promise. We don't
      // support changing request options for requests that are already queued.
      if (existingRequest) {
        return existingRequest.entry.promise;
      }
      const entry = {};
      const promise = new Promise((resolve, reject) => {
        entry.resolve = resolve;
        entry.reject = reject;
      });
      entry.promise = promise;
      this.queuedRequests[requestKey] = {
        entry,
        requestOptions
      };

      // If we hit the max size for a request send one off, otherwise call the
      // debounced version to wait a bit for any other requests before firing
      if (Object.keys(this.queuedRequests).length >= this.MAX_REQUEST_SIZE) {
        this.fetchQueuedRequests();
      } else {
        this.debouncedFetchQueuedRequests();
      }
      return entry.promise;
    };
    invariant(typeof api === 'function', 'api function must be provided to BatchRequestClient');
    invariant(typeof makeRequestBody === 'function', 'makeRequestBody function must be provided to BatchRequestClient');
    invariant(typeof makeRequestKey === 'function', 'makeRequestKey function must be provided to BatchRequestClient');
    invariant(MAX_REQUEST_SIZE && MAX_REQUEST_SIZE > 1, 'MAX_REQUEST_SIZE must be at least 2');
    this.cache = {};
    this.queuedRequests = {};
    this.requestsInProgress = {};
    this.api = api;
    this.makeRequestBody = makeRequestBody;
    this.makeRequestKey = makeRequestKey;
    this.MAX_REQUEST_SIZE = MAX_REQUEST_SIZE;
  }
  fetch(requestOptions) {
    const requestKey = this.makeRequestKey(requestOptions);
    return this.queueRequest(requestKey, requestOptions);
  }
}