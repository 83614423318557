export function trackCompareToolInteraction(tracker, subKey) {
  tracker.track('compareToolInteraction', Object.assign({}, subKey));
}
let tracker;
let currentScreen;
let currentSubscreen;
let currentSubSubscreen;
export function init(newTracker) {
  tracker = newTracker;
}
export const getUsageTracker = () => tracker;
export function trackPageLoad(screen, subscreen, subSubscreen) {
  tracker.track('pageLoad', {
    newPostSend: 'yes',
    screen,
    subscreen,
    subscreen2: subSubscreen
  });
  currentScreen = screen;
  currentSubscreen = subscreen;
  currentSubSubscreen = subSubscreen || currentSubSubscreen;
}
export function trackScreenChange(eventValue, screen, subscreen, newSubSubscreen, extraData = {}) {
  if (currentScreen === screen && currentSubscreen === subscreen && currentSubSubscreen === newSubSubscreen) {
    return;
  }
  if (typeof currentSubSubscreen === 'undefined') {
    trackPageLoad(screen, subscreen, newSubSubscreen);
  } else {
    tracker.track(eventValue, Object.assign({
      screen,
      subscreen,
      subscreen2: currentSubSubscreen,
      viewSection: newSubSubscreen
    }, extraData));
  }
  currentSubSubscreen = newSubSubscreen || currentSubSubscreen;
}
export function trackDashboardScreenChange(screen, subscreen, newSubSubscreen) {
  trackScreenChange('dashboardInteraction', screen, subscreen, newSubSubscreen);
}
export function trackPostSendScreenChange(screen, subscreen, newSubSubscreen) {
  trackScreenChange('postSendInteraction', screen, subscreen, newSubSubscreen, {
    newPostSend: 'yes'
  });
}
export function trackPostSendInteraction(subKey) {
  tracker.track('postSendInteraction', Object.assign({
    newPostSend: 'yes'
  }, subKey));
}
export function trackRemediationInteraction(data) {
  tracker.track('remediationInteraction', Object.assign({}, data));
}
export function trackTldrInteraction(subKey) {
  tracker.track('tldrInteraction', Object.assign({
    tldrAction: 'yes',
    newPostSend: 'yes',
    fromNotification: false
  }, subKey));
}
export function trackAnalyzeInteraction(subKey) {
  tracker.track('analyzeInteraction', Object.assign({
    isVNext: true
  }, subKey));
}
export function trackDashboardInteraction(subKey) {
  tracker.track('dashboardInteraction', Object.assign({
    newPostSend: 'yes'
  }, subKey));
}
export function trackFrameworkTableInteraction(subKey) {
  tracker.track('frameworkTableInteraction', Object.assign({}, subKey));
}
export function trackAssetAccessInteraction(action) {
  trackDashboardInteraction({
    assetAccessAction: action
  });
}
export function trackCreateEmailInteraction() {
  tracker.track('clickCreateEmail');
}
export function trackDashboardUpgradeInteraction() {
  trackDashboardInteraction({
    isUpgradeInteraction: true
  });
}
export function trackPostSendUpgradeInteraction() {
  trackPostSendInteraction({
    isUpgradeInteraction: true
  });
}
export function trackIntegrationInteraction(properties) {
  tracker.track('integrationsInteraction', properties);
}
export function trackOnboardingTourInteraction(action) {
  trackDashboardInteraction({
    onboardingTourAction: action
  });
}
export function trackEmptyStateInteraction(action) {
  trackDashboardInteraction({
    emptyStateInteraction: action
  });
}
export function trackContextualAutomationInteraction(props) {
  tracker.track('contentEditorAutomationInteraction', Object.assign({
    sourceApp: 'MARKETING_EMAIL_AUTOMATED'
  }, props));
}
export function trackTestSendInteraction(subKey) {
  tracker.track('testSendInteraction', Object.assign({}, subKey));
}
export const trackBeforeUnload = (eventKey, eventProperties = {}) => tracker.clone({}).track(eventKey, eventProperties);