import { Record } from 'immutable';
import StatisticsDeviceCounters from './StatisticsDeviceCounters';
class StatisticsDeviceBreakdown extends Record({
  click_device_type: StatisticsDeviceCounters.from({}),
  open_device_type: StatisticsDeviceCounters.from({})
}) {
  static from(json) {
    return new StatisticsDeviceBreakdown({
      click_device_type: StatisticsDeviceCounters.from(json.click_device_type || {}),
      open_device_type: StatisticsDeviceCounters.from(json.open_device_type || {})
    });
  }
}
export default StatisticsDeviceBreakdown;