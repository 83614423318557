'use es6';

import { List, Record } from 'immutable';
class UsersData extends Record({
  users: List([]),
  isFetched: false
}) {
  static from(json) {
    return new UsersData(json);
  }
}
export default UsersData;