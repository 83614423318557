import combineImmutableReducers from 'EmailData/reducers/helpers/combineImmutableReducers';
// @ts-expect-error not migrated to TS yet
import blogRssHistoryTable from './blogRssHistoryTable';
// @ts-expect-error not migrated to TS yet
import detailsActionModal from './detailsActionModal';
// @ts-expect-error not migrated to TS yet
import emailChildSelect from './emailChildSelect';
import emailTemplateModeCloneModal from './emailTemplateModeCloneModal';
import hasLaggedStatsTimedOut from './hasLaggedStatsTimedOut';
import hasReachedLimitModalOpen from './hasReachedLimitModalOpen';
import overTimeSelect from './overTimeSelect';
// @ts-expect-error not migrated to TS yet
import sendToMoreLists from './sendToMoreLists';
// @ts-expect-error not migrated to TS yet
import sendToMoreVids from './sendToMoreVids';
// @ts-expect-error not migrated to TS yet
import slack from './slack';
import portalBotFilterStatus from './portalBotFilterStatus';
// @ts-expect-error not migrated to TS yet
import smtpPassword from './smtpPassword';
// @ts-expect-error not migrated to TS yet
import testResults from './testResults';
import listLimits from './listLimits';
export default combineImmutableReducers({
  blogRssHistoryTable,
  detailsActionModal,
  emailChildSelect,
  overTimeSelect,
  portalBotFilterStatus,
  sendToMoreVids,
  slack,
  smtpPassword,
  sendToMoreLists,
  hasReachedLimitModalOpen,
  emailTemplateModeCloneModal,
  hasLaggedStatsTimedOut,
  testResults,
  listLimits
});