import { Metrics } from 'framework-listing-lib/internal/metrics';
const PREFIX = 'measure_framework_listing_lib_';
export const MEASURE_MARK_NAMES = {
  CRM_SEARCH: `${PREFIX}crm_search`,
  LIFECYCLE: `${PREFIX}lifecycle`,
  TABLE_COLUMNS: `${PREFIX}tableColumns`,
  VIEWS: `${PREFIX}views`
};
/**
 * Creates a tracking object with markTimeStart and markTimeEnd methods.
 * @param name Name of the performance marker.
 */
function createTrackingObject(name) {
  const markTimeStart = () => {
    if (performance.getEntriesByName(name).length > 0) {
      return;
    }
    performance.mark(name);
  };
  const markTimeEnd = () => {
    const entries = performance.getEntriesByType('measure');
    if (entries.find(performanceMeasure => performanceMeasure.name === name)) {
      return;
    }
    performance.measure(name, name);
  };
  return {
    markTimeStart,
    markTimeEnd
  };
}
const INITIAL_HAS_TRACKED_PERFORMANCE = {
  [MEASURE_MARK_NAMES.CRM_SEARCH]: false,
  [MEASURE_MARK_NAMES.LIFECYCLE]: false,
  [MEASURE_MARK_NAMES.TABLE_COLUMNS]: false,
  [MEASURE_MARK_NAMES.VIEWS]: false
};
let instance;
class ListingLibPerformanceTracking {
  constructor() {
    if (instance) {
      throw new Error('ListingLibPerformanceTracking should have only one instance!');
    }
    this.hasTrackedLibPerformance = Object.assign({}, INITIAL_HAS_TRACKED_PERFORMANCE);
    this.performanceTrackingMap = {
      [MEASURE_MARK_NAMES.CRM_SEARCH]: createTrackingObject(MEASURE_MARK_NAMES.CRM_SEARCH),
      [MEASURE_MARK_NAMES.LIFECYCLE]: createTrackingObject(MEASURE_MARK_NAMES.LIFECYCLE),
      [MEASURE_MARK_NAMES.TABLE_COLUMNS]: createTrackingObject(MEASURE_MARK_NAMES.TABLE_COLUMNS),
      [MEASURE_MARK_NAMES.VIEWS]: createTrackingObject(MEASURE_MARK_NAMES.VIEWS)
    };

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    instance = this;
  }

  /**
   * Return all PerformanceEntry objects used to track performance in framework-listing-lib
   */
  getPerformanceSummary() {
    const allTrackingKeys = Object.values(MEASURE_MARK_NAMES);
    return performance.getEntriesByType('measure').filter(performanceMeasure => allTrackingKeys.includes(performanceMeasure.name));
  }
  trackLibPerformanceGrafana({
    applicationId,
    objectTypeId,
    performanceEntry
  }) {
    /**
     * Metrics JS tracking
     */
    const metricsJsName = performanceEntry.name.replace(/_/g, '-');

    /**
     * Common props to all tracked events
     */
    const metricsJsDimensions = {
      application_id: applicationId,
      object_type: objectTypeId
    };

    /**
     * Track start time & duration for lifecycle
     */
    if (performanceEntry.name === MEASURE_MARK_NAMES.LIFECYCLE) {
      Metrics.timer(`${metricsJsName}-start-time`, metricsJsDimensions).update(performanceEntry.startTime);
    }
    Metrics.timer(metricsJsName, metricsJsDimensions).update(performanceEntry.duration);
  }
  trackLibPerformanceAmplitude({
    applicationId,
    objectTypeId,
    performanceEntry,
    tracker
  }) {
    if (!tracker) {
      return;
    }
    tracker.track('performanceInteraction', {
      applicationId,
      objectType: objectTypeId,
      duration: performanceEntry.duration,
      name: performanceEntry.name,
      startTime: performanceEntry.startTime
    });
  }

  /**
   * This method is only used for tests to prevent perf tracking from being tracked by other random tests
   * in the codebase.
   */
  _reset() {
    this.hasTrackedLibPerformance = Object.assign({}, INITIAL_HAS_TRACKED_PERFORMANCE);
  }
  getPerformanceTracking(metricName) {
    return this.performanceTrackingMap[metricName];
  }
  trackLibPerformance({
    applicationId,
    objectTypeId,
    tracker
  }) {
    if (Object.values(this.hasTrackedLibPerformance).every(value => value === true)) {
      return;
    }
    this.getPerformanceSummary().forEach(performanceEntry => {
      if (this.hasTrackedLibPerformance[performanceEntry.name]) {
        return;
      } else {
        this.hasTrackedLibPerformance[performanceEntry.name] = true;
      }
      this.trackLibPerformanceAmplitude({
        applicationId,
        objectTypeId,
        tracker,
        performanceEntry
      });
      this.trackLibPerformanceGrafana({
        applicationId,
        objectTypeId,
        performanceEntry
      });
    });
  }
}
export default new ListingLibPerformanceTracking();