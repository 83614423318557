import { CONTACT_PIPELINE, CONTACT_LIFECYCLESTAGE, COMPANY_PIPELINE, COMPANY_LIFECYCLESTAGE, DEAL_PIPELINE, DEAL_STAGE, TICKET_PIPELINE, TICKET_PIPELINE_STAGE, PIPELINE, PIPELINE_STAGE } from '../../../v2/constants/AdditionalPropertyNames';
import { CONTACT_TYPE_ID, COMPANY_TYPE_ID, DEAL_TYPE_ID, TICKET_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
export const getPipelinePropertyName = objectTypeId => {
  if (objectTypeId === CONTACT_TYPE_ID) {
    return CONTACT_PIPELINE;
  } else if (objectTypeId === COMPANY_TYPE_ID) {
    return COMPANY_PIPELINE;
  } else if (objectTypeId === DEAL_TYPE_ID) {
    return DEAL_PIPELINE;
  } else if (objectTypeId === TICKET_TYPE_ID) {
    return TICKET_PIPELINE;
  } else {
    return PIPELINE;
  }
};
export const getPipelineStagePropertyName = objectTypeId => {
  if (objectTypeId === CONTACT_TYPE_ID) {
    return CONTACT_LIFECYCLESTAGE;
  } else if (objectTypeId === COMPANY_TYPE_ID) {
    return COMPANY_LIFECYCLESTAGE;
  } else if (objectTypeId === DEAL_TYPE_ID) {
    return DEAL_STAGE;
  } else if (objectTypeId === TICKET_TYPE_ID) {
    return TICKET_PIPELINE_STAGE;
  } else {
    return PIPELINE_STAGE;
  }
};

/**
 * Returns true if the given object type allows users to clear the pipeline/stage
 * properties by picking an empty stage.
 *
 * This is intentionally allowed for LCS stages (contact/company pipeline stages),
 * to preserve backwards compatibility with LCS behavior before it was implemented
 * as a pipeline/stage, but it is not allowed for deals or tickets.
 *
 * It's an open question whether custom object types should allow users to pick
 * an empty stage; for now we default to no, so that pipelineable custom objects
 * will behave like deals/tickets.
 *
 * NOTE: this behavior would be better specified as framework metadata on the
 * object type, rather than a hard-coded list of object types.
 */
export const allowsEmptyPipelineStage = objectTypeId => objectTypeId === CONTACT_TYPE_ID || objectTypeId === COMPANY_TYPE_ID;

/**
 * Returns true if the given object type allows users to create custom pipelines.
 * If not, then only one built-in HubSpot-defined pipeline can ever exist for
 * the object type, as with LCS for contact/company stages.
 *
 * If only one pipeline can ever exist, it's safe to show shortened stage labels
 * ("Stage" instead of "Stage (Pipeline)") when the current pipeline is unknown.
 */
export const allowsPortalSpecificPipelines = objectTypeId => objectTypeId !== CONTACT_TYPE_ID && objectTypeId !== COMPANY_TYPE_ID;