'use es6';

import { parse } from 'hub-http/helpers/params';
import { APP_PATHS } from 'EmailData/constants/app';
import { SET_COMPARISON_TOOL_RETURN_LOCATION } from 'email-management-lib/refactor/actions/actionTypes';
let queryString = window.location.search;
if (queryString.indexOf('?') === 0) {
  queryString = queryString.slice(1);
}
const {
  redirectTo
} = parse(queryString);
const initialState = redirectTo || APP_PATHS.MANAGE_TAB;
export default function comparisonToolReturnLocation(state = initialState, action) {
  switch (action.type) {
    case SET_COMPARISON_TOOL_RETURN_LOCATION:
      return action.payload.location;
    default:
      return state;
  }
}