'use es6';

import { createSelector } from 'reselect';
import { get } from 'EmailData/utils/localStorage';

// Utilises keys from: https://git.hubteam.com/HubSpot/COSEmail/blob/master/COSEmailModel/src/main/java/com/hubspot/cosemail/constants/FrontendDisabledFunctionality.java
const DISABLED_FUNCTIONALITY = Object.freeze({
  EMAIL_WRITE_DISABLED: 'EMAIL_WRITE_DISABLED',
  STATISTICS_DISABLED: 'STATISTICS_DISABLED'
});
const disabledFunctionalitySelector = state => state.getIn(['portalStatus', 'status', 'disabledFunctionality'], []);
export const activePortalMessagesSelector = state => state.getIn(['portalStatus', 'status', 'activePortalMessages'], []);
export const showStatisticsDisabledWarning = createSelector([disabledFunctionalitySelector], disabledFunctionality => {
  return disabledFunctionality.indexOf(DISABLED_FUNCTIONALITY.STATISTICS_DISABLED) > -1 || Boolean(get('DEBUG_EMAIL_STATISTICS_DISABLED'));
});
export const getIsProductReadOnly = createSelector([disabledFunctionalitySelector], disabledFunctionality => {
  return disabledFunctionality.indexOf(DISABLED_FUNCTIONALITY.EMAIL_WRITE_DISABLED) > -1 || Boolean(get('DEBUG_EMAIL_READ_ONLY_MODE'));
});