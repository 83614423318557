'use es6';

import { Record } from 'immutable';
import PORTAL_MIGRATION_STATUS from 'EmailData/constants/portalMigration';
import { RequestStatus } from 'EmailData/constants/requestStatus';
const defaults = {
  readOnlyStartDate: null,
  readOnlyEndDate: null,
  fromHublet: null,
  toHublet: null,
  statusCategory: PORTAL_MIGRATION_STATUS.NO_MIGRATION,
  requestStatus: RequestStatus.UNINITIALIZED
};
class PortalMigration extends Record(defaults) {
  static from(json) {
    return new PortalMigration(json);
  }
}
export default PortalMigration;