'use es6';

import { RequestStatus } from 'EmailData/constants/requestStatus';
import { RECEIVE_EARLY_MINIMAL_EMAILS, RECEIVE_EMAILS, REQUEST_EMAILS } from 'email-management-lib/refactor/actions/actionTypes';
const {
  UNINITIALIZED,
  PENDING,
  SUCCEEDED,
  FAILED
} = RequestStatus;
const initialState = UNINITIALIZED;
export default function fetchStatus(state = initialState, action) {
  switch (action.type) {
    case REQUEST_EMAILS:
      return PENDING;
    case RECEIVE_EMAILS:
      return action.error === true ? FAILED : SUCCEEDED;
    case RECEIVE_EARLY_MINIMAL_EMAILS:
      {
        return action.error !== true ? SUCCEEDED : PENDING;
      }
    default:
      return state;
  }
}