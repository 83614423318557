import get from '../../lib/get';
import indexBy from '../../lib/indexBy';
import { toObjectNotFoundError, toSearchFailedError } from '../../Errors';
import { filterReferences } from './filterReferences';

// The code in this file is only used in `createFetchAllReferenceResolver` so
// tests for it should be written in the test suite for that file.
const idGetter = get('id');
const getFromByIdCache = (cache, id) => {
  const reference = cache[id];
  return reference ? Promise.resolve(reference) : Promise.reject(toObjectNotFoundError(id));
};
export const applyDefaultFetchAllCache = ({
  all
}) => {
  let allCache = null;
  let byIdCache = null;
  const allWithCaching = () => {
    if (allCache) {
      return allCache;
    }
    allCache = all();
    return allCache;
  };
  const api = {
    byId: id => {
      if (byIdCache) {
        return getFromByIdCache(byIdCache, id);
      }
      return allWithCaching().then(references => {
        byIdCache = indexBy(idGetter)(references);
        return getFromByIdCache(byIdCache, id);
      });
    },
    refreshCache: id => {
      allCache = null;
      byIdCache = null;
      return api.byId(id);
    },
    search: searchQuery => allWithCaching().then(references => {
      const {
        count,
        offset,
        query
      } = searchQuery;
      if (typeof offset !== 'number' || offset < 0) {
        return Promise.reject(toSearchFailedError('FetchAllResolvers must be called with non-negative numeric offsets, non-numeric or negative offsets will result in pagination not working'));
      }
      const matchingReferences = filterReferences(references, query);
      const results = matchingReferences.slice(offset, offset + count);
      const newOffset = offset >= matchingReferences.length ? matchingReferences.length : offset + results.length;
      return {
        hasMore: matchingReferences.length > newOffset,
        offset: newOffset,
        references: results
      };
    })
  };
  return api;
};