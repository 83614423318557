export const FetchStatuses = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  RESOLVED: 'RESOLVED',
  REJECTED: 'REJECTED'
};
export const isIdle = state => {
  return state.status === FetchStatuses.IDLE;
};
export const isLoading = state => {
  return state.status === FetchStatuses.LOADING;
};
export const isResolved = state => {
  return state.status === FetchStatuses.RESOLVED;
};
export const isRejected = state => {
  return state.status === FetchStatuses.REJECTED;
};