const cacheMergePolicies = {
  CrmObject: {
    fields: {
      userPermissions: {
        merge: true
      }
    }
  },
  DefaultCrmObject: {
    fields: {
      defaultAssociations: {
        merge: true
      }
    }
  },
  Quote: {
    fields: {
      defaultProperties: {
        merge: true
      }
    }
  },
  Cart: {
    fields: {
      defaultProperties: {
        merge: true
      }
    }
  },
  Contact: {
    fields: {
      associations: {
        merge: true
      },
      defaultProperties: {
        merge: true
      }
    }
  },
  FeedbackSubmission: {
    fields: {
      defaultProperties: {
        merge: true
      }
    }
  },
  Engagement: {
    fields: {
      defaultProperties: {
        merge: true
      }
    }
  },
  Deal: {
    fields: {
      associations: {
        merge: true
      },
      defaultProperties: {
        merge: true
      }
    }
  },
  Company: {
    fields: {
      associations: {
        merge: true
      },
      defaultProperties: {
        merge: true
      }
    }
  },
  Ticket: {
    fields: {
      associations: {
        merge: true
      },
      defaultProperties: {
        merge: true
      },
      pipelineInfo: {
        merge: true
      }
    }
  },
  LineItem: {
    fields: {
      defaultProperties: {
        merge: true
      }
    }
  },
  Conversation: {
    fields: {
      associations: {
        merge: true
      },
      defaultProperties: {
        merge: true
      }
    }
  },
  ConversationInbox: {
    fields: {
      defaultProperties: {
        merge: true
      }
    }
  },
  QuoteModule: {
    fields: {
      defaultProperties: {
        merge: true
      }
    }
  },
  QuoteModuleField: {
    fields: {
      defaultProperties: {
        merge: true
      }
    }
  },
  QuoteTemplate: {
    fields: {
      defaultProperties: {
        merge: true
      }
    }
  },
  PaymentsSubscription: {
    fields: {
      defaultProperties: {
        merge: true
      }
    }
  },
  PermissionsTesting: {
    fields: {
      defaultProperties: {
        merge: true
      }
    }
  },
  CommercePayment: {
    fields: {
      defaultProperties: {
        merge: true
      }
    }
  },
  Invoice: {
    fields: {
      defaultProperties: {
        merge: true
      }
    }
  },
  Discount: {
    fields: {
      defaultProperties: {
        merge: true
      }
    }
  },
  Fee: {
    fields: {
      defaultProperties: {
        merge: true
      }
    }
  },
  Tax: {
    fields: {
      defaultProperties: {
        merge: true
      }
    }
  },
  DiscountTemplate: {
    fields: {
      defaultProperties: {
        merge: true
      }
    }
  },
  DiscountCode: {
    fields: {
      defaultProperties: {
        merge: true
      }
    }
  },
  PaymentLink: {
    fields: {
      defaultProperties: {
        merge: true
      }
    }
  },
  PaymentSchedule: {
    fields: {
      defaultProperties: {
        merge: true
      }
    }
  },
  PaymentScheduleInstallment: {
    fields: {
      defaultProperties: {
        merge: true
      }
    }
  },
  ListDefinition: {
    fields: {
      metadata: {
        merge: false
      }
    }
  },
  ViewerOperations: {
    merge: true
  },
  HomeCurrency: {
    merge: true
  },
  PropertyGroup: {
    fields: {
      allPropertyDefinitions: {
        // Always accept the latest set of allPropertyDefinitions that comes back from the server, the new and existing data can't
        // merged automatically as it is an array, this is also the default behavior for arrays we're just specifying to
        // Apollo that it's intentional.
        // For more info see https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-arrays
        merge: false
      }
    }
  },
  Order: {
    fields: {
      defaultProperties: {
        merge: false
      }
    }
  },
  PropertyValue: {
    merge: true
  }
};
export default cacheMergePolicies;