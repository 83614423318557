import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
export const fetchSubscriptionDefinitionsWithTranslations = businessUnitId => {
  const query = {
    includeInactive: false,
    businessUnitId
  };
  return http.get('subscriptions/v1/translations', {
    query
  });
};
export const fetchSmsSubscriptionRequest = () => {
  return http.get('subscriptions/v1/definitions/by-subscription-channel/SMS', {
    query: {
      portalId: PortalIdParser.get()
    }
  });
};