'use es6';

import { REQUEST_PORTAL_STATUS, RECEIVE_PORTAL_STATUS, REQUEST_PORTAL_DISMISS_STATUS, RECEIVE_PORTAL_DISMISS_STATUS, RECEIVE_UPDATE_PORTAL_DISMISS_STATUS, RECEIVE_SUSPENSION_APPEAL } from 'EmailData/actions/actionTypes';
import { SUSPENSION_APPEAL_STATUS } from 'EmailData/constants/portalSuspension';
import PortalDismissStatus from 'EmailData/models/PortalDismissStatus';
import PortalStatus from 'EmailData/models/PortalStatus';
const initialState = PortalStatus.from({});
export default function portalStatus(state = initialState, action) {
  switch (action.type) {
    case REQUEST_PORTAL_STATUS:
      return state.mergeDeep({
        loading: true
      });
    case RECEIVE_PORTAL_STATUS:
      if (action.error !== true) {
        return state.mergeDeep(PortalStatus.from(action.payload));
      }
      return state;
    case REQUEST_PORTAL_DISMISS_STATUS:
      return state.mergeDeep({
        dismissStatus: PortalDismissStatus.from({
          loading: true
        })
      });
    case RECEIVE_PORTAL_DISMISS_STATUS:
    case RECEIVE_UPDATE_PORTAL_DISMISS_STATUS:
      if (action.error !== true) {
        return state.mergeDeep({
          dismissStatus: PortalDismissStatus.from(action.payload)
        });
      }
      return state;
    case RECEIVE_SUSPENSION_APPEAL:
      if (action.error !== true) {
        const appeal = action.payload && {
          ticketId: action.payload.appealReference,
          state: action.payload.portalAppealState
        };
        return state.mergeDeepIn(['precogHealthStatus', 'remediationStatus'], {
          appeal
        });
      }
      return state.mergeDeep({
        precogHealthStatus: {
          appealState: SUSPENSION_APPEAL_STATUS.ERROR
        }
      });
    default:
      return state;
  }
}