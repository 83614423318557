import { getHttpClientAsLastParam } from 'EmailData/helpers/apiHelpers';
const BLOGS_URI = 'blog-settings/v1/blogs';
const BLOG_EMAILS_URI = 'cosemail/v1/emails/blog';
const BUSINESS_UNIT_BLOGS_URI = 'blog-settings/v1/blogs/business-unit';
export function getById(id, ...rest) {
  const http = getHttpClientAsLastParam(rest);
  return http.get(BLOGS_URI, {
    query: {
      id
    }
  });
}
export function get(userInput, ...rest) {
  const http = getHttpClientAsLastParam(rest);
  return http.get(BLOGS_URI, {
    query: {
      name__icontains: userInput
    }
  });
}
export function getEmailsForBlog(blogId, ...rest) {
  const http = getHttpClientAsLastParam(rest);
  return http.get(`${BLOG_EMAILS_URI}/${blogId}`);
}
export function getBusinessUnitBlogs(userInput, businessUnitId, ...rest) {
  const http = getHttpClientAsLastParam(rest);
  return http.get(`${BUSINESS_UNIT_BLOGS_URI}/${businessUnitId}`, {
    query: {
      name__icontains: userInput
    }
  });
}