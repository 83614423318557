import PortalIdParser from 'PortalIdParser';
export function isNegativeViewId(viewId) {
  return viewId.startsWith('-') || isNaN(parseInt(viewId, 10));
}

/**
 * Set view properties for "Default" views coming from outside of the lib.
 * @param objectType
 * @param view
 */
export function maybeMakeDefaultView(objectType, view) {
  if (isNegativeViewId(String(view.id))) {
    return Object.assign({}, view, {
      defaultColumns: view.columns,
      objectTypeId: objectType,
      ownerId: null,
      portalId: PortalIdParser.get(),
      private: false,
      teamId: null,
      type: 'DEFAULT'
    });
  }
  return view;
}

/**
 * The "idLabel" property should be used as the main view identifier everywhere.
 * When it is not defined, we'll fallback to the view "id".
 */
export function getViewId(view) {
  return view.idLabel || view.id;
}
export function findViewById(views, viewId) {
  return views.find(view => view.id === viewId || view.idLabel && view.idLabel === viewId);
}