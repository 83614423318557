import PortalIdParser from 'PortalIdParser';
import Raven from 'raven-js';
import { Metrics } from '../metrics/Metrics';
export function fetchAllPropertyValues(objectId, objectTypeId, httpClient) {
  return httpClient.get(`inbounddb-objects/v1/crm-objects/${encodeURIComponent(objectTypeId)}/${encodeURIComponent(objectId)}?includeAllValues=true`, {
    query: {
      portalId: PortalIdParser.get()
    }
  }).then(value => {
    Metrics.counter('fetchallpropertyvalues-success').increment();
    return value;
  }).catch(reason => {
    Metrics.counter('fetchallpropertyvalues-failure').increment();
    throw reason;
  });
}
export const fetchDecryptedHighlySensitivePropertyValue = ({
  objectTypeId,
  objectId,
  propertyName,
  timestamp,
  httpClient
}) => {
  return httpClient.get(`inbounddb-objects-sensitive/v1/decrypted/${encodeURIComponent(objectTypeId)}/${encodeURIComponent(objectId)}/${encodeURIComponent(propertyName)}`, timestamp ? {
    query: {
      timestamp
    }
  } : {}).then(response => {
    Metrics.counter('fetchdecryptedhighlysensitivepropertyvalue-success').increment();
    if (!response) {
      return '';
    }
    return response.value || '';
  }).catch(err => {
    Metrics.counter('fetchdecryptedhighlysensitivepropertyvalue-failure').increment();
    Raven.captureException(err, {
      extra: {
        apiError: 'DECRYPT_HIGHLY_SENSITIVE_PROPERTY_VALUE_FAILED',
        objectId,
        objectTypeId,
        propertyName
      }
    });
    throw err;
  });
};