import { useCallback, useMemo } from 'react';
import { UI_MODE } from 'framework-listing-lib/constants/uiMode';
import { useAppSettingsUIModes } from 'framework-listing-lib/internal/frameworkAppSettings/hooks/useFrameworkAppSettings';
import { useListing } from 'framework-listing-lib/internal/providers/ListingProvider';
export default function useUIMode() {
  const uiModes = useUIModes();
  const {
    uiModeProps: _uiModeProps
  } = useListing();
  return useMemo(() => {
    if (!_uiModeProps) {
      return {
        currentUIMode: UI_MODE.LIST
      };
    }
    const currentUIMode = _uiModeProps.currentUIMode && uiModes.includes(_uiModeProps.currentUIMode) ? _uiModeProps.currentUIMode : UI_MODE.LIST;
    return {
      currentUIMode,
      onChangeUIMode: _uiModeProps.onChangeUIMode
    };
  }, [_uiModeProps, uiModes]);
}
export function useOnChangeUIMode() {
  const currentUIMode = useCurrentUIMode();
  const uiModeProps = useUIMode();
  const uiModes = useUIModes();
  return useCallback(newUIMode => {
    if (!uiModeProps.onChangeUIMode) {
      return;
    }
    if (currentUIMode === newUIMode) {
      return;
    }
    uiModeProps.onChangeUIMode(newUIMode, {
      previousUIMode: currentUIMode,
      uiModes
    });
  }, [currentUIMode, uiModeProps, uiModes]);
}
export function useUIModes() {
  return useAppSettingsUIModes();
}
export function useCurrentUIMode() {
  const uiModeProps = useUIMode();
  return useMemo(() => uiModeProps.currentUIMode, [uiModeProps.currentUIMode]);
}
export function useIsFoldersMode() {
  const currentUIMode = useCurrentUIMode();
  return useMemo(() => currentUIMode === UI_MODE.FOLDERS, [currentUIMode]);
}
export function useIsGalleryMode() {
  const currentUIMode = useCurrentUIMode();
  return useMemo(() => currentUIMode === UI_MODE.GALLERY, [currentUIMode]);
}
export function useIsCalendarMode() {
  const currentUIMode = useCurrentUIMode();
  return useMemo(() => currentUIMode === UI_MODE.CALENDAR, [currentUIMode]);
}
export function useIsListMode() {
  const currentUIMode = useCurrentUIMode();
  return useMemo(() => currentUIMode === UI_MODE.LIST, [currentUIMode]);
}
export function useHasFoldersMode() {
  const uiModes = useUIModes();
  return useMemo(() => uiModes.includes(UI_MODE.FOLDERS), [uiModes]);
}