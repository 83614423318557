import { useCallback } from 'react';
import { HOVER_DATE_TIME_CELL, PROPERTY_VALIDATION_CHANGE, PROPERTY_VALUE_CHANGE, RESET_ALL, RESET_PROPERTY_VALUE } from 'framework-listing-lib/internal/FrameworkDataTable/constants/actionTypes';
import { useInlineEditDispatch, useInlineEditState } from 'framework-listing-lib/internal/FrameworkDataTable/providers/InlineEditProvider';
export function useOnPropertyValueChange() {
  const dispatch = useInlineEditDispatch();
  return useCallback(changes => {
    // The propertyInput objectId could be null because the component
    // can be used to create or bulk update objects.
    // In this case it should always be defined
    if (!changes.objectId) {
      return;
    }
    dispatch({
      type: PROPERTY_VALUE_CHANGE,
      payload: {
        changes: changes.values,
        objectId: changes.objectId
      }
    });
  }, [dispatch]);
}
export function useOnValidationChange() {
  const dispatch = useInlineEditDispatch();
  return useCallback((objectId, propertyName, changes) => dispatch({
    type: PROPERTY_VALIDATION_CHANGE,
    payload: {
      changes,
      objectId,
      propertyName
    }
  }), [dispatch]);
}
export function useOnResetPropertyValue() {
  const dispatch = useInlineEditDispatch();
  return useCallback((objectId, propertyName) => dispatch({
    type: RESET_PROPERTY_VALUE,
    payload: {
      objectId,
      propertyName
    }
  }), [dispatch]);
}
export function useOnResetAll() {
  const dispatch = useInlineEditDispatch();
  return useCallback(() => dispatch({
    type: RESET_ALL
  }), [dispatch]);
}
export function useGetInlineEditPropertyValue() {
  const state = useInlineEditState();
  return useCallback((objectId, propertyName, defaultValue) => {
    if (!(objectId in state.propertyChanges)) {
      return defaultValue;
    }
    if (!(propertyName in state.propertyChanges[objectId])) {
      return defaultValue;
    }
    return state.propertyChanges[objectId][propertyName];
  }, [state.propertyChanges]);
}
export function useGetPropertyValidation() {
  const state = useInlineEditState();
  return useCallback((objectId, propertyName) => {
    if (!(objectId in state.propertyValidationResult)) {
      return undefined;
    }
    if (!(propertyName in state.propertyValidationResult[objectId])) {
      return undefined;
    }
    return state.propertyValidationResult[objectId][propertyName];
  }, [state.propertyValidationResult]);
}
export function useGetIsRowInvalid() {
  const state = useInlineEditState();
  return useCallback(objectId => {
    if (!(objectId in state.propertyValidationResult)) {
      return false;
    }
    const rowValidationResult = state.propertyValidationResult[objectId];
    return Object.keys(rowValidationResult).some(propertyName => rowValidationResult[propertyName].invalid);
  }, [state.propertyValidationResult]);
}
export function useTotalChanges() {
  const state = useInlineEditState();
  return Object.keys(state.propertyChanges).reduce((acc, objectId) => {
    return acc + Object.keys(state.propertyChanges[objectId]).length;
  }, 0);
}
export function useIsSaveable() {
  const state = useInlineEditState();
  return Object.keys(state.propertyValidationResult).reduce((acc, objectId) => {
    const objectValidationResult = state.propertyValidationResult[objectId];
    return acc && Object.keys(objectValidationResult).every(propertyName => objectValidationResult[propertyName].saveable);
  }, true);
}
export function useGetChanges() {
  const state = useInlineEditState();
  return useCallback(() => state.propertyChanges, [state.propertyChanges]);
}
export function useGetHoverDateTimeCell() {
  const state = useInlineEditState();
  return state.dateTimeHoverCell;
}
export function useSetHoverDateTimeCell() {
  const dispatch = useInlineEditDispatch();
  return useCallback((objectId, propertyName) => dispatch({
    type: HOVER_DATE_TIME_CELL,
    payload: {
      objectId,
      propertyName
    }
  }), [dispatch]);
}