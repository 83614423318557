// @ts-expect-error not typed
import EmailDetailsFactory from 'EmailData/models/details/EmailDetailsFactory';
import { useQuery } from 'data-fetching-client';
import { useMemo } from 'react';
import { FETCH_EMAIL_DETAILS_REQUEST } from 'email-management-lib/refactor/queries/emailDetails';
function useEmailDetails({
  emailId,
  skip
}) {
  const {
    data,
    loading: isFetchingEmailDetails,
    error
  } = useQuery(FETCH_EMAIL_DETAILS_REQUEST, {
    variables: {
      emailId
    },
    skip
  });
  return useMemo(() => {
    return {
      emailDetails: data && data.emailDetails ? EmailDetailsFactory.fromDetails(data.emailDetails) : null,
      isFetchingEmailDetails,
      error
    };
  }, [data, error, isFetchingEmailDetails]);
}
export default useEmailDetails;