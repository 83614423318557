import reactUtilsKeyMirror from 'react-utils/keyMirror';
export function getObjectKeys(input) {
  return Object.keys(input);
}
export function getObjectEntries(input) {
  return Object.entries(input);
}
export function keyMirror(input) {
  return reactUtilsKeyMirror(input);
}
export const stripNullOrUndefinedValues = obj => getObjectKeys(obj).reduce((acc, key) => {
  if (obj[key] !== null && obj[key] !== undefined) {
    acc[key] = obj[key];
  }
  return acc;
}, {});

// TODO: Add better generic types for getObjectFromEntries, such that the
// return type is inferred from the entries.
export function getObjectFromEntries(entries) {
  return Object.fromEntries(entries);
}