import PortalIdParser from 'PortalIdParser';
export function validatePhoneNumber(phoneNumber, httpClient) {
  return httpClient.post(`calls/v1/phone-numbers/validate/`, {
    query: {
      portalId: PortalIdParser.get()
    },
    data: {
      phoneNumber
    }
  });
}