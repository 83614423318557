import { getFileAccess } from './utils';
const BASE_V3_URL = 'filemanager/api/v3/files';
export const CRM_PROPERTY_FILES_FOLDER = '/crm-properties-file-values';
const didAllUploadsFail = fileUploadResponses => !fileUploadResponses.some(fileUploadResponse => fileUploadResponse.status === 'fulfilled');
const buildFileUploadPromise = (file, progressCallback, successCallback, errorCallback, httpClient, fileVisibility, dataSensitivity) => {
  const formData = new FormData();
  formData.append('file', file);
  // Folders are portal specific. We are using a folder path to upload files.
  // If the folder does not exist, it gets created automatically.
  formData.append('folderPath', CRM_PROPERTY_FILES_FOLDER);
  formData.append('options', JSON.stringify({
    access: getFileAccess(fileVisibility, dataSensitivity)
  }));
  return httpClient.post(`${BASE_V3_URL}/upload`, {
    data: formData,
    headers: {
      'content-type': false
    },
    timeout: 0,
    withXhr: xhr => {
      xhr.upload.addEventListener('progress', e => progressCallback(e, file), false);
    }
  }).then(response => {
    const fileObject = response.objects[0];
    const fileReference = {
      id: fileObject.id,
      label: fileObject.name,
      additionalProperties: {
        fileId: fileObject.id,
        created: fileObject.created,
        size: fileObject.size,
        type: fileObject.type,
        updated: fileObject.updated
      }
    };
    successCallback(file, fileReference);
    return fileReference;
  }).catch(err => {
    const errorData = JSON.parse(err.data);
    errorCallback(file, errorData.errorType);
  });
};
export const uploadFiles = (files, httpClient, progressCallback, successCallback, errorCallback, fileVisibility, dataSensitivity) => {
  const fileUploadPromises = files.map(file => buildFileUploadPromise(file, progressCallback, successCallback, errorCallback, httpClient, fileVisibility, dataSensitivity));
  return Promise.allSettled(fileUploadPromises).then(fileUploadResponses => {
    if (didAllUploadsFail(fileUploadResponses)) {
      throw Error('No files were uploaded');
    }
    return fileUploadResponses.flatMap(fileUploadResponse => fileUploadResponse.status === 'fulfilled' && fileUploadResponse.value ? fileUploadResponse.value.id : []).join(';');
  }).catch(err => {
    throw err;
  });
};