import { useEffect, useState } from 'react';
import useCrmObjectTypeTracking from 'framework-listing-lib/internal/hooks/useCrmObjectTypeTracking';
import { useCurrentTypeDefinition } from 'framework-listing-lib/internal/hooks/useObjectTypeDefinitions';
import useQueryParams, { useRemoveQueryParams } from './useQueryParams';
import { parseColumns, parseFilterGroups, parseFilters } from '../utils';
import { COLUMNS_QUERY_PARAM, FILTERS_QUERY_PARAM, FILTER_GROUPS_QUERY_PARAM, QUICK_FILTERS_PARAM } from '../constants';
export default function useHandleQueryParams() {
  const [urlParams, setUrlParams] = useState(undefined);
  const queryParams = useQueryParams();
  const removeQueryParams = useRemoveQueryParams();
  const {
    trackCrmTableInteraction
  } = useCrmObjectTypeTracking();
  const currentTypeDefinition = useCurrentTypeDefinition();
  useEffect(() => {
    if (!currentTypeDefinition) {
      return;
    }

    /**
     * Ignore query params if they have already been recorded
     */
    if (urlParams) {
      return;
    }
    const columns = parseColumns(queryParams, currentTypeDefinition);
    const filterGroups = parseFilterGroups(queryParams);
    const filters = filterGroups.length === 0 ? parseFilters(queryParams, FILTERS_QUERY_PARAM) : [];
    const quickFilters = parseFilters(queryParams, QUICK_FILTERS_PARAM);
    if (filterGroups.length === 0 && filters.length === 0 && quickFilters.length === 0 && columns.length === 0) {
      return;
    }
    trackCrmTableInteraction({
      action: 'used-deep-link',
      numberOfFilters: filters.length,
      numberOfProperties: columns.length,
      totalFilterGroups: filterGroups.length,
      totalQuickFilters: quickFilters.length
    });
    setUrlParams({
      columns,
      filterGroups,
      // use @filters **only** when @filterGroups is not defined
      filters: filterGroups.length === 0 ? filters : [],
      quickFilters
    });
    removeQueryParams([FILTER_GROUPS_QUERY_PARAM, FILTERS_QUERY_PARAM, QUICK_FILTERS_PARAM, COLUMNS_QUERY_PARAM]);
  }, [currentTypeDefinition, queryParams, removeQueryParams, trackCrmTableInteraction, urlParams]);
  return urlParams;
}