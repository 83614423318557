module.exports = {
  "toolAccessInteraction": {
    "name": "tool-access-interaction",
    "class": "interaction",
    "properties": {
      "app": "string",
      "interaction": "string"
    },
    "namespace": "tool-access"
  },
  "toolAccessRequestCreated": {
    "name": "tool-access-request-created",
    "class": "usage",
    "properties": {
      "app": "string",
      "role": "string",
      "value": "string"
    },
    "namespace": "tool-access"
  }
};