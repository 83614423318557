export const ContentStates = Object.freeze({
  PUBLISHED: 'PUBLISHED',
  PUBLISHED_OR_SCHEDULED: 'PUBLISHED_OR_SCHEDULED',
  DRAFT: 'DRAFT',
  SCHEDULED: 'SCHEDULED',
  ARCHIVED: 'ARCHIVED',
  PUBLISHED_AB: 'PUBLISHED_AB',
  PUBLISHED_AB_VARIANT: 'PUBLISHED_AB_VARIANT',
  LOSER_AB_VARIANT: 'LOSER_AB_VARIANT',
  DRAFT_AB: 'DRAFT_AB',
  DRAFT_AB_VARIANT: 'DRAFT_AB_VARIANT',
  SCHEDULED_AB: 'SCHEDULED_AB',
  PROCESSING: 'PROCESSING',
  PRE_PROCESSING: 'PRE_PROCESSING',
  ERROR: 'ERROR',
  BLOG_EMAIL_DRAFT: 'BLOG_EMAIL_DRAFT',
  BLOG_EMAIL_PUBLISHED: 'BLOG_EMAIL_PUBLISHED',
  AUTOMATED: 'AUTOMATED',
  AUTOMATED_LEADFLOW_EMAIL: 'AUTOMATED_LEADFLOW_EMAIL',
  AUTOMATED_FOR_FORM: 'AUTOMATED_FOR_FORM',
  AUTOMATED_FOR_FORM_LEGACY: 'AUTOMATED_FOR_FORM_LEGACY',
  AUTOMATED_DRAFT: 'AUTOMATED_DRAFT',
  AUTOMATED_DRAFT_ABVARIANT: 'AUTOMATED_DRAFT_ABVARIANT',
  AUTOMATED_DRAFT_AB: 'AUTOMATED_DRAFT_AB',
  AUTOMATED_AB: 'AUTOMATED_AB',
  AUTOMATED_AB_VARIANT: 'AUTOMATED_AB_VARIANT',
  AUTOMATED_LOSER_ABVARIANT: 'AUTOMATED_LOSER_ABVARIANT',
  AUTOMATED_FOR_FORM_DRAFT: 'AUTOMATED_FOR_FORM_DRAFT',
  RSS_TO_EMAIL_DRAFT: 'RSS_TO_EMAIL_DRAFT',
  RSS_TO_EMAIL_PUBLISHED: 'RSS_TO_EMAIL_PUBLISHED',
  PENDING_AKISMET: 'PENDING_AKISMET',
  SPAM: 'SPAM',
  PENDING_MODERATION: 'PENDING_MODERATION',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED'
});