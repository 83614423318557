import get from 'transmute/get';
import toJS from 'transmute/toJS';
import { OWNER } from 'customer-data-objects/property/ExternalOptionTypes';

// @ts-expect-error: no types
import OwnerRecord from 'customer-data-objects/owners/OwnerRecord';

// Adapted from `customer-data-table/static/js/resolverSerializer.js`
const resolverSerializer = (ReferenceRecord, objectType) => {
  if (!ReferenceRecord) {
    return null;
  }

  // TODO(ts): migrate these types once customer-data-objects is fully typed.
  const referencedObject = get('referencedObject', ReferenceRecord);
  switch (objectType) {
    case OWNER:
      {
        const newRecord = Object.assign({}, toJS(referencedObject), toJS(ReferenceRecord));
        return OwnerRecord(newRecord);
      }
    default:
      return Object.assign({}, toJS(referencedObject));
  }
};
export default resolverSerializer;