import ContentRoutes from 'ContentUtils/Routes';
import PortalIdParser from 'PortalIdParser';
// @ts-expect-error not migrated to TS yet

const HYPHEN_SEPARATOR = '-';
const COLON_SEPARATOR = ':';
const UNDERSCORE_SEPARATOR = '_';
export const EMAIL_SOURCE_APP_ID = 'MARKETING_EMAIL_AUTOMATED';
export const WorkflowType = {
  FORMS: 'FORMS',
  ADS: 'ADS',
  WORKFLOWS: 'WORKFLOWS'
};
const marketingEmailAutomatedDirectUrl = ({
  emailId
}) => `/email/${PortalIdParser.get()}/edit/${emailId}/automation`;
const formsAppDirectURL = ({
  formGuid
}) => `/forms/${PortalIdParser.get()}/editor/${formGuid}/edit/automation`;
const pagesAppDirectURL = ({
  pageId
}) => `/pages/${PortalIdParser.get()}/editor/${pageId}/content`;
const feedbackAppDirectURL = ({
  feedbackSurveyId,
  surveyRoute
}) => {
  if (surveyRoute === 'custom') {
    return `/feedback-editor/${PortalIdParser.get()}/custom/${feedbackSurveyId}/email`;
  }
  return `/feedback-editor/${PortalIdParser.get()}/${surveyRoute}/${feedbackSurveyId}/automation`;
};
const journeyAppDirectURL = ({
  journeyId
}) => {
  return `/journeys/${PortalIdParser.get()}/journey/${journeyId}`;
};
export const editContentRoute = {
  [WorkflowType.WORKFLOWS]: workflow => {
    const {
      flowId = workflow.id
    } = workflow;
    let workflowId;
    let source;
    if (typeof workflow.asImmutable === 'function') {
      workflowId = workflow.get('flowId') || workflow.get('id');
      source = workflow.get('flowSource') || workflow.get('workflowType');
    } else {
      workflowId = workflow.id || workflow.flowId;
      source = workflow.sourceApp || workflow.flowSource;
    }
    return ContentRoutes.workflows(flowId || workflowId, source);
  },
  [WorkflowType.FORMS]: form => {
    const {
      guid
    } = form;
    return formsAppDirectURL({
      formGuid: guid
    });
  }
};
export const idKeyByWorkflowType = {
  [WorkflowType.WORKFLOWS]: 'flowId',
  [WorkflowType.FORMS]: 'guid'
};
const DIRECT_URL_BUILDERS = {
  FORMS_APP: formsAppDirectURL,
  MARKETING_EMAIL_AUTOMATED: marketingEmailAutomatedDirectUrl,
  FEEDBACK: feedbackAppDirectURL,
  CMS_FORM_MODULE_FIELD_FOLLOWUP_EMAIL: pagesAppDirectURL,
  JOURNEY_STAGE: journeyAppDirectURL
};
const formsAppValueParser = (uuid = '') => {
  const [, formGuid] = uuid.split(COLON_SEPARATOR);
  if (formGuid) {
    return {
      formGuid
    };
  }
  return null;
};
const pagesAppValueParser = (uuid = '') => {
  const [,,,,,, pageId] = uuid.split(COLON_SEPARATOR);
  if (pageId) {
    return {
      pageId
    };
  }
  return null;
};

// uuid format: <portalId>-<emailId>-<emailEventTypes>
const marketingEmailAutomatedValueParser = (uuid = '') => {
  const [__portalId, emailId, __emailEventTypes] = uuid.split(HYPHEN_SEPARATOR);
  if (emailId) {
    return {
      emailId
    };
  }
  return null;
};

// uuid format: sureveyName-surveyType-feedbackSurveyId
export const feedbackValueParser = (uuid = '') => {
  const [_surveyType, __surveyName, feedbackSurveyId] = uuid.split(UNDERSCORE_SEPARATOR);
  let surveyRoute = '';
  switch (_surveyType) {
    case 'ces':
      surveyRoute = 'support';
      break;
    case 'csat':
      surveyRoute = 'satisfaction';
      break;
    case 'nps':
      surveyRoute = 'loyalty';
      break;
    case 'custom':
      surveyRoute = 'custom';
      break;
    default:
  }
  if (surveyRoute) {
    return {
      feedbackSurveyId,
      surveyRoute
    };
  }
  return null;
};

// uuid format: /(JOURNEY)-(PORTAL_ID: \d*)-(OBJECT_TYPE_ID: 0-158)-(JOURNEY_ID: \d*)-(TIMESTAMP: \d*)/;
export const journeyUUIDParser = (uuid = '') => {
  var _journeyUuidRegex$exe;
  const journeyUuidRegex = /(JOURNEY)-(\d*)-(0-158)-(\d*)-(\d*)/;
  const [match,,,, journeyId] = (_journeyUuidRegex$exe = journeyUuidRegex.exec(uuid)) !== null && _journeyUuidRegex$exe !== void 0 ? _journeyUuidRegex$exe : [];
  if (match) {
    return {
      journeyId
    };
  }
  return null;
};
const PATH_VALUE_PARSERS = {
  FORMS_APP: formsAppValueParser,
  MARKETING_EMAIL_AUTOMATED: marketingEmailAutomatedValueParser,
  FEEDBACK: feedbackValueParser,
  CMS_FORM_MODULE_FIELD_FOLLOWUP_EMAIL: pagesAppValueParser,
  JOURNEY_STAGE: journeyUUIDParser
};
function buildDirectURL(valueMap) {
  if (!valueMap) {
    return null;
  }
  if ('formGuid' in valueMap) {
    return formsAppDirectURL(valueMap);
  }
  if ('emailId' in valueMap) {
    return marketingEmailAutomatedDirectUrl(valueMap);
  }
  if ('feedbackSurveyId' in valueMap) {
    return feedbackAppDirectURL(valueMap);
  }
  if ('pageId' in valueMap) {
    return pagesAppDirectURL(valueMap);
  }
  if ('journeyId' in valueMap) {
    return journeyAppDirectURL(valueMap);
  }
  return null;
}
export const getWorkflowEditUrlForSourceApp = workflow => {
  let uuid;
  let sourceApp;
  if (typeof workflow.asImmutable === 'function') {
    uuid = workflow.get('uuid');
    sourceApp = workflow.get('sourceApp');
  } else {
    uuid = workflow.uuid;
    sourceApp = workflow.sourceApp;
  }
  if (uuid && DIRECT_URL_BUILDERS[sourceApp]) {
    const parser = PATH_VALUE_PARSERS[sourceApp];
    if (buildDirectURL && parser) {
      const directURL = buildDirectURL(parser(uuid));
      if (directURL) {
        return directURL;
      }
    }
  }
  return editContentRoute[WorkflowType.WORKFLOWS](workflow);
};