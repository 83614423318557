import { PRODUCT_TYPE_ID, SUBSCRIPTION_TYPE_ID, COMMERCE_PAYMENT_TYPE_ID, PAYMENT_LINK_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
const FULL_PRECISION_OBJECT_TYPE_IDS = new Set([COMMERCE_PAYMENT_TYPE_ID, SUBSCRIPTION_TYPE_ID, PAYMENT_LINK_TYPE_ID, PRODUCT_TYPE_ID]);

// All properties for commerce objects (hubspot-defined as well as custom) should
// always be shown in full precision, because they are used for accounting purposes
// See https://hubspot.slack.com/archives/C0301NK85TP/p1646854298845709
// https://git.hubteam.com/HubSpot/Merchant-Experience-Team/issues/134
// and https://git.hubteam.com/HubSpot/PropertiesFrontendTeam/issues/397
export default function isFullPrecisionCurrencyRequired(objectTypeId) {
  return FULL_PRECISION_OBJECT_TYPE_IDS.has(objectTypeId);
}