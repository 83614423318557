import { CREATE_VIEW_MODAL_STRATEGIES } from '../components/CreateViewModal';
export const getLangStringsForStrategy = ({
  strategy
}) => {
  if (strategy === CREATE_VIEW_MODAL_STRATEGIES.CREATE_NEW) {
    return {
      modalTitle: 'viewsManagement.modals.createViewModal.title',
      confirmButton: 'viewsManagement.modals.createViewModal.confirm',
      cancelButton: 'viewsManagement.modals.createViewModal.cancel',
      errors: {
        emptyName: 'viewsManagement.modals.createViewModal.nameInput.empty',
        duplicateName: 'viewsManagement.modals.createViewModal.nameInput.duplicate',
        atOrAboveLimit: 'viewsManagement.modals.createViewModal.viewLimits.atOrAboveLimit',
        fetchError: 'viewsManagement.modals.createViewModal.errors.fetchError',
        createError: 'viewsManagement.modals.createViewModal.errors.createError'
      }
    };
  }
  if (strategy === CREATE_VIEW_MODAL_STRATEGIES.SAVE_AS_NEW) {
    return {
      modalTitle: 'viewsManagement.modals.saveAsNewViewModal.title',
      confirmButton: 'viewsManagement.modals.saveAsNewViewModal.confirm',
      cancelButton: 'viewsManagement.modals.saveAsNewViewModal.cancel',
      errors: {
        emptyName: 'viewsManagement.modals.saveAsNewViewModal.nameInput.empty',
        duplicateName: 'viewsManagement.modals.saveAsNewViewModal.nameInput.duplicate',
        atOrAboveLimit: 'viewsManagement.modals.saveAsNewViewModal.viewLimits.atOrAboveLimit',
        fetchError: 'viewsManagement.modals.saveAsNewViewModal.errors.fetchError',
        createError: 'viewsManagement.modals.saveAsNewViewModal.errors.createError'
      }
    };
  }
  if (strategy === CREATE_VIEW_MODAL_STRATEGIES.CLONE_EXISTING) {
    return {
      modalTitle: 'viewsManagement.modals.cloneViewModal.title',
      confirmButton: 'viewsManagement.modals.cloneViewModal.confirm',
      cancelButton: 'viewsManagement.modals.cloneViewModal.cancel',
      errors: {
        emptyName: 'viewsManagement.modals.cloneViewModal.nameInput.empty',
        duplicateName: 'viewsManagement.modals.cloneViewModal.nameInput.duplicate',
        atOrAboveLimit: 'viewsManagement.modals.cloneViewModal.viewLimits.atOrAboveLimit',
        fetchError: 'viewsManagement.modals.cloneViewModal.errors.fetchError',
        createError: 'viewsManagement.modals.cloneViewModal.errors.createError'
      }
    };
  }
  throw new Error('strategy unknown');
};