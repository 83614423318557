import { registerQuery } from 'data-fetching-client';
import { getEmailDetails } from 'EmailData/api/emails';
export const FETCH_EMAIL_DETAILS_REQUEST = registerQuery({
  fieldName: 'emailDetails',
  args: ['emailId'],
  fetcher({
    emailId
  }) {
    return getEmailDetails(emailId);
  }
});