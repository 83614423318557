import PortalIdParser from 'PortalIdParser';
export const ALL_BUSINESS_UNITS = 'ALL';
export const DEFAULT_ACCOUNT_BUSINESS_UNIT_ID = 0;
export default function getBUFromLocalStorage() {
  let lsValue = '';
  try {
    lsValue = String(localStorage.getItem('SELECTED_BUSINESS_UNIT') || '{}');
  } catch (_unused) {
    lsValue = '{}';
  }
  let parsedValue = {};
  try {
    parsedValue = JSON.parse(lsValue);
  } catch (_unused2) {
    parsedValue = {};
  }

  // legacy number value
  if (typeof parsedValue === 'number') {
    return String(parsedValue);
  }
  // @ts-expect-error legacy ALL value
  if (parsedValue === 'ALL') {
    return 'ALL';
  }
  const currentPortalId = PortalIdParser.get();
  if (currentPortalId && currentPortalId in parsedValue) {
    return String(parsedValue[currentPortalId]).replaceAll('"', '');
  }
  return DEFAULT_ACCOUNT_BUSINESS_UNIT_ID.toString();
}