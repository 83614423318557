import http from 'hub-http/clients/apiClient';
import { readEarlyRequest } from 'EmailData/utils/earlyRequest';
import { HubUserAttributesClient } from 'frontend-preferences-client';
const hubUserAttributesClient = HubUserAttributesClient.forCaller('EmailData');
const URI_STATUS = 'cosemail/v1/portal/status';
const URI_SEND_CREDITS = 'email/v1/portalstatus/sendcredits';
const URI_REOPEN_TICKET = 'precog/public/v1/portal-remediation/reopen-appeal';
const URI_SUSPENSION_APPEAL = '/vetting/public/v1/account-verification';
const TRIALS_URI = 'trials/v1/hub-trials/app/active';
export const PORTAL_STATUS_NOTIFICATION = 'MarketingEmail:portal-status-notification:';
export default {
  fetch() {
    return readEarlyRequest({
      key: 'portal-status',
      fetchCallback: () => http.get(URI_STATUS)
    });
  },
  fetchDismissStatus() {
    return hubUserAttributesClient.fetch(PORTAL_STATUS_NOTIFICATION, JSON.stringify({
      dismissed: false,
      level: 'DEFAULT',
      timestamp: 0
    }));
  },
  fetchSendCredits() {
    return http.get(URI_SEND_CREDITS);
  },
  fetchPortalTrials() {
    return http.get(TRIALS_URI);
  },
  sendSuspensionAppeal(data) {
    return http.post(URI_SUSPENSION_APPEAL, {
      data: {
        formData: data
      }
    });
  },
  updateDismissStatus(value) {
    return hubUserAttributesClient.write(PORTAL_STATUS_NOTIFICATION, JSON.stringify(value));
  },
  reopenAppealTicket(ticketId) {
    return http.put(`${URI_REOPEN_TICKET}/${ticketId}`);
  }
};