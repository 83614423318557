import { useCallback } from 'react';
import { fetchPropertyExtensionsMetadata } from '../propertyExtensionsMetadata/api';
import { useAsyncFunction } from '../../utils/hooks/useAsyncFunction';
import { useHttpClient } from '../../client/HttpClientContext';
export const usePropertyExtensionsMetadata = (objectTypeId, options = {
  skip: false
}) => {
  const httpClient = useHttpClient();
  const getPropertyExtensionsMetadata = useCallback(() => {
    return fetchPropertyExtensionsMetadata(objectTypeId, httpClient);
  }, [httpClient, objectTypeId]);
  return useAsyncFunction(getPropertyExtensionsMetadata, options);
};