'use es6';

import { DELETE_PORTAL_SETTING, RECEIVE_PORTAL_SETTINGS, RECEIVE_TRACKING_CONFIG, UPDATE_PORTAL_SETTINGS, FETCH_DATA_PRIVACY_SETTINGS_SUCCESS } from 'EmailData/portalSettings/actions/types';
import PortalSettings from 'EmailData/portalSettings/models/PortalSettings';
import TrackingConfiguration from 'EmailData/portalSettings/models/TrackingConfiguration';
import PortalIdParser from 'PortalIdParser';
const initialState = PortalSettings.from({});
export default function portalSettings(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_PORTAL_SETTINGS:
      if (action.error !== true) {
        const data = [];
        const hubId = PortalIdParser.get();
        Object.entries(action.payload).forEach(([key, value]) => {
          if (value !== '') {
            data.push({
              hubId,
              key,
              value
            });
          }
        });
        if (state.getIsUsingCache()) {
          return state.mergeCachedChangesWithPayload(data);
        }
        return PortalSettings.from(Object.assign({
          loaded: true
        }, {
          settings: data
        }));
      }
      return state;
    case RECEIVE_TRACKING_CONFIG:
      return state.updateOtherSettings({
        tracking: TrackingConfiguration.from(action.payload)
      });
    case UPDATE_PORTAL_SETTINGS:
      return state.updateSetting(action.settingKey, action.settingValue);
    case DELETE_PORTAL_SETTING:
      return state.deleteSetting(action.settingKey);
    case FETCH_DATA_PRIVACY_SETTINGS_SUCCESS:
      {
        const {
          gdprToggleEnabled,
          legalBasisRequirementEnabled
        } = action.payload;
        state = state.updateSetting('gdprToggleEnabled', String(gdprToggleEnabled));
        return state.updateSetting('legalBasisRequirementEnabled', String(legalBasisRequirementEnabled));
      }
    default:
      return state;
  }
}