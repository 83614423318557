'use es6';

import { FETCH_RSS_URL_FEED_VALIDATION_START, FETCH_RSS_URL_FEED_VALIDATION_SUCCESS, FETCH_RSS_URL_FEED_VALIDATION_FAIL, RESET_RSS_FEED_URL_VALIDATION } from 'EmailData/actions/actionTypes';
import RssFeedValidation from 'EmailData/data/models/RssFeedValidation';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
const initialState = RssFeedValidation.from({});
const rssFeedUrlValidationReducer = (state = initialState, {
  type,
  response,
  feedUrl
}) => {
  switch (type) {
    case FETCH_RSS_URL_FEED_VALIDATION_START:
      return RssFeedValidation.from({
        status: ''
      });
    case FETCH_RSS_URL_FEED_VALIDATION_SUCCESS:
      return state.merge(Object.assign({}, response));
    case FETCH_RSS_URL_FEED_VALIDATION_FAIL:
      return RssFeedValidation.from({
        feedResultStatus: 'TIMEOUT',
        feedUrl
      });
    case RESET_RSS_FEED_URL_VALIDATION:
      return initialState;
    default:
      return state;
  }
};
export default asyncResource(rssFeedUrlValidationReducer, {
  requestActions: [FETCH_RSS_URL_FEED_VALIDATION_START],
  responseActions: [FETCH_RSS_URL_FEED_VALIDATION_SUCCESS, FETCH_RSS_URL_FEED_VALIDATION_FAIL],
  invalidatingActions: [RESET_RSS_FEED_URL_VALIDATION]
});