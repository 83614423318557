import quickFetch from 'quick-fetch';
export function makeQuickFetchRequest(options) {
  const {
    makeRequestFn,
    requestName
  } = options;
  const earlyRequest = quickFetch.getRequestStateByName(requestName);
  if (!earlyRequest) {
    return {
      requestPromise: makeRequestFn(),
      usedQuickfetch: false
    };
  }
  const requestPromise = new Promise((resolve, reject) => {
    earlyRequest.whenFinished(result => {
      resolve(result);
      quickFetch.removeEarlyRequest(requestName);
    });
    earlyRequest.onError(() => {
      makeRequestFn().then(resolve, reject).catch(error => {
        console.error(error);
      });
      quickFetch.removeEarlyRequest(requestName);
    });
  });
  return {
    requestPromise,
    usedQuickfetch: true
  };
}