import { HAS_ASSIGNABLE_OVERRIDE, HAS_UNASSIGNABLE_OVERRIDE } from 'ui-addon-upgrades/_core/utils/commMethodOverrides';
import { COMM_METHODS_MODAL_TYPES } from './constants';
import { track } from 'ui-addon-upgrades/_core/common/eventTracking/tracker';
import { getRepName, getUpgradeDataTrackingProperties } from 'ui-addon-upgrades/ums/utils';
// returns ASSIGNABLE/UNASSIGNABLE overrides (if any)
// else returns original isAssignable value. Can be boolean or undefined
export const overrideAssignable = isAssignable => {
  if (HAS_ASSIGNABLE_OVERRIDE) return true;else if (HAS_UNASSIGNABLE_OVERRIDE) return false;else return isAssignable;
};
export const sequence = (...handlers) => {
  return (...args) => {
    handlers.forEach(handler => handler && handler(...args));
  };
};
export const trackContactSalesButtonView = props => {
  const {
    additionalUnitQuantity,
    isAssignable,
    isUpgradePoint,
    level,
    subscreen,
    termId,
    upgradeData,
    usageLimitBannerData
  } = props;
  track('contactSalesButtonInteraction', Object.assign({
    action: 'viewed'
  }, getUpgradeDataTrackingProperties(upgradeData), {
    termId,
    additionalUnitQuantity,
    subscreen
  }));
  if (isUpgradePoint) {
    track('interaction', Object.assign({
      action: 'viewed talk to sales',
      isAssignable,
      isPrimaryCta: level === 1,
      isSecondaryCta: level === 2
    }, usageLimitBannerData, getUpgradeDataTrackingProperties(upgradeData)));
  }
};
const checkIsAssignable = ({
  isAssignable,
  isRetailPortal,
  repInfo
}) => {
  return !!(isAssignable && !isRetailPortal && repInfo && repInfo.link);
};
export const determineCommModal = ({
  repInfo,
  isRetailPortal,
  isAssignable
}) => {
  const isAssignableClick = checkIsAssignable({
    isAssignable,
    isRetailPortal,
    repInfo
  });
  if (isAssignableClick) {
    return COMM_METHODS_MODAL_TYPES.ASSIGNABLE;
  } else {
    return COMM_METHODS_MODAL_TYPES.UNASSIGNABLE;
  }
};
export const trackContactSalesButtonClick = (props, isRetailPortal, repInfo) => {
  const {
    additionalUnitQuantity,
    isAssignable,
    isUpgradePoint,
    level,
    subscreen,
    termId,
    upgradeData,
    usageLimitBannerData
  } = props;
  const isAssignableClick = checkIsAssignable({
    isAssignable,
    isRetailPortal,
    repInfo
  });
  track('contactSalesButtonInteraction', Object.assign({
    action: isAssignableClick ? 'clicked' : 'clicked talk to ISC'
  }, getUpgradeDataTrackingProperties(upgradeData), {
    termId,
    additionalUnitQuantity,
    subscreen,
    repName: getRepName(repInfo)
  }));
  if (isUpgradePoint) {
    track('interaction', Object.assign({
      action: 'clicked upgrade point',
      isAssignable
    }, usageLimitBannerData, getUpgradeDataTrackingProperties(upgradeData)));
    track('interaction', Object.assign({
      action: 'clicked talk to sales',
      isAssignable,
      isPrimaryCta: level === 1,
      isSecondaryCta: level === 2
    }, usageLimitBannerData, getUpgradeDataTrackingProperties(upgradeData)));
  }
};
export const trackCommModalClose = (upgradeData, repInfo) => {
  track('communicationMethodsInteraction', Object.assign({
    action: 'closed'
  }, getUpgradeDataTrackingProperties(upgradeData), {
    repName: getRepName(repInfo)
  }));
};