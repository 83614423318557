import { useSelector } from 'react-redux';
import { getInsightPolarity } from 'messaging-publishing-lib/Insights/utils';
import { getIsUngatedForAiInsights } from 'email-management-lib/refactor/selectors/user';
import { useInsightContext } from 'messaging-publishing-lib/Insights/components/InsightContextProviderBase';
export const useInsightPolarity = ({
  crmObject,
  propertyName
}) => {
  const isUngatedForAiInsights = useSelector(getIsUngatedForAiInsights);
  const {
    context
  } = useInsightContext();
  if (!isUngatedForAiInsights) return null;
  const maybeInsightPolarity = getInsightPolarity({
    crmObject,
    propertyName,
    context
  });
  return maybeInsightPolarity;
};