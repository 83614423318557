/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */

'use es6';

import debounce from 'react-utils/debounce';
import { fetchTeams } from 'ContentData/actions/Teams';
import { SAVE_CURRENT_DASHBOARD_URL_STATE, UNSELECT_EMAILS, UPDATE_SEARCH_TERM, REQUEST_EMAILS } from 'email-management-lib/refactor/actions/actionTypes';
import { isPathActive } from 'EmailComponents/utils/urlUtils';
import { trackDashboardInteraction } from 'EmailComponents/utils/tracking';
import { initParams, updateQueryParams } from 'email-management-lib/refactor/actions/params';
import { fetchEmails } from 'email-management-lib/refactor/actions/manage/general';
import { currentParamsSelector, currentQuerySelector } from 'EmailData/selectors/params';
import { parseQueryParams } from 'EmailData/utils/url';
import { getHasCosEmailTrialGate, getHasEmailAccess, getHasEmailEditorAccess, getHasPermissionsToLoadDashboard, getHasAccessToSeamlessPortalMigration, getHasUserTeamsAssignment } from 'EmailData/selectors/user';
import { fetchPortalMigrationStatus } from 'EmailData/actions/portalMigrationStatusActions';
import { fetchPortalStatus } from 'EmailData/actions/portalStatusActions';
import { fetchPortalTrials } from 'EmailData/actions';
import { fetchPortalDismissStatus } from 'EmailData/portalStatus/actions';
import { shouldNavigateRefresh } from 'EmailComponents/utils/urlUtils';
export function fetchTeamsByUser() {
  return (dispatch, getState) => {
    const state = getState();
    const isTeamAssignment = getHasUserTeamsAssignment(state);
    dispatch(fetchTeams(isTeamAssignment));
  };
}
function debouncedFetchEmailFunc() {
  return debounce(dispatch => dispatch(fetchEmails()), 300);
}
const fetchEmailsOnSearch = (dispatch, search) => {
  if (!isPathActive('/analyze')) {
    trackDashboardInteraction({
      otherActions: 'search'
    });
    dispatch(updateQueryParams({
      search
    }, debouncedFetchEmailFunc));
  }
};
export function clearEmailSearch() {
  return dispatch => {
    dispatch(searchForTerm(''));
  };
}
export function searchForTerm(term) {
  return dispatch => {
    dispatch({
      type: UPDATE_SEARCH_TERM,
      payload: {
        term
      }
    });
    dispatch({
      type: UNSELECT_EMAILS
    });
    dispatch({
      type: REQUEST_EMAILS
    });
    const search = term === '' ? undefined : term;
    fetchEmailsOnSearch(dispatch, search);
  };
}
export function saveCurrentDashboardUrlState() {
  return (dispatch, getState) => {
    dispatch({
      type: SAVE_CURRENT_DASHBOARD_URL_STATE,
      payload: {
        pathParams: currentParamsSelector(getState()),
        queryParams: currentQuerySelector(getState())
      }
    });
  };
}
export function maybeFetchOnNavigation(historyAction, newLocationSearch, oldLocationSearch, newPathParams, oldPathParams, fetchFunc) {
  return dispatch => {
    if (shouldNavigateRefresh(historyAction, newLocationSearch, oldLocationSearch, newPathParams, oldPathParams)) {
      dispatch(initParams(parseQueryParams(newLocationSearch), newPathParams, fetchFunc));
    }
  };
}
export function fetchStatus(authConfig) {
  return (dispatch, getState) => {
    const state = getState();
    if (!getHasEmailAccess(state) && !getHasEmailEditorAccess(state)) {
      return;
    }
    if (!authConfig.portal.trial || getHasCosEmailTrialGate(state)) {
      dispatch(fetchPortalStatus());
    }
    dispatch(fetchPortalTrials());
    dispatch(fetchPortalDismissStatus());
  };
}
export function delayedSetupActions(authConfig) {
  return (dispatch, getState) => {
    const state = getState();
    if (getHasPermissionsToLoadDashboard(state)) {
      dispatch(fetchStatus(authConfig));
      if (getHasAccessToSeamlessPortalMigration(state)) {
        dispatch(fetchPortalMigrationStatus());
      }
    }
  };
}