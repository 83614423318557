import { ENUMERATION } from 'customer-data-objects/property/PropertyTypes';
//@ts-expect-error Missing types
import { TEAM } from 'reference-resolvers/constants/ReferenceObjectTypes';
//@ts-expect-error Missing types
import FieldDefinitionRecord from 'customer-data-filters/filterQueryFormat/fieldDefinitions/FieldDefinitionRecord';
import { OrderedSet } from 'immutable';
// @ts-expect-error not typed yet
import * as Operators from 'customer-data-filters/filterQueryFormat/operator/Operators';
const OPERATORS = OrderedSet.of(Operators.In, Operators.NotIn, Operators.Known, Operators.NotKnown);
const TeamFilter = () => FieldDefinitionRecord({
  inputType: ENUMERATION,
  referencedObjectType: TEAM,
  operators: OPERATORS
});
export default TeamFilter;