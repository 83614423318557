'use es6';

import { List, Record } from 'immutable';
const defaults = {
  count: 0,
  limit: 100,
  offset: 0,
  results: List([]),
  isSearchView: false,
  searchTerm: ''
};
class RecipientsList extends Record(defaults) {
  static from(json) {
    if (typeof json.results !== 'undefined') {
      json.results = List(json.results);
    }
    return new RecipientsList(json);
  }
}
export default RecipientsList;