import { useQuery } from 'data-fetching-client';
import { FETCH_TOP_SPOTLIGHTS } from 'messaging-publishing-lib/Insights/queries';
import { isSupportedSpotlight } from '../utils/support';
export const filterUnsupportedData = response => {
  const topNegativeSpotlights = response.topNegativeSpotlights.filter(isSupportedSpotlight);
  const topPositiveSpotlights = response.topPositiveSpotlights.filter(isSupportedSpotlight);
  return Object.assign({}, response, {
    topNegativeSpotlights,
    topPositiveSpotlights,
    negativeSpotlightCount: topNegativeSpotlights.length,
    positiveSpotlightCount: topPositiveSpotlights.length
  });
};
const useFetchTopSpotlights = variables => {
  const {
    data,
    loading: fetchAllInsightLoading,
    error: fetchAllInsightError,
    refetch
  } = useQuery(FETCH_TOP_SPOTLIGHTS, {
    variables
  });
  return {
    topSpotlights: data ? filterUnsupportedData(data.topInsights) : undefined,
    loading: fetchAllInsightLoading,
    error: !!fetchAllInsightError,
    refetch
  };
};
export default useFetchTopSpotlights;