import { Record } from 'immutable';
import BounceQualifiers from './qualifiers/BounceQualifiers';
import DropQualifiers from './qualifiers/DropQualifiers';
const defaults = {
  bounce_category: BounceQualifiers.from({}),
  drop_reason: DropQualifiers.from({})
};
class StatisticsQualifiers extends Record(defaults) {
  static from({
    bounce_category,
    drop_reason
  }) {
    return new StatisticsQualifiers({
      bounce_category: BounceQualifiers.from(bounce_category || {}),
      drop_reason: DropQualifiers.from(drop_reason || {})
    });
  }

  // @ts-expect-error mergeWith types for base class do not match
  mergeWith(merger, otherQualifiers = StatisticsQualifiers.from({})) {
    const mergedBounces = this.bounce_category.mergeWith(merger, otherQualifiers.bounce_category);
    const mergedDrops = this.drop_reason.mergeWith(merger, otherQualifiers.drop_reason);
    return new StatisticsQualifiers({
      bounce_category: mergedBounces,
      drop_reason: mergedDrops
    });
  }
}
export default StatisticsQualifiers;