import getScopedProducts from 'ui-addon-upgrades/_core/common/adapters/getScopedProducts';
import { getIsProdAcceptanceTest } from '../../../utils/getIsProdAcceptanceTest';
import { trackFailedApiNameMap } from '../../../utils/metricUtils';
import { productUpgradeInterest } from '../upgradeData/properties/productUpgradeInterest';
import { getLegalConsentOptions } from './legalConsentOptions';
const isPhoneNumber = dataFormOptions => {
  return 'phoneNumber' in dataFormOptions;
};
const isEmail = dataFormOptions => {
  return 'emailSubject' in dataFormOptions && 'emailBody' in dataFormOptions;
};
export const getFormattedDate = timestamp => {
  const currentDate = new Date(timestamp);
  const date = currentDate.getDate();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();
  const formattedDate = date < 10 ? `0${date}` : date;
  const formattedMonth = month < 10 ? `0${month}` : month;
  return `${formattedMonth}/${formattedDate}/${year}`;
};
const getFieldDataFromOptions = options => {
  const fieldData = [];
  if (isPhoneNumber(options)) {
    fieldData.push({
      name: 'pql_phone_number_submitted',
      value: true
    }, {
      name: 'phone',
      value: options.phoneNumber
    });
  } else {
    fieldData.push({
      name: 'pql_phone_number_submitted',
      value: false
    });
  }

  // Email fields
  if (isEmail(options)) {
    fieldData.push({
      name: 'pql_message_submitted',
      value: true
    }, {
      name: 'pql_message_type',
      value: options.emailSubject
    }, {
      name: 'pql_message_content',
      value: options.emailBody
    });
  } else {
    fieldData.push({
      name: 'pql_message_submitted',
      value: false
    });
  }
  return fieldData;
};
const getCommMethodExp = pqlData => {
  // will not have these fields when not coming from a Talk to Sales PQL.
  if (!('isRetail' in pqlData) && !('isAssignable' in pqlData)) {
    return 'Not Applicable';
  }
  if (pqlData.isRetail) {
    return 'Retail';
  }
  if (pqlData.isAssignable) {
    return 'Assignable';
  }
  return 'Unassignable';
};
const getProductUpgradeInterest = pqlData => {
  const {
    upgradeProduct,
    apiName
  } = pqlData;
  if (apiName && productUpgradeInterest[apiName]) {
    return productUpgradeInterest[apiName];
  }
  if (upgradeProduct && productUpgradeInterest[upgradeProduct]) {
    return productUpgradeInterest[upgradeProduct];
  }
  trackFailedApiNameMap('getProductUpgradeInterest', apiName || 'no api name', upgradeProduct || 'no upgrade product');
  return 'General';
};

// Using an email address that ends in +skipform results in the form not actually submitting
// data, but resolves successfully. We want to do this for prod acceptance tests to
// prevent polluting 53 data.
export const PROD_ACCEPTANCE_TEST_EMAIL = 'acceptance-test+skipform@hubspot.com';
export const _getEmail = userEmail => {
  if (getIsProdAcceptanceTest()) {
    return PROD_ACCEPTANCE_TEST_EMAIL;
  } else {
    return userEmail;
  }
};

/**
 * @param {Object}
 * @return {String}
 */
export const createPqlFormData = ({
  metaData,
  pqlData,
  user,
  portal,
  options = {}
}) => {
  const portalScopes = getScopedProducts(portal, user);
  const optionsFieldData = getFieldDataFromOptions(options);
  const commMethod = getCommMethodExp(pqlData);
  const formattedDate = getFormattedDate(metaData.timestamp);
  const email = _getEmail(user.email);
  const fieldData = [{
    name: 'email',
    value: email
  }, {
    name: 'firstname',
    value: user.first_name
  }, {
    name: 'lastname',
    value: user.last_name
  }, {
    name: 'crm_hub_id',
    value: portal.portal_id
  }, {
    name: 'pql_conversion_date',
    value: formattedDate
  }, {
    name: 'pql_app',
    value: pqlData.app
  }, {
    name: 'pql_source',
    value: pqlData.uniqueId
  }, {
    name: 'product_signup',
    value: portalScopes.length > 0 ? portalScopes.join(', ') : ''
  }, {
    name: 'product_upgrade_interest',
    value: getProductUpgradeInterest(pqlData)
  }, {
    name: 'comm_method_experience',
    value: commMethod
  }, ...optionsFieldData];
  const pqlFormData = {
    fields: fieldData,
    skipValidation: true,
    legalConsentOptions: getLegalConsentOptions()
  };
  return pqlFormData;
};