import { getHubAndTier, getHubAndTierFromApiName } from 'ui-addon-upgrades/ums/utils';
const sortByTier = upgradeProducts => {
  if (!upgradeProducts || upgradeProducts.length === 1) {
    return upgradeProducts;
  }
  const productObjects = upgradeProducts.map(product => getHubAndTier(product));

  // by chance our tier order matches A-Z sort
  // this will NOT sort by hub A-Z within tiers
  const sortedProductObjects = productObjects.sort((a, b) => a.tier.localeCompare(b.tier));

  // rebuild the upgradeProduct string array from the objects
  return sortedProductObjects.map(product => {
    return `${product.hub}-${product.tier}`;
  });
};
const sortWeights = {
  free: 0,
  basic: 1,
  starter: 2,
  professional: 3,
  enterprise: 4
};
export const sortByTierFromApiName = apiNames => {
  if (!apiNames || apiNames.length === 1) {
    return apiNames;
  }
  const productObjectMap = {};
  const productObjects = apiNames.map(apiName => {
    const obj = getHubAndTierFromApiName(apiName);

    //we can't rebuild these later so store them to be looked up instead
    productObjectMap[`key-${obj.hub}-${obj.tier}`] = apiName;
    return obj;
  });

  // sort by tier based on sortWeights
  // this will NOT sort by hub A-Z within tiers
  const sortedProductObjects = productObjects.sort((a, b) => {
    const aWeight = sortWeights[a.tier];
    const bWeight = sortWeights[b.tier];
    if (aWeight > bWeight) {
      return -1;
    } else if (bWeight < aWeight) {
      return 1;
    } else {
      return 0;
    }
  });

  // look up the apiName string array from the stored values
  return sortedProductObjects.map(apiName => {
    return productObjectMap[`key-${apiName.hub}-${apiName.tier}`];
  });
};
export default sortByTier;