'use es6';

import { createSelector } from 'reselect';
import { getIsUngatedForRegularAutomation } from 'EmailData/selectors/user';
import currentEmail from 'EmailData/selectors/details/current/currentEmail';
const automationForRegularEmailsCheck = (email, isUngatedForRegularAutomation) => (email.isBatch() || email.isLocalTime() || email.isABEmail() || email.isScheduled()) && isUngatedForRegularAutomation;
export const getCanLoadAutomationByEmail = email => createSelector([getIsUngatedForRegularAutomation], isUngatedForRegularAutomation => {
  return email.isAvailableToWorkflows() || automationForRegularEmailsCheck(email, isUngatedForRegularAutomation);
});
export const getCanUseAutomationOnRegularEmails = createSelector([currentEmail, getIsUngatedForRegularAutomation], automationForRegularEmailsCheck);
export const getCanLoadAutomation = createSelector([currentEmail, getCanUseAutomationOnRegularEmails], (email, canUseAutomationOnRegularEmails) => {
  return email.isAvailableToWorkflows() || canUseAutomationOnRegularEmails;
});