import { useMemo } from 'react';
import { READ_ONLY } from 'customer-data-objects/property/AccessLevel';
import { isReadOnly } from 'customer-data-objects/property/PropertyIdentifier';
import { useIsReadOnlySubscriptionProperty } from './useIsReadOnlySubscriptionProperty';
import { useIsReadOnlyLeadOwnerProperty } from './useIsReadOnlyLeadOwnerProperty';
export const useIsPropertyReadOnly = (objectTypeId, propertyDefinition, accessLevel, readOnly) => {
  const isReadOnlySubscriptionProperty = useIsReadOnlySubscriptionProperty(objectTypeId, propertyDefinition);
  const isReadOnlyLeadOwnerProperty = useIsReadOnlyLeadOwnerProperty(objectTypeId, propertyDefinition);
  return useMemo(() => {
    if (accessLevel === READ_ONLY || Boolean(readOnly) || isReadOnlySubscriptionProperty || isReadOnlyLeadOwnerProperty) {
      return true;
    }
    return isReadOnly(propertyDefinition);
  }, [accessLevel, readOnly, isReadOnlySubscriptionProperty, isReadOnlyLeadOwnerProperty, propertyDefinition]);
};