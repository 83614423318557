'use es6';

import { Record } from 'immutable';
const defaults = {
  id: null,
  name: null,
  isInstalled: true,
  integrationStatus: null,
  integrationStatusError: null
};
class InstalledApp extends Record(defaults) {
  constructor(args = defaults) {
    super(Object.assign({}, args, {
      isInstalled: true
    }));
  }
}
export default InstalledApp;