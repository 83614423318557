'use es6';

import { List, Map as ImmutableMap } from 'immutable';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { FETCH_SIMPLE_WORKFLOWS, FETCH_SIMPLE_WORKFLOWS_REQUEST } from 'email-management-lib/refactor/actions/actionTypes';
import Workflow from 'EmailData/models/Workflow';
const initialState = ImmutableMap({});
function simpleWorkflows(state = initialState, action) {
  switch (action.type) {
    case FETCH_SIMPLE_WORKFLOWS:
      if (action.error !== true) {
        return state.set(action.payload.emailContentId, List(action.payload.simpleWorkflows.results.map(swf => Workflow.from(Object.assign({
          id: swf.flowId,
          name: swf.name,
          enabled: swf.isEnabled,
          sourceApp: swf.createdSourceApp,
          updatedBy: swf.updatedUserId,
          showLinksAsExternal: false
        }, swf)))));
      }
      return state;
    default:
      return state;
  }
}
export default asyncResource(simpleWorkflows, {
  requestActions: [FETCH_SIMPLE_WORKFLOWS_REQUEST],
  responseActions: [FETCH_SIMPLE_WORKFLOWS]
});