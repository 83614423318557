import { ApolloLink, Observable } from '@apollo/client';
import Raven from 'raven-js';
export default new ApolloLink((operation, forward) => new Observable(observer => {
  const sub = forward(operation).subscribe({
    next(result) {
      observer.next(result);
    },
    error(err) {
      const {
        operationName,
        variables
      } = operation;
      const context = operation.getContext();
      const correlationId = context.correlationId;
      const isMutation = operation.query.definitions && operation.query.definitions.some(definition => definition.kind === 'OperationDefinition' && definition.operation === 'mutation');
      if (err.errorCode === 'TIMEOUT') {
        Raven.captureMessage('hubspot-apollo-client: graphQLTimeout', {
          extra: {
            errorCode: err.errorCode,
            operationName,
            isMutation,
            correlationId,
            // only include variables if query is not a mutation.
            // Mutations can contain PII/GDPR-sensitive data
            variables: isMutation ? 'mutation' : JSON.stringify(variables)
          }
        });
        console.error('graphQLTimeout', {
          operationName
        });
      } else {
        Raven.captureMessage('hubspot-apollo-client: graphQLError', {
          extra: {
            errorCode: err.errorCode,
            operationName,
            isMutation,
            correlationId
          }
        });
      }
      observer.error(err);
    },
    complete() {
      observer.complete();
    }
  });
  return () => {
    if (sub) {
      sub.unsubscribe();
    }
  };
}));