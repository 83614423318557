import PortalIdParser from 'PortalIdParser';
import http from 'hub-http/clients/apiClient';
import { Map as ImmutableMap, fromJS } from 'immutable';
import Folder from 'email-management-lib/refactor/models/Folder';
const URI = 'content/api/v4/folders';
function parse(folder) {
  return Folder.from(folder);
}
export function parseAllFolders(data, parentFolderId = 0) {
  return fromJS(data.childFolders.reduce((folders, folder) => {
    const {
      id,
      name,
      items
    } = folder;
    const mappedChildren = parseAllFolders(folder, id);
    return folders.set(id, parse({
      id,
      name,
      items,
      count: items.length,
      childFolders: mappedChildren,
      parentFolderId
    }));
  }, ImmutableMap()).sortBy(_folder => _folder.get('name') ? _folder.get('name').toLocaleLowerCase() : 0));
}
export function fetchFolderTree() {
  return http.get('cosemail/v1/emails/folders/tree', {
    query: {
      portalId: PortalIdParser.get(),
      limit: 5000
    }
  }).then(parseAllFolders);
}
export function createFolderRequest(name, parentFolderId = 0) {
  return http.post(URI, {
    data: {
      name,
      parentFolderId,
      category: 2
    }
  }).then(parse);
}
export function destroyFolderRequest(folderId) {
  return http.delete(`${URI}/${folderId}`);
}
export function renameFolderRequest(folderId, name) {
  return http.put(`${URI}/${folderId}`, {
    data: {
      name
    }
  });
}
export function moveFolderRequest(folderId, parentFolderId) {
  return http.put(`${URI}/${folderId}`, {
    data: {
      parentFolderId
    }
  });
}