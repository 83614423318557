import { RESET_EMAIL_DETAILS } from 'EmailData/actions/actionTypes';
import { SET_LAGGING_STATS_TIMED_OUT } from 'email-management-lib/refactor/actions/actionTypes';
const initialState = false;
export default function hasLaggedStatsTimedOut(state = initialState, action) {
  switch (action.type) {
    case SET_LAGGING_STATS_TIMED_OUT:
      return true;
    case RESET_EMAIL_DETAILS:
      return initialState;
    default:
      return state;
  }
}