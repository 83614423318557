'use es6';

import { Map as ImmutableMap } from 'immutable';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { FETCH_HUB_USERS, FETCH_HUB_USERS_REQUEST } from 'email-management-lib/refactor/actions/actionTypes';
import HubUser from 'email-management-lib/refactor/models/HubUser';
const initialState = ImmutableMap({});
function hubUsers(state = initialState, action) {
  switch (action.type) {
    case FETCH_HUB_USERS:
      if (action.error !== true) {
        return ImmutableMap(action.payload.hubUsers.map(hubUser => [hubUser.id, HubUser.from(hubUser)]));
      }
      return state;
    default:
      return state;
  }
}
export default asyncResource(hubUsers, {
  requestActions: [FETCH_HUB_USERS_REQUEST],
  responseActions: [FETCH_HUB_USERS]
});