export const campaignFields = {
  DISPLAY_NAME: 'display_name',
  OWNER: 'owner',
  STARTED_AT: 'startedAt',
  ENDED_AT: 'endedAt',
  COLOR_HEX: 'colorHex',
  GOAL: 'goal',
  AUDIENCE: 'audience',
  BUDGET: 'actualBudget',
  NOTES: 'notes',
  BUSINESS_UNITS_INFO: 'businessUnitsInfo',
  CURRENCY_CODE: 'currencyCode',
  NUMERIC_BUDGET: 'numericBudget',
  SPEND_AMOUNT: 'spendAmount'
};