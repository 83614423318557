'use es6';

import { Map as ImmutableMap } from 'immutable';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { FETCH_CAMPAIGN_STATUS, FETCH_CAMPAIGN_STATUS_REQUEST } from 'email-management-lib/refactor/actions/actionTypes';
import CampaignStatus from 'email-management-lib/refactor/models/campaignStatus/CampaignStatus';
const initialState = ImmutableMap();
function campaignStatuses(state = initialState, action) {
  switch (action.type) {
    case FETCH_CAMPAIGN_STATUS:
      if (action.error !== true) {
        const newStatuses = Object.keys(action.payload).reduce((newState, key) => {
          return newState.set(parseInt(key, 10), CampaignStatus.from(action.payload[key]));
        }, ImmutableMap({}));
        return state.mergeDeep(newStatuses);
      }
      return state;
    default:
      return state;
  }
}
export default asyncResource(campaignStatuses, {
  requestActions: [FETCH_CAMPAIGN_STATUS_REQUEST],
  responseActions: [FETCH_CAMPAIGN_STATUS],
  invalidatingActions: []
});