'use es6';

import { BULK_EMAIL_SELECTION, CLEAR_BULK_EMAIL_SELECTION, SELECT_STATE, UPDATE_SEARCH_TERM } from 'email-management-lib/refactor/actions/actionTypes';
const initialState = false;
export default function bulkSelection(state = initialState, action) {
  switch (action.type) {
    case BULK_EMAIL_SELECTION:
      return true;
    case CLEAR_BULK_EMAIL_SELECTION:
    case SELECT_STATE:
    case UPDATE_SEARCH_TERM:
      return false;
    default:
      return state;
  }
}