'use es6';

import BaseEmailDetail from 'EmailData/models/details/BaseEmailDetail';
const followupDefaults = {};
class FollowUpEmailDetail extends BaseEmailDetail(Object.assign({}, followupDefaults)) {
  static from(json) {
    const response = super.from(json);
    return new FollowUpEmailDetail(response);
  }
}
export default FollowUpEmailDetail;