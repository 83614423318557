/**
 * CampaignRecord
 */

export { default as CampaignRecord, CAMPAIGN_DEFAULT_VALUES } from './data/CampaignRecord';
/**
 * CampaignSelect
 */
export { default as CampaignSelect } from './components/CampaignSelect';

/**
 * CampaignFilter & CampaignFilterFormControl
 */
export { default as CampaignFilter } from './components/campaignSelect/CampaignFilter';
export { default as CampaignFilterFormControl } from './components/campaignSelect/CampaignFilterFormControl';

/**
 * Campaign create/edit (side)panel
 */
import { campaignFields } from './constants/campaignFieldsForEditing';
export const CampaignPanelFields = campaignFields;
export default 42;