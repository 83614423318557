import unescapedText from 'I18n/utils/unescapedText';

/**
 * This file is copied from reporting to avoid having a dep on reporting-data,
 * in generally doesn't change but here's a link to the original file just in
 * case we need to keep things in sync
 * https://git.hubteam.com/HubSpot/reporting/blob/master/reporting-data/static/js/lib/guids.ts
 */

/**
 * Known GUID constants keyed by name
 *
 * @constants
 * @private
 */
const guidToLangKey = {
  nobreakdown: 'NO_BREAKDOWN',
  'c90e6907-e895-479c-8689-0d22fa660677': 'NO_DETAIL_GUID',
  '30434570-5795-496c-b0ae-8d2dc9053483': 'EMPTY_LIST_GUID',
  '5ca1ab1e-fb50-4e89-9cff-a000ba5eba11': 'UNKNOWN',
  '9bae4a1c-0f1c-11e1-9afb-58b0357d1cb3': 'UNKNOWN_CAMPAIGN_GUID',
  'bd185678-735d-4c78-a673-d9528d37a4a6': 'UNKNOWN_COUNTRY_GUID',
  'c1d25565-f664-4089-9f0a-1734cc1b3a65': 'UNKNOWN_KEYWORD_GUID',
  '1ca35c16-25e9-49f1-bfcb-960300c42b6f': 'UNKNOWN_REFERRER_GUID',
  '1d3f1024-9c84-4955-8a30-a84fd506915b': 'UNKNOWN_DEVICE_GUID',
  '1b7853ea-92b0-483f-a480-9c8595259731': 'UNKNOWN_DEVICE_OS_GUID',
  '5636aafb-35ed-4881-bd4c-3fa6eb95a194': 'UNKNOWN_DEVICE_MARKET_NAME',
  '7081c5b2-d128-4ec1-a9be-cba29cfc540a': 'UNKNOWN_GEO_GUID',
  'f67178c8-9eba-4f66-a560-c02206160915': 'UNKNOWN_BROWSER_VERSION',
  'e3875d32-ab81-48f1-9e78-493eae864f12': 'UNKNOWN_URL',
  'Unknown keywords (SSL)': 'SSL_REMOVED_KEYWORDS',
  'Unknown keywords (image preview)': 'LYCOS_IMAGE_KEYWORD_PLACEHOLDER',
  'Auto-tagged PPC': 'PPC_GCLID_BUCKET',
  EMAIL_INTEGRATION: 'EMAIL_INTEGRATION',
  BCC_TO_CRM: 'BCC_TO_CRM',
  GMAIL_INTEGRATION: 'GMAIL_INTEGRATION'
};
/**
 * Check whether GUID is known
 *
 * @param {string} guid GUID to check
 * @returns {boolean} Whether GUID is known
 */
export const isKnownGuid = guid => Object.prototype.hasOwnProperty.call(guidToLangKey, guid);

/**
 * Get humanized label from GUID
 *
 * @param {string} guid GUID to humanize
 * @returns {string} Label if known otherwise original value
 */
export const getGuidLabel = guid => isKnownGuid(guid) ? unescapedText(`customerDataObjects.guids.${guidToLangKey[guid]}`) : guid;