'use es6';

import { Map as ImmutableMap } from 'immutable';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { RESET_EMAIL_DETAILS } from 'EmailData/actions/actionTypes';
import { DETAILS_MARK_STATISTICS_DIRTY, FETCH_USER_INTERACTION_STATISTICS, FETCH_USER_INTERACTION_STATISTICS_REQUEST, SELECT_TIME_RANGE } from 'email-management-lib/refactor/actions/actionTypes';
import StatisticsUAEngagement from 'email-management-lib/refactor/models/stats/StatisticsUAEngagement';
const initialState = ImmutableMap({
  clicked: StatisticsUAEngagement.from([]),
  opened: StatisticsUAEngagement.from([])
});
function userAgentStatistics(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_INTERACTION_STATISTICS:
      {
        if (action.error === true) {
          return state;
        }
        return ImmutableMap({
          opened: StatisticsUAEngagement.from(action.payload.openedUAStatistics),
          clicked: StatisticsUAEngagement.from(action.payload.clickedUAStatistics)
        });
      }
    case RESET_EMAIL_DETAILS:
      return initialState;
    default:
      return state;
  }
}
export default asyncResource(userAgentStatistics, {
  requestActions: [FETCH_USER_INTERACTION_STATISTICS_REQUEST],
  responseActions: [FETCH_USER_INTERACTION_STATISTICS],
  invalidatingActions: [RESET_EMAIL_DETAILS, SELECT_TIME_RANGE, DETAILS_MARK_STATISTICS_DIRTY]
});