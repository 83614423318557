import PortalIdParser from 'PortalIdParser';
import { useState } from 'react';
const USER_HID_BANNER_LOCAL_STORAGE_KEY = 'hs-suspension-banner-hide_';
const DEFAULT_VALUE = {};
const getKeyName = portalId => USER_HID_BANNER_LOCAL_STORAGE_KEY + portalId;
const useUserHidden = () => {
  const userPortalHideKey = getKeyName(PortalIdParser.get() || 0);
  const [userHidBanner, setUserHidBanner] = useState(() => {
    try {
      const rawVal = window.localStorage.getItem(userPortalHideKey);
      if (!rawVal) {
        throw new Error('Stored value not present');
      }
      const storedValue = JSON.parse(rawVal);
      if (!storedValue || typeof storedValue !== 'object') {
        throw new Error('Stored value not an object');
      }
      return storedValue;
    } catch (ex) {
      return DEFAULT_VALUE;
    }
  });
  const setBannerHiddenByUserFor = scope => value => {
    setUserHidBanner(prev => Object.assign({}, prev, {
      [scope]: value
    }));
    const storedValue = JSON.stringify(Object.assign({}, userHidBanner, {
      [scope]: value
    }));
    try {
      localStorage.setItem(userPortalHideKey, storedValue);
    } catch (_ex) {
      //noop
    }
  };
  const bannerHiddenByUserFor = scope => userHidBanner[scope] || false;
  return [bannerHiddenByUserFor, setBannerHiddenByUserFor];
};
export default useUserHidden;