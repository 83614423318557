'use es6';

import { Record, Map as ImmutableMap } from 'immutable';
const ReviewObjectRecord = Record({
  reviewState: undefined,
  slaCopyType: undefined
});
export const BulkContent = Record({
  contentType: 'content',
  reviewList: ReviewObjectRecord()
}, 'BulkContent');
export const BulkContentReview = (data, keyWord) => {
  const filteredContent = ImmutableMap(data).filter(object => object.reviewState === 'IN_REVIEW' || object.reviewState === 'BLOCKED').map(reviewObject => ReviewObjectRecord(reviewObject));
  return BulkContent({
    reviewList: filteredContent,
    contentType: keyWord
  });
};