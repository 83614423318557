import Raven from 'raven-js';
import PortalIdParser from 'PortalIdParser';
import apiClient from 'hub-http/clients/apiClient';
const portalId = `${PortalIdParser.get()}`;
const COACHING_TIPS_ENDPOINT_URL = '/coaching-tips/v1/status';
const requestPayload = {
  query: {
    portalId
  }
};
const fetchStatus = (coachingTipId, http = apiClient) => http.get(`${COACHING_TIPS_ENDPOINT_URL}/${coachingTipId}`, requestPayload);
export const getCoachingTipsDismissed = (coachingTipId, http = apiClient) => fetchStatus(coachingTipId, http).then(({
  dismissed
}) => dismissed).catch(error => {
  // Creating new error object to generate error stack
  // like mentioned here https://product.hubteam.com/docs/frontend/docs/sentry.html#sentry-source-routing-faqs
  // The API error doesn't actually have a stack trace that can be identified as ui-coaching-tips
  Raven.captureException(new Error(error.message));
  return true;
});
export const setCoachingTipsDismissed = (coachingTipId, http = apiClient) => http.post(`${COACHING_TIPS_ENDPOINT_URL}`, Object.assign({}, requestPayload, {
  data: {
    coachingTipId
  }
})).catch(error => {
  // Creating new error object to generate error stack
  // like mentioned here https://product.hubteam.com/docs/frontend/docs/sentry.html#sentry-source-routing-faqs
  // The API error doesn't actually have a stack trace that can be identified as ui-coaching-tips
  Raven.captureException(new Error(error.message));
});
export const restoreCoachingTips = (coachingTipId, http = apiClient) => http.delete(`${COACHING_TIPS_ENDPOINT_URL}/${coachingTipId}`, requestPayload);