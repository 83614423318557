'use es6';

import noAuthClient from './clients/noAuthApiClient';
import { hubletApi } from './middlewares/core';
const hubspot = hubletApi('api', 'hubspot');
const refreshCookieForApi = (portalId, api, withNoCacheHeader) => {
  const headers = {
    'X-Force-Cookie-Refresh': true
  };
  if (withNoCacheHeader) {
    headers['X-Force-Cookie-Refresh-No-Cache'] = true;
  }
  const query = portalId ? {
    portalId
  } : {};
  const path = `/login-api/v1/cookie-refresh${portalId ? '' : '/hubless'}`;
  return noAuthClient.get(path, {
    api,
    headers,
    withCredentials: true,
    query
  });
};
export const refreshCookie = (portalId, withNoCacheHeader = false) => {
  return refreshCookieForApi(portalId, hubspot, withNoCacheHeader);
};