import { useCallback, useEffect, useState } from 'react';
import useCalculateMaxHeight from './useCalculateMaxHeight';

/**
 * Keeps the DOM ref in state for live updates.
 * Useful when moving between UI modes and to avoid the
 * table from using a stale DOM ref.
 */
function useLiveRef() {
  const [refValue, setRef] = useState(null);
  const refCallback = useCallback(node => {
    setRef(node);
  }, []);
  return [refValue, refCallback];
}
function useTableWidth({
  elementRef
}) {
  const [width, setWidth] = useState(0);
  useEffect(() => {
    const offsetTopObserver = new ResizeObserver(() => {
      if (!elementRef) {
        return;
      }
      const {
        width: boundingRectWidth
      } = elementRef.getBoundingClientRect();
      if (width === boundingRectWidth) {
        return;
      }
      setWidth(boundingRectWidth);
    });
    if (elementRef) {
      offsetTopObserver.observe(elementRef);
    }
    return () => offsetTopObserver.disconnect();
  }, [elementRef, width]);
  return width;
}
export default function useTableDimensions({
  heightAffordances,
  maxHeight
}) {
  const [tableWrapperRef, refCallback] = useLiveRef();
  const tableHeight = useCalculateMaxHeight({
    elementRef: tableWrapperRef,
    initial: `${maxHeight}`,
    heightAffordances
  });
  const tableWidth = useTableWidth({
    elementRef: tableWrapperRef
  });
  return {
    height: tableHeight,
    width: tableWidth,
    tableRef: tableWrapperRef,
    refCallback
  };
}