'use es6';

import { Map as ImmutableMap } from 'immutable';
import StatisticsData from 'EmailData/models/statistics/StatisticsData';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { RESET_EMAIL_DETAILS } from 'EmailData/actions/actionTypes';
import { DETAILS_MARK_STATISTICS_DIRTY, FETCH_EMAIL_DETAILS_STATISTICS, FETCH_EMAIL_DETAILS_STATISTICS_REQUEST, SELECT_TIME_RANGE } from 'email-management-lib/refactor/actions/actionTypes';
import AllCampaignStatistics from 'email-management-lib/refactor/models/AllCampaignStatistics';
const initialState = ImmutableMap({
  campaigns: ImmutableMap({}),
  totalStatistics: StatisticsData.from({}),
  source: ''
});
function campaignStatistics(state = initialState, action) {
  switch (action.type) {
    case FETCH_EMAIL_DETAILS_STATISTICS:
      if (action.error !== true) {
        const statsResponse = AllCampaignStatistics.from(action.payload);
        const mergedCampaigns = state.get('campaigns').mergeDeep(statsResponse.get('campaignStatistics'));
        let newTotals = statsResponse.get('totals');
        if (!state.get('totalStatistics').isEmpty()) {
          newTotals = mergedCampaigns.reduce((aggregation, value) => aggregation.merge(value), StatisticsData.from({}));
        }
        return state.set('campaigns', mergedCampaigns).set('totalStatistics', newTotals).set('source', statsResponse.source);
      }
      return state;
    case RESET_EMAIL_DETAILS:
      return initialState;
    default:
      return state;
  }
}
export default asyncResource(campaignStatistics, {
  requestActions: [FETCH_EMAIL_DETAILS_STATISTICS_REQUEST],
  responseActions: [FETCH_EMAIL_DETAILS_STATISTICS],
  invalidatingActions: [RESET_EMAIL_DETAILS, SELECT_TIME_RANGE, DETAILS_MARK_STATISTICS_DIRTY]
});