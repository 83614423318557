import Raven from 'raven-js';
import { fetchApprovalsConfigRequest } from 'EmailData/api/approvals';
import { FETCH_APPROVALS_CONFIG_FAIL, FETCH_APPROVALS_CONFIG_SUCCESS } from 'EmailData/actions/actionTypes';
export function fetchApprovalsConfig() {
  return dispatch => {
    return fetchApprovalsConfigRequest().then(response => {
      dispatch({
        type: FETCH_APPROVALS_CONFIG_SUCCESS,
        payload: response
      });
    }, err => {
      dispatch({
        type: FETCH_APPROVALS_CONFIG_FAIL
      });
      if (err && err.message && !err.message.includes('failed with status 0')) {
        Raven.captureException(new Error('Error Fetching Approvals config'), {
          extra: {
            message: err.message
          }
        });
      }
    });
  };
}