'use es6';

import { Record } from 'immutable';
const defaults = {
  appId: null,
  contentId: null,
  createdAt: null,
  finishedAt: null,
  emailCampaignGroupId: null,
  emailCampaignId: null,
  portalId: null,
  scheduledAt: null,
  sendId: null,
  variantVersion: null,
  winnerCampaignIds: []
};
class AbEmailStreamCampaign extends Record(defaults) {
  static from(json) {
    return new AbEmailStreamCampaign(json);
  }
}
export default AbEmailStreamCampaign;