import { getUserInfo } from '../api/getUserInfo';
import { isAdmin as _testableIsAdmin } from './isAdmin';
import logError from '../reliability/logError';
export const getIsAdmin = adminType => {
  return getUserInfo().then(({
    user
  }) => _testableIsAdmin(user, adminType), error => {
    logError('getIsAdmin', Object.assign({}, error, {
      errorMessage: `Failed at getUserInfo`
    }));
    return false;
  }).catch(error => {
    logError('getIsAdmin', Object.assign({}, error, {
      errorMessage: `Failed at _testableIsAdmin for ${adminType}`
    }));
    return false;
  });
};

// export the function for backwards compatibility
export const isAdmin = _testableIsAdmin;