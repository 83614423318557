import { DEFAULT_MODAL_TRANSITION_TIMING } from 'HubStyleTokens/times';

/**
 * Returns the delay needed due to the animation of a modal opening - attempting to attach to an animating modal
 * can have unpredictable results. The DEFAULT_MODAL_TRANSITION_TIMING is a string ending in 'ms'.
 */
export const getAnimationDelay = () => {
  const timeString = DEFAULT_MODAL_TRANSITION_TIMING;
  return parseInt(timeString.slice(0, timeString.length - 2), 10) + 100;
};
/**
 * Watches DOM changes for when a specificed elementQuery is matched.
 *
 * @return a promise with the element
 */
export const waitForElementToAppear = ({
  elementQuery,
  requiresModalAnimation = false,
  targetDocument = document
}) => {
  const maybeElement = targetDocument.querySelector(elementQuery);
  if (maybeElement && !requiresModalAnimation) {
    return Promise.resolve(maybeElement);
  }
  return new Promise(resolve => {
    const observer = new MutationObserver(() => {
      const maybeAppearedElement = targetDocument.querySelector(elementQuery);
      if (maybeAppearedElement) {
        if (requiresModalAnimation) {
          setTimeout(() => resolve(maybeAppearedElement), getAnimationDelay());
        } else {
          resolve(maybeAppearedElement);
        }
        observer.disconnect();
      }
    });
    observer.observe(targetDocument, {
      attributes: true,
      childList: true,
      characterData: false,
      subtree: true
    });
  });
};