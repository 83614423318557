import { useEffect, useMemo, useRef } from 'react';
import isEqual from 'hs-lodash/isEqual';
/**
 * @param value the value to be memoized (usually a dependency list)
 * @returns a memoized version of the value as long as it remains deeply equal
 */
function useDeepCompareMemoize(value) {
  const ref = useRef(value);
  const signalRef = useRef(0);
  if (!isEqual(value, ref.current)) {
    ref.current = value;
    signalRef.current += 1;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => ref.current, [signalRef.current]);
}

/**
 * Adapted from https://github.com/kentcdodds/use-deep-compare-effect/blob/main/src/index.ts
 */
export default function useDeepEqualEffect(callback, dependencies) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, useDeepCompareMemoize(dependencies));
}