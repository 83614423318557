import { useEffect, useState } from 'react';
import { fetchUsers } from '../api/users';
import useCrmObjectTypePermissions from './useCrmObjectTypePermissions';
import useFetchUserInfo from './useFetchUserInfo';
function getUserIdsFromUsersTeams(userInfo) {
  const userIds = [];
  if (!userInfo || !userInfo.user) {
    return userIds;
  }
  userIds.push(userInfo.user.user_id);
  if (userInfo.user.teams) {
    userInfo.user.teams.forEach(team => {
      team.teammates.forEach(userId => {
        if (userIds.indexOf(userId) === -1) {
          userIds.push(userId);
        }
      });
    });
  }
  return [...new Set(userIds)];
}
export default function useFetchUsers(viewAssetScopes) {
  const [state, setState] = useState({
    error: false,
    loading: true,
    users: []
  });
  const {
    loading: loadingScopes,
    data: scopes,
    error: errorScopes
  } = useCrmObjectTypePermissions(viewAssetScopes);
  const {
    loading: loadingUserInfo,
    data: userInfo,
    error: errorUserInfo
  } = useFetchUserInfo();
  useEffect(() => {
    if (loadingScopes || loadingUserInfo || errorScopes || errorUserInfo || !scopes) {
      return;
    }
    fetchUsers({
      canViewAllTeams: scopes.canViewAllTeams,
      canViewOwnedTeams: scopes.canViewOwnedTeams,
      userIdsFromUsersTeams: getUserIdsFromUsersTeams(userInfo)
    }).then(users => setState({
      error: false,
      loading: false,
      users
    })).catch(() => setState({
      error: true,
      loading: false,
      users: []
    }));
  }, [scopes, errorScopes, loadingScopes, userInfo, loadingUserInfo, errorUserInfo]);
  return state;
}