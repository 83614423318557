import { dismissDashboardZeroState } from './dashboardZeroState';
export let dismissFirstEmailZeroState = isFirstEmail => {
  return dispatch => {
    if (isFirstEmail) {
      return dispatch(dismissDashboardZeroState());
    }
    return Promise.resolve();
  };
};

// https://product.hubteam.com/docs/frontend/kb/concatenated-spies.html#eliminate-dependence-on-mutable-imports
export const setDismissFirstEmailZeroStateForTesting = mock => {
  dismissFirstEmailZeroState = mock;
};