import I18n from 'I18n';
import unescapedText from 'I18n/utils/unescapedText';
import { formatDurationV2 } from '../formatDurationV2';
const getDateDisplayHintForDate = ({
  numberValue,
  dateDisplayHint
}) => {
  // Create moment object for both value and current time pointing to UTC midnight
  const momentValueInUTC = I18n.moment.utc(numberValue);

  // We ignore the time part of the date and pick the portal time zone's date alone
  // then convert the date to UTC midnight and use that for comparing the difference
  const momentTodayInUTC = I18n.moment.utc([I18n.moment.portalTz().year(), I18n.moment.portalTz().month(), I18n.moment.portalTz().date()]);
  const diff = dateDisplayHint === 'time_since' ? momentTodayInUTC.diff(momentValueInUTC, 'days') : momentValueInUTC.diff(momentTodayInUTC, 'days');
  if (dateDisplayHint === 'absolute_with_relative') {
    return diff === 0 ? unescapedText('customerDataPropertyUtils.dateDisplayHintLabel.date.today') : diff > 0 ? unescapedText(`customerDataPropertyUtils.dateDisplayHintLabel.date.absolute_with_relative.future`, {
      count: Math.abs(diff)
    }) : unescapedText(`customerDataPropertyUtils.dateDisplayHintLabel.date.absolute_with_relative.past`, {
      count: Math.abs(diff)
    });
  }
  if (dateDisplayHint === 'time_since' || dateDisplayHint === 'time_until') {
    return unescapedText(`customerDataPropertyUtils.dateDisplayHintLabel.date.${dateDisplayHint}`, {
      count: diff
    });
  }
  return '';
};
const getDateDisplayHintForDateTime = ({
  value,
  dateDisplayHint
}) => {
  const {
    duration,
    unit
  } = formatDurationV2(value, dateDisplayHint);
  if (duration === undefined) {
    return '';
  }
  if (dateDisplayHint === 'absolute_with_relative') {
    return duration === 0 ? unescapedText('customerDataPropertyUtils.dateDisplayHintLabel.dateTime.now') : duration > 0 ? unescapedText(`customerDataPropertyUtils.dateDisplayHintLabel.dateTime.absolute_with_relative.future.${unit}`, {
      duration: Math.abs(duration)
    }) : unescapedText(`customerDataPropertyUtils.dateDisplayHintLabel.dateTime.absolute_with_relative.past.${unit}`, {
      duration: Math.abs(duration)
    });
  }
  if (dateDisplayHint === 'time_until' || dateDisplayHint === 'time_since') {
    return unescapedText(`customerDataPropertyUtils.dateDisplayHintLabel.dateTime.${dateDisplayHint}.${unit}`, {
      duration
    });
  }
  return '';
};
export const getDateDisplayHintLabel = ({
  value,
  type,
  dateDisplayHint
}) => {
  if (type !== 'date' && type !== 'datetime') {
    return '';
  }
  if (!value || !dateDisplayHint || dateDisplayHint === 'absolute') {
    return '';
  }
  const numberValue = Number(value);
  if (isNaN(numberValue)) {
    return '';
  }
  if (type === 'datetime') {
    return getDateDisplayHintForDateTime({
      value,
      dateDisplayHint
    });
  }
  return getDateDisplayHintForDate({
    numberValue,
    dateDisplayHint
  });
};