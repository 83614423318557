import I18n from 'I18n';
import { formatOwnerName } from '../../utils/UserUtils';
import { FilterOptionTypes, BaseFilterLevels } from './Constants';
const getCustomFilterOptions = ({
  allowPrivate,
  assetTypeDisplayName,
  userHasTeamAssignmentScope,
  showAny = true,
  showUnassigned,
  customTeamOptionDisabled,
  additionalOptions,
  ownerInfo,
  showMyTeamsOption
}) => {
  const CustomOptions = [];
  const allLangKey = userHasTeamAssignmentScope ? 'allUsersAndTeams' : 'allTeams';
  if (userHasTeamAssignmentScope && allowPrivate) {
    CustomOptions.push({
      value: BaseFilterLevels.ASSIGNED_TO_USER,
      type: FilterOptionTypes.CUSTOM,
      text: I18n.text('ui-asset-management-lib.filterOptions.myAssets', {
        ownerName: I18n.SafeString(formatOwnerName(ownerInfo))
      })
    });
  }
  if (showMyTeamsOption) {
    CustomOptions.push({
      value: BaseFilterLevels.ASSIGNED_TO_USERS_TEAM,
      type: FilterOptionTypes.CUSTOM,
      text: I18n.text('ui-asset-management-lib.filterOptions.myTeamsAssets'),
      disabled: customTeamOptionDisabled
    });
  }
  additionalOptions.forEach(option => {
    CustomOptions.push(option);
  });
  if (showAny) {
    CustomOptions.push({
      value: BaseFilterLevels.ALL,
      type: FilterOptionTypes.CUSTOM,
      text: I18n.text(`ui-asset-management-lib.filterOptions.${allLangKey}`)
    });
  }
  if (showUnassigned) {
    CustomOptions.push({
      value: BaseFilterLevels.UNASSIGNED,
      type: FilterOptionTypes.CUSTOM,
      text: I18n.text('ui-asset-management-lib.filterOptions.unassignedAssets', {
        assetType: assetTypeDisplayName
      })
    });
  }
  return CustomOptions;
};
export default getCustomFilterOptions;