/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */

import { initAppTranslations } from 'I18n/init/setup/AppInit'; // used to track time to load translations at app start
import createStandardI18nProvider from 'I18n/init/providers/createStandardI18nProvider';
import lang from 'i2l?query=sporks!../../lang/en.lyaml'; // path to your app's main language file
import registerAllData from 'I18n/init/register/sync/registerAllData';

// I18n Data that you only need to bring in if you absolutely need this data.
// Do not import these if you don't need them.
// Eventually these will be imported differently, but we are still figuring out how to best handle that I18n#617

// Imports all currency data
// Needed if you render currency anywhere
import 'I18n/init/data/currency';

// Imports info on what base locales we support in I18n
// Usually used in Language/Locale selects
import 'I18n/init/data/baseLocales';
import 'I18n/init/data/currency';
import 'I18n/init/data/baseLocales';
import 'I18n/init/data/publicLocales';

// Laboratory copy experiments
// @ts-expect-error not migrated to TS yet
import createExperimentI18nProvider from 'laboratory-lib/i18n/createExperimentI18nProvider';
import experimentalLang from 'i2l?query=sporks!../../lang/laboratory-copy-experiments/en.lyaml';
import { treatmentsClient } from './api/ExperimentApi';
export default function setupI18n({
  user,
  portal
}) {
  // Initiates the I18n provider which you use to register lang files and set locale
  const StandardI18nProvider = createStandardI18nProvider();
  const ExperimentalI18nProvider = createExperimentI18nProvider({
    client: treatmentsClient
  });
  const I18nProviders = [[StandardI18nProvider, lang], [ExperimentalI18nProvider, experimentalLang]];
  // Set locale + timezone data for each provider
  I18nProviders.forEach(([I18nProvider]) => {
    I18nProvider.setLocale({
      locale: user.locale,
      langEnabled: user.lang_enabled,
      timezone: portal.timezone
    });
  });
  const standardI18nRegistration = StandardI18nProvider.register(lang);
  const experimentalI18nRegistration = new Promise(resolve => {
    standardI18nRegistration.then(() => {
      ExperimentalI18nProvider.register(experimentalLang).then(resolve);
    }).catch(e => {
      throw e;
    });
  });
  const I18nRegistrations = [standardI18nRegistration, experimentalI18nRegistration];
  const i18nPromises = [
  // register the lang file for all translations in the app
  ...I18nRegistrations,
  // tells I18n to register and load I18n data like date & number formatting and moment
  registerAllData(StandardI18nProvider)];
  return initAppTranslations(i18nPromises);
}