import { CMS_HUB_ENTERPRISE, CMS_HUB_PROFESSIONAL, CMS_HUB_STARTER, CRM_SUITE_ENTERPRISE_BUNDLE, CRM_SUITE_PROFESSIONAL_BUNDLE, CRM_SUITE_STARTER_BUNDLE, DEDICATED_IP, MARKETING_HUB_ENTERPRISE, MARKETING_HUB_PROFESSIONAL, MARKETING_HUB_STARTER, MARKETING_PLUS_ENTERPRISE_BUNDLE, MARKETING_PLUS_PROFESSIONAL_BUNDLE, OPERATIONS_HUB_ENTERPRISE, OPERATIONS_HUB_PROFESSIONAL, OPERATIONS_HUB_STARTER, SALES_HUB_ENTERPRISE, SALES_HUB_PROFESSIONAL, SALES_HUB_STARTER, SERVICE_HUB_ENTERPRISE, SERVICE_HUB_PROFESSIONAL, SERVICE_HUB_STARTER } from './ApiNames';
const VersionNumbers = {
  [DEDICATED_IP]: {
    LEGACY: 1,
    EARLY_2020: 3
  },
  [MARKETING_HUB_STARTER]: {
    LATEST: 8,
    LEGACY_250: 2,
    NOV_2019: 3,
    MARKETING_CONTACTS: 4
  },
  [MARKETING_HUB_PROFESSIONAL]: {
    LATEST: 15,
    MARKETING_CONTACTS: 12
  },
  [MARKETING_HUB_ENTERPRISE]: {
    LATEST: 15,
    MARKETING_CONTACTS: 12
  },
  [SALES_HUB_STARTER]: {
    LATEST: 5,
    LEGACY: 1,
    MARCH_2020: 3
  },
  [SALES_HUB_PROFESSIONAL]: {
    LATEST: 7,
    LEGACY_BUSINESS_USER: 3,
    QUANTIFIABLE_SEAT: 7
  },
  [SALES_HUB_ENTERPRISE]: {
    LATEST: 3,
    QUANTIFIABLE_SEAT: 3
  },
  [SERVICE_HUB_STARTER]: {
    LATEST: 4,
    LEGACY: 1,
    MARCH_2020: 2
  },
  [SERVICE_HUB_PROFESSIONAL]: {
    LATEST: 2,
    QUANTIFIABLE_SEAT: 2
  },
  [SERVICE_HUB_ENTERPRISE]: {
    LATEST: 3,
    QUANTIFIABLE_SEAT: 3
  },
  [OPERATIONS_HUB_STARTER]: {
    LATEST: 3
  },
  [OPERATIONS_HUB_PROFESSIONAL]: {
    LATEST: 2
  },
  [OPERATIONS_HUB_ENTERPRISE]: {
    LATEST: 2
  },
  [CMS_HUB_STARTER]: {
    LATEST: 7
  },
  [CMS_HUB_PROFESSIONAL]: {
    LATEST: 12
  },
  [CMS_HUB_ENTERPRISE]: {
    LATEST: 4
  },
  [MARKETING_PLUS_PROFESSIONAL_BUNDLE]: {
    LATEST: 1
  },
  [MARKETING_PLUS_ENTERPRISE_BUNDLE]: {
    LATEST: 1
  },
  [CRM_SUITE_STARTER_BUNDLE]: {
    LATEST: 3
  },
  [CRM_SUITE_PROFESSIONAL_BUNDLE]: {
    LATEST: 2
  },
  [CRM_SUITE_ENTERPRISE_BUNDLE]: {
    LATEST: 2
  }
};
export default VersionNumbers;