import { CMS_HUB_ENTERPRISE } from 'self-service-api/constants/ApiNames';
import { CMS_HUB_PROFESSIONAL } from 'self-service-api/constants/ProductNames';
import { CMS_ENTERPRISE, CMS_PROFESSIONAL } from 'self-service-api/constants/UpgradeProducts';
import { activateCms } from '../api/activateByProduct';
export const productActivationProducts = [CMS_ENTERPRISE, CMS_PROFESSIONAL];
export const productActivationApiNames = [CMS_HUB_ENTERPRISE, CMS_HUB_PROFESSIONAL];
export const getActivationFn = upgradeProduct => {
  switch (upgradeProduct) {
    case CMS_PROFESSIONAL:
    case CMS_ENTERPRISE:
      return activateCms;
    default:
      return null;
  }
};
export const getActivationFnFromApiName = apiName => {
  switch (apiName) {
    case CMS_HUB_PROFESSIONAL:
    case CMS_HUB_ENTERPRISE:
      return activateCms;
    default:
      return null;
  }
};