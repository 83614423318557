import { PROPERTIES } from 'email-management-lib/refactor/constants/manage/frameworkTable';
import { IN } from 'email-management-lib/refactor/utils/filters';
import { useMemo } from 'react';
export default function useInitialFilters({
  queryStringObj,
  shouldAddArchivedFalseFilter
}) {
  return useMemo(() => {
    const filterGroups = [{
      filters: []
    }];
    if (shouldAddArchivedFalseFilter) {
      filterGroups[0].filters.push(IN(PROPERTIES.ARCHIVED, ['false']));
    }
    return {
      query: queryStringObj.search,
      filterGroups
    };
  }, [queryStringObj.search, shouldAddArchivedFalseFilter]);
}