import { useEffect, useState } from 'react';
import { REQUEST_SEAT_MODAL_INITIAL_STATE, fetchAvailableSeatOptions } from './requestSeatApi';
export const useRequestSeatModalData = () => {
  const [requestSeatModalData, setRequestSeatModalData] = useState(REQUEST_SEAT_MODAL_INITIAL_STATE);
  useEffect(() => {
    fetchAvailableSeatOptions().then(availableSeatOptions => {
      setRequestSeatModalData({
        isLoading: false,
        availableSeatOptions,
        defaultSeat: availableSeatOptions[0] ? availableSeatOptions[0].value : null
      });
    }).catch(() => {
      setRequestSeatModalData(Object.assign({}, REQUEST_SEAT_MODAL_INITIAL_STATE, {
        isLoading: false
      }));
    });
  }, []);
  return requestSeatModalData;
};