/**
 * Extract number attribute from @element.
 * Makes an extra check for null in case the attribute value is zero.
 * @param element the HTML element
 * @param attributeName the name of the attribute
 * @returns the value of attributeName in element
 */
function getElementNumberAttribute(element, attributeName) {
  const attrValue = element.getAttribute(attributeName);
  return parseInt(attrValue !== null ? attrValue : '-1', 10);
}

/**
 * Traverses the table and find for all table rows between @allSelectedObjectIds[0] and @currentObjectId
 * when the shift key is pressed alongside with mouse click.
 *
 * @returns a list of CrmObjects to be selected
 */
export function getCrmObjectsBetweenRows({
  allSelectedObjectIds,
  crmObjects,
  currentObjectId
}) {
  if (allSelectedObjectIds.length !== 1) {
    return [];
  }

  /**
   * Find table cells
   */
  const tdFirstCell = document.querySelector(`td[data-test-id="crm-table-bulk-action-cell"][data-test-object-id="${allSelectedObjectIds[0]}"]`);
  const tdLastCell = document.querySelector(`td[data-test-id="crm-table-bulk-action-cell"][data-test-object-id="${currentObjectId}"]`);
  if (!tdFirstCell || !tdLastCell) {
    return [];
  }

  /**
   * Find row index for table cells
   */
  const indexOfFirstCell = getElementNumberAttribute(tdFirstCell, 'data-test-row-index');
  const indexOfLastCell = getElementNumberAttribute(tdLastCell, 'data-test-row-index');
  if (indexOfFirstCell === -1 || indexOfLastCell === -1 || indexOfFirstCell === indexOfLastCell) {
    return [];
  }

  /**
   * Can select rows above or below.
   * Stop the flow if any row in between is not found.
   */
  const startRowIndex = Math.min(indexOfFirstCell, indexOfLastCell);
  const endRowIndex = Math.max(indexOfFirstCell, indexOfLastCell);
  let result = [];
  for (let currentIndex = startRowIndex + 1; currentIndex < endRowIndex; currentIndex++) {
    const tdCell = document.querySelector(`td[data-test-id="crm-table-bulk-action-cell"][data-test-row-index="${currentIndex}"]`);
    if (!tdCell) {
      result = [];
      break;
    }
    const objectId = getElementNumberAttribute(tdCell, 'data-test-object-id');
    if (objectId === -1) {
      result = [];
      break;
    }
    const rowCrmObject = crmObjects.find(object => object.objectId === objectId);
    if (!rowCrmObject) {
      result = [];
      break;
    }
    result.push(rowCrmObject);
  }
  return result;
}