'use es6';

import { Map as ImmutableMap } from 'immutable';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { RECEIVE_INTEGRATION_SETTINGS, RECEIVE_INTEGRATION_SETTINGS_REQUEST, RECEIVE_NATIVE_STATUSES, RECEIVE_NATIVE_STATUSES_REQUEST } from 'email-management-lib/refactor/actions/actionTypes';
import InstalledApp from 'email-management-lib/refactor/models/InstalledApp';
const initialState = ImmutableMap({
  installedApps: ImmutableMap()
});
function integrationSettings(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_INTEGRATION_SETTINGS:
      {
        const {
          payload: {
            listings = []
          }
        } = action;
        const installedApps = ImmutableMap(listings.map(app => [app.id, new InstalledApp(app)]));
        return state.set('installedApps', installedApps);
      }
    case RECEIVE_NATIVE_STATUSES:
      {
        const {
          payload
        } = action;
        const newState = state.get('installedApps').map(app => {
          const nativeStatus = payload.find(integration => integration.appId === app.id);
          if (nativeStatus) {
            const newItem = app.set('integrationStatus', nativeStatus.integrationStatus).set('integrationStatusError', nativeStatus.integrationStatusError);
            return newItem;
          }
          return app;
        });
        return state.set('installedApps', newState);
      }
    default:
      return state;
  }
}
export default asyncResource(integrationSettings, {
  requestActions: [RECEIVE_INTEGRATION_SETTINGS_REQUEST, RECEIVE_NATIVE_STATUSES_REQUEST],
  responseActions: [RECEIVE_INTEGRATION_SETTINGS, RECEIVE_NATIVE_STATUSES]
});