import { Record } from 'immutable';
const getRatio = (numerator, denominator) => {
  if (!numerator || !denominator) {
    return 0;
  }
  return numerator / denominator * 100;
};
class StatisticsRatios extends Record({
  clickratio: 0,
  clickthroughratio: 0,
  replyratio: 0,
  deliveredratio: 0,
  openratio: 0,
  unsubscribedratio: 0,
  spamreportratio: 0,
  bounceratio: 0,
  hardbounceratio: 0,
  softbounceratio: 0,
  contactslostratio: 0,
  pendingratio: 0,
  notsentratio: 0,
  clickThroughRateExcludingBots: 0,
  openRateExcludingBots: 0,
  clickRateExcludingBots: 0,
  adjustedOpenRate: 0
}) {
  static from(json) {
    return new StatisticsRatios(json);
  }
  static fromCounters(counters) {
    const {
      bounce,
      click,
      reply,
      contactslost,
      delivered,
      hardbounced,
      open,
      pending,
      selected,
      sent,
      softbounced,
      spamreport,
      unsubscribed
    } = counters;
    return StatisticsRatios.from({
      clickratio: getRatio(click, delivered),
      clickthroughratio: getRatio(click, open),
      replyratio: getRatio(reply, delivered),
      deliveredratio: getRatio(delivered, sent),
      openratio: getRatio(open, delivered),
      unsubscribedratio: getRatio(unsubscribed, delivered),
      spamreportratio: getRatio(spamreport, delivered),
      bounceratio: getRatio(bounce, sent),
      hardbounceratio: getRatio(hardbounced, sent),
      softbounceratio: getRatio(softbounced, sent),
      contactslostratio: getRatio(contactslost, sent),
      pendingratio: getRatio(pending, sent),
      notsentratio: 100 - getRatio(sent, selected)
    });
  }
}
export default StatisticsRatios;