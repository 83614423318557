const toSafeEpoch = epochTime => {
  try {
    if (isNaN(epochTime)) throw new Error('not valid epoch time');
    if (epochTime === true || epochTime === false) throw new Error('not valid epoch time');
    const converted = new Date(epochTime).getTime();
    const valid = !isNaN(converted) && converted > 0;
    if (!valid) throw new Error('not valid epoch time');
    return converted;
  } catch (_ex) {
    return new Date().getTime();
  }
};
export default toSafeEpoch;