const MEDIUM = {
  condensed: false,
  tableClassName: ''
};
const SMALL = {
  condensed: true,
  tableClassName: ''
};
const EXTRA_SMALL = {
  condensed: true,
  tableClassName: 'table-more-condensed'
};

/**
 * Returns the correct values for @condensed and @tableClassName props down
 * to UIStickyHeaderTable.
 * Default to @small size if @rownProps is not defined.
 */
export function getTableProps({
  crmUI,
  rowProps
}) {
  if (!rowProps || !rowProps.rowSize) {
    return crmUI ? EXTRA_SMALL : SMALL;
  }
  switch (rowProps.rowSize) {
    case 'extra-small':
      return EXTRA_SMALL;
    default:
    case 'small':
      return SMALL;
    case 'medium':
      return MEDIUM;
  }
}