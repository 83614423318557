'use es6';

import { Record } from 'immutable';
const defaults = {
  influencedContacts: null,
  influencedDeals: null,
  revenue: null
};
class RevenueReportingTotals extends Record(defaults) {
  static from(json) {
    return new RevenueReportingTotals(json.totals);
  }
}
export default RevenueReportingTotals;