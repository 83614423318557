// This history object allows us to programatically navigate
// through react router from anywhere in the project.

export let history;
const instance = {
  configure(hist) {
    history = hist;
  },
  navigateTo(to, queryParams = window.location.search) {
    history.push(`${to}${queryParams}`);
  }
};
export default instance;