import { useCallback, useMemo } from 'react';
import { useCrmObjectType } from 'framework-listing-lib/internal/providers/CrmObjectTypeListingClientProvider';
import { useHasViews } from 'framework-listing-lib/internal/ViewTabs/hooks/useViewProps';
import { useHasFoldersMode } from 'framework-listing-lib/internal/hooks/useUIMode';
export default function useFolderProps() {
  const {
    folderProps
  } = useCrmObjectType();
  return useMemo(() => {
    if (!folderProps) {
      return {};
    }
    return folderProps;
  }, [folderProps]);
}
export function useHasFoldersTab() {
  const hasViews = useHasViews();
  const hasFoldersMode = useHasFoldersMode();
  return useMemo(() => hasViews && hasFoldersMode, [hasFoldersMode, hasViews]);
}
export function useOnSelectFolder() {
  const {
    onSelectFolder
  } = useFolderProps();
  return useCallback(crmFolder => {
    if (onSelectFolder) {
      onSelectFolder(crmFolder);
    }
  }, [onSelectFolder]);
}
export function useHiddenFolderFilter() {
  const {
    hiddenFilter
  } = useFolderProps();
  return hiddenFilter;
}