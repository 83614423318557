/**
 * A wide property type that is used in our external API to support various
 * data shapes. We normalize this internally to match the client type `Property`,
 * but there are a lot of apps that use Immutable or GraphQL for example.
 *
 * To learn more about providing this definition, see the docs: https://product.hubteam.com/docs/crm-properties/frontend/fetching.html
 */

/** @deprecated getIsUngated is ignored in favor of gates returned by `userInfo()`. This can be deleted after all usages have been removed. */

export const EDIT_INPUT_MODE = 'edit';
export const CREATE_INPUT_MODE = 'create';
export const BULK_INPUT_MODE = 'bulk';
export const PREVIEW_INPUT_MODE = 'preview';
export const AUTOMATION_INPUT_MODE = 'automation';

/**
 * This is the list of props that are passed through from PropertyInputV2 to the
 * leaf inputs. These are props that are directly exposed on the external API
 * for the PropertyInputV2 component.
 */

/**
 * This is the list of props that are built inside PropertyInputV2 and then
 * provided to the leaf inputs
 */

/**
 * These are created inside usePropertyValidation so we can trigger validation
 * when the value of the input changes
 */

/**
 * The complete list of props that are available to every input are composed of
 * 4 separate lists of props. There is more detail on what each of these groups
 * are in comments around their type definition but there's a brief description
 * here as well to summarize where everything comes from.
 *
 * 1. PropertyInputV2ComponentPassthroughProps - These are props that are
 * exposed directly on PropertyInputV2 and passed through to the leaf inputs
 *
 * 2. PropertyInputV2LeafInputProvidedProps - These are props that are built
 * inside PropertyInputV2 and then passed to the leaf node, they are not
 * directly available via the PropertyInputV2 API.
 *
 * 3. FloatingFormControlFocusProps - These are props that are provided by
 * withFloatingFormControlFocus, they are added to all inputs via the
 * InputWithFloatingFormControlFocus that wraps every input component.
 */

/**
 * These are the props consumed by the PropertyInputV2 component which is
 * wrapped in contexts and higher order components
 */

/**
 * These props are the public facing API of the PropertyInputV2 and represent
 * all the props which can be passed to the component
 */