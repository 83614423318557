import { useQuery } from 'data-fetching-client';
import { useMemo } from 'react';
import { FETCH_USER_HAS_USED_AI_TRANSLATION_REQUEST } from 'email-management-lib/refactor/queries/aiTranslation';
function useHasUsedAITranslation() {
  const {
    data,
    loading: isFetchingUserHasUsedAITranslation
  } = useQuery(FETCH_USER_HAS_USED_AI_TRANSLATION_REQUEST);
  const hasUsedAiTranslation = useMemo(() => {
    return !!data && !!data.hasUsedAiTranslation && data.hasUsedAiTranslation === 'true';
  }, [data]);
  return useMemo(() => ({
    hasUsedAiTranslation,
    isFetchingUserHasUsedAITranslation
  }), [hasUsedAiTranslation, isFetchingUserHasUsedAITranslation]);
}
export default useHasUsedAITranslation;