import { useRef } from 'react';
const defaultEqual = (prev, next) => prev === next;

/**
 * Returns a ref whose `current` value is the most recent `value` which
 * was not equal to the previous value.
 *
 * Useful for stabilizing component props where consumers can not be expected
 * to provide a stable reference.
 *
 * @example
 * // `ref.current` is always `value`
 * const ref = useStableRef(value);
 *
 * // `ref.current` only changes when `value.key` changes
 * const valueRef = useStableRef(value, (prev, next) => prev.key === next.key);
 */
export const useStableRef = (value, equal = defaultEqual) => {
  const ref = useRef(value);
  const prev = ref.current;
  const next = value;
  if (prev !== next && !equal(prev, next)) {
    ref.current = next;
  }
  return ref;
};