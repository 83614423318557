/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */

'use es6';

import { RECEIVE_PORTAL_SETTINGS, RECEIVE_TRACKING_CONFIG, FETCH_DATA_PRIVACY_SETTINGS_SUCCESS } from 'EmailData/portalSettings/actions/types';
import { getIsPortalSettingsLoaded, getIsTrackingConfigurationLoaded } from 'EmailData/portalSettings/selectors';
import { fetchPortalSettingsRequest, fetchTrackingConfigurationRequest, fetchDataPrivacySettings } from 'EmailData/portalSettings/api';
import Raven from 'raven-js';
export function fetchPortalSettings() {
  return (dispatch, getState) => {
    if (!getIsPortalSettingsLoaded(getState())) {
      const requests = [fetchPortalSettingsRequest(), fetchDataPrivacySettings()];
      Promise.all(requests).then(([portalSettingsResponse, dataPrivacySettingsResponse]) => {
        dispatch({
          type: RECEIVE_PORTAL_SETTINGS,
          payload: portalSettingsResponse
        });
        dispatch({
          type: FETCH_DATA_PRIVACY_SETTINGS_SUCCESS,
          payload: dataPrivacySettingsResponse
        });
      }).catch(err => Raven.captureException(err));
    }
  };
}
export function fetchTrackingConfiguration() {
  return (dispatch, getState) => {
    if (!getIsTrackingConfigurationLoaded(getState())) {
      fetchTrackingConfigurationRequest().then(response => {
        dispatch({
          type: RECEIVE_TRACKING_CONFIG,
          payload: response
        });
      }, err => dispatch({
        type: RECEIVE_TRACKING_CONFIG,
        error: true,
        payload: err,
        metadata: {
          growlError: {
            error: err,
            feature: RECEIVE_TRACKING_CONFIG,
            hideNotification: true
          }
        }
      }));
    }
  };
}