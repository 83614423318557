import { useCallback } from 'react';
import { useDataFetchingClient } from 'data-fetching-client';
import { useApolloClient } from '@apollo/client';
import useFetchCrmObjectType from 'framework-listing-lib/hooks/useFetchCrmObjectType';
import useCrmObjectTypeFetchParams from 'framework-listing-lib/internal/hooks/useCrmObjectTypeFetchParams';
import { CRM_OBJECT_TYPE_QUERY, CRM_OBJECT_TYPE_FIELD_NAME, LISTING_LIB_CRM_SEARCH_QUERY } from 'framework-listing-lib/internal/hooks/useFetchFromCRM';
import useHasGraphQLCrmSearch from 'framework-listing-lib/internal/graphql/hooks/useHasGraphQLCrmSearch';
import { CRM_SEARCH_GRAPHQL_CACHE_KEY } from 'framework-listing-lib/internal/graphql/constants';
import { getGraphQLVariables, transformCrmSearchResponseToGraphQLResponse, transformGraphQLResponseIntoCrmSearchResponse } from 'framework-listing-lib/internal/graphql/utils/graphql';
export function useWipeTableCache() {
  const client = useDataFetchingClient();
  const hasGraphQLCrmSearch = useHasGraphQLCrmSearch();
  const apolloClient = useApolloClient();
  return useCallback(() => {
    if (hasGraphQLCrmSearch) {
      apolloClient.cache.evict({
        id: 'ROOT_QUERY',
        fieldName: CRM_SEARCH_GRAPHQL_CACHE_KEY
      });
      apolloClient.cache.gc();
    } else {
      // Clear all cache keys for @fieldName
      client.cache.evict({
        fieldName: CRM_OBJECT_TYPE_FIELD_NAME
      });
      client.cache.gc();
    }
  }, [apolloClient.cache, client.cache, hasGraphQLCrmSearch]);
}
export function useWipeTableCacheAndRefetch() {
  const wipeTableCache = useWipeTableCache();
  const {
    refetch
  } = useFetchCrmObjectType();
  return useCallback(() => {
    wipeTableCache();
    refetch().then(() => {}).catch(() => {});
  }, [refetch, wipeTableCache]);
}
export function useWriteToTableCache() {
  const client = useDataFetchingClient();
  const hasGraphQLCrmSearch = useHasGraphQLCrmSearch();
  const apolloClient = useApolloClient();
  const variables = useCrmObjectTypeFetchParams();
  return useCallback(newData => {
    if (hasGraphQLCrmSearch) {
      apolloClient.writeQuery({
        query: LISTING_LIB_CRM_SEARCH_QUERY,
        data: {
          [CRM_SEARCH_GRAPHQL_CACHE_KEY]: transformCrmSearchResponseToGraphQLResponse(newData)
        },
        variables: getGraphQLVariables(variables)
      });
    } else {
      client.cache.writeQuery({
        data: {
          [CRM_OBJECT_TYPE_FIELD_NAME]: newData
        },
        query: CRM_OBJECT_TYPE_QUERY,
        variables
      });
    }
  }, [apolloClient, client.cache, hasGraphQLCrmSearch, variables]);
}
export function useReadFromTableCache() {
  const client = useDataFetchingClient();
  const hasGraphQLCrmSearch = useHasGraphQLCrmSearch();
  const apolloClient = useApolloClient();
  const variables = useCrmObjectTypeFetchParams();
  return useCallback(() => {
    if (hasGraphQLCrmSearch) {
      const cachedQuery = apolloClient.readQuery({
        query: LISTING_LIB_CRM_SEARCH_QUERY,
        variables: getGraphQLVariables(variables)
      });
      if (cachedQuery) {
        return transformGraphQLResponseIntoCrmSearchResponse(cachedQuery, variables.fetchParams.objectTypeId);
      }
      return null;
    }
    const cache = client.cache.readQuery({
      query: CRM_OBJECT_TYPE_QUERY,
      variables
    });
    if (!cache) {
      return null;
    }
    return cache[CRM_OBJECT_TYPE_FIELD_NAME];
  }, [apolloClient, client.cache, hasGraphQLCrmSearch, variables]);
}