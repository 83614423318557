import { useState, useEffect } from 'react';
import http from 'hub-http/clients/apiClient';
export default function useCurrencySelectInfo({
  skip
} = {}) {
  const [currencySelectInfo, setCurrencySelectInfo] = useState({});
  useEffect(() => {
    if (!skip) {
      http.get('multi-currency/v1/currencies/information').then(({
        currencyExchangeRates,
        homeCurrency
      }) => {
        const homeCurrencyCode = homeCurrency && homeCurrency.currencyCode;
        const validCurrencies = [...new Set(currencyExchangeRates.filter(xr => xr.visible).map(xr => xr.fromCurrencyCode).concat(homeCurrencyCode || []))];
        const currenciesFilter = ({
          value
        }) => validCurrencies.includes(value);
        setCurrencySelectInfo({
          currenciesFilter,
          homeCurrencyCode
        });
      }).catch(() => {});
    }
  }, [skip]);
  return currencySelectInfo;
}