import quickFetch from 'quick-fetch';

// TODO: Delete some of these once usages are cleaned from ContentEmailUI
export function getEarlyEmailListingKey() {
  return 'EmailListing';
}
export function getEarlyEmailListingMinimal() {
  return 'EmailListingMinimal';
}
export function getEarlyEmailListingArchivedKey() {
  return 'ArchivedEmails';
}
export function getEarlyEmailListingHasEmailsKey() {
  return 'HasEmails';
}
export function getEarlyBlogsKey() {
  return 'Blogs';
}
export function getEarlyEmailListingByCrmSearchKey() {
  return 'EmailListingByCrmSearch';
}
export function getEarlyEmailListingArchivedByCrmSearchKey() {
  return 'ArchivedEmailsByCrmSearch';
}
export function getEarlyEmailListingHasEmailsByCrmSearchKey() {
  return 'HasEmailsByCrmSearch';
}
function makeEarlyRequest(endpoint, responseKey, data = {}, timeout = 15000) {
  quickFetch.makeEarlyRequest(responseKey, {
    url: quickFetch.getApiUrl(endpoint, true),
    timeout,
    data,
    dataType: 'json',
    contentType: 'application/json',
    type: 'GET'
  });
}
export function earlyDetailsFetch(emailId) {
  if (!emailId) {
    // Skip the early request if the parameter is not passed
    return;
  }
  makeEarlyRequest(`/cosemail/v1/details/${emailId}`, `EmailDetails::${emailId}`);
  makeEarlyRequest(`/cosemail/v1/details/${emailId}/minimal-performance`, `PerformanceStats::${emailId}`, {}, 30000);
}