'use es6';

import { Record } from 'immutable';
const defaults = {
  appId: 0,
  createdAt: 0,
  emailCampaignId: 0,
  emailCampaignGroupId: 0,
  estimatedRecipients: 0,
  gmtOffset: '',
  scheduledAt: 0,
  sendId: ''
};
class LocalTimeSendChild extends Record(defaults) {
  static from(json) {
    return new LocalTimeSendChild(json);
  }
  getOffsetName() {
    return `UTC${this.gmtOffset}`;
  }
  mergeWith(primaryEmail) {
    return primaryEmail.set('publishDate', this.scheduledAt).set('appId', this.appId);
  }
}
export default LocalTimeSendChild;