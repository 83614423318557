import omit from 'transmute/omit';
import { parseQueryParams } from './url';
import { stringify } from 'hub-http/helpers/params';
const DETAILS_VALID_URL = /^\/email\/([0-9]+)\/details\/([0-9]+)[/]?(?:performance|recipients|localtime)?[/]?([a-z-]+)?$/;

// Checks that the requested page has the valid form as provided
// in the given Regex and no url parameters have been set.
export function isValidEarlyUrl(validUrl, url, params) {
  return url.match(validUrl) !== null && params === '';
}
export function isValidDetailsUrl(url, params, supportedParams = []) {
  return isValidEarlyUrl(DETAILS_VALID_URL, url, stringify(omit(supportedParams, parseQueryParams(params))));
}