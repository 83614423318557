import { NOT_SPECIFIED, normalizeAccessLevel } from 'customer-data-objects/property/AccessLevel';
import { getPropertyDefinitions } from '../propertyDefinitions/getPropertyDefinitions';
import { keyBy } from '../utils/keyBy';
export const fetchAccessLevel = (objectTypeId, httpClient) => getPropertyDefinitions({
  objectTypeId,
  httpClient
}).then(properties => keyBy(
// Filter out any non-NOT_SPECIFIED FLP value to keep the map from
// growing too large. Downstream code interprets "no value" as "not specified".
properties.filter(({
  permission
}) => permission && permission.accessLevel && permission.accessLevel !== NOT_SPECIFIED), ({
  property: {
    name
  }
}) => name, ({
  permission
}) => normalizeAccessLevel(permission.accessLevel)));