import { useReducer } from 'react';
import { validatePhoneNumber } from '../api/validatePhoneNumber';
import useDebouncedValue from '../../../utils/hooks/useDebouncedValue';
import { useHttpClient } from '../../../client/HttpClientContext';
export const FETCH_STATUS = {
  UNINITIALIZED: 'UNINITIALIZED',
  PENDING: 'PENDING',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED'
};
const initialState = {};
const reducer = (state, action) => {
  const {
    type,
    phoneNumber,
    payload
  } = action;
  switch (type) {
    case 'PHONE_NUMBER_VALIDATION_PENDING':
      return Object.assign({}, state, {
        [phoneNumber]: {
          status: FETCH_STATUS.PENDING
        }
      });
    case 'PHONE_NUMBER_VALIDATION_SUCCESS':
      return Object.assign({}, state, {
        [phoneNumber]: {
          status: FETCH_STATUS.SUCCEEDED,
          data: payload
        }
      });
    case 'PHONE_NUMBER_VALIDATION_FAILED':
      return Object.assign({}, state, {
        [phoneNumber]: {
          status: FETCH_STATUS.FAILED,
          error: payload
        }
      });
    default:
      {
        return state;
      }
  }
};
export const usePhoneNumberValidator = phoneNumber => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const debouncedPhoneNumber = useDebouncedValue(phoneNumber, 500);
  const httpClient = useHttpClient();
  if (!state[debouncedPhoneNumber]) {
    dispatch({
      type: `PHONE_NUMBER_VALIDATION_PENDING`,
      phoneNumber: debouncedPhoneNumber,
      payload: undefined
    });
    validatePhoneNumber(debouncedPhoneNumber, httpClient).then(res => {
      dispatch({
        type: `PHONE_NUMBER_VALIDATION_SUCCESS`,
        phoneNumber: debouncedPhoneNumber,
        payload: res
      });
    }).catch(err => {
      dispatch({
        type: `PHONE_NUMBER_VALIDATION_FAILED`,
        phoneNumber: debouncedPhoneNumber,
        payload: err
      });
    });
  }
  const phoneNumberState = state[phoneNumber] || {
    status: FETCH_STATUS.PENDING
  };
  return {
    status: phoneNumberState.status,
    error: phoneNumberState.error,
    data: phoneNumberState.data
  };
};
export default usePhoneNumberValidator;