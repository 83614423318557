import { useCallback, useEffect, useRef } from 'react';
import SyntheticEvent from 'UIComponents/core/SyntheticEvent';
import { sanitizeValue } from '../utils/campaignSelect';
export default function useGetCampaignSelectChangeHandler({
  initialOptions = [],
  multi,
  onChange
}) {
  // Keep a ref to the initial options to merge them
  // together when creating a new campaign with multi=true
  const currentOptionsRef = useRef(initialOptions);
  useEffect(() => {
    currentOptionsRef.current = initialOptions;
  }, [initialOptions]);
  return useCallback(optionOrOptions => {
    const isArray = Array.isArray(optionOrOptions);
    if (!optionOrOptions || isArray && optionOrOptions.length === 0) {
      return onChange(SyntheticEvent([]), optionOrOptions);
    }
    if (multi) {
      // if multi=true, we force to call onChange with an array
      let options = [];
      if (!isArray) {
        // if we are passed a single item when multi=true, we need to handle
        // the current options reference
        options = currentOptionsRef.current.concat(optionOrOptions);
        currentOptionsRef.current = options;
      } else {
        // otherwise we just use the options passed
        options = optionOrOptions;
      }
      const sanitizedOptions = options.map(option => sanitizeValue(option.value));
      return onChange(SyntheticEvent(sanitizedOptions), options);
    }

    // if not multi, we force to call onChange with a single item
    const option = isArray ? optionOrOptions[0] : optionOrOptions;
    return onChange(SyntheticEvent(sanitizeValue(option.value)), option);
  }, [multi, onChange]);
}