import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { parse } from 'hub-http/helpers/params';
import { getCurrentURL } from 'framework-listing-lib/internal/deepLink/utils';
export default function useQueryParams() {
  const {
    location
  } = useHistory();
  return useMemo(() => parse(location.search.substring(1)), [location.search]);
}
export function useRemoveQueryParams() {
  const {
    location,
    replace
  } = useHistory();
  return useCallback(params => {
    const url = getCurrentURL();
    for (const param of params) {
      url.searchParams.delete(param);
    }
    const nextSearch = url.searchParams.toString();
    if (nextSearch !== location.search) {
      replace({
        search: url.searchParams.toString(),
        state: location.state
      });
    }
  }, [location.search, location.state, replace]);
}