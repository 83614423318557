import { useEffect, useCallback, useState } from 'react';
import { waitForTargetElement, watchTargetElementVisibility } from '../utils/targetElementUtil';
import { getAnimationDelay } from '../utils/observerUtils';
import useIsMounted from '../hooks/useIsMounted';
import { executeAfterPromise } from '../utils/promise';
export const useTarget = (attachTo, {
  requiresTargetDisappearAnimation = false,
  beforeEnter,
  fallbackElementQuery
} = {}) => {
  // Set to false, because first we find the element than we make sure it's visible
  const [isTargetVisible, setIsTargetVisible] = useState(false);
  const [targetElement, setTargetElement] = useState(document.querySelector(attachTo) || fallbackElementQuery && document.querySelector(fallbackElementQuery) || null);
  const isMounted = useIsMounted();
  const getBeforeEnterPromise = useCallback(async () => {
    if (typeof beforeEnter === 'function') {
      await beforeEnter();
    }
  }, [beforeEnter]);
  useEffect(() => {
    // Starts watching for visibility as soon as there is a target
    if (targetElement) {
      return watchTargetElementVisibility(targetElement, isVisible => {
        if (isMounted.current) {
          setIsTargetVisible(isVisible);

          // If the element at any point is not visible we make sure it's still rendered
          if (!isVisible) {
            if (requiresTargetDisappearAnimation) {
              setTimeout(() => {
                setTargetElement(document.querySelector(attachTo));
              }, getAnimationDelay());
            } else {
              executeAfterPromise(getBeforeEnterPromise(), () => {
                setTargetElement(document.querySelector(attachTo));
              });
            }
          }
        }
      });
    }
    return waitForTargetElement(attachTo, element => {
      if (element && isMounted.current) {
        executeAfterPromise(getBeforeEnterPromise(), () => {
          setTargetElement(element);
        });
      }
    });
  }, [attachTo, isMounted, targetElement, requiresTargetDisappearAnimation, getBeforeEnterPromise]);
  return {
    isTargetVisible,
    targetElement
  };
};