import { Map as ImmutableMap } from 'immutable';
function reduceAll(state, action, reducers) {
  let newState = state;
  Object.keys(reducers).forEach(reducerKey => {
    const reducer = reducers[reducerKey];
    const stateSlice = state.get(reducerKey);
    newState = newState.set(reducerKey, reducer(stateSlice, action));
  });
  return newState;
}
export default function combineImmutableReducers(reducers) {
  const defaultState = Object.keys(reducers).reduce((result, key) => {
    return result.set(key, undefined);
  }, ImmutableMap());
  return (state = defaultState, action) => {
    return reduceAll(state, action, reducers);
  };
}