// @ts-expect-error not migrated to TS yet

import { anyTrueFunctions, allTrueFunctions } from 'EmailData/utils/functionUtils';
import EmailType from 'EmailData/enums/EmailType';
import CombinedEmailSendState from 'EmailData/enums/CombinedEmailSendState';
import StateStatusGroups from 'ContentUtils/constants/StateStatusGroups';
import EmailTemplateModes from 'EmailData/enums/EmailTemplateModes';
import { AUTOMATED_LIKE } from 'EmailData/helpers/EmailTypeHelpers';
export const getState = email => {
  if (email === undefined || email === null) {
    return null;
  }
  if (email.state !== null && email.state !== undefined) {
    return email.state;
  }
  if (typeof email.get === 'function') {
    return email.get('state');
  }
  return null;
};
export const getSubcategory = email => {
  if (email === undefined || email === null) {
    return null;
  }
  if (email.subcategory !== null && email.subcategory !== undefined) {
    return email.subcategory;
  }
  if (typeof email.get === 'function') {
    return email.get('subcategory');
  }
  return null;
};
export const getEmailType = email => {
  if (email === undefined || email === null) {
    return null;
  }
  if (email.emailType !== undefined && email.emailType !== null) {
    return email.emailType;
  }
  if (typeof email.get === 'function') {
    return email.get('emailType');
  }
  return null;
};
export const getProcessingStatus = email => {
  if (email === undefined || email === null) {
    return null;
  }
  if (email.processingStatus !== null && email.processingStatus !== undefined) {
    return email.processingStatus;
  }
  if (typeof email.get === 'function') {
    return email.get('processingStatus');
  }
  return null;
};
const compareValue = (currentValue, check) => {
  if (check instanceof Array) {
    return check.some(item => item === currentValue);
  }
  return check === currentValue;
};
const stateCheck = check => {
  return email => {
    return compareValue(getState(email), check);
  };
};
const subcategoryCheck = check => {
  return email => {
    return compareValue(getSubcategory(email), check);
  };
};
const emailTypeCheck = check => {
  return email => {
    return compareValue(getEmailType(email), check);
  };
};
const processingStatusCheck = check => {
  return email => {
    return compareValue(getProcessingStatus(email), check);
  };
};
export const getIsAbAutomated = emailTypeCheck(EmailType.AUTOMATED_AB_EMAIL);
export const getIsAbEmail = emailTypeCheck(EmailType.AB_EMAIL);
export const getIsSingleSendApi = emailTypeCheck([EmailType.SINGLE_SEND_API, EmailType.MARKETING_SINGLE_SEND_API]);
export const getIsAutomated = emailTypeCheck(EmailType.AUTOMATED_EMAIL);
export const getIsLocalTime = emailTypeCheck(EmailType.LOCALTIME_EMAIL);
export const getIsBlogEmail = emailTypeCheck(EmailType.BLOG_EMAIL);
export const getIsBlogEmailChild = emailTypeCheck(EmailType.BLOG_EMAIL_CHILD);
export const getIsRssEmail = emailTypeCheck(EmailType.RSS_EMAIL);
export const getIsRssEmailChild = emailTypeCheck(EmailType.RSS_EMAIL_CHILD);
export const getIsBatchEmail = emailTypeCheck(EmailType.BATCH_EMAIL);
export const getIsSmtpToken = emailTypeCheck(EmailType.SMTP_TOKEN);
export const getIsFollowupEmail = emailTypeCheck(EmailType.FOLLOWUP_EMAIL);
export const getIsLeadflowEmail = emailTypeCheck(EmailType.LEADFLOW_EMAIL);
export const getIsTicketEmail = emailTypeCheck(EmailType.TICKET_EMAIL);
export const getIsMembershipRegistrationEmail = emailTypeCheck(EmailType.MEMBERSHIP_REGISTRATION_EMAIL);
export const getIsMembershipPasswordResetEmail = emailTypeCheck(EmailType.MEMBERSHIP_PASSWORD_RESET_EMAIL);
export const getIsMembershipPasswordSavedEmail = emailTypeCheck(EmailType.MEMBERSHIP_PASSWORD_SAVED_EMAIL);
export const getIsMembershipFollowUpEmail = emailTypeCheck(EmailType.MEMBERSHIP_FOLLOW_UP_EMAIL);
export const getIsMembershipPasswordlessAuthEmail = emailTypeCheck(EmailType.MEMBERSHIP_PASSWORDLESS_AUTH_EMAIL);
export const getIsMembershipVerificationEmail = emailTypeCheck(EmailType.MEMBERSHIP_VERIFICATION_EMAIL);
export const getIsFeedbackLikeEmail = anyTrueFunctions([emailTypeCheck(EmailType.FEEDBACK_CES_EMAIL), emailTypeCheck(EmailType.FEEDBACK_NPS_EMAIL), emailTypeCheck(EmailType.FEEDBACK_CUSTOM_EMAIL), emailTypeCheck(EmailType.FEEDBACK_CUSTOM_SURVEY_EMAIL)]);
export const getIsTypeAutomatedLike = check =>
// @ts-expect-error Argument of type 'string' is not assignable to parameter of type '"AUTOMATED_AB_EMAIL" | "AUTOMATED_EMAIL" | "FOLLOWUP_EMAIL" | "OPTIN_EMAIL"
AUTOMATED_LIKE.indexOf(check) >= 0;
export const getIsAutomatedLike = email => getIsTypeAutomatedLike(getEmailType(email));
export const getIsAbLike = anyTrueFunctions([emailTypeCheck(EmailType.AUTOMATED_AB_EMAIL), emailTypeCheck(EmailType.AB_EMAIL)]);
export const getIsMembershipEmail = anyTrueFunctions([getIsMembershipRegistrationEmail, getIsMembershipPasswordSavedEmail, getIsMembershipPasswordResetEmail, getIsMembershipFollowUpEmail, getIsMembershipPasswordlessAuthEmail, getIsMembershipVerificationEmail]);
export const getIsEditableAutomated = anyTrueFunctions([getIsAutomated, getIsAbAutomated, getIsMembershipEmail, getIsTicketEmail, getIsSingleSendApi, emailTypeCheck(EmailType.OPTIN_EMAIL), emailTypeCheck(EmailType.OPTIN_FOLLOWUP_EMAIL), emailTypeCheck(EmailType.FOLLOWUP_EMAIL), emailTypeCheck(EmailType.RESUBSCRIBE_EMAIL)]);
export const getIsCanceled = processingStatusCheck(CombinedEmailSendState.CANCELED);
export const getIsForciblyCanceled = processingStatusCheck(CombinedEmailSendState.CANCELED_FORCIBLY);
export const getIsPublished = email => StateStatusGroups.PUBLISHED.indexOf(getState(email)) > -1;
export const getIsScheduled = email => StateStatusGroups.SCHEDULED.indexOf(getState(email)) > -1;
export const getIsDraft = email => StateStatusGroups.DRAFT.indexOf(getState(email)) > -1;
export const getIsError = stateCheck('ERROR');
export const getIsPreProcessing = stateCheck('PRE_PROCESSING');
export const getIsProcessing = stateCheck('PROCESSING');
export const getIsInitializing = stateCheck('INITIALIZING');
export const getIsAutomatedForDeal = allTrueFunctions([getIsAutomated, subcategoryCheck('automated_for_deal')]);
export const getIsAutomatedForForm = anyTrueFunctions([getIsFollowupEmail, stateCheck('AUTOMATED_FOR_FORM'), subcategoryCheck('automated_for_form')]);
export const getIsOptinEmail = anyTrueFunctions([emailTypeCheck(EmailType.OPTIN_EMAIL), emailTypeCheck(EmailType.OPTIN_FOLLOWUP_EMAIL), subcategoryCheck('optin_email'), subcategoryCheck('optin_followup_email')]);
export const getIsOptinFollowupEmail = emailTypeCheck(EmailType.OPTIN_FOLLOWUP_EMAIL);
export const getIsCanceledForAbuse = allTrueFunctions([getIsPublished, processingStatusCheck('CANCELED_ABUSE')]);
export const getIsResubscribeEmail = anyTrueFunctions([subcategoryCheck('resubscribe_email'), emailTypeCheck(EmailType.RESUBSCRIBE_EMAIL)]);
export const getIsResubscribeConfirmationEmail = subcategoryCheck('resubscribe_confirmation_email');
export const getIsUnsubscribeConfirmationEmail = subcategoryCheck('unsubscribe_confirmation_email');
export const getIsSubscriptionEmail = anyTrueFunctions([getIsResubscribeEmail, getIsResubscribeConfirmationEmail, getIsUnsubscribeConfirmationEmail, getIsOptinEmail]);
export const getIsAvailableToWorkflows = email => {
  return (getIsAutomated(email) || getIsAbAutomated(email)) && !getIsAutomatedForForm(email) && !getIsAutomatedForDeal(email) && !getIsSingleSendApi(email);
};
export const getIsPublishedAutomated = email => {
  return (getIsPublished(email) || getIsScheduled(email)) && (getIsAutomatedLike(email) || getIsBlogEmail(email) || getIsRssEmail(email));
};
export const getIsDragDropEmail = email => {
  return email.emailTemplateMode === EmailTemplateModes.DRAG_AND_DROP;
};
export const getIsConvertibleToDragAndDrop = email => !getIsBlogEmailChild(email) && !getIsDragDropEmail(email);
export const getIsBlogInstantSend = email => {
  return email.rssToEmailTiming.repeats === 'instant';
};
export const getIsBlogOrRssType = email => getIsBlogEmail(email) || getIsBlogEmailChild(email) || getIsRssEmail(email) || getIsRssEmailChild(email);
export const getApprovalStatus = email => {
  if (email === undefined || email === null) {
    return null;
  }
  if (email.approvalStatus !== undefined && email.approvalStatus !== null) {
    return email.approvalStatus;
  }
  if (typeof email.get === 'function') {
    return email.get('approvalStatus');
  }
  return null;
};