import { useCallback, useRef } from 'react';
import { useObserverContext } from '../context/observerContext';
export const useObserveTableElementRef = () => {
  const oldRef = useRef(null);
  const observer = useObserverContext();

  // This is called a callback ref. When passed to the `ref` prop of a component,
  // this function is called twice whenever the component changes its underlying dom node:
  // Once with `null` (so we can clean up) and once with the new node. We use this to
  // stop observing the old node so that we don't introduce a memory leak.
  const handleRef = useCallback(ref => {
    if (!observer) {
      return;
    }
    if (oldRef.current) {
      observer.unobserve(oldRef.current);
      oldRef.current = null;
    }
    if (!ref) {
      return;
    }
    observer.observe(ref);
    oldRef.current = ref;
  }, [observer]);
  return handleRef;
};