// @ts-expect-error not migrated to TS yet

export const getHasSmartObjects = email => {
  let emailHasSmartContent = false;
  email.widgets.forEach(widget => {
    if (widget.smart_objects && widget.smart_objects.length > 0) {
      emailHasSmartContent = true;
    }
  });
  return emailHasSmartContent;
};
export const getHasNote = email => {
  return email.emailNote !== null && email.emailNote !== '';
};
export const getCanCancelOrUnpublish = email => {
  const isScheduledOrAutomated = email.isScheduled() || email.isPublished() && email.isAutomated();
  const isPublishedBlogOrRss = email.isPublished() && (email.isRssEmail() || email.isBlogEmail());
  return isScheduledOrAutomated || isPublishedBlogOrRss;
};