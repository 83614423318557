'use es6';

import { List, Map as ImmutableMap, Record } from 'immutable';
import { COMPLETED_STATES } from 'EmailData/helpers/CombinedEmailSendStateHelpers';
import { DRAFT_STATES } from 'EmailData/utils/details/abEmailCampaignStates';
const defaults = {
  abTestId: null,
  campaignGroupId: null,
  canceledAt: null,
  created: null,
  id: null,
  numIncluded: null,
  processingCompleted: false,
  processingCompletedAt: null,
  processingState: DRAFT_STATES.UNDEFINED,
  processingUpdatedAt: null,
  publishedAt: null,
  sampleSizeInsignificant: false,
  samplingInsignificant: true,
  updated: null,
  variantMetricSnapshots: List([]),
  variantNumIncluded: ImmutableMap({}),
  winnerExecTime: null,
  winningEmailCampaignId: null
};
class AbEmailCampaign extends Record(defaults) {
  static from(json) {
    if (json !== null && typeof json.variantMetricSnapshots !== 'undefined') {
      json.variantMetricSnapshots = List(json.variantMetricSnapshots);
    }
    if (json !== null && typeof json.variantNumIncluded !== 'undefined') {
      json.variantNumIncluded = ImmutableMap(json.variantNumIncluded);
    }
    return new AbEmailCampaign(json);
  }
  isCompleted() {
    return COMPLETED_STATES.includes(this.processingState);
  }
}
export default AbEmailCampaign;