import { useMemo } from 'react';
import userInfo, { userInfoSync } from 'hub-http/userInfo';
import { useQuery, registerQuery } from 'data-fetching-client';
const GET_USER_INFO_FIELD_NAME = 'userInfo';
const GET_USER_INFO = registerQuery({
  fieldName: GET_USER_INFO_FIELD_NAME,
  fetcher() {
    return userInfo();
  }
});
export default function useFetchUserInfo() {
  const cachedUserInfo = useMemo(() => {
    try {
      return userInfoSync();
    } catch (_unused) {
      return undefined;
    }
  }, []);
  const {
    data,
    error,
    loading
  } = useQuery(GET_USER_INFO, {
    skip: !!cachedUserInfo
  });
  return {
    data: cachedUserInfo || (data && GET_USER_INFO_FIELD_NAME in data ? data.userInfo : undefined),
    error,
    loading
  };
}