import I18n from 'I18n';
import { Map as ImmutableMap } from 'immutable';
import { TEST_SEND_RESET, TEST_SEND_FINISHED, TEST_SEND_ITEM_FAIL, TEST_SEND_ITEM_SUCCESS, TEST_SEND_MODAL_TOGGLE, TEST_EMAIL_SEND_START, TEST_EMAIL_RENDER_START, TEST_EMAIL_RENDER_FAIL, SET_TEST_SEND_CONFIG, CLOSED_PANEL } from 'EmailData/actions/actionTypes';
import TestSendModel from 'EmailData/models/TestSendModel';
import TestSendResult from 'EmailData/models/TestSendResult';
const initialState = new TestSendModel();
const testSendDataReducer = (state = initialState, action) => {
  let newTestSendObject;
  switch (action.type) {
    case CLOSED_PANEL:
      if (action.name !== 'testSend') {
        return state;
      }
      return initialState;
    case TEST_SEND_RESET:
      return initialState;
    case TEST_EMAIL_RENDER_START:
      return state.set('isValidating', true).set('errorMessages', []);
    case TEST_EMAIL_RENDER_FAIL:
      return state.set('isValidating', false).set('errorMessages', [{
        key: null,
        message: [action.error && action.error.message || I18n.text('EmailComponents.testSend.renderError')]
      }]);
    case TEST_EMAIL_SEND_START:
      {
        const resultObject = {};
        action.recipients.forEach(recipient => {
          resultObject[recipient] = new TestSendResult();
        });
        return state.set('currentTestSends', ImmutableMap(resultObject)).set('isValidating', false).set('isSending', true);
      }
    case TEST_SEND_ITEM_SUCCESS:
      if (state.get('currentTestSends').size === 0) {
        return state;
      }
      newTestSendObject = TestSendResult.from({
        isLoading: false,
        failed: false,
        result: action.result
      });
      return state.set('currentTestSends', state.get('currentTestSends').set(action.testId, newTestSendObject));
    case TEST_SEND_ITEM_FAIL:
      {
        if (state.get('currentTestSends').size === 0) {
          return state;
        }
        const {
          error,
          errorMessage = ''
        } = action;
        const result = error && error.status < 500 && error.status >= 400 ? error.responseJSON : {
          sendResult: ''
        };
        return state.set('currentTestSends', state.get('currentTestSends').set(action.testId, TestSendResult.from({
          isLoading: false,
          failed: true,
          errorMessage,
          result: action.result || result
        })));
      }
    case TEST_SEND_FINISHED:
      return state.set('isSending', false);
    case TEST_SEND_MODAL_TOGGLE:
      return state.set('isModalOpen', action.isOpen);
    case SET_TEST_SEND_CONFIG:
      return state.set('testSendConfig', action.payload);
    default:
      return state;
  }
};
export default testSendDataReducer;