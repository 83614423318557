import { getIsFreePortalAndOwnedProducts } from '../_core/common/eligibility/getIsFreePortal';
import { getUpgradePointPromise, validateBannerUpgradeConfig } from './utils';
export const fetchValidatedBannerDetails = upgradeKey => {
  return Promise.all([getIsFreePortalAndOwnedProducts(), getUpgradePointPromise({
    upgradeKey,
    delayCall: false
  })]).then(([isFreePortalAndOwnedProductsResponse, upgradePointResponse]) => {
    if (!upgradePointResponse) return {};
    try {
      validateBannerUpgradeConfig(upgradePointResponse);
    } catch (validationError) {
      console.error(validationError);
      throw validationError;
    }
    return {
      upgradeConfig: upgradePointResponse,
      isFreePortal: isFreePortalAndOwnedProductsResponse.isFreePortal,
      ownedProducts: isFreePortalAndOwnedProductsResponse.ownedProducts
    };
  });
};