'use es6';

import { Map as ImmutableMap } from 'immutable';
import { INIT_PATH_PARAMS, UPDATE_PATH_PARAMS } from 'EmailData/actions/actionTypes';
const INITIAL_STATE = ImmutableMap();
export default function pathParams(state = INITIAL_STATE, action) {
  switch (action.type) {
    case INIT_PATH_PARAMS:
      return ImmutableMap(action.payload);
    case UPDATE_PATH_PARAMS:
      return state.mergeDeep(action.payload);
    default:
      return state;
  }
}