import { HubUserAttributesClient } from 'frontend-preferences-client';
import Raven from 'raven-js';
import { userInfoSync } from 'hub-http/userInfo';
export const hubUserAttributesClient = HubUserAttributesClient.forCaller('EmailUILib');
export const HUB_USER_ATTRIBUTE_PREFIX = 'MarketingEmail:';
export const HUA_KEYS_IN_MANAGE = ['dashboardZeroStateComplete', 'dismissedOutageCritsitBanner', 'dismissedToolsSidebarCarousel', 'dismissedOnboardingTourEntry', 'dismissedOnboardingTourTime', 'manageColumns'];
export const ALL_KEYS_IN_HUB_USER_ATTRIBUTES = ['isNewToTestResultsTab', 'analyzeTableColumns', 'dismissedYearInReviews', 'blogRssHistoryColumns'];
export function setHubUserAttributeRequest(attributeName, attributeValue) {
  // write and del can throw, so perform your standard error handling here
  return hubUserAttributesClient.write(attributeName, attributeValue.toString());
}
export function getHubUserAttributesRequest(keys) {
  // In event hub-user key does not exist in user-attributes it will return whatever
  // is chosen as the default value ; in our case empty string
  const keysToGetWithDefault = Object.fromEntries(keys.map(obj => [HUB_USER_ATTRIBUTE_PREFIX + obj, '']));
  return hubUserAttributesClient.batchFetch(keysToGetWithDefault);
}
export function deleteHubUserAttributesRequest(attributeName) {
  return hubUserAttributesClient.del(attributeName);
}
export function writeToUserAttributesInManage(value, overrideSettings = false) {
  const userId = userInfoSync().user.user_id;
  const keysToWrite = [];
  const keysToDelete = [];
  HUA_KEYS_IN_MANAGE.forEach(key => {
    if (key in value) {
      keysToWrite.push(key);
    } else {
      keysToDelete.push(key);
    }
  });
  const writeRequests = keysToWrite.map(key => {
    return setHubUserAttributeRequest(HUB_USER_ATTRIBUTE_PREFIX + key, value[key]);
  });
  const deleteRequests = overrideSettings ? keysToDelete.map(key => {
    return deleteHubUserAttributesRequest(HUB_USER_ATTRIBUTE_PREFIX + key);
  }) : [Promise.resolve()];
  return Promise.allSettled(deleteRequests).then(() => {
    return Promise.allSettled(writeRequests);
  }).catch(error => {
    Raven.captureException(new Error('Hub user attribute cannot write or delete in manage'), {
      extra: Object.assign({
        userId,
        value
      }, error)
    });
    return Promise.resolve();
  });
}
export function writeToUserAttributes(value, overrideSettings = false) {
  const userId = userInfoSync().user.user_id;
  const keysToWrite = [];
  const keysToDelete = [];
  ALL_KEYS_IN_HUB_USER_ATTRIBUTES.forEach(key => {
    if (key in value) {
      keysToWrite.push(key);
    } else {
      keysToDelete.push(key);
    }
  });
  const writeRequests = keysToWrite.map(key => {
    return setHubUserAttributeRequest(HUB_USER_ATTRIBUTE_PREFIX + key, value[key]);
  });
  const deleteRequests = overrideSettings ? keysToDelete.map(key => {
    return deleteHubUserAttributesRequest(HUB_USER_ATTRIBUTE_PREFIX + key);
  }) : [Promise.resolve()];
  return Promise.allSettled(deleteRequests).then(() => {
    return Promise.allSettled(writeRequests);
  }).catch(error => {
    Raven.captureException(new Error('Hub user attribute cannot write or delete user setting'), {
      extra: Object.assign({
        userId,
        value
      }, error)
    });
  });
}