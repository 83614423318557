import Raven from 'raven-js';
import { createIndexedDBSuperstore } from 'superstore';
import { Metrics } from '../metrics/Metrics';
let superStoreInstance;
const getSuperstoreInstance = () => {
  if (!superStoreInstance) {
    try {
      superStoreInstance = createIndexedDBSuperstore({
        namespace: 'customer-data-views-management'
      }).open().catch(() => {
        Metrics.counter('superstore-open-failed').increment();
      });
    } catch (e) {
      Metrics.counter('superstore-init-failed').increment();
      Raven.captureException(e, {
        tags: {
          superstoreInit: 'true'
        }
      });
      superStoreInstance = Promise.reject(e);
    }
  }
  return superStoreInstance;
};
export function getSuperstoreValue(key) {
  return getSuperstoreInstance().then(superstore => superstore && superstore.get(key));
}
export function setSuperstoreValue(key, value) {
  return getSuperstoreInstance().then(store => store && store.set(key, value));
}