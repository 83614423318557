export function includes(arr, element) {
  return arr.includes(element);
}
export function maybeMakeArray(value) {
  if (Array.isArray(value)) {
    return value;
  }
  if (value === null) {
    return null;
  }
  if (value === undefined) {
    return undefined;
  }
  return [value];
}