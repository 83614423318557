import { useCallback, useEffect } from 'react';
import { getFilterValueByProperty } from 'framework-listing-lib/utils/filters';
import { MANAGE_ACCESS_SUCCESS } from 'framework-listing-lib/constants/tableEvents';
import useCrmObjectTypeListingFilters from 'framework-listing-lib/hooks/useCrmObjectTypeListingFilters';
import { useReadFromTableCache, useWipeTableCache, useWriteToTableCache, useWipeTableCacheAndRefetch } from 'framework-listing-lib/internal/hooks/useFetchCrmObjectTypeCache';
import listingLibObserver from 'framework-listing-lib/utils/listingLibObserver';
const TEAM_ID_PROPERTY = 'hs_all_team_ids';
export default function useManageAccessObserver() {
  const {
    filters
  } = useCrmObjectTypeListingFilters();
  const wipeTableCache = useWipeTableCache();
  const writeToTableCache = useWriteToTableCache();
  const readFromTableCache = useReadFromTableCache();
  const wipeTableCacheAndRefetch = useWipeTableCacheAndRefetch();
  const updateTableCache = useCallback(response => {
    if (!response) {
      return;
    }
    const {
      objectIdOrIds,
      selectedTeamIds
    } = response;
    const objectIds = Array.isArray(objectIdOrIds) ? objectIdOrIds : [objectIdOrIds];

    /**
     * Check whether we need to manually remove the asset from the list:
     *  1. Filter asset by team id = 123
     *  2. "Manage access" and remove team id 123 from the asset
     *  3. We remove the asset from current table cache (delay until CRM data is up to date)
     *
     * In all cases we need to wipe the entire table cache to prevent false results that have been
     * previously cached by other team ids.
     */

    const currentSelectedTeamIds = getFilterValueByProperty(filters, TEAM_ID_PROPERTY, []);
    const shouldKeepAssetOnTheTable = !currentSelectedTeamIds || currentSelectedTeamIds.length === 0 || selectedTeamIds && selectedTeamIds.some(permissionWriteId => currentSelectedTeamIds.includes(permissionWriteId));
    if (shouldKeepAssetOnTheTable) {
      wipeTableCacheAndRefetch();
    } else {
      const cachedAssets = readFromTableCache();
      if (!cachedAssets) {
        return;
      }
      wipeTableCache();
      writeToTableCache(Object.assign({}, cachedAssets, {
        results: cachedAssets.results.filter(crmObjectType => !objectIds.includes(crmObjectType.objectId)),
        total: cachedAssets.total - 1
      }));
    }
  }, [filters, readFromTableCache, wipeTableCache, wipeTableCacheAndRefetch, writeToTableCache]);
  useEffect(() => {
    listingLibObserver.on(MANAGE_ACCESS_SUCCESS, updateTableCache);
    return () => {
      listingLibObserver.off(MANAGE_ACCESS_SUCCESS, updateTableCache);
    };
  }, [updateTableCache]);
}