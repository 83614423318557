'use es6';

import BaseEmailDetail from 'EmailData/models/details/BaseEmailDetail';
import EmailCampaign from 'EmailData/models/details/children/EmailCampaign';
import Workflow from 'EmailData/models/Workflow';
const automatedEmailDefaults = {
  emailCampaign: null
};
class AutomatedEmailDetail extends BaseEmailDetail(Object.assign({}, automatedEmailDefaults)) {
  static from(json) {
    const response = super.from(json);
    if (typeof json.emailCampaign !== 'undefined') {
      response.emailCampaign = EmailCampaign.from(json.emailCampaign);
    }
    if (typeof json.workflows !== 'undefined') {
      response.workflows = json.workflows.map(Workflow.from);
    }
    return new AutomatedEmailDetail(response);
  }
}
export default AutomatedEmailDetail;