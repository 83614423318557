import { useEffect, useMemo } from 'react';
import listingLibPerformanceTracking from 'framework-listing-lib/internal/utils/performanceTracking';
import useCrmObjectTypeTracking from './useCrmObjectTypeTracking';
export function useSyncPerformanceTracking() {
  const {
    trackPerformanceInteraction
  } = useCrmObjectTypeTracking();
  useEffect(() => {
    trackPerformanceInteraction();
    // Intentionally no dependency array, trackPerformanceInteraction handles deduplication internally
  });
}
export default function usePerformanceTracking(metricName) {
  const performanceTracking = useMemo(() => listingLibPerformanceTracking.getPerformanceTracking(metricName), [metricName]);
  performanceTracking.markTimeStart();
  return performanceTracking;
}