'use es6';

import { BatchRequestClient } from './BatchRequestClient';
import http from 'hub-http/clients/apiClient';
import { BatchRequestClientError } from '../error/BatchRequestClientError';
const BASE_URL = 'inbounddb-objects/v1/preview';
const MAX_REQUEST_SIZE = 200;
export const makeRequestKey = ({
  objectTypeId,
  objectId
}) => `${objectTypeId}/${objectId}`;
export const makeRequestBody = requestOptionsByKey => Object.values(requestOptionsByKey).reduce((body, {
  objectTypeId,
  objectId
}) => {
  const objectIds = body[objectTypeId] || [];
  objectIds.push(objectId);
  body[objectTypeId] = objectIds;
  return body;
}, {});
export const api = ({
  requestBody
}) => http.post(BASE_URL, {
  data: requestBody
}).then(response => Object.entries(response).reduce((results, [objectTypeId, objects]) => {
  Object.entries(objects).forEach(([objectId, object]) => results[makeRequestKey({
    objectTypeId,
    objectId
  })] = object);
  return results;
}, {})).catch(err => {
  const requestKeys = [];
  Object.keys(requestBody).forEach(objectTypeId => {
    requestBody[objectTypeId].forEach(objectId => {
      requestKeys.push(makeRequestKey({
        objectTypeId,
        objectId
      }));
    });
  });
  throw new BatchRequestClientError(err.message, requestKeys);
});
export const CrmObjectPreviewBatchRequestClient = new BatchRequestClient({
  api,
  makeRequestBody,
  makeRequestKey,
  options: {
    MAX_REQUEST_SIZE
  }
});
export const __resetCrmObjectPreviewCache = () => {
  CrmObjectPreviewBatchRequestClient.clearCache();
  CrmObjectPreviewBatchRequestClient.clearRequestsInProgress();
};