'use es6';

import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { FETCH_UNBOUNCE_STATUS, FETCH_UNBOUNCE_STATUS_REQUEST, UNBOUNCE_CONTACT, UNBOUNCE_CONTACT_REQUEST } from 'email-management-lib/refactor/actions/actionTypes';
import { RecipientUnbounceStatus, UNBOUNCE_STATUS_STATES } from 'email-management-lib/refactor/models/details/RecipientUnbounceStatus';
const initialState = RecipientUnbounceStatus.from({});
function events(state = initialState, action) {
  const notBounced = UNBOUNCE_STATUS_STATES.IS_NOT_BOUNCED;
  switch (action.type) {
    case FETCH_UNBOUNCE_STATUS:
      if (action.error) {
        return state;
      }
      if (action.payload.result === notBounced) {
        // We set just this field as the server response will nullify all other fields
        return state.set('result', notBounced);
      }
      return state.merge(action.payload);
    case UNBOUNCE_CONTACT:
      {
        const isError = action.error;
        if (!isError) {
          return state.merge(action.payload);
        }
        const alreadyUnbounced = action.payload.result === notBounced;
        if (isError && alreadyUnbounced) {
          return state.set('result', notBounced);
        }
        return state;
      }
    default:
      return state;
  }
}
export default asyncResource(events, {
  requestActions: [FETCH_UNBOUNCE_STATUS_REQUEST, UNBOUNCE_CONTACT_REQUEST],
  responseActions: [FETCH_UNBOUNCE_STATUS, UNBOUNCE_CONTACT],
  invalidatingActions: []
});