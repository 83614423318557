import get from 'transmute/get';
import memoize from 'transmute/memoize';
// @ts-expect-error Untyped dependency
import EmailAddressRegex from 'PatternValidationJS/patterns/EmailAddress';
// @ts-expect-error Untyped dependency
import DomainRegex from 'PatternValidationJS/patterns/Domain';
import { ENUMERATION } from 'customer-data-objects/property/PropertyTypes';
import { isMultienum } from 'customer-data-objects/property/PropertyIdentifier';
import { parseMultiEnumValue } from 'customer-data-property-utils/parseMultiEnumValue';

// Based on URI spec: https://www.rfc-editor.org/rfc/rfc3986#appendix-B
const DOMAIN_PROTOCOL_REGEX = /(^[^:/?#]*:(?:\/+)?)?(?:.*)/;
function isNotHubspotSupportEmail(email) {
  return !/(support\.hubspot\.com)/g.test(email);
}
export function isValidEmail(email) {
  return email && EmailAddressRegex.test(email) && isNotHubspotSupportEmail(email);
}
export const removeProtocolAndWwwPrefix = domain => {
  return domain.replace(/https?:\/\//i, '').replace(/(www\.)?/i, '');
};
export function domainContainsPath(domain) {
  const parsedDomain = removeProtocolAndWwwPrefix(domain);
  return parsedDomain.indexOf('/') !== -1;
}
function getDomainProtocol(domain) {
  // Cast OK due to (?:.*) capture group always matching
  const match = domain.match(DOMAIN_PROTOCOL_REGEX);
  return match[1];
}
export function domainContainsProtocol(domain) {
  return Boolean(domain && getDomainProtocol(domain) !== undefined);
}
export function getDomainProtocolInfo(domain) {
  if (!domain) {
    return {
      domain,
      protocol: undefined
    };
  }
  const protocol = getDomainProtocol(domain);
  if (protocol !== undefined) {
    return {
      domain: domain.replace(protocol, ''),
      protocol
    };
  }
  return {
    domain,
    protocol: undefined
  };
}
export function getDomainPathInfo(domain) {
  if (!domain) {
    return {
      domain,
      path: undefined
    };
  }
  const parsedDomain = removeProtocolAndWwwPrefix(domain);

  // We can cast this here because the regex will match anything, even an empty
  // string (.* will match blank)
  const match = parsedDomain.match(/([^/]*)(.*)/);
  if (!domainContainsPath(domain)) {
    return {
      domain: parsedDomain,
      path: undefined
    };
  }
  return {
    domain: match[1],
    path: match[2]
  };
}
export const getDomain = memoize(url => {
  if (!url) {
    return undefined;
  }
  const pathInfo = getDomainPathInfo(url);
  return pathInfo.domain;
});
export function isValidDomain(domain) {
  const {
    domain: parsedDomain
  } = getDomainPathInfo(domain);
  if (!parsedDomain) {
    return false;
  }
  return DomainRegex.test(parsedDomain);
}
export function isValidRequiredProperties(requiredProps, propertyValues, availableProps) {
  if (!requiredProps) {
    return true;
  }
  return requiredProps.every(prop => {
    const value = propertyValues.get(prop);
    const valueAsString = `${value}`;
    const propertyType = availableProps.getIn([prop, 'type']);
    const hasExternalOptions = availableProps.getIn([prop, 'externalOptions']);
    let isValid;
    if (propertyType === ENUMERATION && !hasExternalOptions) {
      const values = isMultienum(availableProps.get(prop).toJS()) ? parseMultiEnumValue(valueAsString) : [valueAsString];
      const options = availableProps.getIn([prop, 'options']);
      isValid = options.some(option => values.includes(get('value', option)));
    } else {
      let trimmedValue;
      if (valueAsString) {
        trimmedValue = valueAsString.trim();
      }
      isValid = value !== undefined && value !== null && !!trimmedValue && trimmedValue.length > 0;
    }
    return isValid;
  });
}