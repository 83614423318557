import { createSelector } from 'reselect';
import EmailTemplateModesForParams from 'EmailData/enums/EmailTemplateModesForParams';
import { getEmailAuthSelectors } from 'EmailData/selectors/emailAuthSelectors';
import PortalRestrictions from 'EmailData/data/models/PortalRestrictions';
import PortalSettings from 'EmailData/portalSettings/models/PortalSettings';
export const getEmailDefaultTemplateModeSelector = (getGates, getScopes, getPortalSettings) => {
  const emailAuthSelectors = getEmailAuthSelectors(getGates, getScopes);
  const getHasEverUsedClassic = createSelector(getPortalSettings, portalSettings => {
    const settingKey = 'email:portalUsedClassicBeforeCreationFlowMerge';
    const portalSetting = portalSettings instanceof PortalSettings ? portalSettings.getValue(settingKey) : portalSettings[settingKey];
    return portalSetting === 'true' || portalSetting === true;
  });
  const getEmailDefaultTemplateMode = createSelector([emailAuthSelectors.getHasAccessToBothEmailEditors, emailAuthSelectors.getCanCreateClassicEmail, getHasEverUsedClassic], (hasAccessToBothEmailEditors, canCreateClassicEmail, hasEverUsedClassic) => {
    if (hasAccessToBothEmailEditors) {
      return hasEverUsedClassic ? EmailTemplateModesForParams.CLASSIC_EMAIL : EmailTemplateModesForParams.DRAG_DROP_EMAIL;
    } else if (canCreateClassicEmail) {
      return EmailTemplateModesForParams.CLASSIC_EMAIL;
    }
    return EmailTemplateModesForParams.DRAG_DROP_EMAIL;
  });
  return {
    getHasEverUsedClassic,
    getEmailDefaultTemplateMode
  };
};
export const getEmailStatus = (getGates, getScopes, getEmailStatusSelector) => {
  const emailAuthSelectors = getEmailAuthSelectors(getGates, getScopes);
  const getDismissStatus = createSelector([getEmailStatusSelector],
  // @ts-expect-error untyped
  portalStatus => portalStatus.getDismissStatus());
  const getHealthStatus = createSelector([getEmailStatusSelector],
  // @ts-expect-error untyped
  portalStatus => portalStatus.getHealthStatus());
  const getPortalRestrictions = createSelector([emailAuthSelectors.getHasFreeEmail, emailAuthSelectors.usingEmailStarter, emailAuthSelectors.getHasSupportAccessSelector, emailAuthSelectors.getHasCrmImportScope, getHealthStatus], (isFreeEmail, isStarterEmail, hasSupportAccess, hasCrmImportScope, healthStatus) => PortalRestrictions.from({
    crmImportScope: hasCrmImportScope,
    forcedSuspension: healthStatus.isForcedSuspension(),
    freePortal: isFreeEmail,
    starterPortal: isStarterEmail,
    supportAccess: hasSupportAccess
  }));
  const shouldShowPortalProbation = createSelector([getEmailStatusSelector], portalStatus => {
    // @ts-expect-error untyped
    const portalDismissStatus = portalStatus.getDismissStatus();
    if (
    // @ts-expect-error untyped
    portalStatus.isLoading() || portalDismissStatus.isLoading()) {
      return false;
    }

    // @ts-expect-error untyped
    const portalHealthStatus = portalStatus.getHealthStatus();
    return !portalHealthStatus.isHealthy() && !portalHealthStatus.isSuspended() && portalHealthStatus.shouldShowMessage(portalDismissStatus);
  });
  const shouldShowPortalSuspension = createSelector([getEmailStatusSelector], portalStatus => {
    // @ts-expect-error untyped
    const portalHealthStatus = portalStatus && portalStatus.getHealthStatus();
    if (!portalStatus ||
    // @ts-expect-error untyped
    portalStatus.isLoading() || portalHealthStatus.isRecoverableSuspension()) {
      return false;
    }
    return portalHealthStatus.isSuspended();
  });
  const getIsSuspensionRemediationLocked = createSelector(emailAuthSelectors.getHasSupportAccessSelector, hasSupportAccess => !hasSupportAccess);
  return {
    getDismissStatus,
    getHealthStatus,
    getPortalRestrictions,
    shouldShowPortalProbation,
    shouldShowPortalSuspension,
    getIsSuspensionRemediationLocked
  };
};