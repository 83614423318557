import { useMemo } from 'react';
import { useCrmObjectType } from 'framework-listing-lib/internal/providers/CrmObjectTypeListingClientProvider';
import useObjectTypeId from 'framework-listing-lib/internal/hooks/useObjectTypeId';
import { updateHiddenFilterForBUNav } from '../businessUnits/utils';
import { useHasBUNavIntegration } from '../frameworkAppSettings/hooks/useFrameworkAppSettings';
import { combineFilters } from '../../utils/filters';
export default function useFetchEmptyStateParams() {
  const objectType = useObjectTypeId();
  const {
    emptyStateFilter,
    fetcher
  } = useCrmObjectType();
  const hasBUNavIntegration = useHasBUNavIntegration();
  const baseFilterGroups = useMemo(() => emptyStateFilter ? emptyStateFilter.filterGroups : [{
    filters: []
  }], [emptyStateFilter]);
  const combinedFilterGroups = useMemo(() => combineFilters(baseFilterGroups, updateHiddenFilterForBUNav({
    hiddenFilter: undefined,
    hasBUNavIntegration
  }).filterGroups), [baseFilterGroups, hasBUNavIntegration]);
  const variables = useMemo(() => ({
    fetcher,
    fetchParams: {
      count: 1,
      filterGroups: combinedFilterGroups,
      objectTypeId: objectType,
      offset: 0,
      query: '',
      requestOptions: {
        properties: ['hs_object_id']
      },
      sorts: [{
        order: 'DESC',
        property: 'hs_created_at'
      }]
    },
    quickFetchName: `fetch-crm-objects-${objectType}-initial-state`,
    removeAfterFetch: false
  }), [combinedFilterGroups, fetcher, objectType]);
  return {
    variables
  };
}