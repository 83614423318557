'use es6';

import { Record } from 'immutable';
import { DEVICE_BREAKDOWN, deviceTypeToConstant } from 'EmailData/utils/details/detailTypeUtil';
const defaults = {
  browserFamily: '',
  browserName: '',
  browserType: '',
  count: 0,
  deviceType: DEVICE_BREAKDOWN.OTHER
};
class StatisticsUserAgent extends Record(defaults) {
  static from(json) {
    json.deviceType = deviceTypeToConstant(json.deviceType, json.browserType, json.browserFamily);
    return new StatisticsUserAgent(json);
  }
}
export default StatisticsUserAgent;