/* hs-eslint ignored failing-rules */
/* eslint-disable no-prototype-builtins */

import formatName from 'I18n/utils/formatName';
import { userInfoSync } from 'hub-http/userInfo';
const memoize = func => {
  const cache = {};
  return (...args) => {
    const key = JSON.stringify(args);
    if (cache.hasOwnProperty(key)) {
      return cache[key];
    }
    const result = func(...args);
    cache[key] = result;
    return result;
  };
};
export const formatUserFullNameForDisplay = user => {
  const {
    email,
    firstName,
    lastName
  } = user;
  if (!firstName || !lastName) {
    return email;
  }
  return `${formatName({
    firstName,
    lastName
  })} (${email})`;
};
export const formatFullNameAndEmailSeperately = user => {
  const {
    email,
    firstName,
    lastName
  } = user;
  if (!firstName || !lastName) {
    return {
      formattedEmail: email
    };
  }
  return {
    formattedEmail: `(${email})`,
    formattedFullName: `${formatName({
      firstName,
      lastName
    })} `
  };
};

// If both userA and userB have first/last name, sort by first name alphabetically.
// if both userA and userB only have emails, sort by email alphabetically.
// If only one user has a name, that gets sorted above users who only have an email.
export const getSortedUsers = memoize(users => {
  return [...users].sort((userA, userB) => {
    const {
      email: userAemail,
      firstName: userAfirstName,
      lastName: userAlastName
    } = userA;
    const {
      email: userBemail,
      firstName: userBfirstName,
      lastName: userBlastName
    } = userB;
    if (userAfirstName && userBfirstName) {
      return `${userAfirstName}${userAlastName}`.toLocaleLowerCase() > `${userBfirstName}${userBlastName}`.toLocaleLowerCase() ? 1 : -1;
    }
    if (userAfirstName && !userBfirstName) {
      return -1;
    }
    if (!userAfirstName && userBfirstName) {
      return 1;
    }
    return userAemail.toLocaleLowerCase() > userBemail.toLocaleLowerCase() ? 1 : -1;
  });
});
export const formatOwnerName = ownerInfo => formatName({
  firstName: ownerInfo.first_name,
  lastName: ownerInfo.last_name,
  email: ownerInfo.email
});
export const getUserId = () => userInfoSync().user.user_id;
export const getDisabledUserIdsFromAllowedUserIds = ({
  allUsers,
  allowedUserIds
}) => {
  // All users are allowed (not disabled) if allowedUserIds is undefined
  if (!allowedUserIds) {
    return [];
  }
  return allUsers.filter(user => !allowedUserIds.includes(user.id)).map(user => user.id);
};