import { createTracker } from 'usage-tracker';
import events from '../../events.yaml';
const usageTracker = createTracker({
  events
});
export const trackSubmit = stageName => {
  usageTracker.track('revenuestagechangesubmit', {
    stageName
  });
};
export const trackConditionalPropertiesPanelSubmit = () => {
  usageTracker.track('Interaction', {
    screen: `customer-data-properties`,
    subscreen: `conditional-properties-panel`,
    action: 'submit'
  });
};
export const trackShowHighlySensitiveValueClick = ({
  propertyName,
  objectType
}) => {
  usageTracker.track('Interaction', {
    screen: `customer-data-properties`,
    propertyName,
    objectType,
    action: 'show highly sensitive value'
  });
};
export const logPageView = payload => {
  usageTracker.track('Pageview', Object.assign({}, payload, {
    screen: `customer-data-properties`
  }));
};