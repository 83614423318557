import { useEffect, useMemo, useState } from 'react';
const tryGetPageHasPageBeenScrolled = () => {
  var _document$querySelect;
  // This class is present on body if the app/portal is using the redesigned nav
  // https://git.hubteam.com/HubSpot/Navigation/blob/6d3a35aada5fb34f98bc7234d04462c7ba396da4/unified-navigation-ui/static/js/utils/redesignedNavHacks/setupStickyToolbar.ts
  // https://hubspot.slack.com/archives/CBFBM9SK1/p1702921630246919?thread_ts=1702917671.384409&cid=CBFBM9SK1
  const isNewSidebarNavLayout = document.body.classList.contains('sticky-global-toolbar');
  const scrolledDistance = isNewSidebarNavLayout && ((_document$querySelect = document.querySelector('.page')) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.scrollTop) || window.scrollY;
  return scrolledDistance !== 0;
};
function parseInitialValue(initial) {
  if (initial === 'auto' || initial === 'full-height') {
    return initial;
  }
  return `${initial}px`;
}

/**
 * Calculates the maximum height @elementRef can take on screen.
 * It is calculated based on the offset top of @elementRef minus whichever @heightAffordances it might need.
 */
export default function useCalculateMaxHeight({
  elementRef,
  initial,
  heightAffordances
}) {
  const [height, setHeight] = useState(parseInitialValue(initial));
  const shouldCalculate = useMemo(() => initial === 'full-height', [initial]);

  /**
   * Recalculate table max-height by ignoring paginator's height and page padding bottom.
   */
  useEffect(() => {
    if (!shouldCalculate) {
      const newInitial = parseInitialValue(initial);
      setHeight(prevHeight => prevHeight !== newInitial ? newInitial : prevHeight);
      return undefined;
    }
    const calculate = () => {
      /**
       * Stop calculating the table height when the page has been scrolled.
       */
      if (!elementRef || tryGetPageHasPageBeenScrolled()) {
        return;
      }
      const {
        top
      } = elementRef.getBoundingClientRect();
      const newHeight = `calc(100vh - ${top + heightAffordances}px)`;
      setHeight(prevHeight => {
        if (prevHeight === newHeight) {
          return prevHeight;
        }
        return newHeight;
      });
    };
    const offsetTopObserver = new MutationObserver(calculate);
    if (elementRef) {
      offsetTopObserver.observe(elementRef, {
        attributes: true,
        childList: true,
        subtree: true
      });
      calculate();
    }
    return () => offsetTopObserver.disconnect();
  }, [elementRef, heightAffordances, initial, shouldCalculate]);
  return height;
}