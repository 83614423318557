import { useCallback } from 'react';
import { useUserInfo } from './useUserInfo';
import memoizeOne from 'react-utils/memoizeOne';
const EMPTY_SCOPES = new Set();

// scopes don't change over the lifetime of the app. use a memoized helper to
// ensure that all calls to the `useGetHasScope` hook share one Set,
// instead of each hook call/component building its own
const memoizedToScopesSet = memoizeOne(scopes => new Set(scopes));
export const useGetHasScope = () => {
  const userInfo = useUserInfo();
  const userInfoScopes = !userInfo || !userInfo.user ? EMPTY_SCOPES : memoizedToScopesSet(userInfo.user.scopes);
  return useCallback(scope => userInfoScopes.has(scope), [userInfoScopes]);
};