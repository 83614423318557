'use es6';

import { Record } from 'immutable';
const defaults = {
  creditsUsed: 0,
  portalLimit: 1000000,
  isHardLimited: null,
  warningThreshold: 800000,
  isTrial: false,
  yearMonth: {
    monthOfYear: 1,
    year: 1970
  }
};
class PortalSendCredits extends Record(defaults) {
  static from(json) {
    return new PortalSendCredits(json);
  }
  getIsHardLimited() {
    return this.get('isHardLimited');
  }
  getCreditUsed() {
    return this.get('creditsUsed');
  }
  getPortalLimit() {
    return this.get('portalLimit');
  }
  getWarningThreshold() {
    return this.get('warningThreshold');
  }
  getMonth() {
    return this.get('yearMonth').monthOfYear;
  }
  getYear() {
    return this.get('yearMonth').year;
  }
}
export default PortalSendCredits;