'use es6';

import { ensureFn } from '../core/helpers';
export function isCopyVariant(key) {
  return key.indexOf('__copym:::') !== -1;
}
export function isSwappableCopyVariant(key, treatments) {
  const [treatmentKey, variant] = deserializeCopyVariant(key);
  const treatment = treatments[treatmentKey];
  return treatment && treatment.parameters && treatment.parameters['group'] === variant;
}
export function deserializeCopyVariant(serialized) {
  const parts = serialized.split(':::');
  const key = parts[1];
  const variant = parts[2].split('__')[0];
  return [key, variant];
}
export function resolveSwappableCopies({
  translations = {},
  treatments = {},
  onError
} = {}) {
  try {
    const allTranslations = [translations, translations['translate-copy'], translations['do-not-translate-copy']];
    const flatTranslations = allTranslations.filter(branch => !!branch).reduce((acc, branch) => Object.assign({}, acc, branch), {});
    const copyVariants = Object.keys(flatTranslations).filter(isCopyVariant);
    return copyVariants.reduce((acc, key) => {
      if (isSwappableCopyVariant(key, treatments)) {
        acc[key] = flatTranslations[key];
      }
      return acc;
    }, {});
  } catch (err) {
    ensureFn(onError)(err);
    return {};
  }
}