import get from 'transmute/get';
import * as Identifiable from '../protocol/Identifiable';
import { fromJS, List, Record } from 'immutable';

// Marking all fields on the owner as optional is not the best type
// here, but it should be fine to maintain existing behavior when using the immutable
const OwnerRecord = Record({
  createdAt: 0,
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  ownerId: undefined,
  portalId: undefined,
  remoteList: List(),
  type: undefined,
  updatedAt: 0,
  signature: undefined,
  activeUserId: undefined,
  activeSalesforceId: undefined,
  // Keys present in the BE Owner immutable, but omitted from the FE's OwnerRecord:
  //
  // userIdIncludingInactive: undefined, // number | null
  // isActive: undefined, // boolean
  // hasContactsAccess: undefined, // boolean | null

  // Extras
  active: false,
  quota: 0
}, 'OwnerRecord');
Identifiable.getId.implement(OwnerRecord, get('ownerId'));
const getActive = owner => {
  return Boolean(owner.activeSalesforceId || owner.activeUserId);
};
OwnerRecord.fromJS = json => {
  const active = getActive(json);
  return OwnerRecord(Object.assign({}, json, {
    active,
    remoteList: json.remoteList ? fromJS(json.remoteList) : List()
  }));
};
export default OwnerRecord;