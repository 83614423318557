import { QUERY_PARAM_BLACKLIST, DIACRITICS_CHARACTERS } from './constants';
export function removeQueryParams(url, paramsBlacklist = [], paramsWhitelist = []) {
  const queryStringIdx = url.indexOf('?');
  const fragmentIdx = url.indexOf('#');
  const useBlacklist = paramsBlacklist.length;
  const useWhitelist = paramsWhitelist.length && !useBlacklist;
  let preFragmentUrl = url;
  let fragment = '';
  if (fragmentIdx > 0) {
    preFragmentUrl = url.substring(0, fragmentIdx);
    fragment = url.substring(fragmentIdx);
    const noListsSpecified = !useBlacklist && !useWhitelist;

    // Decoding the URIComponent so that encoded fragments will also be detected
    // This is useful to clear regexes
    // more context here https://git.hubteam.com/HubSpot/ClickMapJS/pull/126
    let decodedFragment;
    try {
      decodedFragment = decodeURIComponent(fragment);
    } catch (_error) {
      decodedFragment = fragment;
    }

    // Filter out blacklisted params from the fragment.
    // E.g. link https://www.example.com#hello&utm_source=existing&utm_medium=email&utm_campaign=123
    // becomes https://www.example.com#hello
    const fragmentSplitByQuestionMark = decodedFragment.substring(1).split(/[?]+/);
    const filteredFragmentSections = fragmentSplitByQuestionMark.map(splitFragment => {
      const allFragmentParams = splitFragment.split(/[&]+/);
      const filteredFragmentParams = allFragmentParams.filter(fragmentParam => {
        if (fragmentParam.indexOf('=') > 0) {
          const [paramKey, paramItem] = fragmentParam.split('=');
          const isWhitelisted = useWhitelist && paramsWhitelist.indexOf(paramKey) !== -1;
          const isNotBlacklisted = useBlacklist && paramsBlacklist.indexOf(paramKey) === -1;
          return noListsSpecified || isNotBlacklisted || isWhitelisted && paramItem !== '';
        }
        return true;
      });
      return filteredFragmentParams.join('&');
    });
    const reconstructedFragmentSections = filteredFragmentSections.join('?');
    if (reconstructedFragmentSections.length === 0) {
      fragment = '';
    } else {
      fragment = `#${reconstructedFragmentSections}`;
    }
  }
  if (queryStringIdx < 0) {
    return preFragmentUrl + fragment;
  }
  const queryString = preFragmentUrl.substring(queryStringIdx);
  const urlWithoutQuery = preFragmentUrl.substring(0, queryStringIdx);
  const deduplicatedQueryString = queryString.substring(1).split('&').filter((item, index, array) => {
    // Remove duplicate params
    return array.indexOf(item) === index;
  }, []);
  const allQueryParams = queryString ? deduplicatedQueryString : [];
  const filteredParams = allQueryParams.reduce((results, item) => {
    const key = item.split('=')[0];
    const noListsSpecified = !useBlacklist && !useWhitelist;
    const isNotBlacklisted = useBlacklist && paramsBlacklist.indexOf(key) === -1;
    const isWhitelisted = useWhitelist && paramsWhitelist.indexOf(key) !== -1;

    // Preserve fragments found in query params for url matching
    const preFragmentQuery = item.split('#')[0];
    const postFragmentQuery = item.split('#')[1];
    if (noListsSpecified || isNotBlacklisted || isWhitelisted) {
      if (results.length === 0) {
        results.push(item);
      } else {
        results.push(preFragmentQuery);
      }
    }
    if (postFragmentQuery && results.length > 0) {
      results[results.length - 1] = `${results[results.length - 1] || ''}#${postFragmentQuery}`;
    }
    return results;
  }, []);
  let filteredUrl = '';
  if (filteredParams.length) {
    filteredUrl = `${urlWithoutQuery}?${filteredParams.join('&')}`;
  } else {
    filteredUrl = urlWithoutQuery;
  }
  if (fragment.length) {
    filteredUrl = filteredUrl + fragment;
  }
  return filteredUrl;
}
export function removeHostnameQueryParams(url, hostname, hostnameParamsBlacklist) {
  if (!(hostname in hostnameParamsBlacklist)) {
    return url;
  }
  return removeQueryParams(url, hostnameParamsBlacklist[hostname]);
}
export function getClickMapVersion(url) {
  const regex = new RegExp('[?&]clickMapVersion(=([^&#]*)|&|#|$)');
  const version = regex.exec(url);
  if (!version) {
    return 1;
  }
  return parseInt(version[2], 10);
}
export function isExpandedClickMap(url) {
  const regex = new RegExp('[?&]ExpandedClickMap(=([^&#]*)|&|#|$)');
  const isExpanded = regex.exec(url);
  return isExpanded;
}
export function isScreenshotClickMap(url) {
  const regex = new RegExp('[?&]isScreenshot(=([^&#]*)|&|#|$)');
  const isScreenshot = regex.exec(url);
  if (isScreenshot && isScreenshot.length >= 2) {
    return isScreenshot[2].toLowerCase() === 'true';
  }
  return false;
}
export function removeProtocol(url) {
  return url.replace(/^https?:\/\//, '');
}
export function escapeRegExp(str) {
  // eslint-disable-next-line no-useless-escape
  return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
}
export function parseUrl(url) {
  var _regex$exec;
  const regex =
  // eslint-disable-next-line no-useless-escape
  /^(?:(?:([^:\/?#]+):)?(?:\/\/([^:\/?#]+)(?::([0-9]+))?)+?)?([^?#]+)?(?:\?([^#]*))?(?:#(.+))?/;
  const regexpArray = (_regex$exec = regex.exec(url)) !== null && _regex$exec !== void 0 ? _regex$exec : [];
  const [, protocol, hostname,, path, query, hash] = regexpArray;
  return {
    protocol,
    hostname,
    path,
    query,
    hash
  };
}
export function safeDecodeUrl(url) {
  try {
    let preppedUrl = url;
    const splitUrl = url.split('?');
    if (splitUrl.length > 2) {
      const encodedSplits = splitUrl.slice(2, splitUrl.length).map(segment => {
        return segment.replace(/=/gi, '%3d');
      });
      preppedUrl = `${splitUrl[0]}?${splitUrl[1]}%3F${encodedSplits.join('%3F')}`;
    }

    // Encode any ?, & or = encountered in a hash section of a url.
    const splitByHashUrl = preppedUrl.split('#');
    if (splitByHashUrl.length > 1) {
      const encodedSplits = splitByHashUrl.slice(1, splitByHashUrl.length).map(segment => {
        return segment.replace(/=/gi, '%3d').replace(/\?/i, '%3f').replace(/&/gi, '%26');
      });
      preppedUrl = `${splitByHashUrl[0]}#${encodedSplits.join('#')}`;
    }
    const replacedAmpersandString = preppedUrl.replace(/%26/gi, '%2526');
    const replacedQuestionMarkString = replacedAmpersandString.replace(/%3F/i, '%253f');
    const replacedEqualsString = replacedQuestionMarkString.replace(/%3D/gi, '%253d');
    const replacedHashsString = replacedEqualsString.replace(/%23/gi, '%2523');
    const replacedSpaceString = replacedHashsString.replace(/%20/gi, '%2520');
    const replacedíString = replacedSpaceString.replace(/C3%AD/g, 'i');
    return decodeURIComponent(replacedíString);
  } catch (ex) {
    return url;
  }
}

// Temporary fix until backend handles this error https://hubspot.slack.com/archives/CFH59DP6W/p1580217648016300
export const fixQuestionMarkRedundancy = url => {
  //If url doesn't contain 2 or more question marks in pre framgment url, let it pass
  const fragmentIdx = url.indexOf('#');
  const preFragmentUrl = fragmentIdx > -1 ? url.substring(0, fragmentIdx) : url;
  if (!(/\?/g.test(preFragmentUrl) && preFragmentUrl.match(/\?/g).length > 1)) {
    return url;
  }
  const paramsString = url.slice(url.indexOf('?') + 1);

  //If redundant question marks are included in a param url, let it pass
  if (/http/g.test(paramsString)) {
    return url;
  }
  const wrongAppend = QUERY_PARAM_BLACKLIST.find(blacklistParam => {
    const regex = new RegExp(`\\?${blacklistParam}`);
    return paramsString.match(regex);
  });
  if (!wrongAppend) {
    return url;
  }
  return url.replace(new RegExp(`\\?${wrongAppend}`), `&${wrongAppend}`);
};
export function removeTrailingSlash(url) {
  const splitUrl = url.split('?');
  let trailingSlashCleanedUrl = url.replace(/\/$/, '').replace(/\/#/, '#');
  if (splitUrl.length > 1) {
    trailingSlashCleanedUrl = `${splitUrl[0].replace(/\/$/, '').replace(/\/#/, '#')}?${splitUrl.slice(1, splitUrl.length).join('?')}`;
  }
  return trailingSlashCleanedUrl;
}
export function removeDiacritics(url) {
  const diacriticsRegex = new RegExp('[^\\\u0000-\\\u007E]', 'g');
  if (url.match(diacriticsRegex) && url.match(diacriticsRegex).length > 0) {
    const diacriticsMap = {};
    for (let i = 0; i < DIACRITICS_CHARACTERS.length; i++) {
      const letters = DIACRITICS_CHARACTERS[i].letters;
      for (let j = 0; j < letters.length; j++) {
        diacriticsMap[letters[j]] = DIACRITICS_CHARACTERS[i].base;
      }
    }
    return url.replace(diacriticsRegex, character => {
      return diacriticsMap[character] || character;
    });
  }
  return url;
}