'use es6';

import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { FETCH_STATIC_LISTS, FETCH_STATIC_LISTS_REQUEST } from 'email-management-lib/refactor/actions/actionTypes';
import IncrementalResult from 'email-management-lib/refactor/models/IncrementalResult';
const initialState = new IncrementalResult();
function lists(state = initialState, action) {
  switch (action.type) {
    case FETCH_STATIC_LISTS:
      if (action.error !== true) {
        return state.mergeDeep(action.payload.lists);
      }
      return state;
    default:
      return state;
  }
}
export default asyncResource(lists, {
  requestActions: [FETCH_STATIC_LISTS_REQUEST],
  responseActions: [FETCH_STATIC_LISTS],
  invalidatingActions: []
});