export const WHITE = '#ffffff';
export const BLACK = {
  NEUTRAL: '#33475b',
  HOVER: '#516f90',
  ACTIVE: '#33475b'
};
export const MAGENTA = {
  600: '#800051',
  500: '#a5016a',
  400: '#d20688',
  300: '#ff9fcc',
  200: '#fbdbe9',
  100: '#fcebf2'
};
export const ORANGE = {
  400: '#c93700',
  300: '#ff4800',
  200: '#ffa581',
  100: '#fbddd2'
};
export const GRADIENT = {
  LIGHT: `linear-gradient(99deg, #fbddd2, #fbdbe9)`,
  MEDIUM: `linear-gradient(135deg, #fb31a7, #ff4800, #fbddd2)`,
  DARK: `linear-gradient(114deg, #fc0849, #d20688)`
};