import { useContext } from 'react';
import { ActionsContext } from '../context/ActionsContext';
import { usePropertyDefinition } from './usePropertyDefinition';
import { useObjectType } from './useObjectType';
import { isContactEmail } from 'customer-data-objects/property/PropertyIdentifier';
export const useActionsForProperty = () => {
  const propertyDefinition = usePropertyDefinition();
  const objectType = useObjectType();
  const {
    actions
  } = useContext(ActionsContext);
  if (isContactEmail(propertyDefinition, objectType)) {
    return actions.email;
  }
  return undefined;
};