import devLogger from 'react-utils/devLogger';
import { createSearchReferenceResolver } from 'reference-resolvers-lite/createSearchReferenceResolver';
import identity from 'transmute/identity';
import { IMPORT_NAME } from '../CustomResolverReferenceTypes';
import PortalIdParser from 'PortalIdParser';
const formatImportNameByIdResponse = response => Object.entries(response.hits).map(([key, value]) => ({
  id: key,
  label: value
}));
export const createImportNameReferenceResolver = ({
  httpClient
}) => {
  return createSearchReferenceResolver({
    fetchByIds: ids => httpClient.put('/inbounddb-io/imports/get-names', {
      query: {
        portalId: PortalIdParser.get()
      },
      data: ids
    }),
    fetchBySearch: () => {
      devLogger.warn({
        message: 'Import Name resolver does not support search fetching',
        key: 'import-name-resolver'
      });
      return Promise.resolve({
        hasMore: false,
        offset: 0,
        references: []
      });
    },
    formatByIdResponse: formatImportNameByIdResponse,
    formatSearchResponse: identity,
    referenceType: IMPORT_NAME
  });
};