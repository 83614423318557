import { useFetchApiResolver, useSearchApiResolver } from './adapters/ApiHooks';
import { applyDefaultFetchAllCache } from './internals/fetchAllResolver/cache';
import { verifyReferenceType } from './internals/Invariants';
import { toFetchFailedError } from './Errors';
export const createFetchAllReferenceResolver = ({
  fetchAll,
  formatFetchAllResponse,
  referenceType
}) => {
  verifyReferenceType(referenceType);
  const fetchAllWithFormatting = () => fetchAll().then(formatFetchAllResponse).catch(error => Promise.reject(toFetchFailedError(error)));
  const baseApi = {
    all: fetchAllWithFormatting
  };
  const apiWithCaching = applyDefaultFetchAllCache(baseApi);
  return {
    api: apiWithCaching,
    referenceType,
    useFetchQuery: useFetchApiResolver,
    useSearchQuery: useSearchApiResolver
  };
};