'use es6';

import { Map as ImmutableMap, fromJS } from 'immutable';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { DISMISS_DETAILS_ACTION_MODAL, SEND_TO_MORE_CONTACTS_FETCH, SEND_TO_MORE_CONTACTS_FETCH_REQUEST, SEND_TO_MORE_CONTACTS_SEARCH, SEND_TO_MORE_CONTACTS_SEARCH_REQUEST, SEND_TO_MORE_EXCLUDE_CONTACT, SEND_TO_MORE_INCLUDE_CONTACT } from 'email-management-lib/refactor/actions/actionTypes';
import VidModel from 'EmailData/models/VidModel';
const initialState = fromJS({
  initialContacts: {},
  searchContacts: {},
  includedContacts: {},
  excludedContacts: {}
});
const sendToMoreVids = (state = initialState, action) => {
  switch (action.type) {
    case SEND_TO_MORE_CONTACTS_FETCH:
      {
        if (action.error) {
          return state;
        }
        const initialContacts = ImmutableMap(action.payload.contacts.map((contact, index) => [index, VidModel.from(contact)]));
        return state.set('initialContacts', initialContacts).set('searchContacts', initialContacts);
      }
    case SEND_TO_MORE_CONTACTS_SEARCH:
      {
        if (action.error) {
          console.error(action.payload.message);
          return state;
        }
        const searchContacts = ImmutableMap(action.payload.contacts.map((contact, index) => [index, VidModel.from(contact)]));
        return state.set('searchContacts', searchContacts);
      }
    case SEND_TO_MORE_INCLUDE_CONTACT:
      {
        return state.set('includedContacts', action.payload);
      }
    case SEND_TO_MORE_EXCLUDE_CONTACT:
      {
        return state.set('excludedContacts', action.payload);
      }
    case DISMISS_DETAILS_ACTION_MODAL:
      {
        const initialContacts = state.get('initialContacts');
        return ImmutableMap({
          initialContacts,
          searchContacts: initialContacts,
          includedContacts: ImmutableMap({}),
          excludedContacts: ImmutableMap({})
        });
      }
    default:
      return state;
  }
};
export default asyncResource(sendToMoreVids, {
  requestActions: [SEND_TO_MORE_CONTACTS_FETCH_REQUEST, SEND_TO_MORE_CONTACTS_SEARCH_REQUEST],
  responseActions: [SEND_TO_MORE_CONTACTS_FETCH, SEND_TO_MORE_CONTACTS_SEARCH],
  invalidatingActions: [DISMISS_DETAILS_ACTION_MODAL],
  customActions: [SEND_TO_MORE_INCLUDE_CONTACT, SEND_TO_MORE_EXCLUDE_CONTACT]
});