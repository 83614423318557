import { useState, useEffect, useCallback } from 'react';
export const useAddToWorkflowModalKey = hasFullEditorAccess => {
  const BASE_KEY = 'EmailComponents.modals.addToWorkflow';
  const getKey = useCallback((key, usePrefix = true) => {
    if (usePrefix) {
      const keyWithPrefix = hasFullEditorAccess ? `workflows.${key}` : `simple.${key}`;
      return `${BASE_KEY}.${keyWithPrefix}`;
    } else {
      return `${BASE_KEY}.${key}`;
    }
  }, [hasFullEditorAccess]);
  return getKey;
};
export const useGetEmailHasWorkflows = (workflows, simpleWorkflows) => {
  const [emailHasWorkflows, setEmailHasWorkflows] = useState(false);
  const [emailHasSimpleWorkflows, setEmailHasSimpleWorkflows] = useState(false);
  useEffect(() => {
    setEmailHasWorkflows(workflows && workflows.get('all') && workflows.get('all').size > 0);
    setEmailHasSimpleWorkflows(simpleWorkflows && simpleWorkflows.get('all') && simpleWorkflows.get('all').size > 0);
  }, [workflows, simpleWorkflows]);
  return [emailHasWorkflows, emailHasSimpleWorkflows];
};