import { useCallback, useMemo } from 'react';
import { useFetchTeamPermissions, useFetchTeams, useFetchUsers, useFetchUserPermissions } from 'framework-asset-management-lib';
import Raven from 'raven-js';
import { MARKETING_EMAIL_OBJECT_TYPE } from 'email-management-lib/refactor/constants/manage/frameworkTable';
import { CONTENT_TEAM_ASSIGNMENT, TEAMS_CONTENT_PARTITIONING } from 'EmailData/constants/teams';
import { assignUsersAndTeams, bulkAssignUsersAndTeams } from 'email-management-lib/refactor/actions/manage/emailActions';
import { useDispatch } from 'react-redux';
import { List } from 'immutable';
export default function useManageAccessPanel({
  isAssigningAll,
  contentIds,
  objectIds,
  onClose,
  onSuccess,
  totalObjects
}) {
  const dispatch = useDispatch();
  const {
    loading: teamsLoading,
    error: teamsError,
    teams
  } = useFetchTeams({
    viewAllAssetsScope: CONTENT_TEAM_ASSIGNMENT,
    viewTeamOwnedAssetsScope: TEAMS_CONTENT_PARTITIONING
  });
  const {
    loading: usersLoading,
    error: usersError,
    users
  } = useFetchUsers({
    viewAllAssetsScope: CONTENT_TEAM_ASSIGNMENT,
    viewTeamOwnedAssetsScope: TEAMS_CONTENT_PARTITIONING
  });
  const {
    loading: teamIdsLoading,
    teamIds,
    error: teamIdsError
  } = useFetchTeamPermissions({
    objectType: MARKETING_EMAIL_OBJECT_TYPE,
    objectIdOrIds: objectIds
  });
  const {
    loading: userIdsLoading,
    userIds,
    error: userIdsError
  } = useFetchUserPermissions({
    objectType: MARKETING_EMAIL_OBJECT_TYPE,
    objectIdOrIds: objectIds
  });
  const handleSave = useCallback(({
    selectedTeamIds,
    selectedUserIds
  }) => {
    const data = {
      users: selectedUserIds,
      teams: selectedTeamIds
    };
    const saveOptions = {
      fetchEmails: false
    };
    let assignUsersAndTeamsPromise = null;
    if (isAssigningAll) {
      assignUsersAndTeamsPromise = bulkAssignUsersAndTeams(data, totalObjects, saveOptions);
    } else {
      assignUsersAndTeamsPromise = assignUsersAndTeams(List(contentIds), data, saveOptions);
    }
    dispatch(assignUsersAndTeamsPromise).then(() => {
      onClose();
      onSuccess({
        objectIdOrIds: objectIds,
        selectedTeamIds,
        selectedUserIds
      });
    }).catch(e => Raven.captureException(e));
  }, [isAssigningAll, dispatch, totalObjects, contentIds, onClose, onSuccess, objectIds]);
  const isLoading = useMemo(() => teamIdsLoading || userIdsLoading || teamsLoading || usersLoading, [teamIdsLoading, userIdsLoading, teamsLoading, usersLoading]);
  const hasErrors = useMemo(() => teamIdsError || userIdsError || teamsError || usersError, [teamIdsError, userIdsError, teamsError, usersError]);
  return {
    teamIds,
    userIds,
    hasErrors,
    isLoading,
    handleSave,
    users,
    teams
  };
}