import I18n from 'I18n';
import http from 'hub-http/clients/apiClient';
import { useEffect, useState } from 'react';
import PortalIdParser from 'PortalIdParser';
export default function useGetUnsupportedCloningAssetsAlertConfig(campaignObjectId, use, supportedAssets, associatedUnsupportedAssets) {
  const [hasFetchedSettings, setHasFetchedSettings] = useState(false);
  const [associatedFASAssets, setAssociatedFASAssets] = useState([]);
  useEffect(() => {
    if (!hasFetchedSettings && campaignObjectId && use === 'clone') {
      setHasFetchedSettings(true);

      // Fetch framework-app-settings for campaigns associations
      // To know which asset types can be associated to a campaign
      http.post(`/framework-builder/v1/read/metadata/type/all/batch`, {
        data: ['campaignsAssociations']
      }).then(associationSettings => {
        // Fetch the counts for all defined asset types from
        // the framework app settings for this campaign know
        // which to show a warning for.
        const associationsPromise = http.post(`associations/v1/association-counts/get-counts-app`, {
          data: {
            associationRowIds: Object.keys(associationSettings).map(objectTypes => ({
              portalId: PortalIdParser.get(),
              fromObjectId: campaignObjectId,
              associationCategory: 'HUBSPOT_DEFINED',
              associationTypeId: `${associationSettings[objectTypes].campaignsAssociations.metadata.inverseAssociationId.value}`
            }))
          }
        });

        // Fetch the object definitions for all defined asset types
        // from the framework app settings for this campaign to
        // resolve the labels for.
        const objectDefinitionsPromise = http.post('inbounddb-meta/v1/object-types/batch-get', {
          data: {
            objectTypeIds: Object.keys(associationSettings)
          }
        });

        // For all associatable asset types, resolve the label and list
        // them as unsupported if they are associated to this campaign.
        return Promise.all([associationsPromise, objectDefinitionsPromise]).then(([associationCounts, objectDefinitions]) => {
          const assetsWithAssociations = associationCounts.reduce((associationsWithCounts, associationData) => {
            const objectTypeForAssociation = Object.keys(associationSettings).find(associationType => associationSettings[associationType].campaignsAssociations.metadata.inverseAssociationId.value === associationData.associationRowId.associationTypeId && associationData.numberOfAssociations > 0 && !associationsWithCounts.includes(associationType));
            return [...associationsWithCounts, objectTypeForAssociation].filter(assetType => assetType && !supportedAssets.includes(assetType));
          }, []);
          setAssociatedFASAssets([...assetsWithAssociations.map(objectType => objectDefinitions.find(obj => obj.name === objectType).pluralForm), ...associatedUnsupportedAssets]);
        }).catch(() => {});
      }).catch(() => {});
    }
  }, [campaignObjectId, hasFetchedSettings, supportedAssets, use, associatedUnsupportedAssets]);
  if (associatedFASAssets.length > 0) {
    return {
      titleText: I18n.text('campaignsLib.unsupportedCloningTypes.title'),
      contentText: I18n.text('campaignsLib.unsupportedCloningTypes.body'),
      type: 'warning',
      listItems: associatedFASAssets,
      closeable: true
    };
  }
  return undefined;
}