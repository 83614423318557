export const RSS_STATUS_TYPES = {
  FOUND_RSS_FEEDS: 'FOUND_RSS_FEEDS',
  NO_FEEDS_FOUND: 'NO_FEEDS_FOUND',
  TIMEOUT: 'TIMEOUT',
  INVALID_URL: 'INVALID_URL',
  VALIDATOR_ERROR: 'VALIDATOR_ERROR',
  HTTP_RETRIEVAL_ERROR: 'HTTP_RETRIEVAL_ERROR',
  NO_GUID_OR_LINK: 'NO_GUID_OR_LINK',
  NO_GENERATED_GUID: 'NO_GENERATED_GUID',
  FEED_EMPTY: 'FEED_EMPTY',
  FEED_OK: 'FEED_OK'
};
export const VALID_RSS_STATUS_TYPES = [RSS_STATUS_TYPES.NO_GUID_OR_LINK, RSS_STATUS_TYPES.FEED_EMPTY, RSS_STATUS_TYPES.FEED_OK, RSS_STATUS_TYPES.FOUND_RSS_FEEDS];
export const WARNING_RSS_STATUS_TYPES = [RSS_STATUS_TYPES.NO_GUID_OR_LINK, RSS_STATUS_TYPES.FEED_EMPTY];
export const RSS_STATUS_ALERT_TYPE = {
  [RSS_STATUS_TYPES.NO_GUID_OR_LINK]: 'warning',
  [RSS_STATUS_TYPES.NO_GENERATED_GUID]: 'warning',
  [RSS_STATUS_TYPES.FEED_EMPTY]: 'info'
};
export const BLOG_EMAIL_TYPES = ['instant', 'daily', 'weekly', 'monthly'];
export const RECIPIENTS_FATIGUE_SUPPRESSION_REASONS = {
  UNAVAILABLE: 'UNAVAILABLE',
  SETTING_NOT_ENABLED: 'SETTING_NOT_ENABLED',
  UPDATE_IN_PROGRESS: 'UPDATE_IN_PROGRESS',
  PRESENT: 'PRESENT'
};
export const RECIPIENT_PROPS = ['vidsIncluded', 'vidsExcluded', 'mailingListsIncluded', 'mailingListsExcluded'];
export const RECIPIENT_PROPS_WITH_ILS = ['vidsIncluded', 'vidsExcluded', 'mailingIlsListsIncluded', 'mailingIlsListsExcluded'];