import { createSearchReferenceResolver } from 'reference-resolvers-lite/createSearchReferenceResolver';
import { EMAIL_CAMPAIGN } from '../CustomResolverReferenceTypes';
import PortalIdParser from 'PortalIdParser';
const formatEmailCampaignsByIdResponse = emailCampaignApiResponse => Object.entries(emailCampaignApiResponse).map(([id, campaign]) => ({
  id,
  label: campaign.name
}));
const formatEmailCampaignsSearchResponse = searchResponse => ({
  hasMore: searchResponse.hasMore,
  offset: searchResponse.offset,
  references: searchResponse.results.map(emailCampaign => ({
    id: `${emailCampaign.id}`,
    label: emailCampaign.name
  }))
});
export const createEmailCampaignReferenceResolver = ({
  httpClient
}) => {
  return createSearchReferenceResolver({
    fetchByIds: ids => httpClient.put('/cosemail/v1/emails/search/by-ids', {
      query: {
        portalId: PortalIdParser.get()
      },
      data: {
        emailCampaignIds: ids
      }
    }),
    fetchBySearch: searchQuery => httpClient.get('/cosemail/v1/emails/search', {
      query: {
        portalId: PortalIdParser.get(),
        limit: searchQuery.count,
        offset: searchQuery.offset,
        q: searchQuery.query
      }
    }),
    formatByIdResponse: formatEmailCampaignsByIdResponse,
    formatSearchResponse: formatEmailCampaignsSearchResponse,
    referenceType: EMAIL_CAMPAIGN
  });
};