import { DEFAULT_PAGE_SIZE } from './pagination';
import { DESC } from './sorts';
export const INITIAL_FILTER_STATE = {
  filterGroups: [{
    filters: []
  }],
  pagination: {
    count: DEFAULT_PAGE_SIZE,
    offset: 0
  },
  query: '',
  quickFilters: [],
  requestOptions: {
    allPropertiesFetchMode: 'latest_version',
    includeAllProperties: true
  },
  sort: {
    property: 'hs_created_at',
    order: DESC
  },
  associationPreviews: {}
};