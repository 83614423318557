'use es6';

import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { RESET_EMAIL_DETAILS } from 'EmailData/actions/actionTypes';
import { FETCH_REVENUE_OVER_TIME_REQUEST, FETCH_REVENUE_OVER_TIME_RESULT } from 'email-management-lib/refactor/actions/actionTypes';
import RevenueOverTimeStats from 'email-management-lib/refactor/models/RevenueOverTimeStats';
const initialState = RevenueOverTimeStats.from({});
function revenueOverTimeStatistics(state = initialState, action) {
  switch (action.type) {
    case FETCH_REVENUE_OVER_TIME_RESULT:
      if (action.error) {
        return state;
      }
      return RevenueOverTimeStats.from(action.payload);
    case RESET_EMAIL_DETAILS:
      return initialState;
    default:
      return state;
  }
}
export default asyncResource(revenueOverTimeStatistics, {
  requestActions: [FETCH_REVENUE_OVER_TIME_REQUEST],
  responseActions: [FETCH_REVENUE_OVER_TIME_RESULT],
  invalidatingActions: [RESET_EMAIL_DETAILS]
});