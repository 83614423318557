import PropTypes from 'prop-types';
import PropertyOptionDefinitionPropType from './PropertyOptionDefinitionPropType';

/**
 * The shape of a property definition expected by PropertyInputV2.
 * These fields are either available from REST via `properties/v4` or from GraphQL.
 */
export default PropTypes.shape({
  calculated: PropTypes.bool,
  currencyPropertyName: PropTypes.string,
  deleted: PropTypes.bool,
  description: PropTypes.string,
  displayMode: PropTypes.string,
  displayOrder: PropTypes.number,
  externalOptions: PropTypes.bool,
  externalOptionsReferenceType: PropTypes.string,
  fieldType: PropTypes.string,
  formField: PropTypes.bool,
  groupName: PropTypes.string,
  hasUniqueValue: PropTypes.bool,
  hidden: PropTypes.bool,
  hubspotDefined: PropTypes.bool,
  isCustomizedDefault: PropTypes.bool,
  label: PropTypes.string,
  mutableDefinitionNotDeletable: PropTypes.bool,
  name: PropTypes.string.isRequired,
  numberDisplayHint: PropTypes.string,
  options: PropTypes.arrayOf(PropertyOptionDefinitionPropType.isRequired),
  optionsAreMutable: PropTypes.bool,
  optionSortStrategy: PropTypes.string,
  readOnlyDefinition: PropTypes.bool,
  readOnlyValue: PropTypes.bool,
  referencedObjectType: PropTypes.string,
  searchableInGlobalSearch: PropTypes.bool,
  showCurrencySymbol: PropTypes.bool,
  textDisplayHint: PropTypes.string,
  type: PropTypes.string

  // Fields we intentionally omit (from the definition in contacts.proto):
  // * favorited - deprecated and unused
  // * favoritedOrder - deprecated and unused
  // * isPartial - duplicate; it represents the same concept as 'isCustomizedDefault'
  // * portalId - callers implicitly know the portalId already
  // * relatedProperty - unused outside of bizops/BET
  // * tags - unused outside PUMA/property management
  // * metadata - unused outside PUMA/property management
  // * createdAt - because we can't provide createdUserId as well
  // * createdUserId - GQL and REST disagree on naming; createdUserId vs. createdBy {...}
  // * updatedAt - because we can't provide createdUserId as well
  // * updatedUserId - GQL and REST disagree on naming; createdUserId vs. createdBy {...}
});