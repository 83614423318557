'use es6';

import { RECEIVE_SENDING_LIMIT_EMAILS, REQUEST_SENDING_LIMIT_EMAILS } from 'EmailData/actions/actionTypes';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { List } from 'immutable';
import Email from 'EmailData/models/Email';
const initialState = List();
const sendingLimitEmailsReducer = (state = initialState, {
  type,
  response
}) => {
  switch (type) {
    case RECEIVE_SENDING_LIMIT_EMAILS:
      return List(response.map(Email.from));
    default:
      return state;
  }
};
export default asyncResource(sendingLimitEmailsReducer, {
  requestActions: [REQUEST_SENDING_LIMIT_EMAILS],
  responseActions: [RECEIVE_SENDING_LIMIT_EMAILS],
  invalidatingActions: []
});