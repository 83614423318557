import { EQ, NOT_HAS_PROPERTY } from 'framework-listing-lib/utils/filters';
export const UNFILED_ASSETS_ID = -1;
export const FOLDER_OBJECT_TYPE = '0-97';
export const FOLDER_ID = 'hs_folder_id';
export const PARENT_FOLDER_ID = 'hs_parent_folder_id';

// Identified for dragging assets in/out of folders
export const FOLDER_ASSET_DND = 'FOLDER_ASSET_DND';
export const FOLDER_PROPERTIES = {
  enabledObjectType: 'hs_enabled_object_type',
  name: 'hs_name',
  objectCount: 'hs_total_object_count',
  objectId: 'hs_object_id',
  parentFolderId: PARENT_FOLDER_ID
};
export const ASSETS_WITHOUT_FOLDERS_FILTER = [{
  filters: [NOT_HAS_PROPERTY(FOLDER_ID)]
}, {
  filters: [EQ(FOLDER_ID, 0)]
}];
export const FOLDERS_POLLING_INTERVAL = 3 * 1000;
export const DRAG_EVENT_KEY = 'dragData';