/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-misused-promises */

import quickFetch from 'quick-fetch';
export function readEarlyRequest({
  key,
  fetchCallback
}) {
  const earlyRequest = quickFetch.getRequestStateByName(key);
  if (earlyRequest) {
    return new Promise((resolve, reject) => {
      earlyRequest.whenFinished(result => {
        quickFetch.removeEarlyRequest(key);
        resolve(result);
      });
      earlyRequest.onError(() => {
        quickFetch.removeEarlyRequest(key);
        return fetchCallback().then(resolve, reject);
      });
    });
  }
  return fetchCallback();
}