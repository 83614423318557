'use es6';

import { List, Map as ImmutableMap, OrderedMap } from 'immutable';
import { REHYDRATE_FROM_USER_SETTINGS, RESET_EMAIL_DETAILS } from 'EmailData/actions/actionTypes';
import { BLOG_RSS_BEGIN_COLUMN_CUSTOMIZATION, BLOG_RSS_SAVE_COLUMN_CUSTOMIZATION, BLOG_RSS_SET_COLUMNS, BLOG_RSS_SET_SHOW_SKIPPED, BLOG_RSS_SET_TABLE_SORT, FETCH_BLOG_RSS_HISTORY, FETCH_BLOG_RSS_HISTORY_REQUEST } from 'email-management-lib/refactor/actions/actionTypes';
import { COLUMN_PUBLISH_DATE, HISTORY_DEFAULT_COLUMNS, SORT_DIRECTIONS, TABLE_PAGE_SIZE } from 'email-management-lib/refactor/constants/details/blogRssConstants';
import { getTableFilterTypeKey } from 'email-management-lib/refactor/selectors/details/current/currentEmailBlogRss';
import BlogRssSendAttempt from 'email-management-lib/refactor/models/details/BlogRssSendAttempt';
import IncrementalFilteredResult from 'email-management-lib/refactor/models/IncrementalFilteredResult';
const FILTER_PAGE_SIZE = TABLE_PAGE_SIZE;
const initialState = ImmutableMap({
  emails: new IncrementalFilteredResult(),
  isCustomizingColumns: false,
  showSkipped: true,
  orderField: COLUMN_PUBLISH_DATE,
  orderDirection: SORT_DIRECTIONS.DESC,
  visibleColumns: List([...HISTORY_DEFAULT_COLUMNS]),
  isLoading: false
});
export default function blogRssHistoryTable(state = initialState, action) {
  switch (action.type) {
    case BLOG_RSS_BEGIN_COLUMN_CUSTOMIZATION:
      return state.set('isCustomizingColumns', true);
    case BLOG_RSS_SAVE_COLUMN_CUSTOMIZATION:
      return state.set('isCustomizingColumns', false);
    case BLOG_RSS_SET_COLUMNS:
      return state.merge({
        visibleColumns: action.payload.columns
      });
    case BLOG_RSS_SET_SHOW_SKIPPED:
      return state.set('showSkipped', action.payload);
    case BLOG_RSS_SET_TABLE_SORT:
      return state.merge({
        orderDirection: action.payload.orderDirection,
        orderField: action.payload.orderField
      });
    case FETCH_BLOG_RSS_HISTORY_REQUEST:
      return state.set('isLoading', true);
    case FETCH_BLOG_RSS_HISTORY:
      if (action.payload.result) {
        const emails = getHistoryItemsToMerge(state, action.payload);
        return state.set('emails', emails).set('isLoading', false);
      }
      return state;
    case REHYDRATE_FROM_USER_SETTINGS:
      if (action.payload.has('blogRssHistoryColumns')) {
        return state.merge({
          visibleColumns: action.payload.get('blogRssHistoryColumns')
        });
      }
      return state;
    case RESET_EMAIL_DETAILS:
      return initialState;
    default:
      return state;
  }
}

// Paginate fetched items as page sizes may be different
function getHistoryItemsToMerge(state, payload) {
  const result = payload.result;
  const orderedItems = OrderedMap({}).withMutations(map => {
    result.objects.forEach(attempt => {
      const keyId = attempt.email ? attempt.email.id : attempt.taskQueueUid;
      map.set(keyId, BlogRssSendAttempt.from(attempt));
    });
  });
  const filterTypeKey = getTableFilterTypeKey(payload.startDate, payload.endDate, state.get('orderField'), state.get('orderDirection'), state.get('showSkipped'));
  let emails = state.get('emails');
  let key = payload.key;
  const iterations = Math.ceil(orderedItems.size / FILTER_PAGE_SIZE);
  for (let i = 0; i < iterations; i++) {
    const first = i * FILTER_PAGE_SIZE;
    emails = emails.inc(orderedItems.slice(first, first + FILTER_PAGE_SIZE), result.total, filterTypeKey, key++);
  }
  return emails;
}