import { useMemo } from 'react';
import { useApolloClient } from '@apollo/client';
import useHasGraphQLCrmSearch from 'framework-listing-lib/internal/graphql/hooks/useHasGraphQLCrmSearch';

/**
 * This hook is meant to be used by folders only!
 * Some of the cache manipulation in folders is done either via DFC or Apollo.
 * In case GraphQL (crm-search) is turned on, we'll use the Apollo client instead of DFC client.
 */
export default function useMaybeApolloClient() {
  const hasGraphQLCrmSearch = useHasGraphQLCrmSearch();
  const apolloClient = useApolloClient();
  return useMemo(() => hasGraphQLCrmSearch ? apolloClient : undefined, [apolloClient, hasGraphQLCrmSearch]);
}