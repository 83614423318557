import { useCallback, useEffect, useMemo, useRef } from 'react';
import { hasAlreadyCampaignAssociated } from '../utils/campaignSelect';
import useCampaignToAutoSelect from './useCampaignToAutoSelect';
export default function useCampaignToAutoSelectPopover({
  forceClose,
  hasDismissedPopover,
  onSelect,
  setHasDismissedPopover,
  setValueFromAttributes,
  valueFromProps
}) {
  const hasCheckedIfAssociated = useRef(false);
  const {
    campaignToAutoSelect
  } = useCampaignToAutoSelect();
  const hasCampaignAssociated = useMemo(() => hasAlreadyCampaignAssociated(valueFromProps), [valueFromProps]);
  const isPopoverOpen = useMemo(() => Boolean(campaignToAutoSelect) && !hasCampaignAssociated && !hasCheckedIfAssociated.current && !hasDismissedPopover && !forceClose, [forceClose, hasDismissedPopover, campaignToAutoSelect, hasCampaignAssociated]);
  const dismiss = useCallback(() => {
    setHasDismissedPopover(true);
    setValueFromAttributes('');
  }, [setHasDismissedPopover, setValueFromAttributes]);
  const onYes = useCallback(() => {
    setHasDismissedPopover(true);
    onSelect(campaignToAutoSelect);
  }, [campaignToAutoSelect, setHasDismissedPopover, onSelect]);

  // only setValueFromAttributes if asset is not yet associated with any campaign
  // and forceClose is false
  useEffect(() => {
    if (campaignToAutoSelect) {
      if (campaignToAutoSelect.guid && !hasCheckedIfAssociated.current && !hasCampaignAssociated && !forceClose) {
        setValueFromAttributes(campaignToAutoSelect.guid);
      }
      hasCheckedIfAssociated.current = true;
    }
  }, [campaignToAutoSelect, setValueFromAttributes, hasCampaignAssociated, hasCheckedIfAssociated, forceClose]);
  useEffect(() => {
    if (forceClose) {
      dismiss();
    }
  }, [forceClose, dismiss]);
  return {
    isPopoverOpen,
    onYes,
    onNo: dismiss
  };
}