import http from 'hub-http/clients/apiClient';
import I18n from 'I18n';
const BASE_URL = 'tool-access/v1';
export default {
  fetchRequestByScope: scope => {
    // @ts-expect-error langEnabled not on I18n type def
    const lang = I18n.langEnabled ? I18n.lang : 'en';
    return http.get(`${BASE_URL}/requests/active/${scope}`, {
      query: {
        locale: lang
      }
    });
  },
  createRequest: data => http.post(`${BASE_URL}/requests`, {
    data
  })
};