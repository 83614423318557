'use es6';

import { Record } from 'immutable';
const defaults = {
  apiEmailCampaignId: null,
  appId: null,
  freezeDate: null,
  id: null,
  listIntersectionIncluded: null,
  previewKey: '',
  sendingProblem: ''
};
class RssEmailData extends Record(defaults) {
  static from(json) {
    return new RssEmailData(json);
  }
  mergeWith(primaryEmail) {
    return primaryEmail.set('id', this.id).set('appId', this.appId).set('previewKey', this.previewKey).set('primaryEmailCampaignId', this.apiEmailCampaignId);
  }
}
export default RssEmailData;