'use es6';

import * as userAttributes from 'email-management-lib/refactor/constants/userAttributes';
import { RECEIVE_USER_ATTRIBUTES, RECEIVE_USER_ATTRIBUTES_REQUEST, SET_USER_ATTRIBUTE, SET_USER_ATTRIBUTE_REQUEST } from 'email-management-lib/refactor/actions/actionTypes';
import { getUserAttributesHasLoaded } from 'email-management-lib/refactor/selectors/userAttributes';
import { getUserAttributesRequest, setUserAttributeRequest } from 'email-management-lib/refactor/api/userAttributes';
export function fetchUserAttributes() {
  return (dispatch, getState) => {
    const state = getState();
    if (!getUserAttributesHasLoaded(state)) {
      dispatch({
        type: RECEIVE_USER_ATTRIBUTES_REQUEST
      });
      const keys = Object.keys(userAttributes).map(a => userAttributes[a]);
      return getUserAttributesRequest({
        keys
      }).then(response => {
        dispatch({
          type: RECEIVE_USER_ATTRIBUTES,
          payload: response
        });
      });
    }
    return Promise.resolve();
  };
}
export function setUserAttribute(key, value) {
  return dispatch => {
    dispatch({
      type: SET_USER_ATTRIBUTE_REQUEST,
      key,
      value
    });
    return setUserAttributeRequest(key, value).then(response => {
      dispatch({
        type: SET_USER_ATTRIBUTE,
        payload: response
      });
    });
  };
}