'use es6';

import { REHYDRATE_FROM_USER_SETTINGS } from 'EmailData/actions/actionTypes';
import { MAYBE_LATER_DISMISS_ONBOARDING_TOUR_ENTRY } from 'email-management-lib/refactor/actions/actionTypes';
const INITIAL_STATE = undefined;
export default function dismissedOnboardingTourTime(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REHYDRATE_FROM_USER_SETTINGS:
      if (action.payload.has('dismissedOnboardingTourTime')) {
        return action.payload.get('dismissedOnboardingTourTime');
      }
      return state;
    case MAYBE_LATER_DISMISS_ONBOARDING_TOUR_ENTRY:
      return Date.now();
    default:
      return state;
  }
}