import { ContentStates } from 'ContentComponents/constants/ContentStates';
const getContentState = function getContentState(status) {
  switch (status) {
    case ContentStates.PUBLISHED:
    case ContentStates.PUBLISHED_AB:
    case ContentStates.PUBLISHED_AB_VARIANT:
    case ContentStates.PUBLISHED_OR_SCHEDULED:
    case ContentStates.AUTOMATED:
    case ContentStates.AUTOMATED_AB:
    case ContentStates.AUTOMATED_AB_VARIANT:
    case ContentStates.AUTOMATED_LOSER_ABVARIANT:
    case ContentStates.AUTOMATED_FOR_FORM:
    case ContentStates.AUTOMATED_FOR_FORM_LEGACY:
    case ContentStates.APPROVED:
    case ContentStates.BLOG_EMAIL_PUBLISHED:
    case ContentStates.RSS_TO_EMAIL_PUBLISHED:
    case ContentStates.AUTOMATED_LEADFLOW_EMAIL:
      {
        return 'published';
      }
    case ContentStates.DRAFT:
    case ContentStates.DRAFT_AB:
    case ContentStates.DRAFT_AB_VARIANT:
    case ContentStates.AUTOMATED_DRAFT:
    case ContentStates.AUTOMATED_DRAFT_ABVARIANT:
    case ContentStates.AUTOMATED_DRAFT_AB:
    case ContentStates.AUTOMATED_FOR_FORM_DRAFT:
    case ContentStates.ARCHIVED:
    case ContentStates.PENDING_AKISMET:
    case ContentStates.PENDING_MODERATION:
    case ContentStates.BLOG_EMAIL_DRAFT:
    case ContentStates.RSS_TO_EMAIL_DRAFT:
      {
        return 'draft';
      }
    case ContentStates.SCHEDULED:
    case ContentStates.SCHEDULED_AB:
      {
        return 'scheduled';
      }
    case ContentStates.ERROR:
    case ContentStates.SPAM:
    case ContentStates.REJECTED:
      {
        return 'error';
      }
    case ContentStates.PROCESSING:
    case ContentStates.PRE_PROCESSING:
      {
        return 'thunderdome';
      }
    default:
      {
        return 'default';
      }
  }
};
export default getContentState;