import { Record } from 'immutable';
import * as FromFieldConstants from 'EmailData/constants/FromFieldConstants';
class EmailAddress extends Record({
  email: '',
  type: '',
  verified: true,
  pendingVerification: false
}) {
  static from(json) {
    return new EmailAddress({
      email: (json.content || '').toLowerCase(),
      type: FromFieldConstants[json.type],
      verified: json.isVerified,
      pendingVerification: json.isPendingVerification || false
    });
  }
  getEmail() {
    return this.get('email').toLowerCase();
  }
  getIsVerified() {
    return this.get('verified');
  }
  getIsPendingVerification() {
    return this.get('pendingVerification');
  }
  getIsHubspotUser() {
    return this.get('type') === 'USER';
  }
}
export default EmailAddress;