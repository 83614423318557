'use es6';

import { Map as ImmutableMap } from 'immutable';
import { createSelector } from 'reselect';
import ListMetaData from 'EmailData/models/contacts/ListMetaData';
export const allListsSelector = state => state.getIn(['entities', 'lists']).getData();
export const allIlsListsSelector = state => {
  if (!state.hasIn(['entities', 'ilsLists'])) return ImmutableMap();
  return state.getIn(['entities', 'ilsLists']).getData();
};
const processingListsSelector = createSelector([allListsSelector], all => all.filter(list => list.getIn(['metaData', 'processing']) === ListMetaData.PROCESSING_STATE.PROCESSING));
const erroredListsSelector = createSelector([allListsSelector], all => all.filter(list => list.getIn(['metaData', 'processing']) === ListMetaData.PROCESSING_STATE.ERRORY_WILL_NOT_RETRY));
const deletedListsSelector = createSelector([allListsSelector], all => all.filter(list => list.get('deleted', false)));
export const hasListsEverLoaded = state => state.getIn(['entities', 'lists']).hasEverLoaded();
export const listSelector = createSelector([allListsSelector, allIlsListsSelector, processingListsSelector, erroredListsSelector, deletedListsSelector], (allClsList, allIlsList, processingLists, erroredLists, deletedLists) => {
  const all = allClsList.concat(allIlsList);
  return ImmutableMap({
    all: all.toList(),
    processing: processingLists.toList(),
    errored: erroredLists.toList(),
    deleted: deletedLists.toList()
  });
});