import http from 'hub-http/clients/apiClient';
export const updateCrmObject = ({
  objectTypeId,
  objectId,
  propertyValues
}) => http.put(`inbounddb-objects/v1/crm-objects/${encodeURIComponent(objectTypeId)}`, {
  data: [{
    objectId,
    propertyValues
  }],
  headers: {
    'x-properties-source': 'CRM_UI'
  }
});