export const get = key => {
  try {
    if ('localStorage' in window) {
      return window.localStorage.getItem(key);
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
  return undefined;
};
export const set = (key, value) => {
  try {
    if ('localStorage' in window) {
      return window.localStorage.setItem(key, value);
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
  return undefined;
};
export const remove = key => {
  try {
    if ('localStorage' in window) {
      return window.localStorage.removeItem(key);
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
  return undefined;
};