'use es6';

import { Record } from 'immutable';
import Suspension from './Suspension';
const defaults = {
  authorized: true,
  message: '',
  reason: '',
  sendCredits: null,
  source: '',
  suspension: null
};
class PortalEmailStatus extends Record(defaults) {
  static from(json) {
    if (typeof json.suspension !== 'undefined') {
      json.suspension = Suspension.from(json.suspension);
    }
    return new PortalEmailStatus(json);
  }
  isSuspended() {
    return this.authorized === false;
  }
}
export default PortalEmailStatus;