'use es6';

import { List } from 'immutable';
import { MIN_CLICKS_CUTTOFF_WHEN_THROTTLED, UNIQUE_LINKS_THRESHOLD } from 'email-management-lib/refactor/constants/details/detailsConstants';
import CampaignLinkStats from './CampaignLinkStats';
class CampaignClicks {
  static from(json) {
    if (json.urlClicks === undefined) {
      return List([]);
    }
    const urlLinks = Object.keys(json.urlClicks);

    // If more than threshold links, only process those with more than cutoff clicks for perf reasons.
    if (urlLinks.length > UNIQUE_LINKS_THRESHOLD) {
      return List(urlLinks.filter(link => {
        return json.urlClicks[link] > MIN_CLICKS_CUTTOFF_WHEN_THROTTLED;
      }).map(link => CampaignLinkStats.from({
        link,
        metric: json.urlClicks[link]
      })));
    }
    return List(urlLinks.map(link => CampaignLinkStats.from({
      link,
      metric: json.urlClicks[link]
    })));
  }
}
export default CampaignClicks;