import { useContext, useMemo } from 'react';
import { HighlySensitivePropertyContext } from './HighlySensitivePropertyContext';
export const useAllHighlySensitivePropertyValues = ({
  objectTypeId,
  defaultValues
}) => {
  const context = useContext(HighlySensitivePropertyContext);
  if (!context && !defaultValues) {
    throw new Error('useHighlySensitivePropertyValues must be used within a HighlySensitivePropertyContextProvider');
  }
  return useMemo(() => {
    if (!context) {
      return defaultValues;
    }
    return Array.from(context.propertyValuesMap.values()).filter(propertyValue => propertyValue.objectTypeId === objectTypeId && propertyValue.status === 'SUCCEEDED' && propertyValue.timestamp === undefined).reduce((acc, propertyValue) => {
      var _propertyValue$object;
      acc[_propertyValue$object = propertyValue.objectId] || (acc[_propertyValue$object] = {});
      acc[propertyValue.objectId][propertyValue.propertyName] = propertyValue.value;
      return acc;
    }, {});
  }, [context, defaultValues, objectTypeId]);
};