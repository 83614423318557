import { Record } from 'immutable';
import I18n from 'I18n';
import Raven from 'raven-js';
export const PROCESSING_STATE = {
  INITIALIZING: 'INITIALIZING',
  PROCESSING: 'PROCESSING',
  DONE: 'DONE',
  REFRESHING: 'REFRESHING',
  ERROR_WILL_RETRY: 'ERROR_WILL_RETRY',
  ERROR_WILL_NOT_RETRY: 'ERROR_WILL_NOT_RETRY',
  DELETING: 'DELETING',
  WAITING_ON_RATE_LIMIT: 'WAITING_ON_RATE_LIMIT',
  IMPORTING: 'IMPORTING'
};
export const defaults = {
  metaData: {
    error: '',
    processing: '',
    size: 0
  },
  name: '',
  portalId: -1,
  listId: -1,
  ilsListId: -1,
  listType: '',
  internalListId: -1,
  dynamic: false,
  archived: false,
  filters: [],
  deleted: false,
  createdAt: -1,
  id: -1
};
class ListModel extends Record(defaults) {
  static from(json) {
    try {
      const nestedList = 'list' in json ? json.list : json;
      nestedList.id = nestedList.listId;
      return new ListModel(nestedList);
    } catch (error) {
      Raven.captureMessage('Error when converting to ListModel', {
        extra: {
          json,
          error
        }
      });
      return null;
    }
  }
  getUrlRoot() {
    return '/contacts/v1/lists';
  }
  getName() {
    this.get('name');
  }
  getUrl() {
    return `/lists/${this.get('portalId')}/list/${this.get('listId')}`;
  }
  isDeleted() {
    return this.get('deleted') === true;
  }
  isProcessing() {
    return this.get('metaData').processing === PROCESSING_STATE.PROCESSING;
  }
  isInitializing() {
    return this.get('metaData').processing === PROCESSING_STATE.INITIALIZING;
  }
  isInErrorStateWithRetry() {
    return this.get('metaData').processing === PROCESSING_STATE.ERROR_WILL_RETRY;
  }
  isImporting() {
    return this.get('metaData').processing === PROCESSING_STATE.IMPORTING;
  }
  isDone() {
    return this.get('metaData').processing === PROCESSING_STATE.DONE;
  }
  isRefreshing() {
    return this.get('metaData').processing === PROCESSING_STATE.REFRESHING;
  }
  isDeleting() {
    return this.get('metaData').processing === PROCESSING_STATE.DELETING;
  }
  isWaitingOnRateLimit() {
    return this.get('metaData').processing === PROCESSING_STATE.WAITING_ON_RATE_LIMIT;
  }
  isInErrorState() {
    return this.get('metaData').processing === PROCESSING_STATE.ERROR_WILL_NOT_RETRY;
  }
  getId() {
    return this.get('id');
  }
  getHyperlink() {
    return `<a href=${this.getUrl()}>${this.get('name')}</a>`;
  }
  getSize() {
    return this.get('metaData').size || 0;
  }
  getComputedName() {
    const formattedSize = I18n.formatNumber(this.getSize());
    return `${this.get('name')} (${formattedSize})`;
  }
}
export default ListModel;