import { BETWEEN, HAS_PROPERTY } from 'marketing-platform-lib/utils/filters';
import { combineFilters } from 'framework-listing-lib/utils/filters';
import ViewType from '../constants/ViewType';
import { getCalendarMomentFromTimestamp } from './time';

// Originally copied from https://git.hubteam.com/HubSpot/marketing-orchestration-ui/blob/9e8fd9c2bd23527c29f887db2aa2bdf7cbe61dfb/marketing-orchestration-ui/static/js/calendar/utils/calendar.js#L6
function getVisibleDateRange({
  displayDate,
  viewType
}) {
  switch (viewType) {
    case ViewType.WEEK:
      return {
        startDate: displayDate.clone().startOf('week'),
        endDate: displayDate.clone().endOf('week')
      };
    case ViewType.DAY:
      return {
        startDate: displayDate.clone().startOf('day'),
        endDate: displayDate.clone().endOf('day')
      };
    case ViewType.MONTH:
    default:
      return {
        startDate: displayDate.clone().startOf('month').startOf('week'),
        endDate: displayDate.clone().endOf('month').endOf('week')
      };
  }
}
export function getCalendarFilterGroups({
  dateRangeEndTime,
  dateRangeStartTime,
  endPropertyName,
  startPropertyName
}) {
  if (!endPropertyName) {
    return [{
      filters: [BETWEEN(startPropertyName, dateRangeStartTime, dateRangeEndTime)]
    }];
  }

  // If the endPropertyName is defined, then the event must have both an end and a start property to be valid
  return [{
    filters: [BETWEEN(startPropertyName, dateRangeStartTime, dateRangeEndTime), HAS_PROPERTY(endPropertyName)]
  }, {
    filters: [BETWEEN(endPropertyName, dateRangeStartTime, dateRangeEndTime), HAS_PROPERTY(startPropertyName)]
  }];
}
export function updateHiddenFilterForCalendarDisplayDate({
  displayTimestamp,
  endPropertyName,
  hiddenFilter,
  startPropertyName,
  viewType
}) {
  const displayDate = getCalendarMomentFromTimestamp(displayTimestamp);
  const {
    startDate,
    endDate
  } = getVisibleDateRange({
    displayDate,
    viewType
  });
  const calendarFilterGroups = getCalendarFilterGroups({
    dateRangeStartTime: startDate.valueOf(),
    dateRangeEndTime: endDate.valueOf(),
    startPropertyName,
    endPropertyName
  });
  const nonNullHiddenFilter = hiddenFilter || {
    filterGroups: [{
      filters: []
    }]
  };
  return {
    filterGroups: combineFilters(nonNullHiddenFilter.filterGroups, calendarFilterGroups)
  };
}