import PropTypes from 'prop-types';

/**
 * PropType matching Value TS type. It represents a property value:
 *
 * - `undefined` = no value has ever been set for this property
 *
 * - `null` = no value has ever been set for this property, and the value
 *    was fetched via graphql (which doesn't have an `undefined` type, only `null`)
 *
 * - `''` = (empty string) property has been cleared, either manually or via an
 *    api/workflow. empty string and null/undefined values should be treated the same.
 *
 * - `'...'` = standard property value
 */
export default PropTypes.string;