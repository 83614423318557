import { getRelativeDateTimeLabel } from './getRelativeDateTimeLabel';
import I18n from 'I18n';
import { getDateDisplayHintLabel } from './getDateDisplayHintLabel';
export const getDisplayedValueForDateTime = (property, value, dateTimeFormat, relativeDateTime, showDateDisplayHintLabel) => {
  let displayValue = '';
  const maybeRelativeLabel = relativeDateTime && property.dateDisplayHint !== 'time_since' && property.dateDisplayHint !== 'time_until' ? getRelativeDateTimeLabel({
    value,
    isDateTime: true
  }) : null;

  // time_since and time_until properties should be treated as duration.
  // So, they should never show the date.
  // showDateDisplayHintLabel is a temporary argument to this function since this library does not have access to gates
  // once CRM:PropMgmt:TimeSinceUntil is ungated to 100% we can remove this argument
  const shouldShowDateDisplayHintLabel = showDateDisplayHintLabel || property.dateDisplayHint === 'time_since' || property.dateDisplayHint === 'time_until';
  const maybeDateDisplayHintLabel = shouldShowDateDisplayHintLabel ? getDateDisplayHintLabel({
    value,
    type: property.type,
    dateDisplayHint: property.dateDisplayHint
  }) : null;
  if (maybeRelativeLabel) {
    displayValue = maybeRelativeLabel;
    if (property.dateDisplayHint === 'absolute_with_relative' && maybeDateDisplayHintLabel) {
      displayValue = `${displayValue} (${maybeDateDisplayHintLabel})`;
    }
  } else {
    displayValue = I18n.moment.userTz(Number(value)).format(dateTimeFormat);
    if (maybeDateDisplayHintLabel) {
      displayValue = property.dateDisplayHint === 'time_since' || property.dateDisplayHint === 'time_until' ? `${maybeDateDisplayHintLabel}` : `${displayValue} (${maybeDateDisplayHintLabel})`;
    }
  }
  return displayValue;
};