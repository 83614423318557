// Selection Type
export const SELECTION_TYPE_USER = 'USER';
export const SELECTION_TYPE_TEAM = 'TEAM';
export const SELECTION_TYPE_CUSTOM = 'CUSTOM';

// Selection ID
export const SELECTION_ID_ALL = 'ALL';
export const SELECTION_ID_ME = 'ASSIGNED_TO_USER';
export const SELECTION_ID_MY_TEAMS = 'ASSIGNED_TO_USERS_TEAM';

// Property Names
export const USERS_PROPERTY_NAME = 'hs_user_ids_of_all_owners';
export const TEAMS_PROPERTY_NAME = 'hs_all_team_ids';
export const ALL_TEAMS_OPTION = {
  id: SELECTION_ID_ALL,
  type: SELECTION_TYPE_CUSTOM
};
export const MY_TEAMS_OPTION = {
  id: SELECTION_ID_MY_TEAMS,
  type: SELECTION_TYPE_CUSTOM
};
export const ME_OPTION = {
  id: SELECTION_ID_ME,
  type: SELECTION_TYPE_CUSTOM
};