'use es6';

import { getHttpClientAsLastParam } from 'EmailData/helpers/apiHelpers';
export const validateRssUrl = (url, ...rest) => {
  const http = getHttpClientAsLastParam(rest);
  return http.get('cosemail/v1/rssfeeds/validate', {
    query: {
      url
    }
  });
};