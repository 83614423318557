'use es6';

import I18n from 'I18n';
import sortOptions from './sortOptions';
import { currencyFormatters, currencyStyles } from 'I18n/internal/utils/CurrencyLabelFormatters';
export const currencyOptions = {
  [currencyStyles.short]: [],
  [currencyStyles.long]: []
};
export const getCurrencyOptions = ({
  currencyStyle,
  currenciesFilter,
  validCurrencies
}) => {
  const filter = currenciesFilter || (currency => {
    return validCurrencies.indexOf(currency.value) >= 0;
  });
  const initialOptions = currencyOptions[currencyStyle];
  if (!validCurrencies && !currenciesFilter && initialOptions.length) {
    return initialOptions;
  } else if (initialOptions.length) {
    return initialOptions.filter(filter);
  }
  currencyOptions[currencyStyle] = Object.keys(I18n.currencySymbols).map(code => {
    return {
      text: currencyFormatters[currencyStyle](code),
      value: code
    };
  }).sort(sortOptions);
  if (validCurrencies || currenciesFilter) {
    return currencyOptions[currencyStyle].filter(filter);
  }
  return currencyOptions[currencyStyle];
};