import http from 'hub-http/clients/apiClient';
import { registerMutation, useMutation } from 'data-fetching-client';
import { useCallback } from 'react';
import { PINNED_VIEWS_QUERY, PinnedViewsQuery } from './useFetchPinnedViews';

/* this takes in an objectTypeId and a namespace and returns a formatted url to set pinned views from the views api
 */
const getUrl = ({
  objectTypeId,
  namespace
}) => {
  const baseUrl = `sales/v3/views/${objectTypeId}/pinned`;
  const urlParams = new URLSearchParams({
    namespace
  });
  return encodeURI(`${baseUrl}?${urlParams.toString()}`);
};
export const SetPinnedViewsMutation = registerMutation({
  fieldName: PINNED_VIEWS_QUERY,
  args: ['objectTypeId', 'namespace', 'viewIds'],
  fetcher: ({
    objectTypeId,
    namespace,
    viewIds
  }) => http.put(getUrl({
    objectTypeId,
    namespace
  }), {
    data: viewIds
  }).then(() => {
    //we need to return the viewIds we just set for apollo cache
    return viewIds;
  })
});
export const useSetPinnedViews = ({
  objectTypeId,
  namespace
}) => {
  const [mutate, result] = useMutation(SetPinnedViewsMutation);
  const mutateCallback = useCallback(viewIds => mutate({
    optimisticResponse: {
      pinnedViews: viewIds
    },
    variables: {
      viewIds,
      objectTypeId,
      namespace
    },
    update(cache, {
      data
    }) {
      if (data) {
        cache.writeQuery({
          query: PinnedViewsQuery,
          variables: {
            objectTypeId,
            namespace
          },
          data
        });
      }
      return data;
    }
  }), [mutate, namespace, objectTypeId]);
  return [mutateCallback, result];
};