import PortalIdParser from 'PortalIdParser';
import { safeParseJSON } from 'framework-listing-lib/utils/json';
import { DESC } from 'framework-listing-lib/constants/sorts';
import { VIEW_TYPE } from 'framework-listing-lib/internal/ViewTabs/constants/views';
import { combineFilters } from 'framework-listing-lib/utils/filters';
import { isNegativeViewId } from './views';

/**
 * Converts the @salesViews response from the BE to a View type in framework-listing-lib.
 *
 * Properties such as `columns`, `filters` and `state` are stored as a stringified JSON object in the BE
 * and this method parses this data to something we could use throughout the codebase.
 */
export function convertSalesViewToView({
  namespace,
  salesView
}) {
  let filters = safeParseJSON(salesView.filters, []);
  let filterGroups = safeParseJSON(salesView.filterGroups || '[]', []);
  const isDefaultViewV3 = Boolean(isNegativeViewId(String(salesView.id)) && filters.length && filterGroups.length === 0);

  /**
   * Some default views have not been migrated to filter groups and
   * we need to manually map filters to filterGroups.
   */
  if (isDefaultViewV3) {
    filterGroups = [{
      filters
    }];
    filters = [];
  } else {
    filters = [];
  }
  return Object.assign({
    columns: safeParseJSON(salesView.columns, []),
    filters,
    filterGroups,
    quickFilters: safeParseJSON(salesView.quickFilters || '[]', []),
    id: String(salesView.id),
    name: salesView.name,
    namespace,
    objectTypeId: salesView.objectTypeId,
    ownerId: salesView.ownerId,
    portalId: salesView.portalId,
    private: salesView.private,
    state: safeParseJSON(salesView.state, {
      order: 1,
      sortKey: 'hs_created_at',
      mLangGroup: {
        showAll: false
      },
      uiMode: undefined
    }),
    teamId: salesView.teamId,
    type: salesView.type
  }, salesView.defaultColumns ? {
    defaultColumns: safeParseJSON(salesView.defaultColumns, [])
  } : {}, salesView.idLabel ? {
    idLabel: salesView.idLabel
  } : {});
}

/**
 * Creates a view request used to save views (create or update) in the BE.
 * If taking and existing view (@view is defined), it will use its current information to update a view.
 * Otherwise, it is going to return a view to create.
 */
export function convertViewToViewRequest({
  cardProperties,
  columns,
  currentUIMode,
  filterGroups,
  hiddenFilter,
  numberOfFrozenColumns,
  objectType,
  quickFilters,
  sort,
  view,
  viewUpdateState
}) {
  const viewFilterGroups = hiddenFilter ? combineFilters(filterGroups, hiddenFilter.filterGroups) : filterGroups;
  return {
    columns: JSON.stringify(columns.map((name, index) => ({
      name,
      order: index + 1
    }))),
    id: view ? String(view.id) : null,
    filterGroups: JSON.stringify(viewFilterGroups),
    filters: JSON.stringify([]),
    name: view ? view.name : '',
    ownerId: view ? view.ownerId : null,
    objectTypeId: objectType,
    portalId: PortalIdParser.get(),
    private: view ? view.private : false,
    quickFilters: JSON.stringify(quickFilters),
    state: JSON.stringify({
      cardProperties,
      order: sort.order === DESC ? 1 : -1,
      sortKey: sort.property,
      mLangGroup: {
        showAll: viewUpdateState.mLangGroup.showAll
      },
      numberOfFrozenColumns,
      uiMode: String(currentUIMode)
    }),
    teamId: view ? view.teamId : null,
    type: view ? view.type : VIEW_TYPE.STANDARD
  };
}
export function getViewWithNewFilters({
  filtersForNewViews,
  viewRequest
}) {
  return Object.assign({}, viewRequest, {
    filterGroups: JSON.stringify(filtersForNewViews.filterGroups),
    filters: '[]',
    quickFilters: JSON.stringify(filtersForNewViews.quickFilters)
  });
}
export function convertCustomerDataViewToView(customerDataView) {
  return {
    columns: customerDataView.columns.map(({
      name
    }, index) => ({
      name,
      order: index
    })),
    filters: [],
    // Different TS types between FLL and customer-data-views-management
    filterGroups: customerDataView.filterGroups,
    // Different TS types between FLL and customer-data-views-management
    quickFilters: customerDataView.quickFilters,
    /**
     * customer-data-views-management uses the "idLabel" as the view "id"
     * whereas FLL uses the actual "id" property.
     */
    id: customerDataView.id,
    name: customerDataView.name,
    namespace: customerDataView.namespaceName,
    objectTypeId: customerDataView.objectTypeId,
    ownerId: customerDataView.ownerId || null,
    portalId: PortalIdParser.get(),
    private: customerDataView.private,
    // Different TS types between FLL and customer-data-views-management
    state: customerDataView.state,
    teamId: customerDataView.teamId || null,
    type: customerDataView.type
  };
}