const _unique = definitions => {
  const names = {};
  return definitions.filter(definition => {
    if (definition.kind !== 'FragmentDefinition') {
      return true;
    }
    const name = definition.name.value;
    if (names[name]) {
      return false;
    } else {
      names[name] = true;
      return true;
    }
  });
};
import { useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';
import useObjectTypeId from 'framework-listing-lib/internal/hooks/useObjectTypeId';
import { Metrics } from 'framework-listing-lib/internal/metrics';
import { OBJECT_TYPE_DEFINITION_FRAGMENT, PROPERTY_GROUP_FRAGMENT } from '../fragments';
import useSkipCrm from 'framework-listing-lib/internal/hooks/useSkipCrm';
const FETCH_LISTING_LIB_QUERY = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"query\",\"name\":{\"kind\":\"Name\",\"value\":\"ListingLibQuery\"},\"variableDefinitions\":[{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectTypeId\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"String\"}}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"allObjectTypes\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"FragmentSpread\",\"name\":{\"kind\":\"Name\",\"value\":\"ObjectTypeDefinitionFragment\"}}]}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"allPropertyGroups\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"type\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectTypeId\"}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"FragmentSpread\",\"name\":{\"kind\":\"Name\",\"value\":\"PropertyGroupFragment\"}}]}}]}}]}", {
  id: null,
  kind: "Document",
  definitions: _unique([{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "ListingLibQuery"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "objectTypeId"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "allObjectTypes"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ObjectTypeDefinitionFragment"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "allPropertyGroups"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "type"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "objectTypeId"
            }
          }
        }],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "PropertyGroupFragment"
            }
          }]
        }
      }]
    }
  }].concat(OBJECT_TYPE_DEFINITION_FRAGMENT.definitions, PROPERTY_GROUP_FRAGMENT.definitions))
});
export default function useFetchListingLibData() {
  const objectTypeId = useObjectTypeId();
  const skipCrm = useSkipCrm();
  const {
    loading,
    data,
    error
  } = useQuery(FETCH_LISTING_LIB_QUERY, {
    variables: {
      objectTypeId
    },
    skip: skipCrm,
    onCompleted: () => {
      Metrics.counter('fetch-properties-success', {
        object_type_id: objectTypeId
      }).increment();
    },
    onError: () => {
      Metrics.counter('fetch-properties-failure', {
        object_type_id: objectTypeId
      }).increment();
    }
  });
  const propertyGroups = useMemo(() => {
    if (!data || !data.allPropertyGroups || !Array.isArray(data.allPropertyGroups)) {
      return [];
    }
    return data.allPropertyGroups.map(propertyGroup => ({
      displayName: propertyGroup.label,
      displayOrder: propertyGroup.displayOrder,
      hubspotDefined: propertyGroup.hubspotDefined,
      label: propertyGroup.label,
      name: propertyGroup.name,
      properties: propertyGroup.allPropertyDefinitions && Array.isArray(propertyGroup.allPropertyDefinitions) ? propertyGroup.allPropertyDefinitions : []
    }));
  }, [data]);
  return useMemo(() => ({
    error,
    loading,
    properties: propertyGroups.map(propertyGroup => propertyGroup.properties).flat(),
    propertyGroups,
    typeDefinitions: data && data.allObjectTypes && Array.isArray(data.allObjectTypes) ? data.allObjectTypes : []
  }), [data, error, loading, propertyGroups]);
}