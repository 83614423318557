import { Record } from 'immutable';
class DropQualifiers extends Record({
  portal_suspended: 0,
  invalid_to_address: 0,
  blocked_domain: 0,
  previously_bounced: 0,
  email_unconfirmed: 0,
  previous_spam: 0,
  previously_unsubscribed_message: 0,
  previously_unsubscribed_portal: 0,
  invalid_from_address: 0,
  campaign_cancelled: 0,
  validation_failed: 0,
  mta_ignore: 0,
  blocked_address: 0,
  portal_over_limit: 0,
  missing_content: 0
}) {
  static from(json) {
    return new DropQualifiers(json);
  }
}
export default DropQualifiers;