import { defaults, PROCESSING_STATE } from 'EmailData/models/ListModel';
import { LIST_PROPERTIES } from 'messaging-types-lib/content/recipients';
const DYNAMIC = 'DYNAMIC';
const STATIC = 'STATIC';
const BAG = 'BAG';
const LIST_TYPE_MAP = {
  ADHOC: BAG,
  DYNAMIC,
  EVALUATION_ONLY: STATIC,
  MANUAL: STATIC,
  ONLINE_ONLY: STATIC,
  PREVIEW: STATIC,
  SNAPSHOT: STATIC,
  STATIC
};
const LIST_PROCESSING_MAP = {
  PROCESSING_LIST_MIGRATION: PROCESSING_STATE.PROCESSING,
  PROCESSING: PROCESSING_STATE.PROCESSING,
  COMPLETE: PROCESSING_STATE.DONE,
  PAUSED: PROCESSING_STATE.DONE
};
export function getListPropertyValue(property, defaultValue) {
  if (property === undefined) {
    return defaultValue;
  }
  return property.value;
}
export function recipientsListsMapper(json) {
  if (json.properties === undefined) {
    return defaults;
  }
  const nestedList = Object.assign({}, defaults, {
    metaData: Object.assign({}, defaults.metaData)
  });
  nestedList.name = getListPropertyValue(json.properties[LIST_PROPERTIES.LIST_NAME], LIST_PROPERTIES.LIST_NAME);
  nestedList.metaData.size = Number(getListPropertyValue(json.properties[LIST_PROPERTIES.LIST_SIZE], defaults.metaData.size));
  nestedList.id = Number(getListPropertyValue(json.properties[LIST_PROPERTIES.LIST_CLASSIC_ID], defaults.id));
  nestedList.listId = nestedList.id;
  nestedList.ilsListId = Number(getListPropertyValue(json.properties[LIST_PROPERTIES.INBOUND_LIST_ID], defaults.ilsListId));
  nestedList.portalId = json.portalId;
  const inboundListType = getListPropertyValue(json.properties[LIST_PROPERTIES.LIST_TYPE], LIST_PROPERTIES.LIST_TYPE);
  nestedList.listType = inboundListType === '' ? inboundListType : LIST_TYPE_MAP[inboundListType];
  nestedList.dynamic = nestedList.listType === DYNAMIC;
  nestedList.deleted = json.properties[LIST_PROPERTIES.DELETED_AT] !== undefined && !['', null, undefined].includes(json.properties[LIST_PROPERTIES.DELETED_AT].value) && Number(json.properties[LIST_PROPERTIES.DELETED_AT].value) > 0;
  const inboundProcessingStatus = getListPropertyValue(json.properties[LIST_PROPERTIES.PROCESSING_STATUS], defaults.metaData.processing);
  nestedList.metaData.processing = inboundProcessingStatus === '' ? inboundProcessingStatus : LIST_PROCESSING_MAP[inboundProcessingStatus];
  nestedList.createdAt = Number(getListPropertyValue(json.properties[LIST_PROPERTIES.CREATED_AT], defaults.createdAt));
  return nestedList;
}