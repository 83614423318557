import RssResult from 'EmailData/enums/RssResult';
export const ATTEMPT_STATUS = {
  ERROR: 'error',
  SCHEDULED: 'scheduled',
  SENT: 'sent',
  SKIPPED: 'disabled'
};
export const COLUMN_EMAIL = 'emailName';
export const COLUMN_PUBLISH_DATE = 'publishDate';
export const HISTORY_DEFAULT_COLUMNS = [COLUMN_EMAIL, COLUMN_PUBLISH_DATE, 'sent', 'openrate', 'clickthroughrate'];
export const HISTORY_CUSTOMIZABLE_COLUMNS = ['bounce', 'bouncerate', 'click', 'clickrate', 'clickthroughrate', 'delivered', 'deliveredrate', 'open', 'openrate', 'reply', 'replyrate', COLUMN_PUBLISH_DATE, 'sent', 'spamreport', 'spamreportrate', 'unsubscribed', 'unsubscribedrate'];
export const SORT_DIRECTIONS = {
  ASC: 'ascending',
  DESC: 'descending'
};
export const SORT_DIRECTIONS_TO_ENUM = {
  ascending: 'ASC',
  descending: 'DESC'
};
// The following results may change depending on the # of retries
const RESULTS_RELYING_ON_RETRIES = {
  BLOG_TASK_COMPLETED_OR_PROCESSING: 'BLOG_TASK_COMPLETED_OR_PROCESSING',
  BLOG_TASK_BACKDATED: 'BLOG_TASK_BACKDATED',
  PREVIOUSLY_SENT: 'PREVIOUSLY_SENT',
  BLOG_TASK_LOCK_EXCEPTION: 'BLOG_TASK_LOCK_EXCEPTION',
  ENQUEUE_FAILED: 'ENQUEUE_FAILED',
  PUBLISHING_FAILED: 'PUBLISHING_FAILED',
  RSS_EMAIL_LOCK_EXCEPTION: 'RSS_EMAIL_LOCK_EXCEPTION',
  RSS_ENTRIES_NOT_RENDERED: 'RSS_ENTRIES_NOT_RENDERED',
  UNEXPECTED_EXCEPTION: 'UNEXPECTED_EXCEPTION'
};
const BLACKLISTED_RESULTS = {
  UNEXPECTED_TQ_UID: 'UNEXPECTED_TQ_UID'
};

// These values should ideally come from the backend
const RETRIES_WHEN_ERROR = 10;
export const RETRIES_WHEN_ERROR_FETCHING_FEED = 3;
export const TABLE_PAGE_SIZE = 10;
export function attemptCanShowPreview(result) {
  return result === RssResult.EMAIL_ENQUEUED;
}
export function attemptWasSkipped(result) {
  return result === RssResult.NO_RSS_ENTRIES_FOUND || result === RssResult.PREVIOUSLY_SENT || result === RssResult.BLOG_TASK_BACKDATED;
}
export function attemptFailedDueToUnpublish(result) {
  return result === RssResult.BLOG_POST_NOT_PUBLISHED || result === RssResult.EMAIL_NOT_PUBLISHED;
}
export function attemptFailedDueToFeedError(result) {
  return result === RssResult.ERROR_FETCHING_FEED;
}
export function getAttemptStatus(result) {
  switch (result) {
    case RssResult.EMAIL_ENQUEUED:
      return ATTEMPT_STATUS.SENT;
    case RssResult.RETRY_SCHEDULED:
      return ATTEMPT_STATUS.SCHEDULED;
    case RssResult.NO_RSS_ENTRIES_FOUND:
    case RssResult.PREVIOUSLY_SENT:
    case RssResult.BLOG_TASK_BACKDATED:
      return ATTEMPT_STATUS.SKIPPED;
    default:
      return ATTEMPT_STATUS.ERROR;
  }
}
function canResultStillChange(result) {
  if (result === null) {
    return false;
  }
  return result in RESULTS_RELYING_ON_RETRIES;
}
export function isErrorAttemptStillProcessing(attempt) {
  const result = attempt.get('result');
  if (!canResultStillChange(result)) {
    return false;
  }
  const retries = attempt.get('retries');
  return result === RssResult.ERROR_FETCHING_FEED ? retries < RETRIES_WHEN_ERROR_FETCHING_FEED : retries < RETRIES_WHEN_ERROR;
}
export function isResultBlacklisted(result) {
  return Object.prototype.hasOwnProperty.call(BLACKLISTED_RESULTS, result);
}
export function getColumnType(column) {
  if (column === COLUMN_PUBLISH_DATE) {
    return 'date';
  } else if (column.endsWith('rate')) {
    return 'percent';
  }
  return 'number';
}