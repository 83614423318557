export const FEEDBACK_IDS = {
  BLOG_RSS_HISTORY: 147,
  INITIAL_SEND: 15,
  SIX_MONTH_SEND: 16,
  ONBOARDING: 64,
  TLDR: 66,
  AUTOMATED_AB: 380,
  ECOMM_MODULES: 463,
  RELIABLE_OPEN: 699,
  AUTOMATION_OF_BATCH_EMAIL: 732,
  EAGER_EXECUTION: 770,
  APPLE_PRIVACY_UPDATE_V2: 891,
  EMAIL_ANALYTICS_SURVEY: 892,
  EMAIL_FRAMEWORK: 1024,
  EMAIL_INSIGHTS: 1130
};

// These surveys will be automatically handled by FeedbackLoader
export const VISIBLE_SURVEYS = [FEEDBACK_IDS.BLOG_RSS_HISTORY, FEEDBACK_IDS.INITIAL_SEND, FEEDBACK_IDS.SIX_MONTH_SEND, FEEDBACK_IDS.AUTOMATED_AB, FEEDBACK_IDS.ECOMM_MODULES, FEEDBACK_IDS.RELIABLE_OPEN, FEEDBACK_IDS.AUTOMATION_OF_BATCH_EMAIL, FEEDBACK_IDS.EAGER_EXECUTION, FEEDBACK_IDS.APPLE_PRIVACY_UPDATE_V2, FEEDBACK_IDS.EMAIL_ANALYTICS_SURVEY, FEEDBACK_IDS.EMAIL_FRAMEWORK, FEEDBACK_IDS.EMAIL_INSIGHTS];