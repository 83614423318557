import { Map as ImmutableMap } from 'immutable';
import I18n from 'I18n';
import BlogRssTiming from 'EmailData/enums/BlogRssTiming';
export function buildEmailFromChild(sendChild, primaryEmail, attemptData = ImmutableMap({})) {
  const updatedPublishDate = sendChild.get('freezeDate');
  const updatedPublishDateMoment = I18n.moment.utc(updatedPublishDate).portalTz();
  const builtEmail = sendChild.mergeWith(primaryEmail).set('publishDate', updatedPublishDate).set('publishDateMoment', updatedPublishDateMoment);
  if (!attemptData.isEmpty()) {
    const updatedDeletedAt = attemptData.getIn(['email', 'deletedAt']);
    return builtEmail.set('stats', attemptData.getIn(['email', 'stats'])).set('deletedAt', updatedDeletedAt);
  }
  return builtEmail;
}
export function getTimingFrequency(timing) {
  return timing.repeats ? timing.repeats.toUpperCase() : null;
}
export function getFrequencyTimeDetails(timing, frequency) {
  const time = I18n.moment(timing.time, 'h:mm a'); // Parse as 12-hour am/pm
  const timeFormatted = time.format('LT'); // Format as locale-sensitive time
  let dayFormatted = '';
  let dateFormatted = '';
  if (frequency === BlogRssTiming.WEEKLY) {
    const day = I18n.moment(timing.repeats_on_weekly, 'd'); // Parse as "1"
    dayFormatted = day.format('dddd'); // Format as "Monday"
  } else if (frequency === BlogRssTiming.MONTHLY) {
    const date = I18n.moment(timing.repeats_on_monthly, 'D'); // Parse as "2"
    dateFormatted = date.format('Do'); // Format as "2nd"
  }
  return {
    time: timeFormatted,
    day: dayFormatted,
    monthlyDate: dateFormatted
  };
}