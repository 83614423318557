import { useCallback, useMemo } from 'react';
import { useDataFetchingClient } from 'data-fetching-client';
import { handleUpdateFolderCountsAfterDelete } from 'framework-listing-lib/internal/Folders/utils/cache';
import { useIsFoldersMode } from 'framework-listing-lib/internal/hooks/useUIMode';
import useUnfiledAssetVariables from 'framework-listing-lib/internal/Folders/hooks/useUnfiledAssetVariables';
import useMaybeApolloClient from './useMaybeApolloClient';
export default function useFolderCacheUpdates() {
  const client = useDataFetchingClient();
  const maybeApolloClient = useMaybeApolloClient();
  const isFoldersMode = useIsFoldersMode();
  const unfiledAssetVariables = useUnfiledAssetVariables();
  const updateCountAfterDeleteFromFolder = useCallback((objectIdOrIds, folderId) => {
    if (isFoldersMode) {
      handleUpdateFolderCountsAfterDelete({
        dfcClient: client,
        maybeApolloClient,
        deletedAssetsCount: Array.isArray(objectIdOrIds) ? objectIdOrIds.length : 1,
        folderId,
        unfiledAssetVariables
      });
    }
  }, [client, isFoldersMode, maybeApolloClient, unfiledAssetVariables]);
  return useMemo(() => ({
    updateCountAfterDeleteFromFolder
  }), [updateCountAfterDeleteFromFolder]);
}