import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["businessUnitId"],
  _excluded2 = ["emailContentId"];
import http from 'hub-http/clients/apiClient';
import { getShouldUseBusinessUnitContext } from '../utils';
const filterDefault = query => {
  const {
      businessUnitId
    } = query,
    rest = _objectWithoutPropertiesLoose(query, _excluded);
  if (!getShouldUseBusinessUnitContext(businessUnitId)) {
    return rest;
  }
  return query;
};
export function fetchTopSpotlightsRequest(query) {
  return http.get('emailportalhealth/v1/insights/email-campaign-insights/top-spotlights', {
    query: Object.fromEntries(new URLSearchParams(filterDefault(query)))
  });
}
export function fetchEmailInsightsRequest(_ref) {
  let {
      emailContentId
    } = _ref,
    query = _objectWithoutPropertiesLoose(_ref, _excluded2);
  return http.get(`emailportalhealth/v1/insights/email-campaign-insights/${emailContentId}`, {
    query: Object.fromEntries(new URLSearchParams(filterDefault(query)))
  });
}
export function submitEmailInsightFeedbackRequest(data) {
  return http.post('emailportalhealth/v1/insights/feedback', {
    data
  });
}