'use es6';

import { List } from 'immutable';
import { REHYDRATE_FROM_USER_SETTINGS } from 'EmailData/actions/actionTypes';
import { trackDashboardInteraction } from 'EmailComponents/utils/tracking';
import { SET_COLUMNS } from 'email-management-lib/refactor/actions/actionTypes';
const prefixCols = ['checkbox', 'name'];
const initialState = List(['checkbox', 'name', 'updated', 'openrate']);
const nonCustomizableColumns = ['drag', 'checkbox', 'name'];
const deprecatedColumns = ['actions'];
export default function visibleColumns(state = initialState, action) {
  switch (action.type) {
    case SET_COLUMNS:
      return action.payload.columns;
    case REHYDRATE_FROM_USER_SETTINGS:
      if (action.payload.has('manageColumns') && action.payload.get('manageColumns').size > 0) {
        const cols = action.payload.get('manageColumns').toJS();
        const userCols = cols.filter(col => nonCustomizableColumns.indexOf(col) === -1 && deprecatedColumns.indexOf(col) === -1);
        const columns = userCols.slice(0, 19);
        trackDashboardInteraction({
          dashboardColumnLength: columns.length
        });
        columns.forEach(column => {
          trackDashboardInteraction({
            dashboardColumn: column
          });
        });
        return List(prefixCols.concat(columns));
      }
      return state;
    default:
      return state;
  }
}