import { useEffect } from 'react';
import { getErrorStatusCode } from 'framework-listing-lib/internal/utils/errors';
import { useGlobalError } from 'framework-listing-lib/internal/providers/DataProvider';
export default function useHandleDFCError(apolloError) {
  const [, setGlobalError] = useGlobalError();
  useEffect(() => {
    const statusCode = getErrorStatusCode(apolloError);
    if (statusCode) {
      setGlobalError({
        statusCode
      });
    }
  }, [apolloError, setGlobalError]);
}