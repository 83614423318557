import Raven from 'raven-js';
const sentryPrefix = 'ui-tool-access';
class ToolAccessRequestException extends Error {
  constructor(name = '', message = '', ...args) {
    super(message, ...args);
    this.message = message;
    this.name = `ToolAccessRequestException - ${name}`;
  }
}
export function reportRequestException(requestName, errorResponse, extra = {}) {
  try {
    const errorSubCategory = errorResponse.responseJSON.subCategory ? errorResponse.responseJSON.subCategory.replace('ToolAccessErrorSubCategory.', '') : 'UNKNOWN_SUB_CATEGORY';
    const errorMessage = `[${sentryPrefix}][${errorResponse.status}][${requestName}]`;
    const toolAccessRequestException = new ToolAccessRequestException(errorSubCategory, errorMessage);
    Raven.captureException(toolAccessRequestException, {
      level: 'error',
      extra: Object.assign({}, errorResponse.responseJSON, extra)
    });
  } catch (e) {
    Raven.captureException(new Error('Unhandled request exception'), {
      level: 'error',
      extra: Object.assign({}, errorResponse, extra)
    });
  }
}