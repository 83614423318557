'use es6';

import { FETCH_BOUNCE_DATA } from 'email-management-lib/refactor/actions/actionTypes';
const initialState = [];
export default function unbounceEmails(state = initialState, action) {
  switch (action.type) {
    case FETCH_BOUNCE_DATA:
      return action.payload;
    default:
      return state;
  }
}