'use es6';

import keyWords from '../constants/keyWords';
export default (keyWord => {
  switch (keyWord) {
    case keyWords.email:
      return 'contentLabelLibInteraction';
    case keyWords.webpage:
      return 'contentLabelLibInteractionPages';
    default:
      return 'contentLabelLibInteraction';
  }
});