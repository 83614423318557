'use es6';

import { FETCH_ROLLUP_LIMIT, FETCH_ROLLUP_LIMIT_REQUEST } from 'EmailData/actions/actionTypes';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
const INITIAL_STATE = 33 * 24 * 60 * 60 * 1000; // 33 days (in ms) same as backend

function rollupLimit(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ROLLUP_LIMIT:
      if (action.error) {
        return state;
      }
      return action.payload.maximumRequestDurationMillis;
    default:
      return state;
  }
}
export default asyncResource(rollupLimit, {
  requestActions: [FETCH_ROLLUP_LIMIT_REQUEST],
  responseActions: [FETCH_ROLLUP_LIMIT]
});