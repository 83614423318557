'use es6';

import { Map as ImmutableMap, OrderedMap } from 'immutable';
import BaseEmailDetail from 'EmailData/models/details/BaseEmailDetail';
import RssEmailAttempt from 'EmailData/models/details/children/RssEmailAttempt';
import RssEmailData from 'EmailData/models/details/children/RssEmailData';
import RssToEmailData from 'EmailData/models/details/children/RssToEmailData';
const blogRssDefaults = {
  rssToEmail: RssToEmailData.from({}),
  lastRssEmailAttempt: RssEmailAttempt.from({}),
  blogRssEmails: ImmutableMap({})
};
class BlogRssEmailDetail extends BaseEmailDetail(Object.assign({}, blogRssDefaults)) {
  static from(json) {
    const response = super.from(json);
    if (typeof response.rssEmails !== 'undefined') {
      response.blogRssEmails = OrderedMap(response.rssEmails.map(email => [email.id, RssEmailData.from(email)]));
    }
    if (typeof response.rssToEmail !== 'undefined') {
      response.rssToEmail = RssToEmailData.from(response.rssToEmail);
    }
    if (typeof response.lastRssEmailAttempt !== 'undefined' && response.lastRssEmailAttempt !== null) {
      response.lastRssEmailAttempt = RssEmailAttempt.from(response.lastRssEmailAttempt);
    }
    return new BlogRssEmailDetail(response);
  }
}
export default BlogRssEmailDetail;