import I18n from 'I18n';
const APPLE_HINT_URL = {
  en: `https://community.hubspot.com/t5/HubSpot-Community-Blog/How-to-Prepare-for-the-Apple-s-iOS15-Privacy-Changes-Checklist/ba-p/494069`,
  es: `https://community.hubspot.com/t5/Email-marketing/C%C3%B3mo-afrontar-los-cambios-de-privacidad-en-iOS15-de-Apple/td-p/512300`,
  de: `https://community.hubspot.com/t5/Fragen-Tipps-bew%C3%A4hrte-Methoden/Wie-bereite-ich-mi%5B%E2%80%A6%5Duf-die-Datenschutz%C3%A4nderungen-in-iOS-15-von/m-p/512309`,
  fr: `https://community.hubspot.com/t5/Strat%C3%A9gie-discussions-et/Comment-se-pr%C3%A9parer-%C3%A0-l-arriv%C3%A9e-d-Apple-iOS15-et-%C3%A0-ses/m-p/511705`,
  ja: `https://community.hubspot.com/t5/%E3%83%92%E3%83%B3%E3%83%88-%E3%83%8A%E3%83%AC%E3%83%83%E3%82%B8%E3%82%B7%E3%82%A7%E3%82%A2/Apple-iOS-15%E3%81%AE%E3%83%97%E3%83%A9%E3%82%A4%E3%83%90%E3%82%B7%E3%83%BC%E6%A9%9F%E8%83%BD%E3%81%AE%E5%A4%89%E6%9B%B4%E3%81%AB%E5%82%99%E3%81%88%E3%82%8B%E6%96%B9%E6%B3%95-%E3%83%81%E3%82%A7%E3%83%83%E3%82%AF%E3%83%AA%E3%82%B9%E3%83%88%E4%BB%98%E3%81%8D/m-p/511627`,
  pt: `https://community.hubspot.com/t5/Integra%C3%A7%C3%B5es-de-Sales-Hub/Como-se-preparar-pa%5B%E2%80%A6%5D3%B5es-de-privacidade-do-iOS15-da/m-p/512106`
};
export const ADJUSTED_OPEN_RATE_KB = 'https://knowledge.hubspot.com/email/analyze-the-adjusted-open-rate-of-your-emails';
export const BOT_FILTERING_STATUS_KB = 'https://knowledge.hubspot.com/email/understand-bot-filtering-in-marketing-email-analytics?hs_preview=NoYAHhGU-99643653445';
export const getAppleHintUrl = () =>
// @ts-expect-error ts-migrate(7053) FIXME:  Property 'lang' does not exist on type 'I18n'
APPLE_HINT_URL[I18n.lang] || APPLE_HINT_URL['en'];
const toNumber = val => {
  return val || 0;
};
const convertBotOpenStatsToCampaignStatistics = ({
  opens,
  clicks,
  replies,
  adjustedOpens,
  sent,
  delivered
}) => {
  const statJson = {
    counters: {
      click: toNumber(clicks.uniqueClicksExcludingBots),
      cumulativeclicks: toNumber(clicks.totalClicksWithBots),
      cumulativeopens: toNumber(opens.totalOpens),
      delivered: toNumber(delivered),
      open: toNumber(opens.uniqueReliableOpens),
      sent: toNumber(sent),
      reply: toNumber(replies.uniqueReplies),
      unreliableOpens: toNumber(opens.uniqueUnreliableOpens),
      totalClicksExcludingBot: toNumber(clicks.totalClicksWithoutBots)
    },
    deviceBreakdown: {
      click_device_type: {
        desktopPercent: toNumber(clicks.desktopPercentIncludingBots),
        mobilePercent: toNumber(clicks.mobilePercentIncludingBots),
        unknownPercent: toNumber(clicks.unknownPercentIncludingBots),
        desktopPercentExcludingBots: toNumber(clicks.desktopPercentExcludingBots),
        mobilePercentExcludingBots: toNumber(clicks.mobilePercentExcludingBots),
        unknownPercentExcludingBots: toNumber(clicks.unknownPercentExcludingBots)
      },
      open_device_type: {
        desktopPercent: toNumber(opens.desktopPercentIncludingBots),
        mobilePercent: toNumber(opens.mobilePercentIncludingBots),
        unknownPercent: toNumber(opens.unknownPercentIncludingBots),
        desktopPercentExcludingBots: toNumber(opens.desktopPercentExcludingBots),
        mobilePercentExcludingBots: toNumber(opens.mobilePercentExcludingBots),
        unknownPercentExcludingBots: toNumber(opens.unknownPercentExcludingBots)
      }
    },
    ratios: {
      clickratio: toNumber(clicks.clickRateIncludingBots),
      clickthroughratio: toNumber(clicks.clickThroughRateIncludingBots),
      openratio: toNumber(opens.rateIncludingBots),
      replyratio: toNumber(replies.replyRate),
      adjustedOpenRate: toNumber(adjustedOpens.adjustedOpenRate),
      clickThroughRateExcludingBots: toNumber(clicks.clickThroughRateExcludingBots),
      openRateExcludingBots: toNumber(opens.rateExcludingBots),
      clickRateExcludingBots: toNumber(clicks.clickRateExcludingBots)
    }
  };
  return statJson;
};

/**
 * Convert campaign response from new stats emailAnalytics API into a former model of totalStatistic
 * known format recognized by our models (such as AllCampaignStatistics and StatisticsData)
 * @param {Object} newStats
 */
export const analyticStatisticsMapper = newStats => {
  return convertBotOpenStatsToCampaignStatistics({
    opens: newStats.opens || {},
    clicks: newStats.clicks || {},
    replies: newStats.replies || {},
    adjustedOpens: newStats.adjustedOpens || {},
    sent: newStats.sent || 0,
    delivered: newStats.delivered || 0
  });
};