import { useEffect, useCallback } from 'react';
import { useDataFetchingClient } from 'data-fetching-client';
import { useWipeTableCache } from 'framework-listing-lib/internal/hooks/useFetchCrmObjectTypeCache';
import useCrmObjectTypeListingFilters from 'framework-listing-lib/hooks/useCrmObjectTypeListingFilters';
import listingLibObserver from 'framework-listing-lib/utils/listingLibObserver';
import { CLEAR_DELETED_OBJECTS, CREATE_OBJECTS, DELETE_OBJECTS, UPDATE_OBJECTS, UPDATE_PROPERTY_VALUES } from 'framework-listing-lib/constants/tableEvents';
import { CRM_OBJECT_TYPE_FIELD_NAME, LISTING_LIB_CRM_SEARCH_QUERY } from 'framework-listing-lib/internal/hooks/useFetchFromCRM';
import { addCrmObject, updateCrmObjects, updatePropertyValues } from 'framework-listing-lib/internal/Table/utils/tableCache';
import useFolderCacheUpdates from 'framework-listing-lib/internal/Folders/hooks/useFolderCacheUpdates';
import useCurrentFolderId from 'framework-listing-lib/internal/Folders/hooks/useCurrentFolderId';
import useCrmObjectTypeFetchParams from './useCrmObjectTypeFetchParams';
import useReadWriteQuery from 'framework-listing-lib/internal/graphql/hooks/useReadWriteQuery';
import { addCrmObjectToApolloCache, updateCrmObjectsInApolloCache, updateGraphQLPropertyValues, getGraphQLVariables } from 'framework-listing-lib/internal/graphql/utils/graphql';
import useHasGraphQLCrmSearch from 'framework-listing-lib/internal/graphql/hooks/useHasGraphQLCrmSearch';

/**
 * Listens to CRUD operations made in CRM objects and manipulates our internal cache.
 */
export default function useCrmObjectTypeObserver() {
  const client = useDataFetchingClient();
  const wipeTableCache = useWipeTableCache();
  const {
    onClearDeletedObjects,
    onDeleteObject
  } = useCrmObjectTypeListingFilters();
  const {
    updateCountAfterDeleteFromFolder
  } = useFolderCacheUpdates();
  const currentFolderId = useCurrentFolderId();
  const variables = useCrmObjectTypeFetchParams();
  const readWriteQuery = useReadWriteQuery();
  const hasGraphQLCrmSearch = useHasGraphQLCrmSearch();
  const handleDeleteObjects = useCallback(objectIdOrIds => {
    onDeleteObject(objectIdOrIds);
    updateCountAfterDeleteFromFolder(objectIdOrIds, currentFolderId);
  }, [onDeleteObject, updateCountAfterDeleteFromFolder, currentFolderId]);

  /**
   * Wipes the list of deleted object ids we store + wipes the entire table cache.
   *
   * This method is meant to be used in very specific cases where we can't truly
   * rely on crm-search to fetch for the latest information.
   *
   * Example in Marketing email:
   *  1. the user is in "All emails" and clicks to archive "Email ABC"
   *  2. the users clicks "Archived" from the side panel
   *  3. "Email ABC" should be listed there
   *
   * We don't have a reliable way to archive the email and immediately re-fetch information so it won't be returned.
   * So instead, we temporarily "delete" the email (it's gone from the UI but not actually deleted)
   * and once the user switches between tabs, we wipe the entire table cache and list of deleted emails.
   * As framework-listing-lib is unable to detect tab changes, we need to ask teams to let us know "when to wipe everything" (this method).
   */
  const handleClearDeletedObjects = useCallback(() => {
    onClearDeletedObjects();
    wipeTableCache();
  }, [onClearDeletedObjects, wipeTableCache]);
  const handleCreateObjects = useCallback(crmObject => {
    if (hasGraphQLCrmSearch) {
      readWriteQuery({
        query: LISTING_LIB_CRM_SEARCH_QUERY,
        variables: getGraphQLVariables(variables),
        updaterFn: apolloCache => addCrmObjectToApolloCache(apolloCache, crmObject)
      });
    } else {
      client.cache.modify({
        fields: {
          [CRM_OBJECT_TYPE_FIELD_NAME]: addCrmObject(crmObject)
        }
      });
    }
  }, [client.cache, hasGraphQLCrmSearch, readWriteQuery, variables]);
  const handleUpdateObjects = useCallback(crmObjectOrObjects => {
    if (hasGraphQLCrmSearch) {
      readWriteQuery({
        query: LISTING_LIB_CRM_SEARCH_QUERY,
        variables: getGraphQLVariables(variables),
        updaterFn: apolloCache => updateCrmObjectsInApolloCache(apolloCache, crmObjectOrObjects)
      });
    } else {
      client.cache.modify({
        fields: {
          [CRM_OBJECT_TYPE_FIELD_NAME]: updateCrmObjects(crmObjectOrObjects)
        }
      });
    }
  }, [client.cache, hasGraphQLCrmSearch, readWriteQuery, variables]);

  /**
   * Will loop through table cache and apply @propertyValues in all object types ids
   * that exist in @selectedIds.
   *
   * In case @selectedIds is an empty array, we apply @propertyValues to all object types.
   */
  const handleUpdatePropertyValues = useCallback(({
    propertyValues,
    selectedIds
  }) => {
    if (hasGraphQLCrmSearch) {
      readWriteQuery({
        query: LISTING_LIB_CRM_SEARCH_QUERY,
        variables: getGraphQLVariables(variables),
        updaterFn: apolloCache => updateGraphQLPropertyValues(apolloCache, selectedIds, propertyValues)
      });
    } else {
      client.cache.modify({
        fields: {
          [CRM_OBJECT_TYPE_FIELD_NAME]: updatePropertyValues({
            propertyValues,
            selectedIds
          })
        }
      });
    }
  }, [client.cache, hasGraphQLCrmSearch, readWriteQuery, variables]);
  useEffect(() => {
    listingLibObserver.on(CREATE_OBJECTS, handleCreateObjects);
    listingLibObserver.on(DELETE_OBJECTS, handleDeleteObjects);
    listingLibObserver.on(UPDATE_OBJECTS, handleUpdateObjects);
    listingLibObserver.on(UPDATE_PROPERTY_VALUES, handleUpdatePropertyValues);
    listingLibObserver.on(CLEAR_DELETED_OBJECTS, handleClearDeletedObjects);
    return () => {
      listingLibObserver.off(CREATE_OBJECTS, handleCreateObjects);
      listingLibObserver.off(DELETE_OBJECTS, handleDeleteObjects);
      listingLibObserver.off(UPDATE_OBJECTS, handleUpdateObjects);
      listingLibObserver.off(UPDATE_PROPERTY_VALUES, handleUpdatePropertyValues);
      listingLibObserver.off(CLEAR_DELETED_OBJECTS, handleClearDeletedObjects);
    };
  }, [handleClearDeletedObjects, handleCreateObjects, handleDeleteObjects, handleUpdateObjects, handleUpdatePropertyValues]);
}