import { useEffect, useState } from 'react';
import { fetchTeams } from '../api/teams';
import useCrmObjectTypePermissions from './useCrmObjectTypePermissions';
export default function useFetchTeams(viewAssetScopes) {
  const [state, setState] = useState({
    error: false,
    loading: true,
    teams: []
  });
  const {
    loading: loadingScopes,
    data,
    error: errorScopes
  } = useCrmObjectTypePermissions(viewAssetScopes);
  useEffect(() => {
    if (loadingScopes || errorScopes || !data) {
      return;
    }
    fetchTeams({
      canViewAllTeams: data.canViewAllTeams,
      canViewOwnedTeams: data.canViewOwnedTeams
    }).then(teams => setState({
      error: false,
      loading: false,
      teams
    })).catch(() => setState({
      error: true,
      loading: false,
      teams: []
    }));
  }, [data, errorScopes, loadingScopes]);
  return state;
}