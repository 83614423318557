export const COMPANY_ADDITIONAL_DOMAINS = 'hs_additional_domains';
export const CONTACT_ADDITIONAL_EMAILS = 'hs_additional_emails';
export const DEAL_PIPELINE = 'pipeline';
export const DEAL_STAGE = 'dealstage';
export const TICKET_PIPELINE = 'hs_pipeline';
export const TICKET_PIPELINE_STAGE = 'hs_pipeline_stage';
export const CONTACT_PIPELINE = 'hs_pipeline';
export const CONTACT_LIFECYCLESTAGE = 'lifecyclestage';
export const COMPANY_PIPELINE = 'hs_pipeline';
export const COMPANY_LIFECYCLESTAGE = 'lifecyclestage';
export const PIPELINE = 'hs_pipeline';
export const PIPELINE_STAGE = 'hs_pipeline_stage';
export const CONTACT_MARKETABLE_REASON_TYPE = 'hs_marketable_reason_type';
export const IS_DEAL_CLOSED = 'hs_is_closed';