export const UI_MODE = {
  FOLDERS: 'FOLDERS',
  GALLERY: 'GALLERY',
  LIST: 'LIST',
  CALENDAR: 'CALENDAR'
};
export const UI_MODE_ICON = {
  FOLDERS: 'folder',
  GALLERY: 'grid',
  LIST: 'listView',
  CALENDAR: 'date'
};
export const UI_MODE_COPY = {
  FOLDERS: 'frameworkListingLib.uiModes.FOLDERS',
  GALLERY: 'frameworkListingLib.uiModes.GALLERY',
  LIST: 'frameworkListingLib.uiModes.LIST',
  CALENDAR: 'frameworkListingLib.uiModes.CALENDAR'
};