import { Map as ImmutableMap } from 'immutable';
import { FETCH_APPROVALS_CONFIG_FAIL, FETCH_APPROVALS_CONFIG_START, FETCH_APPROVALS_CONFIG_SUCCESS } from 'EmailData/actions/actionTypes';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
const initialState = ImmutableMap({
  isLoading: true,
  enabled: false,
  error: false
});
function approvalsConfig(state = initialState, action) {
  switch (action.type) {
    case FETCH_APPROVALS_CONFIG_START:
      return state.set('isLoading', true);
    case FETCH_APPROVALS_CONFIG_SUCCESS:
      return state.set('isLoading', false).set('enabled', action.payload.enabled);
    case FETCH_APPROVALS_CONFIG_FAIL:
      return state.set('isLoading', false).set('error', true);
    default:
      return state;
  }
}
export default asyncResource(approvalsConfig, {
  requestActions: [FETCH_APPROVALS_CONFIG_START],
  responseActions: [FETCH_APPROVALS_CONFIG_SUCCESS, FETCH_APPROVALS_CONFIG_FAIL],
  invalidatingActions: []
});