const URI_DOMAIN = 'https://knowledge.hubspot.com/';
const SECTION_ARTICLES = 'articles/kcs_article/';
const SECTION_EMAIL_ARTICLES = 'email/';
const SECTION_GETTING_STARTED = 'getting-started-with-hubspot-v2/';
function getGettingStartedLink(path) {
  return `${URI_DOMAIN}${SECTION_GETTING_STARTED}${path}`;
}
function getKnowledgeArticleLink(path) {
  return `${URI_DOMAIN}${SECTION_EMAIL_ARTICLES}${path}`;
}
function getGeneralKnowledgeArticleLink(path) {
  return `${URI_DOMAIN}${SECTION_ARTICLES}${path}`;
}
const KNOWLEDGE_ARTICLE_ROUTES = {
  AVOID_UNENGAGED_CONTACTS: getKnowledgeArticleLink('what-is-graymail-and-how-can-i-avoid-sending-my-email-to-unengaged-contacts'),
  CLEANUP_CONTACT_LISTS: getKnowledgeArticleLink('how-to-clean-up-your-contact-lists-to-improve-deliverability'),
  CONTACT_LIST_TO_USE: getKnowledgeArticleLink('is-my-contact-list-ok-to-email-using-hubspot'),
  CREATE_AND_SEND_MARKETING_EMAILS: getKnowledgeArticleLink('create-and-send-marketing-emails-with-the-updated-classic-editor#:~:text=You%20can%20preview%20how,will%20also%20be%20excluded.'),
  CREATE_AND_SEND_MARKETING_EMAILS_DND: getKnowledgeArticleLink('create-marketing-emails-in-the-drag-and-drop-email-editor#:~:text=You%20can%20preview%20how,will%20also%20be%20excluded.'),
  EMAIL_BLOCKLIST: getKnowledgeArticleLink('what-is-an-email-blocklist'),
  EMAIL_BLOCKLIST_FAQ: getKnowledgeArticleLink('blocklist-remediation-frequently-asked-questions'),
  EMAIL_BLOCKLIST_RESPONSE: getKnowledgeArticleLink('what-is-an-email-blocklist#how-does-hubspot-respond-to-blocklistings'),
  OPT_OUT_LIST: getGettingStartedLink('how-to-import-an-opt-out-list-into-your-account'),
  PERMISSION_PASS_CAMPAIGN: getKnowledgeArticleLink('how-can-i-run-a-permission-pass-campaign-in-hubspot'),
  UNDERSTAND_OPT_IN: getKnowledgeArticleLink('understand-opt-in-consent-for-email'),
  USER_ROLES_GUIDE: getGeneralKnowledgeArticleLink('settings/hubspot-user-roles-guide'),
  WHY_SUSPENSION: getKnowledgeArticleLink('why-is-my-email-suspended'),
  WHY_SUSPENSION_HUBS: getKnowledgeArticleLink(`why-is-my-email-suspended##marketing-hub-starter-accounts-sales-hub-starter-professional-and-enterprise-accounts-service-hub-starter-professional-and-enterprise-accounts-and-free-trial-accounts`),
  WHY_SUSPENSION_WHAT_TO_DO: getKnowledgeArticleLink('why-is-my-email-suspended#what-should-i-do-if-my-email-sending-is-suspended'),
  WHY_WAS_SEND_STOPPED: getKnowledgeArticleLink('why-was-my-email-send-stopped'),
  WHY_EMAIL_NOT_SENT: getKnowledgeArticleLink('why-does-my-email-send-show-not-sent-on-the-contact-timeline'),
  SEND_TRANSACTIONAL_EMAIL: getGeneralKnowledgeArticleLink('email/how-to-use-transactional-email-in-hubspot')
};
export default KNOWLEDGE_ARTICLE_ROUTES;