import { Record } from 'immutable';
import I18n from 'I18n';
import { PROPERTIES } from 'email-management-lib/refactor/constants/manage/frameworkTable';
import * as emailStateUtil from 'EmailData/utils/emailStateUtil';
import * as emailAttributeUtil from 'EmailData/utils/emailAttributeUtil';
import { getEmailPreviewUrl } from 'EmailData/utils/getEmailPreviewUrl';
const CREATED_TIMESTAMP = new Date().valueOf();
const defaults = {
  archived: false,
  author: undefined,
  businessUnitId: 0,
  categoryId: 2,
  created: 0,
  emailTemplateMode: 'DRAG_AND_DROP',
  emailType: 'BATCH_EMAIL',
  folderId: undefined,
  id: 0,
  language: 'en',
  name: undefined,
  previewKey: undefined,
  processingStatus: undefined,
  publishDate: undefined,
  publishDateMoment: null,
  publishedById: undefined,
  rssToEmailTiming: {},
  state: 'DRAFT',
  securityState: undefined,
  subcategory: 'batch',
  subscription: null,
  teamPerms: [],
  updatedMoment: null,
  userPerms: [],
  isMinimalEmail: false
};
const CRMObjectProperties = {
  [PROPERTIES.ARCHIVED]: 'archived',
  [PROPERTIES.AUTHOR]: 'author',
  [PROPERTIES.BUSINESS_UNIT_IDS]: 'businessUnitId',
  [PROPERTIES.CREATED_AT]: 'created',
  [PROPERTIES.EMAIL_TEMPLATE_MODE]: 'emailTemplateMode',
  [PROPERTIES.EMAIL_TYPE]: 'emailType',
  [PROPERTIES.FOLDER_ID]: 'folderId',
  [PROPERTIES.MARKETING_EMAIL_ID]: 'id',
  [PROPERTIES.NAME]: 'name',
  [PROPERTIES.PREVIEW_KEY]: 'previewKey',
  [PROPERTIES.PROCESSING_STATUS]: 'processingStatus',
  [PROPERTIES.PUBLISHED_DATE]: 'publishDate',
  [PROPERTIES.SECURITY_STATE]: 'securityState',
  [PROPERTIES.STATE]: 'state',
  [PROPERTIES.SUBCATEGORY]: 'subcategory',
  [PROPERTIES.TEAM_IDS]: 'teamPerms',
  [PROPERTIES.USER_IDS]: 'userPerms',
  [PROPERTIES.SUBSCRIPTION]: 'subscription',
  [PROPERTIES.LANGUAGE]: 'language'
};
function getValueFromCrmObject(crmObject, propertyName) {
  if (crmObject && crmObject.properties && typeof crmObject.properties[propertyName] !== 'undefined') {
    return crmObject.properties[propertyName].value;
  }
  return defaults[CRMObjectProperties[propertyName]];
}
class CRMEmail extends Record(defaults) {
  static from(json) {
    const email = Object.assign({}, defaults);
    email.publishDate = getValueFromCrmObject(json, PROPERTIES.PUBLISHED_DATE);
    email.created = getValueFromCrmObject(json, PROPERTIES.CREATED_AT);
    if (json && json.properties && typeof json.properties.hs_publish_status !== 'undefined') {
      email.publishDateMoment = I18n.moment.utc(json.properties.hs_publish_status.timestamp)
      // @ts-expect-error Property 'portalTz' does not exist on type 'Moment'.
      .portalTz();
    } else {
      email.publishDateMoment = I18n.moment(CREATED_TIMESTAMP);
    }
    if (json && json.properties && typeof json.properties.hs_updated_by_user_id !== 'undefined') {
      email.updatedMoment = I18n.moment.utc(json.properties.hs_updated_by_user_id.timestamp)
      // @ts-expect-error Property 'portalTz' does not exist on type 'Moment'.
      .portalTz();
    } else {
      email.updatedMoment = I18n.moment(CREATED_TIMESTAMP);
    }
    email.businessUnitId = parseInt(getValueFromCrmObject(json, PROPERTIES.BUSINESS_UNIT_IDS), 10);
    email.author = getValueFromCrmObject(json, PROPERTIES.AUTHOR);
    email.emailTemplateMode = getValueFromCrmObject(json, PROPERTIES.EMAIL_TEMPLATE_MODE);
    email.emailType = getValueFromCrmObject(json, PROPERTIES.EMAIL_TYPE);
    email.name = getValueFromCrmObject(json, PROPERTIES.NAME);
    email.id = parseInt(getValueFromCrmObject(json, PROPERTIES.MARKETING_EMAIL_ID), 10);
    email.folderId = parseInt(getValueFromCrmObject(json, PROPERTIES.FOLDER_ID), 10);
    email.previewKey = getValueFromCrmObject(json, PROPERTIES.PREVIEW_KEY);
    email.processingStatus = getValueFromCrmObject(json, PROPERTIES.PROCESSING_STATUS);
    if (email.processingStatus) {
      email.processingStatus = email.processingStatus.toUpperCase();
    }
    email.securityState = getValueFromCrmObject(json, PROPERTIES.SECURITY_STATE);
    email.state = getValueFromCrmObject(json, PROPERTIES.STATE);
    email.archived = getValueFromCrmObject(json, PROPERTIES.ARCHIVED) === 'true';
    email.subcategory = getValueFromCrmObject(json, PROPERTIES.SUBCATEGORY);
    const teamIds = getValueFromCrmObject(json, PROPERTIES.TEAM_IDS);
    email.teamPerms = typeof teamIds === 'string' ? teamIds.split(';').map(Number) : teamIds;
    const userIds = getValueFromCrmObject(json, PROPERTIES.USER_IDS);
    email.userPerms = typeof userIds === 'string' ? userIds.split(';').map(Number) : userIds;
    if (json && json.properties && json.properties[PROPERTIES.RSS_TO_EMAIL_TIMING]) {
      email.rssToEmailTiming = JSON.parse(getValueFromCrmObject(json, PROPERTIES.RSS_TO_EMAIL_TIMING));
    }
    email.isMinimalEmail = json.isMinimalEmail;
    email.subscription = getValueFromCrmObject(json, PROPERTIES.SUBSCRIPTION);
    email.language = getValueFromCrmObject(json, PROPERTIES.LANGUAGE);
    return new CRMEmail(email);
  }
  // @ts-expect-error but extended class 'CRMEmail' defines it as instance member function.
  isPublished() {
    return emailStateUtil.getIsPublished(this);
  }
  isABAutomatedPublishedEmail() {
    return this.isABAutomatedEmail() && this.isPublishedAutomated();
  }
  isScheduled() {
    return emailStateUtil.getIsScheduled(this);
  }

  // @ts-expect-error but extended class 'CRMEmail' defines it as instance member function.
  isDraft() {
    return emailStateUtil.getIsDraft(this);
  }
  isErrored() {
    return emailStateUtil.getIsError(this);
  }
  isProcessing() {
    return emailStateUtil.getIsProcessing(this);
  }
  isPreProcessing() {
    return emailStateUtil.getIsPreProcessing(this);
  }
  isInitializing() {
    return emailStateUtil.getIsInitializing(this);
  }
  isArchived() {
    return this.archived;
  }
  isAutomatedForForm() {
    return emailStateUtil.getIsAutomatedForForm(this);
  }
  isBatch() {
    return emailStateUtil.getIsBatchEmail(this);
  }
  isAutomated() {
    return emailStateUtil.getIsAutomated(this);
  }
  isAutomatedLike() {
    return emailStateUtil.getIsAutomatedLike(this);
  }
  isLocalTime() {
    return emailStateUtil.getIsLocalTime(this);
  }
  isPublishedAutomated() {
    return emailStateUtil.getIsPublishedAutomated(this);
  }
  isFollowup() {
    return emailStateUtil.getIsFollowupEmail(this);
  }
  isSingleSendApi() {
    return emailStateUtil.getIsSingleSendApi(this);
  }
  isBlogEmail() {
    return emailStateUtil.getIsBlogEmail(this);
  }
  isBlogChildEmail() {
    return emailStateUtil.getIsBlogEmailChild(this);
  }
  isABEmail() {
    return emailStateUtil.getIsAbEmail(this);
  }
  isABAutomatedEmail() {
    return emailStateUtil.getIsAbAutomated(this);
  }
  isABLike() {
    return emailStateUtil.getIsAbLike(this);
  }
  isRssEmail() {
    return emailStateUtil.getIsRssEmail(this);
  }
  isRssChildEmail() {
    return emailStateUtil.getIsRssEmailChild(this);
  }
  isOptinEmail() {
    return emailStateUtil.getIsOptinEmail(this);
  }
  isLeadFlowEmail() {
    return emailStateUtil.getIsLeadflowEmail(this);
  }
  isTicketEmail() {
    return emailStateUtil.getIsTicketEmail(this);
  }
  isFeedbackEmail() {
    return emailStateUtil.getIsFeedbackLikeEmail(this);
  }
  isSmtpEmail() {
    return emailStateUtil.getIsSmtpToken(this);
  }
  isAbandonedCartEmail() {
    return emailStateUtil.getIsAutomatedForDeal(this);
  }
  isTransactionalEmail() {
    return this.transactional;
  }
  isAvailableToWorkflows() {
    return emailStateUtil.getIsAvailableToWorkflows(this);
  }
  hasLoaded() {
    return this.emailType !== defaults.emailType;
  }
  wasForciblyCancelled() {
    return emailStateUtil.getIsForciblyCanceled(this);
  }
  wasCanceled() {
    return emailStateUtil.getIsCanceled(this);
  }
  wasCanceledForAbuse() {
    return emailStateUtil.getIsCanceledForAbuse(this);
  }
  getPreviewUrl(convertToDragDrop = false) {
    const isDraftEmail = !(this.isPublished() || this.isScheduled());
    return getEmailPreviewUrl(this.id || 0, this.previewKey || '', isDraftEmail, convertToDragDrop);
  }
  canCancelOrUnpublish() {
    return emailAttributeUtil.getCanCancelOrUnpublish(this);
  }
}
export default CRMEmail;