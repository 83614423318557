'use es6';

import I18n from 'I18n';
import Raven from 'raven-js';
import growlInternal from './growlInternal';
function parseError(error) {
  if (error.message && error.message.indexOf('No auth cookie') >= 0) {
    return I18n.text('EmailComponents.errors.authentication');
  }
  return null;
}
export function growl(...args) {
  growlInternal.growl(...args);
}
export function growlError(error, feature, options = {}) {
  const {
    isSentryError,
    messageKey
  } = options;
  let message = parseError(error);
  if (!message) {
    message = messageKey ? I18n.text(messageKey) : I18n.text('EmailComponents.errors.generic');
  }

  // This method is also called from files other than the main middleware
  // In this case, we need to log Sentry for backward compatibility
  if (!isSentryError) {
    Raven.captureMessage(`${feature}: ${error}`);
  }
  growl('error', message, feature);
}