import { List, Map as ImmutableMap, fromJS } from 'immutable';
import get from 'hs-lodash/get';
import has from 'hs-lodash/has';
import getIn from 'transmute/getIn';
import hasIn from 'transmute/hasIn';

// better types for immutable map and list.
// largely copied from https://hubspot.slack.com/archives/C054KSE5LBA/p1681878202098509
// with some modifications to handle nested objects, arrays and toJS better.
// A utility function to build an immutable Map of a given structure, ensuring that initial keys and values conform to the given type
export const buildTypedMap = data => ImmutableMap(data);

// immutablejs 'fromJS' but typesafe :D
export const typedFromJS = data => {
  return fromJS(data);
};

/** Gets the type contained within a TypedMap. */

const immutableShim = Symbol('ImmutableShim');
export function ImmutableMapShim(obj) {
  return Object.assign({}, obj, {
    /** @deprecated */
    get(key) {
      return get(obj, key);
    },
    /** @deprecated */
    getIn(keypath, defaultValue) {
      var _getIn;
      return (_getIn = getIn(keypath, obj)) !== null && _getIn !== void 0 ? _getIn : defaultValue;
    },
    /** @deprecated */
    has(key) {
      return has(obj, key);
    },
    /** @deprecated */
    hasIn(keypath) {
      return hasIn(keypath, obj);
    },
    // Using these on top-level state sometimes happens in unit tests, including them only to avoid TS errors.
    /** @deprecated */
    set(__key, __value) {
      throw new Error('ImmutableMapShim will not perform mutations');
    },
    /** @deprecated */
    setIn(__keypath, __value) {
      throw new Error('ImmutableMapShim will not perform mutations');
    },
    [immutableShim]: true
  });
}
export function isImmutableMap(maybeMap) {
  if (!maybeMap) {
    return false;
  }
  if (typeof maybeMap === 'object' && maybeMap !== null && immutableShim in maybeMap) {
    return true;
  }
  return ImmutableMap.isMap(maybeMap);
}
export const isAsList = value => List.isList(value);