/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable prefer-promise-reject-errors */

import I18n from 'I18n';
import { FETCH_LISTS, FETCH_LISTS_REQUEST } from 'EmailData/actions/actionTypes';
import { createHardBounceListRequest, updateHardBounceListRequest } from 'email-management-lib/refactor/api/lists';
// @ts-expect-error not migrated to TS yet
import { growl } from 'EmailComponents/errors/growl';
import { redirectToIlsList } from 'email-management-lib/refactor/utils/listUtils';
import { searchListsRequest as searchInboundDBListsRequest } from 'EmailData/api/recipients';
import { recipientsListsMapper } from 'EmailData/utils/recipientsListsMapper';
const getTimestampsFromDates = (startDate, endDate) => ({
  startTimestamp: startDate ? I18n.moment(startDate).valueOf() : null,
  endTimestamp: endDate ? I18n.moment(endDate).endOf('day').valueOf() : null
});
export function createHardBounceList({
  listName,
  reasons,
  includeGlobalBounces,
  startDate,
  endDate
}) {
  return () => {
    const {
      startTimestamp,
      endTimestamp
    } = getTimestampsFromDates(startDate, endDate);
    return createHardBounceListRequest(listName, reasons, includeGlobalBounces, startTimestamp, endTimestamp).then(listInfo => {
      // Redirect to the newly created list
      redirectToIlsList(listInfo.ilsListId);
    }).catch(error => {
      if (error.responseJSON.errorType === 'LIST_EXISTS') {
        growl('error', I18n.text('email-management.modals.export.hardBounceListModal.listExistsError'));
      } else {
        growl('error', I18n.text('email-management.modals.export.hardBounceListModal.creationError'));
      }
      return Promise.reject();
    });
  };
}
export function updateHardBounceList({
  listSelection,
  reasons,
  includeGlobalBounces,
  startDate,
  endDate
}) {
  return () => {
    const {
      startTimestamp,
      endTimestamp
    } = getTimestampsFromDates(startDate, endDate);
    return updateHardBounceListRequest(listSelection, reasons, includeGlobalBounces, startTimestamp, endTimestamp).then(() => {
      // Redirect to the newly created list
      return redirectToIlsList(listSelection);
    }, () => {
      growl('error', I18n.text('email-management.modals.export.hardBounceListModal.updateError'));
      return Promise.reject();
    });
  };
}
export function fetchLists(query) {
  return dispatch => {
    dispatch({
      type: FETCH_LISTS_REQUEST
    });
    const promise = searchInboundDBListsRequest(query || '');
    return promise.then(response => {
      const processedResp = {};
      processedResp.lists = response.results.map(list => ({
        list: recipientsListsMapper(list)
      }));
      return Promise.resolve(dispatch({
        type: FETCH_LISTS,
        payload: {
          lists: processedResp.lists.map(list => list.list)
        }
      }));
    }, err => {
      if (err.status === 404) {
        return Promise.resolve(dispatch({
          type: FETCH_LISTS,
          payload: {
            lists: []
          }
        }));
      }
      return Promise.resolve(dispatch({
        type: FETCH_LISTS,
        error: true,
        payload: err
      }));
    });
  };
}