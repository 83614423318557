import { Map as ImmutableMap } from 'immutable';
import { createSelector } from 'reselect';
export const getManageTable = state => state.getIn(['manage', 'table'], ImmutableMap({}));
export const getSelectedEmailCrmObject = state => state.getIn(['manage', 'frameworkTable']).selectedEmailCrmObject;
export const getSelectedMultiEmailCrmObjects = state => state.getIn(['manage', 'frameworkTable']).selectedMultiEmailCrmObjects;
const getHasEmails = createSelector([getManageTable], table => table.get('hasEmails'));
export const getHasEmailsLoaded = createSelector([getHasEmails], hasEmails => hasEmails.hasEverLoaded());
export const getHasEmailsData = createSelector([getHasEmails], hasEmails => hasEmails.getData());
export const getHasArchivedEmails = createSelector([getManageTable], table => table.get('hasArchived'));
export const getHasArchivedEmailsLoaded = createSelector([getHasArchivedEmails], hasArchived => hasArchived.hasEverLoaded());
export const getHasArchivedEmailsData = createSelector([getHasArchivedEmails], hasArchived => hasArchived.getData());
export const getSelectedUsersTeamsEmails = createSelector([getManageTable], table => table.get('selectedUsersTeamsEmails'));
export const getSelectedEmail = createSelector([getManageTable], table => table.get('selectedEmail'));
export const getSelectedDetailPanelTab = createSelector([getManageTable], table => table.get('selectedDetailsPanelTab'));
export const getCampaignIds = createSelector([getManageTable], table => table.get('campaignIds'));
export const getSortColumn = createSelector([getManageTable], table => table.get('sortColumn'));
export const getEmailIds = createSelector([getManageTable], table => table.get('emailIds'));
export const getEmailIdsData = createSelector([getEmailIds], emailIds => emailIds.getData());
export const getEmailIdsHasEverLoaded = createSelector([getEmailIds], emailIds => emailIds.hasEverLoaded());
export const getIsBulkSelection = createSelector([getManageTable], table => table.get('bulkSelection'));
export const getSubscriptions = state => {
  return state.getIn(['manage', 'frameworkTable']).subscriptions;
};