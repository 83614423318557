import I18n from 'I18n';
import { getIsPublished, getIsScheduled } from 'EmailData/utils/emailStateUtil';

// @ts-expect-error not migrated to TS yet

export const getHasWebPageVersion = email => {
  return (getIsPublished(email) || getIsScheduled(email)) && email.createPage;
};
export const getHasWebPageRedirect = email => {
  return (getIsPublished(email) || getIsScheduled(email)) && email.pageRedirected;
};
export const getHasWebVersionExpiry = email => {
  return (getIsPublished(email) || getIsScheduled(email)) && email.pageExpiryEnabled;
};
export const getHasWebRedirectAfterDate = (email, date) => {
  if (!getHasWebVersionExpiry(email)) {
    return false;
  }
  const comparisonDate = I18n.moment(date);
  const redirectDate = I18n.moment(email.pageExpiryDate);
  return redirectDate.isAfter(comparisonDate);
};
export const getHasWebRedirectBeforeDate = (email, date) => {
  if (!getHasWebVersionExpiry(email)) {
    return false;
  }
  const comparisonDate = I18n.moment(date);
  const redirectDate = I18n.moment(email.pageExpiryDate);
  return redirectDate.isBefore(comparisonDate);
};