export const WEEKLY = 'weekly';
export const BIWEEKLY = 'biweekly';
export const MONTHLY = 'monthly';
export const QUARTERLY = 'quarterly';
export const PER_SIX_MONTHS = 'per_six_months';
export const ANNUALLY = 'annually';
export const PER_TWO_YEARS = 'per_two_years';
export const PER_THREE_YEARS = 'per_three_years';
export const PER_FOUR_YEARS = 'per_four_years';
export const PER_FIVE_YEARS = 'per_five_years';
export const WEEKLY_BILLING_FREQUENCIES = new Set([WEEKLY, BIWEEKLY]);
export const MONTHLY_BILLING_FREQUENCIES = new Set([MONTHLY, QUARTERLY, PER_SIX_MONTHS, ANNUALLY, PER_TWO_YEARS, PER_THREE_YEARS, PER_FOUR_YEARS, PER_FIVE_YEARS]);
export const BILLING_FREQUENCIES_OBJECT = {
  // These values represent the number of weeks
  [WEEKLY]: {
    days: 7,
    weeks: 1
  },
  [BIWEEKLY]: {
    days: 14,
    weeks: 2
  },
  // These values represent the number of months
  [MONTHLY]: {
    months: 1
  },
  [QUARTERLY]: {
    months: 3
  },
  [PER_SIX_MONTHS]: {
    months: 6
  },
  [ANNUALLY]: {
    months: 12
  },
  [PER_TWO_YEARS]: {
    months: 24
  },
  [PER_THREE_YEARS]: {
    months: 36
  },
  [PER_FOUR_YEARS]: {
    months: 48
  },
  [PER_FIVE_YEARS]: {
    months: 60
  }
};
export const BILLING_FREQUENCY_NAMES = Object.keys(BILLING_FREQUENCIES_OBJECT);