import { AccountVerificationProgressStatus } from '../enums';
import mapScopeStatusToAccountVerificationProgressStatus from '../util/mapScopeStatusToAccountVerificationProgressStatus';
import toSafeEpoch from '../util/toSafeEpoch';
const LANG_BASE_PATH = 'ui-suspension-banners-lib.accountSuspension';
const {
  ACCEPTED_VERIFICATION,
  NOT_APPLICABLE,
  NEEDS_ACCOUNT_VERIFICATION,
  NEEDS_USER_VERIFICATION,
  NEEDS_VERIFICATION
} = AccountVerificationProgressStatus;
const statusesWithCTA = [NEEDS_ACCOUNT_VERIFICATION, NEEDS_USER_VERIFICATION, NEEDS_VERIFICATION];
const calculateDaySinceDecision = bannerInfo => {
  const safeDecisionDate = bannerInfo ? toSafeEpoch(bannerInfo.decidedAt) : new Date().getTime();
  const today = new Date();
  const decidedAt = new Date(safeDecisionDate);
  return (today.getTime() - decidedAt.getTime()) / (1000 * 60 * 60 * 24);
};
const useBanner = (userHidBanner, bannerInfo) => {
  const [progressStatus, alertType] = mapScopeStatusToAccountVerificationProgressStatus(bannerInfo);
  const verificationAccepted = progressStatus === ACCEPTED_VERIFICATION;
  const userClosedClosableBanner = verificationAccepted && userHidBanner;
  const olderAcceptedVerification = verificationAccepted && calculateDaySinceDecision(bannerInfo) >= 60;
  const hideBanner = progressStatus === undefined || progressStatus === NOT_APPLICABLE || olderAcceptedVerification || userClosedClosableBanner;
  const scopeEnabled = progressStatus === NOT_APPLICABLE || progressStatus === ACCEPTED_VERIFICATION;
  return {
    showBanner: !hideBanner,
    scopeDisabled: !scopeEnabled,
    showCta: statusesWithCTA.includes(progressStatus),
    alertType,
    langPath: {
      title: `${LANG_BASE_PATH}.${progressStatus}.title`,
      body: `${LANG_BASE_PATH}.${progressStatus}.body_jsx`,
      cta: `${LANG_BASE_PATH}.${progressStatus}.cta`
    },
    redirectUrl: bannerInfo && bannerInfo.redirectUrl,
    closable: verificationAccepted
  };
};
export default useBanner;