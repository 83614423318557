/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-misused-promises */

import { createIndexedDBSuperstore } from 'superstore';
let storeInstance;
const getStoreInstance = () => {
  if (!storeInstance) {
    storeInstance = createIndexedDBSuperstore({
      namespace: 'framework-listing-lib'
    }).open();
  }
  return storeInstance;
};
export function getSuperStoreValue(key) {
  return getStoreInstance().then(store => store.get(key));
}
export function setSuperstoreValue(key, value) {
  return getStoreInstance().then(store => store.set(key, value));
}
export function wipeSuperstore() {
  return getStoreInstance().then(store => store.clear());
}