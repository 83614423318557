/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { viewEmail, selectUsersAndTeams, viewPerformance, confirmDelete } from 'email-management-lib/refactor/actions/manage/emailActions';
import { archive, archiveAndCancel, cancelEmail, confirmClone, edit, moveToFolder, unarchive } from 'EmailData/actions/emailActions';
import { fetchEmails } from 'email-management-lib/refactor/actions/manage/general';
import { trackDashboardInteraction } from 'EmailComponents/utils/tracking';
import { trackFrameworkTableInteraction } from 'EmailComponents/utils/tracking';

// @ts-expect-error not migrated TS yet

export function viewPerformanceFromDash(emailId, isFramework = false) {
  if (isFramework) {
    trackFrameworkTableInteraction({
      otherActions: 'view-performance'
    });
  } else {
    trackDashboardInteraction({
      otherActions: 'view-performance'
    });
  }
  return viewPerformance(emailId);
}
export function cancelEmailFromDash(emailId, isFramework = false) {
  if (isFramework) {
    trackFrameworkTableInteraction({
      otherActions: 'cancel-email'
    });
  } else {
    trackDashboardInteraction({
      otherActions: 'cancel-email'
    });
  }
  return dispatch => {
    dispatch(cancelEmail(emailId)).then(() => {
      if (!isFramework) {
        dispatch(fetchEmails());
      }
    });
  };
}
export function archiveFromDash(emailId, isFramework = false) {
  if (isFramework) {
    trackFrameworkTableInteraction({
      otherActions: 'archive'
    });
  } else {
    trackDashboardInteraction({
      otherActions: 'archive'
    });
  }
  return dispatch => {
    dispatch(archive(emailId)).then(() => {
      if (!isFramework) {
        dispatch(fetchEmails());
      }
    });
  };
}
export function archiveAndCancelFromDash(emailId, isFramework = false) {
  if (isFramework) {
    trackFrameworkTableInteraction({
      otherActions: 'archive-and-cancel'
    });
  } else {
    trackDashboardInteraction({
      otherActions: 'archive-and-cancel'
    });
  }
  return dispatch => {
    dispatch(archiveAndCancel(emailId)).then(() => {
      if (!isFramework) {
        dispatch(fetchEmails());
      }
    });
  };
}
export function selectUsersTeamsFromDash(emailId, isFramework = false) {
  if (isFramework) {
    trackFrameworkTableInteraction({
      otherActions: 'selectUsersAndTeams'
    });
  } else {
    trackDashboardInteraction({
      otherActions: 'selectUsersAndTeams'
    });
  }
  return dispatch => {
    dispatch(selectUsersAndTeams([emailId]));
  };
}
export function cloneFromDash(emailId, emailCloneOptions, isFramework = false) {
  if (isFramework) {
    trackFrameworkTableInteraction({
      otherActions: 'clone'
    });
  } else {
    trackDashboardInteraction({
      otherActions: 'clone'
    });
  }
  return dispatch => {
    dispatch(confirmClone(emailId, emailCloneOptions));
  };
}
export function confirmDeleteFromDash(emailId, isFramework = false) {
  if (isFramework) {
    trackFrameworkTableInteraction({
      otherActions: 'delete'
    });
  } else {
    trackDashboardInteraction({
      otherActions: 'delete'
    });
  }
  return dispatch => {
    dispatch(confirmDelete(emailId)).then(() => {
      if (!isFramework) {
        dispatch(fetchEmails());
      }
    });
  };
}
export function unarchiveFromDash(emailId, isFramework = false) {
  if (isFramework) {
    trackFrameworkTableInteraction({
      otherActions: 'unarchive'
    });
  } else {
    trackDashboardInteraction({
      otherActions: 'unarchive'
    });
  }
  return dispatch => {
    dispatch(unarchive(emailId)).then(() => {
      if (!isFramework) {
        dispatch(fetchEmails());
      }
    });
  };
}
export function moveToFolderDashboard(emailId, folderId) {
  return dispatch => {
    dispatch(moveToFolder(emailId, folderId)).then(() => {
      trackDashboardInteraction({
        otherActions: 'move-to-folder'
      });
      dispatch(fetchEmails());
    });
  };
}
export function editDashboard({
  emailId,
  email = null,
  isFramework = false
}) {
  if (isFramework) {
    trackFrameworkTableInteraction({
      otherActions: 'edit'
    });
  } else {
    trackDashboardInteraction({
      otherActions: 'edit'
    });
  }
  return edit({
    emailId,
    email
  });
}
export function viewEmailFromDash(email, isFramework = false) {
  if (isFramework) {
    trackFrameworkTableInteraction({
      otherActions: 'view-email'
    });
  } else {
    trackDashboardInteraction({
      otherActions: 'view-email'
    });
  }
  return viewEmail({
    emailId: email.id,
    previewKey: email.previewKey,
    fromBuffer: !email.isPublished()
  });
}