import devLogger from 'react-utils/devLogger';
import { createSearchReferenceResolver } from 'reference-resolvers-lite/createSearchReferenceResolver';
import identity from 'transmute/identity';
import { INTEGRATION_NAME } from '../CustomResolverReferenceTypes';
import PortalIdParser from 'PortalIdParser';

// https://git.hubteam.com/HubSpot/ApiUtils/blob/master/ApiUtilsCore/src/main/java/com/hubspot/apiutils/core/models/action/ActionStatus.java#L3

const formatIntegrationNameByIdResponse = response => response.results.map(integration => Object.assign({
  id: `${integration.id}`,
  label: integration.name
}, integration.description && {
  description: integration.description
}));
export const createIntegrationNameReferenceResolver = ({
  httpClient
}) => {
  return createSearchReferenceResolver({
    fetchByIds: ids => httpClient.post('apps-hublets/v2/customer/applications/batch/read', {
      query: {
        portalId: PortalIdParser.get()
      },
      data: {
        inputs: ids.map(id => parseInt(id, 10))
      }
    }),
    fetchBySearch: () => {
      devLogger.warn({
        message: 'Integration Name resolver does not support search fetching',
        key: 'integration-name-resolver'
      });
      return Promise.resolve({
        hasMore: false,
        offset: 0,
        references: []
      });
    },
    isIdValid: id => /^\d+$/.test(id),
    formatByIdResponse: formatIntegrationNameByIdResponse,
    formatSearchResponse: identity,
    referenceType: INTEGRATION_NAME
  });
};