/**
 * Helper methods to quickly create filter group objects used in crm-search.
 * Reference: https://product.hubteam.com/docs/crm-search/usage.html#filters-and-filter-groups
 */

/**
 * Finds objects where the chosen property value is equal to the input value.
 * @param {string} property
 * @param {string|number|boolean} value
 */
export function EQ(property, value) {
  return {
    operator: 'EQ',
    property,
    value
  };
}

/**
 * Finds objects where the chosen property value is not equal to the input value.
 * @param {string} property
 * @param {string|number|boolean} value
 */
export function NEQ(property, value) {
  return {
    operator: 'NEQ',
    property,
    value
  };
}

/**
 * Finds objects where the chosen property does not have a value.
 * @param {string} property
 */
export function NOT_HAS_PROPERTY(property) {
  return {
    operator: 'NOT_HAS_PROPERTY',
    property
  };
}

/**
 * Finds objects where the chosen property has a value.
 * @param {string} property
 */
export function HAS_PROPERTY(property) {
  return {
    operator: 'HAS_PROPERTY',
    property
  };
}

/**
 * Finds objects where the chosen property value does not exactly match any of the input values.
 * @param {string} property
 * @param {string[]|number[]|boolean[]} values
 */
export function NOT_IN(property, values) {
  return {
    operator: 'NOT_IN',
    property,
    values
  };
}

/**
 * Finds objects where the chosen property value is between value and highValue.
 * @param {string} property
 * @param {string|number|boolean} value
 * @param {object} options
 */
export function BETWEEN(property, value, highValue, options) {
  return Object.assign({
    highValue,
    operator: 'BETWEEN',
    property,
    value
  }, options || {});
}

/**
 * Finds objects where the chosen property value is less than or equals value.
 * @param {string} property
 * @param {string|number|boolean} value
 * @param {object} options
 */
export function LTE(property, value, options) {
  return Object.assign({
    operator: 'LTE',
    property,
    value
  }, options || {});
}

/**
 * Finds objects where the chosen property value is less than value.
 * @param {string} property
 * @param {string|number|boolean} value
 * @param {object} options
 */
export function LT(property, value, options) {
  return Object.assign({
    operator: 'LT',
    property,
    value
  }, options || {});
}

/**
 * Finds objects where the chosen property value is greater than or equals value.
 * @param {string} property
 * @param {string|number|boolean} value
 * @param {object} options
 */
export function GTE(property, value, options) {
  return Object.assign({
    operator: 'GTE',
    property,
    value
  }, options || {});
}

/**
 * Finds objects where the chosen property value is greater than value.
 * @param {string} property
 * @param {string|number|boolean} value
 * @param {object} options
 */
export function GT(property, value, options) {
  return Object.assign({
    operator: 'GT',
    property,
    value
  }, options || {});
}

/**
 * Finds objects where the chosen property value is in any of the input values.
 * @param {string} property
 * @param {string[]|number[]|boolean[]} values
 */
export function IN(property, values) {
  return {
    operator: 'IN',
    property,
    values
  };
}

/**
 * Find objects where a chosen date(time) property is within a date range with relation to the current time.
 * @param {string} property
 * @param {Object} options
 */
export function ROLLING_DATE_RANGE(property, options) {
  return Object.assign({
    operator: 'ROLLING_DATE_RANGE',
    property
  }, options);
}

/**
 * Find objects where a chosen time property is within a date range with relation to the current time.
 * @param {string} property
 * @param {Object} options
 */
export function TIME_UNIT_TO_DATE(property, options) {
  return Object.assign({
    operator: 'TIME_UNIT_TO_DATE',
    property
  }, options);
}

/**
 * Find objects where a chosen date(time) property is outside the last X days.
 * @param {string} property
 * @param {string[]|number[]|boolean[]} value
 * @param {Object} options
 */
export function GT_X_DAYS(property, value, options) {
  return Object.assign({
    operator: 'GT_X_DAYS',
    property,
    value
  }, options);
}

/**
 * Find objects where a chosen date(time) property is within the last X days.
 * @param {string} property
 * @param {string[]|number[]|boolean[]} value
 * @param {Object} options
 */
export function LT_X_DAYS(property, value, options) {
  return Object.assign({
    operator: 'LT_X_DAYS',
    property,
    value
  }, options);
}

/**
 * Simple validator for filter operators
 */
export function isFilterOperator(maybeFilterOperator) {
  if (!maybeFilterOperator || typeof maybeFilterOperator !== 'object') {
    return false;
  }
  if (!('operator' in maybeFilterOperator) || !('property' in maybeFilterOperator)) {
    return false;
  }
  return true;
}