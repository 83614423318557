import { useState, useEffect, useMemo } from 'react';
import Raven from 'raven-js';
export const useBeforeEnter = beforeEnter => {
  const [ready, setReady] = useState(() => typeof beforeEnter !== 'function');
  const maybePromise = useMemo(async () => {
    if (typeof beforeEnter === 'function') {
      await beforeEnter();
    }
  }, [beforeEnter]);
  useEffect(() => {
    if (ready) {
      return;
    }
    maybePromise.catch(err => {
      Raven.captureException(err);
    }).finally(() => {
      setReady(true);
    });
  }, [ready, maybePromise]);
  return ready;
};