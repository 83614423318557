const PROPERTY_SOURCE_USER_ID = /^userId:\d+$/;

/**
 * @return {boolean} Whether the given value represents a property source userId.
 * Property source values may use the format `userId:123` to indicate that the
 * source of a property change was a HubSpot user.
 */
export function isPropertySourceUserId(source) {
  return !!(source && PROPERTY_SOURCE_USER_ID.test(source));
}

/**
 * @return {number} Parsed user id (`123`) from a `userId:123` property source user id value.
 */
export function parsePropertySourceUserId(source) {
  if (source && PROPERTY_SOURCE_USER_ID.test(source)) {
    const id = source.split('userId:')[1];
    return parseInt(id, 10);
  }
  return undefined;
}