'use es6';

import BaseEmailDetail from 'EmailData/models/details/BaseEmailDetail';
import EmailCampaign from 'EmailData/models/details/children/EmailCampaign';
const batchDetailDefaults = {
  emailCampaign: null
};
class BatchEmailDetail extends BaseEmailDetail(Object.assign({}, batchDetailDefaults)) {
  static from(json) {
    const response = super.from(json);
    if (typeof json.emailCampaign !== 'undefined') {
      response.emailCampaign = EmailCampaign.from(json.emailCampaign);
    }
    return new BatchEmailDetail(response);
  }
}
export default BatchEmailDetail;