import { apiNameToTrialIds } from 'self-service-api/constants/Trials';
import { getUpgradeDataTrackingProperties } from '../../ums/utils';
import { setSurveyResponses } from '../common/api/surveyResponseApi';
import { tracker } from '../common/eventTracking/tracker';
export const handleSubmitSurveyResponses = async (apiName, surveyResponses, upgradeData) => {
  const trialId = apiNameToTrialIds[apiName];
  const selectedResponses = Object.values(surveyResponses).filter(response => response.isSelected).map(selectedResponse => selectedResponse.featureKey);
  tracker.track('trialActivationInteraction', Object.assign({
    action: 'survey submission successful'
  }, getUpgradeDataTrackingProperties(upgradeData), {
    apiName,
    features: selectedResponses
  }));
  await setSurveyResponses(selectedResponses, trialId);
};