export const PRIVATE = 'PRIVATE';
export const TEAM = 'TEAM';
export const PUBLIC = 'PUBLIC';
export const DEFAULT_PERMISSION_DETAILS = {
  userIds: []
};
export const getCheckedOptionFromView = viewUpdates => {
  if (!viewUpdates.private) {
    return PUBLIC;
  } else if (viewUpdates.teamId || viewUpdates.permissionDetails && viewUpdates.permissionDetails.userIds.length !== 0) {
    return TEAM;
  }
  return PRIVATE;
};