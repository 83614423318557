import styled, { css } from 'styled-components';
import { CALYPSO, CANDY_APPLE, THUNDERDOME } from 'HubStyleTokens/colors';
import UITableHoverCell from 'UIComponents/table/UITableHoverCell';
const activeHighlight = css(["box-shadow:inset 0 0 0 1px ", ";"], CALYPSO);
const errorHighlight = css(["box-shadow:inset 0 0 0 1px ", ";"], CANDY_APPLE);
const editedHighlight = css(["box-shadow:inset 0 0 0 1px ", ";"], THUNDERDOME);
export const EditableCell = styled(UITableHoverCell).withConfig({
  displayName: "StyledEditCellComponents__EditableCell",
  componentId: "sc-4yqrem-0"
})(["cursor:text;transition:box-shadow 0s;", ";", " &:hover,&:focus{", " transition:box-shadow 0.1s ease-in;}"], props => {
  // @ts-expect-error passed through to td
  return props['aria-pressed'] && editedHighlight;
}, props => {
  // @ts-expect-error passed through to td
  return props['aria-invalid'] && errorHighlight;
}, activeHighlight);
export const EditCellContentAndValidationAlertWrapper = styled.div.withConfig({
  displayName: "StyledEditCellComponents__EditCellContentAndValidationAlertWrapper",
  componentId: "sc-4yqrem-1"
})(["display:flex;"]);
export const ValidationAlert = styled.div.withConfig({
  displayName: "StyledEditCellComponents__ValidationAlert",
  componentId: "sc-4yqrem-2"
})(["margin-right:0.1em;"]);
export const ContactNameValidationAlert = styled.div.withConfig({
  displayName: "StyledEditCellComponents__ContactNameValidationAlert",
  componentId: "sc-4yqrem-3"
})(["margin-left:0.2em;margin-top:0.2em;"]);