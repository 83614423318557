import I18n from 'I18n';
import * as AbEmailCampaignStates from 'EmailData/utils/details/abEmailCampaignStates';
import { AB_EMAIL_TYPES, AB_VARIANT_EMAIL_MAP } from 'EmailData/constants/details/abEmailConstants';
import { AB_EMAIL_WINNER } from 'EmailData/utils/details/detailTypeUtil';
import { AB_METRIC_TYPES } from 'EmailData/constants/details/abEmailConstants';
import { isAutomatedEmailWinnerFromCampaignId } from 'EmailData/utils/details/abAutomatedEmailUtil';
export function isSampleSizeInsignificant(abEmailDetails) {
  const abCampaign = abEmailDetails.get('abEmailCampaign');
  return abCampaign && abCampaign.get('sampleSizeInsignificant');
}
export function isSamplingInsignificant(abEmailDetails) {
  const abCampaign = abEmailDetails.get('abEmailCampaign');
  return abCampaign && abCampaign.get('samplingInsignificant');
}
export function isInconclusive(abEmailDetails) {
  return isSampleSizeInsignificant(abEmailDetails) || isSamplingInsignificant(abEmailDetails);
}
export function isSampling(abEmailDetails) {
  const processingState = getCampaignProcessingState(abEmailDetails);
  return AbEmailCampaignStates.isSampling(processingState);
}
export function isDraft(abEmailDetails) {
  const processingState = getCampaignProcessingState(abEmailDetails);
  return AbEmailCampaignStates.isDraft(processingState);
}
export function isWinnerCanceled(abEmailDetails) {
  const processingState = getCampaignProcessingState(abEmailDetails);
  return AbEmailCampaignStates.isWinnerCanceled(processingState);
}
function isFinishedSuccessfully(abEmailDetails) {
  const processingState = getCampaignProcessingState(abEmailDetails);
  return AbEmailCampaignStates.isFinishedSuccessfully(processingState);
}
export function isProcessingCancelled(email) {
  const processingState = getCampaignProcessingState(email);
  return AbEmailCampaignStates.isCanceled(processingState);
}
export function isWaitingForWinner(abEmailDetails) {
  const processingState = getCampaignProcessingState(abEmailDetails);
  return AbEmailCampaignStates.isWaitingForWinner(processingState);
}
export function canCancel(abEmailDetails) {
  const processingState = getCampaignProcessingState(abEmailDetails);
  return AbEmailCampaignStates.canCancel(processingState);
}
export function canForceCancel(abEmailDetails) {
  const processingState = getCampaignProcessingState(abEmailDetails);
  return AbEmailCampaignStates.canForceCancel(processingState);
}
export function isEvenlySplitSend(abEmailDetails) {
  const primaryEmail = abEmailDetails.get('primaryEmail');
  return primaryEmail.get('abTestPercentage') === 100;
}
export function isAutomatedAb(abEmailDetails) {
  const primaryEmail = abEmailDetails.get('primaryEmail');
  return primaryEmail.isABAutomatedEmail();
}
export function hasWinner(abEmailDetails) {
  return Boolean(isFinishedSuccessfully(abEmailDetails) && !isEvenlySplitSend(abEmailDetails) && !isInconclusive(abEmailDetails) && getWinningCampaignId(abEmailDetails));
}
export function hasFallback(abEmailDetails) {
  return Boolean(isFinishedSuccessfully(abEmailDetails) && !isEvenlySplitSend(abEmailDetails) && isInconclusive(abEmailDetails) && getWinningCampaignId(abEmailDetails));
}
export function getWinningCampaignId(abEmailDetails) {
  const abCampaign = abEmailDetails.get('abEmailCampaign');
  if (abCampaign && abCampaign.get('winningEmailCampaignId')) {
    return abCampaign.get('winningEmailCampaignId');
  }
  const defaultWinnerType = getDefaultWinningEmailType(abEmailDetails);
  const defaultWinner = abEmailDetails.get(defaultWinnerType);
  return defaultWinner.primaryEmailCampaignId;
}
export function getAbMasterCampaignId(abEmailDetails) {
  return abEmailDetails.primaryEmail.primaryEmailCampaignId;
}
export function getAbVariantCampaignId(abEmailDetails) {
  return abEmailDetails.variantEmail.primaryEmailCampaignId;
}
export function isEmailWinner(abEmailDetails, campaignId) {
  if (isAutomatedAb(abEmailDetails)) {
    return isAutomatedEmailWinnerFromCampaignId(abEmailDetails, campaignId);
  }
  return hasWinner(abEmailDetails) && getWinningCampaignId(abEmailDetails) === campaignId;
}
export function isEmailFallback(abEmailDetails, campaignId) {
  return hasFallback(abEmailDetails) && getWinningCampaignId(abEmailDetails) === campaignId;
}
export function getVersionFilterId(abEmailDetails, currentVersion) {
  const currentId = currentVersion.primaryEmailCampaignId;
  const isWinningVersion = isEmailWinner(abEmailDetails, currentId);
  const isWinnerOrFallback = isWinningVersion || isEmailFallback(abEmailDetails, currentId);
  return isWinnerOrFallback ? AB_EMAIL_WINNER : currentVersion.primaryEmailCampaignId;
}
export function getWinnerExecTime(abEmailDetails) {
  const abCampaign = abEmailDetails.get('abEmailCampaign');
  return abCampaign.get('winnerExecTime');
}
function getCampaignProcessingState(abEmailDetails) {
  const abCampaign = abEmailDetails.get('abEmailCampaign');
  return abCampaign && abCampaign.processingState || '';
}
export function getTestMetric(abEmailDetails) {
  return isEvenlySplitSend(abEmailDetails) ? AB_METRIC_TYPES.NONE : abEmailDetails.primaryEmail.abSuccessMetric;
}
export function getHoursToWait(abEmailDetails) {
  return abEmailDetails.primaryEmail.abHoursToWait;
}
export function getTestPercentage(abEmailDetails) {
  return abEmailDetails.primaryEmail.abTestPercentage;
}
export function getWinningEmailType(abEmailDetails) {
  if (isWaitingForWinner(abEmailDetails) || isProcessingCancelled(abEmailDetails) || isSampleSizeInsignificant(abEmailDetails) || isSamplingInsignificant(abEmailDetails) || isEvenlySplitSend(abEmailDetails)) {
    return getDefaultWinningEmailType(abEmailDetails);
  } else if (isEmailWinner(abEmailDetails, getAbMasterCampaignId(abEmailDetails))) {
    return AB_EMAIL_TYPES.primaryEmail;
  }
  return AB_EMAIL_TYPES.variantEmail;
}
export function getWinningVariantType(abEmailDetails) {
  const winningEmail = getWinningEmailType(abEmailDetails);
  return AB_VARIANT_EMAIL_MAP[winningEmail];
}
export function getDefaultEmail(abEmailDetails) {
  const defaultType = getDefaultWinningEmailType(abEmailDetails);
  return abEmailDetails.get(defaultType);
}
export function getWinningEmail(abEmailDetails) {
  const winningType = getWinningEmailType(abEmailDetails);
  return abEmailDetails.get(winningType);
}
export function getDefaultWinningEmailType(abEmailDetails) {
  const sampleSizeInsignificant = isSampleSizeInsignificant(abEmailDetails);
  const defaultMetric = sampleSizeInsignificant ? 'abSampleSizeDefault' : 'abSamplingDefault';
  const defaultEmail = `${abEmailDetails.primaryEmail[defaultMetric]}Email`;
  if (defaultEmail === AB_EMAIL_TYPES.variantEmail) {
    return AB_EMAIL_TYPES.variantEmail;
  }
  return AB_EMAIL_TYPES.primaryEmail;
}
export function getButtonSubKey(hasTestWinner, isClearWinner) {
  if (!hasTestWinner) {
    return `chooseWinner`;
  }
  if (isClearWinner) {
    return `newTest`;
  }
  return `rerun`;
}
export function getTestTimes(abEmailDetails) {
  const testStartTimestamp = abEmailDetails.primaryEmail.get('publishDateMoment');
  const testEndTime = I18n.moment.portalTz(testStartTimestamp).add(getHoursToWait(abEmailDetails), 'h');
  return {
    testStartTime: I18n.moment.portalTz(testStartTimestamp),
    testEndTime
  };
}