import http from 'hub-http/clients/apiClient';
const CREATE_HARD_BOUNCE_URI = 'email/v1/contacts/build-portal-list/hardbounce/create/ils';
const UPDATE_HARD_BOUNCE_URI = 'email/v1/contacts/build-portal-list/hardbounce/update/v2';
export function createHardBounceListRequest(name, reasons, includeGlobalBounces, startTimestamp, endTimestamp) {
  const timeOptions = {};
  if (startTimestamp && endTimestamp) {
    timeOptions.startTimestamp = startTimestamp;
    timeOptions.endTimestamp = endTimestamp;
  }
  return http.put(CREATE_HARD_BOUNCE_URI, {
    data: Object.assign({
      listName: name,
      hardBounceClassifications: reasons,
      includeGlobalBounces
    }, timeOptions)
  });
}
export function updateHardBounceListRequest(listId, reasons, includeGlobalBounces, startTimestamp, endTimestamp) {
  let timeOptions = {};
  if (startTimestamp && endTimestamp) {
    timeOptions = {
      startTimestamp,
      endTimestamp
    };
  }
  return http.put(UPDATE_HARD_BOUNCE_URI, {
    data: Object.assign({
      listId,
      hardBounceClassifications: reasons,
      includeGlobalBounces
    }, timeOptions)
  });
}