'use es6';

import { Map as ImmutableMap } from 'immutable';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { RESET_EMAIL_DETAILS } from 'EmailData/actions/actionTypes';
import { FETCH_EMAIL_OPEN_DURATION, FETCH_EMAIL_OPEN_DURATION_REQUEST, SELECT_EMAIL_CHILD, SELECT_LIST_ID } from 'email-management-lib/refactor/actions/actionTypes';
import OpenDuration from 'email-management-lib/refactor/models/OpenDuration';

// Campaign fetch is used to show the loading icon after a
// campaign switch, but not when there's a background fetch.
const initialState = ImmutableMap({
  campaignFetch: false,
  stats: OpenDuration.from({})
});
function openDuration(state = initialState, action) {
  switch (action.type) {
    case FETCH_EMAIL_OPEN_DURATION:
      {
        if (action.error) {
          return state;
        }
        return ImmutableMap({
          campaignFetch: false,
          stats: OpenDuration.from(action.payload)
        });
      }
    case SELECT_EMAIL_CHILD:
    case SELECT_LIST_ID:
      return state.set('campaignFetch', true);
    case RESET_EMAIL_DETAILS:
      return initialState;
    default:
      return state;
  }
}
export default asyncResource(openDuration, {
  requestActions: [FETCH_EMAIL_OPEN_DURATION_REQUEST],
  responseActions: [FETCH_EMAIL_OPEN_DURATION],
  invalidatingActions: [RESET_EMAIL_DETAILS],
  customActions: [SELECT_EMAIL_CHILD, SELECT_LIST_ID]
});