import { useEffect, useState } from 'react';
import { getUserPermissions } from '../api/userPermissions';
export default function useFetchUserPermissions({
  objectType,
  objectIdOrIds
}) {
  const [state, setState] = useState({
    loading: true,
    userIds: [],
    error: undefined
  });
  useEffect(() => {
    /**
     * Does not fetch permissions when @objectIdOrIds is empty or if @objectIdOrIds has more than 1 element.
     */
    if (!objectIdOrIds || Array.isArray(objectIdOrIds) && objectIdOrIds.length !== 1) {
      setState({
        loading: false,
        userIds: [],
        error: undefined
      });
      return;
    }

    // Fetch user permissions for a single objectId
    const objectId = Array.isArray(objectIdOrIds) ? objectIdOrIds[0] : objectIdOrIds;
    setState({
      loading: true,
      userIds: [],
      error: undefined
    });
    getUserPermissions({
      objectType,
      objectId
    }).then(userIds => setState({
      loading: false,
      userIds,
      error: undefined
    })).catch(error => setState({
      loading: false,
      userIds: [],
      error
    }));
  }, [objectIdOrIds, objectType]);
  return state;
}