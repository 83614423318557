'use es6';

import { Record } from 'immutable';
const defaults = {
  email: '',
  firstName: '',
  id: null,
  lastName: ''
};
class HubUser extends Record(defaults) {
  static from(json) {
    return new HubUser(json);
  }
}
export default HubUser;