export const BASE_URL = 'campaigns/v1/campaigns';
export const CRM_SEARCH_CAMPAIGNS_URL = 'crm-search/search';
export const PAGE_DEFAULT_LIMIT = 30;
export const CAMPAIGN_DEFAULTS = {
  createdAt: 0,
  endedAt: 0,
  goals: {},
  notes: '',
  startedAt: 0
};
export const SORT_VALUES = {
  DISPLAY_NAME: 'DISPLAY_NAME',
  UTM: 'UTM',
  CREATED_AT: 'CREATED_AT',
  UPDATED_AT: 'UPDATED_AT'
};
export let SORT_DIR;
(function (SORT_DIR) {
  SORT_DIR["ASC"] = "ASC";
  SORT_DIR["DESC"] = "DESC";
})(SORT_DIR || (SORT_DIR = {}));
export let CRM_SEARCH_SORT;
(function (CRM_SEARCH_SORT) {
  CRM_SEARCH_SORT["CREATED_AT"] = "hs_created_at";
  CRM_SEARCH_SORT["UPDATED_AT"] = "hs_origin_asset_updated_at";
  CRM_SEARCH_SORT["DISPLAY_NAME"] = "hs_name";
})(CRM_SEARCH_SORT || (CRM_SEARCH_SORT = {}));
export const CRM_SEARCH_CAMPAIGN_PROPERTIES = ['hs_color_hex', 'hs_origin_asset_id', 'hs_name', 'hs_budget_items_sum_amount', 'hs_spend_items_sum_amount', 'hs_utm', 'hs_object_id'];
export const CRM_SEARCH_CAMPAIGN_PROPERTIES_FULL = ['hs_budget', 'hs_attribution_enabled', 'hs_audience', 'hs_all_assigned_business_unit_ids', 'hs_color_hex', 'hs_created_at', 'hs_created_by_user_id', 'hs_end_date', 'hs_goal',
// goals-start
'hs_session_goal', 'hs_new_contact_goal', 'hs_influenced_contact_goal', 'hs_influenced_closed_deal_goal', 'hs_influenced_revenue',
// goals-end
'hs_origin_asset_id', 'hs_name', 'hs_notes', 'hs_object_id', 'hs_owner', 'hs_projected_budget', 'hs_revenue', 'hs_start_date', 'hs_origin_asset_updated_at', 'hs_template_guid',
// Budget and spend items total
'hs_budget_items_sum_amount', 'hs_spend_items_sum_amount', 'hs_utm', 'hs_utms',
// campaign template custom action serialized
'hs_action_metadata'];
export const INCLUDE_CAMPAIGN_STEPS_PARAM = '?properties=steps';