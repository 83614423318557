import Raven from 'raven-js';
export const VIEW_CATEGORY_DEFAULT = 'DEFAULT';
export const VIEW_CATEGORY_PROMOTED = 'PROMOTED';
export const VIEW_CATEGORY_MINE = 'MINE';
export const VIEW_CATEGORY_OTHERS = 'OTHERS';
export const SupportedCategories = [VIEW_CATEGORY_DEFAULT, VIEW_CATEGORY_PROMOTED, VIEW_CATEGORY_MINE, VIEW_CATEGORY_OTHERS];
export const getMessageForCategory = category => {
  switch (category) {
    case VIEW_CATEGORY_DEFAULT:
      {
        return 'viewsManagement.addView.categories.hubspotProvidedViewsHeader';
      }
    case VIEW_CATEGORY_PROMOTED:
      {
        return 'viewsManagement.addView.categories.adminPromotedHeader';
      }
    case VIEW_CATEGORY_MINE:
      {
        return 'viewsManagement.addView.categories.myViewsHeader';
      }
    case VIEW_CATEGORY_OTHERS:
      {
        return 'viewsManagement.addView.categories.sharedViewsHeader';
      }
    default:
      {
        Raven.captureMessage('Unexpected view category', {
          extra: {
            category
          }
        });
        return '';
      }
  }
};