import { Record, Map as ImmutableMap } from 'immutable';
class TestSendModel extends Record({
  currentTestSends: ImmutableMap(),
  isValidating: false,
  errorMessages: [],
  isSending: false,
  isModalOpen: false,
  testSendConfig: {}
}) {
  static from(json) {
    return new TestSendModel(json);
  }
}
export default TestSendModel;