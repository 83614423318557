'use es6';

import { SET_COMPARISON_TOOL_RETURN_LOCATION } from 'email-management-lib/refactor/actions/actionTypes';
export function setComparisonToolReturnLocation(location) {
  return dispatch => {
    dispatch({
      type: SET_COMPARISON_TOOL_RETURN_LOCATION,
      payload: {
        location
      }
    });
  };
}