'use es6';

import { FETCH_BLOG_EMAILS_START, FETCH_BLOG_EMAILS_SUCCESS, FETCH_BLOG_EMAILS_FAIL, RESET_BLOG_EMAILS } from 'EmailData/actions/actionTypes';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { List } from 'immutable';
const initialState = List();
const blogEmailsReducer = (state = initialState, {
  type,
  response
}) => {
  switch (type) {
    case FETCH_BLOG_EMAILS_START:
      return List();
    case FETCH_BLOG_EMAILS_SUCCESS:
      return List(response);
    case FETCH_BLOG_EMAILS_FAIL:
    case RESET_BLOG_EMAILS:
      return initialState;
    default:
      return state;
  }
};
export default asyncResource(blogEmailsReducer, {
  requestActions: [FETCH_BLOG_EMAILS_START],
  responseActions: [FETCH_BLOG_EMAILS_SUCCESS, FETCH_BLOG_EMAILS_FAIL],
  invalidatingActions: [RESET_BLOG_EMAILS]
});