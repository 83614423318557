import { getCurrentPathname } from 'EmailData/utils/url';
import router from 'EmailData/utils/history';
// @ts-expect-error not migrated TS yet

import { archiveFromDash, archiveAndCancelFromDash, unarchiveFromDash, selectUsersTeamsFromDash, cancelEmailFromDash, confirmDeleteFromDash, viewPerformanceFromDash, cloneFromDash, editDashboard, viewEmailFromDash } from 'email-management-lib/refactor/actions/manage/emailDashboardActions';
import { multiMoveToFolder } from 'email-management-lib/refactor/actions/manage/emailActions';

/**
 * == Why does this file exist? ==
 * This file served as layer between the actions in `DashboardComponents` and the actions inside of
 * `ContentEmailUI`
 *
 * TODO:
 * `DashboardComponents` has since been archived and removed from this app meaning we can remove this
 * shim file and update the references to the actions to go directly to emailDashboardActions.
 */

export function archive(email, isFramework = false) {
  return archiveFromDash(email.id, isFramework);
}
export function archiveAndCancel(email, isFramework = false) {
  return archiveAndCancelFromDash(email.id, isFramework);
}
export function unarchive(email, isFramework = false) {
  return unarchiveFromDash(email.id, isFramework);
}
export function assignTeams(email, isFramework = false) {
  return selectUsersTeamsFromDash(email.id, isFramework);
}
export function cancel(email, isFramework = false) {
  return cancelEmailFromDash(email.id, isFramework);
}
export function confirmDelete(email, isFramework = false) {
  return confirmDeleteFromDash(email.id, isFramework);
}
export function viewPerformance(email, isFramework = false) {
  return viewPerformanceFromDash(email.id, isFramework);
}
export function confirmClone(emailId, emailCloneOptions, isFramework = false) {
  return cloneFromDash(emailId, emailCloneOptions, isFramework);
}
export function edit(email, isFramework = false) {
  return editDashboard({
    emailId: email.id,
    email,
    isFramework
  });
}
export function viewEmail(email, isFramework = false) {
  return viewEmailFromDash(email, isFramework);
}
export function backToContainer() {
  return () => {
    const currentPath = getCurrentPathname().split('/');
    currentPath.pop();
    router.navigateTo(currentPath.join('/'));
  };
}
export function moveToFolder({
  selectedEmailIds,
  targetFolderId,
  selectedEmailNames,
  targetFolderName,
  isFramework = false
}) {
  return multiMoveToFolder({
    selectedEmailIds,
    targetFolderId,
    selectedEmailNames,
    targetFolderName,
    isFramework
  });
}