import { PARENT_FOLDER_ID, FOLDER_PROPERTIES, UNFILED_ASSETS_ID } from 'framework-listing-lib/internal/Folders/constants/folders';
import { getPropertyValue } from 'framework-listing-lib/utils/crmObject';

/**
 * Returns an integer for @crmFolder's parent id.
 * Defaults to -1 when value is not found.
 * @param crmFolder
 */
export function getParentFolderId(crmFolder) {
  const parentFolderId = getPropertyValue(crmFolder, PARENT_FOLDER_ID);
  return parentFolderId ? parseInt(`${parentFolderId}`, 10) : UNFILED_ASSETS_ID;
}
export function getTotalAssets(crmFolder) {
  const totalAssets = getPropertyValue(crmFolder, FOLDER_PROPERTIES.objectCount, '0');
  return totalAssets ? parseInt(totalAssets, 10) : 0;
}