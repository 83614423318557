import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["data"];
import { useEffect, useMemo } from 'react';
import http from 'hub-http/clients/apiClient';
import { registerQuery, useQuery } from 'data-fetching-client';
import { Metrics } from 'framework-listing-lib/internal/metrics';
import { FOLDERS_POLLING_INTERVAL, UNFILED_ASSETS_ID } from '../constants/folders';
import { getParentFolderId } from '../utils/folders';
import useFolderPollingState from './useFolderPollingState';
export const GET_FOLDERS_FIELD_NAME = 'getFolders';
export const GET_FOLDERS = registerQuery({
  args: ['objectType', 'parentFolderId'],
  fieldName: GET_FOLDERS_FIELD_NAME,
  fetcher({
    objectType,
    parentFolderId
  }) {
    const isFetchingTopLevelFolders = !Array.isArray(parentFolderId) && parentFolderId === UNFILED_ASSETS_ID;
    return http.get('/framework/folders/v1', {
      query: {
        enabledObjectTypeId: isFetchingTopLevelFolders ? objectType : undefined,
        parentFolderIds: isFetchingTopLevelFolders ? undefined : parentFolderId
      }
    }).then(foldersResponse => {
      Metrics.counter('fetch-folders-success', {
        object_type_id: objectType
      }).increment();
      if (!isFetchingTopLevelFolders) {
        return foldersResponse;
      }

      /**
       * Occasionally, folders that have been recently created (with parentId) come back
       * from the response when fetching top-level folders.
       * We have to manually filter these out.
       */
      return foldersResponse.filter(crmFolder => {
        const folderParentId = getParentFolderId(crmFolder);
        const isInvalidTopLevelFolder = folderParentId !== UNFILED_ASSETS_ID;
        return !isInvalidTopLevelFolder;
      });
    }).catch(error => {
      Metrics.counter('fetch-folders-failure', {
        object_type_id: objectType
      }).increment();
      throw error;
    });
  }
});
export default function useFetchFolders({
  objectType,
  parentFolderId,
  skip
}) {
  const {
    affectedFolderIds,
    pollingState
  } = useFolderPollingState();

  /**
   * Set poll interval only for folders that have been affected in the "asset move" action.
   */
  const pollInterval = useMemo(() => {
    if (pollingState === 'OFF') {
      return undefined;
    }
    if (Array.isArray(parentFolderId)) {
      return parentFolderId.some(folderId => affectedFolderIds.includes(folderId)) ? FOLDERS_POLLING_INTERVAL : undefined;
    }
    return affectedFolderIds.includes(parseInt(`${parentFolderId}`, 10)) ? FOLDERS_POLLING_INTERVAL : undefined;
  }, [affectedFolderIds, parentFolderId, pollingState]);
  const _useQuery = useQuery(GET_FOLDERS, {
      pollInterval,
      variables: {
        objectType,
        parentFolderId
      },
      skip
    }),
    {
      data
    } = _useQuery,
    rest = _objectWithoutPropertiesLoose(_useQuery, _excluded);
  const {
    stopPolling
  } = rest;
  useEffect(() => {
    if (pollingState === 'OFF') {
      stopPolling();
    }
  }, [pollingState, stopPolling]);
  return useMemo(() => {
    return Object.assign({
      folders: data && GET_FOLDERS_FIELD_NAME in data ? data.getFolders : []
    }, rest);
  }, [data, rest]);
}