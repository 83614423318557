import { useQuery } from 'data-fetching-client';
import { useMemo } from 'react';
import { FETCH_USER_HAS_SEEN_EMAIL_FRAMEWORK_ONBOARDING_REQUEST } from 'email-management-lib/refactor/queries/emailFrameworkOnboarding';
import { HAS_SEEN_EMAIL_FRAMEWORK_ONBOARDING_KEY, SEEN_EMAIL_FRAMEWORK_ONBOARDING_TIME_KEY } from 'email-management-lib/refactor/api/userAttributes';
function useShouldSeeEmailFrameworkOnboarding() {
  const {
    data,
    loading: isFetchingUserHasSeenEmailFrameworkOnboarding
  } = useQuery(FETCH_USER_HAS_SEEN_EMAIL_FRAMEWORK_ONBOARDING_REQUEST);
  const hasSeenEmailFrameworkOnboarding = useMemo(() => {
    return !!data && !!data.onboardingAttributes && data.onboardingAttributes[HAS_SEEN_EMAIL_FRAMEWORK_ONBOARDING_KEY] === 'true';
  }, [data]);
  const seenEmailFrameworkOnboardingTime = useMemo(() => {
    if (data && data.onboardingAttributes && data.onboardingAttributes[SEEN_EMAIL_FRAMEWORK_ONBOARDING_TIME_KEY]) {
      return data.onboardingAttributes[SEEN_EMAIL_FRAMEWORK_ONBOARDING_TIME_KEY];
    }
    return null;
  }, [data]);
  return useMemo(() => ({
    shouldSeeOnboarding: !hasSeenEmailFrameworkOnboarding && !isFetchingUserHasSeenEmailFrameworkOnboarding,
    seenEmailFrameworkOnboardingTime
  }), [hasSeenEmailFrameworkOnboarding, isFetchingUserHasSeenEmailFrameworkOnboarding, seenEmailFrameworkOnboardingTime]);
}
export default useShouldSeeEmailFrameworkOnboarding;