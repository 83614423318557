import { useState } from 'react';
const initialState = {
  message: '',
  error: null
};
export const useToolAccessRequestForm = () => {
  const [formState, setFormState] = useState(initialState);
  const setApprover = approver => setFormState(prevState => Object.assign({}, prevState, {
    approver
  }));
  const setMessage = message => setFormState(prevState => Object.assign({}, prevState, {
    message
  }));
  const setError = error => setFormState(prevState => Object.assign({}, prevState, {
    error
  }));
  const resetForm = () => {
    setFormState(initialState);
  };
  return {
    formState,
    setApprover,
    setMessage,
    setError,
    resetForm
  };
};