import PortalIdParser from 'PortalIdParser';
import http from 'hub-http/clients/apiClient';
const automationAppUrl = 'automationapps/v1/summaries';
export function fetchWorkflowsRequest(emailId) {
  return http.get(`cosemail/v1/emails/${emailId}/workflows`, null);
}
export function fetchEmailSimpleWorkflows(emailId) {
  const uuid = `${PortalIdParser.get()}-${emailId}`;
  return http.get(automationAppUrl, {
    query: {
      limit: 25,
      sortBy: 'updatedAt',
      sortOrder: 'descending',
      uuid
    }
  });
}