import cloneDeep from 'hs-lodash/cloneDeep';
import { getPropertyValue, setPropertyValue } from 'framework-listing-lib/utils/crmObject';
import { TRANSLATED_FROM_ID, TRANSLATIONS_ASSOCIATION_NAME } from '../constants/translatedContent';

/**
 * Look for the primary group within @results
 *
 * If not present, find its reference from any of @langVariationObjectIds
 * to recreate the primary group.
 * @param results
 * @param primaryGroupId
 */
export function getPrimaryGroup(results, primaryGroupId) {
  let primaryGroupCrmObject = results.find(crmObject => crmObject.objectId === primaryGroupId);
  if (primaryGroupCrmObject) {
    return cloneDeep(primaryGroupCrmObject);
  }
  const variationCrmObjects = results.filter(crmObject => {
    const propertyValue = getPropertyValue(crmObject, TRANSLATED_FROM_ID);
    if (!propertyValue) {
      return false;
    }
    const intPropertyValue = parseInt(`${propertyValue}`, 10);
    return intPropertyValue === primaryGroupId;
  });
  if (variationCrmObjects.length === 0) {
    return undefined;
  }

  // primary group is a reference of any of its translated content
  primaryGroupCrmObject = variationCrmObjects[0].associatedObjects[TRANSLATIONS_ASSOCIATION_NAME].find(associatedObject => associatedObject.objectId === primaryGroupId);
  if (!primaryGroupCrmObject) {
    return undefined;
  }
  primaryGroupCrmObject = cloneDeep(primaryGroupCrmObject);
  if (!primaryGroupCrmObject.associatedObjects) {
    primaryGroupCrmObject.associatedObjects = {
      [TRANSLATIONS_ASSOCIATION_NAME]: []
    };
  }
  const associatedObjects = [];
  /**
   * We can't use [...new Set(arr)] to find for distinct values.
   * Sticking with a Map for now.
   */
  const associatedObjectMap = {};
  for (const variationCrmObject of variationCrmObjects) {
    if (!variationCrmObject.associatedObjects) {
      continue;
    }

    /**
     * Add all associated objects that are not present in @associatedObjectMap
     */
    for (const associatedObject of variationCrmObject.associatedObjects[TRANSLATIONS_ASSOCIATION_NAME]) {
      if (associatedObjectMap[associatedObject.objectId] || associatedObject.objectId === primaryGroupId) {
        continue;
      }
      associatedObjectMap[associatedObject.objectId] = true;
      associatedObjects.push(associatedObject);
    }

    /**
     * Make sure that variationCrmObject is also added to @primaryGroupCrmObject
     */
    if (!(variationCrmObject.objectId in associatedObjectMap)) {
      const clonedVariationCrmObject = cloneDeep(variationCrmObject);
      if (!clonedVariationCrmObject.associatedObjects) {
        clonedVariationCrmObject.associatedObjects = {
          [TRANSLATIONS_ASSOCIATION_NAME]: []
        };
      } else {
        clonedVariationCrmObject.associatedObjects[TRANSLATIONS_ASSOCIATION_NAME] = [];
      }
      associatedObjectMap[clonedVariationCrmObject.objectId] = true;
      associatedObjects.push(clonedVariationCrmObject);
    }
  }
  primaryGroupCrmObject.associatedObjects[TRANSLATIONS_ASSOCIATION_NAME] = associatedObjects;
  return primaryGroupCrmObject;
}

/**
 * Add primary group CRM object to list of @results.
 *
 * Useful when filtering the table and the primary group is not present in the results
 * but present in one of its lang variations as referenced object.
 * @param results
 * @param primaryGroupId
 * @param langVariationObjectIds
 */
function addPrimaryGroupIfNotPresent(results, primaryGroupId, langVariationObjectIds) {
  const hasPrimaryGroupInCurrentResults = results.some(crmObject => crmObject.objectId === primaryGroupId);
  if (hasPrimaryGroupInCurrentResults) {
    return results;
  }
  const primaryGroupCrmObject = getPrimaryGroup(results, primaryGroupId);
  if (!primaryGroupCrmObject) {
    return results;
  }

  /**
   * Find index of translated content that is closer to the top of @results.
   * This is needed so we maintain the same order of results rendered on the table after expanding primary groups.
   *
   * Add to the bottom of @results if none of its translated content are found
   */
  let indexOfFirstTranslatedContent = -1;
  for (const langVariationObjectId of langVariationObjectIds) {
    const indexOfLangVariation = results.findIndex(crmObject => crmObject.objectId === langVariationObjectId);
    if (indexOfLangVariation === -1) {
      continue;
    }
    if (indexOfFirstTranslatedContent === -1 || indexOfLangVariation < indexOfFirstTranslatedContent) {
      indexOfFirstTranslatedContent = indexOfLangVariation;
    }
  }
  if (indexOfFirstTranslatedContent !== -1) {
    results.splice(indexOfFirstTranslatedContent, 0, primaryGroupCrmObject);
  } else {
    results.push(primaryGroupCrmObject);
  }
  return results;
}

/**
 * For every expanded primary group in @expandedPrimaryGroups,
 * adds primaryGroupCrmObject to @currentResults and filter out any lang variation.
 * @param currentResults
 * @param expandedPrimaryGroups
 */
export function showMultiLanguageGroup(currentResults, expandedPrimaryGroups) {
  let results = [...currentResults];
  for (const entry of expandedPrimaryGroups) {
    const [primaryGroupId, langVariationObjectIds] = entry;
    if (langVariationObjectIds.length === 0) {
      continue;
    }
    const intPrimaryGroupId = parseInt(primaryGroupId, 10);
    results = addPrimaryGroupIfNotPresent(results, intPrimaryGroupId, langVariationObjectIds);

    // Remove all @langVariationObjectIds from results
    results = results.filter(crmObject => !langVariationObjectIds.includes(crmObject.objectId));
  }
  return results;
}
function wipeTranslatedContent(crmObject) {
  const copyObject = cloneDeep(crmObject);
  if (!copyObject.associatedObjects) {
    copyObject.associatedObjects = {
      [TRANSLATIONS_ASSOCIATION_NAME]: []
    };
  } else {
    copyObject.associatedObjects[TRANSLATIONS_ASSOCIATION_NAME] = [];
  }
  delete copyObject.properties[TRANSLATED_FROM_ID];
  delete copyObject.properties['hs_translated_content'];
  return copyObject;
}

/**
 * Note:
 *  - primary group contains a reference to all of its translated content
 *  - translated content contains a reference to its primary group as well as all other "sibling" translations.
 *
 * This method scans @currentResults and:
 *  - removes @langVariationObjectIdToRemove from its primary group
 *  - removes @langVariationObjectIdToRemove from all "sibling" translations
 *  - remove all references from @langVariationObjectIdToRemove object so it becomes a primary group
 * @param currentResults
 * @param primaryGroupId
 * @param langVariationObjectIdToRemove
 */
export function removeReferencesFromResults(currentResults, primaryGroupId, langVariationObjectIdToRemove) {
  const primaryGroupCrmObject = getPrimaryGroup(currentResults, primaryGroupId);
  if (!primaryGroupCrmObject || !primaryGroupCrmObject.associatedObjects) {
    return currentResults;
  }
  const allTranslatedContentIds = primaryGroupCrmObject.associatedObjects[TRANSLATIONS_ASSOCIATION_NAME].map(associatedObject => associatedObject.objectId);
  return currentResults.map(crmObject => {
    const isPrimaryGroup = crmObject.objectId === primaryGroupId;
    const isLangVariationBeingRemoved = !isPrimaryGroup && crmObject.objectId === langVariationObjectIdToRemove;
    const isOtherLangVariation = !isLangVariationBeingRemoved && allTranslatedContentIds.includes(crmObject.objectId);
    if (!isPrimaryGroup && !isLangVariationBeingRemoved && !isOtherLangVariation) {
      return crmObject;
    }

    /**
     * Remove variation reference from primary group and from other lang variations.
     */
    if ((isPrimaryGroup || isOtherLangVariation) && crmObject.associatedObjects) {
      const copyObject = cloneDeep(crmObject);
      copyObject.associatedObjects[TRANSLATIONS_ASSOCIATION_NAME] = copyObject.associatedObjects[TRANSLATIONS_ASSOCIATION_NAME].filter(associatedObject => associatedObject.objectId !== langVariationObjectIdToRemove);
      return copyObject;
    }

    /**
     * Clean up references in @langVariationObjectIdToRemove
     */
    if (isLangVariationBeingRemoved && crmObject.associatedObjects) {
      return wipeTranslatedContent(crmObject);
    }
    return crmObject;
  });
}

/**
 * Remove @translatedContentToRemove's references from:
 *  - Its @primaryGroupId
 *  - Other translated content from @primaryGroupId
 *  - @translatedContentToRemove itself so it also becomes a standalone primary group
 *
 * In case @translatedContentToRemove is not in @currentResults (usually when not searching or filtering the table) add it next to @primaryGroupId.
 * @param currentResults
 * @param primaryGroupId
 * @param translatedContentToRemove
 * @param isFilteringOrSearching
 */
export function removeFromMultiLanguageGroup(currentResults, primaryGroupId, translatedContentToRemove, isFilteringOrSearching) {
  const results = removeReferencesFromResults(currentResults, primaryGroupId, translatedContentToRemove.objectId);
  const hasTranslatedContentToRemove = results.some(crmObject => crmObject.objectId === translatedContentToRemove.objectId);
  if (hasTranslatedContentToRemove || isFilteringOrSearching) {
    return results;
  }

  /**
   * If @translatedContentToRemove is not in @results, add it next to its primary group.
   */
  const newTranslatedContentToRemove = wipeTranslatedContent(translatedContentToRemove);
  const primaryGroupIndex = results.findIndex(crmObject => crmObject.objectId === primaryGroupId);
  if (primaryGroupIndex !== -1) {
    results.splice(primaryGroupIndex, 0, newTranslatedContentToRemove);
  } else {
    results.push(newTranslatedContentToRemove);
  }
  return results;
}

/**
 * - Remove all translated content references from @translatedContent
 * - Copy all translated content referenced in @primaryGroupId object to new primary group object and update hs_translated_from_id.
 * @param currentResults
 * @param primaryGroupId
 * @param translatedContent
 */
function swapPrimaryGroup(currentResults, primaryGroupId, translatedContent) {
  const oldPrimaryGroupCrmObject = getPrimaryGroup(currentResults, primaryGroupId);
  if (!oldPrimaryGroupCrmObject || !oldPrimaryGroupCrmObject.associatedObjects) {
    return undefined;
  }
  const newPrimaryGroupCrmObject = wipeTranslatedContent(translatedContent);
  const translatedContentObjects = [oldPrimaryGroupCrmObject, ...oldPrimaryGroupCrmObject.associatedObjects[TRANSLATIONS_ASSOCIATION_NAME].filter(associatedObject => associatedObject.objectId !== newPrimaryGroupCrmObject.objectId)];
  newPrimaryGroupCrmObject.associatedObjects[TRANSLATIONS_ASSOCIATION_NAME] = translatedContentObjects.map(associatedObject => {
    const associatedObjectWithoutReferences = wipeTranslatedContent(associatedObject);
    return setPropertyValue(associatedObjectWithoutReferences, TRANSLATED_FROM_ID, `${newPrimaryGroupCrmObject.objectId}`);
  });
  return newPrimaryGroupCrmObject;
}

/**
 * Make @translatedContent the new primary group:
 *   - Copy @primaryGroupId references to @translatedContent and update references (hs_translated_from_id property).
 *
 * From @currentResults:
 *   - if @translatedContent: replace it with new primary group object (with updated references)
 *   - if @primaryGroupId or any other of its translated content: update references and set new hs_translated_from_id's value.
 * @param currentResults
 * @param primaryGroupId
 * @param translatedContent
 * @param isFilteringOrSearching
 */
export function makePrimaryLanguageGroup(currentResults, primaryGroupId, translatedContent, isFilteringOrSearching) {
  const newPrimaryGroupCrmObject = swapPrimaryGroup(currentResults, primaryGroupId, translatedContent);
  if (!newPrimaryGroupCrmObject || !newPrimaryGroupCrmObject.associatedObjects) {
    return currentResults;
  }
  const newTranslatedContentObjects = [wipeTranslatedContent(newPrimaryGroupCrmObject), ...newPrimaryGroupCrmObject.associatedObjects[TRANSLATIONS_ASSOCIATION_NAME]];
  const translatedContentIdsFromNewPrimaryGroup = newTranslatedContentObjects.reduce((acc, crmObject) => {
    acc[`${crmObject.objectId}`] = true;
    return acc;
  }, {});
  const indexOfOldPrimaryGroup = currentResults.findIndex(crmObject => crmObject.objectId === primaryGroupId);
  const indexOfTranslatedContent = currentResults.findIndex(crmObject => crmObject.objectId === translatedContent.objectId);

  /**
   * Need to replace "oldPrimaryGroup" with "newPrimaryGroup" when not searching or filtering
   */
  const shouldReplaceOldPrimaryGroup = !isFilteringOrSearching && indexOfOldPrimaryGroup !== -1 && indexOfTranslatedContent === -1;

  /**
   * Replace objects from current results
   */
  return currentResults.map(crmObject => {
    if (crmObject.objectId === newPrimaryGroupCrmObject.objectId) {
      return newPrimaryGroupCrmObject;
    }

    /**
     * It's none of new/old primary group or any of its translated content
     */
    if (!(crmObject.objectId in translatedContentIdsFromNewPrimaryGroup)) {
      return crmObject;
    }
    if (crmObject.objectId === primaryGroupId && shouldReplaceOldPrimaryGroup) {
      return newPrimaryGroupCrmObject;
    }

    /**
     * Its either the old primary group or any of its translated content.
     * We need to update @associatedObjects and @hs_translated_from_id property to point to
     * @newPrimaryGroupCrmObject
     */
    const oldPrimaryGroupWithoutReferences = wipeTranslatedContent(crmObject);
    oldPrimaryGroupWithoutReferences.associatedObjects[TRANSLATIONS_ASSOCIATION_NAME] = newTranslatedContentObjects.filter(associatedObject => associatedObject.objectId !== crmObject.objectId);
    return setPropertyValue(oldPrimaryGroupWithoutReferences, TRANSLATED_FROM_ID, `${newPrimaryGroupCrmObject.objectId}`);
  });
}

/**
 * Add @translatedContent to @primaryGroupId and remove @translatedContent from the results list.
 * @translatedContent is usually another primary group without any translated content.
 * @param currentResults
 * @param primaryGroupId
 * @param translatedContent
 */
export function moveToPrimaryGroup(currentResults, primaryGroupId, translatedContent) {
  const primaryGroupCrmObject = getPrimaryGroup(currentResults, primaryGroupId);

  /**
   * If @primaryGroupCrmObject is not found, it means that it is not listed in @currentResults.
   * In this case we need to filter out @translatedContent from @currentResults
   */
  if (!primaryGroupCrmObject || !primaryGroupCrmObject.associatedObjects) {
    return currentResults.filter(crmObject => crmObject.objectId !== translatedContent.objectId);
  }

  /**
   * Copy translatedContent and set hs_translated_id property
   */
  let copyTranslatedContent = cloneDeep(translatedContent);
  copyTranslatedContent = setPropertyValue(copyTranslatedContent, TRANSLATED_FROM_ID, `${primaryGroupId}`);

  /**
   * Add @translatedContent to primaryGroupCrmObject
   */
  primaryGroupCrmObject.associatedObjects[TRANSLATIONS_ASSOCIATION_NAME].push(copyTranslatedContent);
  return currentResults.map(crmObject => {
    if (crmObject.objectId === primaryGroupId) {
      return primaryGroupCrmObject;
    }

    /**
     * @translatedContent is a translation of @primaryGroupCrmObject and we don't need to render it
     * on the table anymore.
     */
    if (crmObject.objectId === copyTranslatedContent.objectId) {
      return null;
    }
    return crmObject;
  }).filter(Boolean);
}

/**
 * Creates a map with primary group ids and its translated content ids.
 *
 * @example
 * const primaryGroupMap = {
 *  [groupId1]: [translatedId1, translatedId2, ..., translatedIdM],
 *  [groupId2]: [translatedId3, translatedId4, ..., translatedIdN],
 * }
 * @param currentResults
 * @param options
 */
export function getPrimaryGroupMap(currentResults, {
  includeTranslatedContentIds
}) {
  const primaryGroupMap = {};
  for (const crmObject of currentResults) {
    const translatedFromId = getPropertyValue(crmObject, TRANSLATED_FROM_ID);
    const primaryGroupId = translatedFromId === undefined ? crmObject.objectId : parseInt(`${translatedFromId}`, 10);
    if (primaryGroupId in primaryGroupMap) {
      continue;
    }
    if (!includeTranslatedContentIds) {
      primaryGroupMap[primaryGroupId] = [];
      continue;
    }
    const primaryGroupCrmObject = getPrimaryGroup(currentResults, primaryGroupId);
    if (!primaryGroupCrmObject || !primaryGroupCrmObject.associatedObjects || !primaryGroupCrmObject.associatedObjects[TRANSLATIONS_ASSOCIATION_NAME]) {
      continue;
    }
    primaryGroupMap[primaryGroupId] = primaryGroupCrmObject.associatedObjects[TRANSLATIONS_ASSOCIATION_NAME].map(associatedObject => associatedObject.objectId);
  }
  return primaryGroupMap;
}

/**
 * Counts the amount of primary groups in @currentResults
 * @param currentResults
 */
export function countPrimaryGroups(currentResults) {
  let count = 0;
  for (const crmObject of currentResults) {
    const translatedFromId = getPropertyValue(crmObject, TRANSLATED_FROM_ID);
    const hasTranslatedFrom = translatedFromId !== undefined;
    const hasTranslations = crmObject.associatedObjects && crmObject.associatedObjects[TRANSLATIONS_ASSOCIATION_NAME] && crmObject.associatedObjects[TRANSLATIONS_ASSOCIATION_NAME].length > 0;
    const isPrimaryGroup = Boolean(!hasTranslatedFrom && hasTranslations);
    count += isPrimaryGroup ? 1 : 0;
  }
  return count;
}

/**
 * Store all CRM objects from this group so we can de-select them
 * after the new hierarchy is built on the table.
 *
 * Select only CRM objects with groups.
 *
 * More details in https://git.hubteam.com/HubSpot/framework-listing-lib/issues/1242
 */
export function getAllCrmObjectsFromGroup(currentResults, primaryGroupMap) {
  const primaryGroupIds = Object.keys(primaryGroupMap);
  let crmObjectsFromGroup = [];
  for (const primaryGroupId of primaryGroupIds) {
    const intPrimaryGroupId = parseInt(primaryGroupId, 10);
    const translatedContentObjectIds = primaryGroupMap[intPrimaryGroupId];
    if (translatedContentObjectIds.length === 0) {
      continue;
    }
    crmObjectsFromGroup = [...crmObjectsFromGroup, ...currentResults.filter(crmObject => crmObject.objectId === intPrimaryGroupId || translatedContentObjectIds.includes(crmObject.objectId))];
  }
  return crmObjectsFromGroup;
}