import { getApp, getScreen, getSource } from '../utils';
export const useUpgradeDataFromUpgradeConfig = upgradeConfig => {
  const app = getApp();
  const screen = getScreen();
  const source = getSource(app, screen, upgradeConfig.upgradeKey);
  return {
    app,
    screen,
    source,
    upgradeProduct: upgradeConfig.upgradeProducts[0],
    upgradeProducts: upgradeConfig.upgradeProducts,
    apiName: upgradeConfig.apiNames[0],
    apiNames: upgradeConfig.apiNames,
    uniqueId: upgradeConfig.upgradeKey,
    pointType: upgradeConfig.pointType,
    status: upgradeConfig.status,
    feature: upgradeConfig.featureStoreFeature,
    upgradeKey: upgradeConfig.upgradeKey
  };
};