import { is, Map as ImmutableMap, fromJS } from 'immutable';
import { createModel } from './asyncResourceModel';
function asyncResource(reducer, opts) {
  const initialState = createModel({
    data: reducer(undefined, {})
  });
  const {
    requestActions,
    responseActions,
    invalidatingActions = [],
    resetActions = [],
    customActions = []
  } = opts;
  const wrappedAsyncResource = (state = initialState, action) => {
    const {
      type,
      error: actionError
    } = action;
    const data = state.getData();
    const internalState = state._internalState;
    if (requestActions.indexOf(type) >= 0) {
      // Request made
      return state.set('_internalState', internalState.set('loading', true));
    } else if (responseActions.indexOf(type) >= 0) {
      if (actionError) {
        let errorMap;
        if (action.payload instanceof XMLHttpRequest) {
          // Support earlyFetch requests
          let parsedJson = {};
          try {
            parsedJson = JSON.parse(action.payload.responseText);
          } catch (e) {
            /* Ignore failures */
          }
          errorMap = ImmutableMap({
            responseJSON: parsedJson,
            status: action.payload.status
          });
        } else {
          errorMap = ImmutableMap(fromJS(action.payload));
        }
        const _errorType = errorMap.getIn(['responseJSON', 'errorType'], null);
        const errorType = _errorType || errorMap.getIn(['responseJSON']) && errorMap.getIn(['responseJSON']).errorType || null;
        // Request failed
        return state.set('_internalState', internalState.merge({
          data: reducer(data, action),
          loading: false,
          error: true,
          errorStatus: errorMap.get('status', null),
          errorType,
          errorTokens: errorMap.getIn(['responseJSON', 'errorTokens'], null)
        }));
      }
      // Request succeeded
      return state.set('_internalState', internalState.merge({
        data: reducer(data, action),
        dirty: false,
        error: false,
        errorStatus: null,
        errorType: null,
        errorTokens: null,
        loading: false,
        lastLoaded: new Date().valueOf()
      }));
    } else if (invalidatingActions.indexOf(type) >= 0) {
      return state.set('_internalState', internalState.merge({
        dirty: true,
        data: reducer(data, action)
      }));
    } else if (resetActions.indexOf(type) >= 0) {
      return initialState.mergeIn(['_internalState'], {
        data: reducer(data, action)
      });
    } else if (customActions.indexOf(type) >= 0) {
      const result = reducer(data, action);
      if (is(result, data)) {
        // Same result from reducer
        return state;
      }
      return state.set('_internalState', internalState.merge({
        data: result
      }));
    }

    // Else: Unrelated action, do not run reducer
    return state;
  };
  return wrappedAsyncResource;
}
export default asyncResource;