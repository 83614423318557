'use es6';

import { fetchHealthDismissStatus, updateHealthDismissStatus } from 'EmailData/actions/portalStatusActions';
import { portalHealthStatusSelector } from 'EmailData/portalStatus/selectors';
export function fetchPortalDismissStatus() {
  return dispatch => {
    dispatch(fetchHealthDismissStatus());
  };
}
export function updatePortalDismissStatus(dismissed = true) {
  return (dispatch, getState) => {
    const state = getState();
    const healthStatus = portalHealthStatusSelector(state);
    dispatch(updateHealthDismissStatus(healthStatus, dismissed));
  };
}