'use es6';

import { Map as ImmutableMap } from 'immutable';
import { REHYDRATE_FROM_USER_SETTINGS } from 'EmailData/actions/actionTypes';
import { FETCH_AB_AUTOMATED_HISTORY, SAVE_IS_NEW_TO_TEST_RESULTS_TAB } from 'email-management-lib/refactor/actions/actionTypes';
function testResults(state = ImmutableMap({
  isNewToTestResultsTab: false,
  history: null
}), action) {
  switch (action.type) {
    case REHYDRATE_FROM_USER_SETTINGS:
      return state.merge({
        isNewToTestResultsTab: action.payload.has('isNewToTestResultsTab') ? action.payload.get('isNewToTestResultsTab') : true
      });
    case SAVE_IS_NEW_TO_TEST_RESULTS_TAB:
      if (action.error) {
        return state;
      }
      return state.set('isNewToTestResultsTab', action.payload);
    case FETCH_AB_AUTOMATED_HISTORY:
      if (action.error) {
        return state;
      }
      return state.merge(action.payload);
    default:
      return state;
  }
}
export default testResults;