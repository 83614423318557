import { useQuery } from 'data-fetching-client';
import { FETCH_EMAIL_INSIGHTS } from 'messaging-publishing-lib/Insights/queries';
import { isSupportedInsight, isSupportedReason, isSupportedRecommendation, isSupportedSpotlight } from '../utils/support';
export const filterUnsupportedData = response => {
  var _response$spotlights, _response$insights, _response$reasons, _response$recommendat;
  return Object.assign({}, response, {
    /**
     * For emails with no insights, these
     * properties will be undefined
     */
    spotlights: (_response$spotlights = response.spotlights) === null || _response$spotlights === void 0 ? void 0 : _response$spotlights.filter(isSupportedSpotlight),
    insights: (_response$insights = response.insights) === null || _response$insights === void 0 ? void 0 : _response$insights.filter(isSupportedInsight),
    reasons: (_response$reasons = response.reasons) === null || _response$reasons === void 0 ? void 0 : _response$reasons.filter(isSupportedReason),
    recommendations: (_response$recommendat = response.recommendations) === null || _response$recommendat === void 0 ? void 0 : _response$recommendat.filter(isSupportedRecommendation)
  });
};
const useFetchEmailInsights = (variables, forceSkip) => {
  const {
    data,
    loading: fetchEmailInsightLoading,
    error: fetchEmailInsightError,
    refetch
  } = useQuery(FETCH_EMAIL_INSIGHTS, {
    variables,
    skip: forceSkip || !variables.emailContentId
  });
  return {
    emailInsight: data ? filterUnsupportedData(data.emailInsights) : undefined,
    loading: fetchEmailInsightLoading,
    error: !!fetchEmailInsightError,
    refetch
  };
};
export default useFetchEmailInsights;