import { Metrics } from '../../metrics/Metrics';
export const fetchConditionalPropertyRules = (objectTypeId, httpClient, query) => {
  // Semi-paginated unified endpoint will blend stage change and CP rules
  // All stage change rules returned at once (in unpaginated fashion)
  // If offset provided, then only fetch CP rules (which are paginated)
  // see https://git.hubteam.com/HubSpot/CRM-Issues/issues/12045#issuecomment-4108084

  return httpClient.get(`conditional-properties/v2/unified-rules`, {
    query: Object.assign({
      objectTypeId,
      pageSize: 100,
      includeActualConditionalPropertyRules: true
    }, query)
  }).then(response => {
    const {
      results,
      offset: newOffset,
      hasMore
    } = response;
    if (hasMore) {
      return fetchConditionalPropertyRules(objectTypeId, httpClient, Object.assign({}, query, {
        offset: newOffset
      })).then(rules => results.concat(rules));
    }
    Metrics.counter('fetchconditionalpropertyrules-success').increment();
    return results;
  }).catch(reason => {
    Metrics.counter('fetchconditionalpropertyrules-failure').increment();
    throw reason;
  });
};