const _unique = definitions => {
  const names = {};
  return definitions.filter(definition => {
    if (definition.kind !== 'FragmentDefinition') {
      return true;
    }
    const name = definition.name.value;
    if (names[name]) {
      return false;
    } else {
      names[name] = true;
      return true;
    }
  });
};
import { gql } from '@apollo/client';
import { PropertyInputPropertyDefinitionFields } from 'customer-data-properties/fragments';
export const CRM_OBJECT_FRAGMENT = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"FragmentDefinition\",\"name\":{\"kind\":\"Name\",\"value\":\"CrmObjectFragment\"},\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"CrmObject\"}},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"}},{\"kind\":\"Field\",\"alias\":{\"kind\":\"Name\",\"value\":\"objectId\"},\"name\":{\"kind\":\"Name\",\"value\":\"id\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"allProperties\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"name\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"value\"}}]}}]}}]}", {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "CrmObjectFragment"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "CrmObject"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        alias: {
          kind: "Name",
          value: "objectId"
        },
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "allProperties"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "value"
            }
          }]
        }
      }]
    }
  }]
});
export const OBJECT_TYPE_DEFINITION_FRAGMENT = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"FragmentDefinition\",\"name\":{\"kind\":\"Name\",\"value\":\"ObjectTypeDefinitionFragment\"},\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"ObjectTypeDefinition\"}},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"}},{\"kind\":\"Field\",\"alias\":{\"kind\":\"Name\",\"value\":\"objectTypeId\"},\"name\":{\"kind\":\"Name\",\"value\":\"id\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"singularForm\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"pluralForm\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"defaultSearchPropertyNames\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"name\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"primaryDisplayLabelPropertyName\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"requiredProperties\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"secondaryDisplayLabelPropertyNames\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"fullyQualifiedName\"}}]}}]}", {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ObjectTypeDefinitionFragment"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ObjectTypeDefinition"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        alias: {
          kind: "Name",
          value: "objectTypeId"
        },
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "singularForm"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "pluralForm"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "defaultSearchPropertyNames"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "primaryDisplayLabelPropertyName"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "requiredProperties"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "secondaryDisplayLabelPropertyNames"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "fullyQualifiedName"
        }
      }]
    }
  }]
});
export const PROPERTY_GROUP_FRAGMENT = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"FragmentDefinition\",\"name\":{\"kind\":\"Name\",\"value\":\"PropertyGroupFragment\"},\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"PropertyGroup\"}},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"displayOrder\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"hubspotDefined\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"label\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"name\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"allPropertyDefinitions\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"FragmentSpread\",\"name\":{\"kind\":\"Name\",\"value\":\"PropertyInputPropertyDefinitionFields\"}}]}}]}}]}", {
  kind: "Document",
  definitions: _unique([{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "PropertyGroupFragment"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "PropertyGroup"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "displayOrder"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "hubspotDefined"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "label"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "allPropertyDefinitions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "PropertyInputPropertyDefinitionFields"
            }
          }]
        }
      }]
    }
  }].concat(PropertyInputPropertyDefinitionFields.definitions))
});