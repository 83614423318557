'use es6';

import { Map as ImmutableMap } from 'immutable';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { RESET_EMAIL_DETAILS } from 'EmailData/actions/actionTypes';
import { FETCH_ALL_CTA, FETCH_CTA, FETCH_CTA_REQUEST, FETCH_INTERACTIVE_REQUEST, FETCH_INTERACTIVES } from 'email-management-lib/refactor/actions/actionTypes';
import CtaData from 'email-management-lib/refactor/models/CtaData';
import InteractiveData from 'email-management-lib/refactor/models/InteractiveData';
const initialState = ImmutableMap({
  legacy: ImmutableMap({}),
  interactive: ImmutableMap({})
});
function ctas(state = initialState, action) {
  switch (action.type) {
    case FETCH_CTA:
      if (action.error) {
        return state;
      }
      return state.setIn(['legacy', action.payload.guid], CtaData.from(action.payload));
    case FETCH_INTERACTIVES:
      if (action.error) {
        return state;
      }
      return state.mergeIn(['interactive'], ImmutableMap(Object.keys(action.payload).reduce((all, id) => Object.assign({}, all, {
        [id]: InteractiveData.from(action.payload[id])
      }), {})));
    case RESET_EMAIL_DETAILS:
      return initialState;
    default:
      return state;
  }
}
export default asyncResource(ctas, {
  requestActions: [FETCH_CTA_REQUEST, FETCH_INTERACTIVE_REQUEST],
  responseActions: [FETCH_ALL_CTA, FETCH_INTERACTIVES],
  invalidatingActions: [RESET_EMAIL_DETAILS],
  customActions: [FETCH_CTA]
});