'use es6';

import { SAVE_CURRENT_DASHBOARD_URL_STATE } from 'email-management-lib/refactor/actions/actionTypes';
import { Map as ImmutableMap } from 'immutable';
const INITIAL_STATE = ImmutableMap({
  pathParams: ImmutableMap(),
  queryParams: ImmutableMap()
});
export default function previousDashboardUrlState(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SAVE_CURRENT_DASHBOARD_URL_STATE:
      return ImmutableMap({
        pathParams: action.payload.pathParams,
        queryParams: action.payload.queryParams
      });
    default:
      return state;
  }
}