import { useMemo, useState, useEffect } from 'react';
import { getHasCompletedCampaignsOnboardingTourFromMOUISuperStore } from '../api/superstore';
export default function useShouldDisplayTourLinkOnCampaignSelect() {
  const [hasCompletedCampaignsOnboardingTour, setHasCompletedCampaignsOnboardingTour] = useState(false);
  const [loadingHasCompletedCampaignsOnboardingTour, setLoadingHasCompletedCampaignsOnboardingTour] = useState(false);
  useEffect(() => {
    setLoadingHasCompletedCampaignsOnboardingTour(true);
    getHasCompletedCampaignsOnboardingTourFromMOUISuperStore().then(value => {
      setHasCompletedCampaignsOnboardingTour(value);
    }).catch().finally(() => {
      setLoadingHasCompletedCampaignsOnboardingTour(false);
    });
  }, []);
  return useMemo(() => !hasCompletedCampaignsOnboardingTour && !loadingHasCompletedCampaignsOnboardingTour, [hasCompletedCampaignsOnboardingTour, loadingHasCompletedCampaignsOnboardingTour]);
}