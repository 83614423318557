import { GET_PINNED_VIEWS, GET_PINNED_VIEWS_FIELD_NAME, GET_VIEWS, GET_VIEWS_FIELD_NAME } from '../hooks/useFetchViews';
export function updateViewsCache({
  cache,
  variables,
  updater
}) {
  /**
   * Update current cache with new views
   */
  const cachedData = cache.readQuery({
    query: GET_VIEWS,
    variables
  });
  if (!cachedData) {
    return;
  }
  cache.writeQuery({
    query: GET_VIEWS,
    data: {
      [GET_VIEWS_FIELD_NAME]: updater(cachedData.getViews)
    },
    variables
  });
}
export function updatePinnedViewsCache({
  cache,
  variables,
  updater
}) {
  /**
   * Update current cache with new views
   */
  const cachedData = cache.readQuery({
    query: GET_PINNED_VIEWS,
    variables
  });
  if (!cachedData) {
    return;
  }
  cache.writeQuery({
    query: GET_PINNED_VIEWS,
    data: {
      [GET_PINNED_VIEWS_FIELD_NAME]: updater(cachedData.getPinnedViews)
    },
    variables
  });
}