import { useCallback, useEffect, useState } from 'react';
import listingLibObserver from 'framework-listing-lib/utils/listingLibObserver';
import { CLOSE_EDIT_COLUMNS_MODAL, OPEN_EDIT_COLUMNS_MODAL } from 'framework-listing-lib/constants/tableEvents';
export default function useRenderEditColumnsModal() {
  const [state, setState] = useState({
    modalProps: undefined,
    show: false
  });
  const handleCloseEditColumnsModal = useCallback(() => {
    setState(() => ({
      modalProps: undefined,
      show: false
    }));
  }, []);
  const handleOpenEditColumnsModal = useCallback(modalProps => {
    setState(() => ({
      modalProps,
      show: true
    }));
  }, []);
  useEffect(() => {
    listingLibObserver.on(CLOSE_EDIT_COLUMNS_MODAL, handleCloseEditColumnsModal);
    listingLibObserver.on(OPEN_EDIT_COLUMNS_MODAL, handleOpenEditColumnsModal);
    return () => {
      listingLibObserver.off(CLOSE_EDIT_COLUMNS_MODAL, handleCloseEditColumnsModal);
      listingLibObserver.off(OPEN_EDIT_COLUMNS_MODAL, handleOpenEditColumnsModal);
    };
  }, [handleCloseEditColumnsModal, handleOpenEditColumnsModal]);
  return state;
}