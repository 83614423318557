import http from 'hub-http/clients/apiClient';
const ENDPOINT_URL = '/upgrade-catalog-hublets/v1/purchase-orders';
const quantityUpgradesApi = {
  post(recurringProductConfigurations) {
    return http.post(ENDPOINT_URL, {
      data: {
        recurringProductConfigurations
      }
    }).then(purchaseOrder => purchaseOrder.id);
  }
};
export default quantityUpgradesApi;