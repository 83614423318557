'use es6';

import { SELECT_FOLDER_ROW, SELECT_STATE, SET_COLUMNS, SET_TABLE_OFFSET, SORT_TABLE_BY_COLUMN, UPDATE_SEARCH_TERM, RECEIVE_EMAILS, SELECT_EMAIL_TYPE, SELECT_BUSINESS_UNIT } from 'email-management-lib/refactor/actions/actionTypes';
const initialState = 0;
export default function offset(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_EMAILS:
      return action.error === true ? initialState : action.payload.offset;
    case SET_TABLE_OFFSET:
      return action.payload.offset;
    case SELECT_STATE:
    case SELECT_FOLDER_ROW:
    case SELECT_EMAIL_TYPE:
    case SELECT_BUSINESS_UNIT:
    case UPDATE_SEARCH_TERM:
    case SORT_TABLE_BY_COLUMN:
    case SET_COLUMNS:
      return initialState;
    default:
      return state;
  }
}