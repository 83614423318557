import { Map as ImmutableMap } from 'immutable';
// @ts-expect-error not yet migrated
import StatisticsData from 'EmailData/models/statistics/StatisticsData';
import { FETCH_EMAIL_STATISTICS_WITH_BOT_OPEN_REQUEST, FETCH_EMAIL_STATISTICS_WITH_BOT_OPEN } from 'email-management-lib/refactor/actions/actionTypes';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { analyticStatisticsMapper } from 'email-management-lib/refactor/utils/applePrivacyUtils';
const initialState = ImmutableMap({
  totalStatistics: StatisticsData.from({})
});
function emailCampaignStatistics(state = initialState, action) {
  switch (action.type) {
    case FETCH_EMAIL_STATISTICS_WITH_BOT_OPEN:
      {
        if (!action.error) {
          const mappedPayload = analyticStatisticsMapper(action.payload);
          const newTotalStats = state.get('totalStatistics').mergeDeep(StatisticsData.from(mappedPayload));
          return state.set('totalStatistics', newTotalStats);
        }
        return state;
      }
    default:
      return state;
  }
}
export default asyncResource(emailCampaignStatistics, {
  requestActions: [FETCH_EMAIL_STATISTICS_WITH_BOT_OPEN_REQUEST],
  responseActions: [FETCH_EMAIL_STATISTICS_WITH_BOT_OPEN],
  invalidatingActions: []
});