import omit from 'transmute/omit';
import { DEFAULT, FORECAST, STANDARD } from '../types/ViewTypes';

/**
 * Utility that will take a possibly stringified json value, and parse and return it as value of type T
 */
function parseAttribute(value, defaultValue) {
  if (value == null) {
    return defaultValue;
  }
  try {
    if (typeof value === 'string') {
      const val = JSON.parse(value) || defaultValue;
      return val;
    } else {
      return value;
    }
  } catch (e) {
    return defaultValue;
  }
}
const defaultViewState = {
  sortKey: '',
  sortColumnName: '',
  order: 1
};
const defaultViewColumns = [];
const defaultViewFilterGroups = [];
const defaultViewQuickFilters = [];
const defaultViewQuickFilterProperties = [];
const BACKEND_VIEW_FIELDS_TO_OMIT = ['collectionType', 'portalId', 'filterGroups', 'quickFilters', 'filters', 'idLabel'];
const getViewType = rawView => {
  //DEFAULT views do not have a `type` on their response, whereas custom views have type: 'STANDARD' on them
  if (!rawView.type || ![STANDARD, DEFAULT, FORECAST].includes(rawView.type)) {
    return DEFAULT;
  }
  return rawView.type;
};

/**
 * The backend stores columns, state, and filters as stringified json.
 * This function will take a view from the backend and parse out all fields neccessary
 */
export const transformBackendViewToInternalFormat = rawView => {
  const baseView = omit(BACKEND_VIEW_FIELDS_TO_OMIT, rawView);
  //sometimes view ids come back as numbers - we want to normalize to strings to prevent 1234 === '1234' mismatches
  const parsedViewId = String(rawView.idLabel || rawView.id);
  const newColumns = parseAttribute(rawView.columns, defaultViewColumns);
  const parsedFilterGroups = parseAttribute(rawView.filterGroups, defaultViewFilterGroups);
  const newState = parseAttribute(rawView.state, defaultViewState);
  const newQuickFilters = parseAttribute(rawView.quickFilters, defaultViewQuickFilters);
  const newQuickFiltersProperties = parseAttribute(rawView.quickFilterProperties, defaultViewQuickFilterProperties);
  const type = getViewType(rawView);
  return Object.assign({}, baseView, {
    type,
    id: parsedViewId,
    columns: newColumns,
    filterGroups: parsedFilterGroups,
    quickFilters: newQuickFilters,
    quickFilterProperties: newQuickFiltersProperties,
    state: newState
  });
};
const propertiesToStringifyIfTheyExist = ['state', 'columns', 'filters', 'filterGroups', 'quickFilters', 'quickFilterProperties'];
export const convertInternalViewToBEFormat = (view, propertiesToOmitEntirely = []) => {
  const viewProperties = Object.keys(view);
  const newView = viewProperties.reduce((accumulator, currentValue) => {
    if (propertiesToOmitEntirely.includes(currentValue)) {
      return accumulator;
    } else if (propertiesToStringifyIfTheyExist.includes(currentValue)) {
      const currentval = view[currentValue];
      const stringifiedValue = JSON.stringify(currentval);
      return Object.assign({}, accumulator, {
        [currentValue]: stringifiedValue
      });
    }
    return Object.assign({}, accumulator, {
      [currentValue]: view[currentValue]
    });
  }, {});
  return newView;
};