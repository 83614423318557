import { gql } from '@apollo/client';

// Fragments for key fields outlined in cacheKeyFields, please don't add
// or modify without also making the same changes in cacheKeyFields

// Clients using hubspot-apollo-client must include these fragments any
// time they fetch one of the following types, otherwise queries will
// fail with 'missing key fields' errors.

export const AssociationDefinitionKeyFields = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"FragmentDefinition\",\"name\":{\"kind\":\"Name\",\"value\":\"AssociationDefinitionKeyFields\"},\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"AssociationDefinition\"}},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"associationTypeId\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"associationCategory\"}}]}}]}", {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "AssociationDefinitionKeyFields"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "AssociationDefinition"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "associationTypeId"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "associationCategory"
        }
      }]
    }
  }]
});
export const CrmRecordCardKeyFields = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"FragmentDefinition\",\"name\":{\"kind\":\"Name\",\"value\":\"CrmRecordCardKeyFields\"},\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"CrmRecordCard\"}},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"cardId\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"crmObject\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"__typename\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"}}]}}]}}]}", {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "CrmRecordCardKeyFields"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "CrmRecordCard"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "cardId"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "crmObject"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "__typename"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }]
        }
      }]
    }
  }]
});
export const ObjectRequirementKeyFields = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"FragmentDefinition\",\"name\":{\"kind\":\"Name\",\"value\":\"ObjectRequirementKeyFields\"},\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"ObjectRequirement\"}},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"requirementType\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"requirementIdentifier\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"}}]}}]}", {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ObjectRequirementKeyFields"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ObjectRequirement"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "requirementType"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "requirementIdentifier"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }]
    }
  }]
});
export const PropertyDefinitionKeyFields = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"FragmentDefinition\",\"name\":{\"kind\":\"Name\",\"value\":\"PropertyDefinitionKeyFields\"},\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"PropertyDefinition\"}},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"name\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"objectTypeId\"}}]}}]}", {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "PropertyDefinitionKeyFields"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "PropertyDefinition"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "objectTypeId"
        }
      }]
    }
  }]
});
export const PropertyGroupKeyFields = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"FragmentDefinition\",\"name\":{\"kind\":\"Name\",\"value\":\"PropertyGroupKeyFields\"},\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"PropertyGroup\"}},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"name\"}}]}}]}", {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "PropertyGroupKeyFields"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "PropertyGroup"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "name"
        }
      }]
    }
  }]
});
export const PipelineKeyFields = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"FragmentDefinition\",\"name\":{\"kind\":\"Name\",\"value\":\"PipelineKeyFields\"},\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"Pipeline\"}},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"pipelineId\"}}]}}]}", {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "PipelineKeyFields"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "Pipeline"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "pipelineId"
        }
      }]
    }
  }]
});
export const PipelineStageKeyFields = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"FragmentDefinition\",\"name\":{\"kind\":\"Name\",\"value\":\"PipelineStageKeyFields\"},\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"PipelineStage\"}},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"stageId\"}}]}}]}", {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "PipelineStageKeyFields"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "PipelineStage"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "stageId"
        }
      }]
    }
  }]
});