'use es6';

import { RECEIVE_EMAILS } from 'email-management-lib/refactor/actions/actionTypes';
const initialState = 15;
export default function limit(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_EMAILS:
      return action.error === true ? initialState : action.payload.limit;
    default:
      return state;
  }
}