import { BATTLESHIP, CALYPSO_MEDIUM } from 'HubStyleTokens/colors';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'hubspot.bender' or its corresp... Remove this comment to see the full error message
import { depVersions } from 'hubspot.bender';
export const LOCAL_STORAGE_BASE_KEY = 'ContentEmailUI';
export const USER_SETTINGS_CACHE_KEY = uid => `GameraSettings:${uid}`;
export const SETTINGS_DURATION_MILLIS = 518400000; // 6 Days

export const PORTAL_SETTINGS_CACHE_KEY = 'PortalSettings';
export const COLORS = {
  SKY_BLUE: '#81C1FD',
  SKY_BLUE_LIGHT: '#B4DAFE',
  PINK: '#EA90B1',
  PINK_LIGHT: '#F2BACF',
  PURPLE: '#BDA9EA',
  LIME_GREEN: '#A2D28F',
  TURQUOISE_BLUE: '#51D3D9',
  TURQUOISE_BLUE_LIGHT: '#79DCE2',
  GRAY: BATTLESHIP,
  GRAY_LIGHT: '#E9F0F6',
  CORN_YELLOW: '#F5C78E',
  CALYPSO_MEDIUM,
  PEACH: '#F3A490',
  TANGERINE_ORANGE: '#FEA58E',
  ICON_BLUE: '#84A1BB',
  WHITE: '#FFFFFF',
  EERIE: '#7c98b6'
};
export const METRIC_COLORS = {
  CONTACT_LOSS: COLORS.PEACH,
  DELIVERED: COLORS.CALYPSO_MEDIUM,
  PENDING: COLORS.GRAY,
  SENT: COLORS.CORN_YELLOW,
  OVERVIEW: COLORS.TANGERINE_ORANGE,
  DESKTOP: COLORS.PEACH,
  MOBILE: CALYPSO_MEDIUM
};
const FULL_CARD_WIDTH = 911;
export const PANEL_WIDTH = 512;
export const CHART_DIMENSIONS = {
  FULL_WIDTH_CHART: {
    height: 500,
    width: FULL_CARD_WIDTH
  },
  PERFORMANCE_CHARTS: {
    height: 500,
    width: FULL_CARD_WIDTH
  },
  DELIVERED_DONUT_CHART: {
    height: 400,
    width: 313
  },
  DELIVERED_BAR_CHART: {
    height: 500,
    width: 550
  },
  CONTACT_LOSS_CHART: {
    height: 434,
    width: FULL_CARD_WIDTH
  }
};
export const ANALYZE_ERRORS = {
  DATE_LIMIT_ERR: 'ROLLUP_REQUEST_LIMIT_EXCEEDED'
};
export const CLICK_MAP_VERSION = depVersions ? depVersions.ClickMapJS : 'static';