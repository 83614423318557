import { useHomeCurrency } from './useHomeCurrency';
import { usePropertyDefinition } from './usePropertyDefinition';
import { useObjectTypeId } from './useObjectTypeId';
import { usePropertyValue } from './usePropertyValue';
import { useAdditionalPropertyValues } from './useAdditionalPropertyValues';
import { parseNumericValue } from '../../utils/parseNumericValue';
import isFractionalCurrencySupported from '../../v2/utils/isFractionalCurrencySupported';
import isFullPrecisionCurrencyRequired from '../utils/isFullPrecisionCurrencyRequired';
import { isProductUnitCost, isProductUnitPrice } from 'customer-data-objects/property/PropertyIdentifier';
const defaultFractionalCurrencyProps = {
  precision: 6,
  stripInsignificantZeros: true
};
const getMinimum = (propertyDefinition, objectTypeId, min) => {
  if (min !== undefined) {
    return min;
  }
  if (isProductUnitPrice(propertyDefinition, objectTypeId) || isProductUnitCost(propertyDefinition, objectTypeId)) {
    return 0;
  }
  return undefined;
};
export const useFormattedCurrencyDisplay = ({
  defaultValue,
  defaultCurrencyCode,
  defaultMin,
  shouldSimplifyCurrency
} = {}) => {
  const objectTypeId = useObjectTypeId();
  const propertyDefinition = usePropertyDefinition();
  const propertyValue = usePropertyValue();
  const {
    data: additionalValues
  } = useAdditionalPropertyValues({
    subjectCurrencyCode: propertyDefinition.currencyPropertyName
  }, {
    skip: !propertyDefinition.currencyPropertyName
  });
  const {
    data: homeCurrencyCode
  } = useHomeCurrency();
  const subjectCurrencyCode = additionalValues === null || additionalValues === void 0 ? void 0 : additionalValues.subjectCurrencyCode;
  const currencyValue = parseNumericValue(defaultValue !== null && defaultValue !== void 0 ? defaultValue : propertyValue);
  const currencyCodeValue = defaultCurrencyCode || subjectCurrencyCode || homeCurrencyCode;
  const minValue = getMinimum(propertyDefinition, objectTypeId, defaultMin);
  const shouldSimplifyCurrencyValue = shouldSimplifyCurrency !== undefined ? shouldSimplifyCurrency : !isFullPrecisionCurrencyRequired(objectTypeId);
  const fractionalCurrencyProps = isFractionalCurrencySupported(objectTypeId) ? defaultFractionalCurrencyProps : undefined;
  return {
    currencyValue,
    currencyCode: currencyCodeValue,
    formattingOptions: {
      shouldSimplifyCurrency: shouldSimplifyCurrencyValue,
      fractionalCurrencyProps,
      min: minValue
    }
  };
};