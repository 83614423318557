'use es6';

import { Map as ImmutableMap } from 'immutable';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { CREATE_FOLDER, DELETE_FOLDER, MOVE_FOLDER, RECEIVE_FOLDERS, RENAME_FOLDER, REQUEST_FOLDERS } from 'email-management-lib/refactor/actions/actionTypes';
const initialState = ImmutableMap();
function idsToNumbers(pathParts) {
  return pathParts.map(part => {
    try {
      const parsed = parseInt(part, 10);
      return isNaN(parsed) ? part : parsed;
    } catch (e) {
      return part;
    }
  });
}
function getPath(allFolders, id) {
  let res = '';
  allFolders.forEach(f => {
    if (res.length === 0) {
      res = f.getPath(id);
    }
  });
  return idsToNumbers(res.split('/'));
}
function folders(state = initialState, action) {
  let paths = [];
  let tempState;
  let tempFolder;
  switch (action.type) {
    case RECEIVE_FOLDERS:
      if (action.error !== true) {
        return state.merge(action.payload.folders).sortBy(folder => folder.get('name') ? folder.get('name').toLocaleLowerCase() : 0);
      }
      return state;
    case CREATE_FOLDER:
      if (action.error !== true) {
        if (action.payload.folder.parentFolderId) {
          paths = getPath(state, action.payload.folder.parentFolderId);
          return state.setIn(paths.concat(['childFolders', action.payload.folder.id]), action.payload.folder);
        }
        return state.set(action.payload.folder.id, action.payload.folder);
      }
      return state;
    case DELETE_FOLDER:
      if (action.error !== true) {
        paths = getPath(state, action.payload.folderId);
        if (paths.length > 1) {
          return state.deleteIn(paths);
        }
        return state.delete(action.payload.folderId);
      }
      return state;
    case RENAME_FOLDER:
      if (action.error !== true) {
        paths = getPath(state, action.payload.folderId);
        return state.setIn(paths.concat(['name']), action.payload.name);
      }
      return state;
    case MOVE_FOLDER:
      if (action.error !== true) {
        paths = getPath(state, action.payload.folderId);
        if (paths.length === 1) {
          tempFolder = state.get(action.payload.folderId);
          tempState = state.delete(action.payload.folderId);
        } else {
          tempFolder = state.getIn(paths);
          tempState = state.deleteIn(paths);
        }
        if (action.payload.targetId === 0) {
          return tempState.set(action.payload.folderId, tempFolder);
        }
        paths = getPath(tempState, action.payload.targetId);
        return tempState.setIn(paths.concat(['childFolders', action.payload.folderId]), tempFolder);
      }
      return state;
    default:
      return state;
  }
}
export default asyncResource(folders, {
  requestActions: [REQUEST_FOLDERS],
  responseActions: [RECEIVE_FOLDERS, CREATE_FOLDER, DELETE_FOLDER, RENAME_FOLDER, MOVE_FOLDER],
  invalidatingActions: []
});