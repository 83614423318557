import { Component } from 'react';
import Raven from 'raven-js';
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null
    };
  }
  componentDidCatch(error, errorInfo) {
    this.setState({
      errorInfo,
      error
    });
    Raven.captureException(new Error('ui-tool-access error'), {
      level: 'error',
      extra: {
        error
      }
    });
  }
  render() {
    if (this.state.errorInfo) {
      // Error path
      return this.props.fallbackContent || null;
    }
    return this.props.children;
  }
}
export default ErrorBoundary;