import PortalIdParser from 'PortalIdParser';
const getBaseUrl = () => '/manage-views';
export const getRootUrl = () => `${getBaseUrl()}/${PortalIdParser.get()}`;

/**
 * @description A utility that determines if the url matches the same origin/hostname of the browsers current location. Preserves hash and query params.
 *
 * This can be helpful if you are trying to forbid cross-origin redirection, which is a security vulnerability.
 *
 * @param link - The link to be checked
 * @return the link
 * @example
 * ```ts
 * isRelativeUrl('//bing.com/contact') // false
 * isRelativeUrl('https://google.com/path#frag?q=123') // false
 * isRelativeUrl('/contact/12345/list/all') // true
 * ```
 */
export function isRelativeUrl(link) {
  const {
    origin
  } = new URL(link, window.location.origin);
  return origin === window.location.origin;
}