import { applyMiddleware, compose, createStore } from 'redux';
import errorTokenMiddleware from './errorTokenMiddleware';
import rootReducer from 'email-management-lib/refactor/reducers/app/index';
import sentryErrorMiddleware from 'email-management-lib/refactor/utils/redux/sentryErrorMiddleware';
import thunk from './thunk';
import { Map as ImmutableMap } from 'immutable';
function applyDevToolsMiddleware() {
  return window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f;
}
const createStoreWithMiddleware = compose(applyMiddleware(thunk, errorTokenMiddleware, sentryErrorMiddleware), applyDevToolsMiddleware())(createStore);
export default function configureStore(initialState = ImmutableMap({})) {
  return createStoreWithMiddleware(rootReducer, initialState);
}