'use es6';

import { createSelector } from 'reselect';
import { BLOG_EMAIL_TYPES } from 'EmailData/utils/constants';
import difference from 'hs-lodash/difference';
const _getBlogEmails = state => typeof state.get === 'function' ? state.get('blogEmails') : state.blogEmails;
export const getBlogEmails = createSelector(_getBlogEmails, blogEmails => blogEmails.getData());
export const getBlogEmailsLoading = createSelector(_getBlogEmails, blogEmails => blogEmails.isLoading());
export const getBlogEmailsErrored = createSelector(_getBlogEmails, blogEmails => blogEmails.isErrored());
const getCurrentBlogEmailTypes = createSelector(getBlogEmails, blogEmails => blogEmails.reduce((acc, email) => {
  if (email.id) {
    acc.push(email.blogEmailType);
  }
  return acc;
}, []));
export const getAvailableBlogEmailTypes = createSelector(getCurrentBlogEmailTypes, currentTypes => difference(BLOG_EMAIL_TYPES, currentTypes));