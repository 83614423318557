import http from 'hub-http/clients/apiClient';
export function fetchTeams({
  canViewAllTeams,
  canViewOwnedTeams
}) {
  if (canViewAllTeams) {
    return http.get(`app-users/v1/teams/hierarchy`);
  }
  if (canViewOwnedTeams) {
    return http.get(`app-users/v1/teams/user/all`, {
      query: {
        includeChildTeamMembers: true,
        includeHierarchy: true
      }
    });
  }
  return Promise.resolve([]);
}