import { getStorageItem, setStorageItem } from './storageUtil';
const SELENIUM_COACHING_TIPS_CONFIG = 'seleniumCoachingTipsConfig';
const SELENIUM_COACHING_TIPS_FLAG = 'seleniumCoachingTipsFlag';
export const setConfigForSelenium = config => {
  if (getStorageItem(SELENIUM_COACHING_TIPS_FLAG) === 'true') {
    setStorageItem(SELENIUM_COACHING_TIPS_CONFIG, JSON.stringify({
      id: config.id,
      tips: (config.tips || []).map(({
        tipId
      }) => ({
        tipId
      }))
    }));
  }
};