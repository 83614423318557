import { getFrameworkDataSchemasClient } from 'framework-data-schema-resolvers';
export const getPropertyDefinitions = ({
  objectTypeId,
  httpClient
}) => getFrameworkDataSchemasClient({
  httpClient
}).then(client => client.properties.get({
  frameworkTypeIdentifier: objectTypeId,
  query: {
    showHighlySensitiveProperties: true
  }
}));