/**
 * Direct copy-paste of marketing-platform-lib/utils/filters
 * to prevent unnecessary bundle size increase on earlyRequester file
 * Only copied the required operators for maximum performance
 */

/**
 * Finds objects where the chosen property value is equal to the input value.
 * @param {string} property
 * @param {string|number|boolean} value
 */
export function EQ(property, value) {
  return {
    operator: 'EQ',
    property,
    value
  };
}

/**
 * Finds objects where the chosen property value is not equal to the input value.
 * @param {string} property
 * @param {string|number|boolean} value
 */
export function NEQ(property, value) {
  return {
    operator: 'NEQ',
    property,
    value
  };
}

/**
 * Finds objects where the chosen property has a value.
 * @param {string} property
 */
export function HAS_PROPERTY(property) {
  return {
    operator: 'HAS_PROPERTY',
    property
  };
}

/**
 * Finds objects where the chosen property value does not exactly match any of the input values.
 * @param {string} property
 * @param {string[]|number[]|boolean[]} values
 */
export function NOT_IN(property, values) {
  return {
    operator: 'NOT_IN',
    property,
    values
  };
}

/**
 * Finds objects where the chosen property value is in any of the input values.
 * @param {string} property
 * @param {string[]|number[]|boolean[]} values
 */
export function IN(property, values) {
  return {
    operator: 'IN',
    property,
    values
  };
}