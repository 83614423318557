import useFetcher from 'framework-listing-lib/internal/hooks/useFetcher';
import { useListing } from 'framework-listing-lib/internal/providers/ListingProvider';

/**
 * Enables GraphQL usage crm-search as long as there is no custom fetcher defined.
 */
export default function useHasGraphQLCrmSearch() {
  const fetcher = useFetcher();
  const {
    _useNonGqlCRMSearch
  } = useListing();
  return Boolean(!fetcher && !_useNonGqlCRMSearch);
}