/* eslint-disable promise/catch-or-return */
import quickFetch from 'quick-fetch';
import http from 'hub-http/clients/apiClient';
export function getEarlyRequest(requestKey) {
  return quickFetch.getRequestStateByName(requestKey);
}
export function getEarlyEmailDetailsKey(emailId) {
  return `EmailDetails::${emailId}`;
}
export function readEarlyOrFetch(requestKey, requestOptions) {
  const earlyRequest = getEarlyRequest(requestKey);
  let resultPromise;
  if (earlyRequest) {
    resultPromise = new Promise((resolve, reject) => {
      earlyRequest.whenFinished(result => {
        resolve(result);
        quickFetch.removeEarlyRequest(requestKey);
      });
      earlyRequest.onError(() => {
        fetchViaHubHttp(requestKey, requestOptions).then(resolve, reject);
        quickFetch.removeEarlyRequest(requestKey);
      });
    });
  } else {
    // Send off the API Request via hub-http otherwise.
    resultPromise = fetchViaHubHttp(requestKey, requestOptions);
  }
  return resultPromise;
}
function fetchViaHubHttp(requestKey, requestOptions) {
  const emailId = requestOptions && requestOptions.emailId;
  const uri = getRequestUriByKey(requestKey, emailId);
  if (uri === getPerformanceStatsUri(emailId)) {
    requestOptions.timeout = 30000;
  }
  return http.get(uri, requestOptions);
}
function getRequestUriByKey(requestKey, id) {
  if (id && requestKey === getEarlyEmailPerformanceStatsKey(id)) {
    return getPerformanceStatsUri(id);
  } else if (id && requestKey === getEarlyEmailDetailsKey(id)) {
    return `cosemail/v1/details/${id}`;
  }
  return 'cosemail/v1/emails/listing';
}
export function getEarlyEmailPerformanceStatsKey(emailId) {
  return `PerformanceStats::${emailId}`;
}
function getPerformanceStatsUri(emailId) {
  return `cosemail/v1/details/${emailId}/minimal-performance`;
}
export function cleanEarlyRequests(requestKey) {
  const earlyRequest = getEarlyRequest(requestKey);
  if (earlyRequest) {
    quickFetch.removeEarlyRequest(requestKey);
  }
}