'use es6';

import ContentRoutes from 'ContentUtils/Routes';
import formatName from 'I18n/utils/formatName';
import text from 'I18n/utils/unescapedText';
import { Map as ImmutableMap, Record } from 'immutable';
const defaults = {
  id: null,
  vid: null,
  properties: ImmutableMap({})
};
class ContactRecord extends Record(defaults) {
  static from(json) {
    const vid = json.vid || json.objectId;
    return new ContactRecord(Object.assign({}, json, {
      id: vid,
      vid
    }));
  }
  getFirstName() {
    return this.getIn(['properties', 'firstname']);
  }
  getLastName() {
    return this.getIn(['properties', 'lastname']);
  }
  getEmail() {
    return this.getIn(['properties', 'email']);
  }
  getComputedName() {
    const properties = this.get('properties');
    const {
      firstname,
      lastname,
      email
    } = properties;
    let maybeEmail = text('EmailData.contacts.noEmailAvailable');
    if (email && email.value) {
      maybeEmail = email.value;
    }
    const firstnameValue = this.getPropertyValue(firstname, '');
    const lastnameValue = this.getPropertyValue(lastname, '');
    const formattedName = formatName({
      firstName: firstnameValue,
      lastName: lastnameValue
    });
    if (formattedName.length > 0) {
      return text('EmailData.contacts.computedNameAndEmail', {
        localizedName: formattedName,
        email: maybeEmail
      });
    }
    return text('EmailData.contacts.computedEmail', {
      email: maybeEmail
    });
  }
  getMinComputedName() {
    const {
      firstname,
      lastname
    } = this.get('properties');
    const firstnameValue = this.getPropertyValue(firstname, '');
    const lastnameValue = this.getPropertyValue(lastname, '');
    if (firstname) {
      return formatName({
        firstName: firstnameValue,
        lastName: lastnameValue
      });
    }
    return this.getEmail();
  }
  getUrl() {
    return ContentRoutes.contact(this.get('vid'));
  }
  getPropertyValue(property, defaultValue) {
    return property && property.value ? property.value : defaultValue;
  }
  getAsSelectOption() {
    return {
      value: this.get('vid'),
      text: this.getComputedName(),
      email: this.getEmail(),
      name: this.getMinComputedName()
    };
  }
}
export default ContactRecord;