import { RESET_EMAIL_DETAILS } from 'EmailData/actions/actionTypes';
import { CLOSE_PREVIEW, SELECT_FRAMEWORK_DETAILS_PANEL_TAB } from 'email-management-lib/refactor/actions/actionTypes';
import { TAB } from 'framework-asset-preview-lib/utils/tabs';
const initialState = TAB.DETAILS;
export default function selectedDetailsPanelTab(state = initialState, action) {
  switch (action.type) {
    case SELECT_FRAMEWORK_DETAILS_PANEL_TAB:
      return action.payload.tab;
    case CLOSE_PREVIEW:
    case RESET_EMAIL_DETAILS:
      return initialState;
    default:
      return state;
  }
}