/**
 * We can assume that property values we get from the back-end are in the
 * format of numbers that can be parsed by JS, i.e. they have no commas and
 * only one decimal (in regex terms /[\d+](\.[\d+])?/).
 *
 * Values not following this format can be ignored and treated as empty
 * (such values are either grandfathered in from before the public API change,
 * or have bypassed validation via some backdoor)
 *
 * Public API changes made to enforce this:
 * * https://developers.hubspot.com/changelog/upcoming-api-number-value-changes
 * * https://community.hubspot.com/t5/Developer-Announcements/Upcoming-Increased-validation-for-contact-updates-made-through/td-p/760355
 */
export const parseNumericValue = value =>
// @ts-expect-error TS type for 'Value' doesn't include 'number', but some JS callers still pass one (https://git.hubteam.com/HubSpot/PropertiesFrontendTeam/issues/613)
!value && value !== 0 || Number.isNaN(+value) ? null : +value;