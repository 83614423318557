import http from 'hub-http/clients/apiClient';
const MARKETING_ASSET_PERMISSION_USERS = 'marketing/asset-permissions/v1/users';
export function getUserPermissions({
  objectType,
  objectId
}) {
  return http.get(`${MARKETING_ASSET_PERMISSION_USERS}/${objectType}/${objectId}`);
}
export function saveUserPermissions({
  objectType,
  objectIds,
  userIds
}) {
  // Unfortunately we don't have a vectorized API for user permissions
  return Promise.allSettled(objectIds.map(objectId => http.put(`${MARKETING_ASSET_PERMISSION_USERS}/${objectType}/${objectId}`, {
    data: userIds
  }))).then(() => userIds);
}