import PortalIdParser from 'PortalIdParser';
import KNOWLEDGE_ARTICLE_ROUTES from 'EmailData/constants/knowledgeRoutes';
import PORTAL_HEALTH_STATES from 'EmailData/constants/portalHealthStates';
export const KNOWLEDGE_ARTICLE_SECTION = Object.freeze({
  FREE_CRM_ACCOUNTS: '#free-crm-accounts',
  FRAUDULENT: '#fraudulent',
  HUBS: '#marketing-hub-starter-accounts-sales-hub-starter-professional-and-enterprise-accounts-service-hub-starter-professional-and-enterprise-accounts-and-free-trial-accounts',
  HUBS_LEGACY: '#marketing-hub-basic-legacy-professional-or-enterprise-accounts'
});
export const SUSPENSION_APPEAL_STATUS = {
  UNINITIATED: 'UNINITIATED',
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  ERROR: 'ERROR'
};
export const SUSPENSION_REASON_TYPES = {
  ABUSE_COMPLAINT: 'ABUSE_COMPLAINT',
  BLACKLISTING: 'BLACKLISTING',
  BOUNCES: 'BOUNCES',
  CANCELLATIONS: 'CANCELLATIONS',
  DEFAULT: 'DEFAULT',
  EHAWK: 'EHAWK',
  HEALTH_SCORE: 'HEALTH_SCORE',
  NONPAYMENT: 'NONPAYMENT',
  OVERRIDE: 'OVERRIDE',
  OVERRIDE_PHISHING: 'OVERRIDE_PHISHING',
  OVERRIDE_SPAM: 'OVERRIDE_SPAM',
  OVERRIDE_UNSUBSCRIBE_REASONS: 'OVERRIDE_UNSUBSCRIBE_REASONS',
  OVERRIDE_UNSUBSCRIBES: 'OVERRIDE_UNSUBSCRIBES',
  PHISHING: 'PHISHING',
  SPAM: 'SPAM',
  SPAMTRAP: 'SPAMTRAP',
  UNSUBSCRIBES: 'UNSUBSCRIBES',
  DOMAIN_MISMATCH: 'DOMAIN_MISMATCH',
  HIGH_CTU_TOUCHLESS: 'HIGH_CTU_TOUCHLESS',
  COMPLIANCE_TAKEOVER: 'COMPLIANCE_TAKEOVER',
  DEFAULT_FORM: 'DEFAULT_FORM'
};
export const APPEAL_REASON_TYPES = {
  BOUNCES: 'BOUNCES',
  CANCELLATIONS: 'CANCELLATIONS',
  SPAM: 'SPAM',
  UNSUBSCRIBES: 'UNSUBSCRIBES',
  OVERRIDE_UNSUBSCRIBES: 'OVERRIDE_UNSUBSCRIBES',
  OVERRIDE_UNSUBSCRIBE_REASONS: 'OVERRIDE_UNSUBSCRIBE_REASONS'
};
export const PERFORMANCE_SUSPENSION_REASONS = [SUSPENSION_REASON_TYPES.CANCELLATIONS, SUSPENSION_REASON_TYPES.BOUNCES, SUSPENSION_REASON_TYPES.UNSUBSCRIBES, SUSPENSION_REASON_TYPES.SPAM, SUSPENSION_REASON_TYPES.OVERRIDE_SPAM, SUSPENSION_REASON_TYPES.OVERRIDE_UNSUBSCRIBES];
export function getSuspendedState() {
  return {
    portalId: PortalIdParser.get(),
    overallState: PORTAL_HEALTH_STATES.SUSPENDED
  };
}
export function getAbuseAppealReasons() {
  return [SUSPENSION_REASON_TYPES.EHAWK, SUSPENSION_REASON_TYPES.DOMAIN_MISMATCH, SUSPENSION_REASON_TYPES.HIGH_CTU_TOUCHLESS];
}
export function isReasonAbuseAppealRelated(reason) {
  return getAbuseAppealReasons().indexOf(reason) > -1;
}
export function getSuspensionKbArticle(reason, hasAccessToSupport = false) {
  if (isReasonAbuseAppealRelated(reason)) {
    return `${KNOWLEDGE_ARTICLE_ROUTES.WHY_SUSPENSION}${KNOWLEDGE_ARTICLE_SECTION.FRAUDULENT}`;
  } else if (!hasAccessToSupport) {
    return `${KNOWLEDGE_ARTICLE_ROUTES.WHY_SUSPENSION}${KNOWLEDGE_ARTICLE_SECTION.FREE_CRM_ACCOUNTS}`;
  }
  switch (reason) {
    case SUSPENSION_REASON_TYPES.BOUNCES:
    case SUSPENSION_REASON_TYPES.UNSUBSCRIBES:
      return `${KNOWLEDGE_ARTICLE_ROUTES.WHY_SUSPENSION}${KNOWLEDGE_ARTICLE_SECTION.HUBS}`;
    case SUSPENSION_REASON_TYPES.OVERRIDE:
    case SUSPENSION_REASON_TYPES.OVERRIDE_SPAM:
    case SUSPENSION_REASON_TYPES.OVERRIDE_UNSUBSCRIBES:
    case SUSPENSION_REASON_TYPES.OVERRIDE_UNSUBSCRIBE_REASONS:
      return `${KNOWLEDGE_ARTICLE_ROUTES.WHY_SUSPENSION}${KNOWLEDGE_ARTICLE_SECTION.HUBS_LEGACY}`;
    default:
      return `${KNOWLEDGE_ARTICLE_ROUTES.WHY_SUSPENSION}`;
  }
}
export function isOverrideWithReason(reason) {
  return reason === SUSPENSION_REASON_TYPES.OVERRIDE_SPAM || reason === SUSPENSION_REASON_TYPES.OVERRIDE_UNSUBSCRIBE_REASONS || reason === SUSPENSION_REASON_TYPES.OVERRIDE_UNSUBSCRIBES;
}
export function isReasonSetManually(reason) {
  return reason === SUSPENSION_REASON_TYPES.OVERRIDE || reason === SUSPENSION_REASON_TYPES.OVERRIDE_SPAM || reason === SUSPENSION_REASON_TYPES.OVERRIDE_UNSUBSCRIBES || reason === SUSPENSION_REASON_TYPES.DEFAULT;
}
export function isReasonPhishingRelated(reason) {
  return reason === SUSPENSION_REASON_TYPES.PHISHING || reason === SUSPENSION_REASON_TYPES.OVERRIDE_PHISHING;
}