'use es6';

import { SORT_TABLE_BY_COLUMN } from 'email-management-lib/refactor/actions/actionTypes';
const initialState = 'DESC';
export default function sortDir(state = initialState, action, sameColumn = false) {
  switch (action.type) {
    case SORT_TABLE_BY_COLUMN:
      if (sameColumn) {
        return state === 'DESC' ? 'ASC' : 'DESC';
      }
      return initialState;
    default:
      return state;
  }
}