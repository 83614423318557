import { useCallback } from 'react';
import { useCrmObjectType } from 'framework-listing-lib/internal/providers/CrmObjectTypeListingClientProvider';

/**
 * This hook is meant to be used by host apps.
 * Exposing a getter and setter for expandable row objects
 */
export default function useExpandCollapseRow() {
  const {
    expandableObjectIds,
    onSetExpandableObjectIds
  } = useCrmObjectType();
  const collapseAll = useCallback(() => onSetExpandableObjectIds([]), [onSetExpandableObjectIds]);
  const expandRow = useCallback(objectId => {
    onSetExpandableObjectIds(prevValue => {
      if (prevValue.includes(objectId)) {
        return prevValue;
      }
      return [...prevValue, objectId];
    });
  }, [onSetExpandableObjectIds]);
  const collapseRow = useCallback(objectId => {
    onSetExpandableObjectIds(prevValue => prevValue.filter(id => id !== objectId));
  }, [onSetExpandableObjectIds]);
  return {
    collapseAll,
    collapseRow,
    expandableObjectIds,
    expandRow
  };
}