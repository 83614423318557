'use es6';

import { LOAD_FEEDBACK_CLIENT, LOAD_FEEDBACK_CONTACT, LOAD_FEEDBACK_SURVEY } from 'EmailData/actions/actionTypes';
import { getCsatSurvey, submitFeedbackRequest, submitFeedbackWithSentiment, submitSentiment } from 'EmailData/feedback/apiClients';
import { getIsSurveyVisible } from 'EmailData/feedback/selectors';

// Only applicable to FeedbackLoader onClientLoad
// Higher order constructor to be used by consumer apps
export const buildOnLoadFeedbackClientCallback = ({
  visibleSurveys,
  visibleSurveysEvalMap
}) => {
  return client => (dispatch, getState) => {
    client.onSurveyLoad = ({
      webRespondent
    }) => {
      dispatch({
        type: LOAD_FEEDBACK_CONTACT,
        contact: webRespondent.contactId
      });
    };
    const unchangedLoadSurvey = client.loadSurvey;
    client.loadSurvey = (type, surveyId) => {
      if (getIsSurveyVisible(getState(), {
        surveyId,
        visibleSurveys,
        visibleSurveysEvalMap
      })) {
        dispatch({
          type: LOAD_FEEDBACK_SURVEY
        });
        unchangedLoadSurvey(type, surveyId);
      }
    };
    dispatch({
      type: LOAD_FEEDBACK_CLIENT,
      client
    });
  };
};
export const fetchCsatSurvey = surveyId => getCsatSurvey(surveyId);
export const submitFeedback = (contactId, sentiment, feedback, surveyId, submissionId) => {
  if (contactId && sentiment !== undefined && feedback && !submissionId) {
    return submitFeedbackWithSentiment(sentiment, feedback, surveyId, contactId);
  } else if (contactId && sentiment !== undefined && !submissionId && !feedback) {
    return submitSentiment(sentiment, surveyId, contactId);
  } else if (contactId && feedback && submissionId) {
    return submitFeedbackRequest(sentiment, feedback, surveyId, contactId, submissionId);
  }
  return Promise.resolve({});
};