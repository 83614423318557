import devLogger from 'react-utils/devLogger';
import { createSearchReferenceResolver } from 'reference-resolvers-lite/createSearchReferenceResolver';
import identity from 'transmute/identity';
import { MARKETING_REASON } from '../CustomResolverReferenceTypes';
import PortalIdParser from 'PortalIdParser';
const REASON_TYPE_AND_SOURCE_OBJECT_ID_REGEX = /^(.*?)\/(.*)$/;
export const isValidReasonId = id => REASON_TYPE_AND_SOURCE_OBJECT_ID_REGEX.test(id);
export const stringifyReasonId = ({
  reasonType,
  reasonSourceObjectId
}) => `${reasonType}/${reasonSourceObjectId}`;
export const parseReasonId = id => {
  const match = id.match(REASON_TYPE_AND_SOURCE_OBJECT_ID_REGEX);
  if (match) {
    const [__, reasonType, reasonSourceObjectId] = match;
    return {
      reasonType,
      reasonSourceObjectId
    };
  }
  return null;
};
const formatMarketingReasonByIdResponse = response => response.map(result => {
  return {
    id: stringifyReasonId(result),
    label: result.reportingLabel.label || '',
    description: result.reportingLabel.url === null ? undefined : result.reportingLabel.url
  };
});
export const createMarketingReasonReferenceResolver = ({
  httpClient
}) => {
  return createSearchReferenceResolver({
    fetchByIds: ids => {
      const reasonTypeAndSourceObjectIds = ids.map(parseReasonId);
      return httpClient.post('/marketable-contacts/v1/marketable-labels/reasons', {
        query: {
          portalId: PortalIdParser.get()
        },
        data: reasonTypeAndSourceObjectIds
      });
    },
    fetchBySearch: () => {
      devLogger.warn({
        message: 'Marketing Reason resolver does not support search fetching',
        key: 'marketing-reason-resolver'
      });
      return Promise.resolve({
        hasMore: false,
        offset: 0,
        references: []
      });
    },
    formatByIdResponse: formatMarketingReasonByIdResponse,
    formatSearchResponse: identity,
    referenceType: MARKETING_REASON,
    isIdValid: isValidReasonId
  });
};