/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */

'use es6';

import I18n from 'I18n';
import { workflowsSelector } from 'EmailData/selectors/details/current/currentWorkflows';
import currentEmailType from 'EmailData/selectors/details/current/currentEmailType';
import { FETCH_EMAIL_DETAILS_WORKFLOWS_REQUEST, FETCH_EMAIL_DETAILS_WORKFLOWS, FETCH_SIMPLE_WORKFLOWS_REQUEST, FETCH_SIMPLE_WORKFLOWS } from 'EmailData/actions/actionTypes';
import { fetchWorkflowsRequest, fetchEmailSimpleWorkflows } from 'EmailData/api/workflows';
import EmailType from 'EmailData/enums/EmailType';
import { trackPostSendInteraction } from 'EmailData/utils/tracking';
import { getCanLoadAutomationByEmail, getCanLoadAutomation } from 'EmailData/selectors/details/authSelectors';
import { simpleWorkflowsSelector } from 'EmailData/selectors/details/current/currentSimpleWorkflows';
export function fetchWorkflows(emailContentId) {
  return (dispatch, getState) => {
    const state = getState();
    const workflowsMap = workflowsSelector(state);
    const emailType = currentEmailType(state);
    if (workflowsMap.get(emailContentId)) {
      return;
    }
    dispatch({
      type: FETCH_EMAIL_DETAILS_WORKFLOWS_REQUEST
    });
    fetchWorkflowsRequest(emailContentId).then(response => {
      dispatch({
        type: FETCH_EMAIL_DETAILS_WORKFLOWS,
        payload: {
          emailContentId,
          workflows: response
        }
      });
      if (emailType === EmailType.AUTOMATED_AB_EMAIL && response.some(workflow => workflow.enabled)) {
        trackPostSendInteraction({
          emailType,
          context: 'has-published-workflow'
        });
      }
    }, error => {
      dispatch({
        type: FETCH_EMAIL_DETAILS_WORKFLOWS,
        error: true,
        payload: error,
        metadata: {
          growlError: {
            error,
            feature: I18n.text('EmailData.errors.workflows.feature'),
            messageKey: 'EmailData.errors.workflows.fetch'
          }
        }
      });
    });
  };
}
const fetchSimpleWorkflows = (emailContentId, canLoadAutomation) => (dispatch, getState) => {
  const state = getState();
  const simpleWorkflowsMap = simpleWorkflowsSelector(state);
  const emailType = currentEmailType(state);
  if (simpleWorkflowsMap.get(emailContentId) || !canLoadAutomation) {
    return;
  }
  dispatch({
    type: FETCH_SIMPLE_WORKFLOWS_REQUEST
  });
  fetchEmailSimpleWorkflows(emailContentId).then(response => {
    dispatch({
      type: FETCH_SIMPLE_WORKFLOWS,
      payload: {
        emailContentId,
        simpleWorkflows: response
      }
    });
  }, error => {
    dispatch({
      type: FETCH_SIMPLE_WORKFLOWS,
      error: true,
      payload: error,
      metadata: {
        growlError: {
          error: {
            message: error,
            emailType
          },
          feature: I18n.text('EmailData.errors.simpleWorkflows.feature'),
          messageKey: 'EmailData.errors.simpleWorkflows.fetch'
        }
      }
    });
  });
};
export const maybeFetchSimpleWorkflowsFromDetails = emailContentId => (dispatch, getState) => {
  const state = getState();
  const canLoadAutomation = getCanLoadAutomation(state);
  dispatch(fetchSimpleWorkflows(emailContentId, canLoadAutomation));
};
export const maybeFetchSimpleWorkflowsFromDashboard = email => (dispatch, getState) => {
  const state = getState();
  const canLoadAutomation = getCanLoadAutomationByEmail(email)(state);
  dispatch(fetchSimpleWorkflows(email.id, canLoadAutomation));
};