import { useMemo } from 'react';
import I18n from 'I18n';
import useFetchUserInfo from 'framework-listing-lib/internal/hooks/useFetchUserInfo';
import useFetchUserNames from 'framework-listing-lib/internal/hooks/useFetchUserNames';
import { useViews } from './useViewProps';
function getViewsByType(views, userId) {
  return views.reduce((acc, view) => {
    const isOwner = view.ownerId === userId;
    const isDefault = view.type === 'DEFAULT' || view.ownerId === -1;
    if (isOwner) {
      acc.myViews.push(view);
    } else if (isDefault) {
      acc.defaultViews.push(view);
    } else {
      acc.sharedViews.push(view);
    }
    return acc;
  }, {
    myViews: [],
    defaultViews: [],
    sharedViews: []
  });
}
function mapViewsToOptions(views, userNames) {
  return views.map(view => {
    const options = {
      text: view.name,
      value: view.id
    };
    const userName = view.ownerId && userNames && userNames[view.ownerId] ? userNames[view.ownerId] : undefined;
    const isCreatedByHubSpot = !userNames && !view.ownerId;
    if (userName) {
      return Object.assign({}, options, {
        help: `${I18n.text('frameworkListingLib.viewTabs.addViewTab.sharedViewsHelpMessage')} ${userName}`
      });
    } else if (isCreatedByHubSpot) {
      return Object.assign({}, options, {
        help: I18n.text('frameworkListingLib.viewTabs.addViewTab.defaultViewsHelpMessage')
      });
    } else {
      return options;
    }
  });
}
function getViewOptions({
  defaultViews,
  myViews,
  sharedViews
}, userNames) {
  const defaultViewsOptions = mapViewsToOptions(defaultViews);
  const myViewsOptions = mapViewsToOptions(myViews);
  const sharedViewsOptions = mapViewsToOptions(sharedViews, userNames);
  return [{
    text: `${I18n.text('frameworkListingLib.viewTabs.addViewTab.defaultViewsHeader')} (${defaultViewsOptions.length})`,
    value: 'defaultViews',
    options: defaultViewsOptions
  }, {
    text: `${I18n.text('frameworkListingLib.viewTabs.addViewTab.myViewsHeader')} (${myViewsOptions.length})`,
    value: 'myViews',
    options: myViewsOptions
  }, {
    text: `${I18n.text('frameworkListingLib.viewTabs.addViewTab.sharedViewsHeader')} (${sharedViewsOptions.length})`,
    value: 'sharedViews',
    options: sharedViewsOptions
  }];
}
export default function useViewsAsOptions(allOpenViews) {
  const {
    data: userInfo
  } = useFetchUserInfo();
  const views = useViews();
  const userId = useMemo(() => userInfo && userInfo.user ? userInfo.user.user_id : 0, [userInfo]);

  /** Omit pinned & open views from the dropdown */
  const availableViews = views.filter(view => !allOpenViews.some(pinnedView => String(pinnedView.id) === String(view.id)));
  const mappedViews = getViewsByType(availableViews, userId);
  const userIds = useMemo(() => mappedViews.sharedViews.map(view => view.ownerId).filter(Boolean), [mappedViews]);
  const {
    data: userNames
  } = useFetchUserNames(userIds);
  return useMemo(() => getViewOptions(mappedViews, userNames), [mappedViews, userNames]);
}