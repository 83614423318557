import http from 'hub-http/clients/apiClient';
import { registerQuery, useQuery } from 'data-fetching-client';
import { transformBackendViewToInternalFormat } from '../../viewsSearch/dataOperators/backendViews';
export const SINGLE_VIEW_BY_ID_QUERY_NAME = 'singleViewById';
const viewsSearchQueryArgsArgument = ['id', 'objectTypeId', 'namespaceName'];
export const SingleViewByIdQuery = registerQuery({
  fieldName: SINGLE_VIEW_BY_ID_QUERY_NAME,
  args: viewsSearchQueryArgsArgument,
  fetcher: args => {
    return http.get(`/sales/v4/views/${args.objectTypeId}/${args.id}?namespace=${args.namespaceName}`, {
      data: args
    }).then(transformBackendViewToInternalFormat);
  }
});
export const useFetchSingleView = query => {
  return useQuery(SingleViewByIdQuery, {
    variables: query
  });
};