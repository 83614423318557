import { CMS_HUB_PROFESSIONAL, MARKETING_HUB_PROFESSIONAL, OPERATIONS_HUB_PROFESSIONAL, SALES_HUB_PROFESSIONAL, SERVICE_HUB_PROFESSIONAL } from 'self-service-api/constants/ApiNames';
import { FeatureSurveyKeys } from './FeatureSurveyKeys';
export const ProApiNames = [MARKETING_HUB_PROFESSIONAL, CMS_HUB_PROFESSIONAL, SALES_HUB_PROFESSIONAL, SERVICE_HUB_PROFESSIONAL, OPERATIONS_HUB_PROFESSIONAL];
export const MarketingHubProDefaultSurveyResponses = {
  social: {
    featureKey: FeatureSurveyKeys.SOCIAL,
    isSelected: false,
    translationKey: 'upgrades.trialFeatureSurvey.common.features.social'
  },
  seo: {
    featureKey: FeatureSurveyKeys.SEO,
    isSelected: false,
    translationKey: 'upgrades.trialFeatureSurvey.common.features.seo'
  },
  workflows: {
    featureKey: FeatureSurveyKeys.WORKFLOWS,
    isSelected: false,
    translationKey: 'upgrades.trialFeatureSurvey.common.features.workflows'
  },
  campaigns: {
    featureKey: FeatureSurveyKeys.CAMPAIGNS,
    isSelected: false,
    translationKey: 'upgrades.trialFeatureSurvey.common.features.campaigns'
  },
  customReportsBuilder: {
    featureKey: FeatureSurveyKeys.CUSTOM_REPORTS_BUILDER,
    isSelected: false,
    translationKey: 'upgrades.trialFeatureSurvey.common.features.customReportsBuilder'
  },
  other: {
    featureKey: FeatureSurveyKeys.OTHER,
    isSelected: false,
    translationKey: 'upgrades.trialFeatureSurvey.common.features.other'
  }
};
export const SalesHubProDefaultSurveyResponses = {
  forecasts: {
    featureKey: FeatureSurveyKeys.FORECASTS,
    isSelected: false,
    translationKey: 'upgrades.trialFeatureSurvey.common.features.forecasts'
  },
  products: {
    featureKey: FeatureSurveyKeys.PRODUCTS,
    isSelected: false,
    translationKey: 'upgrades.trialFeatureSurvey.common.features.products'
  },
  quotes: {
    featureKey: FeatureSurveyKeys.QUOTES,
    isSelected: false,
    translationKey: 'upgrades.trialFeatureSurvey.common.features.quotes'
  },
  workflows: {
    featureKey: FeatureSurveyKeys.WORKFLOWS,
    isSelected: false,
    translationKey: 'upgrades.trialFeatureSurvey.common.features.workflows'
  },
  sequences: {
    featureKey: FeatureSurveyKeys.SEQUENCES,
    isSelected: false,
    translationKey: 'upgrades.trialFeatureSurvey.common.features.sequences'
  },
  salesAnalytics: {
    featureKey: FeatureSurveyKeys.SALES_ANALYTICS,
    isSelected: false,
    translationKey: 'upgrades.trialFeatureSurvey.common.features.salesAnalytics'
  },
  other: {
    featureKey: FeatureSurveyKeys.OTHER,
    isSelected: false,
    translationKey: 'upgrades.trialFeatureSurvey.common.features.other'
  }
};
export const ServiceHubProDefaultSurveyResponses = {
  sla: {
    featureKey: FeatureSurveyKeys.SLA,
    isSelected: false,
    translationKey: 'upgrades.trialFeatureSurvey.common.features.sla'
  },
  customerPortal: {
    featureKey: FeatureSurveyKeys.CUSTOMER_PORTAL,
    isSelected: false,
    translationKey: 'upgrades.trialFeatureSurvey.common.features.customerPortal'
  },
  knowledgeBase: {
    featureKey: FeatureSurveyKeys.KNOWLEDGE_BASE,
    isSelected: false,
    translationKey: 'upgrades.trialFeatureSurvey.common.features.knowledgeBase'
  },
  serviceEssentials: {
    featureKey: FeatureSurveyKeys.SERVICE_ESSENTIALS,
    isSelected: false,
    translationKey: 'upgrades.trialFeatureSurvey.common.features.serviceEssentials'
  },
  workflows: {
    featureKey: FeatureSurveyKeys.WORKFLOWS,
    isSelected: false,
    translationKey: 'upgrades.trialFeatureSurvey.common.features.workflows'
  },
  other: {
    featureKey: FeatureSurveyKeys.OTHER,
    isSelected: false,
    translationKey: 'upgrades.trialFeatureSurvey.common.features.other'
  }
};
export const CMSHubProDefaultSurveyResponses = {
  contentEssentials: {
    featureKey: FeatureSurveyKeys.CONTENT_ESSENTIALS,
    isSelected: false,
    translationKey: 'upgrades.trialFeatureSurvey.common.features.contentEssentials'
  },
  contentCreation: {
    featureKey: FeatureSurveyKeys.CONTENT_CREATION,
    isSelected: false,
    translationKey: 'upgrades.trialFeatureSurvey.common.features.contentCreation'
  },
  contentStrategy: {
    featureKey: FeatureSurveyKeys.CONTENT_STRATEGY,
    isSelected: false,
    translationKey: 'upgrades.trialFeatureSurvey.common.features.contentStrategy'
  },
  personalization: {
    featureKey: FeatureSurveyKeys.PERSONALIZATION,
    isSelected: false,
    translationKey: 'upgrades.trialFeatureSurvey.common.features.personalization'
  },
  other: {
    featureKey: FeatureSurveyKeys.OTHER,
    isSelected: false,
    translationKey: 'upgrades.trialFeatureSurvey.common.features.other'
  }
};
export const OperationsHubProDefaultSurveyResponses = {
  triggerWorkflows: {
    featureKey: FeatureSurveyKeys.TRIGGER_WORKFLOWS,
    isSelected: false,
    translationKey: 'upgrades.trialFeatureSurvey.common.features.triggerWorkflows'
  },
  automateFormatting: {
    featureKey: FeatureSurveyKeys.AUTOMATE_FORMATTING,
    isSelected: false,
    translationKey: 'upgrades.trialFeatureSurvey.common.features.automateFormatting'
  },
  dataQualityCommandCenter: {
    featureKey: FeatureSurveyKeys.DATA_QUATLITY_COMMAND_CENTER,
    isSelected: false,
    translationKey: 'upgrades.trialFeatureSurvey.common.features.dataQualityCommandCenter'
  },
  webhooks: {
    featureKey: FeatureSurveyKeys.WEBHOOKS,
    isSelected: false,
    translationKey: 'upgrades.trialFeatureSurvey.common.features.webhooks'
  },
  customCodedWorkflowActions: {
    featureKey: FeatureSurveyKeys.CUSTOM_CODED_WORKFLOW_ACTIONS,
    isSelected: false,
    translationKey: 'upgrades.trialFeatureSurvey.common.features.customCodedWorkflowActions'
  },
  other: {
    featureKey: FeatureSurveyKeys.OTHER,
    isSelected: false,
    translationKey: 'upgrades.trialFeatureSurvey.common.features.other'
  }
};
export const proDefaultSurveyResponseMap = {
  [MARKETING_HUB_PROFESSIONAL]: MarketingHubProDefaultSurveyResponses,
  [SALES_HUB_PROFESSIONAL]: SalesHubProDefaultSurveyResponses,
  [SERVICE_HUB_PROFESSIONAL]: ServiceHubProDefaultSurveyResponses,
  [CMS_HUB_PROFESSIONAL]: CMSHubProDefaultSurveyResponses,
  [OPERATIONS_HUB_PROFESSIONAL]: OperationsHubProDefaultSurveyResponses
};
export const isProApiName = apiName => {
  if (typeof apiName === 'number') {
    return false;
  }
  return ProApiNames.includes(apiName);
};