import { createSelector } from 'reselect';
import { isImmutableMap } from 'EmailData/types/immutable';
export const getGates = state => {
  if (isImmutableMap(state)) {
    return state.get('gates');
  }
  return state && state.auth && state.auth.gates;
};
export const getGatesArray = createSelector([getGates], gates => {
  if (!gates) {
    return [];
  }
  return Array.isArray(gates) ? gates : Object.keys(gates);
});
export const createHasGateSelector = gateToCheck => createSelector([getGates, getGatesArray], (gates, gatesArray) => {
  if (isImmutableMap(gates)) {
    return !!(gates !== null && gates !== void 0 && gates.get(gateToCheck));
  }
  return gates && gates[gateToCheck] || (gatesArray === null || gatesArray === void 0 ? void 0 : gatesArray.includes(gateToCheck));
});