import { UNFILED_ASSETS_ID } from 'framework-listing-lib/internal/Folders/constants/folders';
import { CRM_OBJECT_TYPE_QUERY, CRM_OBJECT_TYPE_FIELD_NAME, LISTING_LIB_CRM_SEARCH_QUERY } from 'framework-listing-lib/internal/hooks/useFetchFromCRM';
import { getGraphQLVariables, transformCrmSearchResponseToGraphQLResponse, transformGraphQLResponseIntoCrmSearchResponse } from 'framework-listing-lib/internal/graphql/utils/graphql';
import { CRM_SEARCH_GRAPHQL_CACHE_KEY } from '../../graphql/constants';
export function getUnfiledAssetsInCache({
  dfcClient,
  maybeApolloClient,
  variables
}) {
  if (maybeApolloClient) {
    const apolloCache = maybeApolloClient.readQuery({
      query: LISTING_LIB_CRM_SEARCH_QUERY,
      variables: getGraphQLVariables(variables)
    });
    if (apolloCache) {
      return transformGraphQLResponseIntoCrmSearchResponse(apolloCache, variables.fetchParams.objectTypeId) || null;
    }
    return null;
  }
  const unfiledAssets = dfcClient.cache.readQuery({
    query: CRM_OBJECT_TYPE_QUERY,
    variables
  });
  if (unfiledAssets) {
    return unfiledAssets[CRM_OBJECT_TYPE_FIELD_NAME];
  }
  return null;
}
export function getHasUnfiledAssetsCache({
  dfcClient,
  maybeApolloClient,
  variables
}) {
  return getUnfiledAssetsInCache({
    dfcClient,
    maybeApolloClient,
    variables
  }) !== null;
}
export function getUnfiledAssetsTotalFromCache({
  dfcClient,
  maybeApolloClient,
  variables
}) {
  const unfiledAssetsCache = getUnfiledAssetsInCache({
    dfcClient,
    maybeApolloClient,
    variables
  });
  return unfiledAssetsCache ? unfiledAssetsCache.total : null;
}
export function writeUnfiledAssetsToCache({
  dfcClient,
  maybeApolloClient,
  variables,
  newUnfiledAssets
}) {
  if (maybeApolloClient) {
    maybeApolloClient.writeQuery({
      query: LISTING_LIB_CRM_SEARCH_QUERY,
      data: {
        [CRM_SEARCH_GRAPHQL_CACHE_KEY]: transformCrmSearchResponseToGraphQLResponse(newUnfiledAssets)
      },
      variables: getGraphQLVariables(variables)
    });
  } else {
    dfcClient.cache.writeQuery({
      query: CRM_OBJECT_TYPE_QUERY,
      data: {
        [CRM_OBJECT_TYPE_FIELD_NAME]: newUnfiledAssets
      },
      variables
    });
  }
}
export function modifyUnfiledAssets(previousFolderId, newFolderId, affectedAssets, unfiledAssets) {
  const newUnfiledAssets = Object.assign({}, unfiledAssets);

  // An asset is being removed from the unfiled asset folder
  if (previousFolderId === UNFILED_ASSETS_ID && unfiledAssets) {
    newUnfiledAssets.total = Math.max(0, newUnfiledAssets.total - affectedAssets);
    return newUnfiledAssets;
    //An asset is being added from the unfiled asset folder
  } else if (newFolderId === UNFILED_ASSETS_ID && unfiledAssets) {
    newUnfiledAssets.total += affectedAssets;
    return newUnfiledAssets;
  }
  return unfiledAssets;
}
export function removeDeletedUnfiledAssets(affectedAssets, unfiledAssets) {
  if (unfiledAssets) {
    return Object.assign({}, unfiledAssets, {
      total: Math.max(unfiledAssets.total - affectedAssets, 0)
    });
  }
  return unfiledAssets;
}