import http from 'hub-http/clients/apiClient';
const MARKETING_ASSET_PERMISSION_TEAMS = 'marketing/asset-permissions/v1/teams';
export function getTeamPermissions({
  objectType,
  objectId
}) {
  return http.get(`${MARKETING_ASSET_PERMISSION_TEAMS}/${objectType}/${objectId}`);
}
export function saveTeamPermissions({
  objectType,
  objectIds,
  teamIds
}) {
  return http.put(`${MARKETING_ASSET_PERMISSION_TEAMS}/${objectType}`, {
    data: {
      assetIds: objectIds,
      teamIds
    }
  }).then(() => teamIds);
}