/* hs-eslint ignored failing-rules */
/* eslint-disable hubspot-dev/no-declarations */

import enviro from 'enviro';
import Raven from 'raven-js';
import { track } from '../common/eventTracking/tracker';
import getLang from 'I18n/utils/getLang';
import { QA } from 'self-service-api/constants/Environments';
import { getUpgradeDataTrackingProperties } from 'ui-addon-upgrades/ums/utils';
import { getUserInfo } from 'ui-addon-upgrades/_core/common/api/getUserInfo';
import { getIdentificationToken } from 'ui-addon-upgrades/_core/common/api/getIdentificationToken';
const openSalesChat = (hostWindow, upgradeData) => {
  if (!hostWindow.HubSpotConversations) {
    Raven.captureException(new Error('Attempted to open chat without the widget being loaded'), {
      tags: {
        location: hostWindow.location.href
      }
    });
    return;
  }
  if (hostWindow.hubspot && hostWindow.hubspot.zorse && !hostWindow.hubspot.zorse.supressed) {
    hostWindow.hubspot.zorse.minimizeZorseWidgetButton();
  }
  hostWindow.HubSpotConversations.on('conversationStarted', __payload => {
    track('communicationMethodsInteraction', Object.assign({
      action: 'started conversation'
    }, getUpgradeDataTrackingProperties(upgradeData)));
  });
  hostWindow.HubSpotConversations.on('conversationClosed', __payload => {
    track('communicationMethodsInteraction', Object.assign({
      action: 'ended conversation'
    }, getUpgradeDataTrackingProperties(upgradeData)));
    hostWindow.HubSpotConversations.widget.close();
    if (hostWindow.hubspot && hostWindow.hubspot.zorse && !hostWindow.hubspot.zorse.supressed) {
      hostWindow.hubspot.zorse.restoreZorseWidgetButton();
    }
  });
  const status = hostWindow.HubSpotConversations.widget.status();

  // If widget is already loaded on page (eg paywalls), we need to remove and reload it to get new query params
  if (status.loaded) {
    hostWindow.HubSpotConversations.widget.remove();
    hostWindow.HubSpotConversations.widget.load({
      widgetOpen: true
    });
  } else {
    hostWindow.HubSpotConversations.widget.load({
      widgetOpen: true
    });
  }
};
const mountConversationsScriptTag = hostWindow => {
  const qa = enviro.isProd() ? '' : QA;
  const chatPortal = enviro.isProd() ? '53' : '100048175';
  const script = hostWindow.document.createElement('script');
  const env = enviro.getShort();
  const hublet = enviro.getHublet();
  script.type = 'text/javascript';
  script.id = 'hubspot-messages-loader';
  script.async = false;
  script.defer = false;
  script.src = `//js.usemessages${qa}.com/conversations-embed.js`;
  script.setAttribute('data-hsjs-portal', chatPortal);
  script.setAttribute('data-hsjs-env', env);
  script.setAttribute('data-hs-js-hublet', hublet);
  hostWindow.document.body.appendChild(script);
};
const addChatQueryParams = (hostWindow, isRetail, isAssignable) => {
  const getQueryParam = () => {
    if (isRetail) {
      return `show-retail-tts-chat-${getLang()}`;
    } else if (isAssignable) {
      return `show-assignable-tts-chat-${getLang()}`;
    } else {
      return `show-unassignable-tts-chat-${getLang()}`;
    }
  };
  const queryParam = `${hostWindow.location.search.length ? '&' : '?'}${getQueryParam()}=true`;
  const queryParamIsPresent = hostWindow.location.search.includes(queryParam.slice(1));
  if (!queryParamIsPresent) {
    hostWindow.history.pushState(null, '', `${hostWindow.location.href}${queryParam}`);
  }
};
const initializeChatSettings = (hostWindow, isRetail, isAssignable) => {
  addChatQueryParams(hostWindow, isRetail, isAssignable);
  return Promise.all([getUserInfo(), getIdentificationToken()]).then(([userInfo, tokenObject]) => {
    const userEmail = userInfo.user.email;
    const token = tokenObject.token;
    if (!hostWindow.hsConversationsSettings) {
      hostWindow.hsConversationsSettings = {};
    }
    hostWindow.hsConversationsSettings.loadImmediately = false;
    hostWindow.hsConversationsSettings.identificationEmail = userEmail;
    hostWindow.hsConversationsSettings.identificationToken = token;
  }).catch(error => {
    Raven.captureException(error);
    if (!hostWindow.hsConversationsSettings) {
      hostWindow.hsConversationsSettings = {};
    }
    hostWindow.hsConversationsSettings.loadImmediately = true;
  });
};

/**
 * Adds the required query parameter, loads the Conversations script tag,
 * and opens the chat when it's ready.
 *
 *
 * Note that `show-tts-chat=true` is a specific query parameter for
 * the [chatflow in 53](https://app.hubspot.com/chatflows/53/edit/live-chat/548260/target).
 */

export const loadAndOpenSalesChatInWindow = hostWindow => ({
  upgradeData,
  isRetail = false,
  isAssignable = false
}) => {
  if (hostWindow) {
    // When in a modal, post this the parent, and the parent will open chat
    if (hostWindow.self !== hostWindow.top) {
      hostWindow.parent.postMessage({
        type: 'OPEN_CHAT',
        data: Object.assign({}, upgradeData, {
          isRetail,
          upgradeData,
          isAssignable
        })
      }, '*');
      return;
    }
    initializeChatSettings(hostWindow, isRetail, isAssignable).then(() => {
      if (hostWindow.HubSpotConversations) {
        openSalesChat(hostWindow, upgradeData);
      } else {
        hostWindow.hsConversationsOnReady = [() => openSalesChat(hostWindow, upgradeData)];
        mountConversationsScriptTag(hostWindow);
      }
    }).catch(error => {
      Raven.captureException(error);
    });
  }
};
export const loadAndOpenSalesChat = params => loadAndOpenSalesChatInWindow(window)(params);