import devLogger from 'react-utils/devLogger';
import { createSearchReferenceResolver } from 'reference-resolvers-lite/createSearchReferenceResolver';
import identity from 'transmute/identity';
import { SALESFORCE_CAMPAIGN } from '../CustomResolverReferenceTypes';
import PortalIdParser from 'PortalIdParser';
const formatSalesforceCampaignByIdResponse = response => response.map(campaign => ({
  id: campaign.id,
  label: campaign.name
}));
export const createSalesforceCampaignReferenceResolver = ({
  httpClient
}) => {
  return createSearchReferenceResolver({
    fetchByIds: ids => httpClient.post('/sfdc/v1/campaigns/batch', {
      query: {
        portalId: PortalIdParser.get()
      },
      data: ids
    }),
    fetchBySearch: () => {
      devLogger.warn({
        message: 'Salesforce Campaign resolver does not support search fetching',
        key: 'salesforce-campaign-resolver'
      });
      return Promise.resolve({
        hasMore: false,
        offset: 0,
        references: []
      });
    },
    formatByIdResponse: formatSalesforceCampaignByIdResponse,
    formatSearchResponse: identity,
    referenceType: SALESFORCE_CAMPAIGN
  });
};