import { Record } from 'immutable';
class PortalRestrictions extends Record({
  crmImportScope: false,
  forcedSuspension: false,
  freePortal: false,
  starterPortal: false,
  supportAccess: false
}) {
  static from(json) {
    return new PortalRestrictions(json);
  }
  isFreePortal() {
    return this.freePortal;
  }
  isStarterPortal() {
    return this.starterPortal;
  }
  hasCrmImportScope() {
    return this.crmImportScope;
  }
  hasDeliverabilitySupport() {
    return this.supportAccess;
  }
}
export default PortalRestrictions;