'use es6';

import { Map as ImmutableMap } from 'immutable';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { RESET_EMAIL_DETAILS } from 'EmailData/actions/actionTypes';
import { FETCH_EMAIL_CAMPAIGN_CLICKS, FETCH_EMAIL_CAMPAIGN_CLICKS_REQUEST } from 'email-management-lib/refactor/actions/actionTypes';
import CampaignClicks from 'email-management-lib/refactor/models/CampaignClicks';
const initialState = ImmutableMap();
function campaignClicks(state = initialState, action) {
  switch (action.type) {
    case FETCH_EMAIL_CAMPAIGN_CLICKS:
      {
        if (action.error) {
          return state;
        }
        return ImmutableMap(action.payload.stats.map(campaign => {
          return [campaign.emailCampaignId, CampaignClicks.from(campaign)];
        }));
      }
    case RESET_EMAIL_DETAILS:
      return initialState;
    default:
      return state;
  }
}
export default asyncResource(campaignClicks, {
  requestActions: [FETCH_EMAIL_CAMPAIGN_CLICKS_REQUEST],
  responseActions: [FETCH_EMAIL_CAMPAIGN_CLICKS],
  invalidatingActions: [RESET_EMAIL_DETAILS]
});