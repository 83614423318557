import I18n from 'I18n';
import unescapedText from 'I18n/utils/unescapedText';
const thresholds = {
  years: 12,
  days: 2.4,
  hours: 2.4,
  minutes: 2.4,
  seconds: 0,
  milliseconds: 0
};
const getRoundedUnitWithPrecision = (ms, unit) =>
// @ts-expect-error TS doesn't like math ops on Duration but it is legal
Math.round(ms / unit * 10) / 10;
const getUnits = () => {
  const SECOND = I18n.moment.duration(1, 'second').valueOf();
  const MINUTE = I18n.moment.duration(1, 'minute').valueOf();
  const HOUR = I18n.moment.duration(1, 'hour').valueOf();
  const DAY = I18n.moment.duration(1, 'day').valueOf();
  const YEAR = I18n.moment.duration(1, 'year').valueOf();
  return {
    years: ms => getRoundedUnitWithPrecision(ms, YEAR),
    days: ms => getRoundedUnitWithPrecision(ms, DAY),
    hours: ms => getRoundedUnitWithPrecision(ms, HOUR),
    minutes: ms => getRoundedUnitWithPrecision(ms, MINUTE),
    seconds: ms => getRoundedUnitWithPrecision(ms, SECOND),
    milliseconds: ms =>
    // @ts-expect-error TS doesn't like math ops on Duration but it is legal
    Math.round(ms)
  };
};
export const formatDurationV2 = (value, dateDisplayHint) => {
  if (value === null || value === undefined || value === '' || isNaN(+value)) {
    return {
      label: undefined,
      duration: undefined,
      unit: undefined
    };
  }
  const numberValue = Number(value);
  const momentValueInUserTz = I18n.moment.userTz(numberValue);
  const momentNowInUserTz = I18n.moment.userTz();
  const durationDiff = dateDisplayHint === 'time_since' ? I18n.moment.duration(momentNowInUserTz.diff(momentValueInUserTz)) : I18n.moment.duration(momentValueInUserTz.diff(momentNowInUserTz));
  const units = getUnits();
  const unitKeys = Object.keys(units);
  // this logic has been copied from https://git.hubteam.com/HubSpot/reporting/blob/02c0685fe5556705c27849260d9fcacedb2d269e/reporting-data/static/js/hydrate/durationFormatter.ts#L63
  // Link points to the version from Aug 2024. Any further changes to the original logic should be reflected here.
  return unitKeys.reduce((accumulator, currentUnit) => {
    const {
      label
    } = accumulator;
    const roundedValue = Math.round(units[currentUnit](durationDiff));
    if (label) {
      return accumulator;
    }
    if (Math.abs(roundedValue) > thresholds[currentUnit] || roundedValue === 0 && currentUnit === 'milliseconds') {
      if (currentUnit !== 'milliseconds') {
        return {
          duration: roundedValue,
          label: unescapedText(`customerDataPropertyUtils.dateDisplayHintLabel.durations.label.${currentUnit}`, {
            count: roundedValue
          }),
          unit: currentUnit
        };
      } else {
        return {
          duration: roundedValue,
          label: unescapedText(`customerDataPropertyUtils.dateDisplayHintLabel.durations.label.seconds`, {
            count: roundedValue === 0 ? roundedValue / 1000 : `< ${roundedValue > 0 ? '1' : '-1'}`
          }),
          unit: 'milliseconds'
        };
      }
    } else {
      return {
        label: '',
        duration: 0,
        unit: 'milliseconds'
      };
    }
  }, {
    label: '',
    duration: 0,
    unit: 'milliseconds'
  });
};