'use es6';

import { REQUEST_SENDING_LIMIT_CREDITS, RECEIVE_SENDING_LIMIT_CREDITS } from 'EmailData/actions/actionTypes';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { Map as ImmutableMap } from 'immutable';
const initialState = ImmutableMap();
const sendingLimitCreditsReducer = (state = initialState, {
  type,
  response
}) => {
  switch (type) {
    case RECEIVE_SENDING_LIMIT_CREDITS:
      return ImmutableMap(response);
    default:
      return state;
  }
};
export default asyncResource(sendingLimitCreditsReducer, {
  requestActions: [REQUEST_SENDING_LIMIT_CREDITS],
  responseActions: [RECEIVE_SENDING_LIMIT_CREDITS],
  invalidatingActions: []
});