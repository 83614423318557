import { useEffect, useRef, useState } from 'react';
import { useHttpClient } from '../client/HttpClientContext';
import { cache as conditionalPropertyRulesCache } from '../v2/conditionalPropertyRules/ConditionalPropertyRulesCache';
import { cache as conditionalPropertyOptionsRulesCache } from '../v2/conditionalPropertyOptionsRules/ConditionalPropertyOptionsRulesCache';
import { makePropertyValuesCache } from '../values/PropertyValuesCache';
import { fetchPropertyDefinitions } from './api';
const usePropertiesFormApi = ({
  objectTypeId,
  objectId,
  fetchConditionalPropertyRules
}) => {
  const httpClient = useHttpClient();
  const [state, setState] = useState({
    loading: true,
    error: false,
    properties: {},
    conditionalPropertyRules: [],
    conditionalPropertyOptionsRules: [],
    cachedPropertyValues: {}
  });

  // one values cache per PropertiesForm instance. the cache isn't shared, in
  // order to avoid complexity around cache lifetime management and stale data.
  // however this does mean each PropertiesForm fetches all values for the record
  const propertyValuesCacheRef = useRef(makePropertyValuesCache());
  useEffect(() => {
    const propertyDefinitionsPromise = fetchPropertyDefinitions(objectTypeId, httpClient);
    const conditionalPropertyRulesPromise = fetchConditionalPropertyRules ? fetchConditionalPropertyRules(objectTypeId, httpClient) : conditionalPropertyRulesCache.get(objectTypeId, httpClient);
    const conditionalPropertyOptionsRulesPromise = conditionalPropertyOptionsRulesCache.get(objectTypeId, httpClient);

    // fetch property values for record being edited or default to {}
    let cachedPropertyValuesPromise;
    if (objectId) {
      cachedPropertyValuesPromise = propertyValuesCacheRef.current.get({
        objectTypeId,
        objectId
      }, httpClient);
    } else {
      cachedPropertyValuesPromise = Promise.resolve({});
    }
    Promise.all([propertyDefinitionsPromise, conditionalPropertyRulesPromise, conditionalPropertyOptionsRulesPromise, cachedPropertyValuesPromise]).then(([properties, conditionalPropertyRules, conditionalPropertyOptionsRules, cachedPropertyValues]) => {
      setState({
        loading: false,
        error: false,
        properties,
        conditionalPropertyRules,
        conditionalPropertyOptionsRules,
        cachedPropertyValues
      });
    }).catch(__e => {
      // TODO: Raven
      setState({
        loading: false,
        error: true,
        properties: {},
        conditionalPropertyRules: [],
        conditionalPropertyOptionsRules: [],
        cachedPropertyValues: {}
      });
    });
    // Restricting api calls to initial load. Future changes to flags will not trigger re-load
    // We shall revisit this restriction if a product use-cases arises
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectTypeId]);
  const {
    loading,
    error,
    properties,
    conditionalPropertyRules,
    conditionalPropertyOptionsRules,
    cachedPropertyValues
  } = state;
  return {
    loading,
    error,
    properties,
    conditionalPropertyRules,
    conditionalPropertyOptionsRules,
    cachedPropertyValues
  };
};
export default usePropertiesFormApi;