import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { DISMISS_DETAILS_ACTION_MODAL, RECEIVED_EMAIL_CLONE_DATA, REQUEST_EMAIL_CLONE_DATA } from 'email-management-lib/refactor/actions/actionTypes';
import { buildTypedMap } from 'EmailData/types/immutable';
export const initialState = buildTypedMap({
  primary: {
    isConvertible: null,
    errors: [],
    smartContentLocations: [],
    hasCustomPlaintext: false
  },
  variant: {
    isConvertible: null,
    errors: [],
    smartContentLocations: [],
    hasCustomPlaintext: false
  }
});
function emailTemplateModeCloneModal(state = initialState, action) {
  var _action$payload$prima, _action$payload$varia;
  switch (action.type) {
    case RECEIVED_EMAIL_CLONE_DATA:
      return buildTypedMap({
        primary: (_action$payload$prima = action.payload.primary) !== null && _action$payload$prima !== void 0 ? _action$payload$prima : state.get('primary'),
        variant: (_action$payload$varia = action.payload.variant) !== null && _action$payload$varia !== void 0 ? _action$payload$varia : state.get('variant')
      });
    default:
      return state;
  }
}
export default asyncResource(emailTemplateModeCloneModal, {
  requestActions: [REQUEST_EMAIL_CLONE_DATA],
  responseActions: [RECEIVED_EMAIL_CLONE_DATA],
  invalidatingActions: [DISMISS_DETAILS_ACTION_MODAL]
});