import { useMemo } from 'react';
import { getFilterValueByProperty } from 'framework-listing-lib/utils/filters';
import useCrmObjectTypeListingFilters from 'framework-listing-lib/hooks/useCrmObjectTypeListingFilters';
import { FOLDER_ID, UNFILED_ASSETS_ID } from '../constants/folders';
export default function useCurrentFolderId() {
  const {
    filters
  } = useCrmObjectTypeListingFilters();
  const currentFolderId = getFilterValueByProperty(filters, FOLDER_ID);
  const folderId = useMemo(() => {
    if (!currentFolderId) {
      return UNFILED_ASSETS_ID;
    }
    const intFolderId = parseInt(currentFolderId, 10);
    return intFolderId > 0 ? intFolderId : UNFILED_ASSETS_ID;
  }, [currentFolderId]);
  return folderId;
}