'use es6';

import { Record } from 'immutable';
import { safeDecodeUrl } from 'ClickMapJS/lib/urlUtils';
const defaults = {
  link: '',
  rawLink: '',
  metric: 0
};
class CampaignLinkStats extends Record(defaults) {
  static from(json) {
    return new CampaignLinkStats(Object.assign({}, json, {
      link: safeDecodeUrl(json.link),
      rawLink: json.link
    }));
  }
}
export default CampaignLinkStats;