'use es6';

import { Record } from 'immutable';
import formatName from 'I18n/utils/formatName';
class UserModel extends Record({
  id: -1,
  email: '',
  fullName: '',
  isHubspotUser: false
}) {
  static from(json) {
    let fullName = json.fullName;
    if (!fullName) {
      fullName = formatName({
        firstName: json.firstName,
        lastName: json.lastName
      });
    }
    return new UserModel(Object.assign({
      fullName
    }, json));
  }
  getEmail() {
    return this.get('email');
  }
  getLabel() {
    return this.get('fullName') || this.getEmail();
  }
}
export default UserModel;