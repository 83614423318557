'use es6';

import { Map as ImmutableMap } from 'immutable';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { FETCH_CONTACT_RECORDS, FETCH_CONTACT_RECORDS_REQUEST } from 'email-management-lib/refactor/actions/actionTypes';
import ContactRecord from 'EmailData/models/contacts/ContactRecord';
const initialState = ImmutableMap();
function contactRecords(state = initialState, action) {
  switch (action.type) {
    case FETCH_CONTACT_RECORDS:
      if (action.error !== true) {
        const newRecords = Object.keys(action.payload).reduce((newState, key) => {
          return newState.set(key, ContactRecord.from(action.payload[key]));
        }, ImmutableMap({}));
        return state.mergeDeep(newRecords);
      }
      return state;
    default:
      return state;
  }
}
export default asyncResource(contactRecords, {
  requestActions: [FETCH_CONTACT_RECORDS_REQUEST],
  responseActions: [FETCH_CONTACT_RECORDS],
  invalidatingActions: []
});