/* eslint-disable @typescript-eslint/no-misused-promises */

import { createIndexedDBSuperstore } from 'superstore';
let storeInstance;
let MOUIStoreInstance;
const getStoreInstance = () => {
  if (!storeInstance) {
    storeInstance = createIndexedDBSuperstore({
      namespace: 'campaigns-lib'
    }).open();
  }
  return storeInstance;
};
export function getSuperStoreValue(key) {
  return getStoreInstance().then(store => store.get(key));
}
export function deleteSuperstoreValue(key) {
  return getStoreInstance().then(store => store.delete(key));
}
export const getMOUIStoreInstance = () => {
  if (!MOUIStoreInstance) {
    MOUIStoreInstance = createIndexedDBSuperstore({
      namespace: 'marketing-orchestration-ui'
    }).open();
  }
  return MOUIStoreInstance;
};
export function getMOUISuperStoreValue(key) {
  return getMOUIStoreInstance().then(store => store.get(key));
}
export const getHasCompletedCampaignsOnboardingTourFromMOUISuperStore = () => getMOUISuperStoreValue('HAS_COMPLETED_CAMPAIGNS_ONBOARDING_TOUR').then(data => {
  // Transforms 'true' or 'false' from string to boolean
  return data === 'true';
});