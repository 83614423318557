import { Record } from 'immutable';
const defaults = {
  user_id: null,
  first_name: '',
  last_name: '',
  email: '',
  locale: 'en',
  roles: [],
  scopes: [],
  teams: [],
  verified: false
};
class User extends Record(defaults) {
  static from(json) {
    return new User(json);
  }
  hasScope(scope) {
    return this.scopes.includes(scope);
  }
  hasFullDashboardAccess() {
    return this.hasScope('email-access');
  }
  canWrite() {
    return this.hasScope('email-write');
  }
  hasFullEditorAccess() {
    return this.hasScope('email-full-editing');
  }
  hasAccessToABEmails() {
    return this.hasScope('email-type-ab-access');
  }
  canPublish() {
    return this.hasScope('email-publish');
  }
}
export default User;