import { TEAM, USER
// @ts-expect-error not migrated to TS yet
} from 'reference-resolvers/constants/ReferenceObjectTypes';
// @ts-expect-error not migrated to TS yet
import ProvideReferenceResolvers from 'reference-resolvers/ProvideReferenceResolvers';
// @ts-expect-error not migrated to TS yet
import TeamReferenceResolver from 'reference-resolvers/resolvers/TeamReferenceResolver';
// @ts-expect-error not migrated to TS yet
import UserReferenceResolver from 'reference-resolvers/resolvers/UserReferenceResolver';
const resolvers = {
  [TEAM]: TeamReferenceResolver,
  [USER]: UserReferenceResolver
};
const withProviderReferenceResolvers = ProvideReferenceResolvers(resolvers);
export default withProviderReferenceResolvers;