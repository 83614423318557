'use es6';

import { createSelector } from 'reselect';
import { buildWorkflows } from 'EmailData/utils/workflowsUtils';
export const workflowsSelector = state => state.getIn(['entities', 'workflows']).getData();
export const areWorkflowsLoadingSelector = state => {
  return state.getIn(['entities', 'workflows']).isLoading();
};
export const workflowsFromDashboard = primaryEmailId => createSelector([workflowsSelector], workflowsMap => buildWorkflows(workflowsMap, primaryEmailId, null, {
  omit: 'JOURNEY_STAGE'
}));