import { useCallback } from 'react';
import { useHttpClient } from '../../client/HttpClientContext';
import { useAsyncFunction } from '../../utils/hooks/useAsyncFunction';
import { cache } from '../conditionalPropertyOptionsRules/ConditionalPropertyOptionsRulesCache';
export const useConditionalPropertyOptionsRulesForObjectType = (objectTypeId, options = {
  skip: false
}) => {
  const httpClient = useHttpClient();
  const getRules = useCallback(() => {
    return cache.get(objectTypeId, httpClient);
  }, [httpClient, objectTypeId]);
  return useAsyncFunction(getRules, options);
};