import PortalIdParser from 'PortalIdParser';
import { getFullUrl } from 'hubspot-url-utils';
import { getQueryParam } from './urlUtils';
import { localStorage } from './storageUtil';
const ONBOARDING_REFERRER_TASK = 'onboarding_referrer_task';
const ONBOARDING_REFERRER_GROUP = 'onboarding_referrer_group';
const ONBOARDING_REFERRER_SOURCE = 'onboarding_referrer_source';
const QUERY_PARAMS_TO_INCLUDE = [ONBOARDING_REFERRER_TASK, ONBOARDING_REFERRER_GROUP, ONBOARDING_REFERRER_SOURCE];
export function delayUntilIdle(callback) {
  if (window.requestIdleCallback) {
    return window.requestIdleCallback(callback, {
      timeout: 5000
    });
  }
  return window.setTimeout(callback, 0);
}
const insertRootElement = element => {
  const body = document.body;
  const firstChild = body.firstChild;
  body.insertBefore(element, firstChild);
};
const isLocalBannerFrame = () => localStorage.getItem('ONBOARDING_TOURS_BANNER_ENV') === 'local';
export const getFrameSrc = taskIdParam => {
  const portalId = PortalIdParser.get();
  const bannerFrameBaseUrl = getFullUrl(isLocalBannerFrame() ? 'local' : 'app');
  const onboardingBannerUrl = `${bannerFrameBaseUrl}/guide-onboarding-tours/${portalId}`;
  const extraSearch = QUERY_PARAMS_TO_INCLUDE.map(queryParam => {
    if (queryParam === ONBOARDING_REFERRER_TASK && taskIdParam) {
      return `${ONBOARDING_REFERRER_TASK}=${taskIdParam}`;
    }
    const queryValue = getQueryParam(queryParam);
    return queryValue ? `${queryParam}=${queryValue}` : '';
  }).filter(Boolean).join('&');
  return `${onboardingBannerUrl}${extraSearch ? `?${extraSearch}` : ''}`;
};

// Create onboarding-tours banner iframe
export const createFrameElement = taskId => {
  const frameElement = document.createElement('iframe');
  frameElement.id = 'onboarding-tours-banner-frame';
  frameElement.frameBorder = '0';
  frameElement.src = getFrameSrc(taskId);
  return frameElement;
};
const createRootElement = taskId => {
  // Create iframe container block element
  const blockElement = document.createElement('div');
  blockElement.id = 'onboarding-tours-banner';

  // Create and append iframe to container
  const frameElement = createFrameElement(taskId);
  blockElement.style.display = 'none';
  blockElement.appendChild(frameElement);
  return blockElement;
};
export const insertOnboardingToursBanner = taskId => {
  const frameElement = document.getElementById('onboarding-tours-banner-frame');
  if (frameElement) {
    frameElement.src = getFrameSrc(taskId);
    return;
  }
  const rootElement = createRootElement(taskId);
  insertRootElement(rootElement);
};
export const isInOnboardingSession = () => {
  try {
    const isEnabledFromStorage = window.sessionStorage && window.sessionStorage.getItem('ONBOARDING_TOURS_GUIDE');
    return isEnabledFromStorage || getQueryParam(ONBOARDING_REFERRER_TASK);
  } catch (_unused) {
    return false;
  }
};
export const hasNavBar = () => {
  // hubspot.nav holds an object when there is a navbar
  return !!window.hubspot.nav;
};