/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable promise/catch-or-return */

import { useEffect, useState } from 'react';
import useIsMounted from './useIsMounted';
import { getCoachingTipsDismissed, setCoachingTipsDismissed } from '../api/coachingTipsApi';
import { useHttpClient } from '../hooks/useHttpClient';
export default (coachingTipsId => {
  // Initially hide coaching tips
  const [isCoachingTipsDismissed, setIsCoachingTipsDismissed] = useState(true);
  const isMounted = useIsMounted();
  const http = useHttpClient();
  useEffect(() => {
    // If coaching tips are not dismissed then show them
    getCoachingTipsDismissed(coachingTipsId, http).then(dismissedFromApi => {
      if (isMounted.current) {
        setIsCoachingTipsDismissed(dismissedFromApi);
      }
    });
  }, [coachingTipsId, http, isMounted]);
  const dismissCoachingTips = () => {
    // Set coaching tips dismissed on API
    setCoachingTipsDismissed(coachingTipsId, http);
    // Change state to hide coaching tips
    setIsCoachingTipsDismissed(true);
  };
  return {
    isCoachingTipsDismissed,
    dismissCoachingTips
  };
});