'use es6';

import { List, Map as ImmutableMap } from 'immutable';
import { FETCH_SLACK_CHANNELS_FAIL, FETCH_SLACK_CHANNELS_START, FETCH_SLACK_CHANNELS_SUCCESS, POST_SLACK_MESSAGE_FAIL, POST_SLACK_MESSAGE_START, POST_SLACK_MESSAGE_SUCCESS } from 'email-management-lib/refactor/actions/actionTypes';
import aysncResource from 'EmailData/reducers/helpers/asyncResource';
const initialState = ImmutableMap({
  channels: List(),
  allChannelsLoaded: false
});
const slackReducer = (state = initialState, {
  payload,
  type
}) => {
  switch (type) {
    case FETCH_SLACK_CHANNELS_SUCCESS:
      {
        const {
          channels,
          allChannelsLoaded
        } = payload;
        return state.set('channels', List(channels)).set('allChannelsLoaded', allChannelsLoaded);
      }
    default:
      return state;
  }
};
export default aysncResource(slackReducer, {
  requestActions: [FETCH_SLACK_CHANNELS_START, POST_SLACK_MESSAGE_START],
  responseActions: [FETCH_SLACK_CHANNELS_SUCCESS, POST_SLACK_MESSAGE_SUCCESS],
  invalidatingActions: [FETCH_SLACK_CHANNELS_FAIL, POST_SLACK_MESSAGE_FAIL]
});