import PortalIdParser from 'PortalIdParser';
import { BYTES_IN_MEGABYTE, FileTypes } from '../constants';
import { TypeToExtensions } from '../constants/fileTypes';
export function splitNameAndExtension(fileName) {
  const parts = fileName.split('.');
  if (parts.length === 1) {
    return {
      extension: '',
      name: fileName
    };
  }
  return {
    extension: parts.pop() || '',
    name: parts.join('.')
  };
}
export function isShutterstockImage(file) {
  return Boolean(file.type === FileTypes.IMG && file.meta.shutterstock_id);
}
const FORM_UPLOADS_FOLDER_NAME = 'form-uploads';
const getIsFileSubmittedThroughForms = url => {
  const fileUrl = url || '';
  return fileUrl.indexOf(`hubfs/${PortalIdParser.get()}/${FORM_UPLOADS_FOLDER_NAME}`) > -1;
};
const getIsFileAccessibleAnonymously = meta => {
  return meta.allows_anonymous_access;
};
export const getIsFilePrivate = (meta, url) => {
  return !getIsFileAccessibleAnonymously(meta) && !getIsFileSubmittedThroughForms(url);
};
export const getIsFileSensitive = meta => {
  return meta.sensitive;
};
export const getIsFileExternal = (meta, url) => {
  return !getIsFileAccessibleAnonymously(meta) && getIsFileSubmittedThroughForms(url);
};
const getType = extensionOrType => {
  if (Object.prototype.hasOwnProperty.call(FileTypes, extensionOrType)) {
    return extensionOrType;
  }
  const type = Object.keys(TypeToExtensions).find(key => TypeToExtensions[key].includes(extensionOrType));
  if (type) {
    return type;
  }
  return FileTypes.OTHER;
};
export const getFileType = (type, extension) => {
  if (type) {
    return type;
  }
  return getType(extension);
};
export const getIsImage = (type, extension) => {
  return getFileType(type, extension) === FileTypes.IMG;
};
export const getIsSvg = (type, extension, encoding) => {
  return getIsImage(type, extension) && encoding === 'svg';
};
export const getIsSvgTooLarge = size => {
  return size >= BYTES_IN_MEGABYTE;
};
export const getIsTemporaryUploadingFile = id => {
  return id <= 0;
};