import { useCallback, useMemo } from 'react';
import http from 'hub-http/clients/apiClient';
import { registerQuery, useDataFetchingClient, useQuery } from 'data-fetching-client';
import { makeQuickFetchRequest } from 'framework-listing-lib/utils/quickFetch';
import { Metrics } from 'framework-listing-lib/internal/metrics';
import useObjectTypeId from 'framework-listing-lib/internal/hooks/useObjectTypeId';
import { convertSalesViewToView } from '../utils/mapper';
import { MAX_VIEWS_LIMIT } from '../constants/views';
import { useHasViews, useViewsNamespace } from './useViewProps';
export const GET_PINNED_VIEWS_FIELD_NAME = 'getPinnedViews';
export const GET_PINNED_VIEWS = registerQuery({
  fieldName: GET_PINNED_VIEWS_FIELD_NAME,
  args: ['namespace', 'objectType'],
  fetcher({
    namespace,
    objectType
  }) {
    const quickFetchKey = `crm-listing-pinned-views-${objectType}-${namespace}-v4`;
    const pinnedViewsRequest = () => http.get(`sales/v4/views/${objectType}/pinned/view`, {
      query: {
        namespace,
        count: MAX_VIEWS_LIMIT
      }
    });
    return makeQuickFetchRequest({
      makeRequestFn: pinnedViewsRequest,
      requestName: quickFetchKey
    }).then(response => {
      Metrics.counter('fetch-pinned-views-success-v4').increment();
      return Object.assign({}, response, {
        results: response.results.map(salesView => convertSalesViewToView({
          namespace,
          salesView
        }))
      });
    }).catch(error => {
      Metrics.counter('fetch-pinned-views-failure-v4').increment();
      throw error;
    });
  }
});
export const GET_VIEWS_FIELD_NAME = 'getViews';
export const GET_VIEWS = registerQuery({
  fieldName: GET_VIEWS_FIELD_NAME,
  args: ['namespace', 'objectType'],
  fetcher({
    namespace,
    objectType
  }) {
    const quickFetchKey = `crm-listing-views-${objectType}-${namespace}-v4`;
    const viewsRequest = () => http.post(`sales/v4/views/by-category/search/${objectType}`, {
      data: {
        categories: ['DEFAULT', 'MINE', 'OTHERS'],
        count: 2000,
        offset: 0,
        query: ''
      },
      query: {
        namespace
      }
    });
    return makeQuickFetchRequest({
      makeRequestFn: viewsRequest,
      requestName: quickFetchKey
    }).then(response => {
      Metrics.counter('fetch-views-success-v4').increment();
      const {
        DEFAULT,
        MINE,
        OTHERS
      } = response;
      const results = [...DEFAULT.results, ...MINE.results, ...OTHERS.results].map(salesView => convertSalesViewToView({
        namespace,
        salesView
      }));
      return {
        hasMore: false,
        offset: results.length,
        total: results.length,
        results
      };
    }).catch(error => {
      Metrics.counter('fetch-views-failure-v4').increment();
      throw error;
    });
  }
});
export function useUpdateViewsCache() {
  const client = useDataFetchingClient();
  return useCallback(updatedFn => {
    client.cache.modify({
      fields: {
        [GET_VIEWS_FIELD_NAME]: updatedFn
      }
    });
  }, [client.cache]);
}
export default function useFetchViews() {
  const objectTypeId = useObjectTypeId();
  const namespace = useViewsNamespace();
  const hasViews = useHasViews();
  const {
    data: pinnedViewsData,
    loading: loadingPinnedViews
  } = useQuery(GET_PINNED_VIEWS, {
    variables: {
      namespace,
      objectType: objectTypeId
    },
    skip: !hasViews
  });
  const {
    data: viewsData,
    loading: loadingViews
  } = useQuery(GET_VIEWS, {
    variables: {
      namespace,
      objectType: objectTypeId
    },
    skip: !hasViews
  });
  const value = useMemo(() => {
    if (loadingPinnedViews || loadingViews) {
      return {
        loading: true,
        pinnedViews: [],
        views: []
      };
    }
    return {
      loading: false,
      pinnedViews: pinnedViewsData && GET_PINNED_VIEWS_FIELD_NAME in pinnedViewsData ? pinnedViewsData.getPinnedViews.results : [],
      views: viewsData && GET_VIEWS_FIELD_NAME in viewsData ? viewsData.getViews.results : []
    };
  }, [loadingPinnedViews, loadingViews, pinnedViewsData, viewsData]);
  return value;
}