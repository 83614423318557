/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable promise/catch-or-return */

import { useState } from 'react';
import usersApi from '../data/usersApi';
export const useUsersByScope = scope => {
  const [state, setState] = useState({
    users: [],
    requestSent: false
  });
  const fetchUsers = () => {
    if (state.requestSent) {
      return;
    }
    setState(prevState => Object.assign({}, prevState, {
      requestSent: true
    }));
    usersApi.fetchUsersByScope(scope).then(users => {
      setState(prevState => Object.assign({}, prevState, {
        users
      }));
    });
  };
  return {
    users: state.users,
    fetchUsers
  };
};