import { Map as ImmutableMap, Record } from 'immutable';
const isLoading = state => state.get('loading') === true;
const isErrored = state => state.get('error') === true;
const getErrorStatus = state => state.get('errorStatus');
const getErrorType = state => state.get('errorType');
const getErrorTokens = state => state.get('errorTokens');
const isDirty = state => state.get('dirty') === true;
const hasEverLoaded = state => state.get('lastLoaded') !== null;
const getData = state => state.get('data');
const internalStateRecord = Record({
  lastLoaded: null,
  loading: false,
  error: false,
  errorStatus: null,
  errorType: null,
  errorTokens: null,
  dirty: false,
  data: ImmutableMap({})
});
const modelDefaults = {
  _internalState: null
};
class ModelRecord extends Record(modelDefaults) {
  static from(json) {
    return new ModelRecord(json);
  }
  isLoading() {
    return isLoading(this._internalState);
  }
  isErrored() {
    return isErrored(this._internalState);
  }
  getErrorStatus() {
    return getErrorStatus(this._internalState);
  }
  getErrorType() {
    return getErrorType(this._internalState);
  }
  getErrorTokens() {
    return getErrorTokens(this._internalState);
  }
  errorTokens() {
    return getErrorTokens(this._internalState);
  }
  isDirty() {
    return isDirty(this._internalState);
  }
  hasEverLoaded() {
    return hasEverLoaded(this._internalState);
  }
  getData() {
    return getData(this._internalState);
  }
}
export function createModel(state) {
  return ModelRecord.from({
    _internalState: internalStateRecord(state)
  });
}