'use es6';

import { DISMISS_ZERO_STATE, REHYDRATE_FROM_USER_SETTINGS } from 'EmailData/actions/actionTypes';
const initialState = false;
export default function dashboardZeroStateComplete(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE_FROM_USER_SETTINGS:
      if (action.payload.has('dashboardZeroStateComplete')) {
        return action.payload.get('dashboardZeroStateComplete');
      }
      return state;
    case DISMISS_ZERO_STATE:
      return true;
    default:
      return state;
  }
}