import { useQuery, registerQuery } from 'data-fetching-client';
import http from 'hub-http/clients/apiClient';
import { useMemo } from 'react';
const GET_TEAMS = registerQuery({
  fieldName: 'emailTeams',
  args: ['canViewAllAssets'],
  fetcher({
    canViewAllAssets
  }) {
    if (canViewAllAssets) {
      return http.get(`app-users/v1/teams/hierarchy`);
    }
    return http.get(`app-users/v1/teams/user/all`, {
      query: {
        includeChildTeamMembers: true,
        includeHierarchy: true
      }
    });
  }
});
export default function useFetchTeams(canViewAllAssets) {
  const {
    data,
    error,
    loading
  } = useQuery(GET_TEAMS, {
    variables: {
      canViewAllAssets
    }
  });
  const teams = useMemo(() => {
    if (loading || error || !data || !data.emailTeams) return [];
    return data.emailTeams;
  }, [data, error, loading]);
  return {
    teams,
    fetchTeamsLoading: loading
  };
}