import { keyMirror } from 'email-health-ui-lib/shared/utils/object';
import { CANDY_APPLE, CHART_YELLOW, EERIE, OZ, SORBET } from 'HubStyleTokens/colors';
export const HEALTH_METRICS = {
  OPEN: 'open',
  CLICK_THROUGH: 'clickToOpen',
  HARD_BOUNCE: 'hardBounce',
  UNSUBSCRIBE: 'unsubscribe'
};
export const CONDITIONAL_HEALTH_METRICS = {
  SPAM_REPORT: 'spamReport'
};
export const OVER_TIME = 'overTime';
export const HEALTH_BUCKETS = keyMirror({
  NOT_ENOUGH_DATA: null,
  POOR: null,
  NEEDS_WORK: null,
  OKAY: null,
  GOOD: null,
  VERY_GOOD: null,
  EXCELLENT: null
});
export const HEALTH_METRICS_PRECISION_MAP = {
  [HEALTH_METRICS.OPEN]: 1,
  [HEALTH_METRICS.CLICK_THROUGH]: 1,
  [HEALTH_METRICS.HARD_BOUNCE]: 2,
  [HEALTH_METRICS.UNSUBSCRIBE]: 2
};
export const HEALTH_METRICS_LIST = [HEALTH_METRICS.OPEN, HEALTH_METRICS.CLICK_THROUGH, HEALTH_METRICS.HARD_BOUNCE, HEALTH_METRICS.UNSUBSCRIBE];
export const MINIMUM_SENT_COUNT_REQUIRED_FOR_HS = {
  DEFAULT: 400,
  FILTER: 250
};
export const HEALTH_BUCKET_COLOR_MAP = {
  NOT_ENOUGH_DATA: EERIE,
  POOR: CANDY_APPLE,
  NEEDS_WORK: SORBET,
  OKAY: CHART_YELLOW,
  GOOD: OZ,
  VERY_GOOD: OZ,
  EXCELLENT: OZ
};
export const DEFAULT_BREAKDOWN = OVER_TIME;
export const HEALTH_TYPES = keyMirror({
  NOT_ENOUGH_DATA: null,
  GOOD: null,
  OK: null,
  BAD: null
});
export const DATE_TYPES = keyMirror({
  END: null,
  START: null,
  LAST_UPDATE: null,
  NEXT_UPDATE: null
});
export const TRACKING_INTERACTION_TYPES = keyMirror({
  BREAKDOWN_PAGE_CHANGE: null,
  CLEAR_FILTER: null,
  LINK_CLICK: null,
  OVERALL_PAGE_CHANGE: null,
  OVER_TIME_TOOLTIP_VIEW: null,
  SELECT_FILTER: null,
  SET_FILTER: null,
  RECOMMENDATION_CLICK: null,
  INDUSTRY_BANNER_SET: null,
  INDUSTRY_BANNER_DISMISS: null,
  TOGGLE_HEALTH_SCORE_TYPE: null,
  LOAD_HEALTH_SCORE_TYPE_SELECTION: null,
  LOAD_HEALTH_TOOL: null
});
export const HEALTH_STATUS = keyMirror({
  SCORE_AVAILABLE: null,
  SCORE_NOT_AVAILABLE: null
});
export const HEALTH_SCORE_TYPES = keyMirror({
  GLOBAL: null,
  INDUSTRY: null
});