import { getPathFromFolder } from 'email-management-lib/refactor/utils/folderUtils';
import { Map as ImmutableMap, Record as ImmutableRecord, List } from 'immutable';
const defaults = {
  id: null,
  items: List(),
  name: '',
  count: 0,
  childFolders: ImmutableMap(),
  parentFolderId: 0
};
class Folder extends ImmutableRecord(defaults) {
  static from(json) {
    return new Folder(json);
  }
  getPath(id) {
    const folderPath = this.getFolderPath(id);
    return folderPath.split('/').join('/childFolders/');
  }
  getFolderPath(id) {
    return getPathFromFolder(this, id);
  }
}
export default Folder;