'use es6';

import { getIsHubSpotter, getIsPartner } from 'EmailData/selectors/user';
import { createSelector } from 'reselect';
const hasCompletedZeroStateSelector = state => state.get('dashboardZeroStateComplete', false);
export const selectIsInNewUserState = createSelector([hasCompletedZeroStateSelector, getIsHubSpotter, getIsPartner], (hasCompletedZeroState, isHubspotter, isPartner) => {
  if (hasCompletedZeroState) {
    return false;
  }

  // Is hubspotter & partner is to ensure jita users and partners don't get
  // hit with the zero state on a portal with existing emails.
  return !isHubspotter && !isPartner;
});