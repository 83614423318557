import I18n from 'I18n';
import isValidI18nKey from 'I18n/utils/isValidI18nKey';
import Raven from 'raven-js';
import { UIAUMetrics } from './metricUtils';
const createOwnedProductString = parsedProducts => {
  const translatedProducts = [];
  for (const product of parsedProducts) {
    const productLanguageKey = `upgrades.products.${product}`;
    if (isValidI18nKey(productLanguageKey)) {
      translatedProducts.push(I18n.text(productLanguageKey));
    }
  }
  return translatedProducts.join(', ');
};
const SELECTABLE_SEAT_TYPES = ['core', 'sales', 'service'];
export const getSeatSelectOptions = (seatInfoResponse, ownedHubs) => {
  const seatSelectOptions = [];
  for (const seatInfo of seatInfoResponse) {
    const [seatType, tier] = seatInfo.seatName.split('-');
    if (seatInfo.maxAssignableSeats && seatInfo.maxAssignableSeats > 0 && SELECTABLE_SEAT_TYPES.includes(seatType)) {
      const seatTypeTitleKey = `upgrades.requestSeatModal.seatInputSelect.${seatType}.title`;
      const seatTypeSubtitleKey = `upgrades.requestSeatModal.seatInputSelect.${seatType}.subtitle`;
      const seatLevelKey = `upgrades.productCatalog.tableHeader.${tier === 'pro' ? 'professional' : tier}`;
      if (isValidI18nKey(seatTypeTitleKey) && isValidI18nKey(seatTypeSubtitleKey)) {
        seatSelectOptions.push({
          text: I18n.text(seatTypeTitleKey),
          value: seatInfo.seatName,
          help: I18n.text(seatTypeSubtitleKey, {
            ownedHubs: createOwnedProductString(ownedHubs),
            seatLevel: isValidI18nKey(seatLevelKey) ? I18n.text(seatLevelKey) : ''
          })
        });
      } else {
        UIAUMetrics.counter('getSeatSelectOptions-error', {
          seatType,
          tier
        });
        Raven.captureException(new Error(`getSeatSelectOptions: invalid I18n key for ${seatTypeTitleKey}`), {
          extra: {
            seatType,
            tier
          }
        });
      }
    }
  }
  return seatSelectOptions;
};