export function saveToSessionStorage(key, value) {
  try {
    return sessionStorage.setItem(key, value);
  } catch (_unused) {
    return null;
  }
}
export function readFromSessionStorage(key) {
  try {
    return sessionStorage.getItem(key);
  } catch (_unused2) {
    return null;
  }
}
export function getKeysFromSessionStorage() {
  try {
    return Object.keys(sessionStorage);
  } catch (_unused3) {
    return [];
  }
}