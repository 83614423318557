'use es6';

import { RECEIVE_MAILCHIMP_IMPORT_STATUS } from 'email-management-lib/refactor/actions/actionTypes';
const initialState = null;
function mailchimpImport(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_MAILCHIMP_IMPORT_STATUS:
      return action.payload;
    default:
      return state;
  }
}
export default mailchimpImport;