import { getFullUrl } from 'hubspot-url-utils';
// Appends abunch of <link rel="prefetch"> tags to the page. The idea is to use the
// idle time of the dashboard app to get a jump start on loading the heavy
// assets of the editor. The prefetches are given the lowest priority by the
// browser, so it hopefully doesn't interfere with the dashboard app.
// See https://developer.mozilla.org/en-US/docs/Web/HTTP/Link_prefetching_FAQ
export const setupDeferredPrefetchingOfEditorAssets = () => {
  // Doing this to avoid relative domains, don't want to get 404s
  // when developing locally
  setTimeout(() => {
    const domain = getFullUrl('app');
    const script = document.createElement('script');
    script.src = `${domain}/email/editor/prefetcher.js`;
    document.head.appendChild(script);
  }, 0);
};