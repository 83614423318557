import { getHttpClientAsLastParam } from 'ContentData/helpers/apiHelpers';
/**
 * GET app-users/v1/teams/hierarchy
 */
export function fetchAllTeams(...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('app-users/v1/teams/hierarchy');
}

// Support v1 function name
export const fetchTeams = fetchAllTeams;

/**
 * GET app-users/v1/teams/user/all
 */
export function fetchTeamsForUser(...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('app-users/v1/teams/user/all', {
    query: {
      includeChildTeamMembers: true,
      includeHierarchy: true
    }
  });
}