export const calculateAnimationRenderOffset = tipIndex => {
  const allRenderedTips = document.querySelectorAll('[data-coaching-tips-button].finished-animating');
  if (allRenderedTips.length === 0) {
    return tipIndex;
  }

  // Calculate how many of the tips that should have rendered before this one have already rendered
  let earlierTipsAlreadyRendered = 0;
  for (const renderedTip of allRenderedTips) {
    const coachingTipsButtonAttribute = renderedTip.getAttribute('data-coaching-tips-button') || '';
    const matchedRenderedTipIndex = coachingTipsButtonAttribute.match(/\d+/);
    if (matchedRenderedTipIndex && matchedRenderedTipIndex[0] && parseInt(matchedRenderedTipIndex[0], 10) < tipIndex) {
      earlierTipsAlreadyRendered++;
    }
  }
  return tipIndex - earlierTipsAlreadyRendered;
};