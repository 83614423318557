import isEmpty from 'hs-lodash/isEmpty';
import { parse, stringify } from 'hub-http/helpers/params';
import memoize from 'transmute/memoize';
import { getFullUrl } from 'hubspot-url-utils';
export function getAllQueryParams() {
  return parse(window.location.search.substring(1));
}
export function getQueryParam(key) {
  const queryParams = getAllQueryParams();
  return queryParams[key];
}
export function getCurrentPathname() {
  return window.location.pathname.replace(/\/email\/[0-9]+\//, '/');
}
export function renderQueryParams(query) {
  return isEmpty(query) ? '' : '?'.concat(stringify(query));
}
export const parseQueryParams = memoize(searchParams => {
  return parse(searchParams.replace(/^(\?)/, ''));
});
export function navigateTo(href, external) {
  const subdomain = window.location.hostname.split('.')[0].split('-')[0];
  const url = `${getFullUrl(subdomain)}${href}`;
  if (external) {
    window.open(url, '_blank', 'noopener');
  } else {
    window.location.href = url;
  }
}
export function isDetailsPathActive() {
  const dashboardPathRegex = /\/details(\/[0-9]+)\/\w+\\?/;
  return dashboardPathRegex.test(getCurrentPathname());
}
export function isDashboardPathActive() {
  const dashboardPathRegex = /\/manage\/state\/(all|draft|scheduled|sent|archived)(\/[0-9]+)?/;
  return dashboardPathRegex.test(getCurrentPathname());
}
export function isFolderPathActive() {
  const folderPathRegex = /\/manage\/folder\/[0-9]+(\/[0-9]+)?/;
  return folderPathRegex.test(getCurrentPathname());
}
function addSlash(uri) {
  return uri.charAt(0) === '/' ? '' : '/';
}
export function buildKbURL(url) {
  return url.includes('knowledge.hubspot.com') ? url : `https://knowledge.hubspot.com${addSlash(url)}${url}`;
}