/* eslint-disable @typescript-eslint/no-floating-promises */

import { useCallback, useMemo, useRef, useState } from 'react';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
import { defaultPlaceholder, loadingPlaceholder } from '../components/campaignSelect/helperComponents';
import { toCampaignOption } from '../utils/campaignSelect';
import useGetCampaignSelectChangeHandler from './useGetCampaignSelectChangeHandler';
import useLoadInitialCampaignSelectOption from './useLoadInitialCampaignSelectOption';
import useLoadCampaignSelectOptions from './useLoadCampaignSelectOptions';
export default function useCampaignSelect({
  disabledOptionsByName,
  multi = false,
  numberOfRecentlyUsed,
  onCampaignCreateRef,
  onChange,
  onCurrentValueNotFound,
  pageLimit,
  placeholder,
  recentlyUsedCacheGracePeriod,
  value,
  applicationId
}) {
  const alertStore = useRef(FloatingAlertStore.newFloatingAlertStore());
  const [showCreatePanel, setShowCreatePanel] = useState(false);
  const [showEmptyState, setShowEmptyState] = useState(false);
  const [hasOpened, setHasOpened] = useState(false);
  const [createdCampaigns, setCreatedCampaigns] = useState([]);
  const [valueFromAttributes, setValueFromAttributes] = useState('');
  const [hasDismissedPopover, setHasDismissedPopover] = useState(false);
  const [hasLoadedFullOptions, setHasLoadedFullOptions] = useState(false);

  // Using previous value as this is set to an empty string once
  // the user interacts with the popover
  const isValueSameAsValueFromAttributes = useMemo(() => Boolean(value && valueFromAttributes && value === valueFromAttributes), [valueFromAttributes, value]);
  const {
    initialOptions,
    loading: initialOptionsLoading
  } = useLoadInitialCampaignSelectOption({
    value,
    hasOpened,
    multi,
    onCurrentValueNotFound,
    skip: isValueSameAsValueFromAttributes,
    onChange
  });
  const {
    initialOptions: attributeOptions,
    loading: attributeOptionsLoading
  } = useLoadInitialCampaignSelectOption({
    value: valueFromAttributes,
    hasOpened,
    multi,
    onCurrentValueNotFound,
    skip: isValueSameAsValueFromAttributes
  });
  const loadOptions = useLoadCampaignSelectOptions({
    applicationId,
    numberOfRecentlyUsed,
    onCurrentValueNotFound,
    pageLimit,
    recentlyUsedCacheGracePeriod,
    value,
    createdCampaigns,
    multi,
    disabledOptionsByName,
    setShowEmptyState,
    setHasLoadedFullOptions
  });

  // Only show loading if not multi as the UISelect component has loading built in for multi
  const shouldShowLoading = useMemo(() => !multi && (initialOptionsLoading || attributeOptionsLoading), [multi, initialOptionsLoading, attributeOptionsLoading]);
  const options = useMemo(() => {
    return !hasDismissedPopover && valueFromAttributes ? attributeOptions : initialOptions;
  }, [valueFromAttributes, hasDismissedPopover, attributeOptions, initialOptions]);
  const valueToDisplay = useMemo(() => {
    if (shouldShowLoading) {
      return '';
    }
    return !hasDismissedPopover && valueFromAttributes ? valueFromAttributes : value;
  }, [shouldShowLoading, hasDismissedPopover, valueFromAttributes, value]);
  const placeholderToDisplay = useMemo(() => shouldShowLoading ? loadingPlaceholder : placeholder || defaultPlaceholder, [shouldShowLoading, placeholder]);
  const handleChange = useGetCampaignSelectChangeHandler({
    initialOptions: options,
    onChange,
    multi
  });
  const handleSelectedOptionChange = useCallback(({
    target: {
      value: optionOrOptions
    }
  }) => handleChange(optionOrOptions), [handleChange]);
  const onCampaignCreated = useCallback(newCampaign => {
    if (!newCampaign) {
      return;
    }
    const newCampaignOption = toCampaignOption(newCampaign);
    setCreatedCampaigns(currentCreatedCampaigns => [...currentCreatedCampaigns, newCampaignOption]);
    handleChange(newCampaignOption);
    setShowCreatePanel(false);
    setShowEmptyState(false);
  }, [handleChange, setShowCreatePanel]);
  if (onCampaignCreateRef) {
    onCampaignCreateRef.current = onCampaignCreated;
  }
  return {
    alertStore: alertStore.current,
    createdCampaigns,
    handleCampaignAutoSelectChange: handleChange,
    handleSelectedOptionChange,
    hasDismissedPopover,
    hasOpened,
    loadOptions,
    options,
    onCampaignCreated,
    placeholderToDisplay,
    setHasDismissedPopover,
    setHasOpened,
    setShowCreatePanel,
    setValueFromAttributes,
    showCreatePanel,
    showEmptyState,
    valueToDisplay,
    hasLoadedFullOptions
  };
}