import { Record as ImmutableRecord, Map as ImmutableMap } from 'immutable';
import StatisticsCounters from './StatisticsCounters';
import StatisticsDeviceBreakdown from './StatisticsDeviceBreakdown';
import StatisticsRatios from './StatisticsRatios';
import StatisticsQualifiers from './StatisticsQualifiers';
import { mergeDeep } from 'EmailData/utils/objectUtil';
const statsDefaults = {
  counters: StatisticsCounters.from({}),
  deviceBreakdown: StatisticsDeviceBreakdown.from({}),
  dropEvents: ImmutableMap({}),
  ratios: StatisticsRatios.from({}),
  qualifiers: StatisticsQualifiers.from({})
};
class StatisticsData extends ImmutableRecord(statsDefaults) {
  static from({
    counters,
    deviceBreakdown,
    dropEvents,
    ratios,
    qualifiers,
    qualifierStats
  } = {}) {
    return new StatisticsData({
      counters: StatisticsCounters.from(counters || {}),
      deviceBreakdown: StatisticsDeviceBreakdown.from(deviceBreakdown || {}),
      dropEvents: ImmutableMap(dropEvents || {}),
      ratios: StatisticsRatios.from(ratios || {}),
      qualifiers: StatisticsQualifiers.from(qualifiers || qualifierStats || {})
    });
  }
  hasStatistics() {
    return this.counters.selected > 0;
  }
  // @ts-expect-error merge type for base class does not match
  merge(otherStats = StatisticsData.from({})) {
    function merger(first, second) {
      if (typeof first === 'number' && typeof second === 'number') {
        return first + second;
      }
      if (first instanceof ImmutableRecord && second instanceof ImmutableRecord) {
        return first.mergeWith(merger, second);
      }
      if (typeof first === 'object') {
        // could potentially be array or func but not expected here
        const merged = mergeDeep({}, first);
        return mergeDeep(merged, second);
      }
      return undefined;
    }
    const mergedCounters = this.counters.mergeWith(merger, otherStats.counters);
    const mergedDeviceBreakdowns = this.deviceBreakdown.mergeWith(merger, otherStats.deviceBreakdown);
    const mergedDropEvents = this.dropEvents.mergeWith(merger, otherStats.dropEvents);
    const mergedStatisticsQualifiers = this.qualifiers.mergeWith(merger, otherStats.qualifiers);
    const mergedRatios = StatisticsRatios.fromCounters(mergedCounters);
    return new StatisticsData({
      counters: mergedCounters,
      deviceBreakdown: mergedDeviceBreakdowns,
      dropEvents: mergedDropEvents,
      ratios: mergedRatios,
      qualifiers: mergedStatisticsQualifiers
    });
  }
}
export default StatisticsData;