'use es6';

import { Record } from 'immutable';
const defaults = {
  guid: null,
  portalId: null,
  appId: null,
  emailCampaignId: null,
  message: '',
  createdAt: null,
  permanent: false,
  requestedBy: '',
  thresholdType: '',
  threshold: null,
  thresholdViolation: null,
  sentCount: 0
};
class Suspension extends Record(defaults) {
  static from(json) {
    return new Suspension(json);
  }
}
export default Suspension;