'use es6';

import { Record, List } from 'immutable';
const defaults = {
  entries: List(),
  feedList: List(),
  feedResultStatus: '',
  feedUrl: '',
  hubspotContentGroupId: null
};
class RssFeedValidation extends Record(defaults) {
  static from(json) {
    return new RssFeedValidation(json);
  }
}
export default RssFeedValidation;