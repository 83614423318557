// Mirror of: https://git.hubteam.com/HubSpot/COSEmail/blob/master/COSEmailModel/src/main/java/com/hubspot/cosemail/model/CombinedEmailSendState.java

const COMBINED_EMAIL_SEND_STATE = Object.freeze({
  CREATED: 'CREATED',
  WAITING: 'WAITING',
  INITIALIZING: 'INITIALIZING',
  PROCESSING: 'PROCESSING',
  DONE: 'DONE',
  ERROR_ABANDONED: 'ERROR_ABANDONED',
  ERROR_WILL_RETRY: 'ERROR_WILL_RETRY',
  ERROR_WILL_NOT_RETRY: 'ERROR_WILL_NOT_RETRY',
  WAITING_ON_STATIC_LIST: 'WAITING_ON_STATIC_LIST',
  CANCELED: 'CANCELED',
  CANCELED_ABUSE: 'CANCELED_ABUSE',
  CANCELED_FORCIBLY: 'CANCELED_FORCIBLY',
  ERROR_UNAUTHORIZED_SEND: 'ERROR_UNAUTHORIZED_SEND'
});
export default COMBINED_EMAIL_SEND_STATE;