/* eslint no-console: 0 */

//  Based in https://git.hubteam.com/HubSpot/reporting/blob/a2bd3fb7e4d54f4f294d517b98c7f272ec171e1e/reporting-data/static/js/lib/storage.js

function parse(item) {
  try {
    return JSON.parse(item);
  } catch (err) {
    return null;
  }
}
function getRaw(key) {
  try {
    const item = localStorage.getItem(key);
    if (typeof item === 'string') {
      return parse(item);
    }
    return null;
  } catch (err) {
    console.warn(`storage: failed to get '${key}' from localStorage`);
    return null;
  }
}
function setRaw(key, value) {
  try {
    const item = JSON.stringify(value);
    localStorage.setItem(key, item);
  } catch (err) {
    console.warn(`storage: failed to set '${value}' as '${key}' in localStorage`);
  }
}
const removeRaw = key => {
  try {
    localStorage.removeItem(key);
  } catch (err) {
    console.warn(`storage: failed to remove '${key}' in localStorage`);
  }
};
const getFullKey = (portalId, group, key) => `${group}:${portalId}:${key}`;
export function get(portalId, group, key) {
  const fullKey = getFullKey(portalId, group, key);
  const valueObj = getRaw(fullKey);
  if (valueObj === null || (valueObj && valueObj.value) === null) {
    return null;
  }
  if (valueObj && valueObj.expiration && valueObj.expiration <= new Date().getTime()) {
    removeRaw(fullKey);
    return null;
  }
  return valueObj.value;
}
export function set(portalId, group, key, value, expirationMs = 0) {
  const fullKey = getFullKey(portalId, group, key);
  if (value === null) {
    console.warn(`storage: value for '${fullKey}' in localStorage was null`);
    return;
  }
  const nowMillis = new Date().getTime();
  setRaw(fullKey, {
    created: nowMillis,
    expiration: expirationMs === 0 ? null : expirationMs + nowMillis,
    value
  });
}
export const has = (portalId, group, key) => {
  const value = get(portalId, group, key);
  return value !== null;
};
export const remove = (portalId, group, key) => {
  removeRaw(getFullKey(portalId, group, key));
};