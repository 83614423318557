import { useCallback } from 'react';
import { registerMutation, useMutation } from 'data-fetching-client';
import http from 'hub-http/clients/apiClient';
import useObjectTypeId from 'framework-listing-lib/internal/hooks/useObjectTypeId';
import { useCrmObjectType } from 'framework-listing-lib/internal/providers/CrmObjectTypeListingClientProvider';
const SAVE_CRM_OBJECTS_FIELD_NAME = 'saveCrmObjects';
const SAVE_CRM_OBJECTS = registerMutation({
  fieldName: SAVE_CRM_OBJECTS_FIELD_NAME,
  args: ['changes', 'objectTypeId'],
  fetcher({
    changes,
    objectTypeId
  }) {
    return http.put(`inbounddb-objects/v1/crm-objects/${objectTypeId}`, {
      data: Object.keys(changes).map(objectId => ({
        objectId,
        propertyValues: Object.entries(changes[objectId]).map(([name, value]) => ({
          name,
          value
        }))
      }))
    });
  }
});
export default function useSaveCrmObjects() {
  const objectTypeId = useObjectTypeId();
  const [saveCrmObjectsMutation] = useMutation(SAVE_CRM_OBJECTS);
  const {
    inlineEditProps
  } = useCrmObjectType();
  return useCallback(changes => {
    /**
     * Use custom endpoint for teams that need to save their objects
     * in services other than the CRM.
     */
    if (inlineEditProps && inlineEditProps.onSave) {
      return inlineEditProps.onSave(changes);
    }
    return saveCrmObjectsMutation({
      variables: {
        changes,
        objectTypeId
      }
    });
  }, [inlineEditProps, objectTypeId, saveCrmObjectsMutation]);
}