import { EXPERIMENTS_REQUESTING, EXPERIMENTS_SUCCESSFUL } from './actionTypes';
import { treatmentsClient } from 'email-management-lib/refactor/api/ExperimentApi';
export const fetchExperiments = () => dispatch => {
  dispatch({
    type: EXPERIMENTS_REQUESTING
  });
  return treatmentsClient.resolve(responseTreatments => {
    if (responseTreatments) dispatch({
      type: EXPERIMENTS_SUCCESSFUL,
      payload: responseTreatments
    });
  });
};
export const logTreatmentExposure = key => {
  treatmentsClient.logExposure(key);
};