/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */

import { useState, useEffect } from 'react';
import { callIfPossible } from 'UIComponents/core/Functions';
import { getCrmSearchCampaignAsCampaignSelectOption } from '../data/CampaignDao';
import SyntheticEvent from 'UIComponents/core/SyntheticEvent';
export default function useLoadInitialCampaignSelectOption({
  value,
  hasOpened,
  multi,
  onCurrentValueNotFound,
  skip,
  onChange
}) {
  const [initialOptions, setInitialOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const hasValue = multi && Array.isArray(value) ? value.length > 0 : value;
    if (skip || hasOpened || !hasValue) {
      return;
    }
    setLoading(true);
    getCrmSearchCampaignAsCampaignSelectOption(value).then(results => {
      if (results.length > 0) {
        setInitialOptions(results);
      } else {
        if (onChange) {
          // Reset the selection when no value is found.
          // This typically means the campaign has been deleted,
          // but is still referenced from an external object.
          onChange(SyntheticEvent(''), undefined);
        }
        callIfPossible(onCurrentValueNotFound);
      }
    }).finally(() => {
      setLoading(false);
    });
  }, [skip, hasOpened, value, multi, onCurrentValueNotFound, onChange]);
  return {
    initialOptions,
    loading
  };
}