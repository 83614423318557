'use es6';

import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { FETCH_EMAIL_DETAILS_RECIPIENTS, FETCH_EMAIL_DETAILS_RECIPIENTS_REQUEST, SELECT_SEARCH_RECIPIENT, SET_RECIPIENT_SEARCH_TERM } from 'email-management-lib/refactor/actions/actionTypes';
import RecipientsList from 'email-management-lib/refactor/models/details/RecipientsList';
const initialState = RecipientsList.from({});
function recipients(state = initialState, action) {
  switch (action.type) {
    case FETCH_EMAIL_DETAILS_RECIPIENTS_REQUEST:
      return initialState;
    case FETCH_EMAIL_DETAILS_RECIPIENTS:
      if (action.error) {
        return state;
      }
      return state.merge(action.payload);
    case SELECT_SEARCH_RECIPIENT:
      return state.set('isSearchView', true);
    case SET_RECIPIENT_SEARCH_TERM:
      if (action.payload.length === 0) {
        return state.set('isSearchView', false).set('searchTerm', '');
      }
      return state.set('searchTerm', action.payload);
    default:
      return state;
  }
}
export default asyncResource(recipients, {
  requestActions: [FETCH_EMAIL_DETAILS_RECIPIENTS_REQUEST],
  responseActions: [FETCH_EMAIL_DETAILS_RECIPIENTS],
  invalidatingActions: [],
  customActions: [SELECT_SEARCH_RECIPIENT, SET_RECIPIENT_SEARCH_TERM]
});