import getLang from 'I18n/utils/getLang';
import getLangLocale from 'I18n/utils/getLangLocale';
import getCountryCodeFromLocale from 'I18n/utils/getCountryCodeFromLocale';
import salesPhoneNumbers, { DEFAULT_PHONE_NUMBER } from '../constants/salesPhoneNumbers';
export default function () {
  const lang = getLang();
  const locale = getLangLocale();
  if (salesPhoneNumbers[locale]) {
    const countryCodeFromLocale = getCountryCodeFromLocale();
    const countryCode = typeof countryCodeFromLocale === 'string' ? countryCodeFromLocale.toUpperCase() : null;
    return salesPhoneNumbers[locale][lang] ? {
      phoneNumber: salesPhoneNumbers[locale][lang],
      countryCode
    } : {
      phoneNumber: salesPhoneNumbers[locale].en,
      countryCode
    };
  }
  return {
    phoneNumber: DEFAULT_PHONE_NUMBER,
    countryCode: 'US'
  };
}