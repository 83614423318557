import { createEmailCampaignReferenceResolver } from './custom/EmailCampaignReferenceResolver';
import { createImportNameReferenceResolver } from './custom/ImportNameReferenceResolver';
import { createIntegrationNameReferenceResolver } from './custom/IntegrationNameReferenceResolver';
import { createMarketingReasonReferenceResolver } from './custom/MarketingReasonReferenceResolver';
import { createSalesforceCampaignReferenceResolver } from './custom/SalesforceCampaignReferenceResolver';
import { createPipelineStageInPipelineReferenceResolver } from './custom/PipelineStageInPipelineReferenceResolver';
import { EMAIL_CAMPAIGN, IMPORT_NAME, INTEGRATION_NAME, MARKETING_REASON, SALESFORCE_CAMPAIGN, PIPELINE_STAGE_IN_PIPELINE } from './CustomResolverReferenceTypes';
const cache = {
  [EMAIL_CAMPAIGN]: {},
  [IMPORT_NAME]: {},
  [INTEGRATION_NAME]: {},
  [MARKETING_REASON]: {},
  [SALESFORCE_CAMPAIGN]: {},
  [PIPELINE_STAGE_IN_PIPELINE]: {}
};
const toCacheKey = (referenceType, objectTypeId, options) => {
  switch (referenceType) {
    case PIPELINE_STAGE_IN_PIPELINE:
      {
        const {
          pipelineId
        } = options;
        return `${objectTypeId}/${pipelineId}`;
      }
    default:
      return objectTypeId;
  }
};
export const isCustomReferenceType = referenceType => [EMAIL_CAMPAIGN, IMPORT_NAME, INTEGRATION_NAME, MARKETING_REASON, SALESFORCE_CAMPAIGN, PIPELINE_STAGE_IN_PIPELINE].includes(referenceType);
const createCustomResolver = (referenceType, objectTypeId, options) => {
  const {
    httpClient
  } = options;
  if (referenceType === EMAIL_CAMPAIGN) {
    return createEmailCampaignReferenceResolver({
      httpClient
    });
  } else if (referenceType === IMPORT_NAME) {
    return createImportNameReferenceResolver({
      httpClient
    });
  } else if (referenceType === INTEGRATION_NAME) {
    return createIntegrationNameReferenceResolver({
      httpClient
    });
  } else if (referenceType === MARKETING_REASON) {
    return createMarketingReasonReferenceResolver({
      httpClient
    });
  } else if (referenceType === SALESFORCE_CAMPAIGN) {
    return createSalesforceCampaignReferenceResolver({
      httpClient
    });
  } else if (referenceType === PIPELINE_STAGE_IN_PIPELINE) {
    const pipelineStageInPipelineOptions = options;
    return createPipelineStageInPipelineReferenceResolver({
      objectTypeId,
      pipelineId: pipelineStageInPipelineOptions.pipelineId,
      httpClient
    });
  }
  throw Error('Attempted to create custom resovler for reference type that is not supported');
};

/**
 * These are resolvers that are not yet supported on the BE but CDP needs to
 * resolver property values, we expect them to be moved to the BE over time so
 * more should not be added unless absolutely necessary. This function should
 * never be used outside of customer-data-properties as the resolvers it creates
 * are specifically optimized for properties and not meant to be used outside
 * the context of this library.
 */
export const createCachedCustomResolver = (referenceType, objectTypeId, options) => {
  if (!cache[referenceType]) {
    cache[referenceType] = {};
  }
  const cacheKey = toCacheKey(referenceType, objectTypeId, options);
  if (cache[referenceType][cacheKey]) {
    return cache[referenceType][cacheKey];
  }
  const resolver = createCustomResolver(referenceType, objectTypeId, options);
  cache[referenceType][cacheKey] = resolver;
  return resolver;
};

// exported for unit test usage only
export const _resetCache = () => {
  for (const referenceType of Object.keys(cache)) {
    cache[referenceType] = {};
  }
};