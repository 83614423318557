import { useRef } from 'react';
import isEqual from 'hs-lodash/isEqual';

/**
 * Makes a deep equality comparison for objects used as dependencies in useEffects.
 * useEffect does not play well with objects in the dependency array and useRef can help us maintain
 * the same value across renders without having to re-run useEffects for no reason.
 *
 * This hook is only meant to be used if you have an effect that runs multiple times and one of its dependencies is an object.
 */
export default function useDeepEqual(value) {
  const refValue = useRef(value);
  if (!isEqual(refValue.current, value)) {
    refValue.current = value;
  }
  return refValue.current;
}