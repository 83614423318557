'use es6';

import { Map as ImmutableMap } from 'immutable';
import { getEmailAuthSelectors } from 'EmailData/selectors/emailAuthSelectors';
import { createSendCreditsSelector } from 'EmailData/selectors/portalSendCreditsSelectors';
import { isImmutableMap } from 'EmailData/types/immutable';
export const getGatesAndScopesSelectors = getState => {
  const state = getState();

  //Condition on calling state shape
  if (isImmutableMap(state)) {
    // Considered called from ContentEmailUI
    const untypedGates = state.get('gates');
    const gates = typeof untypedGates === 'object' ? Object.keys(untypedGates).filter(key => untypedGates[key]) : untypedGates;
    return {
      getGates: () => gates,
      getScopes: () => state.get('user').get('scopes')
    };
  } else {
    // Considered called from ContentEditorUI
    return {
      getGates: () => state.auth.gates,
      getScopes: () => state.auth.user.scopes
    };
  }
};
export const getEmailAuthSelectorsFromState = getState => {
  const gatesAndScopes = getGatesAndScopesSelectors(getState);
  return getEmailAuthSelectors(gatesAndScopes.getGates, gatesAndScopes.getScopes);
};
export const getHasEmailAccessScope = getState => {
  return getEmailAuthSelectorsFromState(getState).getHasEmailAccess();
};
export const getHasSoftLimitedScope = getState => {
  return getEmailAuthSelectorsFromState(getState).getHasSoftLimitScope();
};
export const getHasCrmImportScope = getState => {
  return getEmailAuthSelectorsFromState(getState).getHasCrmImportScope();
};
export const getHasListsWriteScope = getState => {
  return getEmailAuthSelectorsFromState(getState).getHasListsWriteScope();
};
export const getHasListsReadScope = getState => {
  return getEmailAuthSelectorsFromState(getState).getHasListsReadScope();
};
export const getHasContactsWriteScope = getState => {
  return getEmailAuthSelectorsFromState(getState).getHasContactsWriteScope();
};
export const getHasEmailFatigueSuppressionAccess = getState => {
  return getEmailAuthSelectorsFromState(getState).getHasFatigueSuppressionAccess();
};
export const getHasStarterEmail = getState => {
  return getEmailAuthSelectorsFromState(getState).usingEmailStarter();
};
export const getHasFreeEmail = getState => {
  return getEmailAuthSelectorsFromState(getState).getHasFreeEmail();
};
export const getHasMarketableContactsAccess = getState => {
  return getEmailAuthSelectorsFromState(getState).getHasMarketableContactsAccess();
};
export const getRecipientsSubState = getState => {
  const state = getState();
  return typeof state.get === 'function' ? state.get('recipients') : state.recipients;
};
export const getTotalRecipientsCount = getState => {
  return getRecipientsSubState(getState).get('numToSendTo');
};
export const getAllRecipients = getState => {
  const recipients = getRecipientsSubState(getState);
  return {
    graymailEnabled: recipients.get('graymailEnabled'),
    fatigueSuppressionEnabled: recipients.get('fatigueSuppressionEnabled'),
    mailingListsIncluded: recipients.get('mailingListsIncluded'),
    mailingListsExcluded: recipients.get('mailingListsExcluded'),
    vidsIncluded: recipients.get('vidsIncluded'),
    vidsExcluded: recipients.get('vidsExcluded')
  };
};
export const portalSendCreditSelectors = createSendCreditsSelector();
export const getIncludedLists = state => state.getIn(['details', 'sendToMoreLists']).getData().get('includedLists');
export const getExcludedLists = state => state.getIn(['details', 'sendToMoreLists']).getData().get('excludedLists');
export const getAllListsFromSendToMore = state => {
  return state.getIn(['details', 'sendToMoreLists']).getData().get('lists') || ImmutableMap({});
};
export const getIncludedRecipients = state => {
  const includedContacts = state.getIn(['details', 'sendToMoreVids']).getData().get('includedContacts');
  const includedLists = getIncludedLists(state);
  return {
    includedContacts,
    includedLists
  };
};
export const getExcludedRecipients = state => {
  const excludedContacts = state.getIn(['details', 'sendToMoreVids']).getData().get('excludedContacts');
  const excludedLists = getExcludedLists(state);
  return {
    excludedContacts,
    excludedLists
  };
};