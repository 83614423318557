'use es6';

import { Map as ImmutableMap } from 'immutable';
import aysncResource from 'EmailData/reducers/helpers/asyncResource';
import { RECEIVE_SUBSCRIPTIONS, REQUEST_SUBSCRIPTIONS } from 'email-management-lib/refactor/actions/actionTypes';
const initialState = ImmutableMap({
  subscriptions: ImmutableMap({}),
  error: null
});
function subscriptions(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_SUBSCRIPTIONS:
      if (action.error !== true) {
        const formattedSubs = action.payload.reduce((subsAccum, subscription) => {
          const {
            subscriptionDefinition
          } = subscription;
          subsAccum.push([subscriptionDefinition.id, subscriptionDefinition]);
          return subsAccum;
        }, []);
        return ImmutableMap({
          subscriptions: ImmutableMap(formattedSubs),
          error: ''
        });
      }
      return ImmutableMap({
        subscriptions: ImmutableMap({}),
        error: action.payload.message
      });
    default:
      return state;
  }
}
export default aysncResource(subscriptions, {
  requestActions: [REQUEST_SUBSCRIPTIONS],
  responseActions: [RECEIVE_SUBSCRIPTIONS],
  invalidatingActions: []
});