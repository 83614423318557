import { stripDiacritics } from 'UIComponents/utils/Diacritics';
// The code in this file is only used in `createFetchAllReferenceResolver` so
// tests for it should be written in the test suite for that file.
const toFilterableString = string => stripDiacritics(string).toLowerCase();
const matchesQuery = (query, string) => {
  if (!string) {
    return false;
  }
  return string.includes(query);
};
export const filterReferences = (references, query) => {
  if (!query) {
    return references.filter(({
      archived
    }) => !archived);
  }
  const filterableQuery = toFilterableString(query);
  return references.filter(reference => {
    const {
      description,
      label,
      id,
      archived
    } = reference;
    const filterableId = id ? toFilterableString(id) : undefined;
    const filterableLabel = label ? toFilterableString(label) : undefined;
    const filterableDescription = description ? toFilterableString(description) : undefined;
    return !archived && (matchesQuery(filterableQuery, filterableId) || matchesQuery(filterableQuery, filterableLabel) || matchesQuery(filterableQuery, filterableDescription));
  });
};