'use es6';

import { Map as ImmutableMap } from 'immutable';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { FETCH_RELIABLE_OPEN_START, FETCH_RELIABLE_OPEN_SUCCESS, FETCH_RELIABLE_OPEN_FAIL } from 'email-management-lib/refactor/actions/actionTypes';
const initialState = ImmutableMap({
  isLoading: true,
  totalReliableOpens: 0,
  unique: 0,
  engagementPercentage: 0
});
function reliableOpen(state = initialState, action) {
  switch (action.type) {
    case FETCH_RELIABLE_OPEN_START:
      return state.set('isLoading', true);
    case FETCH_RELIABLE_OPEN_SUCCESS:
      return state.set('isLoading', false).set('totalReliableOpens', action.payload.totalReliableOpens).set('engagementPercentage', action.payload.engagementPercentage).set('unique', action.payload.uniqueReliableOpens);
    case FETCH_RELIABLE_OPEN_FAIL:
      return state.set('isLoading', false);
    default:
      return state;
  }
}
export default asyncResource(reliableOpen, {
  requestActions: [FETCH_RELIABLE_OPEN_START],
  responseActions: [FETCH_RELIABLE_OPEN_SUCCESS, FETCH_RELIABLE_OPEN_FAIL],
  invalidatingActions: []
});