import { getFrameworkDataSchemasClient } from 'framework-data-schema-resolvers';
import Raven from 'raven-js';
export const fetchPropertyExtensionsMetadata = (objectTypeId, httpClient) => {
  return getFrameworkDataSchemasClient({
    httpClient
  }).then(client => client.propertyMetadata.get({
    appSettingName: 'crmPropertyExtensions',
    frameworkTypeIdentifier: objectTypeId
  }).catch(err => {
    Raven.captureException(err, {
      extra: {
        apiError: 'PROPERTY_EXTENSIONS_METADATA_FETCH_FAILED',
        objectTypeId
      }
    });
    throw err;
  }));
};