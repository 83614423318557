import { useCrmObjectType } from 'framework-listing-lib/internal/providers/CrmObjectTypeListingClientProvider';
export default function useFetcher() {
  const {
    fetcher
  } = useCrmObjectType();
  return fetcher;
}
export function useHasCustomFetcher() {
  const fetcher = useFetcher();
  return Boolean(fetcher);
}