'use es6';

import { Record } from 'immutable';
const defaults = {
  id: null,
  name: '',
  enabled: false,
  deleted: false,
  campaignId: null,
  campaignIds: null,
  workflowType: null,
  uuid: null,
  sourceApp: null,
  updatedAt: null,
  updatedBy: null,
  showLinksAsExternal: true,
  portalId: null,
  triggeringObjectType: null
};
class Workflow extends Record(defaults) {
  static from(json) {
    return new Workflow(json);
  }
}
export default Workflow;