import { getQueryParam, renderQueryParams } from 'EmailData/utils/url';
import PortalIdParser from 'PortalIdParser';
import { APP_PATHS } from 'EmailData/constants/app';
import { getCurrentPathname } from 'EmailData/utils/url';
const buildSelectedValue = (value, parseId) => {
  return Array.isArray(value) ? value.map(id => ({
    id: parseId ? parseInt(id, 10) : id,
    type: 'TEAM'
  })) : [{
    id: parseId ? parseInt(value, 10) : value,
    type: 'TEAM'
  }];
};
export const buildSelectedUserAndTeams = queryParams => {
  let selectedUsersAndTeams = [];
  if (queryParams.team) {
    selectedUsersAndTeams = selectedUsersAndTeams.concat(buildSelectedValue(queryParams.team, true));
  }
  if (queryParams.custom) {
    selectedUsersAndTeams = selectedUsersAndTeams.concat(buildSelectedValue(queryParams.custom, false));
  }
  if (queryParams.user) {
    selectedUsersAndTeams = selectedUsersAndTeams.concat(buildSelectedValue(queryParams.user, true));
  }
  return selectedUsersAndTeams;
};
export function generateDashboardUrl(dashboardUrlState) {
  if (dashboardUrlState.hasIn(['pathParams', 'folderId']) && Boolean(dashboardUrlState.getIn(['pathParams', 'folderId']))) {
    const folderId = dashboardUrlState.getIn(['pathParams', 'folderId']) || '';
    const queryParams = dashboardUrlState.get('queryParams').toJSON();
    return `/manage/folder/${folderId}${renderQueryParams(queryParams)}`;
  } else {
    return APP_PATHS.MANAGE_TAB;
  }
}
export const getArchivedFolderURL = () => {
  return window.location.href.replace(getCurrentPathname(), '/manage/state/archived');
};
export const getCrmContactsUrl = () => `/contacts/${PortalIdParser.get()}/contacts/list/view/all/`;
export const redirectTo = url => {
  window.location.href = url;
};
export const getViaContactEmptyState = () => {
  const viaParam = getQueryParam('via');
  const isViaContactEmptyState = viaParam && !Array.isArray(viaParam) && viaParam.includes('contact-empty-state');
  return isViaContactEmptyState;
};

// Checks that the requested page has the valid form as provided
// in the given Regex and no url parameters have been set.
function isValidEarlyUrl(validUrl, url, params) {
  return url.match(validUrl) !== null && params === '';
}
export function isValidManageUrl(url, params) {
  const validUrl = new RegExp('^/email(?:-manage)?/[0-9]+[/]?(?:/manage[/]?(?:/state[/]?(?:/all[/]?)?)?)?$');
  return isValidEarlyUrl(validUrl, url, params);
}