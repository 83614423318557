import { Record } from 'immutable';
import pickBy from 'hs-lodash/pickBy';
const defaults = {
  name: null,
  state: null,
  subcategory: null,
  variantOnly: null,
  convertToDd: null,
  cloneWithSmartContent: true,
  cloneWithCustomPlaintext: true,
  folderId: null,
  cloneWithNewBusinessUnitId: null,
  retainPermissionsAfterClone: true
};
class EmailCloneOptions extends Record(defaults) {
  static from(json) {
    return new EmailCloneOptions(json);
  }
  buildRequestObj() {
    if (this.name === undefined || this.name === null || this.name === '') {
      throw new Error('A name is required for a email clone request');
    }
    return pickBy({
      name: this.name,
      variant_only: this.variantOnly,
      convert_to_dd: this.convertToDd,
      state: this.state,
      sub_category: this.subcategory,
      clone_with_smart_content: this.cloneWithSmartContent,
      clone_with_custom_plaintext: this.cloneWithCustomPlaintext,
      folder_id: this.folderId,
      clone_with_new_business_unit_id: this.cloneWithNewBusinessUnitId,
      retain_permissions_after_clone: this.retainPermissionsAfterClone
    }, val => val !== null);
  }
}
export default EmailCloneOptions;