import { useCallback, useEffect, useMemo, useState } from 'react';
import usePrevious from 'react-utils/hooks/usePrevious';
import isEqual from 'hs-lodash/isEqual';
import listingLibObserver from 'framework-listing-lib/utils/listingLibObserver';
import { ASSET_ADDED_TO_FOLDER } from 'framework-listing-lib/constants/folderEvents';
import { CHANGE_UI_MODE, DESELECT_OBJECTS, RESET_MULTI_LANGUAGE_GROUP_STATE, SET_BULK_SELECTION_MODE, UNREGISTER_PARENT_ROW } from 'framework-listing-lib/constants/tableEvents';
import useCurrentFolderId from 'framework-listing-lib/internal/Folders/hooks/useCurrentFolderId';
import useCrmObjectTypeTracking from 'framework-listing-lib/internal/hooks/useCrmObjectTypeTracking';
import useCrmUI from 'framework-listing-lib/internal/hooks/useCrmUI';
import useExpandCollapseRow from 'framework-listing-lib/hooks/useExpandCollapseRow';
import { BULK_SELECTION_MODE } from 'framework-listing-lib/constants/table';
import { handleNestedRowsSelection, handleNestedRowsUnselection, selectCrmObject, unselectCrmObject, updateCountsAndSelectionMode, getShouldSelectNestedRows, handleTopLevelRowSelection, handleTopLevelRowUnselection, toggleCheckbox } from '../utils/bulkActionUtils';
export default function useSetupBulkActions({
  bulkActionProps,
  data,
  rowProps
}) {
  const crmUI = useCrmUI();
  const {
    expandRow
  } = useExpandCollapseRow();
  const {
    trackCrmTableInteraction
  } = useCrmObjectTypeTracking();
  const currentFolderId = useCurrentFolderId();
  const lastFolderId = usePrevious(currentFolderId);
  const [bulkSelectState, setBulkSelectState] = useState({
    selectedIds: [],
    selectedObjects: [],
    selectedObjectsCount: 0,
    selectionMode: BULK_SELECTION_MODE.NONE,
    previousIds: []
  });
  const [nestedRowsMap, setNestedRowsMap] = useState({});
  const hasBulkActions = useMemo(() => Boolean(bulkActionProps && bulkActionProps.hasBulkActions), [bulkActionProps]);
  const setSelectionMode = useCallback(mode => {
    let newState = {
      selectedIds: [],
      selectedObjects: [],
      selectedObjectsCount: 0,
      selectionMode: BULK_SELECTION_MODE.NONE
    };
    const selectedObjects = mode === BULK_SELECTION_MODE.ALL_ON_PAGE && data ? data.results : [];
    const selectedObjectsCount = mode === BULK_SELECTION_MODE.ALL && data ? data.total : selectedObjects.length;
    newState = {
      selectedIds: selectedObjects.map(crmObject => crmObject.objectId),
      selectedObjects,
      selectedObjectsCount,
      selectionMode: mode
    };
    setBulkSelectState(newState);
    listingLibObserver.setBulkActionsState(newState);
    trackCrmTableInteraction({
      action: `bulk-select-${mode}`
    });
  }, [data, trackCrmTableInteraction]);
  const handleAssetMovedToFolder = useCallback(response => {
    if (bulkSelectState.selectedIds.length === 0) {
      return;
    }

    /**
     * reset selection mode when @bulkSelectState.selectedIds contains at least one assetId
     */
    if (bulkSelectState.selectedIds.includes(response.assetIds[0])) {
      setSelectionMode(BULK_SELECTION_MODE.NONE);
    } else if (response.selectionMode && response.selectionMode === 'all') {
      setSelectionMode(BULK_SELECTION_MODE.NONE);
    }
  }, [bulkSelectState.selectedIds, setSelectionMode]);
  const handleChangeUIMode = useCallback(() => {
    if (bulkSelectState.selectedIds.length === 0) {
      return;
    }

    /**
     * reset selection mode when uiMode changes
     */
    setSelectionMode(BULK_SELECTION_MODE.NONE);
  }, [bulkSelectState.selectedIds, setSelectionMode]);
  const getParentRow = useCallback(objectId => {
    const keys = Object.keys(nestedRowsMap);
    if (keys.length === 0 || !data) {
      return undefined;
    }
    const parentRowId = keys.find(key => {
      return nestedRowsMap[key].some(crmObject => String(crmObject.objectId) === String(objectId));
    });
    if (!parentRowId) {
      return undefined;
    }
    return data.results.find(crmObject => String(crmObject.objectId) === parentRowId);
  }, [data, nestedRowsMap]);
  const handleSelectRow = useCallback((crmObject, status) => {
    setBulkSelectState(prevState => {
      const newState = Object.assign({}, prevState);
      if (status.selected) {
        newState.selectedObjects = selectCrmObject({
          crmObject,
          parentChildObjects: nestedRowsMap,
          selectAllChildren: Boolean(rowProps && rowProps.selectChildRowsOnParentSelect && rowProps.selectChildRowsOnParentSelect({
            crmObject
          })),
          selectedObjects: [...newState.selectedObjects],
          status,
          topLevelRow: getParentRow(crmObject.objectId),
          selectTopLevelRowOnNestedRowSelect: Boolean(rowProps && rowProps.selectTopLevelRowOnNestedRowSelect)
        });
      } else if (!status.selected && newState.selectionMode === BULK_SELECTION_MODE.ALL) {
        /**
         * This condition will happen when the user is moving from "all" to "partial".
         * We need to reset "selectedObjects" as "all" does not keep a reference of all selected assets
         */
        newState.selectedObjects = data ? data.results.filter(object => object.objectId !== crmObject.objectId) : [];
      } else {
        newState.selectedObjects = unselectCrmObject({
          crmObject,
          parentChildObjects: nestedRowsMap,
          selectedObjects: [...newState.selectedObjects],
          status,
          topLevelRow: getParentRow(crmObject.objectId),
          unselectAllChildren: Boolean(rowProps && rowProps.unselectChildRowsOnParentUnselect && rowProps.unselectChildRowsOnParentUnselect({
            crmObject
          })),
          unselectTopLevelRowOnNestedRowUnselect: Boolean(rowProps && rowProps.unselectTopLevelRowOnNestedRowUnselect)
        });
      }
      const {
        selectedIds,
        selectedObjectsCount,
        selectionMode
      } = updateCountsAndSelectionMode(newState.selectedObjects, data);
      newState.selectedIds = selectedIds;
      newState.selectedObjectsCount = selectedObjectsCount;
      newState.selectionMode = selectionMode;
      if (isEqual(newState, prevState)) {
        return prevState;
      }
      listingLibObserver.setBulkActionsState(newState);
      return newState;
    });
  }, [data, getParentRow, nestedRowsMap, rowProps]);
  const getIsSelected = useCallback(objectId => {
    return bulkSelectState.selectedIds.includes(objectId) || bulkSelectState.selectionMode === BULK_SELECTION_MODE.ALL;
  }, [bulkSelectState.selectedIds, bulkSelectState.selectionMode]);
  const handleRegisterChild = useCallback((parentCrmObject, childCrmObject) => {
    // update map with parent object id and child rows
    setNestedRowsMap(prevNestedRowMap => {
      const newState = Object.assign({}, prevNestedRowMap);
      if (!newState[`${parentCrmObject.objectId}`]) {
        newState[parentCrmObject.objectId] = [];
      } else if (newState[parentCrmObject.objectId].find(object => object.objectId === childCrmObject.objectId)) {
        return prevNestedRowMap;
      }
      newState[parentCrmObject.objectId].push(childCrmObject);
      return newState;
    });

    /**
     * Selects nested rows when top-level row is selected.
     */
    if (crmUI) {
      if (getIsSelected(parentCrmObject.objectId) && getShouldSelectNestedRows({
        topLevelRow: parentCrmObject,
        rowProps
      })) {
        toggleCheckbox({
          objectId: childCrmObject.objectId,
          targetValue: true
        });
      }
    } else {
      if (getShouldSelectNestedRows({
        topLevelRow: parentCrmObject,
        rowProps
      })) {
        if (bulkSelectState.selectedIds.includes(parentCrmObject.objectId) && !bulkSelectState.selectedIds.includes(childCrmObject.objectId)) {
          handleSelectRow(childCrmObject, {
            selected: true
          });
        }
      }
    }
  }, [bulkSelectState.selectedIds, crmUI, getIsSelected, handleSelectRow, rowProps]);
  const handleUnregisterParentRow = useCallback(parentId => {
    setNestedRowsMap(prevNestedRowsMap => {
      const newState = Object.assign({}, prevNestedRowsMap);
      delete newState[parentId];
      return newState;
    });
  }, []);
  const handleDeselectObjects = useCallback(crmObjects => {
    for (const crmObject of crmObjects) {
      handleSelectRow(crmObject, {
        selected: false
      });
    }
  }, [handleSelectRow]);
  const getIsParentRow = useCallback(objectId => Object.keys(nestedRowsMap).includes(String(objectId)), [nestedRowsMap]);
  const getNestedRows = useCallback(objectId => {
    return nestedRowsMap[objectId] || [];
  }, [nestedRowsMap]);
  const getNestedLevel = useCallback(objectId => {
    const keys = Object.keys(nestedRowsMap);
    if (keys.length === 0) {
      return 0;
    }
    const parentRowId = keys.find(key => {
      return nestedRowsMap[key].some(crmObject => String(crmObject.objectId) === String(objectId));
    });
    if (parentRowId) {
      return 1 + getNestedLevel(parentRowId);
    }
    return 0;
  }, [nestedRowsMap]);
  const getIsNestedRow = useCallback(objectId => getParentRow(objectId) !== undefined, [getParentRow]);
  const getRow = useCallback(objectId => {
    if (!data) {
      return undefined;
    }
    if (getIsParentRow(objectId) || !getIsNestedRow(objectId)) {
      return data.results.find(crmObject => String(crmObject.objectId) === String(objectId));
    }
    const keys = Object.keys(nestedRowsMap);
    if (keys.length === 0) {
      return undefined;
    }
    const topLevelRow = getParentRow(objectId);
    if (!topLevelRow) {
      return undefined;
    }
    const nestedRows = getNestedRows(topLevelRow.objectId);
    return nestedRows.find(nestedRow => String(nestedRow.objectId) === String(objectId));
  }, [data, getIsParentRow, getNestedRows, getIsNestedRow, getParentRow, nestedRowsMap]);
  const handleSelectFrameworkDataTableRows = useCallback(({
    selectedIds,
    selectionMode
  }) => {
    setBulkSelectState(prevState => {
      const newState = {
        selectedIds: [],
        selectedObjects: [],
        selectedObjectsCount: 0,
        selectionMode,
        previousIds: prevState.previousIds
      };
      switch (selectionMode) {
        default:
          break;
        case BULK_SELECTION_MODE.ALL:
          {
            newState.selectedObjectsCount = data ? data.total : selectedIds.length;
            break;
          }
        case BULK_SELECTION_MODE.ALL_ON_PAGE:
          {
            newState.selectedObjectsCount = selectedIds.length;
            newState.selectedIds = selectedIds;
            newState.selectedObjects = selectedIds.map(getRow).filter(Boolean);
            break;
          }
        case BULK_SELECTION_MODE.PARTIAL:
          {
            newState.selectedObjectsCount = selectedIds.length;
            newState.selectedIds = selectedIds;
            newState.selectedObjects = selectedIds.map(getRow).filter(Boolean);
            break;
          }
      }

      /**
       * Expand top-level rows that should be expanded
       */
      for (const crmObject of newState.selectedObjects) {
        if (rowProps && rowProps.expandOnSelect && rowProps.expandOnSelect({
          crmObject
        })) {
          expandRow(crmObject.objectId);
        }
      }
      if (newState.selectionMode === BULK_SELECTION_MODE.ALL_ON_PAGE || newState.selectionMode === BULK_SELECTION_MODE.PARTIAL) {
        var _prevState$previousId;
        let newSelectedIds;
        const isSelecting = (((_prevState$previousId = prevState.previousIds) === null || _prevState$previousId === void 0 ? void 0 : _prevState$previousId.length) || 0) <= selectedIds.length;
        if (isSelecting) {
          newSelectedIds = handleNestedRowsSelection({
            getIsTopLevelRow: getIsParentRow,
            getNestedRows,
            getRow,
            previousIds: prevState.previousIds,
            rowProps,
            selectedIds
          });
          newSelectedIds = handleTopLevelRowSelection({
            getIsNestedRow,
            getNestedRows,
            getTopLevelRow: getParentRow,
            getRow,
            rowProps,
            selectedIds: newSelectedIds
          });
        } else {
          newSelectedIds = handleNestedRowsUnselection({
            getNestedRows,
            getTopLevelRow: getParentRow,
            previousIds: prevState.previousIds,
            rowProps,
            selectedIds
          });
          newSelectedIds = handleTopLevelRowUnselection({
            getIsNestedRow,
            getNestedRows,
            getTopLevelRow: getParentRow,
            getRow,
            rowProps,
            selectedIds: newSelectedIds
          });
        }
        newState.previousIds = newSelectedIds;
      }
      listingLibObserver.setBulkActionsState(newState);
      trackCrmTableInteraction({
        action: `bulk-select-${newState.selectionMode}`
      });
      return newState;
    });
  }, [data, expandRow, getIsNestedRow, getIsParentRow, getNestedRows, getParentRow, getRow, rowProps, trackCrmTableInteraction]);
  const handleStateReset = useCallback(() => {
    setNestedRowsMap({});
    setBulkSelectState({
      selectedIds: [],
      selectedObjects: [],
      selectedObjectsCount: 0,
      selectionMode: BULK_SELECTION_MODE.NONE,
      previousIds: []
    });
  }, []);
  useEffect(() => {
    listingLibObserver.on(ASSET_ADDED_TO_FOLDER, handleAssetMovedToFolder);
    listingLibObserver.on(CHANGE_UI_MODE, handleChangeUIMode);
    listingLibObserver.on(SET_BULK_SELECTION_MODE, setSelectionMode);
    listingLibObserver.on(DESELECT_OBJECTS, handleDeselectObjects);
    listingLibObserver.on(UNREGISTER_PARENT_ROW, handleUnregisterParentRow);
    listingLibObserver.on(RESET_MULTI_LANGUAGE_GROUP_STATE, handleStateReset);
    return () => {
      listingLibObserver.off(ASSET_ADDED_TO_FOLDER, handleAssetMovedToFolder);
      listingLibObserver.off(CHANGE_UI_MODE, handleChangeUIMode);
      listingLibObserver.off(SET_BULK_SELECTION_MODE, setSelectionMode);
      listingLibObserver.off(DESELECT_OBJECTS, handleDeselectObjects);
      listingLibObserver.off(UNREGISTER_PARENT_ROW, handleUnregisterParentRow);
    };
  }, [handleAssetMovedToFolder, handleChangeUIMode, handleDeselectObjects, handleStateReset, handleUnregisterParentRow, setSelectionMode]);

  /**
   * Reset selection mode when navigation from one folder to another
   */
  useEffect(() => {
    if (currentFolderId === lastFolderId) {
      return;
    }
    if (bulkSelectState.selectionMode === BULK_SELECTION_MODE.NONE) {
      return;
    }
    setSelectionMode(BULK_SELECTION_MODE.NONE);
  }, [bulkSelectState.selectionMode, currentFolderId, lastFolderId, setSelectionMode]);
  const value = useMemo(() => ({
    getIsParentRow,
    getIsSelected,
    getNestedLevel,
    getNestedRows,
    getParentRow,
    handleRegisterChild,
    handleSelectFrameworkDataTableRows,
    handleSelectRow,
    handleUnregisterParentRow,
    hasBulkActions,
    renderBulkActions: bulkActionProps ? bulkActionProps.renderBulkActions : undefined,
    selectedIds: bulkSelectState.selectedIds,
    selectedObjects: bulkSelectState.selectedObjects,
    selectedObjectsCount: bulkSelectState.selectedObjectsCount,
    selectionMode: bulkSelectState.selectionMode,
    setSelectionMode
  }), [bulkActionProps, bulkSelectState.selectedIds, bulkSelectState.selectedObjects, bulkSelectState.selectedObjectsCount, bulkSelectState.selectionMode, getIsParentRow, getIsSelected, getNestedLevel, getNestedRows, getParentRow, handleRegisterChild, handleSelectFrameworkDataTableRows, handleSelectRow, handleUnregisterParentRow, hasBulkActions, setSelectionMode]);
  return value;
}