import Raven from 'raven-js';
import devLogger from 'react-utils/devLogger';
import * as tracker from 'ui-addon-upgrades/_core/common/eventTracking/tracker';
// @ts-expect-error dependency missing types
import { startTrial } from 'self-service-api/api/startTrial';
import wrapWithErrorMonitoring from 'ui-addon-upgrades/_core/common/reliability/wrapWithErrorMonitoring';
import { getUpgradeDataTrackingProperties } from 'ui-addon-upgrades/ums/utils';
export const activateTrial = wrapWithErrorMonitoring('activateTrial', ({
  activeTrials,
  expiredTrials,
  isMultiTrial,
  upgradeData
}) => {
  const {
    trialId,
    source,
    duration
  } = upgradeData;
  const currentDate = new Date();
  const endDate = currentDate.setDate(currentDate.getDate() + duration).valueOf();
  const trackingProperties = Object.assign({}, getUpgradeDataTrackingProperties(upgradeData), {
    activeTrials,
    expiredTrials,
    isMultiTrial,
    trialId: upgradeData.trialId,
    duration
  });
  return startTrial({
    trialId,
    endDate,
    trialSource: source
  }).then(() => {
    // ********** PUBLIC EVENT **********
    // Public Events help teams across HubSpot automate work and customize experiences based on user actions.
    // Speak with #product-insight and your PM before any shipping any changes to this event incl. event name, properties, values, and when it occurs.
    // Read more about Public Events on the wiki: https://wiki.hubspotcentral.net/display/PM/Public+Events+-+Amplitude+events+ready+for+HubSpot+team+use+and+automation
    tracker.track('trialActivationInteraction', Object.assign({
      action: 'activation succeeded'
    }, trackingProperties));
  }).catch(e => {
    // ********** PUBLIC EVENT **********
    // Public Events help teams across HubSpot automate work and customize experiences based on user actions.
    // Speak with #product-insight and your PM before any shipping any changes to this event incl. event name, properties, values, and when it occurs.
    // Read more about Public Events on the wiki: https://wiki.hubspotcentral.net/display/PM/Public+Events+-+Amplitude+events+ready+for+HubSpot+team+use+and+automation
    tracker.track('trialActivationInteraction', Object.assign({
      action: 'activation failed'
    }, trackingProperties));
    devLogger.warn({
      message: e,
      key: 'TrialActivationButton'
    });
    Raven.captureException(e);
    throw e;
  });
});