import { HUB_USER_ATTRIBUTE_PREFIX } from 'EmailData/api/hubUserAttributes';
import { Map as ImmutableMap, List } from 'immutable';
export const getHubUserAttributeKeyWithPrefix = key => HUB_USER_ATTRIBUTE_PREFIX + key;
export function parseUserAttributesResponse(hubUserAttributes = {}) {
  let userSettings = ImmutableMap({});
  const getValue = key => hubUserAttributes[getHubUserAttributeKeyWithPrefix(key)];
  userSettings = userSettings.set('dashboardZeroStateComplete', getValue('dashboardZeroStateComplete') === 'true');
  const dismissedOnboardingTourTimeHUA = parseInt(getValue('dismissedOnboardingTourTime'), 10);
  userSettings = userSettings.set('dismissedOnboardingTourTime', isNaN(dismissedOnboardingTourTimeHUA) ? 0 : dismissedOnboardingTourTimeHUA);
  userSettings = userSettings.set('dismissedOutageCritsitBanner', getValue('dismissedOutageCritsitBanner') === 'true');
  userSettings = userSettings.set('dismissedToolsSidebarCarousel', getValue('dismissedToolsSidebarCarousel') === 'true');
  userSettings = userSettings.set('dismissedOnboardingTourEntry', getValue('dismissedOnboardingTourEntry') === 'true');
  const manageColumnsValue = getValue('manageColumns');
  userSettings = userSettings.set('manageColumns', List(manageColumnsValue ? manageColumnsValue.split(',') : []));
  return userSettings;
}