// Mirror of:  https://git.hubteam.com/HubSpot/content4j/blob/master/ContentModel/src/main/java/com/hubspot/content/model/EmailType.java

const EMAIL_TYPE = Object.freeze({
  BATCH_EMAIL: 'BATCH_EMAIL',
  AB_EMAIL: 'AB_EMAIL',
  AUTOMATED_AB_EMAIL: 'AUTOMATED_AB_EMAIL',
  AUTOMATED_EMAIL: 'AUTOMATED_EMAIL',
  BLOG_EMAIL: 'BLOG_EMAIL',
  BLOG_EMAIL_CHILD: 'BLOG_EMAIL_CHILD',
  FOLLOWUP_EMAIL: 'FOLLOWUP_EMAIL',
  LOCALTIME_EMAIL: 'LOCALTIME_EMAIL',
  OPTIN_EMAIL: 'OPTIN_EMAIL',
  OPTIN_FOLLOWUP_EMAIL: 'OPTIN_FOLLOWUP_EMAIL',
  RESUBSCRIBE_EMAIL: 'RESUBSCRIBE_EMAIL',
  RSS_EMAIL: 'RSS_EMAIL',
  RSS_EMAIL_CHILD: 'RSS_EMAIL_CHILD',
  SINGLE_SEND_API: 'SINGLE_SEND_API',
  MARKETING_SINGLE_SEND_API: 'MARKETING_SINGLE_SEND_API',
  LEADFLOW_EMAIL: 'LEADFLOW_EMAIL',
  FEEDBACK_NPS_EMAIL: 'FEEDBACK_NPS_EMAIL',
  FEEDBACK_CES_EMAIL: 'FEEDBACK_CES_EMAIL',
  FEEDBACK_CUSTOM_EMAIL: 'FEEDBACK_CUSTOM_EMAIL',
  FEEDBACK_CUSTOM_SURVEY_EMAIL: 'FEEDBACK_CUSTOM_SURVEY_EMAIL',
  TICKET_EMAIL: 'TICKET_EMAIL',
  SMTP_TOKEN: 'SMTP_TOKEN',
  MEMBERSHIP_REGISTRATION_EMAIL: 'MEMBERSHIP_REGISTRATION_EMAIL',
  MEMBERSHIP_PASSWORD_SAVED_EMAIL: 'MEMBERSHIP_PASSWORD_SAVED_EMAIL',
  MEMBERSHIP_PASSWORD_RESET_EMAIL: 'MEMBERSHIP_PASSWORD_RESET_EMAIL',
  MEMBERSHIP_FOLLOW_UP_EMAIL: 'MEMBERSHIP_FOLLOW_UP_EMAIL',
  MEMBERSHIP_PASSWORDLESS_AUTH_EMAIL: 'MEMBERSHIP_PASSWORDLESS_AUTH_EMAIL',
  MEMBERSHIP_VERIFICATION_EMAIL: 'MEMBERSHIP_VERIFICATION_EMAIL'
});
export default EMAIL_TYPE;