'use es6';

import { Map as ImmutableMap } from 'immutable';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { CLEAR_SMTP_PASSWORD_DETAILS, RESET_SMTP_PASSWORD, RESET_SMTP_PASSWORD_REQUEST } from 'email-management-lib/refactor/actions/actionTypes';
const initialState = new ImmutableMap({
  password: ''
});
function smtpPassword(state = initialState, action) {
  switch (action.type) {
    case RESET_SMTP_PASSWORD:
      if (action.error !== true) {
        return state.set('password', action.payload.password);
      }
      return state;
    case CLEAR_SMTP_PASSWORD_DETAILS:
      return initialState;
    default:
      return state;
  }
}
export default asyncResource(smtpPassword, {
  requestActions: [RESET_SMTP_PASSWORD_REQUEST],
  responseActions: [RESET_SMTP_PASSWORD],
  invalidatingActions: [CLEAR_SMTP_PASSWORD_DETAILS]
});