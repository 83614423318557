'use es6';

import I18n from 'I18n';
export const currencyStyles = {
  short: 'short',
  long: 'long'
};
const getCurrencySymbol = ({
  code,
  symbol,
  symbolNative
}) => {
  let finalSymbol = symbol;
  if (code === symbol && code !== symbolNative) {
    finalSymbol = symbolNative;
  } else if (code === symbol) {
    finalSymbol = '';
  }
  return finalSymbol;
};
export const formatCurrencyLabel = (currencyCode, currencyStyle) => {
  const currencyValue = I18n.currencySymbols[currencyCode] || I18n.currencySymbols.USD;
  const code = currencyValue.code;
  const symbol = getCurrencySymbol({
    code,
    symbol: currencyValue.symbol,
    symbolNative: currencyValue.symbol_native
  });
  if (currencyStyle === currencyStyles.short) {
    return `${code} ${symbol}`;
  }
  return `${currencyValue.name} (${code}) ${symbol}`;
};
export const formatCurrencyCode = currencyCode => formatCurrencyLabel(currencyCode, currencyStyles.short);
export const formatCurrencyName = currencyCode => formatCurrencyLabel(currencyCode, currencyStyles.long);
export const currencyFormatters = {
  [currencyStyles.short]: formatCurrencyCode,
  [currencyStyles.long]: formatCurrencyName
};