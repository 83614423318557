import { useGetAdditionalPropertyValue } from './useGetAdditionalPropertyValue';
import { useObjectId } from './useObjectId';
import { useObjectTypeId } from './useObjectTypeId';
/**
 * Looks up the values for the given property names and attaches
 * them to specified keys.
 *
 * ```ts
 * const { data } = useAdditionalPropertyValues({
 *  property1: 'hs_property_1',
 *  property2: 'hs_property_2'
 * });
 *
 * if (data === undefined) { return; }
 *
 * const { property1, property2 } = data;
 * ```
 */
export const useAdditionalPropertyValues = (propertyNames, options) => {
  const objectId = useObjectId();
  const objectTypeId = useObjectTypeId();
  const getAdditionalPropertyValue = useGetAdditionalPropertyValue();
  if (options !== null && options !== void 0 && options.skip) {
    return {
      data: undefined,
      loading: false,
      error: undefined
    };
  }
  const values = {};
  for (const key in propertyNames) {
    if (Object.prototype.hasOwnProperty.call(propertyNames, key)) {
      values[key] = getAdditionalPropertyValue(propertyNames[key], objectId, objectTypeId);
    }
  }
  return {
    data: values,
    loading: false,
    error: undefined
  };
};