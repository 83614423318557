import I18n from 'I18n';
import { PROPERTIES } from 'email-management-lib/refactor/constants/manage/frameworkTable';
import { VISIBLE_TO } from 'email-management-lib/refactor/constants/manage/shared';
export function PROPERTIES_TO_SHOW_ONLY_IN_COLUMNS() {
  return {
    [PROPERTIES.CAMPAIGN_GUID]: {
      hidden: false,
      label: I18n.text('email-management.table.columns.campaign.label'),
      description: I18n.text('email-management.table.columns.campaign.description')
    },
    [PROPERTIES.GENERATED_BY_AI]: {
      hidden: false
    },
    [PROPERTIES.GENERATED_WITH_AI]: {
      hidden: false
    }
  };
}
export function PROPERTIES_TO_SHOW_ONLY_IN_FILTERS() {
  return {
    [PROPERTIES.IS_AI_USED]: {
      hidden: false,
      label: I18n.text('email-management.table.columns.isAiUsed.label')
    }
  };
}
export function PROPERTIES_TO_SHOW_IN_COLUMNS_AND_FILTERS({
  canViewApprovals
}) {
  return {
    [PROPERTIES.APPROVALS_STATUS_V2]: {
      hidden: !canViewApprovals
    },
    [PROPERTIES.ALL_INTERNAL_HUBSPOT_IDS]: {
      hidden: false
    }
  };
}
export function OVERRIDE_PROPERTY_CONTEXT({
  canAccessBusinessUnit
}) {
  return {
    [PROPERTIES.BUSINESS_UNIT_IDS]: {
      label: I18n.text('email-management.table.columns.businessUnit.label'),
      hidden: !canAccessBusinessUnit
    },
    [PROPERTIES.HS_CREATED_BY_USER_ID]: {
      label: I18n.text('email-management.table.columns.createdBy.label'),
      description: I18n.text('email-management.table.columns.createdBy.description')
    },
    [PROPERTIES.UPDATED_BY]: {
      label: I18n.text('email-management.table.columns.updatedBy.label'),
      description: I18n.text('email-management.table.columns.updatedBy.description')
    },
    [PROPERTIES.PREVIEW_KEY]: {
      label: I18n.text('email-management.table.columns.previewLink.label'),
      description: I18n.text('email-management.table.columns.previewLink.description')
    },
    [VISIBLE_TO]: {
      label: I18n.text('email-management.table.columns.visibleTo.label'),
      description: I18n.text('email-management.table.columns.visibleTo.description')
    },
    [PROPERTIES.USER_IDS]: {
      label: I18n.text('email-management.table.columns.users.label')
    },
    [PROPERTIES.TEAM_IDS]: {
      label: I18n.text('email-management.table.columns.teams.label')
    },
    [PROPERTIES.CAMPAIGN_GUID]: {
      label: I18n.text('email-management.table.columns.campaign.label'),
      description: I18n.text('email-management.table.columns.campaign.description')
    },
    [PROPERTIES.PUBLISH_AUTHOR]: {
      label: I18n.text('email-management.table.columns.publishedBy.label'),
      description: I18n.text('email-management.table.columns.publishedBy.description'),
      hidden: false
    }
  };
}