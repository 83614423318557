import memoize from 'react-utils/memoize';
// General utilites for navigating a heirachical team data structure

const sortTeamsByName = (teamA, teamB) => {
  return teamA.name.toLocaleLowerCase() > teamB.name.toLocaleLowerCase() ? 1 : -1;
};
const getTeamAndSortedChildrenAsFlatList = (team, depth) => {
  if (team.childTeams.length === 0) {
    return Object.assign({
      depth
    }, team);
  }
  return [...team.childTeams].sort(sortTeamsByName).reduce((list, childTeam) => list.concat(getTeamAndSortedChildrenAsFlatList(childTeam, depth + 1)), [Object.assign({
    depth
  }, team)]);
};
export const getSortedFlattenedTeamsList = teams => {
  return [...teams].sort(sortTeamsByName).reduce((list, team) => list.concat(getTeamAndSortedChildrenAsFlatList(team, 0)), []);
};
export const getFlattenedTeamsMap = teams => {
  const sortedFlattenedTeamsList = getSortedFlattenedTeamsList(teams);
  return sortedFlattenedTeamsList.reduce((map, team) => {
    map[team.id] = team;
    return map;
  }, {});
};

// Takes an array of 1-many team IDs, for all those teams and all
// of their children, return a list of every unique user on those teams.
export const getUserIdsForSelectedTeamIds = (teamIds, teams) => {
  const teamsMap = getFlattenedTeamsMap(teams);
  return teamIds.reduce((userIds, teamId) => {
    const team = teamsMap[teamId];

    // ** Edge case **
    // When the user is not allowed to see the full team hierarchy, we don't actually have the full team list, so
    // in some instances if a teamId is assigned to an asset we may not have the corresponding record, in which case
    // we just aren't showing that teams info.
    if (!team) {
      return userIds;
    }
    return userIds.concat(team.userIds).concat(team.secondaryUserIds);
  }, []);
};

// For a list of userIds, find all the userIds that are tied to a given team, return
// on userIds that are not part of any of the listed teamIds.
export const getExplicitlySelectedUserIds = (selectedUserIds, selectedTeamIds, teams) => {
  const userIdsForSelectedTeamIds = getUserIdsForSelectedTeamIds(selectedTeamIds, teams);
  return selectedUserIds.filter(userId => !userIdsForSelectedTeamIds.includes(userId));
};

// Takes a single team ID, returns an array of all child team ID's for the team
export const getAllChildTeamIdsForParentTeamId = memoize((parentTeamId, teams) => {
  const teamsMap = getFlattenedTeamsMap(teams);
  const parentTeam = teamsMap[parentTeamId];
  if (parentTeam.childTeams.length === 0) {
    return [];
  }
  return getTeamAndSortedChildrenAsFlatList(parentTeam, 0).map(team => team.id).slice(1);
});

// Takes a single team ID, returns an array of all the parents for that child
export const getAllParentTeamIdsForChildTeamId = memoize((childTeamId, teams) => {
  const teamsMap = getFlattenedTeamsMap(teams);
  const childTeam = teamsMap[childTeamId];
  let parentTeamId = childTeam.parentTeamId;
  const parentTeamIds = [];
  while (parentTeamId !== null) {
    // ** Edge case **
    // When the user is not allowed to see the full team hierarchy, we don't actually have the full team list, so
    // if they have a parent team we only get the teams below the one the user is on, so we have a stray team ID
    // In this case the user will only know all the parents up to the team they are on, but not above.
    if (!teamsMap[parentTeamId]) {
      break;
    }
    parentTeamIds.push(parentTeamId);
    parentTeamId = teamsMap[parentTeamId].parentTeamId;
  }
  return parentTeamIds;
});
export const getRootLevelTeamIds = teams => teams.map(({
  id
}) => id);