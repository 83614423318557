import I18n from 'I18n';
import { CANDY_APPLE_DARK, OZ_DARK } from 'HubStyleTokens/colors';
import { getPropertyValue } from 'framework-listing-lib/utils/crmObject';
import { FRAMEWORK_PROPERTY_TO_INSIGHT_PROPERTY, FRAMEWORK_INSIGHT_POLARITY, BENCHMARK_TYPE } from 'messaging-publishing-lib/Insights/constant/EmailInsightsPanel';
import PortalIdParser from 'PortalIdParser';
import { isSupportedFrameworkInsight, isSupportedFrameworkInsightContext } from './support';
export const isPostSendPage = /^\/email\/[^/]+\/details\/[^/]+\/performance$/.test(window.location.pathname);
export const isRecipientsPage = /^\/email\/[^/]+\/details\/[^/]+\/recipients\/[^/]+$/.test(window.location.pathname);
const getInsightProperty = propertyName => FRAMEWORK_PROPERTY_TO_INSIGHT_PROPERTY[propertyName];
export const isInsightGood = insight => !!insight.endsWith(FRAMEWORK_INSIGHT_POLARITY.GOOD);
export const isParsedInsightGood = parsedInsight => parsedInsight.polarity === FRAMEWORK_INSIGHT_POLARITY.GOOD;
export const unparseInsight = parsedInsight => {
  return Object.values(parsedInsight).join('__');
};
export const parseInsight = insight => {
  const [context, property, polarity] = insight.split('__');
  return {
    context,
    property,
    polarity
  };
};
export const getInsights = crmObject => {
  if (!crmObject) return [];
  const rawPropertyValue = getPropertyValue(crmObject, 'hs_metric_insights');
  const frameworkInsights = (rawPropertyValue === null || rawPropertyValue === void 0 ? void 0 : rawPropertyValue.split(';')) || [];
  const supportedInsights = frameworkInsights.filter(isSupportedFrameworkInsight);
  return supportedInsights;
};
export const getInsightColor = polarity => polarity ? OZ_DARK : CANDY_APPLE_DARK;

/**
 * Gets the polarity of an entire crmObject or
 * individual propertyName (if provided).
 *
 * @returns the polarity as a boolean or null if there is no insight.
 */
export const getInsightPolarity = ({
  crmObject,
  propertyName,
  context = 'PORTAL_CONTEXT'
}) => {
  const insights = getInsights(crmObject);
  const parsedInsights = insights.map(parseInsight);
  const parsedInsightsInContext = parsedInsights.filter(({
    context: possibleContext
  }) => possibleContext === context);
  if (propertyName) {
    const property = getInsightProperty(propertyName);
    const possibleParsedInsightInContext = parsedInsightsInContext.find(({
      property: possibleProperty
    }) => possibleProperty === property);
    return possibleParsedInsightInContext ? isParsedInsightGood(possibleParsedInsightInContext) : null;
  }
  return parsedInsightsInContext.length ? parsedInsightsInContext.every(isParsedInsightGood) : null;
};
export const getEmailDetilsPageLink = emailId => `/email/${PortalIdParser.get()}/details/${emailId}/performance`;
export const formatRate = rate => I18n.formatPercentage(rate, {
  precision: 1
});
export const formatCount = count => I18n.formatNumber(count, {
  precision: 1
});
export const absoluteDifference = (num1, num2) => {
  return Math.abs(num1 - num2);
};

/**
 * @deprecated use getShouldUseBusinessUnitContext instead.
 * Delete this FN once all usages are migrated over in
 * messaging-lib, ContentEmailUI, and email-management-ui.
 */
export const isDefaultBusinessUnitId = businessUnitId => {
  if (typeof businessUnitId === 'number') return businessUnitId === 0;
  return businessUnitId === '0' || businessUnitId === 'ALL';
};

/**
 * @param businessUnit could be the special string
 * `ALL`. Otherwise, it should be the businessUnitId.
 */
export const getShouldUseBusinessUnitContext = businessUnit => {
  return Number(businessUnit) > 0;
};
export const getInsightsBenchmark = (benchmarkStats, benchMarkType) => benchmarkStats.find(benchmark => benchmark.benchmarkType === benchMarkType);
export function getBenchmarkRate(benchmark) {
  if (!benchmark) return formatCount(0);
  const benchmarkRate = benchmark.rateToBenchmarkComparison === FRAMEWORK_INSIGHT_POLARITY.BAD ? benchmark.benchmarkRates.badRate : benchmark.benchmarkRates.goodRate;
  return formatCount(benchmarkRate);
}
export function getHubspotBenchmark({
  metadata: {
    benchmarkStats
  }
}) {
  return getBenchmarkRate(getInsightsBenchmark(benchmarkStats, BENCHMARK_TYPE.GLOBAL));
}
export function getIndustryBenchmark({
  metadata: {
    benchmarkStats
  }
}) {
  return getBenchmarkRate(getInsightsBenchmark(benchmarkStats, BENCHMARK_TYPE.INDUSTRY));
}
export function getFormattedIndustryName({
  metadata: {
    benchmarkStats
  }
}) {
  const industryBenchmark = getInsightsBenchmark(benchmarkStats, BENCHMARK_TYPE.INDUSTRY);
  if (!industryBenchmark) return undefined;
  return I18n.text(`industry.${industryBenchmark.industryName}`);
}
const getInsightsMetricsState = (insights, insightType, benchMarkType) => {
  const insight = insights.find(i => i.insightType === insightType);
  if (!insight) return '';
  const {
    metadata: {
      metricType,
      benchmarkStats
    }
  } = insight;
  const benchmark = getInsightsBenchmark(benchmarkStats, benchMarkType);
  const rateToBenchmarkComparison = (benchmark === null || benchmark === void 0 ? void 0 : benchmark.rateToBenchmarkComparison) || '';
  return `${rateToBenchmarkComparison}_${metricType}`;
};
export function getIndustryPolarity({
  insights,
  insightType
}) {
  return getInsightsMetricsState(insights, insightType, BENCHMARK_TYPE.INDUSTRY);
}
export function getHubspotPolarity({
  insights,
  insightType
}) {
  return getInsightsMetricsState(insights, insightType, BENCHMARK_TYPE.GLOBAL);
}
export function getAvailableContexts({
  shouldUseBusinessUnitContext,
  hasCampaign,
  isAutomatedEmail
}) {
  const availableContexts = [shouldUseBusinessUnitContext ? 'BUSINESS_UNIT_CONTEXT' : 'PORTAL_CONTEXT'];
  if (hasCampaign !== false) {
    availableContexts.push('PRODUCT_CAMPAIGN_CONTEXT');
  }
  if (isAutomatedEmail !== false) {
    availableContexts.push('SAME_EMAIL_MONTH_OVER_MONTH_CONTEXT');
  }
  return availableContexts.filter(isSupportedFrameworkInsightContext);
}
export function getDefaultContext({
  shouldUseBusinessUnitContext,
  hasCampaign,
  isAutomatedEmail
}) {
  if (isAutomatedEmail) {
    return 'SAME_EMAIL_MONTH_OVER_MONTH_CONTEXT';
  }
  if (hasCampaign) {
    return 'PRODUCT_CAMPAIGN_CONTEXT';
  }
  return shouldUseBusinessUnitContext ? 'BUSINESS_UNIT_CONTEXT' : 'PORTAL_CONTEXT';
}