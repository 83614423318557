import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["data"];
import { useMemo } from 'react';
import http from 'hub-http/clients/apiClient';
import { registerQuery, useQuery } from 'data-fetching-client';
import { makeQuickFetchRequest } from 'marketing-platform-lib/utils/quickFetch';
export const FETCH_CRM_OBJECT_TYPE_DEFINITIONS_FIELD_NAME = 'fetchCrmObjectTypeDefinitions';
export const FETCH_CRM_OBJECT_TYPE_DEFINITIONS_QUERY = registerQuery({
  fieldName: FETCH_CRM_OBJECT_TYPE_DEFINITIONS_FIELD_NAME,
  args: [],
  fetcher() {
    const typeDefinitionsRequest = () => http.get(`api/inbounddb-meta/v1/object-types/for-portal`);
    return makeQuickFetchRequest({
      makeRequestFn: typeDefinitionsRequest,
      requestName: 'fetch-crm-object-type-definitions'
    });
  }
});
export default function useFetchCrmObjectTypeDefinitions(options) {
  const _useQuery = useQuery(FETCH_CRM_OBJECT_TYPE_DEFINITIONS_QUERY, options),
    {
      data
    } = _useQuery,
    rest = _objectWithoutPropertiesLoose(_useQuery, _excluded);
  return useMemo(() => Object.assign({
    data: data && FETCH_CRM_OBJECT_TYPE_DEFINITIONS_FIELD_NAME in data && Array.isArray(data[FETCH_CRM_OBJECT_TYPE_DEFINITIONS_FIELD_NAME]) ? data[FETCH_CRM_OBJECT_TYPE_DEFINITIONS_FIELD_NAME] : undefined
  }, rest), [data, rest]);
}