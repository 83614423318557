import { useMemo } from 'react';

// @ts-expect-error: no types
import { fromContactSearch } from 'customer-data-filters/converters/contactSearch/FilterContactSearch';

// @ts-expect-error: no types
import { fromCRMSearch } from 'customer-data-filters/converters/crmSearch/FilterCRMSearch';
import PropertyRecord from 'customer-data-objects/property/PropertyRecord';
import { Map as ImmutableMap } from 'immutable';
import useCrmObjectTypeProperties from 'framework-listing-lib/internal/hooks/useCrmObjectTypeProperties';
import { flattenFilterGroups, removeFilterGroups, removePropertyFilters } from 'framework-listing-lib/utils/filters';
import { useFilterGroups, useHiddenFilter } from 'framework-listing-lib/hooks/useListingLibFilters';
import useQuickFilters from 'framework-listing-lib/hooks/useQuickFilters';
import { DEFAULT_FILTER_FAMILY } from 'framework-listing-lib/internal/FilterEditor/constants/filterEditor';
import useUserInfo from 'framework-listing-lib/internal/hooks/useUserInfo';
export default function useXOFilterEditorValue({
  hasOrConditions,
  propertyOverride,
  valueType = 'filterGroups',
  filterFamily = DEFAULT_FILTER_FAMILY
}) {
  const {
    isUngatedFor
  } = useUserInfo();
  const hiddenFilter = useHiddenFilter();
  const filterGroups = useFilterGroups();
  const quickFilters = useQuickFilters();
  const {
    dataAsJS: propertiesAsJS,
    propertyGroups
  } = useCrmObjectTypeProperties({
    propertyOverride,
    showMostUsed: true
  });
  const propertyKeys = useMemo(() => Object.keys(propertiesAsJS), [propertiesAsJS]);

  /**
   * Filter groups in @hiddenFilter should be removed
   */
  const filterGroupsWithoutHiddenFilter = useMemo(() => removeFilterGroups(filterGroups, hiddenFilter.filterGroups), [filterGroups, hiddenFilter.filterGroups]);

  /**
   * Used when OR conditions is turned off:
   *
   * Propagate filter values for properties that are visible in XOFilterEditor and
   * remove hidden filter before setting the filter editor value
   */
  const validFilterOperators = useMemo(() => {
    if (valueType === 'filterGroups' && hasOrConditions) {
      return [];
    }
    if (valueType === 'filterGroups') {
      return [...flattenFilterGroups(filterGroupsWithoutHiddenFilter), ...quickFilters].filter(filter => propertyKeys.includes(filter.property));
    }
    return flattenFilterGroups(filterGroupsWithoutHiddenFilter).filter(filter => propertyKeys.includes(filter.property));
  }, [filterGroupsWithoutHiddenFilter, hasOrConditions, propertyKeys, quickFilters, valueType]);

  /**
   * Used when OR conditions is turned on:
   *
   * Propagate filter values for properties that are visible in XOFilterEditor and
   * remove hidden filter before setting the filter editor value
   */
  const validFilterGroups = useMemo(() => {
    if (valueType !== 'filterGroups' || !hasOrConditions) {
      return [];
    }
    const allPropertiesUsedInFilterGroups = [...new Set(flattenFilterGroups(filterGroupsWithoutHiddenFilter).map(filterOperator => filterOperator.property))];
    let result = [...filterGroupsWithoutHiddenFilter];
    for (const propertyName of allPropertiesUsedInFilterGroups) {
      if (propertyKeys.includes(propertyName)) {
        continue;
      }
      result = removePropertyFilters(result, propertyName);
    }
    return result.filter(filterGroup => filterGroup.filters.length > 0);
  }, [filterGroupsWithoutHiddenFilter, hasOrConditions, propertyKeys, valueType]);
  const properties = useMemo(() => {
    let propertiesMap = ImmutableMap({});
    for (const propertyKey of Object.keys(propertiesAsJS)) {
      propertiesMap = propertiesMap.set(propertyKey, PropertyRecord.fromJS(propertiesAsJS[propertyKey]));
    }
    return propertiesMap;
  }, [propertiesAsJS]);
  const value = useMemo(() => {
    if (valueType === 'quickFilters') {
      return fromContactSearch(properties, quickFilters, filterFamily, isUngatedFor);
    }
    if (hasOrConditions) {
      return fromCRMSearch(properties, {
        filterGroups: validFilterGroups,
        objectTypeId: filterFamily
      }, filterFamily, isUngatedFor);
    }
    return fromContactSearch(properties, validFilterOperators, filterFamily, isUngatedFor);
  }, [valueType, hasOrConditions, properties, validFilterOperators, quickFilters, validFilterGroups, filterFamily, isUngatedFor]);
  const totalAdvancedFilters = useMemo(() => hasOrConditions ? validFilterGroups.reduce((total, {
    filters: filterOperators
  }) => {
    return total += filterOperators.length;
  }, 0) : validFilterOperators.length, [hasOrConditions, validFilterGroups, validFilterOperators.length]);
  return {
    properties,
    propertiesAsJS,
    propertyGroups,
    totalAdvancedFilters,
    value,
    validFilterGroups
  };
}