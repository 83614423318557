'use es6';

import { FETCH_LISTS, FETCH_LISTS_REQUEST, RESET_EMAIL_DETAILS } from 'EmailData/actions/actionTypes';
import ListRecord from 'EmailData/models/contacts/ListRecord';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { Map as ImmutableMap } from 'immutable';
const initialState = ImmutableMap();
function lists(state = initialState, action) {
  switch (action.type) {
    case FETCH_LISTS:
      if (action.error !== true) {
        let newState = ImmutableMap({});
        for (const value of action.payload.lists) {
          newState = newState.set(value.listId, ListRecord.from(value));
        }
        return state.mergeDeep(newState);
      }
      return state;
    case RESET_EMAIL_DETAILS:
      return initialState;
    default:
      return state;
  }
}
export default asyncResource(lists, {
  requestActions: [FETCH_LISTS_REQUEST],
  responseActions: [FETCH_LISTS],
  invalidatingActions: [RESET_EMAIL_DETAILS]
});