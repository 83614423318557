'use es6';

import { Map as ImmutableMap } from 'immutable';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { RECEIVE_USER_ATTRIBUTES, RECEIVE_USER_ATTRIBUTES_REQUEST, SET_USER_ATTRIBUTE, SET_USER_ATTRIBUTE_REQUEST } from 'email-management-lib/refactor/actions/actionTypes';
const initialState = ImmutableMap({});
function userAttributes(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_USER_ATTRIBUTES:
      {
        const {
          payload: attributes
        } = action;
        return ImmutableMap(Object.assign({}, state.toJS(), attributes));
      }
    case SET_USER_ATTRIBUTE_REQUEST:
      {
        return state.set(action.key, action.value);
      }
    default:
      return state;
  }
}
export default asyncResource(userAttributes, {
  requestActions: [RECEIVE_USER_ATTRIBUTES_REQUEST],
  responseActions: [RECEIVE_USER_ATTRIBUTES, SET_USER_ATTRIBUTE],
  customActions: [SET_USER_ATTRIBUTE_REQUEST]
});