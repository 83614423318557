import { getPropertyValue } from 'marketing-platform-lib/utils/crmUtils';
import { FRAMEWORK_PROPERTY_TO_INSIGHT_PROPERTY } from '../Insights/constant/EmailInsightsPanel';
import { getInsights, parseInsight } from '../Insights/utils';
let tracker;
let applicationName = '';
export function init(newTracker, appName) {
  tracker = newTracker;
  applicationName = appName;
}
export const getUsageTracker = () => tracker;
const trackInteraction = subKey => {
  if (tracker) {
    tracker.track('messagingPublishingInteraction', Object.assign({
      applicationName
    }, subKey));
  }
};
export function trackSendOrScheduleInteraction(interactions) {
  trackInteraction(Object.assign({}, interactions));
}
export function trackInsightsInteraction(interactions) {
  if (tracker) {
    tracker.track('insightsInteraction', Object.assign({
      applicationName
    }, interactions));
  }
}
export function trackInsightsIconClickInteraction(crmObject) {
  const emailType = getPropertyValue(crmObject, 'hs_email_type');
  const parsedInsights = getInsights(crmObject).map(parseInsight);
  for (const {
    context,
    property,
    polarity
  } of parsedInsights) {
    trackInsightsInteraction({
      action: 'click-insights-icon',
      insightName: property,
      insightType: polarity,
      insightContext: context,
      emailType: emailType
    });
  }
}
export function trackInsightsRateClickInteraction(crmObject, propertyName) {
  const emailType = getPropertyValue(crmObject, 'hs_email_type');
  const parsedInsights = getInsights(crmObject).map(parseInsight);
  for (const {
    context,
    property,
    polarity
  } of parsedInsights) {
    if (property !== FRAMEWORK_PROPERTY_TO_INSIGHT_PROPERTY[propertyName]) continue;
    trackInsightsInteraction({
      action: 'click-insights-rate',
      otherAction: `click-${propertyName}`,
      emailType: emailType,
      insightName: property,
      insightType: polarity,
      insightContext: context
    });
  }
}