'use es6';

import { APPEND_LIST_REQUEST, CREATE_LIST_REQUEST, DISMISS_DETAILS_ACTION_MODAL, EDIT_WEB_VERSION_REQUEST, RENDER_DETAILS_ACTION_MODAL, REQUEST_PERFORMANCE_EXPORT, REQUEST_RECIPIENTS_EXPORT, UNBOUNCE_CONTACT_REQUEST, UPDATE_CAMPAIGN_REQUEST } from 'email-management-lib/refactor/actions/actionTypes';
import { RESET_EMAIL_DETAILS } from 'EmailData/actions/actionTypes';
import { MULTI_MOVE_TO_FOLDER } from 'email-management-lib/refactor/actions/actionTypes';
import { Map as ImmutableMap } from 'immutable';
import { NO_ACTION_MODAL } from 'EmailData/utils/details/detailTypeUtil';
const initialState = NO_ACTION_MODAL;
export default function detailsActionModal(state = initialState, action) {
  switch (action.type) {
    case RENDER_DETAILS_ACTION_MODAL:
      return ImmutableMap(action.payload);
    case DISMISS_DETAILS_ACTION_MODAL:
    case REQUEST_RECIPIENTS_EXPORT:
    case REQUEST_PERFORMANCE_EXPORT:
    case UNBOUNCE_CONTACT_REQUEST:
    case APPEND_LIST_REQUEST:
    case CREATE_LIST_REQUEST:
    case UPDATE_CAMPAIGN_REQUEST:
    case MULTI_MOVE_TO_FOLDER:
    case EDIT_WEB_VERSION_REQUEST:
    case RESET_EMAIL_DETAILS:
      return NO_ACTION_MODAL;
    default:
      return state;
  }
}