import { useMemo } from 'react';
import { ASSETS_WITHOUT_FOLDERS_FILTER } from 'framework-listing-lib/internal/Folders/constants/folders';
import useCrmObjectTypeFetchParams from 'framework-listing-lib/internal/hooks/useCrmObjectTypeFetchParams';
import { combineFilters } from 'framework-listing-lib/utils/filters';
import { useHiddenFilter } from 'framework-listing-lib/hooks/useListingLibFilters';
import { useHiddenFolderFilter } from './useFolderProps';
export default function useUnfiledAssetVariables() {
  const variables = useCrmObjectTypeFetchParams();
  const hiddenFolderFilter = useHiddenFolderFilter();
  const hiddenFilter = useHiddenFilter();
  const filterGroups = useMemo(() => {
    const filtersForFolderAndHidden = combineFilters(ASSETS_WITHOUT_FOLDERS_FILTER, hiddenFilter ? hiddenFilter.filterGroups : []);
    return combineFilters(filtersForFolderAndHidden, hiddenFolderFilter ? hiddenFolderFilter.filterGroups : []);
  }, [hiddenFilter, hiddenFolderFilter]);
  return useMemo(() => Object.assign({}, variables, {
    fetchParams: Object.assign({}, variables.fetchParams, {
      filterGroups
    })
  }), [filterGroups, variables]);
}