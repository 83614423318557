import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
const initialState = {
  selection: {},
  querySelected: false
};
const selectionSlice = createSlice({
  name: 'selection',
  initialState,
  reducers: {
    setRowsSelected: (state, action) => {
      action.payload.forEach(({
        rowId,
        selected
      }) => {
        state.selection[rowId] = selected;
      });
      state.querySelected = false;
    },
    selectQuery: state => {
      state.querySelected = true;
    },
    clearSelection: state => {
      state.selection = {};
      state.querySelected = false;
    },
    unselectObjectsNoLongerInTable: (state, action) => {
      const newRowIdsInTable = new Set(action.payload);
      const previouslySelectedRowIds = Object.keys(state.selection);
      state.selection = previouslySelectedRowIds.reduce((acc, rowId) => {
        if (newRowIdsInTable.has(rowId)) {
          acc[rowId] = state.selection[rowId];
        }
        return acc;
      }, {});
    }
  }
});
export const selectionReducer = selectionSlice.reducer;
export const {
  setRowsSelected,
  selectQuery,
  clearSelection,
  unselectObjectsNoLongerInTable
} = selectionSlice.actions;
export const getSelectionState = ({
  selection
}) => selection;
export const getIsQuerySelected = createSelector([getSelectionState], ({
  querySelected
}) => querySelected);
export const getSelectedRowIds = createSelector([getSelectionState], ({
  selection
}) => new Set(Object.entries(selection).filter(([__rowId, selected]) => selected).map(([rowId]) => rowId)));
export const getSelectionCount = createSelector([getSelectedRowIds], selectedRowIds => selectedRowIds.size);
export const getShouldShowBulkActionsContainer = createSelector([getSelectionCount], count => count > 0);
export const makeGetIsRowSelected = rowId => createSelector([getSelectedRowIds], selectedIds => selectedIds.has(rowId));