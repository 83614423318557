export function isErrorMessage(message) {
  return Object.hasOwnProperty.call(message, 'error');
}
export function isApiErrorMessage(message) {
  return Object.hasOwnProperty.call(message, 'error') && Object.hasOwnProperty.call(message.error, 'responseJSON');
}
export function isAlertMessage(message) {
  return Object.hasOwnProperty.call(message, 'alert');
}

// NOTE: shape of 'clone' success is identical to 'update' success