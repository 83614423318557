import { validatePropertyValues } from './api';
import { getErrorMessage } from './getErrorMessage';
import { INVALID, UNKNOWN, VALID } from '../PropertyValidationStatus';
const getValidationStatus = maybeValidationResponse => {
  // The API returns no result sometimes when a property is valid, as long as
  // it's a 200 it means the property was valid
  if (!maybeValidationResponse) {
    return VALID;
  }
  if (maybeValidationResponse.isValid) {
    return VALID;
  }
  return INVALID;
};
const getErrors = (validationRequest, maybeValidationResponse) => {
  if (!maybeValidationResponse) {
    return [];
  }
  if (maybeValidationResponse.isValid === false) {
    return [getErrorMessage(maybeValidationResponse, validationRequest.property, validationRequest.objectTypeId)];
  }
  return [];
};
const createValidationResult = (validationAPIResponses, validationRequest) => {
  const maybeValidationResponse = validationAPIResponses.find(validationResponse => validationResponse.name === validationRequest.property.name);
  const validationStatus = getValidationStatus(maybeValidationResponse);
  const errors = getErrors(validationRequest, maybeValidationResponse);
  return {
    errors,
    validationRequest,
    validationStatus
  };
};
export const inboundDbPropertyValidator = (validationRequest, httpClient) => {
  // Short circuit if the value is not provided since it is always valid (all
  // properties can be cleared)
  if (validationRequest.value === undefined || validationRequest.value === null || validationRequest.value === '') {
    return Promise.resolve({
      errors: [],
      validationRequest,
      validationStatus: VALID
    });
  }
  return validatePropertyValues([{
    name: validationRequest.property.name,
    value: validationRequest.value
  }], httpClient, validationRequest.objectTypeId, validationRequest.objectId).then(apiResponse => {
    return createValidationResult(apiResponse, validationRequest);
  }).catch(() => {
    return {
      errors: [],
      validationRequest,
      validationStatus: UNKNOWN
    };
  });
};