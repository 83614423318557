import { useEffect, useRef, useState } from 'react';
import { makePropertyValuesCache } from '../../../values/PropertyValuesCache';
import { EDIT_INPUT_MODE } from '../../../v2/types/PropertyInputV2Component';
import { getPipelinePropertyName, getPipelineStagePropertyName } from '../utils/pipelineUtils';
export const useRecordInitialPipelineAndStage = ({
  httpClient,
  objectTypeId,
  inputMode,
  objectId
}) => {
  const shouldFetchPropertyValues = objectId !== undefined && objectId !== null && inputMode === EDIT_INPUT_MODE;
  const [recordPipelineId, setRecordPipelineId] = useState(undefined);
  const [recordStageId, setRecordStageId] = useState(undefined);
  const [loading, setLoading] = useState(shouldFetchPropertyValues);

  // The reason we need to instantiate the cache class in the hook itself is due to stale values.
  // If we instantiate the cache as a constant outside the hook, the property values will become stale as the user makes/saves changes.
  // By using a ref, we tie the cache to the lifecycle of the component using the hook.
  // For instance, whenever the PipelineModal is mounted, a new cache is instantiated forcing a refetch each time.
  const propertyValuesCache = useRef(makePropertyValuesCache());
  useEffect(() => {
    if (shouldFetchPropertyValues) {
      propertyValuesCache.current.get({
        objectTypeId,
        objectId
      }, httpClient).then(propertyValues => {
        const pipelineId = propertyValues[getPipelinePropertyName(objectTypeId)];
        const stageId = propertyValues[getPipelineStagePropertyName(objectTypeId)];
        setRecordPipelineId(pipelineId);
        setRecordStageId(stageId);
      }).catch(() => {}).finally(() => setLoading(false));
    }
  }, [httpClient, objectTypeId, objectId, shouldFetchPropertyValues]);
  return {
    recordPipelineId,
    recordStageId,
    propertyValuesLoading: loading
  };
};