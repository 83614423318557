'use es6';

import { Map as ImmutableMap } from 'immutable';
import PortalIdParser from 'PortalIdParser';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { RESET_EMAIL_DETAILS } from 'EmailData/actions/actionTypes';
import { FETCH_SEND_TLDR_SUMMARY, FETCH_SEND_TLDR_SUMMARY_REQUEST } from 'email-management-lib/refactor/actions/actionTypes';
import TldrSendSummary from 'email-management-lib/refactor/models/details/TldrSendSummary';
const initialState = ImmutableMap({});
function tldrSendSummaries(state = initialState, action) {
  switch (action.type) {
    case FETCH_SEND_TLDR_SUMMARY:
      if (action.error !== true) {
        const {
          summary,
          campaignId,
          sendId,
          healthThresholds
        } = action.payload;
        if (summary === '') {
          return state.set(`${PortalIdParser.get()}:${campaignId}:${sendId}`, null);
        }
        return state.set(`${summary.portalId}:${summary.campaignId}:${summary.campaignSendId}`, TldrSendSummary.from(summary, healthThresholds));
      }
      return state;
    case RESET_EMAIL_DETAILS:
      return initialState;
    default:
      return state;
  }
}
export default asyncResource(tldrSendSummaries, {
  requestActions: [FETCH_SEND_TLDR_SUMMARY_REQUEST],
  responseActions: [FETCH_SEND_TLDR_SUMMARY],
  invalidatingActions: [RESET_EMAIL_DETAILS]
});