import { useCallback, useEffect, useMemo, useState } from 'react';
import { ASSET_ADDED_TO_FOLDER } from 'framework-listing-lib/constants/folderEvents';
import listingLibObserver from 'framework-listing-lib/utils/listingLibObserver';
import { getItem, setItem } from 'framework-listing-lib/utils/localStorage';
import useObjectTypeId from 'framework-listing-lib/internal/hooks/useObjectTypeId';
import { safeParseJSON } from 'framework-listing-lib/utils/json';
import useCurrentFolderId from './useCurrentFolderId';
function getLocalStorageKey(objectTypeId) {
  return `LISTING_LIB_MOVE_FOLDERS_${objectTypeId}`;
}
export function getMoveHistoryFromLocalStorage(objectTypeId) {
  const localStorageKey = getLocalStorageKey(objectTypeId);
  const storageKey = getItem(localStorageKey);
  return safeParseJSON(storageKey, {});
}
export default function useFolderMoveHistoryState() {
  const objectTypeId = useObjectTypeId();
  const currentFolderId = useCurrentFolderId();
  const localStorageKey = useMemo(() => getLocalStorageKey(objectTypeId), [objectTypeId]);

  /**
   * Key = folder id
   * Value= list of object ids that have been moved out of @folderId
   */
  const [state, setState] = useState(() => getMoveHistoryFromLocalStorage(objectTypeId));
  const updateStateAndLocalStorage = useCallback(value => {
    setItem(localStorageKey, JSON.stringify(value));
    setState(value);
  }, [localStorageKey]);
  useEffect(() => {
    const handleMove = response => {
      const {
        assetIds,
        previousFolderId
      } = response;
      if (previousFolderId !== currentFolderId) {
        return;
      }
      const copyState = Object.assign({}, state);
      if (!(currentFolderId in copyState)) {
        copyState[currentFolderId] = [];
      }
      copyState[currentFolderId] = [...new Set([...copyState[currentFolderId], ...assetIds])];

      // remove @assetIds from other keys in @copyState
      for (const key of Object.keys(copyState)) {
        const intKey = parseInt(key, 10);
        if (intKey === currentFolderId) {
          continue;
        }
        copyState[intKey] = copyState[intKey].filter(objectId => !copyState[currentFolderId].includes(objectId));
      }
      updateStateAndLocalStorage(copyState);
    };
    listingLibObserver.on(ASSET_ADDED_TO_FOLDER, handleMove);
    return () => {
      listingLibObserver.off(ASSET_ADDED_TO_FOLDER, handleMove);
    };
  }, [currentFolderId, state, updateStateAndLocalStorage]);
  return [state, updateStateAndLocalStorage];
}