import { useMemo } from 'react';
import useDecorateHandlerWithTracking from './useDecorateHandlerWithTracking';
export default function useTrackCampaignSelectInteraction({
  applicationId,
  enableTracking,
  handleCampaignAutoSelectChange,
  handleSelectedOptionChange
}) {
  const eventKey = 'campaignSelectInteraction';
  const handleCampaignAutoSelectChangeWithTracking = useDecorateHandlerWithTracking({
    eventKey,
    filterFn: campaignToAutoSelect => Boolean(campaignToAutoSelect),
    handler: handleCampaignAutoSelectChange,
    properties: {
      applicationId,
      action: 'auto select a campaign'
    }
  });
  const handleSelectedOptionChangeWithTracking = useDecorateHandlerWithTracking({
    eventKey,
    filterFn: ({
      target: {
        value
      }
    }) => Boolean(value),
    handler: handleSelectedOptionChange,
    properties: {
      applicationId,
      action: 'select a campaign'
    },
    additionalPropertiesFn: ({
      target: {
        value
      }
    }) => {
      if (Array.isArray(value)) {
        return {
          containsSearchQuery: Boolean(value.some(val => val.containsSearchQuery)),
          isRecentlyUsed: Boolean(value.some(val => val.isRecentlyUsed))
        };
      }
      return {
        containsSearchQuery: Boolean(value.containsSearchQuery),
        isRecentlyUsed: Boolean(value.isRecentlyUsed)
      };
    }
  });
  return useMemo(() => {
    if (enableTracking) {
      return {
        handleCampaignAutoSelectChangeWithTracking,
        handleSelectedOptionChangeWithTracking
      };
    }
    return {
      handleCampaignAutoSelectChangeWithTracking: handleCampaignAutoSelectChange,
      handleSelectedOptionChangeWithTracking: handleSelectedOptionChange
    };
  }, [enableTracking, handleCampaignAutoSelectChange, handleCampaignAutoSelectChangeWithTracking, handleSelectedOptionChange, handleSelectedOptionChangeWithTracking]);
}