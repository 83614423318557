import { stripDiacritics } from 'UIComponents/utils/Diacritics';
export const getPaginatedOptions = (customOptions, teamOptions, userOptions, offset) => {
  const allTeamOptions = teamOptions[0].options;
  const allUserOptions = userOptions[0].options;
  const allOptions = [...customOptions];
  const addSelectNumOfTeamOptions = offset <= allTeamOptions.length;
  const addTeamsAndSelectNumOfUsersOptions = offset <= allTeamOptions.length + allUserOptions.length;
  if (addSelectNumOfTeamOptions) {
    return allOptions.concat([Object.assign({}, teamOptions[0], {
      options: allTeamOptions.slice(0, offset)
    })]);
  } else if (addTeamsAndSelectNumOfUsersOptions) {
    const userOffset = offset - allTeamOptions.length;
    return allOptions.concat([Object.assign({}, teamOptions[0], {
      options: allTeamOptions
    }), Object.assign({}, userOptions[0], {
      options: allUserOptions.slice(0, userOffset)
    })]);
  } else {
    return allOptions.concat([Object.assign({}, teamOptions[0], {
      options: allTeamOptions
    }), Object.assign({}, userOptions[0], {
      options: allUserOptions
    })]);
  }
};
export const filterOptions = (options, input) => {
  return options.filter(opt => {
    return stripDiacritics(opt.text.toLowerCase()).includes(input);
  });
};
export const checkHasMore = (nextOptions, filteredCustomOptions, filteredTeamOptions, filteredUserOptions) => {
  const nextCustomOptions = nextOptions.slice(0, filteredCustomOptions.length);
  let nextTeamOptions = [];
  let nextUserOptions = [];
  if (nextOptions.length > filteredCustomOptions.length) {
    const teamOptions = nextOptions[filteredCustomOptions.length];
    if ('options' in teamOptions) nextTeamOptions = teamOptions.options;
  }
  if (nextOptions.length > filteredCustomOptions.length + 1) {
    const userOptions = nextOptions[filteredCustomOptions.length + 1];
    if ('options' in userOptions) nextUserOptions = userOptions.options;
  }
  const nextFilteredOptionsLength = nextCustomOptions.length + nextTeamOptions.length + nextUserOptions.length;
  const filteredOptionsLength = filteredCustomOptions.length + filteredTeamOptions[0].options.length + filteredUserOptions[0].options.length;
  return filteredOptionsLength > nextFilteredOptionsLength;
};