import { useEffect } from 'react';
import { Metrics } from './Metrics';
export const incrementComponentMountMetric = componentName => {
  Metrics.counter('component-mounted', {
    name: componentName
  }).increment();
};
const useComponentMountMetric = componentName => {
  useEffect(() => {
    incrementComponentMountMetric(componentName);
  }, [componentName]);
};
export default useComponentMountMetric;