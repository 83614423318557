import { useContext, useMemo } from 'react';
import { HighlySensitivePropertyContext } from './HighlySensitivePropertyContext';
export const useHighlySensitivePropertyValues = ({
  objectTypeId,
  objectId,
  defaultValues
}) => {
  const context = useContext(HighlySensitivePropertyContext);
  if (!context && !defaultValues) {
    throw new Error('useHighlySensitivePropertyValues must be used within a HighlySensitivePropertyContextProvider');
  }
  return useMemo(() => {
    if (!context) {
      return defaultValues;
    }
    return Array.from(context.propertyValuesMap.values()).filter(propertyValue => propertyValue.objectTypeId === objectTypeId && propertyValue.objectId === objectId && propertyValue.status === 'SUCCEEDED' && propertyValue.timestamp === undefined).reduce((acc, propertyValue) => {
      acc[propertyValue.propertyName] = propertyValue.value;
      return acc;
    }, {});
  }, [context, objectTypeId, objectId, defaultValues]);
};