'use es6';

import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { RESET_EMAIL_DETAILS } from 'EmailData/actions/actionTypes';
import { FETCH_TOP_ENGAGED_OPENED_CONTACTS, FETCH_TOP_ENGAGED_OPENED_CONTACTS_REQUEST } from 'email-management-lib/refactor/actions/actionTypes';
import RecipientsList from 'email-management-lib/refactor/models/details/RecipientsList';
const initialState = RecipientsList.from({});
function topEngagedOpenedContacts(state = initialState, action) {
  switch (action.type) {
    case FETCH_TOP_ENGAGED_OPENED_CONTACTS:
      if (action.error) {
        return state;
      }
      return RecipientsList.from(action.payload);
    case RESET_EMAIL_DETAILS:
      return initialState;
    default:
      return state;
  }
}
export default asyncResource(topEngagedOpenedContacts, {
  requestActions: [FETCH_TOP_ENGAGED_OPENED_CONTACTS_REQUEST],
  responseActions: [FETCH_TOP_ENGAGED_OPENED_CONTACTS],
  invalidatingActions: [RESET_EMAIL_DETAILS]
});