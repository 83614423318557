import { SET_SELECTED_HEALTH_SCORE_TYPE } from 'email-health-ui-lib/healthScoreTypeSelection/actions/actionTypes';
import { HEALTH_SCORE_TYPES } from 'email-health-ui-lib/metrics/constants';
import { FETCH_USER_ATTRIBUTES } from 'email-health-ui-lib/shared/actions/types';
import { HEALTH_USER_ATTRIBUTES } from 'email-health-ui-lib/shared/constants/userAttributes';
export const INITIAL_STATE = HEALTH_SCORE_TYPES.GLOBAL;
export default function selectedHealthScoreType(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_SELECTED_HEALTH_SCORE_TYPE:
      return action.payload || INITIAL_STATE;
    case FETCH_USER_ATTRIBUTES:
      return (action.payload.data[HEALTH_USER_ATTRIBUTES.LAST_SELECTED_HEALTH_SCORE_TYPE] || {
        value: INITIAL_STATE
      }).value;
    default:
      return state;
  }
}