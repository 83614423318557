import { registerQuery, useQuery } from 'data-fetching-client';
import { transformBackendViewToInternalFormat } from '../../viewsSearch/dataOperators/backendViews';
import { defaultPinnedViewsApi } from './defaultPinnedViewsApi';
export const DEFAULT_PINNED_VIEWS_QUERY_NAME = 'portalDefaultViews';
const defaultViewsQueryArguments = ['objectTypeId', 'namespaceName'];
export const PortalDefaultViewsQuery = registerQuery({
  fieldName: DEFAULT_PINNED_VIEWS_QUERY_NAME,
  args: defaultViewsQueryArguments,
  fetcher: args => defaultPinnedViewsApi.get({
    objectTypeId: args.objectTypeId,
    namespaceName: args.namespaceName
  }).then(views => {
    return views.map(rawView => {
      return transformBackendViewToInternalFormat(rawView);
    });
  })
});
export const useGetPortalDefaultViews = ({
  objectTypeId,
  namespaceName
}) => {
  return useQuery(PortalDefaultViewsQuery, {
    variables: {
      objectTypeId,
      namespaceName
    }
  });
};