import I18n from 'I18n';
import unescapedText from 'I18n/utils/unescapedText';
const getDateForValueInUserTz = (value, type) => {
  const dateArrayForValue = I18n.moment[type](value).toArray() // makes an array of format [YEAR, MONTH, DAY, HOUR, MIN, SEC, MILSEC]
  .slice(0, 3); // get just the YEAR, MONTH, DAY of the value

  // get the timestamp for 00:00:00 at that date in the user's timezone
  // @ts-expect-error userTz types are wrong
  return I18n.moment.userTz(dateArrayForValue);
};
const getRecentRelativeString = (value, type) => {
  const startOfTodayUserTz = I18n.moment.userTz().startOf('day'); // I18n.moment object for today at 00:00:00 at that date in the user's tz
  const startOfDayForValueUserTz = getDateForValueInUserTz(value, type); // I18n.moment object for 00:00:00 of the start of the DATE supplied in value
  const dayDiff = startOfDayForValueUserTz.diff(startOfTodayUserTz, 'days'); // get value - today for user tz

  switch (dayDiff) {
    case 1:
      {
        return unescapedText('customerDataPropertyUtils.relativeDateTimeLabel.tomorrow');
      }
    case 0:
      {
        return unescapedText('customerDataPropertyUtils.relativeDateTimeLabel.today');
      }
    case -1:
      {
        return unescapedText('customerDataPropertyUtils.relativeDateTimeLabel.yesterday');
      }
    default:
      {
        return null;
      }
  }
};
export const getRelativeDateTimeLabel = ({
  value,
  isDateTime
}) => {
  const numberValue = Number(value);
  const recentDate = getRecentRelativeString(numberValue, isDateTime ? 'userTz' : 'utc');
  if (recentDate) {
    if (!isDateTime) {
      return recentDate;
    }
    return unescapedText('customerDataPropertyUtils.relativeDateTimeLabel.relativeTime', {
      day: recentDate,
      time: I18n.moment.userTz(numberValue).format('LT')
    });
  }
  return null;
};