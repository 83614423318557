import quickFetch from 'quick-fetch';
/* this takes in an objectTypeId and a namespace and returns a formatted url to fetch pinned views from the views api
 */
export const generateGetPinnedViewsUrl = ({
  objectTypeId,
  namespace
}) => {
  const baseUrl = `/sales/v4/views/${objectTypeId}/pinned/view`;
  const urlParams = new URLSearchParams({
    namespace,
    count: '50'
  }); //the count represents "in any scenario, what is the maximum number of a pinned views a user can have" - it allows us to prevent waterfalled paginations for fetching pinned views
  return encodeURI(`${baseUrl}?${urlParams.toString()}`);
};
export const generatePinnedViewsQuickFetchName = ({
  objectTypeId,
  namespace
}) => {
  return `PINNED_VIEWS_QUICK_FETCH_${namespace}_${objectTypeId}`;
};
export const getPinnedViewsQuickFetch = ({
  objectTypeId,
  namespace
}) => {
  const path = generateGetPinnedViewsUrl({
    objectTypeId,
    namespace
  });
  const url = quickFetch.getApiUrl(path);
  const name = generatePinnedViewsQuickFetchName({
    objectTypeId,
    namespace
  });
  return quickFetch.makeEarlyRequest(name, {
    url,
    type: 'GET',
    dataType: 'json',
    contentType: 'application/json'
  });
};