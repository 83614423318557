import { useMemo } from 'react';
import useObjectTypeId from 'framework-listing-lib/internal/hooks/useObjectTypeId';
import useCrmObjectTypeListingFilters from 'framework-listing-lib/hooks/useCrmObjectTypeListingFilters';
import { combineFilters, getAggregatedFilterGroups } from 'framework-listing-lib/utils/filters';
import { DEFAULT_PAGE_SIZE } from 'framework-listing-lib/constants/pagination';
import { ASC, DESC } from 'framework-listing-lib/constants/sorts';
import useQuickFilters from 'framework-listing-lib/hooks/useQuickFilters';
import { useIsFoldersMode } from 'framework-listing-lib/internal/hooks/useUIMode';
import { useHiddenFolderFilter } from 'framework-listing-lib/internal/Folders/hooks/useFolderProps';
import { useFilterGroups, useHiddenFilter } from './useListingLibFilters';
function getDefaultParams(objectTypeId) {
  return {
    count: DEFAULT_PAGE_SIZE,
    offset: 0,
    objectTypeId,
    requestOptions: {
      allPropertiesFetchMode: 'latest_version',
      includeAllProperties: true
    },
    filterGroups: [{
      filters: []
    }],
    query: '',
    sorts: [{
      property: 'hs_created_at',
      order: DESC
    }, {
      property: 'hs_object_id',
      order: ASC
    }]
  };
}

/**
 * Creates fetch params object from @filters object in useCrmObjectTypeListingFilters
 * @param {Object} filters
 */
function getFetchParams({
  deletedObjects,
  filterGroups,
  filtersState,
  hiddenFilter,
  includeHiddenFilter,
  quickFilters
}) {
  const {
    associationPreviews,
    pagination,
    query,
    requestOptions,
    sort
  } = filtersState;
  const {
    count,
    offset
  } = pagination;
  const fetchParams = {
    count,
    filterGroups: getAggregatedFilterGroups({
      deletedObjects,
      filterGroups,
      hiddenFilter,
      includeHiddenFilter,
      quickFilters
    }),
    offset,
    query
  };
  if (Object.keys(sort).length) {
    fetchParams.sorts = [sort];
  }
  if (!fetchParams.query) {
    fetchParams.query = '';
  }
  if (requestOptions) {
    fetchParams.requestOptions = requestOptions;
  }
  if (associationPreviews) {
    fetchParams.associationPreviews = associationPreviews;
  }
  return fetchParams;
}
/**
 * Returns the current crm-search query used in the UI
 */
export default function useCrmSearchQuery({
  includeHiddenFilter
} = {
  includeHiddenFilter: true
}) {
  const objectTypeId = useObjectTypeId();
  const hiddenFilter = useHiddenFilter();
  const {
    deletedObjects,
    filters
  } = useCrmObjectTypeListingFilters();
  const hiddenFolderFilter = useHiddenFolderFilter();
  const isFoldersMode = useIsFoldersMode();
  const filterGroups = useFilterGroups();
  const quickFilters = useQuickFilters();
  const combinedHiddenFilters = useMemo(() => includeHiddenFilter ? {
    filterGroups: combineFilters(hiddenFilter ? hiddenFilter.filterGroups : [], isFoldersMode && hiddenFolderFilter ? hiddenFolderFilter.filterGroups : [])
  } : undefined, [hiddenFilter, hiddenFolderFilter, includeHiddenFilter, isFoldersMode]);
  const fetchParams = useMemo(() => getFetchParams({
    deletedObjects,
    filterGroups,
    filtersState: filters,
    hiddenFilter: combinedHiddenFilters,
    includeHiddenFilter: Boolean(includeHiddenFilter),
    quickFilters
  }), [combinedHiddenFilters, deletedObjects, filterGroups, filters, includeHiddenFilter, quickFilters]);
  return useMemo(() => Object.assign({}, getDefaultParams(objectTypeId), fetchParams), [fetchParams, objectTypeId]);
}