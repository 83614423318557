import { useEffect, useState } from 'react';
import { DEAL_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { hubsSettingClient } from '../../api/hubsSettingApi';
import once from 'transmute/once';
const ENABLED_STRING = 'true';
const DISABLED_STRING = 'false';
const KEY = `Forecasting:DealSplits:Enabled`;
const DEFAULT_VALUE = DISABLED_STRING;
export const getIsDealSplitsEnabled = once(() => hubsSettingClient.fetch(KEY, DEFAULT_VALUE));
export const useIsDealSplitsEnabled = objectType => {
  const [isDealSplitsEnabled, setIsDealSplitsEnabled] = useState(false);
  useEffect(() => {
    if (objectType === DEAL_TYPE_ID) {
      getIsDealSplitsEnabled().then(data => {
        setIsDealSplitsEnabled(data === ENABLED_STRING);
      }).catch(() => {
        setIsDealSplitsEnabled(false);
      });
    }
  }, [objectType]);
  return isDealSplitsEnabled;
};