'use es6';

import { Record } from 'immutable';
const defaults = {
  builtIn: false,
  href: '',
  id: 0,
  linkType: '',
  text: ''
};
class Template extends Record(defaults) {
  static from(json) {
    return new Template(json);
  }
}
export default Template;