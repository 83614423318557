import I18n from 'I18n';
import isValidI18nKey from 'I18n/utils/isValidI18nKey';
function capitalizeFirstLetter(value) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}
function formatObjectName(objectTypeName, textTransform) {
  switch (textTransform) {
    default:
    case 'lowercase':
      {
        const words = objectTypeName.split(' ');
        if (words.length === 1) {
          return objectTypeName.toLowerCase();
        }

        /**
         * If the object type name has multiple words, lowercase only the ones that don't
         * have consecutive uppercase letters.
         *
         * Ex: SMS Message -> SMS message
         */
        return words.map(word => {
          if (word.replace(/[a-z]/g, '').length <= 1) {
            return word.toLowerCase();
          }
          return word;
        }).join(' ');
      }
    case 'uppercase':
      return objectTypeName.toUpperCase();
    case 'none':
      return objectTypeName;
    case 'capitalize':
      return capitalizeFirstLetter(objectTypeName);
  }
}
function getObjectTypeName({
  fallbackTranslationKey,
  form,
  textTransform,
  translationKey,
  typeDefinition
}) {
  /**
   * Will give preference to the translations instead of the type definition
   * so we don't break the experience for teams that would like to use a different translation
   * as opposed to what's defined in PUMA.
   */
  if (isValidI18nKey(translationKey)) {
    return formatObjectName(I18n.text(translationKey), textTransform);
  }
  if (form === 'plural' && typeDefinition && typeDefinition.pluralForm) {
    return formatObjectName(typeDefinition.pluralForm, textTransform);
  }
  if (form === 'singular' && typeDefinition && typeDefinition.singularForm) {
    return formatObjectName(typeDefinition.singularForm, textTransform);
  }
  return formatObjectName(I18n.text(fallbackTranslationKey));
}
export function getObjectTypeNamePlural({
  objectTypeId,
  textTransform,
  typeDefinition
}) {
  return getObjectTypeName({
    fallbackTranslationKey: 'marketingPlatformLib.objectNames.ASSET.plural',
    form: 'plural',
    translationKey: `marketingPlatformLib.objectNames.${objectTypeId}.plural`,
    textTransform,
    typeDefinition
  });
}
export function getObjectTypeNameSingular({
  objectTypeId,
  textTransform,
  typeDefinition
}) {
  return getObjectTypeName({
    fallbackTranslationKey: 'marketingPlatformLib.objectNames.ASSET.singular',
    form: 'singular',
    translationKey: `marketingPlatformLib.objectNames.${objectTypeId}.singular`,
    textTransform,
    typeDefinition
  });
}