'use es6';

import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { RESET_EMAIL_DETAILS } from 'EmailData/actions/actionTypes';
import { FETCH_USERS, FETCH_USERS_REQUEST } from 'email-management-lib/refactor/actions/actionTypes';
import UserModel from 'email-management-lib/refactor/models/details/actionModals/UserModel';
import UsersData from 'email-management-lib/refactor/models/details/actionModals/UsersData';
const initialState = new UsersData();
const users = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS:
      if (action.error) {
        return state;
      }
      return state.set('users', action.payload.map(user => UserModel.from(Object.assign({}, user, {
        isHubspotUser: true
      })))).set('isFetched', true);
    case RESET_EMAIL_DETAILS:
      return initialState;
    default:
      return state;
  }
};
export default asyncResource(users, {
  requestActions: [FETCH_USERS_REQUEST],
  responseActions: [FETCH_USERS],
  invalidatingActions: [RESET_EMAIL_DETAILS]
});