import { useCallback } from 'react';

/**
 * Find the next row/col in either top|right|down|left direction based on keyboard navigation and
 * focus in next cell when found.
 */
export default function useHandleKeyDown() {
  return useCallback(event => {
    let maybeNextTdElement = null;

    /**
     * User pressed Escape key, remove stylings from the cell that is currently highlighted.
     */
    if (event.key === 'Escape') {
      maybeNextTdElement = document.querySelector(`th[data-test-col-index="0"]`);
      if (maybeNextTdElement) {
        maybeNextTdElement.focus();
        maybeNextTdElement.blur();
      }
      return;
    }
    const maybeTdElement = event.currentTarget && (event.currentTarget.nodeName === 'TD' || event.currentTarget.nodeName === 'TH');
    const maybeParentTdElement = event.currentTarget.parentNode && (event.currentTarget.parentNode.nodeName === 'TD' || event.currentTarget.parentNode.nodeName === 'TH');

    /**
     * Ignore if not table header (TH) or table cell (TD)
     */
    if (!maybeTdElement && !maybeParentTdElement) {
      return;
    }
    const tdOrThElement = maybeTdElement ? event.currentTarget : event.currentTarget.parentNode;
    if (!tdOrThElement) {
      return;
    }
    const isTd = tdOrThElement.nodeName === 'TD';
    const colIndexAttr = tdOrThElement.getAttribute('data-test-col-index');
    const rowIndexAttr = isTd ? tdOrThElement.getAttribute('data-test-row-index') : '-1';
    if (!colIndexAttr || !rowIndexAttr) {
      return;
    }
    let colIndex = parseInt(colIndexAttr, 10);
    let rowIndex = parseInt(rowIndexAttr, 10);
    switch (event.key) {
      default:
        break;
      case 'ArrowUp':
        rowIndex--;
        break;
      case 'ArrowRight':
        colIndex++;
        break;
      case 'ArrowDown':
        rowIndex++;
        break;
      case 'ArrowLeft':
        colIndex--;
        break;
    }

    /**
     * Focus on bulk action column header (when visible) when @rowIndex is -1 and @colIndex is -1
     */
    if (rowIndex === -1 && colIndex === -1) {
      maybeNextTdElement = document.querySelector(`th[data-test-id="crm-table-bulk-action-cell-header"]`);
    } else if (colIndex === -1) {
      /**
       * Focus on bulk action column (when visible) when @colIndex is -1
       */
      maybeNextTdElement = document.querySelector(`td[data-test-id="crm-table-bulk-action-cell"][data-test-row-index="${rowIndex}"]`);
    } else if (rowIndex === -1) {
      /**
       * Focus the table header (TH) when @rowIndex is -1.
       */
      maybeNextTdElement = document.querySelector(`th[data-test-col-index="${colIndex}"]`);
    } else {
      maybeNextTdElement = document.querySelector(`td[data-test-id="crm-table-cell"][data-test-col-index="${colIndex}"][data-test-row-index="${rowIndex}"]`);
    }
    if (maybeNextTdElement) {
      maybeNextTdElement.focus();
    }
  }, []);
}