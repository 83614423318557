import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
const SELECT_ALL_CHECKBOX_WIDTH = 44;
const initialState = {
  widths: {}
};
const widthsSlice = createSlice({
  name: 'widths',
  initialState,
  reducers: {
    setAllWidths: (state, action) => {
      state.widths = action.payload;
    },
    setWidth: (state, {
      payload: {
        columnKey,
        width
      }
    }) => {
      state.widths[columnKey] = width;
    }
  }
});
export const widthsReducer = widthsSlice.reducer;
export const {
  setAllWidths,
  setWidth
} = widthsSlice.actions;
const getWidths = ({
  widths: {
    widths
  }
}) => widths;
export const getTableMinWidth = createSelector([getWidths], widths => Object.values(widths).reduce((sum, width) => sum + width, 0));
export const makeGetColumnWidth = columnKey => createSelector([getWidths], widths => widths[columnKey]);
export const makeGetFrozenOffsets = (columnKeys, selectable) => createSelector([getWidths], widths => {
  const initialOffsets = selectable ? [SELECT_ALL_CHECKBOX_WIDTH] : [0];
  return columnKeys.reduce((offsets, key) => {
    var _widths$key;
    return [...offsets, offsets[offsets.length - 1] + ((_widths$key = widths[key]) !== null && _widths$key !== void 0 ? _widths$key : 0)];
  }, initialOffsets);
});