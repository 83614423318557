import { trackDashboardInteraction } from 'EmailComponents/utils/tracking';
import { SELECT_STATE, SELECT_EMAIL_TYPE, SELECT_BUSINESS_UNIT, SELECT_CAMPAIGN, SELECT_STATE_FILTER, SELECT_SUBSCRIPTION, SELECT_TEAM } from 'email-management-lib/refactor/actions/actionTypes';
import { fetchEmails } from 'email-management-lib/refactor/actions/manage/general';
import { updatePathParams, updateQueryParams } from 'email-management-lib/refactor/actions/params';
export function selectState(state) {
  return dispatch => {
    dispatch({
      type: SELECT_STATE,
      payload: {
        state
      }
    });
    dispatch(updatePathParams({
      state,
      folderId: null
    }, fetchEmails, `/manage/state/${state}`));
  };
}
export function selectStateFilter(state) {
  return dispatch => {
    dispatch({
      type: SELECT_STATE_FILTER,
      payload: {
        state
      }
    });
    const stateQuery = state === 'all' ? undefined : state;
    dispatch(updateQueryParams({
      state: stateQuery
    }, fetchEmails));
  };
}
export function selectEmailType(emailType) {
  return dispatch => {
    trackDashboardInteraction({
      filtering: 'filter-emails'
    });
    dispatch({
      type: SELECT_EMAIL_TYPE,
      payload: {
        emailType
      }
    });
    const typeQuery = emailType === '' ? undefined : emailType;
    dispatch(updateQueryParams({
      type: typeQuery,
      outageFilter: null
    }, fetchEmails));
  };
}
export function clearAllEmailFilters() {
  return dispatch => {
    dispatch(updateQueryParams({
      businessUnitId: undefined,
      custom: undefined,
      campaign: undefined,
      team: undefined,
      type: undefined,
      outageFilter: undefined,
      user: undefined
    }, fetchEmails));
  };
}
export function clearEmailTypeFilter() {
  return dispatch => {
    dispatch(updateQueryParams({
      type: undefined,
      outageFilter: null
    }, fetchEmails));
  };
}
export function clearEmailBusinessUnitFilter() {
  return dispatch => {
    dispatch(updateQueryParams({
      businessUnitId: undefined
    }, fetchEmails));
  };
}
export function clearEmailCampaignFilter() {
  return dispatch => {
    dispatch(updateQueryParams({
      campaign: undefined
    }, fetchEmails));
  };
}
export function selectCampaign(guid) {
  return dispatch => {
    trackDashboardInteraction({
      filtering: 'filter-campaign'
    });
    dispatch({
      type: SELECT_CAMPAIGN,
      payload: {
        guid
      }
    });
    const campaignQuery = guid === '' ? undefined : guid;
    dispatch(updateQueryParams({
      campaign: campaignQuery
    }, fetchEmails));
  };
}
export function clearEmailSubscriptionFilter() {
  return dispatch => {
    dispatch(updateQueryParams({
      subscription: undefined
    }, fetchEmails));
  };
}
export function selectSubscription(subscriptionId) {
  return dispatch => {
    trackDashboardInteraction({
      filtering: 'filter-subscription'
    });
    dispatch({
      type: SELECT_SUBSCRIPTION,
      payload: {
        subscriptionId
      }
    });
    const subscriptionQuery = subscriptionId === '' ? undefined : subscriptionId;
    dispatch(updateQueryParams({
      subscription: subscriptionQuery
    }, fetchEmails));
  };
}
export function selectBusinessUnit(buId) {
  return dispatch => {
    trackDashboardInteraction({
      filtering: 'business-unit'
    });
    dispatch({
      type: SELECT_BUSINESS_UNIT,
      payload: {
        buId
      }
    });
    const businessUnitId = buId === '' ? undefined : buId;
    dispatch(updateQueryParams({
      businessUnitId
    }, fetchEmails));
  };
}
export function selectTeam(teamId) {
  return dispatch => {
    trackDashboardInteraction({
      filtering: 'filter-team'
    });
    dispatch({
      type: SELECT_TEAM,
      payload: {
        teamId
      }
    });
    const teamQuery = teamId === 'all' ? undefined : teamId;
    dispatch(updateQueryParams({
      team: teamQuery
    }, fetchEmails));
  };
}
function getQueryByType(usersAndTeams, filterType) {
  return usersAndTeams.filter(({
    type
  }) => type === filterType).map(({
    id
  }) => id);
}
export function selectUsersAndTeams(usersAndTeams) {
  return dispatch => {
    const teamQuery = getQueryByType(usersAndTeams, 'TEAM');
    const userQuery = getQueryByType(usersAndTeams, 'USER');
    const customQuery = getQueryByType(usersAndTeams, 'CUSTOM');
    if (teamQuery.length > 0 || userQuery.length > 0 || customQuery.length > 0) {
      trackDashboardInteraction({
        filtering: 'filter-users-and-teams'
      });
    }
    dispatch(updateQueryParams({
      team: teamQuery,
      user: userQuery,
      custom: customQuery
    }, fetchEmails));
  };
}
export function clearUsersAndTeams() {
  return dispatch => {
    trackDashboardInteraction({
      filtering: 'clear-users-and-teams'
    });
    dispatch(updateQueryParams({
      team: null,
      user: null,
      custom: null
    }, fetchEmails));
  };
}