import { useCallback, useMemo } from 'react';
import { useHttpClient } from '../../../../client/HttpClientContext';
import { useObjectTypeId } from '../../../../v2/hooks/useObjectTypeId';
import { cache } from '../../../leads/leadPipelineSettingsCache';
import { SharedPipelinesCache } from '../../../../panels/PipelinesCache';
import { LEAD_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { useAsyncFunction } from '../../../../utils/hooks/useAsyncFunction';
export const useGetLeadStageQualificationValidator = () => {
  const objectTypeId = useObjectTypeId();
  const httpClient = useHttpClient();
  const getLeadPipelineSettings = useCallback(() => {
    return cache.get('', httpClient).then(response => response.result);
  }, [httpClient]);
  const {
    data: leadPipelineSettingsData,
    loading: leadPipelineSettingsLoading,
    error: leadPipelineSettingsError
  } = useAsyncFunction(getLeadPipelineSettings, {
    skip: objectTypeId !== LEAD_TYPE_ID
  });
  const getPipelines = useCallback(() => {
    return SharedPipelinesCache.get({
      objectTypeId,
      httpClient
    });
  }, [objectTypeId, httpClient]);
  const {
    data: pipelinesData,
    loading: pipelinesLoading,
    error: pipelinesError
  } = useAsyncFunction(getPipelines, {
    skip: objectTypeId !== LEAD_TYPE_ID
  });
  const stageChangeValidator = useCallback(request => {
    const {
      targetStageId,
      targetPipelineId
    } = request;

    // If we don't have data to validate against, fail open to not block changes
    if (!pipelinesData || !leadPipelineSettingsData || leadPipelineSettingsError || pipelinesError) {
      return {
        isValid: true
      };
    }
    const selectedPipeline = pipelinesData[targetPipelineId];
    const selectedPipelineSettings = leadPipelineSettingsData[targetPipelineId];
    const selectedStage = selectedPipeline.stages.find(pipelineStage => pipelineStage.stageId === targetStageId);
    if (selectedPipelineSettings && selectedPipelineSettings.requireDealCreationToQualify && selectedStage && selectedStage.metadata.leadState === 'QUALIFIED') {
      return {
        isValid: false,
        requirement: 'DEAL_CREATION_REQUIRED'
      };
    }
    return {
      isValid: true
    };
  }, [leadPipelineSettingsData, leadPipelineSettingsError, pipelinesData, pipelinesError]);
  return useMemo(() => ({
    loading: leadPipelineSettingsLoading || pipelinesLoading,
    stageChangeValidator
  }), [leadPipelineSettingsLoading, pipelinesLoading, stageChangeValidator]);
};