import { useMemo } from 'react';
import { registerQuery, useQuery } from 'data-fetching-client';
import { getFrameworkDataSchemasClient } from 'framework-data-schema-resolvers';
import { parse } from 'hub-http/helpers/params';
import { UI_MODE } from 'framework-listing-lib/constants';
import useApplicationId from 'framework-listing-lib/internal/hooks/useApplicationId';
import { useHasCustomFetcher } from 'framework-listing-lib/internal/hooks/useFetcher';
import useObjectTypeId from 'framework-listing-lib/internal/hooks/useObjectTypeId';
import { useIsGalleryMode, useIsListMode } from 'framework-listing-lib/internal/hooks/useUIMode';
import useUserInfo from 'framework-listing-lib/internal/hooks/useUserInfo';
import { useListingLibData } from 'framework-listing-lib/internal/providers/DataProvider';
import { LISTING_LIB_APP_SETTING_NAMESPACE } from '../constants';
import { getHasApplicationId, getIsGatedToAll, getIsUngatedToAll } from '../utils';
const GET_FRAMEWORK_APP_SETTINGS_FIELD_NAME = 'listingLibFrameworkAppSettings';
const GET_FRAMEWORK_APP_SETTINGS = registerQuery({
  fieldName: GET_FRAMEWORK_APP_SETTINGS_FIELD_NAME,
  fetcher() {
    return getFrameworkDataSchemasClient().then(client => {
      return client.graph.frameworkTypes.getObjects({
        appSettingNames: [LISTING_LIB_APP_SETTING_NAMESPACE]
      });
    });
  }
});
export function useFetchFrameworkAppSettings() {
  const currentObjectTypeId = useObjectTypeId();
  const {
    data,
    error,
    loading
  } = useQuery(GET_FRAMEWORK_APP_SETTINGS);
  const appSettingsForObject = useMemo(() => {
    var _data$GET_FRAMEWORK_A;
    return data === null || data === void 0 || (_data$GET_FRAMEWORK_A = data[GET_FRAMEWORK_APP_SETTINGS_FIELD_NAME]) === null || _data$GET_FRAMEWORK_A === void 0 || (_data$GET_FRAMEWORK_A = _data$GET_FRAMEWORK_A.find(objectTypeDefinition => objectTypeDefinition.objectTypeId === currentObjectTypeId)) === null || _data$GET_FRAMEWORK_A === void 0 ? void 0 : _data$GET_FRAMEWORK_A.typeMetadata;
  }, [currentObjectTypeId, data]);
  return {
    data: appSettingsForObject || undefined,
    error,
    loading
  };
}
function useAppSettings() {
  var _appSettings$LISTING_;
  const {
    appSettings
  } = useListingLibData();
  return appSettings === null || appSettings === void 0 || (_appSettings$LISTING_ = appSettings[LISTING_LIB_APP_SETTING_NAMESPACE]) === null || _appSettings$LISTING_ === void 0 ? void 0 : _appSettings$LISTING_.metadata;
}

/**
 * Read from app settings and only return ui mode options with
 *  - empty gates and scopes OR
 *  - gates array is not empty, scopes array is empty and user has ALL gates OR
 *  - gates array is empty, scopes array is not empty and user has ALL scopes
 */
export function useAppSettingsUIModes() {
  const {
    isUngatedFor,
    hasScope
  } = useUserInfo();
  const appSettings = useAppSettings();
  const applicationId = useApplicationId();
  if (!appSettings || !appSettings.uiModes || appSettings.uiModes.value.length === 0) {
    return [UI_MODE.LIST];
  }
  const enabledUIModes = appSettings.uiModes.value.filter(uiModeOption => {
    const isUngatedToAll = getIsUngatedToAll({
      gates: uiModeOption.gates,
      isUngatedFor
    });
    const isGatedToAll = getIsGatedToAll({
      gates: uiModeOption.gates,
      isUngatedFor
    });
    let hasAllScopes = true;
    if (uiModeOption.scopes && uiModeOption.scopes.length) {
      hasAllScopes = uiModeOption.scopes.every(hasScope);
    }
    const hasApplicationId = getHasApplicationId({
      currentApplicationId: applicationId,
      applicationIds: uiModeOption.applicationIds
    });
    return isUngatedToAll && isGatedToAll && hasAllScopes && hasApplicationId;
  }).map(uiModeOption => uiModeOption.name);
  return [UI_MODE.LIST, ...enabledUIModes];
}
/**
 * Read from app settings and only return the option when enabled:
 *  - option is present AND
 *  - gates and scopes are empty OR
 *  - gates array is not empty, scopes array is empty and user has ALL gates OR
 *  - gates array is empty, scopes array is not empty and user has ALL scopes
 * @param optionName
 */
export function useAppSettingsOption(optionName) {
  const {
    isUngatedFor,
    hasScope
  } = useUserInfo();
  const appSettings = useAppSettings();
  const applicationId = useApplicationId();
  if (!appSettings || !appSettings.options) {
    return undefined;
  }
  const appSettingsOption = appSettings.options.value.find(val => val.name === optionName);
  if (!appSettingsOption) {
    return undefined;
  }
  const isUngatedToAll = getIsUngatedToAll({
    gates: appSettingsOption.gates,
    isUngatedFor
  });
  const isGatedToAll = getIsGatedToAll({
    gates: appSettingsOption.gates,
    isUngatedFor
  });
  let hasAllScopes = true;
  if (appSettingsOption.scopes && appSettingsOption.scopes.length) {
    hasAllScopes = appSettingsOption.scopes.every(hasScope);
  }
  const hasApplicationId = getHasApplicationId({
    currentApplicationId: applicationId,
    applicationIds: appSettingsOption.applicationIds
  });
  if (isUngatedToAll && isGatedToAll && hasAllScopes && hasApplicationId) {
    return appSettingsOption;
  }
  return undefined;
}
export function useHasCustomProperties() {
  const hasCustomPropertiesOption = useAppSettingsOption('hasCustomProperties');
  return Boolean(hasCustomPropertiesOption);
}
export function useHasEditColumns() {
  const isListMode = useIsListMode();
  const hasEditColumnsOption = useAppSettingsOption('hasEditColumns');
  return isListMode && Boolean(hasEditColumnsOption);
}
export function useHasExport() {
  const isListMode = useIsListMode();
  const hasCustomFetcher = useHasCustomFetcher();
  const hasExportOption = useAppSettingsOption('hasExport');
  return useMemo(() =>
  /**
   * Even if there is an framework app setting for @hasExport, we turn it off for apps using a custom fetcher.
   * Most of the time, the information coming from teams custom fetcher won't be the same as if using crm-search.
   */
  !hasCustomFetcher && isListMode && Boolean(hasExportOption), [hasCustomFetcher, hasExportOption, isListMode]);
}
export function useHasNestedFolders() {
  const hasNestedFolders = useAppSettingsOption('hasNestedFolders');
  return Boolean(hasNestedFolders);
}
export function useHasInlineEdit() {
  const hasInlineEdit = useAppSettingsOption('hasInlineEdit');
  /**
   * Inline editing can also be enabled via query string.
   * This is useful in ATs where we don't have access to change FAS on the fly.
   */
  const queryString = parse(window.location.search.substring(1));
  return Boolean(hasInlineEdit) || queryString.hasInlineEdit === 'true';
}
export function useHasCustomizeGallery() {
  const isGalleryMode = useIsGalleryMode();
  const hasCustomizeGalleryOption = useAppSettingsOption('hasCustomizeGallery');
  return isGalleryMode && Boolean(hasCustomizeGalleryOption);
}
export function useHasBUNavIntegration() {
  const hasBUNavIntegration = useAppSettingsOption('hasBUNavIntegration');
  return Boolean(hasBUNavIntegration);
}