import { Map as ImmutableMap } from 'immutable';
import { RESET_EMAIL_DETAILS } from 'EmailData/actions/actionTypes';
import { OVER_TIME_PERIOD, OVER_TIME_TIMEZONE_TYPE } from 'EmailData/utils/details/detailTypeUtil';
import { SELECT_OVER_TIME_PERIOD, SELECT_OVER_TIME_TIMEZONE } from 'email-management-lib/refactor/actions/actionTypes';
export const initialState = ImmutableMap({
  timezone: OVER_TIME_TIMEZONE_TYPE.TRUE_TIMES,
  period: OVER_TIME_PERIOD.DAY
});
export default function overTimeSelect(state = initialState, action) {
  switch (action.type) {
    case SELECT_OVER_TIME_TIMEZONE:
      return state.set('timezone', action.payload.value);
    case SELECT_OVER_TIME_PERIOD:
      return state.set('period', action.payload.value);
    case RESET_EMAIL_DETAILS:
      return initialState;
    default:
      return state;
  }
}