/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-misused-promises */

// @ts-expect-error not migrated to TS yet
import { growl } from 'EmailComponents/errors/growl';
import { DELETE_PORTAL_SETTING, UPDATE_PORTAL_SETTINGS
// @ts-expect-error not migrated to TS yet
} from 'EmailData/portalSettings/actions/types';
import { DISMISS_ONBOARDING_TOUR_ENTRY, MAYBE_LATER_DISMISS_ONBOARDING_TOUR_ENTRY } from 'email-management-lib/refactor/actions/actionTypes';
import { HUB_SETTING_ONBOARDING_DISMISS_KEY, HUB_SETTING_UPDATED_ONBOARDING_TYPE, ONBOARDING_STEP_TO_DISMISS_KEY } from 'email-management-lib/refactor/constants/manage/onboarding';
import { deleteSettingRequest, updateSettingRequest } from 'EmailData/portalSettings/api';
import I18n from 'I18n';
import { writeToUserAttributesInManage } from 'EmailData/api/hubUserAttributes';
import { fetchMailchimpImportStatus, fetchHistoricalImports } from 'email-management-lib/refactor/actions/manage/historicalImports';
import { fetchLists } from 'email-management-lib/refactor/actions/manage/lists';
import { fetchSentEmailsCount } from 'email-management-lib/refactor/actions/manage/general';
function updateSetting(settingKey, settingValue) {
  return dispatch => {
    dispatch({
      type: UPDATE_PORTAL_SETTINGS,
      settingKey,
      settingValue
    });
    return updateSettingRequest(settingKey, settingValue);
  };
}
function deleteSetting(settingKey) {
  return dispatch => {
    dispatch({
      type: DELETE_PORTAL_SETTING,
      settingKey
    });
    return deleteSettingRequest(settingKey);
  };
}
export function undoAction(actionStep) {
  return dispatch => {
    return dispatch(deleteSetting(ONBOARDING_STEP_TO_DISMISS_KEY[actionStep]));
  };
}
export function dismissAction(actionStep) {
  return dispatch => {
    return dispatch(updateSetting(ONBOARDING_STEP_TO_DISMISS_KEY[actionStep], true));
  };
}
export function selectOnboardingType(onboardingType) {
  return dispatch => {
    return dispatch(updateSetting(HUB_SETTING_UPDATED_ONBOARDING_TYPE, onboardingType));
  };
}
export function resetSelection() {
  return dispatch => {
    return dispatch(deleteSetting(HUB_SETTING_UPDATED_ONBOARDING_TYPE));
  };
}
export function dismissOnboarding() {
  return dispatch => {
    return dispatch(updateSetting(HUB_SETTING_ONBOARDING_DISMISS_KEY, true));
  };
}
export function fetchOnboardingData() {
  return dispatch => {
    return Promise.all([dispatch(fetchLists()), dispatch(fetchHistoricalImports()), dispatch(fetchMailchimpImportStatus()), dispatch(fetchSentEmailsCount())]).catch(() => {
      growl('error', I18n.text('email-management.errors.boundaryDefault'));
    });
  };
}
export function permanentlyDismissOnboardingTourPrompt() {
  return {
    type: DISMISS_ONBOARDING_TOUR_ENTRY
  };
}
export function temporarilyDismissOnboardingTourPrompt() {
  return {
    type: MAYBE_LATER_DISMISS_ONBOARDING_TOUR_ENTRY
  };
}
export function permanentlyDismissOnboardingTourPromptAndReturnPromise() {
  return writeToUserAttributesInManage({
    dismissedOnboardingTourEntry: true
  });
}