import { RECIPIENT_QUERY_FILTERS, RECIPIENT_QUERY_TABS } from 'EmailData/utils/details/detailTypeUtil';
export const BASE_SUMMARY_TYPES = {
  HARD_BOUNCE: 'HARD_BOUNCE',
  OPEN: 'OPEN',
  UNSUBSCRIBE: 'UNSUBSCRIBE',
  SPAM_REPORT: 'SPAM_REPORT',
  LOCAL_TIME: 'LOCAL_TIME',
  CONTACTS_LOST: 'CONTACTS_LOST',
  ENGAGEMENT: 'ENGAGEMENT',
  DMARC_REPORT: 'DMARC_REPORT'
};
export const BLACKLISTED_SUMMARIES = [];
export const TLDR_ILLUSTRATIONS = {
  SUCCESS: 'success',
  IDEA: 'idea'
};
export const SUMMARY_TYPES_TO_TABS_MAP = {
  [BASE_SUMMARY_TYPES.HARD_BOUNCE]: `${RECIPIENT_QUERY_TABS.BOUNCED}?bounceType=${RECIPIENT_QUERY_FILTERS.HARD_BOUNCED}`,
  [BASE_SUMMARY_TYPES.OPEN]: RECIPIENT_QUERY_TABS.OPENED,
  [BASE_SUMMARY_TYPES.UNSUBSCRIBE]: RECIPIENT_QUERY_TABS.UNSUBSCRIBED,
  [BASE_SUMMARY_TYPES.SPAM_REPORT]: RECIPIENT_QUERY_TABS.SPAM
};
export const SUMMARY_TYPES_TO_HEALTH_METRICS_MAP = {
  [BASE_SUMMARY_TYPES.HARD_BOUNCE]: 'hardBounce',
  [BASE_SUMMARY_TYPES.OPEN]: 'open',
  [BASE_SUMMARY_TYPES.SPAM_REPORT]: 'spamReport',
  [BASE_SUMMARY_TYPES.UNSUBSCRIBE]: 'unsubscribe'
};
export const SUGGESTION_SUMMARIES = {
  [BASE_SUMMARY_TYPES.LOCAL_TIME]: BASE_SUMMARY_TYPES.LOCAL_TIME
};
export const NEGATIVE_SUMMARIES = {
  [BASE_SUMMARY_TYPES.HARD_BOUNCE]: BASE_SUMMARY_TYPES.HARD_BOUNCE,
  [BASE_SUMMARY_TYPES.OPEN]: BASE_SUMMARY_TYPES.OPEN,
  [BASE_SUMMARY_TYPES.UNSUBSCRIBE]: BASE_SUMMARY_TYPES.UNSUBSCRIBE,
  [BASE_SUMMARY_TYPES.SPAM_REPORT]: BASE_SUMMARY_TYPES.SPAM_REPORT
};
export const POSITIVE_SUMMARIES = {
  [BASE_SUMMARY_TYPES.CONTACTS_LOST]: BASE_SUMMARY_TYPES.CONTACTS_LOST,
  [BASE_SUMMARY_TYPES.ENGAGEMENT]: BASE_SUMMARY_TYPES.ENGAGEMENT
};
export const SENTIMENTAL_LEVELS = {
  GLOBAL: 'GLOBAL',
  PORTAL: 'PORTAL',
  LIST: 'LIST'
};
export const SENTIMENTAL_TYPES = {
  GOOD: 'GOOD',
  BAD: 'BAD',
  NEUTRAL: 'NEUTRAL'
};
const generateSummaryType = (summaryType, sentimentalType, sentimentalLevel = '') => {
  if (sentimentalType === SENTIMENTAL_TYPES.NEUTRAL || sentimentalType === SENTIMENTAL_TYPES.GOOD) {
    return {
      BASE: summaryType,
      TYPE: sentimentalType
    };
  }
  return {
    BASE: summaryType,
    TYPE: sentimentalType,
    LEVEL: sentimentalLevel
  };
};
const generateAllSummaryTypes = () => {
  const generatedSummaries = {};
  Object.keys(SUGGESTION_SUMMARIES).forEach(suggestionType => {
    generatedSummaries[`${suggestionType}_SUGGESTION`] = generateSummaryType(suggestionType, SENTIMENTAL_TYPES.NEUTRAL);
  });
  Object.keys(POSITIVE_SUMMARIES).forEach(suggestionType => {
    generatedSummaries[`${suggestionType}`] = generateSummaryType(suggestionType, SENTIMENTAL_TYPES.GOOD);
  });
  Object.keys(NEGATIVE_SUMMARIES).forEach(sentimentalType => {
    Object.keys(SENTIMENTAL_LEVELS).forEach(sentimentalLevel => {
      generatedSummaries[`${sentimentalType}_${sentimentalLevel}_THRESHOLD_BAD`] = generateSummaryType(sentimentalType, SENTIMENTAL_TYPES.BAD, sentimentalLevel);
    });
  });
  generatedSummaries[`${BASE_SUMMARY_TYPES.DMARC_REPORT}_THRESHOLD_BAD`] = generateSummaryType(BASE_SUMMARY_TYPES.DMARC_REPORT, SENTIMENTAL_TYPES.BAD);
  return generatedSummaries;
};

/*
  Generates an object of the verbose summary type keyname with an
  object of the base, type and level breakdown. 26 Types in all for now.
  E.g.
    ALL_SUMMARY_TYPES = {
      HARD_BOUNCE_GLOBAL_THRESHOLD_GOOD: {
        BASE: 'HARD_BOUNCE',
        TYPE: 'GOOD',
        LEVEL: 'GLOBAL'
      }
    }
*/
export const ALL_SUMMARY_TYPES = generateAllSummaryTypes();