import quickFetch from 'quick-fetch';
export function makeQuickFetchRequest(options) {
  const {
    makeRequestFn,
    removeAfterFetch,
    requestName
  } = options;
  if (!requestName) {
    return makeRequestFn();
  }
  const earlyRequest = quickFetch.getRequestStateByName(requestName);
  if (!earlyRequest) {
    return makeRequestFn();
  }
  const resultPromise = new Promise((resolve, reject) => {
    earlyRequest.whenFinished(result => {
      resolve(result);
      if (removeAfterFetch === undefined || removeAfterFetch) {
        quickFetch.removeEarlyRequest(requestName);
      }
    });
    earlyRequest.onError(() => {
      makeRequestFn().then(resolve, reject).catch(() => {
        // Eslint catch statement
      });
      if (removeAfterFetch === undefined || removeAfterFetch) {
        quickFetch.removeEarlyRequest(requestName);
      }
    });
  });
  return resultPromise;
}