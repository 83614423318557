'use es6';

import { Map as ImmutableMap } from 'immutable';
import { RESET_EMAIL_DETAILS } from 'EmailData/actions/actionTypes';
import { DETAIL_SELECT_ALL } from 'EmailData/utils/details/detailTypeUtil';
import { SELECT_EMAIL_CHILD } from 'email-management-lib/refactor/actions/actionTypes';
const initialState = ImmutableMap({
  selectedId: DETAIL_SELECT_ALL
});
export default function emailChildSelect(state = initialState, action) {
  switch (action.type) {
    case SELECT_EMAIL_CHILD:
      return state.set('selectedId', action.payload.value);
    case RESET_EMAIL_DETAILS:
      return initialState;
    default:
      return state;
  }
}