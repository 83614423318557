import { useEffect, useState } from 'react';
import { useViews as useViewsProvider } from 'framework-listing-lib/internal/ViewTabs/providers/ViewsProvider';
import { useCurrentViewId, usePinnedViews } from './useViewProps';
import { findViewById } from '../utils/views';
export default function useCachedPinnedViews() {
  const currentViewId = useCurrentViewId();
  const pinnedViews = usePinnedViews();
  const {
    isLoading
  } = useViewsProvider();

  // Store pinned  views in state for faster feedback to users when DnD
  const [optimisticPinnedViews, setOptimisticPinnedViews] = useState(pinnedViews);

  /**
   * Store pinned views in state to prevent the latency when DnD'ing views.
   */
  useEffect(() => {
    if (!isLoading && pinnedViews.length !== optimisticPinnedViews.length &&
    // views response has current view
    findViewById(pinnedViews, currentViewId) &&
    // cache does not have current view
    !findViewById(optimisticPinnedViews, currentViewId)) {
      setOptimisticPinnedViews(pinnedViews);
    }
  }, [currentViewId, isLoading, optimisticPinnedViews, pinnedViews]);
  return [optimisticPinnedViews, setOptimisticPinnedViews];
}