'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["register", "setLocale"];
import { create } from 'I18n/init/internal/hs-intl';
import localeMapper from 'I18n/init/internal/localeMapper';
import { resolveSwappableCopies, deserializeCopyVariant } from '../resolvers/i18n';
import { merge, ensureFn, getNavigatorLanguage } from '../core/helpers';
import { validateWithDefaults } from '../core/validators';
import { logAndReportError } from '../core/errors';
import { createDebugLogger } from '../core/loggers';
const defaultLanguage = 'en';
function markCopyExperimentsAsInstalled() {
  if (typeof window !== 'undefined' && window) {
    window['LABORATORY_COPY_EXPERIMENTS_INSTALLED'] = true;
  }
}

// @TODO: Make this Promise-based and remove the usages of try/catch
function swapExperimentalCopy({
  treatments,
  onError,
  i18n = window.I18n,
  logDebug
} = {}) {
  let swappedCopies = [];
  const safeLogDebug = ensureFn(logDebug);
  try {
    const swapCopiesForLanguage = lang => {
      const translations = i18n.translations[lang];
      if (translations) {
        const swappableCopies = resolveSwappableCopies({
          translations,
          treatments,
          onError
        });
        const swappableKeys = Object.keys(swappableCopies);
        if (swappableKeys.length) {
          const overrides = swappableKeys.reduce((acc, key) => {
            const branch = swappableCopies[key] || {};
            acc = merge(acc, branch);
            return acc;
          }, {});
          i18n.translations[lang] = merge(translations, overrides);
          swappedCopies.push(...swappableKeys);
        }
        safeLogDebug(swappableKeys.length ? 'applied copy experiments into window.i18n' : 'no applicable copies were found for given experiments', 'swapCopiesForLanguage');
        safeLogDebug(swappableCopies, 'swapCopiesForLanguage');
      }
    };

    // Iterate through available (applied) translations from
    // language and locale without going over twice.
    const uniqueTranslations = new Set([i18n.lang, i18n.locale]);
    [...uniqueTranslations].forEach(lang => swapCopiesForLanguage(lang));
  } catch (err) {
    ensureFn(onError)(err);
    swappedCopies = [];
  }
  return swappedCopies;
}
function logExposuresForswappedCopies({
  client,
  swappedCopies = []
}) {
  client.logExposures(swappedCopies.map(copyVariant => deserializeCopyVariant(copyVariant)).map(([key]) => key));
}
export default function createExperimentI18nProvider({
  client,
  createProvider = create,
  useBrowserLocale = false,
  onError = logAndReportError
} = {}) {
  const validators = validateWithDefaults(onError, 'createExperimentI18nProvider');
  validators.validateClientInstance(client);
  markCopyExperimentsAsInstalled();
  const intl = createProvider();
  const {
      register,
      setLocale
    } = intl,
    rest = _objectWithoutPropertiesLoose(intl, _excluded);
  const localesToLoad = [];
  const treatmentsPromise = new Promise(client.resolve);
  const {
    logDebug
  } = createDebugLogger({
    debug: client.debug
  });
  if (useBrowserLocale) {
    setLocale([getNavigatorLanguage(defaultLanguage)]);
  }
  return Object.assign({
    register(lang, registerOptions = {}) {
      const mapper = registerOptions.localeMapper || localeMapper;
      const langPromise = register.call(intl, lang, Object.assign({}, registerOptions, {
        localeMapper(...args) {
          const mappedLocale = mapper(...args);
          if (mappedLocale && !localesToLoad.includes(mappedLocale)) {
            localesToLoad.push(mappedLocale);
          }
          return mappedLocale;
        }
      }));
      return Promise.all([treatmentsPromise, langPromise]).then(([treatments]) => {
        const treatmentKeys = Object.keys(treatments);
        logDebug(treatmentKeys.length ? 'found experiment treatments' : 'no experiment treatments were found', 'createExperimentI18nProvider');
        if (treatmentKeys.length) {
          const swappedCopies = swapExperimentalCopy({
            treatments,
            onError,
            logDebug
          });
          if (swappedCopies.length) {
            logExposuresForswappedCopies({
              client,
              swappedCopies
            });
          }
        }
      });
    },
    setLocale({
      locale
    }) {
      const loaderLocales = [locale];
      if (locale !== defaultLanguage) {
        loaderLocales.push(defaultLanguage);
      }
      return setLocale(loaderLocales);
    }
  }, rest);
}