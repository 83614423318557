import { useState, useCallback, useEffect } from 'react';
import { useTableColumns } from '../providers/TableColumnsProvider';
export default function useColumnResize(column) {
  const {
    onResize,
    onResizeEnd
  } = useTableColumns();
  const [state, setState] = useState({
    currentWidth: column.width,
    isResizing: false,
    startingWidth: column.width,
    startingXCoordinate: null
  });
  const handleMouseMove = useCallback(event => {
    if (!state.isResizing) {
      return;
    }
    const {
      startingWidth,
      startingXCoordinate
    } = state;
    const adjustedSize = startingWidth + (event.x - (startingXCoordinate || 0));
    // maintain a minimum column width
    if (adjustedSize > column.minWidth) {
      setState(prevState => Object.assign({}, prevState, {
        currentWidth: adjustedSize
      }));
      onResize(column.id, adjustedSize);
    }
  }, [column.id, column.minWidth, onResize, state]);
  const handleMouseUp = useCallback(() => {
    if (!state.isResizing) {
      return;
    }
    setState(prevState => Object.assign({}, prevState, {
      isResizing: false,
      startingWidth: prevState.currentWidth,
      startingXCoordinate: null
    }));
    onResizeEnd(column.id, state.currentWidth);
  }, [column.id, onResizeEnd, state.currentWidth, state.isResizing]);
  const handleResize = useCallback(event => {
    setState(prevState => Object.assign({}, prevState, {
      isResizing: true,
      startingXCoordinate: event.nativeEvent.x
    }));
  }, []);
  useEffect(() => {
    if (state.isResizing) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp, state.isResizing]);
  return {
    currentWidth: state.currentWidth,
    handleResize
  };
}