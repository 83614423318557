import http from 'hub-http/clients/apiClient';
import { getFullUrl } from 'hubspot-url-utils';
let cachedResult = null;
export function clearCache() {
  cachedResult = null;
}
export const fetchOwners = () => {
  if (cachedResult !== null) {
    return cachedResult;
  }
  cachedResult = http.get(`${getFullUrl('app-api')}/monetization/v2/owner`);
  return cachedResult;
};