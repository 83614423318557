import { useEffect, useState } from 'react';
import userInfo from 'hub-http/userInfo';
/**
 * Check user scopes and returns if a user has access to all teams vs owned teams
 * based on the provided scopes names.
 */
export default function useCrmObjectTypePermissions({
  viewAllAssetsScope,
  viewTeamOwnedAssetsScope
}) {
  const [state, setState] = useState({
    loading: true,
    data: undefined,
    error: undefined
  });
  useEffect(() => {
    userInfo().then(userInfoResponse => {
      const {
        scopes: userScopes
      } = userInfoResponse.user;
      const canViewAllTeams = userScopes.includes(viewAllAssetsScope);
      const canViewOwnedTeams = canViewAllTeams || userScopes.includes(viewTeamOwnedAssetsScope);
      setState({
        data: {
          canFilterByTeams: canViewAllTeams || canViewOwnedTeams,
          canViewAllTeams,
          canViewOwnedTeams
        },
        error: undefined,
        loading: false
      });
    }).catch(() => {
      setState({
        data: undefined,
        error: true,
        loading: false
      });
    });
  }, [viewAllAssetsScope, viewTeamOwnedAssetsScope]);
  return state;
}