import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["fetcher"],
  _excluded2 = ["fetcher"];
import { useEffect } from 'react';
import isEqual from 'hs-lodash/isEqual';
import useCrmObjectTypeFetchParams from 'framework-listing-lib/internal/hooks/useCrmObjectTypeFetchParams';
import useFetchFromCRM from 'framework-listing-lib/internal/hooks/useFetchFromCRM';
import { useSkip } from 'framework-listing-lib/hooks/useFetchCrmObjectType';
import usePrevious from 'react-utils/hooks/usePrevious';

/**
 * Re-fetches the crm-search data every time variables change
 * Most other queries for the crm-search should use a cache-first strategy
 *
 * If the user updates a crmObject in one view or page
 * eg with listingLibObserver.update or inline-editing
 * it won't be updated in other views/pages that were fetched & cached earlier
 */
export default function useCrmObjectTypeResync() {
  const variables = useCrmObjectTypeFetchParams();
  const skip = useSkip();
  const {
    refetch,
    data
  } = useFetchFromCRM(variables, {
    fetchPolicy: 'cache-only',
    skip
  });
  const previousVariables = usePrevious(variables);
  useEffect(() => {
    /**
     * These conditions help avoid calling refetch on the initial page load
     * which currently seems to bypass any quick-fetch results for the same query
     */
    if (skip || !data || !previousVariables) {
      return;
    }
    const _ref = previousVariables || {},
      previous = _objectWithoutPropertiesLoose(_ref, _excluded);
    const current = _objectWithoutPropertiesLoose(variables, _excluded2);
    if (!isEqual(previous, current)) {
      refetch(variables).catch(e => {
        throw e;
      });
    }
  }, [data, previousVariables, refetch, skip, variables]);
}