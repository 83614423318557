import { registerMutation, registerQuery } from 'data-fetching-client';
import { fetchEmailInsightsRequest, fetchTopSpotlightsRequest, submitEmailInsightFeedbackRequest } from 'messaging-publishing-lib/Insights/api';
export const FETCH_EMAIL_INSIGHTS = registerQuery({
  fieldName: 'emailInsights',
  args: ['businessUnitId', 'comparisonType', 'emailContentId'],
  fetcher: fetchEmailInsightsRequest
});
export const FETCH_TOP_SPOTLIGHTS = registerQuery({
  fieldName: 'topInsights',
  args: ['businessUnitId', 'comparisonType'],
  fetcher: fetchTopSpotlightsRequest
});
export const SUBMIT_INSIGHT_FEEDBACK = registerMutation({
  fieldName: 'insightFeedback',
  args: ['entityId', 'feedbackComment', 'feedbackEntityType', 'feedbackSentiment', 'feedbackCategories'],
  fetcher: submitEmailInsightFeedbackRequest
});