import { useMemo } from 'react';
import useFetchCrmObjectType, { useSkip } from 'framework-listing-lib/hooks/useFetchCrmObjectType';
import { useListingLibData } from 'framework-listing-lib/internal/providers/DataProvider';
export default function useIsLoading() {
  const skip = useSkip();
  const {
    loading: loadingListingLibData
  } = useListingLibData();
  const {
    loading: loadingCrmObjects
  } = useFetchCrmObjectType();
  return useMemo(() => loadingListingLibData || loadingCrmObjects || skip, [loadingCrmObjects, loadingListingLibData, skip]);
}