import { useCallback, useState } from 'react';
import { useTableDispatch } from '../../redux/reduxHooks';
import { DEFAULT_COLUMN_WIDTH, TABLE_ROW_HEIGHT } from '../../table/constants/sizes';
import { updateVisibleIndexes } from '../redux/visibleCellsSlice';
import { COLUMN, ROW } from '../types/observerTypes';

// Overscan by 2 columns and 20 rows (both on either side of the window, both in px)
const COLUMN_OVERSCAN = 2 * DEFAULT_COLUMN_WIDTH;
const ROW_OVERSCAN = 20 * TABLE_ROW_HEIGHT;
export const useObserveTableContainerRef = () => {
  const [observer, setObserver] = useState(null);
  const dispatch = useTableDispatch();

  // This is called a callback ref. When passed to the `ref` prop of a component,
  // this function is called twice whenever the component changes its underlying dom node:
  // Once with `null` (so we can clean up) and once with the new node. We use this to
  // clean up the current observer before making a new one with a new root.
  const handleObserveRef = useCallback(scrollElementRef => {
    setObserver(currentObserver => {
      if (currentObserver) {
        currentObserver.disconnect();
      }
      if (!scrollElementRef) {
        return null;
      }
      const handleIntersection = entries => {
        const visCols = {};
        const visRows = {};
        for (const {
          target,
          isIntersecting
        } of entries) {
          const type = target.getAttribute('data-observer-type');
          const id = target.getAttribute('data-observer-id');

          // Skip elements without a type or id. This should never actually happen,
          // so long as we're setting the `data-observer-*` attributes properly.
          if (!id || !type) {
            continue;
          }
          if (type === COLUMN && isIntersecting) {
            visCols[id] = isIntersecting;
          } else if (type === ROW && isIntersecting) {
            visRows[id] = isIntersecting;
          }
        }
        if (Object.entries(visCols).length || Object.entries(visRows).length) {
          dispatch(updateVisibleIndexes({
            rows: visRows,
            columns: visCols
          }));
        }
      };
      return new IntersectionObserver(handleIntersection, {
        root: scrollElementRef,
        rootMargin: `${ROW_OVERSCAN}px ${COLUMN_OVERSCAN}px ${ROW_OVERSCAN}px ${COLUMN_OVERSCAN}px`
      });
    });
  }, [dispatch]);
  return {
    handleObserveRef,
    observer
  };
};