import { useQuery, registerQuery } from 'data-fetching-client';
import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
import { useMemo } from 'react';
const ACTIVE_DOMAINS = 'activeDomains';
const GET_ACTIVE_DOMAINS = registerQuery({
  fieldName: ACTIVE_DOMAINS,
  args: [],
  fetcher() {
    return http.get('cos-domains/v1/domains/settings', {
      query: {
        portalId: PortalIdParser.get(),
        skipdnscheck: true
      }
    });
  }
});
export default function useFetchDomains() {
  const {
    data
  } = useQuery(GET_ACTIVE_DOMAINS);
  const activeDomains = useMemo(() => {
    return data && data[ACTIVE_DOMAINS] ? data[ACTIVE_DOMAINS].objects : [];
  }, [data]);
  return {
    activeDomains
  };
}