export const AMOUNT = 'amount';
export const BILLING_TERMS = 'hs_recurring_billing_terms';
export const CREATE_DATE = 'createdate';
export const DESCRIPTION = 'description';
export const DISCOUNT = 'discount';
export const DISCOUNT_PERCENTAGE = 'hs_discount_percentage';
export const LINE_ITEM_CURRENCY_CODE = 'hs_line_item_currency_code';
export const NAME = 'name';
export const NUM_PAYMENTS = 'hs_recurring_billing_number_of_payments';
export const ORDER = 'hs_position_on_quote';
export const PRODUCT_ID = 'hs_product_id';
export const PRICE = 'price';
export const QUANTITY = 'quantity';
export const RECURRING_BILLING_FREQUENCY = 'recurringbillingfrequency';
export const SKU = 'hs_sku';
export const START_DATE = 'hs_recurring_billing_start_date';
export const TAX = 'tax';
export const TERM = 'hs_recurring_billing_period';
export const TERM_IN_MONTHS = 'hs_term_in_months';
export const UNIT_COST = 'hs_cost_of_goods_sold';
export const MARGIN = 'hs_margin';
export const TCV = 'hs_tcv';
export const ACV = 'hs_acv';
export const ARR = 'hs_arr';
export const MRR = 'hs_mrr';
export const TCV_MARGIN = 'hs_margin_tcv';
export const ACV_MARGIN = 'hs_margin_acv';
export const ARR_MARGIN = 'hs_margin_arr';
export const MRR_MARGIN = 'hs_margin_mrr';
export const PRE_DISCOUNT_AMOUNT = 'hs_pre_discount_amount';
export const TOTAL_DISCOUNT = 'hs_total_discount';
export const BILLING_START_DELAY_TYPE = 'hs_billing_start_delay_type';
export const BILLING_START_DELAY_DAYS = 'hs_billing_start_delay_days';
export const BILLING_START_DELAY_MONTHS = 'hs_billing_start_delay_months';
export const currencyPricePrefix = 'hs_price_';
export const isCurrencyPriceProperty = propertyName => propertyName.indexOf(currencyPricePrefix) === 0;
export const getCurrencyCodeForPriceProperty = propertyName => {
  const currencyCode = propertyName.split(currencyPricePrefix)[1];
  return currencyCode ? currencyCode.toUpperCase() : null;
};
export const getCurrencyPriceProperty = currencyCode => `${currencyPricePrefix}${currencyCode.toLowerCase()}`;

// TODO: Add Shopify prop names here