'use es6';

import http from 'hub-http/clients/apiClient';
export const getSuspensionStatus = (contentId, keyWord) => {
  return http.get(`abuse-desk-public/v1/content-case/moderation-status/${keyWord}/${contentId}`);
};
export const getBulkSuspensionStatus = (contentIds, keyWord) => {
  return http.get(`abuse-desk-public/v1/content-case/moderation-status/${keyWord}`, {
    query: {
      contentIds
    }
  });
};
export const getHubContentReviewStatus = contentId => {
  console.log(contentId);
  return Promise.resolve({
    reviewState: 'IN_REVIEW',
    portalLevel: 'ENTERPRISE',
    slaCopyType: 'NON_CORE'
  });
};