import { FETCH_PORTAL_MIGRATION_START, FETCH_PORTAL_MIGRATION_SUCCESS, FETCH_PORTAL_MIGRATION_FAIL } from './actionTypes';
import portalMigrationStatus from '../api/portalMigration';
import Raven from 'raven-js';
const HTTP_STATUS_CODES_TO_IGNORE = [404, 403];
export const fetchPortalMigrationStatus = () => {
  return dispatch => {
    dispatch({
      type: FETCH_PORTAL_MIGRATION_START
    });
    portalMigrationStatus().then(response => {
      dispatch({
        type: FETCH_PORTAL_MIGRATION_SUCCESS,
        payload: response
      });
    }).catch(err => {
      dispatch({
        type: FETCH_PORTAL_MIGRATION_FAIL
      });
      if (!err.status || !HTTP_STATUS_CODES_TO_IGNORE.includes(err.status)) {
        Raven.captureException(err);
      }
    });
  };
};