import http from 'hub-http/clients/apiClient';
export function getHttpClientAsLastParam(params) {
  if (!params.length) {
    return http;
  }
  const httpClient = params[params.length - 1];
  if (!httpClient || typeof httpClient.get !== 'function' || typeof httpClient.post !== 'function') {
    return http;
  }
  return httpClient;
}