import { MC0653_GROUP_PARAMETER, MC0653_GROUP_VARIATION, MC0653_TREATMENT_KEY } from 'email-management-lib/refactor/constants/experimentConstants';
// @ts-expect-error not migrated to TS yet
import { getIsFreeEmail } from 'EmailData/selectors/user';
export const getExperimentTreatments = state => {
  return state.get('experiments');
};
export const getTreatments = state => {
  const {
    hasFetched,
    isFetching,
    treatments
  } = getExperimentTreatments(state);
  if (!hasFetched || isFetching) {
    return undefined;
  }
  return treatments;
};
export const getIsMc0653Audience = state => {
  const isFreeEmailUser = getIsFreeEmail(state);
  return isFreeEmailUser;
};
export const getShowMc0653Variation = state => {
  const isAcceptanceTest = !!document.cookie.includes('hs_selenium');
  if (isAcceptanceTest) {
    return false;
  }
  const treatments = getTreatments(state);
  if (!treatments || !treatments[MC0653_TREATMENT_KEY]) {
    return false;
  }
  const treatment = treatments[MC0653_TREATMENT_KEY].parameters[MC0653_GROUP_PARAMETER];
  const isMc0653Audience = getIsMc0653Audience(state);
  return treatment === MC0653_GROUP_VARIATION && isMc0653Audience;
};