import { createHasGateSelector } from 'EmailData/selectors/gates';
import { createHasScopeSelector } from 'EmailData/selectors/scopes';
import { EMAIL_AI_INSIGHTS_GATE } from 'messaging-publishing-lib/constant/gates';
import { gate } from 'hub-http/gates';
export const getUserSelector = state => state.get('user');
export const getCanUseTransactional = createHasScopeSelector('transactional-email-api');
export const getHasDomainsAccess = createHasScopeSelector('email-sending-domains-access');
export const getIsUngatedForInAppGoogleYahooAlerts = createHasGateSelector('Email:InAppGoogleYahooAlerts');
export const getIsUngatedForSpfDmarcDomains = createHasGateSelector('domains:spfAndDmarc');
export const getIsUngatedFrameworkPortalsWithNoFolder = createHasGateSelector('Email:FrameworkPortalsWithNoFolder');
export const getHasDesignManagerAccess = createHasScopeSelector('design-manager-access');
export const getIsUngatedForAnalyticsSurvey = createHasGateSelector('Email:AnalyticsQuarterlySurvey');
export const getPortalUsers = state => {
  const userList = state.get('users').getData().get('users');
  if (!Array.isArray(userList)) {
    return userList.toJS();
  }
  return userList;
};
export const getIsUngatedForMarketingHome = createHasGateSelector('MO:MarketingHome');
export const getIsUngatedForMobileClickMap = createHasGateSelector('Email:MobileClickMap');
export const getIsUngatedForAiInsights = createHasGateSelector(EMAIL_AI_INSIGHTS_GATE);
export const getIsUngatedForPublishDateForBatchEmailPinotStats = createHasGateSelector(gate('Email:PublishDateForBatchEmailPinotStats'));
export const hasAccessToBusinessUnit = createHasScopeSelector('business-units-access');