'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["teamPerms", "userPerms", "customPerms"];
import { getTeams } from 'ContentData/selectors/teams';
import { getUsers } from 'ContentData/selectors/users';
import { currentParamsSelector, currentQuerySelector } from 'EmailData/selectors/params';
import { getUser } from 'EmailData/selectors/user';
import { ALL_TEAMS, ALL_USERS } from 'email-management-lib/refactor/constants/manage/frameworkTable';
import { getManageTable } from 'email-management-lib/refactor/selectors/manage/table';
import identity from 'hs-lodash/identity';
import pickBy from 'hs-lodash/pickBy';
import { List } from 'immutable';
import { createSelector } from 'reselect';
const manageFiltersSelector = createSelector([currentQuerySelector, currentParamsSelector, getManageTable], (currentQuery, currentParams, tableState) => {
  const state = currentParams.get('state') || currentQuery.get('state');
  return {
    customPerms: currentQuery.get('custom'),
    search: currentQuery.get('search'),
    state,
    folderId: currentParams.get('folderId'),
    type: currentQuery.get('type'),
    offset: tableState.get('offset'),
    sortColumn: tableState.get('sortColumn'),
    sortDir: tableState.get('sortDir'),
    campaign: currentQuery.get('campaign'),
    teamPerms: currentQuery.get('team'),
    userPerms: currentQuery.get('user'),
    businessUnitId: currentQuery.get('businessUnitId')
  };
});
function hasValue(customPerms, value) {
  if (List.isList(customPerms)) {
    return customPerms.includes(value);
  }
  return customPerms === value;
}
export const parsePerms = ({
  teamPerms,
  userPerms,
  customPerms,
  user,
  portalUsers,
  teams
}) => {
  let newTeamPerms = List.isList(teamPerms) ? teamPerms : List([teamPerms].filter(Boolean));
  let newUserPerms = List.isList(userPerms) ? userPerms : List([userPerms].filter(Boolean));
  if (hasValue(customPerms, 'ASSIGNED_TO_USER')) {
    newUserPerms = newUserPerms.push(user.user_id);
  }
  if (hasValue(customPerms, 'ASSIGNED_TO_USERS_TEAM')) {
    const userTeams = user.teams.map(({
      id
    }) => id);
    newTeamPerms = newTeamPerms.concat(userTeams);
  }
  if (hasValue(customPerms, ALL_USERS)) {
    const allUsers = [...Object.values(portalUsers).map(portalUser => portalUser.userId)];
    newUserPerms = newUserPerms.concat(allUsers);
  }
  if (hasValue(customPerms, ALL_TEAMS)) {
    const allTeams = teams.map(team => team.id);
    newTeamPerms = newTeamPerms.concat(allTeams);
  }
  return {
    teamPerms: newTeamPerms.length > 1 ? newTeamPerms : newTeamPerms.first(),
    userPerms: newUserPerms.length > 1 ? newUserPerms : newUserPerms.first()
  };
};
export const getCurrentFilters = createSelector([manageFiltersSelector, getUser, getUsers, getTeams], (query, user, portalUsers, teams) => {
  const _pickBy = pickBy(query, identity),
    {
      teamPerms,
      userPerms,
      customPerms
    } = _pickBy,
    restOfQuery = _objectWithoutPropertiesLoose(_pickBy, _excluded);
  return Object.assign({}, restOfQuery, parsePerms({
    teamPerms,
    userPerms,
    customPerms,
    user,
    portalUsers,
    teams
  }));
});