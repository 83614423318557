import formatPhoneNumber from 'I18n/utils/formatPhoneNumber';
// @ts-expect-error Untyped dependency
import formatPhoneNumberWithExtension from 'I18n/utils/formatPhoneNumberWithExtension';
// @ts-expect-error Untyped dependency
import * as TelephoneData from 'I18n/constants/TelephoneData';

/**
 * Returns the corresponding country dial code.
 *
 * @example
 * getCountryDialCode('us'); // => '1'
 * getCountryDialCode('gb'); // => '44'
 * getCountryDialCode('unknown'); // => undefined
 */
export function getCountryDialCode(country) {
  if (!country) {
    return undefined;
  }
  const iso2 = TelephoneData.iso2Lookup[country];
  const dialCode = TelephoneData.allCountries[iso2].dialCode;
  return dialCode;
}
export function parseRawPhoneNumber(rawPhoneNumber) {
  const regex = new RegExp(/^(.*) ext\D*(\d+)$/);
  const phoneNumberParts = regex.exec(rawPhoneNumber);
  const phoneNumber = phoneNumberParts ? phoneNumberParts[1] : rawPhoneNumber;
  const extension = phoneNumberParts ? phoneNumberParts[2] : '';
  return {
    phoneNumber,
    extension
  };
}
export function isValidRawPhoneNumber(rawPhoneNumber) {
  const {
    phoneNumber,
    extension
  } = parseRawPhoneNumber(rawPhoneNumber);
  const regex = /(?=[^+])\D/; // match non-digits excluding '+' character

  return !regex.test(phoneNumber) && !regex.test(extension);
}
export function formatRawPhoneNumber(rawPhoneNumber) {
  if (isValidRawPhoneNumber(rawPhoneNumber)) {
    const {
      phoneNumber,
      extension
    } = parseRawPhoneNumber(rawPhoneNumber);
    return extension ? formatPhoneNumberWithExtension(phoneNumber, extension) : formatPhoneNumber(phoneNumber, '');
  }
  return rawPhoneNumber;
}