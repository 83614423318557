'use es6';

import I18n from 'I18n';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
function getAlertType(type) {
  switch (type) {
    case 'info':
      return 'success';
    case 'error':
      return 'danger';
    default:
      return type;
  }
}
export default {
  growl: (type = 'success', message = I18n.text('EmailComponents.errors.generic'), titleText = '') => {
    const messageType = getAlertType(type);
    FloatingAlertStore.addAlert({
      message,
      timeout: messageType === 'danger' ? null : 10000,
      sticky: messageType === 'danger',
      type: messageType,
      titleText
    });
  }
};