import { useCallback, useMemo } from 'react';
import useCrmObjectTypeListingFilters from 'framework-listing-lib/hooks/useCrmObjectTypeListingFilters';
import useCrmObjectTypeTracking from 'framework-listing-lib/internal/hooks/useCrmObjectTypeTracking';
import listingLibObserver from 'framework-listing-lib/utils/listingLibObserver';
function useCrmObjectTypeListingPagination(total = 0) {
  const {
    filters,
    onUpdateFilters
  } = useCrmObjectTypeListingFilters();
  const {
    trackCrmTableInteraction
  } = useCrmObjectTypeTracking();
  const {
    offset,
    count
  } = filters.pagination;
  const page = offset / count;
  const pages = Math.ceil(total / count);
  const onPageChange = useCallback(newPage => {
    trackCrmTableInteraction({
      action: 'page-change',
      page: newPage + 1
    });
    onUpdateFilters({
      pagination: {
        count,
        offset: newPage * count
      }
    });
    listingLibObserver.setBulkActionSelectionMode('none');
  }, [count, onUpdateFilters, trackCrmTableInteraction]);
  const onPageSizeChange = useCallback(newPageSize => {
    trackCrmTableInteraction({
      action: 'page-size-change',
      pageSize: newPageSize
    });
    onUpdateFilters({
      pagination: {
        count: newPageSize,
        offset: 0
      }
    });
  }, [onUpdateFilters, trackCrmTableInteraction]);
  return useMemo(() => ({
    onPageChange,
    onPageSizeChange,
    page,
    pages,
    pageSize: count
  }), [count, onPageChange, onPageSizeChange, page, pages]);
}
export default useCrmObjectTypeListingPagination;