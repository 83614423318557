import { AI_GENERATED, AI_GENERATED_VIEW_ID, ALL, ARCHIVED, DRAFT, EMAIL_ALL_VIEW_ID, EMAIL_ARCHIVED_VIEW_ID, EMAIL_DRAFT_VIEW_ID, EMAIL_SCHEDULED_VIEW_ID, EMAIL_SENT_VIEW_ID, SCHEDULED, SENT, VISIBLE_TO } from 'email-management-lib/refactor/constants/manage/shared';
import { IN, NOT_IN } from 'email-management-lib/refactor/utils/filters';
export const MARKETING_EMAIL_OBJECT_TYPE = '0-29';
export const APPLICATION_ID = 'marketing-email';
export const PROPERTIES = {
  ARCHIVED: 'hs_archived',
  AB_TEST_STATUS: 'hs_ab_test_status',
  APPROVALS_STATUS_V2: 'hs_approval_publish_status_v2',
  BUSINESS_UNIT_IDS: 'hs_all_assigned_business_unit_ids',
  CAMPAIGN_GUID: 'hs_campaign_guid',
  CREATED_AT: 'hs_created_at',
  EMAIL_TEMPLATE_MODE: 'hs_email_template_mode',
  EMAIL_TYPE: 'hs_type',
  HS_CREATED_BY_USER_ID: 'hs_created_by_user_id',
  LAST_UPDATED: 'hs_updated_at',
  MARKETING_EMAIL_ID: 'hs_origin_asset_id',
  NAME: 'hs_name',
  PREVIEW_KEY: 'hs_preview_key',
  PROCESSING_STATUS: 'hs_processing_status',
  PUBLISHED_DATE: 'hs_publish_date',
  PUBLISH_AUTHOR: 'hs_publish_author',
  PUBLISH_STATUS: 'hs_publish_status',
  SECURITY_STATE: 'hs_security_state',
  SUBJECT: 'hs_subject',
  SUBSCRIPTION: 'hs_subscription',
  TEAM_IDS: 'hs_all_team_ids',
  USER_IDS: 'hs_user_ids_of_all_owners',
  UPDATED_BY: 'hs_updated_by_user_id',
  ALL_INTERNAL_HUBSPOT_IDS: 'hs_all_email_campaign_ids',
  LANGUAGE: 'hs_language',
  /**
   * To hide
   */
  APPROVALS_STATUS: 'hs_approval_publish_status',
  PUBLISHED_AT: 'hs_published_at',
  STATE: 'hs_state',
  FOLDER_ID: 'hs_folder_id',
  METRIC_INSIGHTS: 'hs_metric_insights',
  EMAIL_TYPE_TO_DEPRECATE: 'hs_email_type',
  LAST_MODIFIED_DATE: 'hs_lastmodifieddate',
  OWNER_ASSIGNED_DATE: 'hubspot_owner_assigneddate',
  MERGED_OBJECT_IDS: 'hs_merged_object_ids',
  HUBSPOT_TEAM_ID: 'hubspot_team_id',
  HS_CREATED_DATE: 'hs_createdate',
  HUBSPOT_OWNER_ID: 'hubspot_owner_id',
  RSS_TO_EMAIL_TIMING: 'hs_rss_to_email_timing',
  CREATED_BY: 'hs_marketing_email_created_by_id',
  HS_TRANSACTIONAL_EMAIl: 'hs_transactional_email',
  CAMPAIGN_NAME: 'hs_campaign_name',
  RSS_EMAIL_ENABLED: 'hs_rss_to_email_enabled',
  SUBCATEGORY: 'hs_subcategory',
  AUTHOR: 'hs_author_email',
  AUTHOR_NAME: 'hs_author_name',
  OBJECT_ID: 'hs_object_id',
  IS_AI_USED: 'hs_is_ai_used',
  PUBLISHED_BY_NAME: 'hs_published_by_name',
  INTERNAL_HUBSPOT_ID: 'hs_primary_email_campaign_id',
  RECORD_SOURCE_DETAILS_1: 'hs_object_source_detail_1',
  RECORD_SOURCE_DETAILS_2: 'hs_object_source_detail_2',
  RECORD_SOURCE_DETAILS_3: 'hs_object_source_detail_3',
  /**
   * Rollup properties
   */
  BOUNCES: 'hs_bounces',
  BOUNCE_RATE: 'hs_bounce_rate',
  HARD_BOUNCES: 'hs_hard_bounces',
  HARD_BOUNCES_RATE: 'hs_hard_bounces_rate',
  SOFT_BOUNCES: 'hs_soft_bounces',
  SOFT_BOUNCES_RATE: 'hs_soft_bounces_rate',
  REPLIES: 'hs_replies',
  REPLY_RATE: 'hs_reply_rate',
  SENT: 'hs_sent',
  NOT_SENT: 'hs_not_sent',
  SPAM_REPORTS: 'hs_spam_reports',
  SPAM_REPORTS_RATE: 'hs_spam_reports_rate',
  UNSUBSCRIBED: 'hs_unsubscribed',
  UNSUBSCRIBE_RATE: 'hs_unsubscribe_rate',
  CLICKS: 'hs_clicks',
  CLICK_RATE: 'hs_click_rate',
  CLICK_THROUGH_RATE: 'hs_clickthrough_rate',
  OPENS: 'hs_opens',
  OPEN_RATE: 'hs_open_rate',
  DELIVERED: 'hs_delivered',
  DELIVERY_RATE: 'hs_delivered_rate',
  GENERATED_BY_AI: 'hs_generated_by_ai',
  GENERATED_WITH_AI: 'hs_generated_with_ai',
  FROM_ADDRESS: 'hs_from_address',
  FROM_ADDRESS_DOMAIN: 'hs_from_address_domain',
  FROM_ADDRESS_PERSONALIZED: 'hs_from_address_personalized'
};
export const DESC = 'DESC';
export const CRM_VIEWS_ALL_CAMPAIGNS = 'crm-views-all-campaigns';
export const CRM_VIEWS_TEAM_OWNED_CAMPAIGNS = 'crm-views-team-owned-campaigns';
export const EMAIL = 'email';
export const ALL_USERS = 'ALL_USERS';
export const ALL_TEAMS = 'ALL_TEAMS';
export const STATS_PROPERTIES_TO_SHOW = {
  [PROPERTIES.BOUNCES]: {
    hidden: false
  },
  [PROPERTIES.BOUNCE_RATE]: {
    hidden: false
  },
  [PROPERTIES.HARD_BOUNCES]: {
    hidden: false
  },
  [PROPERTIES.HARD_BOUNCES_RATE]: {
    hidden: false
  },
  [PROPERTIES.SOFT_BOUNCES]: {
    hidden: false
  },
  [PROPERTIES.SOFT_BOUNCES_RATE]: {
    hidden: false
  },
  [PROPERTIES.REPLIES]: {
    hidden: false
  },
  [PROPERTIES.REPLY_RATE]: {
    hidden: false
  },
  [PROPERTIES.SENT]: {
    hidden: false
  },
  [PROPERTIES.NOT_SENT]: {
    hidden: false
  },
  [PROPERTIES.SPAM_REPORTS]: {
    hidden: false
  },
  [PROPERTIES.SPAM_REPORTS_RATE]: {
    hidden: false
  },
  [PROPERTIES.UNSUBSCRIBED]: {
    hidden: false
  },
  [PROPERTIES.UNSUBSCRIBE_RATE]: {
    hidden: false
  },
  [PROPERTIES.CLICKS]: {
    hidden: false
  },
  [PROPERTIES.CLICK_RATE]: {
    hidden: false
  },
  [PROPERTIES.CLICK_THROUGH_RATE]: {
    hidden: false
  },
  [PROPERTIES.OPENS]: {
    hidden: false
  },
  [PROPERTIES.OPEN_RATE]: {
    hidden: false
  },
  [PROPERTIES.DELIVERED]: {
    hidden: false
  },
  [PROPERTIES.DELIVERY_RATE]: {
    hidden: false
  }
};
export const PROPERTIES_TO_HIDE = {
  [PROPERTIES.ARCHIVED]: {
    hidden: true
  },
  [PROPERTIES.APPROVALS_STATUS]: {
    hidden: true
  },
  [PROPERTIES.FOLDER_ID]: {
    hidden: true
  },
  [PROPERTIES.EMAIL_TYPE_TO_DEPRECATE]: {
    hidden: true
  },
  [PROPERTIES.LAST_MODIFIED_DATE]: {
    hidden: true
  },
  [PROPERTIES.OWNER_ASSIGNED_DATE]: {
    hidden: true
  },
  [PROPERTIES.MERGED_OBJECT_IDS]: {
    hidden: true
  },
  [PROPERTIES.HUBSPOT_TEAM_ID]: {
    hidden: true
  },
  [PROPERTIES.HS_CREATED_DATE]: {
    hidden: true
  },
  [PROPERTIES.HUBSPOT_OWNER_ID]: {
    hidden: true
  },
  [PROPERTIES.RSS_TO_EMAIL_TIMING]: {
    hidden: true
  },
  [PROPERTIES.CREATED_BY]: {
    hidden: true
  },
  [PROPERTIES.HS_TRANSACTIONAL_EMAIl]: {
    hidden: true
  },
  [PROPERTIES.CAMPAIGN_NAME]: {
    hidden: true
  },
  [PROPERTIES.AUTHOR]: {
    hidden: true
  },
  [PROPERTIES.AUTHOR_NAME]: {
    hidden: true
  },
  [PROPERTIES.SUBCATEGORY]: {
    hidden: true
  },
  [PROPERTIES.OBJECT_ID]: {
    hidden: true
  },
  [PROPERTIES.RSS_EMAIL_ENABLED]: {
    hidden: true
  },
  [PROPERTIES.STATE]: {
    hidden: true
  },
  [PROPERTIES.PUBLISHED_AT]: {
    hidden: true
  },
  [PROPERTIES.PUBLISHED_BY_NAME]: {
    hidden: true
  },
  [PROPERTIES.INTERNAL_HUBSPOT_ID]: {
    hidden: true
  },
  [PROPERTIES.RECORD_SOURCE_DETAILS_1]: {
    hidden: true
  },
  [PROPERTIES.RECORD_SOURCE_DETAILS_2]: {
    hidden: true
  },
  [PROPERTIES.RECORD_SOURCE_DETAILS_3]: {
    hidden: true
  }
};
export const PROPERTIES_TO_HIDE_ONLY_IN_FILTERS = {
  [VISIBLE_TO]: {
    hidden: true
  },
  [PROPERTIES.PREVIEW_KEY]: {
    hidden: true
  },
  [PROPERTIES.BUSINESS_UNIT_IDS]: {
    hidden: true
  },
  [PROPERTIES.EMAIL_TYPE]: {
    hidden: true
  },
  [PROPERTIES.SUBSCRIPTION]: {
    hidden: true
  },
  [PROPERTIES.GENERATED_BY_AI]: {
    hidden: true
  },
  [PROPERTIES.GENERATED_WITH_AI]: {
    hidden: true
  }
};
export const CRM_SEARCH_HIDDEN_STATE = ['DRAFT_AB_VARIANT', 'PUBLISHED_AB_VARIANT', 'AUTOMATED_DRAFT_ABVARIANT', 'AUTOMATED_AB_VARIANT', 'AUTOMATED_LOSER_ABVARIANT', 'AUTOMATED_FOR_FORM_BUFFER'];
export const CRM_SEARCH_HIDDEN_SUBCATEGORY = ['automated_for_leadflow', 'automated_for_ticket', 'ticket_closed_kickback_email', 'ticket_opened_kickback_email', 'blog_email_child', 'rss_to_email_child'];
export const CRM_SEARCH_HIDDEN_AB_TEST_STATUS = ['VARIANT', 'AUTOMATED_VARIANT', 'AUTOMATED_LOSER_VARIANT'];
export const DEFAULT_FILTERS = [NOT_IN(PROPERTIES.STATE, CRM_SEARCH_HIDDEN_STATE), NOT_IN(PROPERTIES.SUBCATEGORY, CRM_SEARCH_HIDDEN_SUBCATEGORY), NOT_IN(PROPERTIES.AB_TEST_STATUS, CRM_SEARCH_HIDDEN_AB_TEST_STATUS)];
export const CRM_SEARCH_REQUEST_DEFAULT_OPTIONS = {
  count: 25,
  objectTypeId: MARKETING_EMAIL_OBJECT_TYPE,
  requestOptions: {
    allPropertiesFetchMode: 'latest_version',
    includeAllProperties: true
  },
  filterGroups: [{
    filters: [...DEFAULT_FILTERS, IN(PROPERTIES.ARCHIVED, ['false'])]
  }],
  associationPreviews: {},
  sorts: [{
    order: DESC,
    property: PROPERTIES.LAST_UPDATED
  }],
  offset: 0,
  query: ''
};
export const CRM_SEARCH_REQUEST_HAS_ARCHIVED_OPTIONS = Object.assign({}, CRM_SEARCH_REQUEST_DEFAULT_OPTIONS, {
  count: 1,
  filterGroups: [{
    filters: [...DEFAULT_FILTERS, IN(PROPERTIES.ARCHIVED, ['true'])]
  }],
  requestOptions: {
    properties: []
  },
  sorts: []
});
export const CRM_SEARCH_REQUEST_HAS_EMAILS_OPTIONS = Object.assign({}, CRM_SEARCH_REQUEST_DEFAULT_OPTIONS, {
  count: 1,
  requestOptions: {
    properties: []
  },
  sorts: []
});
export const QUICK_FETCH_VIEW_IDS = [EMAIL_ALL_VIEW_ID, EMAIL_DRAFT_VIEW_ID, EMAIL_SCHEDULED_VIEW_ID, EMAIL_SENT_VIEW_ID, AI_GENERATED_VIEW_ID, EMAIL_ARCHIVED_VIEW_ID];
export const QUICK_FETCH_VIEWS = [ALL, DRAFT, SCHEDULED, SENT, AI_GENERATED, ARCHIVED];