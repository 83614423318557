'use es6';

import { Map as ImmutableMap } from 'immutable';
import { RESET_EMAIL_DETAILS } from 'EmailData/actions/actionTypes';
import { SELECT_TOP_ENGAGED_CONTACTS_TAB } from 'email-management-lib/refactor/actions/actionTypes';
import { TOP_ENGAGED_CONTACTS_TABS } from 'EmailData/utils/details/detailTypeUtil';

// Campaign fetch is used to show the loading icon after a
// campaign switch, but not when there's a background fetch.
const initialState = ImmutableMap({
  selectedTab: TOP_ENGAGED_CONTACTS_TABS.OPENED
});
function topEngagedContacts(state = initialState, action) {
  switch (action.type) {
    case SELECT_TOP_ENGAGED_CONTACTS_TAB:
      return state.set('selectedTab', action.payload);
    case RESET_EMAIL_DETAILS:
      return initialState;
    default:
      return state;
  }
}
export default topEngagedContacts;