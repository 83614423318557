export default function (func) {
  let alreadyCalled = false;
  let result;
  return function (...args) {
    if (!alreadyCalled) {
      result = func.apply(this, args);
      alreadyCalled = true;
    }
    return result;
  };
}