'use es6';

import { trackPostSendInteraction } from 'EmailComponents/utils/tracking';
export const ACTION_SOURCE = Object.freeze({
  DETAILS: 'DETAILS',
  MANAGE: 'MANAGE'
});
export const trackActionTriggered = actionName => {
  trackPostSendInteraction({
    otherAction: `${actionName.toLowerCase()}`
  });
};