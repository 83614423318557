import { useEffect, useCallback, useMemo, useRef, useState } from 'react';
import isEqual from 'hs-lodash/isEqual';
import { useWriteToTableCache } from 'framework-listing-lib/internal/hooks/useFetchCrmObjectTypeCache';
import listingLibObserver from 'framework-listing-lib/utils/listingLibObserver';
import useFetchCrmObjectType from 'framework-listing-lib/hooks/useFetchCrmObjectType';
import useExpandCollapseRow from 'framework-listing-lib/hooks/useExpandCollapseRow';
import useCrmObjectTypeListingFilters from 'framework-listing-lib/hooks/useCrmObjectTypeListingFilters';
import useDeepEqual from 'framework-listing-lib/internal/hooks/useDeepEqual';
import { useHasViews, useViewUpdateState, useCurrentViewId } from 'framework-listing-lib/internal/ViewTabs/hooks/useViewProps';
import useCurrentView from 'framework-listing-lib/hooks/useCurrentView';
import { countPrimaryGroups, getAllCrmObjectsFromGroup, getPrimaryGroupMap, makePrimaryLanguageGroup, moveToPrimaryGroup, removeFromMultiLanguageGroup, showMultiLanguageGroup } from '../utils/translatedContent';
import { HIDE_ALL_MULTI_LANGUAGE_GROUPS, HIDE_MULTI_LANGUAGE_GROUP, MAKE_PRIMARY_MULTI_LANGUAGE_GROUP, MOVE_TO_MULTI_LANGUAGE_GROUP, REMOVE_MULTI_LANGUAGE_GROUP, SHOW_ALL_MULTI_LANGUAGE_GROUPS, SHOW_MULTI_LANGUAGE_GROUP } from '../constants/actions';
export default function useMultiLanguageGroupObserver() {
  const [expandedPrimaryGroupMap, setExpandedPrimaryGroupMap] = useState({});
  const hasViews = useHasViews();
  const currentViewHook = useCurrentView();
  const [, setViewUpdateState] = useViewUpdateState();
  const currentViewId = useCurrentViewId();
  const currentView = useDeepEqual(currentViewHook);

  /**
   * Reference of data rendered on screen before applying any multi-lang group action.
   * We need to keep this in ref so we always start with a clean slate when executing
   * any of the actions below.
   */
  const dataRef = useRef(undefined);
  const filtersRef = useRef(undefined);
  const currentViewIdRef = useRef(undefined);
  const {
    data,
    loading
  } = useFetchCrmObjectType();
  const {
    expandRow,
    collapseRow
  } = useExpandCollapseRow();
  const writeToTableCache = useWriteToTableCache();
  const {
    filters,
    hasAppliedFilters
  } = useCrmObjectTypeListingFilters();
  const isFilteringOrSearching = useMemo(() => Boolean(filters.query || hasAppliedFilters), [filters.query, hasAppliedFilters]);

  /**
   * Private method. Used by:
   *  - @handleShowMultiLanguageGroup
   *  - @handleShowAllMultiLanguageGroups
   *  - @handleHideAllMultiLanguageGroups
   */
  const _updateMapShowMLangGroupAndWriteToCache = useCallback(newExpandedPrimaryGroupMap => {
    if (!dataRef.current) {
      return;
    }
    setExpandedPrimaryGroupMap(newExpandedPrimaryGroupMap);
    const results = showMultiLanguageGroup([...dataRef.current.results], Object.entries(newExpandedPrimaryGroupMap));

    /**
     * Update total by removing only lang variations that have been deleted by @showMultiLanguageGroup
     */
    const removedObjects = dataRef.current.results.length - results.length;
    writeToTableCache(Object.assign({}, dataRef.current, {
      results,
      total: dataRef.current.total - removedObjects
    }));
    listingLibObserver.countPrimaryGroups(countPrimaryGroups(results));
  }, [writeToTableCache]);
  const _handleViewStateConfig = useCallback(({
    showAll
  }) => {
    if (!hasViews || !currentView) {
      return;
    }
    const mLangGroupOptions = currentView.state.mLangGroup || {
      showAll: false
    };
    setViewUpdateState(previousViewUpdateState => Object.assign({}, previousViewUpdateState, {
      mLangGroup: {
        changed: mLangGroupOptions.showAll !== undefined && mLangGroupOptions.showAll !== showAll,
        showAll
      }
    }));
  }, [currentView, hasViews, setViewUpdateState]);
  const handleShowMultiLanguageGroup = useCallback((primaryGroupObjectId, translatedContentObjectIds) => {
    if (!dataRef.current) {
      return;
    }
    const newExpandedPrimaryGroupMap = Object.assign({}, expandedPrimaryGroupMap, {
      [primaryGroupObjectId]: translatedContentObjectIds
    });
    _updateMapShowMLangGroupAndWriteToCache(newExpandedPrimaryGroupMap);
    expandRow(primaryGroupObjectId);
    const crmObjectsFromGroup = getAllCrmObjectsFromGroup([...dataRef.current.results], {
      [primaryGroupObjectId]: translatedContentObjectIds
    });
    listingLibObserver.deselectObjects(crmObjectsFromGroup);
  }, [_updateMapShowMLangGroupAndWriteToCache, expandRow, expandedPrimaryGroupMap]);
  const handleShowAllMultiLanguageGroups = useCallback(() => {
    if (!dataRef.current) {
      return;
    }
    const primaryGroupMap = getPrimaryGroupMap([...dataRef.current.results], {
      includeTranslatedContentIds: true
    });
    _updateMapShowMLangGroupAndWriteToCache(primaryGroupMap);
    const primaryGroupIds = Object.keys(primaryGroupMap);
    for (const primaryGroupId of primaryGroupIds) {
      const intPrimaryGroupId = parseInt(primaryGroupId, 10);
      expandRow(intPrimaryGroupId);
    }
    _handleViewStateConfig({
      showAll: true
    });
    const crmObjectsFromGroup = getAllCrmObjectsFromGroup([...dataRef.current.results], primaryGroupMap);
    listingLibObserver.deselectObjects(crmObjectsFromGroup);
  }, [_handleViewStateConfig, _updateMapShowMLangGroupAndWriteToCache, expandRow]);
  const handleHideAllMultiLanguageGroups = useCallback(() => {
    if (!dataRef.current) {
      return;
    }

    /**
     * Same as "show all mLang group" except we don't need to send translated content ids
     */
    const primaryGroupMap = getPrimaryGroupMap([...dataRef.current.results], {
      includeTranslatedContentIds: false
    });
    _updateMapShowMLangGroupAndWriteToCache(primaryGroupMap);
    const primaryGroupIds = Object.keys(primaryGroupMap);
    for (const primaryGroupId of primaryGroupIds) {
      const intPrimaryGroupId = parseInt(primaryGroupId, 10);
      collapseRow(intPrimaryGroupId);
      listingLibObserver.unregisterParentRow(intPrimaryGroupId);
    }
    _handleViewStateConfig({
      showAll: false
    });
    const crmObjectsFromGroup = getAllCrmObjectsFromGroup([...dataRef.current.results], primaryGroupMap);
    listingLibObserver.deselectObjects(crmObjectsFromGroup);
  }, [_handleViewStateConfig, _updateMapShowMLangGroupAndWriteToCache, collapseRow]);
  const handleHideMultiLanguageGroup = useCallback(primaryGroupObjectId => {
    /**
     * This is the same as "show mLang group" for all other primary groups that have been previously expanded except for @objectId.
     */
    handleShowMultiLanguageGroup(primaryGroupObjectId, []);
    collapseRow(primaryGroupObjectId);
    listingLibObserver.unregisterParentRow(primaryGroupObjectId);
  }, [collapseRow, handleShowMultiLanguageGroup]);
  const handleRemoveFromMultiLanguageGroup = useCallback((primaryGroupObjectId, translatedContentToRemove) => {
    if (!dataRef.current) {
      return;
    }

    /**
     * Remove mLang group from cache ref
     */
    const results = removeFromMultiLanguageGroup([...dataRef.current.results], primaryGroupObjectId, translatedContentToRemove, isFilteringOrSearching);
    dataRef.current = Object.assign({}, dataRef.current, {
      results,
      total: results.length
    });
    listingLibObserver.countPrimaryGroups(countPrimaryGroups(dataRef.current.results));

    /**
     * Once cache ref is updated, update mLangGroups that are open
     * with @showMultiLanguageGroup
     */
    handleShowMultiLanguageGroup(primaryGroupObjectId, []);
    collapseRow(primaryGroupObjectId);
    listingLibObserver.unregisterParentRow(primaryGroupObjectId);
  }, [collapseRow, handleShowMultiLanguageGroup, isFilteringOrSearching]);
  const handleMakePrimaryMultiLanguageGroup = useCallback((primaryGroupObjectId, translatedContent) => {
    if (!dataRef.current) {
      return;
    }
    const results = makePrimaryLanguageGroup([...dataRef.current.results], primaryGroupObjectId, translatedContent, isFilteringOrSearching);
    dataRef.current = Object.assign({}, dataRef.current, {
      results,
      total: results.length
    });
    listingLibObserver.countPrimaryGroups(countPrimaryGroups(dataRef.current.results));

    /**
     * Once cache ref is updated, update mLangGroups that are open
     * with @showMultiLanguageGroup
     */
    handleShowMultiLanguageGroup(primaryGroupObjectId, []);
    collapseRow(primaryGroupObjectId);
    listingLibObserver.unregisterParentRow(primaryGroupObjectId);
  }, [collapseRow, handleShowMultiLanguageGroup, isFilteringOrSearching]);
  const handleMoveToMultiLanguageGroup = useCallback((primaryGroupObjectId, translatedContent) => {
    if (!dataRef.current) {
      return;
    }
    const results = moveToPrimaryGroup([...dataRef.current.results], primaryGroupObjectId, translatedContent);
    dataRef.current = Object.assign({}, dataRef.current, {
      results,
      total: results.length
    });
    listingLibObserver.countPrimaryGroups(countPrimaryGroups(dataRef.current.results));

    /**
     * Once cache ref is updated, update mLangGroups that are open
     * with @showMultiLanguageGroup
     */
    handleShowMultiLanguageGroup(primaryGroupObjectId, []);
    collapseRow(primaryGroupObjectId);
  }, [collapseRow, handleShowMultiLanguageGroup]);
  useEffect(() => {
    listingLibObserver.on(HIDE_ALL_MULTI_LANGUAGE_GROUPS, handleHideAllMultiLanguageGroups);
    listingLibObserver.on(HIDE_MULTI_LANGUAGE_GROUP, handleHideMultiLanguageGroup);
    listingLibObserver.on(SHOW_MULTI_LANGUAGE_GROUP, handleShowMultiLanguageGroup);
    listingLibObserver.on(SHOW_ALL_MULTI_LANGUAGE_GROUPS, handleShowAllMultiLanguageGroups);
    listingLibObserver.on(REMOVE_MULTI_LANGUAGE_GROUP, handleRemoveFromMultiLanguageGroup);
    listingLibObserver.on(MAKE_PRIMARY_MULTI_LANGUAGE_GROUP, handleMakePrimaryMultiLanguageGroup);
    listingLibObserver.on(MOVE_TO_MULTI_LANGUAGE_GROUP, handleMoveToMultiLanguageGroup);
    return () => {
      listingLibObserver.off(HIDE_ALL_MULTI_LANGUAGE_GROUPS, handleHideAllMultiLanguageGroups);
      listingLibObserver.off(HIDE_MULTI_LANGUAGE_GROUP, handleHideMultiLanguageGroup);
      listingLibObserver.off(SHOW_ALL_MULTI_LANGUAGE_GROUPS, handleShowAllMultiLanguageGroups);
      listingLibObserver.off(SHOW_MULTI_LANGUAGE_GROUP, handleShowMultiLanguageGroup);
      listingLibObserver.off(REMOVE_MULTI_LANGUAGE_GROUP, handleRemoveFromMultiLanguageGroup);
      listingLibObserver.off(MAKE_PRIMARY_MULTI_LANGUAGE_GROUP, handleMakePrimaryMultiLanguageGroup);
      listingLibObserver.off(MOVE_TO_MULTI_LANGUAGE_GROUP, handleMoveToMultiLanguageGroup);
    };
  }, [handleHideAllMultiLanguageGroups, handleHideMultiLanguageGroup, handleMakePrimaryMultiLanguageGroup, handleMoveToMultiLanguageGroup, handleRemoveFromMultiLanguageGroup, handleShowAllMultiLanguageGroups, handleShowMultiLanguageGroup]);

  /**
   * Sync @dataRef with latest table @data
   * Table @data is mutated in any of the actions above and we
   * need to start from a clean slate before applying subsequent actions.
   *
   * This will happen when:
   *   - a new filter is applied on the UI
   *   - a user navigates from one view to another
   *     - views can have the exact filter criteria and the data won't change
   *     - but this is necessary to reset the state of show/hide all mLangGroups
   */
  useEffect(() => {
    if (loading || !data) {
      dataRef.current = undefined;
      listingLibObserver.countPrimaryGroups(0);
      return;
    }
    const isChangingFilters = !isEqual(filters, filtersRef.current) && data;
    const isChangingView = currentViewId !== currentViewIdRef.current;
    const isResettingData = Boolean(isChangingFilters || isChangingView || !dataRef.current);
    const shouldUpdateHideShowAll = Boolean(hasViews && currentView && (isResettingData || isChangingView));
    if (isChangingView) {
      currentViewIdRef.current = currentViewId;
    }
    if (isResettingData) {
      filtersRef.current = filters;
      dataRef.current = data;
      listingLibObserver.countPrimaryGroups(countPrimaryGroups(data.results || []));
      listingLibObserver.resetMultiLanguageGroupState();
    }

    /**
     * Show/hide all mLangGroups when:
     *  - new data comes in for current view OR
     *  - user has changed to a new view
     */
    if (shouldUpdateHideShowAll) {
      const countGroups = dataRef.current && dataRef.current.results ? countPrimaryGroups(dataRef.current.results) : 0;
      if (countGroups === 0 || currentView.state.mLangGroup === undefined) {
        return;
      }
      if (currentView.state.mLangGroup.showAll) {
        handleShowAllMultiLanguageGroups();
      } else {
        handleHideAllMultiLanguageGroups();
      }
    }
  }, [currentView, currentViewId, data, filters, handleHideAllMultiLanguageGroups, handleShowAllMultiLanguageGroups, hasViews, loading]);
}