import { useCrmObjectType } from 'framework-listing-lib/internal/providers/CrmObjectTypeListingClientProvider';
export default function ListingLibExpandableContent({
  crmObject,
  isParentRowSelected,
  nestedLevel,
  renderNestedContent,
  tableColumns,
  tableRowProps
}) {
  const {
    expandableObjectIds
  } = useCrmObjectType();
  const isRowExpanded = expandableObjectIds.includes(crmObject.objectId);
  if (!isRowExpanded || !renderNestedContent) {
    return null;
  }
  return renderNestedContent({
    parentCrmObject: crmObject,
    tableColumns,
    tableRowProps: Object.assign({}, tableRowProps, {
      isNested: true,
      isSelected: isParentRowSelected,
      nestedLevel: nestedLevel + 1
    })
  });
}