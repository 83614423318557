import http from 'hub-http/clients/apiClient';
import Raven from 'raven-js';
const TRIAL_GUIDE_PREFERENCES_API = 'trials/v1/trial-guide-preferences';
export async function setSurveyResponses(interestedFeatures, trialId) {
  try {
    await http.put(`${TRIAL_GUIDE_PREFERENCES_API}/${trialId}`, {
      data: {
        interestedFeatures
      }
    });
  } catch (error) {
    console.error('Error saving survey responses', error);
    Raven.captureException(error);
  }
}