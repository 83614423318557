'use es6';

import { Map as ImmutableMap } from 'immutable';
import bulkSelection from './bulkSelection';
import campaignIds from './campaignIds';
import emailIds from './emailIds';
import fetchStatus from './fetchStatus';
import hasArchived from './hasArchived';
import hasEmails from './hasEmails';
import isCustomizingColumns from './isCustomizingColumns';
import limit from './limit';
import offset from './offset';
import selectedUsersTeamsEmails from './selectUsersTeamsEmails';
import selectedEmail from './selectedEmail';
import selectedEmailRows from './selectedEmailRows';
import selectedDetailsPanelTab from './selectedDetailsPanelTab';
import sortColumn from './sortColumn';
import sortDir from './sortDir';
import total from './total';
import visibleColumns from './visibleColumns';
export default ((state = ImmutableMap(), action) => {
  const prevSortColumn = state.get('sortColumn');
  const newSortColumn = sortColumn(prevSortColumn, action);
  const sameColumn = prevSortColumn === newSortColumn;
  return state.merge({
    selectedUsersTeamsEmails: selectedUsersTeamsEmails(state.get('selectedUsersTeamsEmails'), action),
    bulkSelection: bulkSelection(state.get('bulkSelection'), action),
    campaignIds: campaignIds(state.get('campaignIds'), action),
    emailIds: emailIds(state.get('emailIds'), action),
    selectedEmail: selectedEmail(state.get('selectedEmail'), action),
    selectedDetailsPanelTab: selectedDetailsPanelTab(state.get('selectedDetailsPanelTab'), action),
    fetchStatus: fetchStatus(state.get('fetchStatus'), action),
    isCustomizingColumns: isCustomizingColumns(state.get('isCustomizingColumns'), action),
    limit: limit(state.get('limit'), action),
    offset: offset(state.get('offset'), action),
    selectedEmailRows: selectedEmailRows(state.get('selectedEmailRows'), action),
    sortColumn: newSortColumn,
    sortDir: sortDir(state.get('sortDir'), action, sameColumn),
    total: total(state.get('total'), action),
    visibleColumns: visibleColumns(state.get('visibleColumns'), action),
    hasArchived: hasArchived(state.get('hasArchived'), action),
    hasEmails: hasEmails(state.get('hasEmails'), action)
  });
});