'use es6';

import { GDPR_TOGGLE_ENABLED, LEGAL_BASIS_REQUIREMENT_ENABLED } from 'EmailData/portalSettings/constants';
export const getPortalSettings = state => state.get('portalSettings');
export const getIsPortalSettingsLoaded = state => state.getIn(['portalSettings']).getIsLoaded();
export const getIsTrackingConfigurationLoaded = state => {
  const trackingSettings = state.getIn(['portalSettings', 'otherSettings', 'tracking']);
  return trackingSettings && trackingSettings.isLoaded();
};
export const getTrackingConfiguration = state => state.getIn(['portalSettings', 'otherSettings', 'tracking']);
export const getHasGDPRToggleEnabled = state => state.getIn(['portalSettings']).getValue(GDPR_TOGGLE_ENABLED) === 'true';
export const getHasLegalBasisRequirementEnabled = state => state.getIn(['portalSettings']).getValue(LEGAL_BASIS_REQUIREMENT_ENABLED) === 'true';