import http from 'hub-http/clients/apiClient';
import { useQuery, registerQuery } from 'data-fetching-client';
import useCrmObjectTypeScopes from 'framework-listing-lib/internal/hooks/useCrmObjectTypeScopes';
import applyPermissionsOverride from 'framework-listing-lib/utils/applyPermissionsOverride';
const GET_TEAMS_FIELD_NAME = 'teams';
const GET_TEAMS = registerQuery({
  fieldName: GET_TEAMS_FIELD_NAME,
  args: ['canViewAllAssets', 'canViewTeamOwnedAssets'],
  fetcher({
    canViewAllAssets,
    canViewTeamOwnedAssets
  }) {
    if (canViewAllAssets) {
      // If a user can view all assets, show all teams in the table filter
      return http.get(`app-users/v1/teams/hierarchy`);
    } else if (canViewTeamOwnedAssets) {
      // If a user can view team-owned assets, show only the user's teams
      // in the table filter.
      return http.get(`app-users/v1/teams/user/all`, {
        query: {
          includeChildTeamMembers: true,
          includeHierarchy: true
        }
      });
    } else {
      return Promise.resolve([]);
    }
  }
});
export default function useFetchTeams({
  permissionsOverride,
  viewAllAssetsScope,
  viewTeamOwnedAssetsScope
}) {
  const scopes = useCrmObjectTypeScopes({
    viewAllAssetsScope,
    viewTeamOwnedAssetsScope
  });
  const finalScopes = applyPermissionsOverride(scopes, permissionsOverride);
  const {
    data,
    error,
    loading
  } = useQuery(GET_TEAMS, {
    variables: {
      canViewAllAssets: finalScopes.canViewAllAssets,
      canViewTeamOwnedAssets: finalScopes.canViewTeamOwnedAssets
    }
  });
  return {
    data: data && GET_TEAMS_FIELD_NAME in data ? data.teams : [],
    error,
    loading
  };
}