export const DEFAULT_PAGE_SIZE = 25;
export const DEFAULT_GALLERY_PAGE_SIZE = 24;
export const MAXIMUM_CRM_SEARCH_PAGE_SIZE = 500;
export const PAGE_SIZE_OPTIONS = [25, 50, 75, 100];
export const GALLERY_PAGE_SIZE_OPTIONS = [24, 48, 72, 96];
export const MAX_VISIBLE_BUTTONS = 10;
export const MIN_VISIBLE_BUTTONS = 5;
export const MIN_WIDTH_FOR_FIRST_LAST_BUTTONS = 1000;
export const MIN_WIDTH_FOR_MAX_VISIBLE_BUTTONS = 768;

// copied from `CRM/crm_ui/static/js/grid/DataTablePaginator`
export const RESULTS_LIMIT = 10000;