export const LIST_PROPERTIES = {
  LIST_SIZE: 'hs_list_size',
  LIST_NAME: 'hs_list_name',
  LIST_TYPE: 'hs_processing_type',
  PROCESSING_STATUS: 'hs_processing_status',
  DELETED_AT: 'hs_deleted_at',
  INBOUND_LIST_ID: 'hs_list_id',
  CREATED_AT: 'hs_created_at',
  UPDATED_AT: 'hs_updated_at',
  LIST_CLASSIC_ID: 'hs_classic_list_id'
};
export const LIST_PROCESSING_TYPE = {
  STATIC: 'STATIC',
  MANUAL: 'MANUAL',
  EVALUATION_ONLY: 'EVALUATION_ONLY',
  SNAPSHOT: 'SNAPSHOT'
};
export const CONTACT_LIST_FILTER = {
  objectTypeId: '0-1'
};
export const STATIC_TYPE_LIST = {
  processingTypes: [LIST_PROCESSING_TYPE.STATIC, LIST_PROCESSING_TYPE.MANUAL, LIST_PROCESSING_TYPE.EVALUATION_ONLY, LIST_PROCESSING_TYPE.SNAPSHOT]
};
export const INBOUND_LIST_PROPERTIES = [LIST_PROPERTIES.LIST_NAME, LIST_PROPERTIES.LIST_SIZE, LIST_PROPERTIES.DELETED_AT, LIST_PROPERTIES.PROCESSING_STATUS, LIST_PROPERTIES.LIST_TYPE, LIST_PROPERTIES.INBOUND_LIST_ID, LIST_PROPERTIES.CREATED_AT, LIST_PROPERTIES.LIST_CLASSIC_ID];
export const CONTACT_PROPERTY_EMAIL = 'email';
export const CONTACT_PROPERTY_FIRSTNAME = 'firstname';
export const CONTACT_PROPERTY_LASTNAME = 'lastname';
export const CONTACT_PROPERTY_CREATEDATE = 'createdate';
export const CONTACT_PROPERTY_VID = 'vid';
export const CONTACT_PROPERTY_LASTMODIFIEDDATE = 'lastmodifieddate';
export const CONTACT_PROPERTY_PHONE = 'phone';
export const CONTACT_PROPERTY_MOBILEPHONE = 'mobilephone';
export const CONTACT_PROPERTY_COUNTRY_CODE = 'hs_calculated_phone_number_country_code';
export const CONTACT_PROPERTIES = [CONTACT_PROPERTY_EMAIL, CONTACT_PROPERTY_FIRSTNAME, CONTACT_PROPERTY_LASTNAME, CONTACT_PROPERTY_CREATEDATE, CONTACT_PROPERTY_PHONE, CONTACT_PROPERTY_MOBILEPHONE, CONTACT_PROPERTY_COUNTRY_CODE];
export const ASC = 'ASC';
export const DESC = 'DESC';
export const LIST_OR_CONTACT_COUNT = 50;
export const INCLUDE = 'include';
export const EXCLUDE = 'exclude';
export const CONTACTS = 'contacts';
export const LISTS = 'lists';
export const CONTACT = 'contact';
export const LIST = 'list';