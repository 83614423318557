import { useCallback, useMemo } from 'react';
import { useAdditionalPropertyValues } from '../../../v2/hooks/useAdditionalPropertyValues';
import { useObjectTypeId } from '../../../v2/hooks/useObjectTypeId';
import { getPipelineStagePropertyName, getPipelinePropertyName } from '../utils/pipelineUtils';
import { RESTART_APPROVAL_WARNING } from '../utils/pipelineValidationTypes';
import { useGetStageChangeValidator } from './useGetStageChangeValidator';
import { EDIT_INPUT_MODE } from '../../../v2/types/PropertyInputV2Component';
import { useStageValidationModal } from './useStageValidationModal';
import { useObjectId } from '../../../v2/hooks/useObjectId';
export const useStageChangeValidation = props => {
  const {
    onChangeMultiple,
    inputMode
  } = props;
  const objectTypeId = useObjectTypeId();
  const objectId = useObjectId();
  const pipelineStagePropertyName = getPipelineStagePropertyName(objectTypeId);
  const pipelinePropertyName = getPipelinePropertyName(objectTypeId);
  const {
    data: additionalValues,
    loading: additionalValuesLoading
  } = useAdditionalPropertyValues({
    originStageId: pipelineStagePropertyName,
    originPipelineId: pipelinePropertyName
  });
  const originStageId = additionalValues === null || additionalValues === void 0 ? void 0 : additionalValues.originStageId;
  const originPipelineId = additionalValues === null || additionalValues === void 0 ? void 0 : additionalValues.originPipelineId;
  const {
    loading,
    stageChangeValidator
  } = useGetStageChangeValidator();
  const {
    stageValidationModal,
    openModal,
    closeModal
  } = useStageValidationModal();
  const handleValidation = useCallback((validationRequest, pendingChanges) => {
    const validationResult = stageChangeValidator(validationRequest);

    // If we're aware that specific validation codes should warrant a confirmation modal we fire an open modal action here.
    // The modal needs to propagate the original onChange callback once onConfirm is called so we include the callback as part of the dispatched modal data.
    if (validationResult.code === RESTART_APPROVAL_WARNING) {
      openModal({
        onConfirm: () => {
          onChangeMultiple(pendingChanges);
          closeModal();
        },
        onCancel: () => {
          closeModal();
        }
      });
      return;
    }

    // Otherwise continue sending values back to host
    onChangeMultiple(pendingChanges);
  }, [openModal, closeModal, onChangeMultiple, stageChangeValidator]);
  const onChangeWithStageValidation = useCallback(changes => {
    // We don't want this validation triggering for other input modes such as bulk actions
    if (!objectId || inputMode !== EDIT_INPUT_MODE) {
      onChangeMultiple(changes);
      return;
    }
    const stageId = changes[pipelineStagePropertyName];

    // Check local changes first, then fallback to host app's value to grab the pipeline
    const pipelineId = changes[pipelinePropertyName] || originPipelineId;

    // If stage or pipeline isn't part of the property change, there is no validation needed.
    if (!stageId || !pipelineId) {
      onChangeMultiple(changes);
      return;
    }
    handleValidation({
      originStageId,
      originPipelineId,
      targetStageId: stageId,
      targetPipelineId: pipelineId
    }, changes);
  }, [originPipelineId, onChangeMultiple, pipelineStagePropertyName, handleValidation, pipelinePropertyName, originStageId, objectId, inputMode]);
  return useMemo(() => ({
    stageChangeValidatorLoading: loading || additionalValuesLoading,
    stageValidationModal,
    onChangeWithStageValidation
  }), [loading, additionalValuesLoading, stageValidationModal, onChangeWithStageValidation]);
};