'use es6';

import Raven from 'raven-js';
import { Map as ImmutableMap } from 'immutable';
import { createSelector } from 'reselect';
import Email from 'EmailData/models/Email';
import EmailType from 'EmailData/enums/EmailType';
import { getSelectedEmail } from 'email-management-lib/refactor/selectors/manage/table';
import { AB_EMAIL_WINNER, DETAIL_SELECT_ALL } from 'EmailData/utils/details/detailTypeUtil';
import { buildEmailFromChild } from 'EmailData/utils/details/blogRssEmailUtil';
import currentEmailDetails from 'EmailData/selectors/details/current/currentEmailDetails';
import currentEmailDetailsState from 'EmailData/selectors/details/current/currentEmailDetailsState';
import currentEmailType from 'EmailData/selectors/details/current/currentEmailType';
import currentSendChildren from 'EmailData/selectors/details/current/currentSendChildren';
const currentEmails = state => state.getIn(['entities', 'emails'], ImmutableMap({}));
const currentEmailByContentId = createSelector([getSelectedEmail, currentEmails], (contentId, emails) => {
  if (emails.isEmpty()) {
    return Email.from(ImmutableMap({}).toJSON());
  }
  return Email.from(emails.get(parseInt(contentId, 10), ImmutableMap({})).toJSON());
});
export const currentSelectedId = createSelector([currentEmailDetailsState], detailState => detailState.isEmpty() ? -1 : detailState.get('selectedId'));
const currentSelectedSendChild = createSelector([currentSelectedId, currentSendChildren], (selectedId, sendChildren) => {
  if (!sendChildren) {
    Raven.captureException(new Error('sendChildren is not defined'), {
      extra: {
        selectedId,
        sendChildren
      }
    });
  }
  return sendChildren.isEmpty() || !sendChildren ? null : sendChildren.get(selectedId);
});
const currentPrimaryEmail = createSelector([currentEmailDetails], emailDetail => emailDetail.get('primaryEmail'));
const currentVariantEmail = createSelector([currentEmailDetails], emailDetail => emailDetail.get('variantEmail'));
const isAbLike = emailType => {
  return emailType === EmailType.AB_EMAIL || emailType === EmailType.AUTOMATED_AB_EMAIL;
};
const getWinningEmail = createSelector([currentEmailDetails, currentEmailType], (emailDetail, emailType) => isAbLike(emailType) ? emailDetail.getWinningEmail() : null);
const getIsEmailDetailsEmpty = createSelector([currentEmailDetails], emailDetail => emailDetail.isEmpty());
const getHistoryTableState = state => state.getIn(['details', 'blogRssHistoryTable']);
const activeEmailSelector = createSelector([currentPrimaryEmail, currentVariantEmail, getIsEmailDetailsEmpty, getWinningEmail, currentSelectedId, currentSelectedSendChild, currentEmailType, getSelectedEmail, currentEmailByContentId, getHistoryTableState], (primaryEmail, variantEmail, isEmailDetailsEmpty, winningEmail, selectedId, selectedChild, emailType, contentId, dashboardEmail, historyTable) => {
  if (contentId > -1) {
    return dashboardEmail;
  }
  if (selectedId === -1 || isEmailDetailsEmpty) {
    return Email.from({});
  }
  if (selectedId === DETAIL_SELECT_ALL) {
    return primaryEmail;
  } else if (selectedId === AB_EMAIL_WINNER) {
    return winningEmail;
  }
  switch (emailType) {
    case EmailType.AB_EMAIL:
      {
        const isDraft = primaryEmail.isDraft();
        const aId = !isDraft ? primaryEmail.primaryEmailCampaignId : primaryEmail.id;
        return aId === selectedId ? primaryEmail : variantEmail;
      }
    case EmailType.BLOG_EMAIL:
    case EmailType.RSS_EMAIL:
      {
        return buildEmailFromChild(selectedChild, primaryEmail, historyTable.get('emails').getEmailAttemptData(selectedId));
      }
    case EmailType.LOCALTIME_EMAIL:
      {
        return selectedChild.mergeWith(primaryEmail);
      }
    case EmailType.BATCH_EMAIL:
      {
        if (selectedChild === null) {
          return primaryEmail;
        }
        return selectedChild.mergeWith(primaryEmail);
      }
    case EmailType.AUTOMATED_AB_EMAIL:
      return selectedId === variantEmail.primaryEmailCampaignId ? variantEmail : primaryEmail;
    default:
      return primaryEmail;
  }
});
export default activeEmailSelector;