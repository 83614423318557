import { useCallback, useRef } from 'react';
import { useObserveTableContainerRef } from '../../virtualization/hooks/useObserveTableContainerRef';
import { useResetTableState } from './useResetTableState';
export const useWatchTableContainer = (rowIds, disableScrollReset = false) => {
  const {
    handleObserveRef,
    observer
  } = useObserveTableContainerRef();
  const tableContainerRef = useRef(null);
  const handleTableContainerRef = useCallback(ref => {
    handleObserveRef(ref);
    tableContainerRef.current = ref;
  }, [handleObserveRef]);
  const resetObserver = useCallback(() => handleObserveRef(tableContainerRef.current), [handleObserveRef]);
  useResetTableState({
    rowIds,
    tableContainerRef,
    resetObserver,
    disableScrollReset
  });
  return {
    observer,
    handleTableContainerRef
  };
};