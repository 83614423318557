'use es6';

import combineImmutableReducers from 'EmailData/reducers/helpers/combineImmutableReducers';
import blogs from './blogs';
import campaignStatistics from './campaignStatistics';
import campaigns from './campaigns';
import emailDetails from './emailDetails';
import emails from './emails';
import folders from './folders';
import recipients from './recipients';
import recipientsEvent from './recipientEvents';
import subscriptions from './subscriptions';
import campaignCancellationReasons from './campaignCancellationReasons';
import campaignClicks from './campaignClicks';
import campaignStatuses from './campaignStatuses';
import contactRecords from './contactRecords';
import contactSearchRecords from './contactSearchRecords';
import ctas from './ctas';
import historicalImports from './historicalImports';
import hubUsers from './hubUsers';
import landingPages from './landingPages';
import lists from 'EmailData/reducers/lists';
import mailchimpImport from './mailchimpImport';
import onboardingSendStats from './onboardingSendStats';
import openDuration from './openDuration';
import overTimeStatistics from './overTimeStatistics';
import pqlSummaries from './pqlSummaries';
import recipientUnbounceStatus from './recipientUnbounceStatus';
import revenueOverTimeStatistics from './revenueOverTimeStatistics';
import revenueReportingStatistics from './revenueReportingStatistics';
import staticLists from './staticLists';
import tldrSendSummaries from './tldrSendSummaries';
import topClickedLinks from './topClickedLinks';
import topEngagedClickedContacts from './topEngagedClickedContacts';
import topEngagedContacts from './topEngagedContacts';
import topEngagedOpenedContacts from './topEngagedOpenedContacts';
import userAgentStatistics from './userAgentStatistics';
import workflows from './workflows';
import simpleWorkflows from './simpleWorkflows';
import reliableOpen from './reliableOpen';
import emailCampaignStatistics from './emailCampaignStatistics';
export default combineImmutableReducers({
  blogs,
  campaigns,
  campaignClicks,
  campaignStatistics,
  campaignStatuses,
  campaignCancellationReasons,
  contactRecords,
  contactSearchRecords,
  emails,
  emailDetails,
  folders,
  lists,
  mailchimpImport,
  openDuration,
  overTimeStatistics,
  userAgentStatistics,
  recipients,
  recipientsEvent,
  ctas,
  recipientUnbounceStatus,
  staticLists,
  landingPages,
  tldrSendSummaries,
  topEngagedContacts,
  topEngagedClickedContacts,
  topEngagedOpenedContacts,
  revenueReportingStatistics,
  revenueOverTimeStatistics,
  topClickedLinks,
  historicalImports,
  onboardingSendStats,
  subscriptions,
  pqlSummaries,
  workflows,
  simpleWorkflows,
  hubUsers,
  reliableOpen,
  emailCampaignStatistics
});