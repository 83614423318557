'use es6';

import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { RECEIVE_HAS_EMAIL, REQUEST_HAS_EMAIL } from 'email-management-lib/refactor/actions/actionTypes';
const initialState = null;
function hasEmails(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_HAS_EMAIL:
      if (action.error !== true) {
        return action.payload;
      }
      return initialState;
    default:
      return state;
  }
}
export default asyncResource(hasEmails, {
  requestActions: [REQUEST_HAS_EMAIL],
  responseActions: [RECEIVE_HAS_EMAIL],
  invalidatingActions: []
});