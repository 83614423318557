'use es6';

import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { RESET_EMAIL_DETAILS } from 'EmailData/actions/actionTypes';
import { FETCH_REVENUE_REPORTING_REQUEST, FETCH_REVENUE_REPORTING_RESULT } from 'email-management-lib/refactor/actions/actionTypes';
import RevenueReportingTotals from 'email-management-lib/refactor/models/RevenueReportingTotals';
const initialState = RevenueReportingTotals.from({});
function revenueReportingStatistics(state = initialState, action) {
  switch (action.type) {
    case FETCH_REVENUE_REPORTING_RESULT:
      if (action.error) {
        return state;
      }
      return RevenueReportingTotals.from(action.payload);
    case RESET_EMAIL_DETAILS:
      return initialState;
    default:
      return state;
  }
}
export default asyncResource(revenueReportingStatistics, {
  requestActions: [FETCH_REVENUE_REPORTING_REQUEST],
  responseActions: [FETCH_REVENUE_REPORTING_RESULT],
  invalidatingActions: [RESET_EMAIL_DETAILS]
});