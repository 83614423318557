import { OBJECT_TYPE } from 'marketing-platform-lib/constants/crm';
const GUID_REGEX = /^([0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12})$/;
export function hasProperty(crmObject, propertyName) {
  return Boolean(crmObject.properties && propertyName in crmObject.properties);
}

/**
 * Returns the value of @propertyName in @crmObject.
 *
 * If @propertyName is not present:
 *   - returns undefined when @defaultValue is not provided.
 *   - returns @defaultValue otherwise.
 * @param crmObject The CRM object
 * @param propertyName The property name
 * @param defaultValue Optional default value if @propertyName is not found in @crmObject
 */
export function getPropertyValue(crmObject, propertyName, defaultValue) {
  if (hasProperty(crmObject, propertyName)) {
    return crmObject.properties[propertyName].value;
  }
  return defaultValue;
}

/**
 * Set property value and return a new CrmObject
 * @param crmObject The CRM Object.
 * @param propertyName Property name to be updated.
 * @param value Property value.
 */
export function setPropertyValue(crmObject, propertyName, value) {
  let result = Object.assign({}, crmObject);

  // Create property if it doesn't exist.
  if (!hasProperty(result, propertyName)) {
    result = Object.assign({}, result, {
      properties: Object.assign({}, result.properties, {
        [propertyName]: {
          value: ''
        }
      })
    });
  }
  result = Object.assign({}, result, {
    properties: Object.assign({}, result.properties, {
      [propertyName]: Object.assign({}, result.properties[propertyName], {
        value
      })
    })
  });
  return result;
}
export function getFilterGroups(objectId, objectTypeId) {
  const objectIdFilter = {
    operator: 'EQ',
    property: 'hs_object_id',
    value: String(objectId)
  };
  const originAssetIdFilter = {
    operator: 'EQ',
    property: 'hs_origin_asset_id',
    value: String(objectId)
  };
  const contentIdFilter = {
    operator: 'EQ',
    property: 'hs_content_id',
    value: String(objectId)
  };

  /**
   * Assets that can also be filtered by hs_origin_asset_id:
   *  - [0-25] LANDING_PAGE: so we can fetch the full Landing page object from CMS BE
   *  - [0-38] SITE_PAGE: so we can fetch the full Site page object from CMS BE
   *  - [0-39] BLOG_POST: so we can fetch the full Blog Post object from CMS BE
   *  - [0-71] SOCIAL_BROADCAST: temporary filter condition until it is fully on the CRM.
   */
  if (objectTypeId === OBJECT_TYPE.LANDING_PAGE || objectTypeId === OBJECT_TYPE.SITE_PAGE || objectTypeId === OBJECT_TYPE.BLOG_POST || objectTypeId === OBJECT_TYPE.SOCIAL_BROADCAST) {
    return [{
      filters: [objectIdFilter]
    }, {
      filters: [originAssetIdFilter]
    }];
  }

  /**
   * Asset that needs to be filtered by hs_content_id:
   *  - [0-10] CONTENT: so we can fetch the full Content object from CMS BE
   */
  if (objectTypeId === OBJECT_TYPE.CONTENT) {
    return [{
      filters: [objectIdFilter]
    }, {
      filters: [contentIdFilter]
    }];
  }

  /**
   * If fetching CAMPAIGN (0-35) and @objectId is in guid format, query using hs_origin_asset_id only
   */
  if (objectTypeId === OBJECT_TYPE.CAMPAIGN && GUID_REGEX.test(String(objectId))) {
    return [{
      filters: [originAssetIdFilter]
    }];
  }
  return [{
    filters: [objectIdFilter]
  }];
}

/**
 * Convert @crmObject to a UISelect option object.
 *
 * For objects that have `hs_name` property:
 *   - propagate object id and `hs_name.value`.
 *
 * For objects that don't have `hs_name` property:
 *   - propagate object id and serialize all properties
 *
 */
export function convertToSelectOption(crmObject, typeDefinition) {
  const propertyValue = getPropertyValue(crmObject, typeDefinition && typeDefinition.primaryDisplayLabelPropertyName || '');
  if (propertyValue) {
    return {
      text: propertyValue,
      value: crmObject.objectId
    };
  }
  if ('hs_name' in crmObject.properties) {
    return {
      text: `${crmObject.properties.hs_name.value}`,
      value: crmObject.objectId
    };
  }
  if (crmObject.objectTypeId === OBJECT_TYPE.PUBLISHING_TASK && 'hs_publishing_task_name' in crmObject.properties) {
    return {
      text: `${crmObject.properties.hs_publishing_task_name.value}`,
      value: crmObject.objectId
    };
  }

  /**
   * Serialize all properties for CRM objects that don't have @hs_name property
   */
  const propertiesArr = Object.keys(crmObject.properties).map(key => ({
    name: key,
    value: crmObject.properties[key].value
  }));
  return {
    text: JSON.stringify(propertiesArr),
    value: crmObject.objectId
  };
}
export function getCrmSearchVariables(objectTypeId, objectId) {
  const variables = {
    fetchParams: {
      count: 1,
      filterGroups: getFilterGroups(objectId, objectTypeId),
      objectTypeId,
      offset: 0,
      query: '',
      requestOptions: {
        allPropertiesFetchMode: 'latest_version',
        includeAllProperties: true
      },
      sorts: []
    },
    quickFetchName: `fetch-crm-object-${objectTypeId}-${objectId}`
  };
  return variables;
}

/**
 * Returns true if @objectTypeId is managed by the CRM team.
 */
export function isStandardCrmObject(objectTypeId) {
  return [OBJECT_TYPE.CONTACT.toString(), OBJECT_TYPE.COMPANY.toString(), OBJECT_TYPE.DEAL.toString(), OBJECT_TYPE.TICKET.toString()].includes(objectTypeId);
}