import { keyMirror } from 'email-health-ui-lib/shared/utils/object';
import { pick } from 'underscore';
export const DEFAULT_FILTER_KEY = 'default';
export const INTERSECTING_FILTERS = {
  BUSINESS_UNIT: 'businessUnitId'
};
export const EXCLUSIVE_FILTERS = {
  CAMPAIGN: 'hCampaign',
  EMAIL_TYPE: 'hEmailType',
  SUBSCRIPTIONS: 'hSubscriptions'
};
export const SUBSCRIPTION_CLASSIFICATION_TYPES = keyMirror({
  ALL_MARKETING: null,
  ALL_TRANSACTIONAL: null,
  ALL_MARKETING_AND_TRANSACTIONAL: null
});

// If changing these values, keep in mind they are duplicated in ContentEmailUI/api/health.js
export const FILTERS = Object.assign({}, INTERSECTING_FILTERS, EXCLUSIVE_FILTERS);
export const DEFAULT_FILTER_PARAMS = {
  [FILTERS.BUSINESS_UNIT]: undefined,
  [FILTERS.CAMPAIGN]: undefined,
  [FILTERS.EMAIL_TYPE]: undefined,
  [FILTERS.SUBSCRIPTIONS]: undefined
};
export const CLEARED_EXCLUSIVE_FILTERS = pick(DEFAULT_FILTER_PARAMS, Object.values(EXCLUSIVE_FILTERS));
export const EMAIL_TYPES = keyMirror({
  AB: null,
  AUTOMATED: null,
  BLOG: null,
  DOUBLE_OPT_IN: null,
  FEEDBACK: null,
  FOLLOWUP: null,
  INTERNAL_NOTIFICATION: null,
  REGULAR: null,
  RESUBSCRIPTION_EMAILS: null,
  RSS: null,
  SMTP_API: null,
  SINGLE_SEND_API: null,
  TICKET: null,
  TIME_ZONE: null,
  AUTOMATED_AB: null
});
export const MULTI_SELECT_FILTERS = [FILTERS.CAMPAIGN, FILTERS.SUBSCRIPTIONS];