'use es6';

import { Map as ImmutableMap } from 'immutable';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { FETCH_CONTACT_SEARCH_RECORDS, FETCH_CONTACT_SEARCH_RECORDS_REQUEST, RESET_CONTACT_SEARCH_RECORDS } from 'email-management-lib/refactor/actions/actionTypes';
import ContactRecord from 'EmailData/models/contacts/ContactRecord';
const initialState = ImmutableMap();
function contactSearchRecords(state = initialState, action) {
  switch (action.type) {
    case FETCH_CONTACT_SEARCH_RECORDS:
      if (action.error !== true) {
        return ImmutableMap(action.payload.map((contact, index) => [index, ContactRecord.from(contact)]));
      }
      return state;
    case RESET_CONTACT_SEARCH_RECORDS:
      return action.payload;
    default:
      return state;
  }
}
export default asyncResource(contactSearchRecords, {
  requestActions: [FETCH_CONTACT_SEARCH_RECORDS_REQUEST],
  responseActions: [FETCH_CONTACT_SEARCH_RECORDS],
  invalidatingActions: [RESET_CONTACT_SEARCH_RECORDS]
});