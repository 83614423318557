import { useMutation } from 'data-fetching-client';
import { SUBMIT_INSIGHT_FEEDBACK } from '../queries';
import { useCallback } from 'react';
export default function useSubmitInsightsFeedback({
  insightId,
  feedbackEntityType = 'EMAIL_CAMPAIGN_INSIGHT'
}) {
  const [submitFeedback, {
    loading: insightFeedbackSubmissionLoading,
    error: insightFeedbackSubmissionError
  }] = useMutation(SUBMIT_INSIGHT_FEEDBACK);
  const submitInsightFeedback = useCallback(({
    sentiment,
    selectedCategories,
    feedbackText
  }) => submitFeedback({
    variables: {
      entityId: insightId,
      feedbackEntityType,
      feedbackSentiment: sentiment,
      feedbackCategories: selectedCategories,
      feedbackComment: feedbackText
    }
  }), [feedbackEntityType, insightId, submitFeedback]);
  return {
    submitInsightFeedback,
    insightFeedbackSubmissionLoading,
    insightFeedbackSubmissionError
  };
}