import { intersection } from 'underscore';
import { createSelector } from 'reselect';
import { getStateProp } from 'ContentData/utils/stateUtils';
import * as RequestStatus from 'ContentUtils/constants/RequestStatus';
import { areOnlyChildTeams, getAllTeamIds
// @ts-expect-error will need to move helpers to ContentData in order to share types
} from 'ContentUtils/js/helpers/TeamsHelpers';
export const getTeams = state => getStateProp(state, 'resources').teams.teams.data;
export const getTeamsFetchStatus = state => getStateProp(state, 'resources').teams.requestStatus;
export const getTeamsRequestIsLoading = state => getTeamsFetchStatus(state) === RequestStatus.PENDING;
export const getTeamsRequestSucceeded = state => getTeamsFetchStatus(state) === RequestStatus.SUCCEEDED;
export const getTeamsRequestDone = state => getTeamsFetchStatus(state) === RequestStatus.SUCCEEDED || getTeamsFetchStatus(state) === RequestStatus.FAILED;
export const getTeamsAreOnlyChildTeams = createSelector([getTeams], teams => areOnlyChildTeams(teams));
export const getSomeTeamsAreAvailableInHierarchy = teamIds => createSelector([getTeams], teamsFromHierarchy => intersection(teamIds, getAllTeamIds(teamsFromHierarchy)).length > 0);