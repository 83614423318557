import { Record } from 'immutable';
class StatisticsDeviceCounters extends Record({
  computer: 0,
  mobile: 0,
  tablet: 0,
  unknown: 0,
  desktopPercent: 0,
  desktopPercentExcludingBots: 0,
  mobilePercent: 0,
  mobilePercentExcludingBots: 0,
  unknownPercent: 0,
  unknownPercentExcludingBots: 0
}) {
  static from(json) {
    return new StatisticsDeviceCounters(json);
  }
  getPercentageOf(type) {
    const total = this.computer + this.mobile + this.tablet + this.unknown;
    if (typeof this[type] === 'undefined') {
      return 0;
    }
    const floatTotal = total > 0 ? this[type] / total : 0;
    return Math.round(floatTotal * 100) / 100;
  }
}
export default StatisticsDeviceCounters;