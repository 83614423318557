'use es6';

import { Record } from 'immutable';
import PortalDismissStatus from 'EmailData/models/PortalDismissStatus';
import PortalEmailStatus from 'EmailData/models/PortalEmailStatus';
import PortalHealthStatus from 'EmailData/models/PortalHealthStatus';
const defaults = {
  loading: false,
  dismissStatus: null,
  emailStatus: null,
  precogHealthStatus: null,
  disabledFunctionality: [],
  activePortalMessages: []
};
class PortalStatus extends Record(defaults) {
  static from(json) {
    const dismissStatus = PortalDismissStatus.from(json && json.dismissStatus ? json.dismissStatus : {});
    const emailStatus = PortalEmailStatus.from(json && json.emailPortalStatus ? json.emailPortalStatus : {});
    const precogHealthStatus = PortalHealthStatus.from(json && json.precogPortalStatus ? json.precogPortalStatus : {});
    return new PortalStatus(Object.assign({
      dismissStatus,
      emailStatus,
      precogHealthStatus
    }, json));
  }
  isAllowedToSend() {
    return !this.emailStatus.isSuspended() && !this.precogHealthStatus.isSuspended();
  }
  isLoading() {
    return this.loading;
  }
  isPortalSuspended() {
    return !this.isAllowedToSend();
  }
  getDismissStatus() {
    return this.dismissStatus;
  }
  getHealthStatus() {
    return this.precogHealthStatus;
  }
}
export default PortalStatus;