import { COUNT_PRIMARY_GROUPS, HIDE_ALL_MULTI_LANGUAGE_GROUPS, HIDE_MULTI_LANGUAGE_GROUP, MAKE_PRIMARY_MULTI_LANGUAGE_GROUP, MOVE_TO_MULTI_LANGUAGE_GROUP, REMOVE_MULTI_LANGUAGE_GROUP, SHOW_ALL_MULTI_LANGUAGE_GROUPS, SHOW_MULTI_LANGUAGE_GROUP } from 'framework-listing-lib/internal/MultiLanguageGroup/constants/actions';
import { CHANGE_UI_MODE, CLEAR_DELETED_OBJECTS, CLOSE_EDIT_COLUMNS_MODAL, CLOSE_EXPORT_MODAL, CREATE_OBJECTS, DELETE_OBJECTS, DESELECT_OBJECTS, EMPTY_STATE_CHANGE, MANAGE_ACCESS_SUCCESS, OPEN_EDIT_COLUMNS_MODAL, OPEN_EXPORT_MODAL, REFRESH_RESOLVED_CELL, SET_BULK_SELECTION_MODE, TABLE_ACTIONS_READY, UNREGISTER_PARENT_ROW, UPDATE_BULK_ACTIONS_STATE, UPDATE_OBJECTS, UPDATE_PROPERTY_VALUES, CLOSE_CUSTOMIZE_GALLERY_SIDE_PANEL, OPEN_CUSTOMIZE_GALLERY_SIDE_PANEL, RESET_MULTI_LANGUAGE_GROUP_STATE } from 'framework-listing-lib/constants/tableEvents';
import { ASSET_ADDED_TO_FOLDER, FOLDER_CREATED, FOLDER_DELETED, FOLDER_SELECTED, FOLDER_UPDATED } from 'framework-listing-lib/constants/folderEvents';
class ListingLibObserver {
  constructor() {
    this.observers = {};
  }
  on(event, observer) {
    if (!this.observers[event]) {
      this.observers[event] = [];
    }
    this.observers[event].push(observer);
  }
  off(event, observer) {
    if (!this.observers[event]) {
      return;
    }
    this.observers[event] = this.observers[event].filter(ob => ob !== observer);
  }
  notify(event, ...args) {
    if (this.observers[event]) {
      this.observers[event].forEach(observer => observer(...args));
    }

    /**
     * Notifies all events when subscribed to '*'
     */
    if (this.observers['*']) {
      this.observers['*'].forEach(observer => observer(event, ...args));
    }
  }
  create(crmObject) {
    this.notify(CREATE_OBJECTS, crmObject);
  }
  delete(objectIdOrIds) {
    this.notify(DELETE_OBJECTS, objectIdOrIds);
  }
  clearDeletedObjects() {
    this.notify(CLEAR_DELETED_OBJECTS);
  }
  update(crmObject) {
    this.notify(UPDATE_OBJECTS, crmObject);
  }
  updatePropertyValues({
    propertyValues,
    selectedIds
  }) {
    this.notify(UPDATE_PROPERTY_VALUES, {
      propertyValues,
      selectedIds
    });
  }
  manageAccessSuccess(response) {
    this.notify(MANAGE_ACCESS_SUCCESS, response);
  }
  notifyEmptyStateChange(emptyStateResult) {
    this.notify(EMPTY_STATE_CHANGE, emptyStateResult);
  }
  closeEditColumnsModal() {
    this.notify(CLOSE_EDIT_COLUMNS_MODAL);
  }
  openEditColumnsModal(modalProps) {
    this.notify(OPEN_EDIT_COLUMNS_MODAL, modalProps);
  }
  closeExportModal() {
    this.notify(CLOSE_EXPORT_MODAL);
  }
  openExportModal(modalProps) {
    this.notify(OPEN_EXPORT_MODAL, modalProps);
  }
  closeCustomizeGallerySidePanel() {
    this.notify(CLOSE_CUSTOMIZE_GALLERY_SIDE_PANEL);
  }
  openCustomizeGallerySidePanel(modalProps) {
    this.notify(OPEN_CUSTOMIZE_GALLERY_SIDE_PANEL, modalProps);
  }
  refreshResolvedCell(id) {
    this.notify(REFRESH_RESOLVED_CELL, id);
  }
  folderCreated(folder) {
    this.notify(FOLDER_CREATED, folder);
  }
  folderDeleted(folder) {
    this.notify(FOLDER_DELETED, folder);
  }
  folderSelected(folder) {
    this.notify(FOLDER_SELECTED, folder);
  }
  folderUpdated(folder) {
    this.notify(FOLDER_UPDATED, folder);
  }
  assetAddedToFolder(response, folderCounts) {
    this.notify(ASSET_ADDED_TO_FOLDER, response, folderCounts);
  }
  hideAllMultiLanguageGroups() {
    this.notify(HIDE_ALL_MULTI_LANGUAGE_GROUPS);
  }
  hideMultiLanguageGroup(primaryGroupObjectId) {
    this.notify(HIDE_MULTI_LANGUAGE_GROUP, primaryGroupObjectId);
  }
  showAllMultiLanguageGroups() {
    this.notify(SHOW_ALL_MULTI_LANGUAGE_GROUPS);
  }
  showMultiLanguageGroup(primaryGroupObjectId, translatedContentObjectIds) {
    this.notify(SHOW_MULTI_LANGUAGE_GROUP, primaryGroupObjectId, translatedContentObjectIds);
  }
  removeFromMultiLanguageGroup(primaryGroupObjectId, translatedContentToRemove) {
    this.notify(REMOVE_MULTI_LANGUAGE_GROUP, primaryGroupObjectId, translatedContentToRemove);
  }
  makePrimaryGroup(primaryGroupObjectId, translatedContent) {
    this.notify(MAKE_PRIMARY_MULTI_LANGUAGE_GROUP, primaryGroupObjectId, translatedContent);
  }
  moveToPrimaryGroup(primaryGroupObjectId, translatedContent) {
    this.notify(MOVE_TO_MULTI_LANGUAGE_GROUP, primaryGroupObjectId, translatedContent);
  }
  countPrimaryGroups(count) {
    this.notify(COUNT_PRIMARY_GROUPS, count);
  }
  changeUIMode(uiMode) {
    this.notify(CHANGE_UI_MODE, uiMode);
  }

  /**
   * Used by content-management-ui to de-select all rows when a modal is closed.
   */
  setBulkActionSelectionMode(selectionMode) {
    this.notify(SET_BULK_SELECTION_MODE, selectionMode);
  }
  setBulkActionsState(newState) {
    this.notify(UPDATE_BULK_ACTIONS_STATE, newState);
  }
  unregisterParentRow(objectId) {
    this.notify(UNREGISTER_PARENT_ROW, objectId);
  }

  /**
   * Used by multi-language group
   * @param crmObjects
   */
  deselectObjects(crmObjects) {
    this.notify(DESELECT_OBJECTS, crmObjects);
  }
  tableActionsReady() {
    this.notify(TABLE_ACTIONS_READY);
  }
  resetMultiLanguageGroupState() {
    this.notify(RESET_MULTI_LANGUAGE_GROUP_STATE);
  }
}
const listingLibObserver = new ListingLibObserver();
export default listingLibObserver;