import PortalIdParser from 'PortalIdParser';
import Routes from 'ContentUtils/Routes';
import { stringify } from 'hub-http/helpers/params';
export const getEmailPreviewUrl = (contentId, previewKey, isDraftEmail, convertToDragDrop = false) => {
  const previewDomain = Routes.getPreviewDomain();
  const cacheBust = isDraftEmail ? Date.now() : 0;
  const params = {
    portalId: PortalIdParser.get(),
    preview_key: previewKey,
    _preview: true,
    from_buffer: isDraftEmail,
    cacheBust,
    hsPreviewerApp: 'email'
  };
  if (convertToDragDrop) {
    params.convert_to_dd = true;
  }
  return `https://${previewDomain}/_hcms/preview/email/${contentId}?${stringify(params)}`;
};