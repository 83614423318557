'use es6';

import { Map as ImmutableMap } from 'immutable';
import I18n from 'I18n';
import { FETCH_SITE_SETTINGS, FILTER_OLD_PQLS, SAVE_PQL } from 'email-management-lib/refactor/actions/actionTypes';
import { MAX_PQL_AGE_IN_DAYS, TLDR_PQLS } from 'email-management-lib/refactor/constants/details/pqlConstants';
const initialState = ImmutableMap(TLDR_PQLS).reduce((initialPqls, pql) => initialPqls.set(pql, ImmutableMap({})), ImmutableMap({}));
export default function pqlSummaries(state = initialState, action) {
  switch (action.type) {
    case FETCH_SITE_SETTINGS:
      {
        return ImmutableMap(action.payload.emailSmartPQLs).reduce((pqls, generatedPqls, pqlType) => {
          // @ts-expect-error  Argument of type 'unknown' is not assignable to parameter of type 'Object'
          return pqls.setIn([pqlType], ImmutableMap(generatedPqls));
        }, ImmutableMap({}));
      }
    case SAVE_PQL:
      return state.setIn([action.payload.pqlType, action.payload.sendCreatedTimestamp], action.payload.pqlKey);
    case FILTER_OLD_PQLS:
      return state.reduce((pqls, generatedPqls, pqlType) => {
        const nowMoment = I18n.moment();
        const filteredPqls = generatedPqls.filter(generatedTime => {
          const daysSincePql = nowMoment.diff(parseInt(generatedTime, 10), 'days');
          return daysSincePql < MAX_PQL_AGE_IN_DAYS;
        });
        return pqls.setIn([pqlType], filteredPqls);
      }, ImmutableMap({}));
    default:
      return state;
  }
}