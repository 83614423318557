import { OrderedMap } from 'immutable';
// @ts-expect-error: no types
import PropertyGroupRecord from 'customer-data-objects/property/PropertyGroupRecord';
import PropertyRecord from 'customer-data-objects/property/PropertyRecord';
import I18n from 'I18n';

/**
 * Look for custom translation in @propertyOverride.
 * Defaults to @property.label or @property.name if it can't find a custom label.
 * @param {Object} property
 * @param {Object} propertyOverride
 */
function getPropertyLabel(property, propertyOverrideMap) {
  const {
    label,
    name
  } = property;
  const defaultValue = label || name;
  if (!propertyOverrideMap) {
    return defaultValue;
  }
  if (!(name in propertyOverrideMap)) {
    return defaultValue;
  }
  return propertyOverrideMap[name].label || defaultValue;
}

/**
 * Override "hidden" attribute in properties.
 */
function getPropertyHidden(property, propertyOverrideMap) {
  const {
    hidden,
    name
  } = property;
  const defaultValue = hidden;
  if (!propertyOverrideMap) {
    return defaultValue;
  }
  if (!(name in propertyOverrideMap)) {
    return defaultValue;
  }
  if (typeof propertyOverrideMap[name].hidden === 'boolean') {
    return Boolean(propertyOverrideMap[name].hidden);
  }
  return defaultValue;
}
function getPropertyDescription(property, propertyOverrideMap) {
  const {
    description,
    name
  } = property;
  const defaultValue = description;
  if (propertyOverrideMap && name in propertyOverrideMap && typeof propertyOverrideMap[name].description === 'string') {
    return propertyOverrideMap[name].description || '';
  }
  return defaultValue;
}

/**
 * Override "readOnlyValue" attribute in properties.
 */
function getPropertyReadOnlyValue(property, propertyOverrideMap) {
  const {
    readOnlyValue,
    name
  } = property;
  const defaultValue = readOnlyValue;
  if (propertyOverrideMap && name in propertyOverrideMap && typeof propertyOverrideMap[name].readOnlyValue === 'boolean') {
    return Boolean(propertyOverrideMap[name].readOnlyValue);
  }
  return defaultValue;
}

/**
 * Get propertyOverride map variable from @propertyOverride.
 * @param propertyOverride
 */
export function getPropertyOverrideMap(propertyOverride) {
  const propertyOverrideMap = {};
  if (!propertyOverride) {
    return propertyOverrideMap;
  }
  if (propertyOverride.propertyMap) {
    return propertyOverride.propertyMap;
  } else if (!propertyOverride.transform && !propertyOverride.propertyMap) {
    return propertyOverride;
  }
  return propertyOverrideMap;
}

/**
 * Get transform function from @propertyOverride.
 * @param propertyOverride
 */
export function getPropertyOverrideTransform(propertyOverride) {
  const transformPropertyFn = property => property;
  if (!propertyOverride) {
    return transformPropertyFn;
  }
  if (propertyOverride.transform && typeof propertyOverride.transform === 'function') {
    return propertyOverride.transform;
  }
  return transformPropertyFn;
}

/**
 * Creates a new property override by applying the additional property override
 * on top of the base property override.
 * @param propertyOverrideBase
 * @param propertyOverrideAdditional
 */
export function mergePropertyOverrides(propertyOverrideBase, propertyOverrideAdditional) {
  const mapBase = getPropertyOverrideMap(propertyOverrideBase);
  const mapAdditional = getPropertyOverrideMap(propertyOverrideAdditional);
  const mergedMap = Object.assign({}, mapBase);
  Object.entries(mapAdditional).forEach(([key, value]) => {
    if (mergedMap[key]) {
      mergedMap[key] = Object.assign({}, mergedMap[key], value);
    } else {
      mergedMap[key] = value;
    }
  });
  const transformBase = getPropertyOverrideTransform(propertyOverrideBase);
  const transformAdditional = getPropertyOverrideTransform(propertyOverrideAdditional);
  return {
    propertyMap: mergedMap,
    transform: property => transformAdditional(transformBase(property))
  };
}

/**
 * Loop @propertiesResponse and override existing properties in @propertyOverride.
 *
 * Useful when:
 *   - teams need to update a property label
 *   - teams need to update a property visibility
 *   - apply a custom criteria to all properties
 * @param propertiesResponse
 * @param propertyOverride
 */
export function mergeResponseWithPropertyOverride(propertiesResponse, propertyOverride) {
  const propertyOverrideMap = getPropertyOverrideMap(propertyOverride);
  const propertyOverrideTransform = getPropertyOverrideTransform(propertyOverride);
  return Object.fromEntries(propertiesResponse.map(property => Object.assign({}, property, {
    label: getPropertyLabel(property, propertyOverrideMap),
    hidden: getPropertyHidden(property, propertyOverrideMap),
    description: getPropertyDescription(property, propertyOverrideMap),
    readOnlyValue: getPropertyReadOnlyValue(property, propertyOverrideMap)
  }))
  /**
   * Ignore hidden properties and don't add them to the final map response
   */.map(property => propertyOverrideTransform(property)).filter(({
    hidden
  }) => !hidden).map(property => [property.name, property]));
}

/**
 * Add properties coming from @propertyOverrideMap that don't initially exist in the object type.
 *
 * Useful when:
 *   - adding properties from object association
 *   - adding the "comment" column
 *   - adding custom filter properties in the filter editor
 */
export function addNewPropertiesFromOverride(propertiesMap, propertiesResponse, propertyOverride) {
  const propertyOverrideMap = getPropertyOverrideMap(propertyOverride);
  /**
   * PropertyRecord is necessary so we avoid propagating Partial property definitions
   * when processing values that have not been defined outside of listing-lib.
   * https://private.hubteam.com/critsit/3738
   */
  const defaultPropertyDefinition = PropertyRecord.fromJS({}).toJS();
  const overridenEntries = Object.fromEntries(Object.keys(propertyOverrideMap).filter(propertyKey => !propertiesMap[propertyKey])
  /**
   * Ignore properties defined in PUMA.
   * This method is meant to be used by a subset of properties that don't exist in PUMA.
   */.filter(propertyKey => !propertiesResponse.some(p => p.name === propertyKey))
  /**
   * Ignore hidden properties
   */.filter(propertyKey => !propertyOverrideMap[propertyKey].hidden).map(propertyKey => [propertyKey, Object.assign({}, defaultPropertyDefinition, propertyOverrideMap[propertyKey], {
    name: propertyKey
  })]));
  return Object.assign({}, propertiesMap, overridenEntries);
}

/**
 * Parses the property group into an OrderedMap for XOFilterEditor
 */
export function parsePropertyGroupsToFilterGroups(propertyGroups) {
  return OrderedMap(Object.values(propertyGroups).reduce((acc, propertyGroup) => {
    acc[propertyGroup.name] = PropertyGroupRecord.fromJS(propertyGroup);
    return acc;
  }, {}));
}

/**
 * Merges most used properties as a property group
 */
export function mergeMostUsedProperties(propertyGroups, properties, mostUsedProperties) {
  const propertyGroupName = 'mostusedproperties';

  /** We don't display most used property group if there's only a small
   * amount of properties to choose from
   */

  if (Object.keys(properties).length < 10) {
    return propertyGroups;
  }
  const sortedMostUsedProperties = mostUsedProperties ? [...mostUsedProperties].sort((a, b) => {
    if (a.usageCount === b.usageCount) {
      return b.lastUsedTimestamp - a.lastUsedTimestamp;
    } else {
      return b.usageCount - a.usageCount;
    }
  }).slice(0, 5) : [];
  const mostUsedPropertyGroup = {
    displayName: I18n.text('frameworkListingLib.localPropertyGroups.mostusedproperties'),
    displayOrder: 0,
    hubspotDefined: false,
    name: propertyGroupName,
    properties: sortedMostUsedProperties.map(({
      key
    }) => properties[key]).filter(Boolean)
  };
  let displayOrder = 1;
  const sortedPropertyGroups = Object.values(propertyGroups).sort((pgA, pgB) => pgA.displayOrder - pgB.displayOrder).map(propertyGroup => Object.assign({}, propertyGroup, {
    displayOrder: displayOrder++
  })).reduce((acc, propertyGroup) => {
    acc[propertyGroup.name] = propertyGroup;
    return acc;
  }, {});
  return Object.assign({
    [propertyGroupName]: mostUsedPropertyGroup
  }, sortedPropertyGroups);
}
export function hasMultiplePropertyGroups(propertyGroups) {
  const propertyGroupsArray = Object.values(propertyGroups);
  if (propertyGroupsArray.length === 1) {
    return false;
  }
  return propertyGroupsArray.filter(propertyGroup => propertyGroup.properties ? propertyGroup.properties.some(property => property.hidden !== true) : false).length > 1;
}

/**
 * Creates a new property override for quick filter props since some properties could be set to hidden = true
 * by the time we render the filter editor.
 * This method ensures we keep those properties visible by merging updates with any existing propertyOverride.
 */
export function createPropertyOverrideForQuickFilterProps({
  propertyOverride,
  quickFilterPropertyNames
}) {
  if (!propertyOverride) {
    return quickFilterPropertyNames.reduce((acc, propertyName) => {
      acc[propertyName] = {
        hidden: false
      };
      return acc;
    }, {});
  }
  const propertyOverrideMap = Object.assign({}, getPropertyOverrideMap(propertyOverride));
  return quickFilterPropertyNames.reduce((acc, propertyName) => {
    acc[propertyName] = Object.assign({}, acc[propertyName], {
      hidden: false
    });
    return acc;
  }, propertyOverrideMap);
}