import http from 'hub-http/clients/apiClient';
export function fetchUsers({
  canViewAllTeams,
  canViewOwnedTeams,
  userIdsFromUsersTeams
}) {
  if (canViewAllTeams) {
    return http.get(`app-users/v1/users`);
  }
  if (canViewOwnedTeams) {
    return http.put(`users/v2/app/hub-users/by-ids`, {
      data: userIdsFromUsersTeams
    });
  }
  return Promise.resolve([]);
}