import { Map as ImmutableMap } from 'immutable';
import { TICKET_TO_CONTACT, TICKET_TO_COMPANY, TICKET_TO_FEEDBACK_SUBMISSION, CONTACT_TO_TICKET, COMPANY_TO_TICKET, ADVISOR_TO_COMPANY, COMPANY_TO_ADVISOR, BOARD_MEMBER_TO_COMPANY, COMPANY_TO_BOARD_MEMBER, CONTRACTOR_TO_COMPANY, COMPANY_TO_CONTRACTOR, MANAGER_TO_COMPANY, COMPANY_TO_MANAGER, OWNER_TO_COMPANY, COMPANY_TO_OWNER, PARTNER_TO_COMPANY, COMPANY_TO_PARTNER, RESELLER_TO_COMPANY, COMPANY_TO_RESELLER, TICKET_TO_DEAL, DEAL_TO_TICKET, CONTACT_TO_DEAL, COMPANY_TO_DEAL, DEAL_TO_CONTACT, DEAL_TO_COMPANY, CONTACT_TO_COMPANY, COMPANY_TO_CONTACT, ENGAGEMENT_TO_COMPANY, ENGAGEMENT_TO_CONTACT, ENGAGEMENT_TO_DEAL, ENGAGEMENT_TO_TICKET, CONTACT_TO_ENGAGEMENT, CONTACT_TO_FEEDBACK_SUBMISSION, CONVERSATION_TO_DEAL, CONVERSATION_TO_TICKET, DEAL_TO_LINE_ITEM, DEAL_TO_QUOTE, FEEDBACK_SUBMISSION_TO_CONTACT, FEEDBACK_SUBMISSION_TO_TICKET, CONTACT_TO_QUOTE, QUOTE_TO_CONTACT, QUOTE_TO_COMPANY, COMPANY_TO_QUOTE, TASK_TO_CONTACT, TASK_TO_COMPANY, TASK_TO_DEAL, TASK_TO_TICKET, TASK_TO_QUOTE, TASK_TO_MARKETING_EVENT } from 'customer-data-objects/associations/AssociationTypes';
export default ImmutableMap({
  [CONTACT_TO_COMPANY]: 1,
  [COMPANY_TO_CONTACT]: 2,
  [DEAL_TO_CONTACT]: 3,
  [CONTACT_TO_DEAL]: 4,
  [DEAL_TO_COMPANY]: 5,
  [COMPANY_TO_DEAL]: 6,
  [ENGAGEMENT_TO_COMPANY]: 8,
  [CONTACT_TO_ENGAGEMENT]: 9,
  [ENGAGEMENT_TO_CONTACT]: 10,
  [ENGAGEMENT_TO_DEAL]: 12,
  [CONTACT_TO_TICKET]: 15,
  [TICKET_TO_CONTACT]: 16,
  [ENGAGEMENT_TO_TICKET]: 18,
  [DEAL_TO_LINE_ITEM]: 19,
  [COMPANY_TO_TICKET]: 25,
  [TICKET_TO_COMPANY]: 26,
  [DEAL_TO_TICKET]: 27,
  [TICKET_TO_DEAL]: 28,
  [CONVERSATION_TO_TICKET]: 31,
  [ADVISOR_TO_COMPANY]: 33,
  [COMPANY_TO_ADVISOR]: 34,
  [BOARD_MEMBER_TO_COMPANY]: 35,
  [COMPANY_TO_BOARD_MEMBER]: 36,
  [CONTRACTOR_TO_COMPANY]: 37,
  [COMPANY_TO_CONTRACTOR]: 38,
  [MANAGER_TO_COMPANY]: 39,
  [COMPANY_TO_MANAGER]: 40,
  [OWNER_TO_COMPANY]: 41,
  [COMPANY_TO_OWNER]: 42,
  [PARTNER_TO_COMPANY]: 43,
  [COMPANY_TO_PARTNER]: 44,
  [RESELLER_TO_COMPANY]: 45,
  [COMPANY_TO_RESELLER]: 46,
  [CONVERSATION_TO_DEAL]: 57,
  [DEAL_TO_QUOTE]: 63,
  [QUOTE_TO_CONTACT]: 69,
  [CONTACT_TO_QUOTE]: 70,
  [QUOTE_TO_COMPANY]: 71,
  [COMPANY_TO_QUOTE]: 72,
  [CONTACT_TO_FEEDBACK_SUBMISSION]: 97,
  [FEEDBACK_SUBMISSION_TO_CONTACT]: 98,
  [TICKET_TO_FEEDBACK_SUBMISSION]: 99,
  [FEEDBACK_SUBMISSION_TO_TICKET]: 100,
  [TASK_TO_CONTACT]: 204,
  [TASK_TO_COMPANY]: 192,
  [TASK_TO_DEAL]: 216,
  [TASK_TO_TICKET]: 230,
  [TASK_TO_QUOTE]: 218,
  [TASK_TO_MARKETING_EVENT]: 262
});