'use es6';

import { createSelector } from 'reselect';
const defaultGetPortalSendCreditsData = state => {
  return typeof state.get === 'function' ? state.get('portalSendCredits').getData() : state.portalSendCredits.getData();
};
const getPortalSendCreditsState = state => {
  return typeof state.get === 'function' ? state.get('portalSendCredits') : state.portalSendCredits;
};

// isEmailStarter must be a selector returning a boolean
export const createSendCreditsSelector = (getPortalSendCreditsData = defaultGetPortalSendCreditsData) => {
  const getEmailCreditUsed = createSelector([getPortalSendCreditsData], portalSendCredits => {
    return portalSendCredits.getCreditUsed();
  });
  const getEmailCreditLimit = createSelector([getPortalSendCreditsData], portalSendCredits => portalSendCredits.getPortalLimit());
  const getEmailCreditWarningThreshold = createSelector([getPortalSendCreditsData], portalSendCredits => portalSendCredits.getWarningThreshold());
  const getIsHardLimited = createSelector([getPortalSendCreditsData], portalSendCredits => portalSendCredits.getIsHardLimited());
  const getHasHardSendLimits = createSelector([getIsHardLimited, getEmailCreditLimit], (isHardLimited, portalLimit) => isHardLimited && portalLimit > 0);
  const getHasPortalReachedLimit = createSelector([getEmailCreditUsed, getEmailCreditLimit, getHasHardSendLimits], (creditUsed, creditLimit, hasHardLimits) => {
    if (hasHardLimits) {
      return creditUsed >= creditLimit;
    }
    return false;
  });
  const getHasPortalReachedThreshold = createSelector([getEmailCreditUsed, getEmailCreditWarningThreshold, getHasHardSendLimits], (creditUsed, threshold, hasHardLimits) => {
    if (hasHardLimits) {
      return creditUsed >= threshold;
    }
    return false;
  });
  return {
    getPortalSendCredits: getPortalSendCreditsState,
    getEmailCreditUsed,
    getEmailCreditLimit,
    getIsHardLimited,
    getHasHardSendLimits,
    getEmailCreditWarningThreshold,
    getHasPortalReachedLimit,
    getHasPortalReachedThreshold
  };
};
const getPortalSendCreditsSelector = state => state.get('portalSendCredits').getData();
const portalSendCreditsSelectors = createSendCreditsSelector(getPortalSendCreditsSelector);
export const getPortalSendCredits = portalSendCreditsSelectors.getPortalSendCredits;
export const getEmailCreditUsed = portalSendCreditsSelectors.getEmailCreditUsed;
export const getEmailCreditLimit = portalSendCreditsSelectors.getEmailCreditLimit;
export const getIsHardLimited = portalSendCreditsSelectors.getIsHardLimited;
export const getHasHardSendLimits = portalSendCreditsSelectors.getHasHardSendLimits;
export const getEmailCreditWarningThreshold = portalSendCreditsSelectors.getEmailCreditWarningThreshold;
export const getHasPortalReachedLimit = portalSendCreditsSelectors.getHasPortalReachedLimit;
export const getHasPortalReachedThreshold = portalSendCreditsSelectors.getHasPortalReachedThreshold;