// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'Emai... Remove this comment to see the full error message
import Email from 'EmailData/models/Email';
export function getPathFromFolder(folder, id) {
  let res = '';
  let tailRes = '';
  const folders = folder.childFolders;
  if (folder.id === id) {
    return String(id);
  }
  folders.forEach(f => {
    if (res.length === 0) {
      tailRes = getPathFromFolder(f, id);
      if (tailRes.length > 0) {
        res = `${folder.id}/${tailRes}`;
      }
    }
  });
  return res;
}
const folderComparer = (folderA, folderB, order = 'ASC') => {
  if (folderA.name === folderB.name) {
    return 0;
  } else if (folderA.name > folderB.name) {
    return order === 'ASC' ? 1 : -1;
  }
  return order === 'ASC' ? -1 : 1;
};
const sortFolders = (rowData, sortColumn, sortDir) => {
  const sortDirection = sortColumn === 'name' ? sortDir : 'DESC';
  let folderRows = rowData.filter(d => !(d instanceof Email));
  if (folderRows.size === 0) {
    return rowData;
  }
  folderRows = folderRows.sort((folderA, folderB) => folderComparer(folderA, folderB, sortDirection));
  const emailRows = rowData.filter(d => d instanceof Email);
  return folderRows.concat(emailRows);
};
export const filterFolders = (emailOrFolders, folderSearchString, sortColumn, sortDir) => {
  return sortFolders(emailOrFolders.filter(emailOrFolder => {
    // Ignore emails as they are filtered on the backend
    if (emailOrFolder instanceof Email) {
      return true;
    }
    return emailOrFolder && emailOrFolder.name.toLowerCase().includes(folderSearchString.toLowerCase());
  }), sortColumn, sortDir);
};