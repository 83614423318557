import { useEffect, useRef, useState } from 'react';
import { useIsMounted } from '../../v2/hooks/useIsMounted';
export const useAsyncFunction = (fn, options = {
  skip: false
}) => {
  const [state, setState] = useState({
    loading: !options.skip,
    data: undefined,
    error: undefined
  });
  const isMounted = useIsMounted();
  const lastCallId = useRef(0);
  useEffect(() => {
    if (options.skip) {
      setState({
        loading: false,
        data: undefined,
        error: undefined
      });
      return;
    }
    setState({
      loading: true,
      data: undefined,
      error: undefined
    });
    const callId = ++lastCallId.current;
    fn().then(data => {
      if (isMounted.current && callId === lastCallId.current) {
        setState({
          loading: false,
          data,
          error: undefined
        });
      }
    }).catch(error => {
      if (isMounted.current && callId === lastCallId.current) {
        setState({
          loading: false,
          data: undefined,
          error
        });
      }
    });
  }, [fn, isMounted, options.skip]);
  return state;
};