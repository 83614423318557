import { List } from 'immutable';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { RECEIVE_HISTORICAL_IMPORTS, REQUEST_HISTORICAL_IMPORTS } from 'email-management-lib/refactor/actions/actionTypes';
const initialState = List();
function historicalImports(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_HISTORICAL_IMPORTS:
      if (action.error !== true) {
        return List(action.payload);
      }
      return state;
    default:
      return state;
  }
}
export default asyncResource(historicalImports, {
  requestActions: [REQUEST_HISTORICAL_IMPORTS],
  responseActions: [RECEIVE_HISTORICAL_IMPORTS],
  invalidatingActions: []
});