import States from 'ContentUtils/constants/States';
import EmailTypes from '../enums/EmailType';
export const BATCH_LIKE = Object.freeze([EmailTypes.BATCH_EMAIL, EmailTypes.LOCALTIME_EMAIL, EmailTypes.BLOG_EMAIL_CHILD, EmailTypes.RSS_EMAIL_CHILD]);
export const AUTOMATED_LIKE = Object.freeze([EmailTypes.AUTOMATED_EMAIL, EmailTypes.AUTOMATED_AB_EMAIL, EmailTypes.FEEDBACK_CES_EMAIL, EmailTypes.FEEDBACK_NPS_EMAIL, EmailTypes.FEEDBACK_CUSTOM_EMAIL, EmailTypes.FEEDBACK_CUSTOM_SURVEY_EMAIL, EmailTypes.SMTP_TOKEN, EmailTypes.LEADFLOW_EMAIL, EmailTypes.OPTIN_EMAIL, EmailTypes.SINGLE_SEND_API, EmailTypes.MARKETING_SINGLE_SEND_API, EmailTypes.OPTIN_FOLLOWUP_EMAIL, EmailTypes.FOLLOWUP_EMAIL, EmailTypes.RESUBSCRIBE_EMAIL, EmailTypes.TICKET_EMAIL, EmailTypes.MEMBERSHIP_REGISTRATION_EMAIL, EmailTypes.MEMBERSHIP_PASSWORD_SAVED_EMAIL, EmailTypes.MEMBERSHIP_PASSWORD_RESET_EMAIL, EmailTypes.MEMBERSHIP_FOLLOW_UP_EMAIL, EmailTypes.MEMBERSHIP_PASSWORDLESS_AUTH_EMAIL, EmailTypes.MEMBERSHIP_VERIFICATION_EMAIL]);
export const CONTENT_STATE_TO_EMAIL_TYPE = Object.freeze({
  [States.AUTOMATED]: EmailTypes.AUTOMATED_EMAIL,
  [States.AUTOMATED_SENDING]: EmailTypes.AUTOMATED_EMAIL,
  [States.AUTOMATED_DRAFT]: EmailTypes.AUTOMATED_EMAIL,
  [States.AUTOMATED_FOR_FORM]: EmailTypes.FOLLOWUP_EMAIL,
  [States.AUTOMATED_FOR_FORM_DRAFT]: EmailTypes.FOLLOWUP_EMAIL,
  [States.AUTOMATED_FOR_FORM_BUFFER]: EmailTypes.FOLLOWUP_EMAIL,
  [States.BLOG_EMAIL_DRAFT]: EmailTypes.BLOG_EMAIL,
  [States.BLOG_EMAIL_PUBLISHED]: EmailTypes.BLOG_EMAIL,
  [States.DRAFT]: EmailTypes.BATCH_EMAIL,
  [States.DRAFT_AB]: EmailTypes.AB_EMAIL,
  [States.DRAFT_AB_VARIANT]: EmailTypes.AB_EMAIL,
  [States.ERROR]: EmailTypes.BATCH_EMAIL,
  [States.LOSER_AB_VARIANT]: EmailTypes.AB_EMAIL,
  [States.PRE_PROCESSING]: EmailTypes.BATCH_EMAIL,
  [States.PROCESSING]: EmailTypes.BATCH_EMAIL,
  [States.PUBLISHED]: EmailTypes.BATCH_EMAIL,
  [States.PUBLISHED_AB]: EmailTypes.AB_EMAIL,
  [States.PUBLISHED_AB_VARIANT]: EmailTypes.AB_EMAIL,
  [States.SCHEDULED_AB]: EmailTypes.AB_EMAIL,
  [States.PUBLISHED_OR_SCHEDULED]: EmailTypes.BATCH_EMAIL,
  [States.RSS_TO_EMAIL_DRAFT]: EmailTypes.RSS_EMAIL,
  [States.RSS_TO_EMAIL_PUBLISHED]: EmailTypes.RSS_EMAIL,
  [States.SCHEDULED]: EmailTypes.BATCH_EMAIL
});
export const EMAIL_TYPE_SCOPES = Object.freeze({
  [EmailTypes.BATCH_EMAIL]: 'email-type-batch-access',
  [EmailTypes.AB_EMAIL]: 'email-type-ab-access',
  [EmailTypes.AUTOMATED_AB_EMAIL]: 'email-type-ab-access',
  [EmailTypes.AUTOMATED_EMAIL]: 'email-type-workflows-access',
  [EmailTypes.BLOG_EMAIL]: 'email-type-blog-access',
  [EmailTypes.BLOG_EMAIL_CHILD]: 'email-type-blog-access',
  [EmailTypes.FOLLOWUP_EMAIL]: 'email-type-batch-access',
  [EmailTypes.LOCALTIME_EMAIL]: 'email-type-localtime-access',
  [EmailTypes.OPTIN_EMAIL]: 'email-type-batch-access',
  [EmailTypes.OPTIN_FOLLOWUP_EMAIL]: 'email-type-batch-access',
  [EmailTypes.RESUBSCRIBE_EMAIL]: ['email-type-batch-access', 'site-settings-write'],
  [EmailTypes.RSS_EMAIL]: 'email-type-rss-access',
  [EmailTypes.RSS_EMAIL_CHILD]: 'email-type-rss-access',
  [EmailTypes.SINGLE_SEND_API]: 'transactional-email-api',
  [EmailTypes.MARKETING_SINGLE_SEND_API]: 'marketing-email-api',
  [EmailTypes.LEADFLOW_EMAIL]: 'email-type-batch-access',
  [EmailTypes.FEEDBACK_NPS_EMAIL]: 'email-type-batch-access',
  [EmailTypes.FEEDBACK_CES_EMAIL]: 'email-type-batch-access',
  [EmailTypes.FEEDBACK_CUSTOM_EMAIL]: 'email-type-batch-access',
  [EmailTypes.FEEDBACK_CUSTOM_SURVEY_EMAIL]: 'email-type-batch-access',
  [EmailTypes.TICKET_EMAIL]: 'email-type-batch-access',
  [EmailTypes.SMTP_TOKEN]: 'email-type-batch-access',
  [EmailTypes.MEMBERSHIP_REGISTRATION_EMAIL]: 'email-type-batch-access',
  [EmailTypes.MEMBERSHIP_PASSWORD_SAVED_EMAIL]: 'email-type-batch-access',
  [EmailTypes.MEMBERSHIP_PASSWORD_RESET_EMAIL]: 'email-type-batch-access',
  [EmailTypes.MEMBERSHIP_FOLLOW_UP_EMAIL]: 'email-type-batch-access',
  [EmailTypes.MEMBERSHIP_PASSWORDLESS_AUTH_EMAIL]: 'email-type-batch-access',
  [EmailTypes.MEMBERSHIP_VERIFICATION_EMAIL]: 'email-type-batch-access'
});
export const getHasAccessToEmailType = (scopes, emailType) => {
  const requiredScopes = EMAIL_TYPE_SCOPES[emailType];
  if (Array.isArray(requiredScopes)) {
    return requiredScopes.every(s => scopes.includes(s));
  }
  return scopes.includes(requiredScopes);
};