import { buildKbURL } from 'EmailData/utils/url';
export const TLDR_PQLS = {
  AB_TESTING: 'AB_TESTING',
  AUTOMATED_SENDS: 'AUTOMATED_SENDS',
  TIMEZONE_SENDS: 'TIMEZONE_SENDS',
  SMART_CONTENT: 'SMART_CONTENT',
  CLIENT_TESTING: 'CLIENT_TESTING'
};
export const MAX_PQL_AGE_IN_DAYS = 100;

// Show a max of 4 smart PQLs in 7 days, and each PQL type a max of once every 3 days.
export const SMART_PQL_ROLLING_PERIOD_IN_DAYS = 7;
export const MAX_SMART_PQL_PER_PERIOD = 4;
export const MIN_DAYS_BETWEEN_PQL_TYPE = 3;
export const MIN_BATCH_SENDS_PER_MONTH_FOR_AUTOMATED_PQL = 23;
export const MIN_LISTS_FOR_SMART_CONTENT_PQL = 3;
export const MIN_CLIENTS_FOR_CLIENT_TESTING_PQL = 18;
export const PQL_ARTICLES = {
  [TLDR_PQLS.AB_TESTING]: buildKbURL('articles/kcs_article/email/create-marketing-emails-in-the-drag-and-drop-email-editor#optional-a-b-test-your-email-marketing-hub-professional-and-enterprise-only'),
  [TLDR_PQLS.AUTOMATED_SENDS]: buildKbURL('articles/kcs_article/email/create-automated-emails-to-use-in-workflows'),
  [TLDR_PQLS.TIMEZONE_SENDS]: buildKbURL('articles/kcs_article/email/how-to-send-an-email-based-on-your-contacts-time-zone'),
  [TLDR_PQLS.SMART_CONTENT]: buildKbURL('articles/kcs_article/cos-general/add-smart-content-to-your-website-pages-landing-pages-and-emails'),
  [TLDR_PQLS.CLIENT_TESTING]: buildKbURL('articles/kcs_article/email/create-and-send-marketing-emails#test-in-email-clients')
};