export const SAMPLING_STATES = {
  ERROR_WILL_RETRY: 'ERROR_WILL_RETRY',
  WAITING: 'WAITING',
  WAITING_ON_STATIC_LIST: 'WAITING_ON_STATIC_LIST',
  INITIALIZING: 'INITIALIZING',
  COLLECTING_CONTACTS_FOR_VARIANTS: 'COLLECTING_CONTACTS_FOR_VARIANTS',
  WRITING_FULL_SPLIT_SUB: 'WRITING_FULL_SPLIT_SUB',
  WRITING_FULL_SPLIT_UNSUB: 'WRITING_FULL_SPLIT_UNSUB',
  SENDING_FULL_SPLIT: 'SENDING_FULL_SPLIT',
  SAMPLING_VARIANTS: 'SAMPLING_VARIANTS',
  SENDING_VARIANTS: 'SENDING_VARIANTS'
};
export const WAITING_FOR_WINNER_STATES = {
  WAITING_FOR_METRICS: 'WAITING_FOR_METRICS',
  WAITING_ON_STATIC_LIST_FOR_WINNER: 'WAITING_ON_STATIC_LIST_FOR_WINNER',
  INITIALIZING_WINNER: 'INITIALIZING_WINNER',
  COLLECTING_CONTACTS_FOR_WINNER: 'COLLECTING_CONTACTS_FOR_WINNER',
  MERGING_CONTACTS: 'MERGING_CONTACTS'
};
const ERROR_STATES = {
  ERROR_WILL_NOT_RETRY: 'ERROR_WILL_NOT_RETRY',
  ERROR_UNAUTHORIZED_SEND: 'ERROR_UNAUTHORIZED_SEND'
};
export const STOP_STATES = Object.assign({}, ERROR_STATES, {
  DONE: 'DONE',
  CANCELED: 'CANCELED',
  CANCELED_WINNER: 'CANCELED_WINNER'
});
export const CANCEL_STATES = {
  WAITING: 'WAITING',
  ERROR_WILL_RETRY: 'ERROR_WILL_RETRY'
};
const CANCELED_STATES = {
  CANCELED: 'CANCELED'
};
export const WAITING_STATES = {
  WAITING: 'WAITING'
};
export const DRAFT_STATES = {
  UNDEFINED: 'UNDEFINED'
};
const SUCCESSFUL_AB_STATES = {
  [STOP_STATES.DONE]: STOP_STATES.DONE
};
export const ALL_STATES = Object.assign({}, SAMPLING_STATES, WAITING_FOR_WINNER_STATES, STOP_STATES, CANCEL_STATES, CANCELED_STATES, WAITING_STATES, DRAFT_STATES);
export const FORCE_CANCEL_STATES = {
  [ALL_STATES.WAITING_ON_STATIC_LIST]: ALL_STATES.WAITING_ON_STATIC_LIST,
  [ALL_STATES.INITIALIZING]: ALL_STATES.INITIALIZING,
  [ALL_STATES.COLLECTING_CONTACTS_FOR_VARIANTS]: ALL_STATES.COLLECTING_CONTACTS_FOR_VARIANTS,
  [ALL_STATES.WRITING_FULL_SPLIT_SUB]: ALL_STATES.WRITING_FULL_SPLIT_SUB,
  [ALL_STATES.WRITING_FULL_SPLIT_UNSUB]: ALL_STATES.WRITING_FULL_SPLIT_UNSUB,
  [ALL_STATES.SENDING_FULL_SPLIT]: ALL_STATES.SENDING_FULL_SPLIT,
  [ALL_STATES.SAMPLING_VARIANTS]: ALL_STATES.SAMPLING_VARIANTS,
  [ALL_STATES.SENDING_VARIANTS]: ALL_STATES.SENDING_VARIANTS,
  [ALL_STATES.WAITING_FOR_METRICS]: ALL_STATES.WAITING_FOR_METRICS,
  [ALL_STATES.WAITING_ON_STATIC_LIST_FOR_WINNER]: ALL_STATES.WAITING_ON_STATIC_LIST_FOR_WINNER,
  [ALL_STATES.INITIALIZING_WINNER]: ALL_STATES.INITIALIZING_WINNER,
  [ALL_STATES.COLLECTING_CONTACTS_FOR_WINNER]: ALL_STATES.COLLECTING_CONTACTS_FOR_WINNER,
  [ALL_STATES.MERGING_CONTACTS]: ALL_STATES.MERGING_CONTACTS,
  [ALL_STATES.UNDEFINED]: ALL_STATES.UNDEFINED
};
function testStateInStates(state, states) {
  return Boolean(states[state]);
}
export function isSampling(state) {
  return testStateInStates(state, SAMPLING_STATES);
}
export function isDone(state) {
  return testStateInStates(state, STOP_STATES);
}
export function isFinishedSuccessfully(state) {
  return testStateInStates(state, SUCCESSFUL_AB_STATES);
}
export function isError(state) {
  return testStateInStates(state, ERROR_STATES);
}
export function isWaiting(state) {
  return testStateInStates(state, WAITING_STATES);
}
export function isWaitingForWinner(state) {
  return testStateInStates(state, WAITING_FOR_WINNER_STATES);
}
export function isDraft(state) {
  return testStateInStates(state, DRAFT_STATES);
}
export function isProcessing(state) {
  return !(isDone(state) || isWaiting(state) || isError(state));
}
export function isCanceled(state) {
  return testStateInStates(state, CANCELED_STATES);
}
export function isWinnerCanceled(state) {
  return state === STOP_STATES.CANCELED_WINNER;
}
export function canCancel(state) {
  return testStateInStates(state, CANCEL_STATES);
}
export function canForceCancel(state) {
  return (isSampling(state) || isProcessing(state)) && !canCancel(state);
}