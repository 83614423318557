export let FilterOptionTypes;
(function (FilterOptionTypes) {
  FilterOptionTypes["CUSTOM"] = "CUSTOM";
  FilterOptionTypes["USER"] = "USER";
  FilterOptionTypes["TEAM"] = "TEAM";
})(FilterOptionTypes || (FilterOptionTypes = {}));
export let BaseFilterLevels;
(function (BaseFilterLevels) {
  BaseFilterLevels["ALL"] = "ALL";
  BaseFilterLevels["ASSIGNED_TO_USER"] = "ASSIGNED_TO_USER";
  BaseFilterLevels["ASSIGNED_TO_USERS_TEAM"] = "ASSIGNED_TO_USERS_TEAM";
  BaseFilterLevels["UNASSIGNED"] = "UNASSIGNED";
})(BaseFilterLevels || (BaseFilterLevels = {}));