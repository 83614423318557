import { RESET_ALL_RECIPIENT_COUNTS, FETCH_GRAYMAIL_SUCCESS, FETCH_FATIGUE_SUCCESS, FETCH_ALL_RECIPIENT_COUNTS_START, FETCH_ALL_RECIPIENT_COUNTS_SUCCESS } from 'EmailData/actions/actionTypes';
import RecipientsData from 'EmailData/models/RecipientsData';
const recipientsReducer = (state = new RecipientsData(), action) => {
  switch (action.type) {
    case FETCH_ALL_RECIPIENT_COUNTS_START:
      return state.merge(action.newSelection);
    case FETCH_ALL_RECIPIENT_COUNTS_SUCCESS:
      return state.merge(action.counts);
    case RESET_ALL_RECIPIENT_COUNTS:
      return new RecipientsData();
    case FETCH_GRAYMAIL_SUCCESS:
      return state.merge({
        graymailEnabled: action.payload
      });
    case FETCH_FATIGUE_SUCCESS:
      return state.merge({
        portalFatigueSuppression: action.payload
      });
    default:
      return state;
  }
};
export default recipientsReducer;