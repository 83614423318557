import { List } from 'immutable';
import { addCondition, isLogicGroup, makeLogicGroup } from './LogicGroup';
import And from './And';
const Or = makeLogicGroup('Or', List());
Or.insertAtPosition = function insertAtPosition(index, condition, group, filterFamily = group.get('filterFamily')) {
  // @ts-expect-error conditions is a List of unknown
  return group.update('conditions', conditions => {
    let logicGroup = isLogicGroup(condition) ? condition : And.of(condition).set('filterFamily', filterFamily);
    if (group.filterFamily !== filterFamily) {
      logicGroup = And.of(logicGroup).set('filterFamily', group.filterFamily);
    }
    return conditions.insert(index, logicGroup);
  });
};
addCondition.implement(Or,
// @ts-expect-error this implementation can only be called with OrInstance
(condition, group, filterFamily) => {
  return Or.insertAtPosition(group.conditions.size, condition, group, filterFamily);
});
export default Or;