'use es6';

import { Map as ImmutableMap, Record } from 'immutable';
const defaults = {
  unbounceLimit: 0,
  unbounceEventId: ImmutableMap({
    created: 0,
    id: ''
  }),
  numUnbounceRequests: 0,
  result: '',
  bounceEventId: ImmutableMap({
    created: 0,
    id: ''
  })
};
export const UNBOUNCE_STATUS_STATES = {
  SUCCESS: 'SUCCESS',
  ALLOWED: 'ALLOWED',
  IS_GLOBALLY_BOUNCED: 'IS_GLOBALLY_BOUNCED',
  BOUNCED_AFTER_UNBOUNCE: 'BOUNCED_AFTER_UNBOUNCE',
  SELF_SERVE_LIMIT_EXCEEDED: 'SELF_SERVE_LIMIT_EXCEEDED',
  IS_NOT_BOUNCED: 'IS_NOT_BOUNCED',
  IS_GLOBALLY_BLOCKED: 'IS_GLOBALLY_BLOCKED',
  ERROR: 'ERROR'
};
const VALID_SUCCESS_STATES = {
  [UNBOUNCE_STATUS_STATES.SUCCESS]: UNBOUNCE_STATUS_STATES.SUCCESS,
  [UNBOUNCE_STATUS_STATES.IS_NOT_BOUNCED]: UNBOUNCE_STATUS_STATES.IS_NOT_BOUNCED
};
export class RecipientUnbounceStatus extends Record(defaults) {
  static from(json) {
    return new RecipientUnbounceStatus(json);
  }
  isValid() {
    const currentResult = UNBOUNCE_STATUS_STATES[this.result];
    return Boolean(currentResult);
  }
  isGloballyBounced() {
    return this.result === UNBOUNCE_STATUS_STATES.IS_GLOBALLY_BOUNCED;
  }
  canUnbounceRecipient() {
    return this.result === UNBOUNCE_STATUS_STATES.ALLOWED;
  }
  alreadyUnbounced() {
    const isInSuccessState = VALID_SUCCESS_STATES[this.result];
    return Boolean(isInSuccessState);
  }
  bounceEventsMatch(event) {
    if (!this.bounceEventId) {
      return false;
    }
    const idsMatch = event.get('id') === this.bounceEventId.get('id');
    const createdsMatch = event.get('created') === this.bounceEventId.get('created');
    return idsMatch && createdsMatch;
  }
}