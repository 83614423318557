import { SETTINGS } from '../constants/namespaces';
import enviro from 'enviro';
import { createTracker } from 'usage-tracker';
import events from 'customer-data-tracking/tracking/settings/events.yaml';
import once from '../utils/once';
import UsageTracker from '../trackers/UsageTracker';
const trackerArgs = {
  properties: {
    namespace: SETTINGS
  },
  onError(err) {
    console.error(err);
  },
  lastKnownEventProperties: ['screen', 'subscreen'],
  debug: () => enviro.debug('customer-data-tracker')
};
export const getTracker = once(() => createTracker(Object.assign({}, trackerArgs, {
  events
})));
export const getTrackerSendImmediate = once(() => createTracker(Object.assign({
  bypassPool: true,
  events
}, trackerArgs)));
export const SettingsLogger = {
  init: data => {
    getTracker().setProperties(data);
    getTrackerSendImmediate().setProperties(data);
  },
  logImmediate: (eventName, eventProps = {}, trackerOptions = {}) => {
    SettingsLogger.log(eventName, eventProps, Object.assign({}, trackerOptions, {
      sendImmediate: true
    }));
  },
  log: (eventName, eventProps = {}, trackerOptions = {
    sendImmediate: false
  }) => {
    SettingsLogger._log(eventName, eventProps, trackerOptions);
  },
  _log(eventName = '', eventProps = {}, trackerOptions = {
    sendImmediate: false
  }) {
    UsageTracker.track(eventName, eventProps, trackerOptions, getTracker(), getTrackerSendImmediate());
  }
};
export default SettingsLogger;