module.exports = {
  "createTaskFromUI": {
    "name": "Create Task From UI",
    "class": "interaction",
    "properties": {
      "datePreset": {
        "type": [
          "today",
          "tomorrow",
          "oneBusinessDay",
          "twoBusinessDays",
          "threeBusinessDays",
          "oneWeek",
          "twoWeeks",
          "oneMonth",
          "threeMonths",
          "sixMonths",
          "CUSTOM"
        ],
        "isOptional": true
      },
      "reminderPreset": {
        "type": [
          "sameTime",
          "halfHourBefore",
          "hourBefore",
          "dayBefore",
          "weekBefore",
          "CUSTOM"
        ],
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": [
          "CONTACT",
          "COMPANY",
          "DEAL",
          "TICKET",
          "CUSTOM"
        ],
        "isOptional": true
      }
    },
    "namespace": "tasks"
  },
  "taskQueueBarInteraction": {
    "name": "Task Queue Bar Interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "backToTasks",
          "completed",
          "rescheduled",
          "skipped",
          "skipToRemainingTask",
          "viewRemainingTasks"
        ],
        "isOptional": false
      },
      "category": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": [
          "private",
          "shared",
          "view"
        ],
        "isOptional": true
      }
    },
    "namespace": "tasks"
  },
  "unassociatedTaskModalInteraction": {
    "name": "Unassociated Task Modal Interaction",
    "class": "interaction",
    "properties": {
      "screen": "string",
      "action": {
        "type": [
          "viewed",
          "associationsAdded",
          "dismissed",
          "markedCompleted",
          "skipped"
        ]
      },
      "associationCount": {
        "type": "number",
        "isOptional": true
      },
      "category": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "tasks"
  }
};