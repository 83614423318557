import { PAGE_DEFAULT_LIMIT, SORT_DIR, CRM_SEARCH_SORT, CRM_SEARCH_CAMPAIGN_PROPERTIES } from '../constants/campaignDaoConstants';
export const getPropertyValue = property => {
  return property ? property.value : null;
};
export const getPropertyValueAsInt = property => {
  const propertyValue = getPropertyValue(property);
  return propertyValue ? parseInt(propertyValue, 10) : propertyValue;
};
export const getPropertyValueAsFloat = property => {
  const propertyValue = getPropertyValue(property);
  return propertyValue ? parseFloat(propertyValue) : propertyValue;
};
export const getPropertyValueAsBoolean = property => {
  const propertyValue = getPropertyValue(property);
  return propertyValue === 'true';
};
export const getCrmSearchCampaignOptions = ({
  offset = 0,
  limit = PAGE_DEFAULT_LIMIT,
  guidValue,
  nameSearch,
  sortProperty = CRM_SEARCH_SORT.DISPLAY_NAME,
  sortDir = SORT_DIR.ASC,
  properties = CRM_SEARCH_CAMPAIGN_PROPERTIES,
  customFilters = [],
  requestOptions = {}
} = {}) => {
  let guidFilter = null;
  if (Array.isArray(guidValue) && guidValue.length) {
    guidFilter = {
      property: 'hs_origin_asset_id',
      values: guidValue,
      operator: 'IN'
    };
  } else if (guidValue) {
    guidFilter = {
      property: 'hs_origin_asset_id',
      value: guidValue,
      operator: 'EQ'
    };
  }
  return {
    count: limit,
    offset,
    objectTypeId: '0-35',
    requestOptions: Object.assign({
      properties
    }, requestOptions),
    filterGroups: [{
      filters: [...[guidFilter].filter(Boolean), ...customFilters]
    }],
    sorts: [{
      property: sortProperty,
      order: sortDir
    }],
    query: nameSearch || null
  };
};
export const mapCrmSearchCampaignsToLegacy = campaigns => {
  const results = campaigns.results.map(({
    objectId,
    properties
  }) => ({
    budgetTotalAmount: getPropertyValueAsFloat(properties.hs_budget_items_sum_amount),
    createdAt: getPropertyValueAsInt(properties.hs_created_at),
    createdBy: getPropertyValueAsInt(properties.hs_created_by_user_id),
    display_name: getPropertyValue(properties.hs_name),
    guid: getPropertyValue(properties.hs_origin_asset_id),
    colorHex: getPropertyValue(properties.hs_color_hex),
    spendTotalAmount: getPropertyValueAsFloat(properties.hs_spend_items_sum_amount),
    utm: getPropertyValue(properties.hs_utm),
    objectId: typeof objectId === 'string' ? parseInt(objectId, 10) : objectId
  }));
  const legacyCampaigns = Object.assign({}, campaigns, {
    results
  });
  return legacyCampaigns;
};