'use es6';

import combineImmutableReducers from 'EmailData/reducers/helpers/combineImmutableReducers';
import dismissedOnboardingTourEntry from './dismissedOnboardingTourEntry';
import dismissedOnboardingTourTime from './dismissedOnboardingTourTime';
import dismissedOutageCritsitBanner from './dismissedOutageCritsitBanner';
import dismissedToolsSidebarCarousel from './dismissedToolsSidebarCarousel';
import previousDashboardUrlState from './previousDashboardUrlState';
import sendingIps from './deliverability/sendingIps';
import unbounceEmails from './deliverability/unbounceEmails';
import emailsMutated from './emailsMutated';
import sendingLimitCredits from 'EmailData/reducers/sendingLimitCredits';
import sendingLimitEmails from 'EmailData/reducers/sendingLimitEmails';
import table from './table';
import frameworkTable from './frameworkTable';
export default combineImmutableReducers({
  table,
  unbounceEmails,
  sendingIps,
  emailsMutated,
  frameworkTable,
  previousDashboardUrlState,
  dismissedOutageCritsitBanner,
  dismissedToolsSidebarCarousel,
  dismissedOnboardingTourEntry,
  dismissedOnboardingTourTime,
  sendLimitEmails: sendingLimitEmails,
  sendLimitCredits: sendingLimitCredits
});