const cacheQueryPolicies = {
  /**
   * redirects queries to existing objects in cache to prevent fetching
   * something that we already have
   *
   * example: if we already have a folder with the same ID we're calling
   * getListFolder with, we should get that folder from the cache instead of
   * fetching
   *
   * apollo docs:
   * https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects-using-field-policy-read-functions
   */
  Query: {
    fields: {
      getListFolder(_, {
        args,
        toReference
      }) {
        const {
          folderId
        } = args;
        return toReference({
          __typename: 'Folder',
          id: folderId
        });
      },
      listDefinition(_, {
        args,
        toReference
      }) {
        const {
          listId
        } = args;
        return toReference({
          __typename: 'ListDefinition',
          id: listId
        });
      },
      objectTypeDefinition(_, {
        args,
        toReference
      }) {
        const {
          objectType
        } = args;
        return toReference({
          __typename: 'ObjectTypeDefinition',
          id: objectType
        });
      }
    }
  }
};
export default cacheQueryPolicies;