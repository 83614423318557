import { LEAD_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { useHttpClient } from '../../client/HttpClientContext';
import { useEffect, useState } from 'react';
import { cache } from '../../input/leads/leadSettingsCache';
import Raven from 'raven-js';
export const useIsReadOnlyLeadOwnerProperty = (objectTypeId, propertyDefinition) => {
  const httpClient = useHttpClient();
  const shouldCheck = objectTypeId === LEAD_TYPE_ID && propertyDefinition.hubspotDefined && propertyDefinition.name === 'hubspot_owner_id';
  const [isReadOnly, setIsReadOnly] = useState(true);
  useEffect(() => {
    if (!shouldCheck) {
      return;
    }
    cache.get('leadSettings', httpClient).then(data => {
      setIsReadOnly(data.mirrorBackingObjectOwner === true);
    }).catch(err => {
      // We want to fail 'open' here, so we don't block the user from editing the property
      setIsReadOnly(false);
      Raven.captureException(new Error('Fetching lead settings failed open'), {
        extra: {
          originalError: err
        }
      });
    });
  }, [httpClient, shouldCheck]);
  return shouldCheck ? isReadOnly : false;
};