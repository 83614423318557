import { buildKbURL } from 'EmailData/utils/url';
export const ONBOARDING_STEPS = {
  SEND_FIRST_EMAIL: 'SEND_FIRST_EMAIL',
  IMPORT_OPT_OUT_LIST: 'IMPORT_OPT_OUT_LIST',
  IMPORT_CONTACTS_AND_CREATE_LISTS: 'IMPORT_CONTACTS_AND_CREATE_LISTS',
  MAILCHIMP_IMPORT: 'MAILCHIMP_IMPORT'
};
export const ONBOARDING_TYPES = {
  NEW: 'NEW',
  MAILCHIMP: 'MAILCHIMP',
  OTHER: 'OTHER'
};
export const MAILCHIMP_IMPORT_STATUS = {
  NONE: 'NONE',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED'
};
export const MAX_EMAILS_BEFORE_AUTO_DISMISS = 10;
export const HUB_SETTING_ONBOARDING_DISMISS_KEY = 'MarketingEmailDashboardOnboarding:Dismissed';
export const HUB_SETTING_ONBOARDING_OPTOUT_DISMISSED = 'MarketingEmailDashboardOnboarding:OptOutImport:Dismissed';
export const HUB_SETTING_ONBOARDING_CONTACTS_DISMISSED = 'MarketingEmailDashboardOnboarding:ContactsImport:Dismissed';
export const HUB_SETTING_ONBOARDING_FIRST_SEND_DISMISSED = 'MarketingEmailDashboardOnboarding:FirstSend:Dismissed';
export const HUB_SETTING_ONBOARDING_MAILCHIMP_DISMISSED = 'MarketingEmailDashboardOnboarding:MailchimpImport:Dismissed';
export const HUB_SETTING_UPDATED_ONBOARDING_TYPE = 'MarketingEmailDashboardOnboarding:OnboardingType';
export const ONBOARDING_STEP_TO_DISMISS_KEY = {
  [ONBOARDING_STEPS.SEND_FIRST_EMAIL]: HUB_SETTING_ONBOARDING_FIRST_SEND_DISMISSED,
  [ONBOARDING_STEPS.IMPORT_CONTACTS_AND_CREATE_LISTS]: HUB_SETTING_ONBOARDING_CONTACTS_DISMISSED,
  [ONBOARDING_STEPS.MAILCHIMP_IMPORT]: HUB_SETTING_ONBOARDING_MAILCHIMP_DISMISSED,
  [ONBOARDING_STEPS.IMPORT_OPT_OUT_LIST]: HUB_SETTING_ONBOARDING_OPTOUT_DISMISSED
};
export const DISMISS_KEY_TO_ONBOARDING_STEP = {
  [HUB_SETTING_ONBOARDING_FIRST_SEND_DISMISSED]: ONBOARDING_STEPS.SEND_FIRST_EMAIL,
  [HUB_SETTING_ONBOARDING_CONTACTS_DISMISSED]: ONBOARDING_STEPS.IMPORT_CONTACTS_AND_CREATE_LISTS,
  [HUB_SETTING_ONBOARDING_MAILCHIMP_DISMISSED]: ONBOARDING_STEPS.MAILCHIMP_IMPORT,
  [HUB_SETTING_ONBOARDING_OPTOUT_DISMISSED]: ONBOARDING_STEPS.IMPORT_OPT_OUT_LIST
};
export const KNOWLEDGE_BASE_MIGRATION_DOC = buildKbURL('articles/kcs_article/email/email-migration-guide');
export const ONBOARDING_FEEDBACK_ID = 64;