// Mirrors `ValidationResult` in `contacts.proto`
// https://git.hubteam.com/HubSpot/IdentityBase/blob/9ccf1f2c348eb8c9c3382732621034f90f8b1aac/src/main/protobuf/contacts.proto#L1665
export const NO_ERROR = 'NO_ERROR';
export const MAX_CHARACTERS = 'MAX_CHARACTERS';
export const PROPERTY_DOESNT_EXIST = 'PROPERTY_DOESNT_EXIST';
export const INVALID_OPTION = 'INVALID_OPTION';
export const INVALID_INTEGER = 'INVALID_INTEGER';
export const LONG_UNDER_0 = 'LONG_UNDER_0';
export const INVALID_LONG = 'INVALID_LONG';
export const EMAIL_NOT_IN_PROPERTIES = 'EMAIL_NOT_IN_PROPERTIES';
export const CONTACT_EXISTS = 'CONTACT_EXISTS';
export const READ_ONLY_VALUE = 'READ_ONLY_VALUE';
export const INVALID_DATE = 'INVALID_DATE';
export const INVALID_OWNER_ID = 'INVALID_OWNER_ID';
export const DUPLICATE_PROPERTY_TO_SAVE = 'DUPLICATE_PROPERTY_TO_SAVE';
export const INVALID_EMAIL = 'INVALID_EMAIL';
export const INVALID_DOMAIN = 'INVALID_DOMAIN';
export const INVALID_COMPANY_ID = 'INVALID_COMPANY_ID';
export const INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER';
export const INVALID_RECURRING_BILLING_DURATION = 'INVALID_RECURRING_BILLING_DURATION';
export const INVALID_DISCOUNT_PERCENTAGE = 'INVALID_DISCOUNT_PERCENTAGE';
export const INVALID_DISCOUNT = 'INVALID_DISCOUNT';
export const INVALID_PRICE = 'INVALID_PRICE';
export const MISSING_REQUIRED_PROPERTY = 'MISSING_REQUIRED_PROPERTY';
export const CONFLICTING_UNIQUE_VALUE = 'CONFLICTING_UNIQUE_VALUE';
export const MAX_UNIQUE_VALUES = 'MAX_UNIQUE_VALUES';
export const INVALID_FOLDER_ID = 'INVALID_FOLDER_ID';
export const EMAIL_GDPR_DELETED = 'EMAIL_GDPR_DELETED';
export const CONTAINS_HTML = 'CONTAINS_HTML';
export const CONTAINS_URL = 'CONTAINS_URL';
export const INVALID_JSON = 'INVALID_JSON';
export const INVALID_OBJECT_COORDINATES = 'INVALID_OBJECT_COORDINATES';
export const OBJECT_TYPE_DOESNT_EXIST = 'OBJECT_TYPE_DOESNT_EXIST';
export const FAILS_CUSTOM_VALIDATION = 'FAILS_CUSTOM_VALIDATION';
export const INVALID_FORMAT_CAPITALIZATION = 'INVALID_FORMAT_CAPITALIZATION';
export const INVALID_FORMAT_UPPER = 'INVALID_FORMAT_UPPER';
export const INVALID_FORMAT_LOWER = 'INVALID_FORMAT_LOWER';
export const INVALID_FORMAT_EMAIL = 'INVALID_FORMAT_EMAIL';
export const INVALID_ALPHANUMERIC = 'INVALID_ALPHANUMERIC';
export const INVALID_ALPHA = 'INVALID_ALPHA';
export const INVALID_NUMERIC = 'INVALID_NUMERIC';
export const MAX_LENGTH = 'MAX_LENGTH';
export const MIN_LENGTH = 'MIN_LENGTH';
export const MIN_NUMBER = 'MIN_NUMBER';
export const MAX_NUMBER = 'MAX_NUMBER';
export const INVALID_START_DATE = 'INVALID_START_DATE';
export const INVALID_END_DATE = 'INVALID_END_DATE';
export const INVALID_START_DATETIME = 'INVALID_START_DATETIME';
export const INVALID_END_DATETIME = 'INVALID_END_DATETIME';
export const CONTAINS_INVALID_CHARACTERS = 'CONTAINS_INVALID_CHARACTERS';
export const INVALID_DECIMAL_PRECISION = 'INVALID_DECIMAL_PRECISION';
export const INVALID_BEFORE_DURATION = 'INVALID_BEFORE_DURATION';
export const INVALID_AFTER_DURATION = 'INVALID_AFTER_DURATION';
export const INVALID_BEFORE_DATETIME_DURATION = 'INVALID_BEFORE_DATETIME_DURATION';
export const INVALID_AFTER_DATETIME_DURATION = 'INVALID_AFTER_DATETIME_DURATION';
export const INVALID_DAYS_OF_WEEK = 'INVALID_DAYS_OF_WEEK';
export const INVALID_ANGLE_BRACKETS = 'INVALID_ANGLE_BRACKETS';