import { ApolloClient } from '@apollo/client';
import { createStack } from 'hub-http';
import { createCache } from './createCache';
import { getApiLink } from './hubHttpLink';
import hubapiStack from 'hub-http/stacks/hubapi';
import { defaultTo } from 'hub-http/middlewares/core';
export const createApolloClient = function createApolloClient({
  connectToDevTools,
  useBatched = false,
  httpStack = createStack(defaultTo('timeout', 14000), hubapiStack)
} = {}) {
  return new ApolloClient({
    link: getApiLink({
      hubHttpLinkOptions: {
        uri: 'graphql/crm'
      },
      useBatched,
      httpStack
    }),
    cache: createCache(),
    connectToDevTools,
    // enabling this improves performance but adds the assumption that app code won't
    // manually mutate the cache, which is generally not a problem
    assumeImmutableResults: true
  });
};