import { useCallback } from 'react';
import { CREATE_INPUT_MODE } from '../../../v2/types/PropertyInputV2Component';
import { StageValidationCache } from '../utils/pipelineStageValidationCache';
import unescapedText from 'I18n/utils/unescapedText';
import { isTransitionValid, userCanBypassValidation } from '../utils/pipelineValidationUtils';
const stageReferenceWithValidation = (reference, validation) => {
  const isValid = isTransitionValid(reference.id, validation);
  const userCanBypassInvalidTransition = userCanBypassValidation(reference.id, validation);
  if (isValid) {
    return reference;
  }
  if (userCanBypassInvalidTransition) {
    return Object.assign({}, reference, {
      description: unescapedText('governance.pipelineStages.bypassed')
    });
  }
  return Object.assign({}, reference, {
    disabled: true,
    description: unescapedText('governance.pipelineStages.disabled')
  });
};
export const useGetLoadStageReferences = ({
  httpClient,
  objectTypeId,
  pipelineId,
  inputMode,
  objectId
}) => {
  const loadStageReferences = useCallback(async (resolver, searchQuery) => {
    // We don't want to refetch the validation in certain situations. We can opt-in to reading from the cache first.
    // 1. If the user has typed something in the search select box
    // 2. If the input is in creation mode. The set of enabled/disabled stages will never change even on save so we don't need to refetch.
    const shouldReadFromCache = !!searchQuery.query || inputMode === CREATE_INPUT_MODE;
    const searchResponsePromise = resolver.api.search(searchQuery);
    const defaultGetParams = {
      objectTypeId,
      inputMode,
      objectId
    };

    // In situations where we don't have a pipelineId (such the custom object case),
    // we can sequentially fetch the pipelines search response and then validate that batch of stages.
    // This prevents us having to perform validation for all pipelines and all stages upfront.
    if (!pipelineId) {
      const searchResponse = await searchResponsePromise;
      const optionStageIds = searchResponse.references.map(reference => reference.id);
      const stageValidationPromise = StageValidationCache.get(Object.assign({}, defaultGetParams, {
        stageIds: optionStageIds
      }), httpClient, !shouldReadFromCache);
      const stageValidationResponse = await stageValidationPromise;
      return Object.assign({}, searchResponse, {
        references: searchResponse.references.map(reference => stageReferenceWithValidation(reference, stageValidationResponse))
      });
    }
    const stageValidationPromise = StageValidationCache.get(Object.assign({}, defaultGetParams, {
      pipelineId
    }), httpClient, !shouldReadFromCache);
    return Promise.all([searchResponsePromise, stageValidationPromise]).then(([searchResponse, stageValidation]) => {
      return Object.assign({}, searchResponse, {
        references: searchResponse.references.map(reference => stageReferenceWithValidation(reference, stageValidation))
      });
    });
  }, [pipelineId, httpClient, objectId, objectTypeId, inputMode]);
  return loadStageReferences;
};