import { createTracker } from 'usage-tracker';
import CrmLogger from 'customer-data-tracking/loggers/CrmLogger';
import events from '../../events.yaml';
// add additional page names as needed
const IndexPageName = 'INDEX_PAGE';
export const clickedCloneView = 'clicked clone view from ';
export const clickedPreviewView = 'clicked preview view';
export const clickedRenameView = 'clicked rename view from ';
const USER_CLICK = 'click';
const ON_LOAD = 'onLoad';
export const viewsManagementTracker = createTracker({
  events,
  onError: err => {
    console.error(err.message || err);
  },
  debug: true
});
export const track = (eventName, properties) => CrmLogger.log(eventName, properties);
export const trackRenameSavedView = () => track('editSavedView', {
  action: 'rename saved view'
});
export const trackUpdateManageSharing = () => track('editSavedView', {
  action: 'manage sharing updated for saved view'
});
export const trackDeleteView = () => track('filterUsage', {
  action: 'delete saved view'
});
export const trackCreateView = ({
  isClone
}) => track('createSavedView', {
  action: isClone ? 'saved filter as new custom view' : 'created view from dropdown menu',
  fromClone: String(isClone)
});
export const trackOpenSavedView = openSavedViewProperties => CrmLogger.logImmediate('openSavedView', Object.assign({}, openSavedViewProperties));
export const trackOpenAddViewPopover = () => track('filterInteractions', {
  action: 'open add view popover'
});
export const trackOpenViewFromAddViewPopover = openViewFromAddViewPopoverProperties => track('indexInteractions', openViewFromAddViewPopoverProperties);
export const trackViewsSearch = viewName => {
  track('filterInteractions', {
    action: 'use view search',
    property: viewName
  });
};
export const trackCloneView = ({
  dropdownLocation
}) => {
  track('filterUsage', {
    action: clickedCloneView + dropdownLocation
  });
};
export const trackCreateReportClick = () => {
  track('filterUsage', {
    action: `create report link clicked`
  });
};
export const trackPreviewClick = () => {
  track('filterUsage', {
    action: clickedPreviewView
  });
};
export const trackRenameClick = ({
  dropdownLocation
}) => {
  track('filterUsage', {
    action: clickedRenameView + dropdownLocation
  });
};
export const trackActionsDropdownCLick = ({
  dropdownLocation
}) => {
  track('filterUsage', {
    action: `clicked ${dropdownLocation}`
  });
};

// On load trackers

export const trackQuickfilters = ({
  objectTypeIds = [],
  currentOwnerIds = []
}) => {
  viewsManagementTracker.track('viewsTableUsage', {
    action: ON_LOAD,
    name: 'quickFilters',
    objectTypeIds,
    currentOwnerIds
  });
};
export const trackPageSize = ({
  pageSize
}) => {
  viewsManagementTracker.track('viewsTableUsage', {
    action: ON_LOAD,
    name: 'page size',
    value: pageSize
  });
};

// Click trackers

export const trackCloseQuickfilter = filterType => {
  viewsManagementTracker.track('viewsTableInteractions', {
    action: USER_CLICK,
    name: 'close quickfilter',
    value: filterType
  });
};
export const trackSortColumn = ({
  sortKey
}) => {
  viewsManagementTracker.track('viewsTableInteractions', {
    action: USER_CLICK,
    name: 'sort columns by column header',
    value: sortKey
  });
};
export const trackUpdatePageSize = ({
  pageSize
}) => {
  viewsManagementTracker.track('viewsTableInteractions', {
    action: USER_CLICK,
    name: 'page size',
    value: pageSize
  });
};
export const trackClearAll = () => {
  viewsManagementTracker.track('viewsTableInteractions', {
    action: USER_CLICK,
    name: 'clear all button'
  });
};
export const trackPreviewPanelFiltersTabClick = () => {
  viewsManagementTracker.track('viewsTableInteractions', {
    action: USER_CLICK,
    name: 'preview panel filters tab'
  });
};
export const trackLinkClick = (name, value) => {
  viewsManagementTracker.track('viewsTableInteractions', {
    action: USER_CLICK,
    name: `link to: ${name}`,
    value
  });
};
export const trackBackButtonClick = value => trackLinkClick(IndexPageName, value);