import PropertyRecord from 'customer-data-objects/property/PropertyRecord';
// We use some heuristics to make type guards for the different "PropertyLike" types here.
// The goal is to identify the different types so we can handle them accordingly below.
const isPropertyRecord = definition => definition instanceof PropertyRecord;
const isGqlDefinition = definition => '__typename' in definition;

/**
 * Converts from the union type of PropertyLike to Property. We use this to
 * allow multiple data shapes in our public API but keep everything internal using
 * a single type. In the future, it would be ideal to move to the client types
 * generated type here, but there are a lot of places that expect the CDO type.
 *
 * @param definition - The property definition to normalize
 * @returns A property definition normalized to the CDO Property type
 */
export const normalizePropertyDefinition = definition => {
  if (isPropertyRecord(definition)) {
    return definition.toJS();
  }
  if (isGqlDefinition(definition)) {
    var _definition$deleted, _definition$hubspotDe;
    return Object.assign({}, definition, {
      // Update key names
      isCustomizedDefault: definition.customizedDefault,
      isMultiValued: definition.multiValued,
      // Correct the GQL null type to undefined or a fallback.
      deleted: (_definition$deleted = definition.deleted) !== null && _definition$deleted !== void 0 ? _definition$deleted : false,
      hubspotDefined: (_definition$hubspotDe = definition.hubspotDefined) !== null && _definition$hubspotDe !== void 0 ? _definition$hubspotDe : undefined,
      options: definition.options.map(option => {
        var _option$hidden, _option$readOnly;
        return Object.assign({}, option, {
          hidden: (_option$hidden = option.hidden) !== null && _option$hidden !== void 0 ? _option$hidden : false,
          readOnly: (_option$readOnly = option.readOnly) !== null && _option$readOnly !== void 0 ? _option$readOnly : undefined
        });
      }),
      // Cast wide types where GQL types are not as specific
      dateDisplayHint: definition.dateDisplayHint,
      displayMode: definition.displayMode,
      fieldType: definition.fieldType,
      numberDisplayHint: definition.numberDisplayHint,
      sensitiveDataCategories: definition.sensitiveDataCategories,
      type: definition.type
    });
  }

  // CDO and client types definition should be compatible. There are a few cases where the
  // client type has wider types for string enums. Also, the client type has some required
  // fields where CDO is optional.
  return definition;
};