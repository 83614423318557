import { parse, stringify } from 'hub-http/helpers/params';
import { getFullUrl } from 'hubspot-url-utils';
import PortalIdParser from 'PortalIdParser';
import memoize from 'transmute/memoize';
const getSearchParamsString = (params = {}) => {
  const paramsString = stringify(params);
  if (paramsString) {
    return `?${paramsString}`;
  }
  return '';
};
export function addSlash(uri) {
  return uri.charAt(0) === '/' ? '' : '/';
}
export function buildKbURL(url) {
  return url.includes('knowledge.hubspot.com') ? url : `https://knowledge.hubspot.com${addSlash(url)}${url}`;
}
export const getRootUrl = () => `/email/${PortalIdParser.get()}`;
export function parseQueryParams(searchParams) {
  return parse(searchParams.replace(/^(\?)/, ''));
}
export const getObjectFromLocationSearch = memoize(params => {
  if (params && params.length && params[0] === '?') {
    params = params.substring(1);
  }
  return parse(params);
});
export const getForceOnboardingUrlParam = () => !!getObjectFromLocationSearch(window.location.search).forceOnboarding;
export const getHealthTabUrl = (params = {}) => {
  return `${getFullUrl('app')}${getRootUrl()}/health${getSearchParamsString(params)}`;
};
export const getAnalyzeTabUrl = (params = {}) => {
  return `${getFullUrl('app')}${getRootUrl()}/analyze${getSearchParamsString(params)}`;
};
export const getListUrl = listId => `${getFullUrl('app')}/contacts/${PortalIdParser.get()}/lists/${listId}`;