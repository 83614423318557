// @ts-expect-error not migrated to TS yet

import ContentRoutes from 'ContentUtils/Routes';
import { getIsDragDropEmail, getIsLeadflowEmail } from './emailStateUtil';
export const getEmailEditPath = email => {
  if (getIsLeadflowEmail(email)) {
    return ContentRoutes.formFollowup(email.formId);
  } else if (getIsDragDropEmail(email)) {
    return ContentRoutes.editDragDropEmail(email.id);
  } else {
    return ContentRoutes.edit(email.id, email.categoryId);
  }
};
export const getEmailPathById = (emailId, email = null) => {
  if (email !== null && getIsLeadflowEmail(email)) {
    return ContentRoutes.formFollowup(email.formId);
  } else if (email !== null && getIsDragDropEmail(email)) {
    return ContentRoutes.editDragDropEmail(email.id);
  } else {
    return ContentRoutes.edit(email.id, email.categoryId);
  }
};