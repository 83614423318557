/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable promise/catch-or-return */

import { fetchHistoricalImportsRequest, fetchMailchimpImportStatusRequest } from 'email-management-lib/refactor/api/imports';
import { REQUEST_HISTORICAL_IMPORTS, RECEIVE_HISTORICAL_IMPORTS, RECEIVE_MAILCHIMP_IMPORT_STATUS } from 'email-management-lib/refactor/actions/actionTypes';
import { getMailchimpImportStatus } from 'email-management-lib/refactor/selectors/manage/onboarding';
import { MAILCHIMP_IMPORT_STATUS } from 'email-management-lib/refactor/constants/manage/onboarding';
const FIVE_SECONDS = 5000;
export function fetchHistoricalImports() {
  return dispatch => {
    dispatch({
      type: REQUEST_HISTORICAL_IMPORTS
    });
    return fetchHistoricalImportsRequest().then(response => {
      return Promise.resolve(dispatch({
        type: RECEIVE_HISTORICAL_IMPORTS,
        payload: response.results
      }));
    }, err => {
      return Promise.resolve(dispatch({
        type: RECEIVE_HISTORICAL_IMPORTS,
        error: true,
        payload: err
      }));
    });
  };
}
export function fetchMailchimpImportStatus() {
  return dispatch => {
    return fetchMailchimpImportStatusRequest().then(response => {
      return Promise.resolve(dispatch({
        type: RECEIVE_MAILCHIMP_IMPORT_STATUS,
        payload: response.contactImportStatus
      }));
    });
  };
}
export function pollForSuccessfulMailchimpImportStatus() {
  return (dispatch, getState) => {
    const onboardingStatus = getMailchimpImportStatus(getState());
    if (onboardingStatus === MAILCHIMP_IMPORT_STATUS.IN_PROGRESS) {
      setTimeout(() => {
        dispatch(fetchMailchimpImportStatus()).then(() => {
          dispatch(pollForSuccessfulMailchimpImportStatus());
        });
      }, FIVE_SECONDS);
    }
  };
}