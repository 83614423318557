import http from 'hub-http/clients/apiClient';
export const sendSeatRequest = ({
  apiName,
  isLegacySeatsModel,
  requestReason,
  requestedSeatType
}) => {
  return http.post(`monetization/v3/request/seat${!isLegacySeatsModel ? '?sendAdminNotification=true' : ''}`, {
    data: {
      apiName,
      requestReason,
      requestedSeatType
    }
  });
};