import { CALL_TYPE_ID, CART_TYPE_ID, COMMERCE_PAYMENT_TYPE_ID, COMPANY_TYPE_ID, CONTACT_CREATE_ATTRIBUTION_TYPE_ID, CONTACT_TYPE_ID, DEAL_REGISTRATION_ID, DEAL_TYPE_ID, ENGAGEMENT_TYPE_ID, FEEDBACK_SUBMISSION_TYPE_ID, FORM_TYPE_ID, INVOICE_TYPE_ID, MARKETING_EVENT_TYPE_ID, ORDER_TYPE_ID, QUOTE_TYPE_ID, SUBSCRIPTION_TYPE_ID, TICKET_TYPE_ID, LINE_ITEM_TYPE_ID, CONVERSATION_TYPE_ID, CONVERSATION_SESSION_TYPE_ID, COMMUNICATION_TYPE_ID, TASK_TYPE_ID, NOTE_TYPE_ID, MEETING_EVENT_TYPE_ID, EMAIL_TYPE_ID, PAYMENT_LINK_TYPE_ID, PRODUCT_TYPE_ID, LEAD_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import PortalIdParser from 'PortalIdParser';
export const getRecordHref = (objectTypeId, objectId, __hasGate = () => false // not needed right now, function is used by others so don't change api unnecessarily
) => {
  const encodedTypeId = encodeURIComponent(objectTypeId);
  const encodedId = encodeURIComponent(objectId);
  const portalId = PortalIdParser.get();
  const baseUrl = `/contacts/${portalId}`;
  switch (objectTypeId) {
    case CONTACT_TYPE_ID:
      {
        return `${baseUrl}/contact/${encodedId}`;
      }
    case COMPANY_TYPE_ID:
      {
        return `${baseUrl}/company/${encodedId}`;
      }
    case DEAL_TYPE_ID:
      {
        return `${baseUrl}/deal/${encodedId}`;
      }
    case TICKET_TYPE_ID:
      {
        return `${baseUrl}/ticket/${encodedId}`;
      }
    case CALL_TYPE_ID:
      {
        return `/calls/${portalId}/review/${encodedId}`;
      }
    case MARKETING_EVENT_TYPE_ID:
      {
        return `/marketing-events/${portalId}/details/${encodedId}`;
      }
    case LEAD_TYPE_ID:
      {
        return `/prospecting/${portalId}/leads?leadId=${encodedId}`;
      }
    case EMAIL_TYPE_ID:
      {
        return `/go-to/${portalId}/${EMAIL_TYPE_ID}/${encodedId}`;
      }
    case ORDER_TYPE_ID:
    case CART_TYPE_ID:
      return `${baseUrl}/record/${encodedTypeId}/${encodedId}`;

    // Objects of these types do not have a record
    // TODO: This should be refactored to use a shared library if/when one becomes available.
    // See also: https://hubspot.slack.com/archives/C74P0T492/p1645635337455309?thread_ts=1645623994.709899&cid=C74P0T492
    case CONTACT_CREATE_ATTRIBUTION_TYPE_ID:
    case CONVERSATION_TYPE_ID:
    case CONVERSATION_SESSION_TYPE_ID:
    case COMMUNICATION_TYPE_ID:
    case DEAL_REGISTRATION_ID:
    case ENGAGEMENT_TYPE_ID:
    case FEEDBACK_SUBMISSION_TYPE_ID:
    case FORM_TYPE_ID:
    case TASK_TYPE_ID:
    case NOTE_TYPE_ID:
    case MEETING_EVENT_TYPE_ID:
    case INVOICE_TYPE_ID:
    case COMMERCE_PAYMENT_TYPE_ID:
    case SUBSCRIPTION_TYPE_ID:
    case QUOTE_TYPE_ID:
    case LINE_ITEM_TYPE_ID:
    case PAYMENT_LINK_TYPE_ID:
    case PRODUCT_TYPE_ID:
      {
        return undefined;
      }
    default:
      {
        // TODO: - we need to add metrics tracking here - I want to remove this default case for anything besides custom objects but I am not confident it wont break something unintentionally
        return `${baseUrl}/record/${encodedTypeId}/${encodedId}/`;
      }
  }
};