import { RESET_EMAIL_DETAILS } from 'EmailData/actions/actionTypes';
import { CLOSE_PREVIEW, SELECT_EMAIL_ROW } from 'email-management-lib/refactor/actions/actionTypes';
const initialState = -1;
export default function selectedEmail(state = initialState, action) {
  switch (action.type) {
    case SELECT_EMAIL_ROW:
      return action.payload.contentId;
    case CLOSE_PREVIEW:
    case RESET_EMAIL_DETAILS:
      return initialState;
    default:
      return state;
  }
}