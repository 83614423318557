import { fromJS } from 'immutable';
import { FETCH_ACTIVE_LIST_LIMIT_SUCCESS, FETCH_STATIC_LIST_LIMIT_SUCCESS } from 'EmailData/actions/actionTypes';
const initialState = fromJS({
  staticListLimitReached: false,
  activeListLimitReached: false
});
export default function listLimits(state = initialState, action) {
  switch (action.type) {
    case FETCH_STATIC_LIST_LIMIT_SUCCESS:
      return state.set('staticListLimitReached', action.payload);
    case FETCH_ACTIVE_LIST_LIMIT_SUCCESS:
      return state.set('activeListLimitReached', action.payload);
    default:
      return state;
  }
}