import { useMemo } from 'react';
import { getConditionalPropertyDependencies } from './utils';
const useConditionalPropertyDependencies = ({
  loading,
  error,
  objectTypeId,
  conditionalPropertyRules,
  getFormValue,
  properties
}) => {
  return useMemo(() => {
    if (!loading && !error) {
      return getConditionalPropertyDependencies(objectTypeId, getFormValue, properties, conditionalPropertyRules);
    }
    return {};
  }, [conditionalPropertyRules, error, getFormValue, loading, objectTypeId, properties]);
};
export default useConditionalPropertyDependencies;