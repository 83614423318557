import { bulkUpdate } from 'framework-listing-lib/utils/bulkActions';
import { setPropertyValue } from 'framework-listing-lib/utils/crmObject';

/**
 * Add @crmObject to the table cache
 */
export function addCrmObject(crmObject) {
  return cacheRef => Object.assign({}, cacheRef, {
    results: [crmObject, ...cacheRef.results],
    total: cacheRef.total + 1
  });
}

/**
 * Update one or many @crmObjectOrObjects in the table cache.
 * This method replaces the entire CRM object from our cache
 */
export function updateCrmObjects(crmObjectOrObjects) {
  return cacheRef => Object.assign({}, cacheRef, {
    results: bulkUpdate(cacheRef.results, crmObjectOrObjects)
  });
}

/**
 * Updates one or multiple property values to existing CRM objects in our cache.
 * Updates all CRM objects in cache when @selectedIds is an empty array.
 */
export function updatePropertyValues({
  propertyValues,
  selectedIds
}) {
  return cacheRef => Object.assign({}, cacheRef, {
    results: cacheRef.results.map(crmObject => {
      if (selectedIds.length && !selectedIds.includes(crmObject.objectId)) {
        return crmObject;
      }
      let copyCrmObject = Object.assign({}, crmObject);
      for (const propertyValue of propertyValues) {
        copyCrmObject = setPropertyValue(copyCrmObject, propertyValue.name, propertyValue.value);
      }
      return copyCrmObject;
    })
  });
}