import { createSelector } from 'reselect';
import { currentChildIdQuery } from '../filtering';

// @ts-expect-error not migrated to ts
import currentEmail from './currentEmail';
export const isBlogRssEmail = createSelector([currentEmail], email => email.isBlogEmail() || email.isRssEmail());
export const isBlogRssChildEmail = createSelector([currentEmail], email => email.isBlogChildEmail() || email.isRssChildEmail());
export const isBlogRssParentEmail = createSelector([isBlogRssEmail, currentChildIdQuery], (isBlogRss, childId) => isBlogRss && childId === undefined);
export const getHistoryTableState = state => state.getIn(['details', 'blogRssHistoryTable']);
export const getEmailsHistory = createSelector([getHistoryTableState], historyTable => historyTable.get('emails'));
export const hasHistoryForEmail = (state, emailId) => {
  const emailHistory = getEmailsHistory(state);
  return emailHistory.getEmailAttemptData(emailId);
};
export const getTableFilterTypeKey = (startDate, endDate, orderField, orderrDirection, skipped = false) => {
  const skippedKey = skipped ? 'ALL' : 'SENT';
  return `${startDate}::${endDate}::${orderField}::${orderrDirection}::${skippedKey}`;
};