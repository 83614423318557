import { getActiveRules, getParsedPropertyValueAsArray } from '../v2/conditionalPropertyOptionsRules/utils';
import { applyConditionalPropertyOptionsRules } from './utils';
import { useCallback, useMemo, useRef } from 'react';
import { getConditionalPropertyOptionsRulesWithDependentErrors } from '../v2/conditionalPropertyOptionsRules/getConditionalPropertyOptionsRulesWithDependentErrors';
/**
 * This hook uses the Conditional Property Options (CPO) Rules and current property values on the record
 * to determine if any CPO dependents should be shown as dependents in the form.
 * Depending on an input's value in the form,
 *  - finds the related CPO rules
 *  - looks at dependent fields' values and determines if they are still valid
 *  - adds dependents with invalid values (per the CPO rule) as dependents to the input in the form.
 *
 * @returns {activeRules}: an array of currently active CPO rules
 * @returns {formProperties}: a tree of form properties along with invalid CPO dependents
 * @returns {resetConditionalPropertyOptionsRules}: callback to reset this hook on form reset
 *
 */
export const useConditionalPropertyOptionsRules = ({
  conditionalPropertyOptionsRules,
  formProperties,
  getFormValue,
  properties,
  changedProperties,
  dependencies,
  alwaysShowConditionalPropertyOptionsWithErrors
}) => {
  const previousActiveRulesWithErrorsRef = useRef([]);
  const resetConditionalPropertyOptionsRules = useCallback(() => {
    previousActiveRulesWithErrorsRef.current = [];
  }, []);
  return useMemo(() => {
    const activeRulesWithErrors = [];
    const activeRules = [];
    const defaultReturn = {
      activeRules,
      formProperties,
      resetConditionalPropertyOptionsRules
    };

    // if there are no rules, we can skip CPO processing
    if (conditionalPropertyOptionsRules.length === 0) {
      return defaultReturn;
    }
    for (const formProperty of formProperties) {
      const {
        propertyName
      } = formProperty;
      // if the property has no value, no further processing required for it
      const parsedControllingPropertyValueAsArray = getParsedPropertyValueAsArray(properties[propertyName], getFormValue(propertyName));
      if (parsedControllingPropertyValueAsArray.length === 0) {
        continue;
      }

      // if the property does not control any CPO rules, no further processing required for it
      const rulesWithPropertyAsControllingField = conditionalPropertyOptionsRules.filter(rule => rule.controllingField.fieldName === propertyName);
      if (rulesWithPropertyAsControllingField.length === 0) {
        continue;
      }

      // if the property has no active CPO rules for current value, no further processing required for it
      const activeRulesForProperty = getActiveRules(parsedControllingPropertyValueAsArray, rulesWithPropertyAsControllingField);
      if (activeRulesForProperty.length === 0) {
        continue;
      }
      activeRules.push(...activeRulesForProperty);
      const dependentFieldValues = activeRulesForProperty.reduce((acc, rule) => {
        acc[rule.dependentFieldName] = getFormValue(rule.dependentFieldName);
        return acc;
      }, {});
      const activeRulesWithErrorsForProperty = getConditionalPropertyOptionsRulesWithDependentErrors(activeRulesForProperty, properties, dependentFieldValues, previousActiveRulesWithErrorsRef.current);
      activeRulesWithErrors.push(...activeRulesWithErrorsForProperty);
    }

    // if the entire form has no properties with dependent errors, we can skip applying CPO rules
    // this is because we don't want to show CPO dependents on the form unless they are in error state
    if (activeRulesWithErrors.length === 0) {
      return defaultReturn;
    }
    previousActiveRulesWithErrorsRef.current = activeRulesWithErrors;
    return {
      activeRules,
      formProperties: applyConditionalPropertyOptionsRules({
        formProperties,
        formDependencies: dependencies,
        changedProperties,
        alwaysShowConditionalPropertyOptionsWithErrors,
        conditionalPropertyOptionsRules: activeRulesWithErrors
      }),
      resetConditionalPropertyOptionsRules
    };
  }, [alwaysShowConditionalPropertyOptionsWithErrors, changedProperties, conditionalPropertyOptionsRules, dependencies, formProperties, getFormValue, properties, resetConditionalPropertyOptionsRules]);
};