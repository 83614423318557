function isReverseGate(gateName) {
  return gateName.startsWith('-');
}

/**
 * Returns true when isUngatedFor returns true for all valid gates within gates.
 *
 * gates can contain reverse gates in the format of "-GATE_NAME" and those should be ignored.
 */
export function getIsUngatedToAll({
  gates,
  isUngatedFor
}) {
  if (!gates || gates.length === 0) {
    return true;
  }
  return gates.filter(gateName => !isReverseGate(gateName)).every(isUngatedFor);
}

/**
 * Returns true when isUngatedFor returns false for all valid reverse gates ("-GATE_NAME") within gates.
 *
 * gates can contain gates in the format of "GATE_NAME" and those should be ignored.
 */
export function getIsGatedToAll({
  gates,
  isUngatedFor
}) {
  if (!gates || gates.length === 0) {
    return true;
  }
  return gates.filter(isReverseGate).map(gateName => gateName.replace('-', '')).every(gateName => !isUngatedFor(gateName));
}

/**
 * Returns true when:
 *  - applicationIds is empty OR
 *  - the current application id is included in the applicationIds array
 */
export function getHasApplicationId({
  applicationIds,
  currentApplicationId
}) {
  if (!applicationIds || applicationIds.length === 0) {
    return true;
  }
  return applicationIds.includes(currentApplicationId);
}