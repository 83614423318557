import { Metrics } from '../metrics/Metrics';
export class InMemoryCache {
  constructor(id, fetch, serializeKey) {
    this.cache = new Map();
    this.cache = new Map();
    this.id = id;
    this.fetch = fetch;
    this.serializeKey = serializeKey;
  }
  get(key, promiseClient, refetch = false) {
    const cacheKey = this.serializeKey(key);
    if (!this.cache.has(cacheKey) || refetch) {
      Metrics.counter('inmemorycache-miss', {
        id: this.id
      }).increment();
      this.cache.set(cacheKey, this.fetch(key, promiseClient));
    } else {
      Metrics.counter('inmemorycache-hit', {
        id: this.id
      }).increment();
    }
    return this.cache.get(cacheKey);
  }
  delete(key) {
    const cacheKey = this.serializeKey(key);
    return this.cache.delete(cacheKey);
  }
  clear() {
    this.cache.clear();
  }
  size() {
    return this.cache.size;
  }
  has(key) {
    return this.cache.has(this.serializeKey(key));
  }
}