import { useMemo } from 'react';
import useCrmObjectTypeListingFilters from './useCrmObjectTypeListingFilters';
export function useFilterGroups() {
  const {
    filters
  } = useCrmObjectTypeListingFilters();
  return useMemo(() => filters.filterGroups || [], [filters.filterGroups]);
}
export function useFilterQuery() {
  const {
    filters
  } = useCrmObjectTypeListingFilters();
  return filters.query;
}
export function useSortState() {
  const {
    filters
  } = useCrmObjectTypeListingFilters();
  return filters.sort;
}
export function useHiddenFilter() {
  const {
    hiddenFilter
  } = useCrmObjectTypeListingFilters();
  return useMemo(() => hiddenFilter || {
    filterGroups: [{
      filters: []
    }]
  }, [hiddenFilter]);
}