'use es6';

import { createSelector } from 'reselect';
import { getEmailStatus } from 'EmailData/selectors/emailStatusSelectors';
import { getGatesArray } from 'EmailData/selectors/gates';
import { getScopes } from 'EmailData/selectors/scopes';
import PortalStatus from 'EmailData/models/PortalStatus';
export const portalStatusSelector = state => {
  if (typeof state.getIn === 'function') {
    return state.getIn(['portalStatus', 'status'], PortalStatus.from({}));
  }
  const {
    portalStatus: {
      status = PortalStatus.from({})
    } = {}
  } = state;
  return status;
};
export const getPortalEmailTier = createSelector([portalStatusSelector], portalStatus => portalStatus.getIn(['precogHealthStatus', 'emailProductTier']));
export const getIsSuspended = createSelector([portalStatusSelector], portalStatus => portalStatus.isPortalSuspended());
export const getIsPrecogSuspended = createSelector([portalStatusSelector], portalStatus => portalStatus.getHealthStatus().isSuspended());
export const getSuspensionReason = createSelector([portalStatusSelector], portalStatus => portalStatus.getHealthStatus().getReason());
const emailStatusSelectors = getEmailStatus(getGatesArray, getScopes, portalStatusSelector);
export const portalHealthStatusSelector = emailStatusSelectors.getHealthStatus;
export const getPortalRestrictions = emailStatusSelectors.getPortalRestrictions;
export const showPortalProbationWarningSelector = emailStatusSelectors.shouldShowPortalProbation;
export const showPortalSuspensionSelector = emailStatusSelectors.shouldShowPortalSuspension;
export const isPortalUnhealthySelector = createSelector([showPortalProbationWarningSelector, showPortalSuspensionSelector], (showProbationWarning, showSuspensionWarning) => showProbationWarning || showSuspensionWarning);
export const getShouldShowSuspensionRemediation = createSelector([getIsPrecogSuspended, portalHealthStatusSelector], (isPrecogSuspended, portalHealthStatus) => isPrecogSuspended && portalHealthStatus.isRecoverableSuspension());
export const getIsSuspensionRemediationLocked = emailStatusSelectors.getIsSuspensionRemediationLocked;