export const waitForTargetElement = (attachTo, callback) => {
  const observer = new MutationObserver(() => {
    const targetElement = document.querySelector(attachTo);
    if (targetElement) {
      observer.disconnect();
      callback(targetElement);
    }
  });
  const config = {
    childList: true,
    subtree: true,
    attributes: true
  };
  observer.observe(document, config);
  return () => {
    observer.disconnect();
  };
};
export const watchTargetElementVisibility = (targetElement, callback) => {
  const observer = new IntersectionObserver(([{
    isIntersecting
  }]) => {
    callback(isIntersecting);
  });
  observer.observe(targetElement);
  return () => {
    observer.disconnect();
  };
};