import { useMemo } from 'react';
import useObjectTypeId from 'framework-listing-lib/internal/hooks/useObjectTypeId';
import { maybeMakeDefaultView } from 'framework-listing-lib/internal/ViewTabs/utils/views';
import useFetchViews from './useFetchViews';
export default function useParseViews() {
  const objectType = useObjectTypeId();
  const {
    loading,
    pinnedViews: pinnedViewsFetch,
    views: viewsFetch
  } = useFetchViews();
  const pinnedViews = useMemo(() => {
    if (loading) {
      return [];
    }
    return pinnedViewsFetch.map(view => maybeMakeDefaultView(objectType, view));
  }, [loading, objectType, pinnedViewsFetch]);
  const views = useMemo(() => {
    if (loading) {
      return [];
    }

    /**
     * Replace any invalid view in @views to its counterpart in @legacyDefaultViews
     */
    return viewsFetch.map(view => maybeMakeDefaultView(objectType, view));
  }, [loading, objectType, viewsFetch]);
  const allViews = useMemo(() => {
    if (loading) {
      return [];
    }
    const viewsList = [...pinnedViews, ...views];
    const uniqueViewIds = Array.from(new Set(viewsList.map(view => view.id)));
    return uniqueViewIds.map(viewId => viewsList.find(view => String(view.id) === String(viewId)));
  }, [loading, pinnedViews, views]);
  return useMemo(() => {
    return {
      loading,
      pinnedViews,
      views: allViews
    };
  }, [allViews, loading, pinnedViews]);
}