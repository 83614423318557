import { useMemo } from 'react';
import { addNewPropertiesFromOverride, mergeMostUsedProperties, mergeResponseWithPropertyOverride } from 'framework-listing-lib/internal/CrmObjectTypeProperties/utils/properties';
import useCrmObjectTypeMostUsedProperties from 'framework-listing-lib/internal/hooks/useCrmObjectTypeMostUsedProperties';
import { useHasBUNavIntegration } from 'framework-listing-lib/internal/frameworkAppSettings/hooks/useFrameworkAppSettings';
import { removeBUPropertyFromFilterEditor } from 'framework-listing-lib/internal/businessUnits/utils';
import { useListingLibData } from 'framework-listing-lib/internal/providers/DataProvider';
import usePropertyOverride from './usePropertyOverride';
import { useHasCustomFetcher } from './useFetcher';
import useSkipCrm from './useSkipCrm';
export default function useCrmObjectTypeProperties({
  propertyOverride: localPropertyOverride,
  showMostUsed
} = {}) {
  const hasBUNavIntegration = useHasBUNavIntegration();
  const propertyOverride = usePropertyOverride();
  const {
    properties: propertiesResponse,
    propertyGroups: propertyGroupsResponse
  } = useListingLibData();
  const hasCustomFetcher = useHasCustomFetcher();
  const skipCrm = useSkipCrm();
  const {
    data: mostUsedProperties,
    updateMostUsedProperties
  } = useCrmObjectTypeMostUsedProperties();
  /**
   * By default always use the global @propertyOverride instance unless a
   * local @localPropertyOverride is provided.
   *
   * The local @localPropertyOverride is mostly used by the filter editor.
   */
  const finalPropertyOverride = localPropertyOverride || propertyOverride;
  /**
   * load & update properties from API response
   * update property names from @columnKeys
   */
  const visiblePropertiesMap = useMemo(() => {
    if (propertiesResponse.length === 0) {
      return finalPropertyOverride && hasCustomFetcher && skipCrm ? addNewPropertiesFromOverride({}, [], finalPropertyOverride) : {};
    }
    const map = mergeResponseWithPropertyOverride(propertiesResponse, finalPropertyOverride);
    const overriddenMap = addNewPropertiesFromOverride(map, propertiesResponse, finalPropertyOverride);

    /**
     * When using the filter editor, we need to remove the BU property
     * (assuming that BU nav changes are turned on)
     */
    if (hasBUNavIntegration && showMostUsed) {
      return removeBUPropertyFromFilterEditor(overriddenMap);
    }
    return overriddenMap;
  }, [finalPropertyOverride, hasBUNavIntegration, hasCustomFetcher, propertiesResponse, showMostUsed, skipCrm]);
  const cachedPropertyGroups = useMemo(() => {
    if (propertiesResponse.length === 0) return {};
    const visibleProperties = Object.values(visiblePropertiesMap);
    const propertyGroups = propertyGroupsResponse.reduce((acc, propertyGroup, groupIndex) => {
      acc[propertyGroup.name] = Object.assign({}, propertyGroup, {
        properties: visibleProperties.filter(property => property.groupName === propertyGroup.name || !property.groupName && groupIndex === 0).sort((a, b) => a.label.localeCompare(b.label))
      });
      return acc;
    }, {});
    if (showMostUsed) {
      return mergeMostUsedProperties(propertyGroups, visiblePropertiesMap, mostUsedProperties);
    }
    return propertyGroups;
  }, [propertiesResponse, mostUsedProperties, propertyGroupsResponse, showMostUsed, visiblePropertiesMap]);
  return {
    dataAsJS: visiblePropertiesMap,
    propertyGroups: cachedPropertyGroups,
    updateMostUsedProperties
  };
}