const PORTAL_MIGRATION_STATUS = {
  NO_MIGRATION: 'NO_MIGRATION',
  MIGRATION_SCHEDULED: 'MIGRATION_SCHEDULED',
  MIGRATION_ACTIVE: 'MIGRATION_ACTIVE',
  MIGRATION_SUCCEEDED: 'MIGRATION_SUCCEEDED',
  MIGRATION_FAILED: 'MIGRATION_FAILED'
};
export const messageTextType = {
  sendNowText: 'sendNowText',
  scheduleText: 'scheduleText',
  warningText: 'warningText',
  localTimeWarningText: 'localTimeWarningText'
};
export const portalMigrationLearnMoreLink = 'https://knowledge.hubspot.com/settings/migrate-your-account-data-to-hubspots-eu-data-center';
export const dashboardType = 'dashboard';
export const sendScheduleType = 'sendSchedule';
export const hublets = {
  /* eslint-disable-next-line hubspot-dev/no-hublet-references */
  eu1: 'European Union (Germany)',
  /* eslint-disable-next-line hubspot-dev/no-hublet-references */
  na1: 'United States (East)'
};
export const noMigrationsCategory = [PORTAL_MIGRATION_STATUS.NO_MIGRATION, PORTAL_MIGRATION_STATUS.MIGRATION_FAILED];
export default PORTAL_MIGRATION_STATUS;