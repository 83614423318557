import { useMemo } from 'react';
import { getObjectTypeNamePlural, getObjectTypeNameSingular } from 'marketing-platform-lib/utils/objectName';
import useFetchCrmObjectTypeDefinitions from './useFetchCrmObjectTypeDefinitions';
export default function useCrmObjectName(objectTypeId, {
  textTransform,
  skip
} = {
  textTransform: 'lowercase'
}) {
  const {
    data: typeDefinitions
  } = useFetchCrmObjectTypeDefinitions({
    skip
  });
  const typeDefinition = typeDefinitions ? typeDefinitions.find(td => td.objectTypeId === objectTypeId) : undefined;
  const objectTypeNamePlural = useMemo(() => getObjectTypeNamePlural({
    objectTypeId,
    textTransform,
    typeDefinition
  }), [objectTypeId, textTransform, typeDefinition]);
  const objectTypeNameSingular = useMemo(() => getObjectTypeNameSingular({
    objectTypeId,
    textTransform,
    typeDefinition
  }), [objectTypeId, textTransform, typeDefinition]);
  return {
    objectTypeNamePlural,
    objectTypeNameSingular
  };
}