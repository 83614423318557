'use es6';

import { REHYDRATE_FROM_USER_SETTINGS } from 'EmailData/actions/actionTypes';
import { DISMISS_CRITSIT_DASHBOARD_BANNER } from 'email-management-lib/refactor/actions/actionTypes';
const INITIAL_STATE = false;
export default function dismissedOutageCritsitBanner(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REHYDRATE_FROM_USER_SETTINGS:
      if (action.payload.has('dismissedOutageCritsitBanner')) {
        return action.payload.get('dismissedOutageCritsitBanner');
      }
      return state;
    case DISMISS_CRITSIT_DASHBOARD_BANNER:
      return true;
    default:
      return state;
  }
}