import memoize from 'react-utils/memoize';
import enviro from 'enviro';
const QA_INDEX = 0;
const PROD_INDEX = 1;
const PATH_INDEX = 2;
// Module key => [qa moduleId, prod moduleId, path]
// any new modules added here should add a translation for it in en.lyaml file
export const MODULES = {
  BUTTON: [1120276, 1976948, '@hubspot/button_email'],
  IMAGE: [1000074, 1367093, '@hubspot/image_email'],
  LINKED_IMAGE: [802481, 1155231, '@hubspot/linked_image'],
  LOGO: [977189, 1155232, '@hubspot/logo'],
  RICH_TEXT: [986064, 1155639, '@hubspot/rich_text'],
  WEB_VERSION_LINK: [1197611, 2794854, '@hubspot/email_webversion_link'],
  FOOTER: [1198400, 2869621, '@hubspot/email_footer'],
  DIVIDER: [1145958, 2191110, '@hubspot/email_divider'],
  FOLLOW_ME: [1149918, 2763545, '@hubspot/follow_me_email'],
  SOCIAL_SHARING: [976280, 1155241, '@hubspot/social_sharing'],
  HTML: [1417000, 3379495, '@hubspot/raw_html_email'],
  BLOG_RSS: [1445927, 3444502, '@hubspot/blog_or_rss_email'],
  TEXT: [801991, 1843376, '@hubspot/text'],
  EMAIL_BODY: [1111543, 1851328, '@hubspot/email_body'],
  EMAIL_VIEW_AS_WEB_PAGE: [992871, 1366605, '@hubspot/email_view_as_web_page'],
  EMAIL_CAN_SPAM: [992876, 1367089, '@hubspot/email_can_spam'],
  EMAIL_SURVEY: [3897975639, 23588106207, '@hubspot/email_survey'],
  EMAIL_LINKED_IMAGE: [53731677482, 122960526478, '@hubspot/email_linked_image'],
  EMAIL_LOGO: [53731677487, 122980089981, '@hubspot/email_logo'],
  EMAIL_POST_FILTER: [53731677490, 122980089983, '@hubspot/email_post_filter'],
  EMAIL_POST_LISTING: [53731677491, 122980089986, '@hubspot/email_post_listing'],
  EMAIL_SECTION_HEADER: [53731677498, 122980089987, '@hubspot/email_section_header'],
  EMAIL_SOCIAL_SHARING: [53731677499, 122980537516, '@hubspot/email_social_sharing'],
  EMAIL_HEADER: [53731677480, 122980089978, '@hubspot/email_header'],
  EMAIL_TEXT: [53731677505, 122980089988, '@hubspot/email_text'],
  EMAIL_CTA: [53731677470, 122980089970, '@hubspot/email_cta'],
  EMAIL_PRODUCTS: [4277100490, 38003752564, '@hubspot/email_products'],
  EMAIL_ABANDONED_CART: [4264447579, 37993565498, '@hubspot/email_abandoned_cart'],
  EMAIL_VIDEO: [4322821356, 42168153629, '@hubspot/video_email'],
  EMAIL_CART: [58285585233, 153395890480, '@hubspot/email_cart']
};

// Module key => module_type
export const LEGACY_MODULES = {
  LOGO_LEGACY: 'logo',
  RICH_TEXT_LEGACY: 'rich_text',
  EMAIL_RESUBSCRIBE_LEGACY: 'email_resubscribe',
  EMAIL_OPTIN_LEGACY: 'email_optin',
  EMAIL_BODY_LEGACY: 'email_body',
  LINKED_IMAGE_LEGACY: 'linked_image',
  EMAIL_CAN_SPAM_LEGACY: 'email_can_spam',
  SOCIAL_SHARING_LEGACY: 'social_sharing',
  EMAIL_VIEW_AS_WEB_PAGE_LEGACY: 'email_view_as_web_page'
};
export const MODULE_TYPES = {
  BUTTON: 'BUTTON',
  IMAGE: 'IMAGE',
  LINKED_IMAGE: 'LINKED_IMAGE',
  LOGO: 'LOGO',
  RICH_TEXT: 'RICH_TEXT',
  WEB_VERSION_LINK: 'WEB_VERSION_LINK',
  FOOTER: 'FOOTER',
  DIVIDER: 'DIVIDER',
  FOLLOW_ME: 'FOLLOW_ME',
  SOCIAL_SHARING: 'SOCIAL_SHARING',
  HTML: 'HTML',
  BLOG_RSS: 'BLOG_RSS',
  EMAIL_ABANDONED_CART: 'EMAIL_ABANDONED_CART',
  EMAIL_CART: 'EMAIL_CART',
  EMAIL_BODY: 'EMAIL_BODY',
  EMAIL_CAN_SPAM: 'EMAIL_CAN_SPAM',
  EMAIL_CTA: 'EMAIL_CTA',
  EMAIL_HEADER: 'EMAIL_HEADER',
  EMAIL_LINKED_IMAGE: 'EMAIL_LINKED_IMAGE',
  EMAIL_LOGO: 'EMAIL_LOGO',
  EMAIL_POST_FILTER: 'EMAIL_POST_FILTER',
  EMAIL_POST_LISTING: 'EMAIL_POST_LISTING',
  EMAIL_PRODUCTS: 'EMAIL_PRODUCTS',
  EMAIL_SECTION_HEADER: 'EMAIL_SECTION_HEADER',
  EMAIL_SOCIAL_SHARING: 'EMAIL_SOCIAL_SHARING',
  EMAIL_SURVEY: 'EMAIL_SURVEY',
  EMAIL_TEXT: 'EMAIL_TEXT',
  EMAIL_VIDEO: 'EMAIL_VIDEO',
  EMAIL_VIEW_AS_WEB_PAGE: 'EMAIL_VIEW_AS_WEB_PAGE'
};
export const LEGACY_MODULE_TYPES = {
  LOGO_LEGACY: 'LOGO_LEGACY',
  RICH_TEXT_LEGACY: 'RICH_TEXT_LEGACY',
  EMAIL_RESUBSCRIBE_LEGACY: 'EMAIL_RESUBSCRIBE_LEGACY',
  EMAIL_OPTIN_LEGACY: 'EMAIL_OPTIN_LEGACY',
  EMAIL_BODY_LEGACY: 'EMAIL_BODY_LEGACY',
  LINKED_IMAGE_LEGACY: 'LINKED_IMAGE_LEGACY',
  EMAIL_CAN_SPAM_LEGACY: 'EMAIL_CAN_SPAM_LEGACY',
  SOCIAL_SHARING_LEGACY: 'SOCIAL_SHARING_LEGACY',
  EMAIL_VIEW_AS_WEB_PAGE_LEGACY: 'EMAIL_VIEW_AS_WEB_PAGE_LEGACY'
};
const makeIdToModuleMap = idIndex => Object.keys(MODULES).reduce((map, nextModule) => {
  const moduleId = MODULES[nextModule][idIndex];
  map[moduleId] = nextModule;
  return map;
}, {});
const ID_TO_MODULE_QA = makeIdToModuleMap(QA_INDEX);
const ID_TO_MODULE_PROD = makeIdToModuleMap(PROD_INDEX);
const PATH_TO_MODULE = makeIdToModuleMap(PATH_INDEX);
const TYPE_TO_LEGACY_MODULE_TYPES = Object.keys(LEGACY_MODULES).reduce((acc, next) => {
  acc[LEGACY_MODULES[next]] = next;
  return acc;
}, {});
const getModuleEnvFromHostname = host => {
  const isQAHost = host.indexOf('sitesqa') >= 0 || host.indexOf('hubspotqa') >= 0 || host.indexOf('hubspotpreviewqa') >= 0;
  return isQAHost ? 'QA' : 'PROD';
};
const isQa = memoize(() => {
  if (window.location.hostname.indexOf('preview') >= 0) {
    return getModuleEnvFromHostname(window.location.hostname) === 'QA';
  }
  return enviro.isQa();
});
export const getModuleType = moduleIdOrPath => {
  if (isNaN(moduleIdOrPath)) {
    return PATH_TO_MODULE[moduleIdOrPath];
  }
  return isQa() ? ID_TO_MODULE_QA[moduleIdOrPath] : ID_TO_MODULE_PROD[moduleIdOrPath];
};
export const getLegacyModuleType = type => {
  return TYPE_TO_LEGACY_MODULE_TYPES[type];
};
export const getModuleId = moduleType => isQa() ? MODULES[moduleType][QA_INDEX] : MODULES[moduleType][PROD_INDEX];
export const getModulePath = moduleType => MODULES[moduleType][PATH_INDEX];