import { useMemo } from 'react';
import { useProperties } from 'framework-listing-lib/internal/providers/PropertiesProvider';
import { useListingLibData } from 'framework-listing-lib/internal/providers/DataProvider';
import useObjectTypeId from './useObjectTypeId';
export function useTypeDefinitions() {
  const {
    typeDefinitions
  } = useListingLibData();
  return typeDefinitions || [];
}
export function useCurrentTypeDefinition() {
  const typeDefinitions = useTypeDefinitions();
  const objectTypeId = useObjectTypeId();
  const {
    typeDefinitionOverride
  } = useProperties();
  return useMemo(() => {
    if (typeDefinitionOverride) {
      return typeDefinitionOverride;
    }
    if (typeDefinitions.length === 0) {
      return undefined;
    }
    return typeDefinitions.find(typeDefinition => typeDefinition.objectTypeId === objectTypeId);
  }, [objectTypeId, typeDefinitionOverride, typeDefinitions]);
}
export function useCurrentTypeDefinitionName() {
  const currentTypeDefinition = useCurrentTypeDefinition();
  return currentTypeDefinition ? currentTypeDefinition.fullyQualifiedName : undefined;
}