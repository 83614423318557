import { useCallback, useMemo, useRef, useState } from 'react';
import I18n from 'I18n';
import formatName from 'I18n/utils/formatName';
import useCrmObjectTypeListingFilters from 'framework-listing-lib/hooks/useCrmObjectTypeListingFilters';
import useFetchUserInfo from 'framework-listing-lib/internal/hooks/useFetchUserInfo';
import useObjectTypeId from 'framework-listing-lib/internal/hooks/useObjectTypeId';
import useCurrentView from 'framework-listing-lib/hooks/useCurrentView';
import { useCurrentUIMode } from 'framework-listing-lib/internal/hooks/useUIMode';
import { useViews, useViewUpdateState, useViewColumns, useNumberOfFrozenColumns, useCardProperties } from 'framework-listing-lib/internal/ViewTabs/hooks/useViewProps';
import { useFilterGroups, useHiddenFilter } from 'framework-listing-lib/hooks/useListingLibFilters';
import useQuickFilters from 'framework-listing-lib/hooks/useQuickFilters';
import { convertViewToViewRequest } from '../utils/mapper';
export default function useViewForm(type) {
  const {
    data
  } = useFetchUserInfo();
  const hiddenFilter = useHiddenFilter();
  const {
    filters
  } = useCrmObjectTypeListingFilters();
  const objectType = useObjectTypeId();
  const currentView = useCurrentView();
  const [viewUpdateState] = useViewUpdateState();
  const views = useViews();
  const columns = useViewColumns();
  const numberOfFrozenColumns = useNumberOfFrozenColumns();
  const currentUIMode = useCurrentUIMode();
  const filterGroups = useFilterGroups();
  const quickFilters = useQuickFilters();
  const cardProperties = useCardProperties();
  const viewToUpdate = type === 'UPDATE' ? currentView : undefined;
  const [viewState, setViewState] = useState(() => {
    const userId = data && data.user ? data.user.user_id : -1;
    const isFirstUserView = views.some(view => view.ownerId === userId) === false;
    const viewRequest = convertViewToViewRequest({
      cardProperties,
      columns,
      currentUIMode,
      filterGroups,
      hiddenFilter,
      numberOfFrozenColumns,
      objectType,
      quickFilters,
      sort: filters.sort,
      view: viewToUpdate,
      viewUpdateState
    });

    /**
     * When creating a view for the first time:
     *   - set view name to "Usernames's first view"
     *   - set view as private
     */
    if (isFirstUserView) {
      viewRequest.name = data && data.user ? I18n.text('frameworkListingLib.viewTabs.modal.create.firstViewName', {
        username: I18n.SafeString(formatName({
          firstName: data.user.first_name,
          lastName: data.user.last_name
        }))
      }) : viewRequest.name;
      viewRequest.private = true;
    }
    return viewRequest;
  });
  const initialView = useRef(viewState);
  const existingNames = useRef(viewToUpdate ? views.filter(view => String(view.id) !== String(viewToUpdate.id)).map(view => view.name) : views.map(view => view.name));
  const nameValidation = useMemo(() => {
    const isValid = viewState.name.length > 0 && !existingNames.current.includes(viewState.name);
    const hasChanged = viewState.name !== initialView.current.name;
    const hasChangedToInvalidValue = hasChanged && !isValid;
    return {
      isInvalid: hasChanged && !isValid,
      showErrorMessage: Boolean(viewState.name && hasChangedToInvalidValue)
    };
  }, [viewState.name]);
  const handleChange = useCallback(view => {
    setViewState(prevInitialView => Object.assign({}, prevInitialView, view));
  }, []);
  return useMemo(() => ({
    viewState,
    validation: {
      name: nameValidation
    },
    onChange: handleChange
  }), [handleChange, nameValidation, viewState]);
}