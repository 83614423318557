import { useRef, useCallback } from 'react';

/**
 * Wraps a function and ensures the first time the wrapped function is called,
 * a callback is invoked. This will only be called once even if the wrapped
 * function is changed.
 */
function useFirstCallEffect(fn, callback) {
  const hasInvoked = useRef(false);
  return useCallback((...args) => {
    if (!hasInvoked.current) {
      callback();
      hasInvoked.current = true;
    }
    return fn(...args);
  }, [fn, callback]);
}
export default useFirstCallEffect;