import { COMMERCE_PAYMENT_TYPE_ID, DEAL_TYPE_ID, LEAD_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { PRODUCT, LINE_ITEM } from 'customer-data-objects/constants/ObjectTypes';
import { isEmail } from 'customer-data-objects/property/PropertyIdentifier';
import enviro from 'enviro';
import PortalIdParser from 'PortalIdParser';
import { SUBSCRIPTION_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
export const isEditableDealExchangeRate = (property, objectTypeId) => objectTypeId === DEAL_TYPE_ID && property.name === 'hs_exchange_rate' && !property.calculated;
export const isSettlementCurrencyProperty = (property, objectTypeId) => objectTypeId === COMMERCE_PAYMENT_TYPE_ID && (property.name === 'hs_fees_amount' || property.name === 'hs_platform_fee' || property.name === 'hs_net_amount');
export const isTaxCategoryProperty = (property, objectType) => (objectType === PRODUCT || objectType === LINE_ITEM) && property.name === 'hs_tax_category';

// This is a special case for the BET portal where we want to use the email validation for 'primary_point_of_contact_email' property.
// isEmail will be extended to use an identifier (probably 'validationHint') in property definition
export const isRevenueEmail = (propertyDefinition, objectType) => {
  const BETPortalId = enviro.isQa() ? 99535353 : 53;
  return BETPortalId === PortalIdParser.get() && isEmail(propertyDefinition, objectType);
};

// In PropertyInputV2, for subscription objects, treat hubspotDefined properties as read-only,
// except for hs_name and hubspot_owner_id, which should remain editable.
// See https://hubspot.slack.com/archives/C0779K5FNJU/p1720732734377819
export const isReadOnlyBillableSubscriptionProperty = (objectTypeId, propertyDefinition) => {
  return objectTypeId === SUBSCRIPTION_TYPE_ID && propertyDefinition.hubspotDefined && !['hs_name', 'hubspot_owner_id'].includes(propertyDefinition.name);
};
export const isLeadLabelProperty = (property, objectTypeId) => objectTypeId === LEAD_TYPE_ID && property.name === 'hs_lead_label';