'use es6';

import { difference, tap, union, uniq, without } from 'underscore';
import formatName from 'I18n/utils/formatName';
function getTeamFromHierarchy(team, targetTeamId) {
  if (team.id === targetTeamId) {
    return team;
  }
  return team.childTeams.reduce((acc, childTeam) => {
    const result = getTeamFromHierarchy(childTeam, targetTeamId);
    if (result) {
      return result;
    }
    return acc;
  }, null);
}
export const getTeam = (teams, targetTeamId) => {
  return teams.reduce((acc, team) => {
    if (!acc) {
      const result = getTeamFromHierarchy(team, targetTeamId);
      if (result) {
        return result;
      }
    }
    return acc;
  }, null);
};
export function getUser(users, targetUserId) {
  return users.find(user => user.id === targetUserId);
}
function getAllChildTeamIds(team) {
  return uniq(team.childTeams.reduce((acc, childTeam) => {
    return union([...acc, childTeam.id], getAllChildTeamIds(childTeam));
  }, []));
}
export const getAllTeamIds = teams => {
  return uniq(teams.reduce((acc, team) => {
    return union([...acc, team.id], getAllChildTeamIds(team));
  }, []));
};
function _getAllParentTeamIdsFromHierarchy(parentIds, team, targetTeamId) {
  if (team.id === targetTeamId) {
    return parentIds;
  }
  return team.childTeams.reduce((acc, childTeam) => {
    const result = _getAllParentTeamIdsFromHierarchy(union(parentIds, [team.id]), childTeam, targetTeamId);
    if (result) {
      return result;
    }
    return acc;
  }, null);
}
export const getRootLevelTeamIds = allTeams => {
  return allTeams.reduce((acc, team) => {
    acc.push(team.id);
    return acc;
  }, []);
};
export const getAllParentTeamIdsFromHierarchy = (team, targetTeamId) => _getAllParentTeamIdsFromHierarchy([], team, targetTeamId);
export const getAllParentTeamIds = (teams, targetTeamId) => {
  return teams.reduce((acc, team) => {
    if (!acc) {
      const result = getAllParentTeamIdsFromHierarchy(team, targetTeamId);
      if (result) {
        return result;
      }
    }
    return acc;
  }, null);
};
export const toggleSelectedTeam = (selectedTeams, allTeams, teamId) => {
  const isSelected = selectedTeams.includes(teamId);
  let updatedSelectedTeams;
  if (!isSelected) {
    const allParentTeamIds = getAllParentTeamIds(allTeams, teamId);
    updatedSelectedTeams = union([...selectedTeams, teamId], allParentTeamIds);
  } else {
    updatedSelectedTeams = difference(without(selectedTeams, teamId), getAllChildTeamIds(getTeam(allTeams, teamId)));
  }
  return updatedSelectedTeams;
};
const getAllUserIdsInHierarchy = (teamHierarchy, selectedTeamIds) => {
  let currentTeamUserIds = [];
  if (!selectedTeamIds || selectedTeamIds && selectedTeamIds.includes(teamHierarchy.id)) {
    currentTeamUserIds = [...teamHierarchy.userIds];
  }
  if (teamHierarchy.childTeams.size === 0) {
    return currentTeamUserIds;
  }
  return teamHierarchy.childTeams.reduce((acc, team) => {
    return union(acc, getAllUserIdsInHierarchy(team, selectedTeamIds));
  }, currentTeamUserIds);
};
export const getAllUserIdsFromTeams = (teams, selectedTeamIds) => {
  return teams.reduce((acc, team) => {
    return union(acc, getAllUserIdsInHierarchy(team, selectedTeamIds));
  }, []);
};
export const getAllSelectedUsers = (allUsers, selectedUserIds) => {
  return selectedUserIds.reduce((acc, userId) => {
    const selectedUser = getUser(allUsers, userId);
    if (selectedUser) {
      return tap(acc, selectedUsers => selectedUsers.push(selectedUser));
    }
    return acc;
  }, []);
};
export const areOnlyChildTeams = teams => {
  return teams.every(team => {
    return !team.childTeams || team.childTeams.length === 0;
  });
};

// Determine if there are valid and enough teams to display teams filter
export const teamsAreFilterable = teams => {
  return teams && teams.length !== 0 && !(teams.length === 1 && areOnlyChildTeams(teams));
};

// Can the user assign teams to object with given permissions, assuming that
// they don't have assignment scope (admin-level - can assign anything)
export const canAssignTeamsAsNonAdmin = (teamPerms, userPerms, teams) => (teamPerms.length > 0 || userPerms.length > 0) && teams && !areOnlyChildTeams(teams);
export const formatUserName = user => {
  const {
    email,
    firstName,
    lastName
  } = user;
  return !firstName || !lastName ? email : `${formatName({
    firstName,
    lastName
  })} (${email})`;
};