'use es6';

import { FETCH_PORTAL_MIGRATION_START, FETCH_PORTAL_MIGRATION_SUCCESS, FETCH_PORTAL_MIGRATION_FAIL } from 'EmailData/actions/actionTypes';
import PortalMigration from 'EmailData/models/PortalMigration';
import { RequestStatus } from 'EmailData/constants/requestStatus';
const initialState = PortalMigration.from({});
export default function portalMigration(state = initialState, action) {
  switch (action.type) {
    case FETCH_PORTAL_MIGRATION_START:
      return state.merge({
        requestStatus: RequestStatus.PENDING
      });
    case FETCH_PORTAL_MIGRATION_SUCCESS:
      return PortalMigration.from(Object.assign({}, action.payload, {
        requestStatus: RequestStatus.SUCCEEDED
      }));
    case FETCH_PORTAL_MIGRATION_FAIL:
      return state.merge({
        requestStatus: RequestStatus.FAILED
      });
    default:
      return state;
  }
}