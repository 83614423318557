'use es6';

import { getEmailIdsData, getSortColumn } from 'email-management-lib/refactor/selectors/manage/table';
import { OrderedMap } from 'immutable';
import { createSelector } from 'reselect';
export const selectCurrentFolderId = (_state, props) => {
  if (props.match.params.folderId === undefined) {
    return undefined;
  }
  try {
    return parseInt(props.match.params.folderId, 10);
  } catch (e) {
    return undefined;
  }
};
export const selectFolderState = state => state.getIn(['entities', 'folders']);
const folderTreeSelector = state => selectFolderState(state).getData();
const emailsSelector = state => state.getIn(['entities', 'emails']);
const idsToChildFolderPath = ids => {
  return ids.reduce((path, nextId) => path.concat('childFolders', nextId), []).slice(1);
};
const findFolderPath = (targetFolderId, folders) => {
  const rootFolders = folders.valueSeq();
  for (let i = 0; i < rootFolders.size; i++) {
    const currentFolder = rootFolders.get(i);
    if (targetFolderId === currentFolder.id) {
      return [targetFolderId];
    }
    const resultPath = currentFolder.getPath(targetFolderId);
    if (resultPath) {
      return idsToChildFolderPath(resultPath.split('/childFolders/').map(idString => parseInt(idString, 10)));
    }
  }
  return [''];
};
export const selectCurrentFolder = createSelector(selectCurrentFolderId, folderTreeSelector, (currentFolderId, folderTree) => {
  const path = findFolderPath(currentFolderId, folderTree);
  return folderTree.getIn(path);
});
export const selectCurrentFolderBreadcrumb = createSelector(selectCurrentFolderId, folderTreeSelector, (currentFolderId, folderTree) => {
  const path = findFolderPath(currentFolderId, folderTree).filter(item => item !== 'childFolders');
  if (path[0] === '') {
    return [];
  }
  const initialBreadcrumb = [folderTree.get(path[0])];
  return path.slice(1).reduce((results, nextId) => {
    return results.concat(results[results.length - 1].childFolders.get(nextId));
  }, initialBreadcrumb);
});
export const selectCurrentChildFolders = createSelector(selectCurrentFolder, folderTreeSelector, getSortColumn, (currentFolder, folderTree, sortColumn) => {
  const childFolders = currentFolder ? currentFolder.childFolders : folderTree;
  return childFolders.sortBy(folder => {
    if (sortColumn === 'name') {
      return folder.name.toLocaleLowerCase();
    }
    return 0;
  });
});
export const selectCurrentChildEmailIds = createSelector(selectCurrentFolder, getEmailIdsData, (currentFolder, tableEmailIds) => {
  if (currentFolder) {
    return tableEmailIds.toArray();
  }
  return [];
});
export const selectCurrentChildEmails = createSelector(selectCurrentChildEmailIds, emailsSelector, (childEmailIds, emails) => childEmailIds.reduce((memo, id) => memo.set(id, emails.get(id)), OrderedMap()));