import I18n from 'I18n';
const isNumber = value => typeof value === 'number' && isFinite(value);
export const calculatePercentage = (a, b) => {
  const numberA = typeof a === 'number' ? a : parseFloat(a);
  const numberB = typeof b === 'number' ? b : parseFloat(b);
  return numberB > 0 ? numberA / numberB * 100 : null;
};
export const formatPercentage = percentage => {
  const formattedPercentage = percentage !== null ? percentage : '-';
  return isNumber(formattedPercentage) ? I18n.formatPercentage(Math.min(formattedPercentage, 100), {
    precision: 2
  }) : formattedPercentage;
};