import { Record } from 'immutable';
import keyMirror from 'react-utils/keyMirror';
const STATES = keyMirror({
  NOT_REQUESTED: null,
  REQUESTED: null,
  SUCCEEDED: null,
  FAILED: null
});
const defaults = {
  state: STATES.NOT_REQUESTED,
  reason: '',
  data: null
};
export class AsyncDataContainer extends Record(defaults) {
  isNotRequested() {
    return this.state === STATES.NOT_REQUESTED;
  }
  isPending() {
    return this.state === STATES.REQUESTED;
  }
  isSuccessful() {
    return this.state === STATES.SUCCEEDED;
  }
  isFailed() {
    return this.state === STATES.FAILED;
  }
  isLoading() {
    return this.isNotRequested() || this.isPending();
  }
  shouldBeRequested() {
    return this.isNotRequested();
  }
  setReason(value) {
    return this.set('reason', value);
  }
  getReason() {
    return this.get('reason');
  }
  getData() {
    return this.get('data');
  }
  setData(value) {
    return this.set('data', value);
  }
  setRequestedState() {
    return this.set('state', STATES.REQUESTED);
  }
  setSucceededState(data = this.data) {
    return this.merge({
      state: STATES.SUCCEEDED,
      data
    });
  }
  setFailedState() {
    return this.set('state', STATES.FAILED);
  }
}
export const NOT_REQUESTED = new AsyncDataContainer({
  state: STATES.NOT_REQUESTED
});
export const REQUESTED = new AsyncDataContainer({
  state: STATES.REQUESTED
});
export const SUCCEEDED = new AsyncDataContainer({
  state: STATES.SUCCEEDED
});
export const FAILED = new AsyncDataContainer({
  state: STATES.FAILED
});
export const getNotRequestedState = (data = null) => new AsyncDataContainer({
  state: STATES.NOT_REQUESTED
}).setData(data);
export const getRequestedState = (data = null) => new AsyncDataContainer({
  state: STATES.REQUESTED
}).setData(data);
export const getSucceededState = (data = null) => new AsyncDataContainer({
  state: STATES.SUCCEEDED
}).setData(data);
export const getFailedState = (reason = '') => new AsyncDataContainer({
  state: STATES.FAILED
}).setReason(reason);