export const SENTRY_KEYS = {
  CRM_LISTING_FILTER_EDITOR: 'CRM_LISTING_FILTER_EDITOR',
  CRM_LISTING_FOLDERS: 'CRM_LISTING_FOLDERS',
  CRM_LISTING_TABLE: 'CRM_LISTING_TABLE',
  LISTING_CALENDAR_LAYOUT: 'CRM_LISTING_CALENDAR',
  LISTING_GALLERY_LAYOUT: 'LISTING_GALLERY_LAYOUT',
  MISSING_RESOLVER: 'MISSING_RESOLVER',
  TOP_LEVEL_LIB_PROVIDER: 'TOP_LEVEL_LIB_PROVIDER',
  CRM_LISTING_FILTERS_ACTIONS_COMPONENT: 'CRM_LISTING_FILTERS_ACTIONS_COMPONENT',
  CRM_LISTING_FILTERS_FILTER_COMPONENT: 'CRM_LISTING_FILTERS_FILTER_COMPONENT',
  CRM_LISTING_CELL_COMPONENT: 'CRM_LISTING_CELL_COMPONENT',
  CRM_LISTING_CUSTOM_CELL_COMPONENT: 'CRM_LISTING_CUSTOM_CELL_COMPONENT',
  CRM_LISTING_FDT_CELL: 'CRM_LISTING_FDT_CELL'
};
export const IGNORE_ERRORS = {
  // This error occurs mainly due to third party extensions and the built-in chrome translation tool
  // clashing with the way react works. There are some _very_ longstanding issues open in both the
  // react and chrome ticket centers; it seems unlikely that this will ever be fixed.
  // Ignore for now
  THIRD_PARTY_DOM_MANIPULATION: "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node."
};