import { configureStore } from '@reduxjs/toolkit';
import { widthsReducer } from '../resizing/redux/widthsSlice';
import { selectionReducer } from '../selection/redux/selectionSlice';
import { visibleCellsReducer } from '../virtualization/redux/visibleCellsSlice';
export const createStore = () => configureStore({
  reducer: {
    selection: selectionReducer,
    visibleCells: visibleCellsReducer,
    widths: widthsReducer
  },
  devTools: {
    name: 'customer-data-table'
  }
});