import { Set as ImmutableSet } from 'immutable';
import { EMAIL_EVENTS } from 'EmailData/utils/details/detailTypeUtil';
export const IGNORED_EVENTS = {
  [EMAIL_EVENTS.PROCESSED]: EMAIL_EVENTS.PROCESSED,
  [EMAIL_EVENTS.FORWARD]: EMAIL_EVENTS.FORWARD,
  [EMAIL_EVENTS.PRINT]: EMAIL_EVENTS.PRINT
};
export const DROP_REASONS = {
  PORTAL_SUSPENDED: 'PORTAL_SUSPENDED',
  INVALID_TO_ADDRESS: 'INVALID_TO_ADDRESS',
  BLOCKED_DOMAIN: 'BLOCKED_DOMAIN',
  PREVIOUSLY_BOUNCED: 'PREVIOUSLY_BOUNCED',
  EMAIL_UNCONFIRMED: 'EMAIL_UNCONFIRMED',
  PREVIOUS_SPAM: 'PREVIOUS_SPAM',
  PREVIOUSLY_UNSUBSCRIBED_MESSAGE: 'PREVIOUSLY_UNSUBSCRIBED_MESSAGE',
  PREVIOUSLY_UNSUBSCRIBED_PORTAL: 'PREVIOUSLY_UNSUBSCRIBED_PORTAL',
  INVALID_FROM_ADDRESS: 'INVALID_FROM_ADDRESS',
  CAMPAIGN_CANCELLED: 'CAMPAIGN_CANCELLED',
  VALIDATION_FAILED: 'VALIDATION_FAILED',
  MTA_IGNORE: 'MTA_IGNORE',
  BLOCKED_ADDRESS: 'BLOCKED_ADDRESS',
  PORTAL_OVER_LIMIT: 'PORTAL_OVER_LIMIT',
  MISSING_CONTENT: 'MISSING_CONTENT',
  QUARANTINED_ADDRESS: 'QUARANTINED_ADDRESS',
  CANCELLED_ABUSE: 'CANCELLED_ABUSE',
  PORTAL_MISSING_MARKETING_SCOPE: 'PORTAL_MISSING_MARKETING_SCOPE',
  UNDELIVERABLE: 'UNDELIVERABLE',
  PREVIOUSLY_UNSUBSCRIBED_BUSINESS_UNIT: 'PREVIOUSLY_UNSUBSCRIBED_BUSINESS_UNIT'
};
export const DROP_MESSAGES = {
  MARCH_19_CRITSIT: 'HubSpot outage March 28 and 29'
};
export const COMMON_DROP_REASONS = {
  [DROP_REASONS.PREVIOUS_SPAM]: [DROP_REASONS.PREVIOUS_SPAM],
  [DROP_REASONS.PREVIOUSLY_UNSUBSCRIBED_MESSAGE]: [DROP_REASONS.PREVIOUSLY_UNSUBSCRIBED_MESSAGE],
  [DROP_REASONS.PREVIOUSLY_UNSUBSCRIBED_PORTAL]: [DROP_REASONS.PREVIOUSLY_UNSUBSCRIBED_PORTAL],
  [DROP_REASONS.PREVIOUSLY_BOUNCED]: [DROP_REASONS.PREVIOUSLY_BOUNCED]
};
export const EVENT_ACTION_TYPES = {
  NOT_SENT: 'NOT_SENT',
  RELATED_EVENT: 'RELATED_EVENT',
  DEFAULT: 'DEFAULT',
  QUARANTINED_WITH_DROP_MESSAGE: 'QUARANTINED_WITH_DROP_MESSAGE'
};
export const TO_EXPAND_DROP_REASONS = ImmutableSet([DROP_REASONS.BLOCKED_ADDRESS, DROP_REASONS.CAMPAIGN_CANCELLED, DROP_REASONS.EMAIL_UNCONFIRMED, DROP_REASONS.INVALID_FROM_ADDRESS, DROP_REASONS.INVALID_TO_ADDRESS, DROP_REASONS.MISSING_CONTENT, DROP_REASONS.MTA_IGNORE, DROP_REASONS.PORTAL_OVER_LIMIT, DROP_REASONS.PORTAL_SUSPENDED]);
export const QUARANTINED_ADDRESS_DROP_MESSAGE = {
  BLOCKLIST_REMEDIATION: 'BLOCKLIST_REMEDIATION',
  FORM_ABUSE: 'FORM_ABUSE',
  HIGH_HARD_BOUNCE_RATE: 'HIGH_HARD_BOUNCE_RATE',
  MULTIPLE_CANCELLED_CAMPAIGNS: 'MULTIPLE_CANCELLED_CAMPAIGNS',
  ON_LIST_IMPORT: 'ON_LIST_IMPORT',
  RECIPIENT_COMPLAINT: 'RECIPIENT_COMPLAINT',
  SUSPENSION_REMEDIATION: 'SUSPENSION_REMEDIATION',
  UNDELIVERABLE_ADDRESS: 'UNDELIVERABLE_ADDRESS'
};