import http from 'hub-http/clients/apiClient';
import { useQuery, registerQuery } from 'data-fetching-client';
import { useMemo } from 'react';
const VIEWS_COUNTS_ENDPOINT = '/sales/v4/views/counts/by-object-type-id';
const VIEWS_COUNTS_QUERY_NAME = 'viewsCounts';
const viewsCountsQueryArgsArgument = [
//this type wont require all properties, but it will at least verify the properties exist on ViewsCountsQueryType
'objectTypeId'];
export const ViewsCountsQuery = registerQuery({
  fieldName: VIEWS_COUNTS_QUERY_NAME,
  args: viewsCountsQueryArgsArgument,
  fetcher: args => http.get(VIEWS_COUNTS_ENDPOINT, {
    data: args
  })
});
const transformRawViewsCountsResponse = (rawResponse, objectTypeId) => {
  return {
    limit: rawResponse.perObjectTypeIdViewLimit,
    currentValue: rawResponse.viewCountPerObjectTypeId[objectTypeId] || 0
  };
};
export const useFetchViewsCounts = objectTypeId => {
  const queryResult = useQuery(ViewsCountsQuery, {
    variables: {
      objectTypeId
    },
    fetchPolicy: 'network-only'
  });
  return useMemo(() => Object.assign({}, queryResult, {
    data: queryResult.data ? transformRawViewsCountsResponse(queryResult.data.viewsCounts, objectTypeId) : undefined
  }), [queryResult, objectTypeId]);
};