// @ts-expect-error not migrated to TS yet
import { canAssignTeamsAsNonAdmin } from 'ContentUtils/helpers/TeamsHelpers';
import { hasContentPartitioningAssignScope, hasEmailContentPartitioning
// @ts-expect-error not migrated to TS yet
} from 'EmailData/selectors/user';
export const canAssignEmail = (email, user, gates, teams) => {
  return hasEmailContentPartitioning(Object.keys(gates), user.get('scopes')) && (hasContentPartitioningAssignScope(user.get('scopes')) || canAssignTeamsAsNonAdmin(email.get('teamPerms'), email.get('userPerms'), teams));
};
export const canAssignMultipleEmail = (hasContentPartitioning, hasContentAssignment) => hasContentPartitioning && hasContentAssignment;
export const buildTeamNameById = teams => {
  let arrayTeams = teams;
  if (!Array.isArray(teams)) {
    arrayTeams = teams.toJS();
  }
  let allTeams = {};
  arrayTeams.forEach(team => {
    let childTeamsObject = {};
    if (team.childTeams.length > 0) {
      childTeamsObject = buildTeamNameById(team.childTeams);
    }
    allTeams = Object.assign({}, allTeams, {
      [team.id]: team.name
    }, childTeamsObject);
  });
  return allTeams;
};