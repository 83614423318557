/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */

import { useEffect, useState } from 'react';
// @ts-expect-error dependency missing types
import getTrialState from 'self-service-api/api/getTrialState';
import ApiNameToUpgradeProductMap from 'self-service-api/constants/ApiNameToUpgradeProductMap';
export const useMultiTrialTrackingProps = () => {
  const [activeTrials, setActiveTrials] = useState(null);
  const [expiredTrials, setExpiredTrials] = useState(null);
  const [isMultiTrial, setIsMultiTrial] = useState(null);
  useEffect(() => {
    getTrialState().then(trialState => {
      const trialMap = Object.keys(trialState).reduce((acc, apiName) => {
        const trial = trialState[apiName];
        const upgradeProduct = ApiNameToUpgradeProductMap[apiName];
        if (trial.hasTrialed) {
          acc.expiredTrials.push(upgradeProduct);
        } else {
          acc.activeTrials.push(upgradeProduct);
        }
        return acc;
      }, {
        activeTrials: [],
        expiredTrials: []
      });
      setActiveTrials(trialMap.activeTrials);
      setExpiredTrials(trialMap.expiredTrials);
      setIsMultiTrial(trialMap.activeTrials.length > 1);
    });
  }, []);
  const isLoadingTrackingProps = [isMultiTrial, activeTrials, expiredTrials].some(item => item === null);
  return {
    activeTrials,
    expiredTrials,
    isMultiTrial,
    isLoadingTrackingProps
  };
};