import { Record } from 'immutable';
class TestSendResult extends Record({
  isLoading: true,
  result: undefined,
  errorMessage: '',
  failed: false
}) {
  static from(json) {
    return new TestSendResult(json);
  }
}
export default TestSendResult;