'use es6';

import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { RECEIVE_CAMPAIGN, REQUEST_CAMPAIGN } from 'email-management-lib/refactor/actions/actionTypes';
import IncrementalResult from 'email-management-lib/refactor/models/IncrementalResult';
const initialState = new IncrementalResult();
function campaigns(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_CAMPAIGN:
      if (action.error !== true) {
        return state.mergeDeep(action.payload.campaigns);
      }
      return state;
    default:
      return state;
  }
}
export default asyncResource(campaigns, {
  requestActions: [REQUEST_CAMPAIGN],
  responseActions: [RECEIVE_CAMPAIGN],
  invalidatingActions: []
});