'use es6';

import { OrderedMap } from 'immutable';
import I18n from 'I18n';
import BaseEmailDetail from 'EmailData/models/details/BaseEmailDetail';
import EmailCampaign from 'EmailData/models/details/children/EmailCampaign';
import LocalTimeSendChild from 'EmailData/models/details/children/LocalTimeSendChild';
const localTimeDefaults = {
  sendChildren: OrderedMap({}),
  emailCampaign: null
};
class LocalTimeEmailDetail extends BaseEmailDetail(Object.assign({}, localTimeDefaults)) {
  static from(json) {
    const response = super.from(json);
    if (typeof json.emailCampaign !== 'undefined') {
      response.emailCampaign = EmailCampaign.from(json.emailCampaign);
    }
    if (response.sendChildren === null) {
      response.sendChildren = localTimeDefaults.sendChildren;
    } else if (typeof response.sendChildren !== 'undefined') {
      response.sendChildren = OrderedMap(response.sendChildren.map(child => [child.gmtOffset, LocalTimeSendChild.from(child)])).sortBy(child => child.scheduledAt);
    }
    return new LocalTimeEmailDetail(response);
  }
  getFirstChildSendMoment() {
    return this.getChildSendMoment(0);
  }
  getLastChildSendMoment() {
    return this.getChildSendMoment(this.sendChildren.size - 1);
  }
  getChildSendMoment(index) {
    if (this.sendChildren.size > index) {
      const child = this.sendChildren.toArray()[index];
      return I18n.moment(child.get('scheduledAt'));
    }
    const getPublishDateMoment = this.getEmail().get('publishDateMoment');
    return I18n.moment(getPublishDateMoment);
  }
  getLocalPublishMomentAsUTC() {
    const publishDateMoment = this.getEmail().get('publishDateMoment');
    return I18n.moment.utc(publishDateMoment.toArray());
  }
}
export default LocalTimeEmailDetail;