import { parse } from 'hub-http/helpers/params';
import memoizeOne from 'react-utils/memoizeOne';
export const getGateOverrides = memoizeOne(search => {
  const {
    gated,
    ungated
  } = parse(search.substring(1));
  const overrides = {};
  if (ungated) {
    (Array.isArray(ungated) ? ungated : [ungated]).reduce((acc, gate) => {
      acc[gate] = true;
      return acc;
    }, overrides);
  }
  if (gated) {
    (Array.isArray(gated) ? gated : [gated]).reduce((acc, gate) => {
      acc[gate] = false;
      return acc;
    }, overrides);
  }
  return overrides;
});

/**
 * Helper that allows gates to be overridden with '?ungated=' (enabled)
 * and '?gated=' (disabled) query parameters. Multiple gates can be
 * overridden by repeating these parameters ('?ungated=...&ungated=...').
 *
 * Using query parameters instead of localStorage for overrides allows
 * links to be sent to support reps or PEs. For discussion on the tradeoffs
 * of various approaches, see https://git.hubteam.com/HubSpot/CRM-Issues/issues/3979
 *
 * WARNINGS:
 * - features that would be dangerous to expose to customers if the url
 *   leaked or was guessed, should not use this helper
 * - features which require the backend to be aware of the gate should not
 *   use this helper, as the override will only apply on the frontend
 *
 * @example
 * const { gates } = useContext(AuthContext);
 * const isUngatedForMyCoolFeature =
 *   withGateOverride('CRM:MyCoolFeature', gates.has('CRM:MyCoolFeature'));
 *
 * // acceptance test
 * await driver.navigate().to('https://.../path/to/page?ungated=CRM:MyCoolFeature')
 */
const withGateOverride = (gate, defaultValue, overrides = getGateOverrides(window.location.search)) => {
  return Object.prototype.hasOwnProperty.call(overrides, gate) ? overrides[gate] : defaultValue;
};
export default withGateOverride;