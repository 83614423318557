'use es6';

import { List, Record } from 'immutable';
const defaults = {
  attemptedAt: null,
  exceptionMessage: null,
  id: null,
  instantBlogPostId: null,
  minimalRssItemList: List([]),
  resultEnum: null,
  retryCount: null,
  rssEmailId: null,
  taskQueueUid: null
};
class RssEmailAttempt extends Record(defaults) {
  static from(json) {
    if (typeof json.minimalRssItemList !== 'undefined') {
      json.minimalRssItemList = List(json.minimalRssItemList);
    }
    return new RssEmailAttempt(json);
  }
}
export default RssEmailAttempt;