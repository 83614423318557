'use es6';

import { Record } from 'immutable';
const defaults = {
  enabled: false,
  failureCount: 0,
  taskQueueExecTime: 0
};
class RssToEmailData extends Record(defaults) {
  static from(json) {
    return new RssToEmailData(json);
  }
  isEnabled() {
    return this.enabled;
  }
  hasRssFeedFailures() {
    return this.failureCount > 0;
  }
}
export default RssToEmailData;