'use es6';

import { FETCH_SMART_INSIGHTS_SUCCESS, FETCH_SMART_INSIGHTS_RESET, FETCH_SMART_INSIGHTS_START, FETCH_SMART_INSIGHTS_FAIL } from 'EmailData/actions/actionTypes';
import SmartInsights from 'EmailData/data/models/SmartInsights';
import aysncResource from 'EmailData/reducers/helpers/asyncResource';
const initialState = SmartInsights.from({});
const smartInsightsReducer = (state = initialState, {
  type,
  payload
}) => {
  switch (type) {
    case FETCH_SMART_INSIGHTS_SUCCESS:
      return SmartInsights.from(payload);
    case FETCH_SMART_INSIGHTS_RESET:
      return initialState;
    default:
      return state;
  }
};
export default aysncResource(smartInsightsReducer, {
  requestActions: [FETCH_SMART_INSIGHTS_START],
  responseActions: [FETCH_SMART_INSIGHTS_SUCCESS, FETCH_SMART_INSIGHTS_RESET],
  invalidatingActions: [FETCH_SMART_INSIGHTS_FAIL]
});