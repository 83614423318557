import PortalIdParser from 'PortalIdParser';
import { createClient } from 'laboratory-lib';
import experiments from '../../../experiments.yaml';
export const treatmentsClient = createClient({
  identifiers: {
    portalId: PortalIdParser.get()
  },
  quickFetchLabel: 'experiment-treatments',
  experiments: experiments,
  timeout: 15000
});