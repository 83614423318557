import styled, { css } from 'styled-components';
import { CALYPSO, CANDY_APPLE, THUNDERDOME } from 'HubStyleTokens/colors';
const activeHighlight = css(["box-shadow:inset 0 0 0 1px ", ";"], CALYPSO);
const errorHighlight = css(["box-shadow:inset 0 0 0 1px ", ";"], CANDY_APPLE);
const editedHighlight = css(["box-shadow:inset 0 0 0 1px ", ";"], THUNDERDOME);
export const EditableCell = styled.td.withConfig({
  displayName: "StyledEditCellComponents__EditableCell",
  componentId: "pgwpru-0"
})(["cursor:text;transition:box-shadow 0s;", ";", ";&:hover,&:focus{", " transition:box-shadow 0.1s ease-in;}"], props => props['aria-pressed'] && editedHighlight, props => props['aria-invalid'] && errorHighlight, activeHighlight);
export const EditCellContentAndValidationAlertWrapper = styled.div.withConfig({
  displayName: "StyledEditCellComponents__EditCellContentAndValidationAlertWrapper",
  componentId: "pgwpru-1"
})(["display:flex;align-items:center;justify-content:space-between;"]);