'use es6';

import { Record } from 'immutable';
import { CANCELED_STATES, COMPLETED_STATES } from 'EmailData/helpers/CombinedEmailSendStateHelpers';
const defaults = {
  apiEmailCampaignGroupId: null,
  apiEmailCampaignId: null,
  batchCompleted: false,
  batchContactsIncluded: null,
  batchEmailApiCallAt: null,
  batchProcessingStartedAt: null,
  batchProcessingState: '',
  batchUpdatedAt: null,
  canceledAt: null,
  compatibleEmailCampaignId: null,
  contentId: null,
  id: null,
  listIntersectionIncluded: null,
  publishDate: null,
  status: '',
  statusChangedAt: null
};
class EmailCampaign extends Record(defaults) {
  static from(json) {
    return new EmailCampaign(json);
  }
  isCompleted() {
    return COMPLETED_STATES.includes(this.batchProcessingState);
  }
  isCancelled() {
    return CANCELED_STATES.includes(this.batchProcessingState);
  }
}
export default EmailCampaign;