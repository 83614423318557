import { getOwnProperty } from '../../utils/getOwnProperty';
import { isDependentPropertyValid } from './utils';
export const getConditionalPropertyOptionsRulesWithDependentErrors = (rules, properties, dependentFieldValues, previousRulesWithErrors) => {
  return rules.filter(rule => {
    const {
      dependentFieldName
    } = rule;

    // ignore CPO rules that may refer to deleted or non-existent properties
    if (!Object.prototype.hasOwnProperty.call(properties, dependentFieldName)) {
      return false;
    }

    // If a rule was marked invalid once, we should continue showing it on the form
    // This is required for the below scenario
    // - When a controlling property's value changes, CPO dependent appear because of an error
    // - Now, when user updates value in the dependent field, it will disappear because it is no longer an error
    // Hence if a rule is marked invalid, we need to keep showing those dependents on all subsequent re-renders of the form
    if (previousRulesWithErrors && previousRulesWithErrors.find(_rule => _rule.dependentFieldName === dependentFieldName)) {
      return true;
    }
    const validDependent = isDependentPropertyValid({
      dependentProperty: properties[dependentFieldName],
      dependentValue: getOwnProperty(dependentFieldValues, dependentFieldName),
      conditionalPropertyOptionsRules: rules
    });
    return !validDependent;
  });
};