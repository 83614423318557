import { registerMutation, registerQuery, useMutation, useQuery } from 'data-fetching-client';
import { Metrics } from '../../metrics/Metrics';
import { getSuperstoreValue, setSuperstoreValue } from '../../utils/superstore';
const LOCAL_STORAGE_VALUE_IS_OPEN = 'IS_OPEN';
const LOCAL_STORAGE_VALUE_IS_CLOSED = 'IS_CLOSED';
const getIsCategoryColumnOpenKey = (objectTypeId, namespace, category) => `${objectTypeId}:${namespace}:${category}`;
const getIsCategoryColumnOpen = ({
  objectTypeId,
  namespace,
  category
}) => {
  return localStorage ? getSuperstoreValue(getIsCategoryColumnOpenKey(objectTypeId, namespace, category)).then(rawValue => String(rawValue) !== LOCAL_STORAGE_VALUE_IS_CLOSED).catch(Metrics.counter('get-is-category-column-open-superstore-error').increment) : Promise.resolve(true);
};
const setIsCategoryColumnOpen = ({
  objectTypeId,
  namespace,
  category,
  isOpen
}) => setSuperstoreValue(getIsCategoryColumnOpenKey(objectTypeId, namespace, category), isOpen ? LOCAL_STORAGE_VALUE_IS_OPEN : LOCAL_STORAGE_VALUE_IS_CLOSED).then(() => isOpen).catch(Metrics.counter('set-is-category-column-open-superstore-error').increment);
const fieldName = 'isCategoryColumnOpen';
export const GetIsCategoryColumnOpenQuery = registerQuery({
  fieldName,
  args: ['objectTypeId', 'namespace', 'category'],
  fetcher: ({
    objectTypeId,
    namespace,
    category
  }) => getIsCategoryColumnOpen({
    objectTypeId,
    namespace,
    category
  })
});
export const SetIsCategoryColumnOpenMutation = registerMutation({
  fieldName,
  args: ['objectTypeId', 'namespace', 'category', 'isOpen'],
  fetcher: ({
    objectTypeId,
    namespace,
    category,
    isOpen
  }) => setIsCategoryColumnOpen({
    objectTypeId,
    namespace,
    category,
    isOpen
  })
});
export const useGetIsCategoryColumnOpen = ({
  objectTypeId,
  namespace,
  category
}) => {
  return useQuery(GetIsCategoryColumnOpenQuery, {
    variables: {
      objectTypeId,
      namespace,
      category
    }
  });
};
export const useSetIsCategoryColumnOpen = ({
  objectTypeId,
  namespace,
  category,
  isOpen
}) => {
  return useMutation(SetIsCategoryColumnOpenMutation, {
    variables: {
      objectTypeId,
      namespace,
      category,
      isOpen
    },
    refetchQueries: [GetIsCategoryColumnOpenQuery],
    awaitRefetchQueries: true
  });
};