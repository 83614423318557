module.exports = {
  "crmTableInteraction": {
    "namespace": "crm-table",
    "name": "CRM Table Interaction",
    "class": "interaction",
    "properties": {
      "page": {
        "type": "string",
        "isOptional": true
      },
      "action": [
        "sort-by-column",
        "page-change",
        "page-size-change",
        "filter-objects-by-query",
        "filter-objects-by-team",
        "filter-objects-by-user",
        "filter-objects-by-user-and-team",
        "filter-objects-by-business-unit",
        "changed-asset-partitioning",
        "open-edit-columns-modal",
        "sort-column-edit-columns-modal",
        "show-column-edit-columns-modal",
        "hide-column-edit-columns-modal",
        "click-save-columns",
        "change-view",
        "create-view",
        "update-view",
        "pin-view",
        "unpin-view",
        "make-default-view",
        "reorder-pinned-views",
        "close-pinned-view",
        "click-save-as-new",
        "click-create-new-view",
        "show-read-only-popover",
        "click-delete-view",
        "click-edit-view",
        "delete-view",
        "click-all-views-link",
        "reorder-view-columns",
        "render-search-empty-state",
        "render-initial-empty-state",
        "edit-columns-reset-default-view",
        "create-folder",
        "update-folder",
        "delete-folder",
        "click-folders-tab",
        "asset-to-folder",
        "asset-out-of-folder",
        "dnd-asset-to-folder",
        "dnd-asset-out-of-folder",
        "change-ui-mode",
        "open-export-modal",
        "export-assets-failure",
        "export-assets-success",
        "select-row",
        "deselect-row",
        "bulk-select-all-on-page",
        "bulk-select-all",
        "bulk-select-none",
        "bulk-select-partial",
        "bulk-select-shift-click",
        "add-quick-filter",
        "remove-quick-filter",
        "set-quick-filters",
        "click-association-cell-link",
        "open-associations-popover",
        "close-associations-popover",
        "click-clear-all-button",
        "click-create-property-link",
        "save-inline-editing-success",
        "save-inline-editing-failure",
        "cancel-inline-editing",
        "used-deep-link",
        "copy-deep-link-from-dev-tools",
        "has-seen-bu-deprecation-banner",
        "dismissed-bu-deprecation-banner",
        "save-view-with-frozen-columns",
        "save-view-without-frozen-columns",
        "save-view-with-card-properties",
        "save-columns-with-frozen-columns",
        "save-columns-without-frozen-columns",
        "save-view-via-shortcut"
      ],
      "objectType": {
        "type": "string"
      },
      "applicationId": {
        "type": "string"
      },
      "uiMode": {
        "type": "string"
      },
      "sortDirection": {
        "type": [
          "ASC",
          "DESC"
        ],
        "isOptional": true
      },
      "pageSize": {
        "type": "number",
        "isOptional": true
      },
      "property": {
        "type": "string",
        "isOptional": true
      },
      "isDefaultView": {
        "type": "boolean",
        "isOptional": true
      },
      "viewId": {
        "type": "string",
        "isOptional": true
      },
      "newUIMode": {
        "type": "string",
        "isOptional": true
      },
      "column": {
        "type": "string",
        "isOptional": true
      },
      "exportedPropertiesNum": {
        "type": "number",
        "isOptional": true
      },
      "exportType": {
        "type": [
          "selected columns",
          "all columns"
        ],
        "isOptional": true
      },
      "toObjectTypeId": {
        "type": "string",
        "isOptional": true
      },
      "numberOfProperties": {
        "type": "number",
        "isOptional": true
      },
      "totalRecords": {
        "type": "number",
        "isOptional": true
      },
      "numberOfFilters": {
        "type": "number",
        "isOptional": true
      },
      "totalFilterGroups": {
        "type": "number",
        "isOptional": true
      },
      "totalQuickFilters": {
        "type": "number",
        "isOptional": true
      },
      "isRecentlyUsed": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "version": "1"
  },
  "filterEditorInteraction": {
    "namespace": "filter-editor",
    "name": "Filter Editor Interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "open-filter-editor",
        "close-filter-editor",
        "condition-added",
        "condition-deleted",
        "condition-cloned",
        "hide-quick-filters"
      ],
      "objectType": {
        "type": "string"
      },
      "property": {
        "type": "string",
        "isOptional": true
      },
      "condition": {
        "type": "string",
        "isOptional": true
      },
      "applicationId": {
        "type": "string"
      },
      "totalAdvancedFilters": {
        "type": "number",
        "isOptional": true
      },
      "totalFilterGroups": {
        "type": "number",
        "isOptional": true
      },
      "totalQuickFilters": {
        "type": "number",
        "isOptional": true
      },
      "hiddenQuickFilterNames": {
        "type": "array",
        "isOptional": true
      }
    },
    "version": "1"
  },
  "performanceInteraction": {
    "namespace": "framework-listing-lib-performance",
    "name": "Lib Perf Interaction",
    "class": "interaction",
    "properties": {
      "objectType": {
        "type": "string"
      },
      "duration": {
        "type": "number"
      },
      "name": {
        "type": "string"
      },
      "startTime": {
        "type": "number"
      },
      "applicationId": {
        "type": "string",
        "isOptional": true
      }
    },
    "version": "1"
  }
};