'use es6';

import { Map as ImmutableMap, fromJS } from 'immutable';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { DISMISS_DETAILS_ACTION_MODAL, SEND_TO_MORE_EXCLUDE_LIST, SEND_TO_MORE_INCLUDE_LIST, SEND_TO_MORE_LISTS_FETCH, SEND_TO_MORE_LISTS_FETCH_REQUEST, SEND_TO_MORE_LISTS_SEARCH, SEND_TO_MORE_LISTS_SEARCH_REQUEST } from 'email-management-lib/refactor/actions/actionTypes';
import ListModel from 'EmailData/models/ListModel';
const initialState = fromJS({
  initialLists: {},
  searchLists: {},
  includedLists: {},
  excludedLists: {}
});
const sendToMoreLists = (state = initialState, action) => {
  switch (action.type) {
    case SEND_TO_MORE_LISTS_FETCH:
      {
        if (action.error) {
          return state;
        }
        const initialLists = action.payload.lists.reduce((listMap, listObject, index) => listMap.set(index, ListModel.from(listObject.list)), ImmutableMap({}));
        return state.set('initialLists', initialLists).set('searchLists', initialLists);
      }
    case SEND_TO_MORE_LISTS_SEARCH:
      {
        if (action.error) {
          return state;
        }
        const searchLists = action.payload.lists.reduce((listMap, listObject, index) => listMap.set(index, ListModel.from(listObject.list)), ImmutableMap({}));
        return state.set('searchLists', searchLists);
      }
    case SEND_TO_MORE_INCLUDE_LIST:
      {
        return state.set('includedLists', action.payload);
      }
    case SEND_TO_MORE_EXCLUDE_LIST:
      {
        return state.set('excludedLists', action.payload);
      }
    case DISMISS_DETAILS_ACTION_MODAL:
      {
        const initialLists = state.get('initialLists');
        return ImmutableMap({
          initialLists,
          searchLists: initialLists,
          includedLists: ImmutableMap({}),
          excludedLists: ImmutableMap({})
        });
      }
    default:
      return state;
  }
};
export default asyncResource(sendToMoreLists, {
  requestActions: [SEND_TO_MORE_LISTS_FETCH_REQUEST, SEND_TO_MORE_LISTS_SEARCH_REQUEST],
  responseActions: [SEND_TO_MORE_LISTS_FETCH, SEND_TO_MORE_LISTS_SEARCH],
  invalidatingActions: [DISMISS_DETAILS_ACTION_MODAL],
  customActions: [SEND_TO_MORE_INCLUDE_LIST, SEND_TO_MORE_EXCLUDE_LIST]
});