'use es6';

import { BEGIN_COLUMN_CUSTOMIZATION, SAVE_COLUMN_CUSTOMIZATION } from 'email-management-lib/refactor/actions/actionTypes';
const initialState = false;
export default function isCustomizingColumns(state = initialState, action) {
  switch (action.type) {
    case BEGIN_COLUMN_CUSTOMIZATION:
      return true;
    case SAVE_COLUMN_CUSTOMIZATION:
      return false;
    default:
      return state;
  }
}