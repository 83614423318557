// @ts-expect-error not migrated to TS yet

import { ANALYZE_REFRESH_STATS, RESET_EMAIL_DETAILS } from 'EmailData/actions/actionTypes';
import { SELECT_STATE, TOGGLE_SELECT_ALL, TOGGLE_SELECT_EMAIL, UNSELECT_EMAILS } from 'email-management-lib/refactor/actions/actionTypes';
import { Map as ImmutableMap } from 'immutable';
const initialState = ImmutableMap();
export default function selectedEmailRows(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SELECT_EMAIL:
      {
        const {
          email
        } = action.payload;
        if (state.has(email.id)) {
          return state.delete(email.id);
        }
        return state.set(email.id, email);
      }
    case TOGGLE_SELECT_ALL:
      {
        const {
          selected,
          allEmails
        } = action.payload;
        if (selected) {
          return allEmails.reduce((emailMap, email) => {
            return emailMap.set(email.id, email);
          }, ImmutableMap());
        }
        return state.clear();
      }
    case UNSELECT_EMAILS:
    case SELECT_STATE:
    case ANALYZE_REFRESH_STATS:
    case RESET_EMAIL_DETAILS:
      return initialState;
    default:
      return state;
  }
}