import { Record } from 'immutable';
class BounceQualifiers extends Record({
  content: 0,
  ip_reputation: 0,
  filtered: 0,
  isp_misconfiguration: 0,
  mailbox_full: 0,
  spamreport: 0,
  temporary_problem: 0,
  throttled: 0,
  unknown_user: 0,
  greylisting: 0,
  mailbox_misconfiguration: 0,
  policy: 0,
  domain_reputation: 0,
  dmarc: 0,
  dns_failure: 0,
  sending_domain_misconfiguration: 0,
  timeout: 0
}) {
  static from(json) {
    return new BounceQualifiers(json);
  }
}
export default BounceQualifiers;