import { useHighlySensitivePropertyValues } from 'customer-data-properties/dataSensitivity/useHighlySensitivePropertyValues';
export const useIsManageButtonVisibile = ({
  objectTypeId,
  objectId,
  propertyDefinition,
  hovered
}) => {
  const highlySensitivePropertyValues = useHighlySensitivePropertyValues({
    objectTypeId,
    objectId,
    defaultValues: {}
  });
  if (propertyDefinition.dataSensitivity !== 'high') {
    return hovered;
  }
  const isHighlySensitiveValueRevealed = Object.prototype.hasOwnProperty.call(highlySensitivePropertyValues, propertyDefinition.name);
  return hovered && isHighlySensitiveValueRevealed;
};