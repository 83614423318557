import { useEffect, useState } from 'react';
import useIsLoading from './useIsLoading';

/**
 * We need to show the skeleton components only once throughout the lifecycle of listing-lib.
 * Sub-sequent loads (sorting, filtering, changing views, changing UI modes...) are handled by UILoadingOverlay since after first load, the app is already loaded.
 */
export default function useShowLoadingSkeleton() {
  const [showLoadingSkeleton, setShowLoadingSkeleton] = useState(true);
  const isLoading = useIsLoading();
  useEffect(() => {
    if (!isLoading) {
      setShowLoadingSkeleton(prev => {
        if (!prev) {
          return prev;
        }
        return false;
      });
    }
  }, [isLoading]);
  return showLoadingSkeleton;
}