import I18n from 'I18n';
import { getAbMasterCampaignId, isAutomatedAb } from 'EmailData/utils/details/abEmailUtil';
import { AB_AUTOMATED_ACTIONS, AB_EMAIL_TYPES, AB_VARIANT_EMAIL_MAP } from 'EmailData/constants/details/abEmailConstants';
export function hasAutomatedWinner(abEmailDetails) {
  return isAutomatedAb(abEmailDetails) && Boolean(abEmailDetails.getIn(['emailABStreamCampaign', 'emailCampaignId']));
}
export function getAutomatedWinningCampaignId(abEmailDetails) {
  const abStreamCampaign = abEmailDetails.get('emailABStreamCampaign');
  if (abStreamCampaign && abStreamCampaign.get('emailCampaignId')) {
    return abStreamCampaign.get('emailCampaignId');
  }
  const defaultWinnerType = getDefaultWinningAutomatedEmailType();
  const defaultWinner = abEmailDetails.get(defaultWinnerType);
  return defaultWinner.primaryEmailCampaignId;
}
export function getAutomatedWinningId(abEmailDetails) {
  const abStreamCampaign = abEmailDetails.get('emailABStreamCampaign');
  if (abStreamCampaign && abStreamCampaign.get('contentId')) {
    return abStreamCampaign.get('contentId');
  }
  const defaultWinnerType = getDefaultWinningAutomatedEmailType();
  const defaultWinner = abEmailDetails.get(defaultWinnerType);
  return defaultWinner.id;
}
export function isAutomatedEmailWinner(abEmailDetails, versionType) {
  return hasAutomatedWinner(abEmailDetails) && versionType === abEmailDetails.getIn(['emailABStreamCampaign', 'variantVersion']);
}
export function isAutomatedEmailWinnerFromCampaignId(abEmailDetails, campaignId) {
  return hasAutomatedWinner(abEmailDetails) && getAutomatedWinningCampaignId(abEmailDetails) === campaignId;
}
export function getWinningAutomatedEmailType(abEmailDetails) {
  if (isAutomatedEmailWinnerFromCampaignId(abEmailDetails, getAbMasterCampaignId(abEmailDetails))) {
    return AB_EMAIL_TYPES.primaryEmail;
  }
  return AB_EMAIL_TYPES.variantEmail;
}
export function getWinningAutomatedVariantType(abEmailDetails) {
  const winningEmail = getWinningAutomatedEmailType(abEmailDetails);
  return AB_VARIANT_EMAIL_MAP[winningEmail];
}
export function getWinningAutomatedEmail(abEmailDetails) {
  const winningType = getWinningAutomatedEmailType(abEmailDetails);
  return abEmailDetails.get(winningType);
}
export function getDefaultWinningAutomatedEmailType() {
  return AB_EMAIL_TYPES.primaryEmail;
}
export function getActionType(hasWinner, isWinningVersion) {
  if (!hasWinner) {
    return AB_AUTOMATED_ACTIONS.CHOOSE_WINNER;
  }
  if (isWinningVersion) {
    return AB_AUTOMATED_ACTIONS.NEW_TEST;
  }
  return AB_AUTOMATED_ACTIONS.RERUN;
}
export function hasAutomatedAction({
  hasWinner,
  isAutomatedAB,
  isUngatedForAutomatedABAdvanced
}) {
  return isAutomatedAB && (!hasWinner || isUngatedForAutomatedABAdvanced);
}
export function getTestTimes(abEmailDetails) {
  const hasWinner = hasAutomatedWinner(abEmailDetails);
  if (hasWinner) {
    return {
      testStartTime: I18n.moment.portalTz(abEmailDetails.getIn(['emailABStreamCampaign', 'createdAt'])),
      testEndTime: I18n.moment.portalTz(abEmailDetails.getIn(['emailABStreamCampaign', 'finishedAt']))
    };
  }
  return {
    testStartTime: I18n.moment.portalTz(abEmailDetails.getIn(['primaryEmail', 'created'])),
    testEndTime: null
  };
}
export function getDeprecatedCampaignId(abEmailDetails) {
  //Won't work when we add AB automated reruns
  return abEmailDetails.get('allCampaignIds').filter(id => !(id === abEmailDetails.primaryEmail.primaryEmailCampaignId || id === abEmailDetails.variantEmail.primaryEmailCampaignId || id === abEmailDetails.get('campaignGroupId'))).first();
}
export function getCampaignGroupId(abEmailDetails) {
  return abEmailDetails.get('campaignGroupId');
}
export function getHasPublishedAutomatedAbTest(abEmailDetails) {
  return !!(abEmailDetails.getEmail().emailABStreamCampaignGroupId && abEmailDetails.getEmail().emailABStreamCampaignSendId);
}