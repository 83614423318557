import { useCallback, useMemo } from 'react';
import isEqual from 'hs-lodash/isEqual';
import { useCrmObjectType } from 'framework-listing-lib/internal/providers/CrmObjectTypeListingClientProvider';
import { areSameFilterGroups, combineFilters, getAggregatedFilterGroups, removePropertyFilters, updateFilterState } from 'framework-listing-lib/utils/filters';
import useExpandCollapseRow from 'framework-listing-lib/hooks/useExpandCollapseRow';
import { UI_MODE } from 'framework-listing-lib/constants/uiMode';
import { useHasViews, useViewUpdateState } from '../internal/ViewTabs/hooks/useViewProps';
import useUIMode from 'framework-listing-lib/internal/hooks/useUIMode';
import useCurrentView, { useCurrentViewFilters } from 'framework-listing-lib/hooks/useCurrentView';
import useDeepEqualEffect from 'framework-listing-lib/internal/hooks/useDeepEqualEffect';
import { ASSETS_WITHOUT_FOLDERS_FILTER } from 'framework-listing-lib/internal/Folders/constants/folders';
import { useHasBUNavIntegration } from 'framework-listing-lib/internal/frameworkAppSettings/hooks/useFrameworkAppSettings';
import { updateFilterGroupsForBUNav, updateQuickFiltersForBUNav } from 'framework-listing-lib/internal/businessUnits/utils';
/**
 * This hook reads the global filter from CrmObjectTypeListingClientProvider and provides two
 * main methods to manipulate filters in the codebase:
 *  - onUpdateFilters: to update/override the filter state.
 *  - onDeleteObject: to delete & ignore objectIds from upcoming requests to crm-search.
 *
 * It also propagates current applied filters as well as list of deleted objects.
 *
 * Components & hooks that need to read/write filter state should pull the filter information from this hook (useCrmObjectTypeListingFilters).
 */
function useCrmObjectTypeListingFilters() {
  const uiModeProps = useUIMode();
  const {
    deletedObjects,
    filters,
    hiddenFilter,
    initialFilter,
    onSetDeletedObjects,
    onSetFilters
  } = useCrmObjectType();
  const hasViews = useHasViews();
  const currentView = useCurrentView();
  const currentViewFilters = useCurrentViewFilters();
  const [, setViewUpdateState] = useViewUpdateState();
  const {
    collapseAll
  } = useExpandCollapseRow();
  const hasBUNavIntegration = useHasBUNavIntegration();
  const currentQuickFilters = useMemo(() => filters.quickFilters, [filters.quickFilters]);
  const hasAppliedFilters = useMemo(() => filters.filterGroups.some(filterGroup => filterGroup.filters.length > 0) || currentQuickFilters.length > 0, [currentQuickFilters.length, filters.filterGroups]);
  const isFilterFromCustomView = useMemo(() => {
    if (!currentView) {
      return false;
    }
    const currentAggregatedFilterGroups = getAggregatedFilterGroups({
      deletedObjects,
      filterGroups: filters.filterGroups,
      hiddenFilter,
      includeHiddenFilter: true,
      quickFilters: currentQuickFilters
    });
    const viewAggregatedFilterGroups = getAggregatedFilterGroups({
      deletedObjects,
      filterGroups: updateFilterGroupsForBUNav({
        filterGroups: currentViewFilters.filterGroups,
        hasBUNavIntegration
      }),
      hiddenFilter,
      includeHiddenFilter: true,
      quickFilters: updateQuickFiltersForBUNav({
        quickFilters: currentViewFilters.quickFilters,
        hasBUNavIntegration
      })
    });
    return areSameFilterGroups(currentAggregatedFilterGroups, viewAggregatedFilterGroups);
  }, [currentQuickFilters, currentView, currentViewFilters.filterGroups, currentViewFilters.quickFilters, deletedObjects, filters.filterGroups, hasBUNavIntegration, hiddenFilter]);
  const onRemovePropertyFilter = useCallback(propertyName => {
    onSetFilters(prevFilters => {
      return Object.assign({}, prevFilters, {
        filterGroups: removePropertyFilters(prevFilters.filterGroups, propertyName)
      });
    });
    collapseAll();
  }, [collapseAll, onSetFilters]);
  const onUpdateFilters = useCallback(updatedFilter => {
    onSetFilters(prevFilters => updateFilterState(prevFilters, updatedFilter));
    collapseAll();
  }, [collapseAll, onSetFilters]);
  const onClearFilters = useCallback(({
    newUIMode,
    newView
  } = {}) => {
    const isGoingToFoldersMode = Boolean(newUIMode && newUIMode !== uiModeProps.currentUIMode && newUIMode === UI_MODE.FOLDERS);

    /**
     * Keep initialFilter when defined
     */
    const initialFilterGroups = initialFilter && initialFilter.filterGroups ? initialFilter.filterGroups : [];
    const initialQuickFilters = initialFilter && initialFilter.quickFilters ? initialFilter.quickFilters : [];

    /**
     * This condition needs to come **before** checking for views for apps that have views + folders.
     * When moving from views to folders both @hasViews and @currentView are still defined, but since
     * we're in folder mode, we need to ignore both, wipe everything and set the initial @ASSETS_WITHOUT_FOLDERS_FILTER filter.
     */
    if (isGoingToFoldersMode) {
      onUpdateFilters({
        filterGroups: combineFilters(initialFilterGroups, ASSETS_WITHOUT_FOLDERS_FILTER),
        quickFilters: initialQuickFilters
      });
      return;
    }
    if (hasViews && newView) {
      onUpdateFilters({
        filterGroups: newView.filterGroups,
        quickFilters: newView.quickFilters || []
      });
    } else if (hasViews && currentView) {
      onUpdateFilters({
        filterGroups: currentViewFilters.filterGroups,
        quickFilters: currentViewFilters.quickFilters
      });
    } else {
      onUpdateFilters({
        filterGroups: initialFilterGroups,
        quickFilters: initialQuickFilters
      });
    }
  }, [currentView, currentViewFilters.filterGroups, currentViewFilters.quickFilters, hasViews, initialFilter, onUpdateFilters, uiModeProps.currentUIMode]);
  const onClearDeletedObjects = useCallback(() => {
    onSetDeletedObjects([]);
  }, [onSetDeletedObjects]);
  const onDeleteObject = useCallback(objectIdOrIds => {
    if (Array.isArray(objectIdOrIds)) {
      onSetDeletedObjects(prevDeletedObjects => [...prevDeletedObjects, ...objectIdOrIds]);
    } else {
      onSetDeletedObjects(prevDeletedObjects => [...prevDeletedObjects, objectIdOrIds]);
    }
  }, [onSetDeletedObjects]);
  useDeepEqualEffect(() => {
    if (!currentView) {
      return;
    }
    setViewUpdateState(previousViewUpdateState => {
      const newViewUpdateState = Object.assign({}, previousViewUpdateState, {
        changedFilters: !isFilterFromCustomView
      });
      if (isEqual(previousViewUpdateState, newViewUpdateState)) {
        return previousViewUpdateState;
      }
      return newViewUpdateState;
    });
  }, [currentView, isFilterFromCustomView, setViewUpdateState]);
  const value = useMemo(() => ({
    deletedObjects,
    filters,
    hasAppliedFilters,
    hiddenFilter,
    initialFilter,
    isFilterFromCustomView,
    onClearDeletedObjects,
    onClearFilters,
    onDeleteObject,
    onRemovePropertyFilter,
    onUpdateFilters
  }), [deletedObjects, filters, hasAppliedFilters, hiddenFilter, initialFilter, isFilterFromCustomView, onClearDeletedObjects, onClearFilters, onDeleteObject, onRemovePropertyFilter, onUpdateFilters]);
  return value;
}
export default useCrmObjectTypeListingFilters;