import I18n from 'I18n';
import EmailType from 'EmailData/enums/EmailType';
const EMAIL_TYPE_DELETE_WARNINGS = {
  [EmailType.OPTIN_EMAIL]: EmailType.OPTIN_EMAIL,
  [EmailType.OPTIN_FOLLOWUP_EMAIL]: EmailType.OPTIN_FOLLOWUP_EMAIL,
  [EmailType.RESUBSCRIBE_EMAIL]: EmailType.RESUBSCRIBE_EMAIL
};
export const getDeleteWarnings = email => {
  const emailType = email.get('emailType');
  if (EMAIL_TYPE_DELETE_WARNINGS[emailType]) {
    return {
      name: email.name,
      text: I18n.text(`EmailComponents.manage.table.deleteWarnings.byEmailType.${emailType}.deleteMulti`)
    };
  }
  return undefined;
};
export const getDeleteHeaderKey = email => {
  const emailType = email.get('emailType');
  if (EMAIL_TYPE_DELETE_WARNINGS[emailType]) {
    return `EmailComponents.modals.delete.header.${emailType}`;
  }
  return `EmailComponents.modals.delete.header.deleteAutomated`;
};
export const getMultiDeleteWarnings = emails => emails.reduce((multiWarnings, email, id) => {
  const warning = getDeleteWarnings(email);
  return warning ? Object.assign({}, multiWarnings, {
    [id]: warning
  }) : multiWarnings;
}, {});