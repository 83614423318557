import { useGetAdditionalPropertyValue } from './useGetAdditionalPropertyValue';
import { usePropertyDefinition } from './usePropertyDefinition';
import { useInternalPropertyValue } from './useInternalPropertyValue';
import { useObjectTypeId } from './useObjectTypeId';
import { useObjectId } from './useObjectId';
import { useHttpClient } from '../../client/HttpClientContext';
import { getPropertyComponentResolver } from '../../resolvers/getPropertyComponentResolver';
export function usePropertyComponentResolver() {
  const propertyDefinition = usePropertyDefinition();
  const internalPropertyValue = useInternalPropertyValue();
  const objectId = useObjectId();
  const objectTypeId = useObjectTypeId();
  const httpClient = useHttpClient();
  const getAdditionalPropertyValue = useGetAdditionalPropertyValue();
  return getPropertyComponentResolver({
    getAdditionalPropertyValue,
    property: propertyDefinition,
    objectId,
    objectTypeId,
    httpClient,
    // We need the raw property value here because some external options
    // components need the raw value of the property to determine the reference
    // type of the property.
    //
    // A specific example of this are Analytics Source properties which can have
    // a value shaped `userId:123` that will usually get parsed into `123` in
    // usePropertyValue but for the purpose of this logic we need the raw value
    // to know it's a user property
    value: internalPropertyValue
  });
}