'use es6';

import { Record } from 'immutable';
const defaults = {
  dismissed: false,
  level: 'DEFAULT',
  loading: false,
  timestamp: 0
};
class PortalDismissStatus extends Record(defaults) {
  static from(json) {
    return new PortalDismissStatus(json);
  }
  isLoading() {
    return this.loading;
  }
}
export default PortalDismissStatus;