import { Record } from 'immutable';
import { RECIPIENTS_FATIGUE_SUPPRESSION_REASONS } from 'EmailData/utils/constants';
const defaults = {
  //Editor selection
  graymailEnabled: false,
  fatigueSuppressionEnabled: true,
  mailingListsIncluded: [],
  mailingListsExcluded: [],
  mailingIlsListsIncluded: [],
  mailingIlsListsExcluded: [],
  marketableContactCheckEnabled: true,
  vidsIncluded: [],
  vidsExcluded: [],
  //Fetched data
  numToSendTo: 0,
  totalIncluded: 0,
  noEmail: 0,
  badEmail: 0,
  unengaged: 0,
  bounced: 0,
  quarantined: 0,
  recipientFatigued: 0,
  exclusionList: 0,
  nonMarketable: 0,
  //Portal settings
  portalFatigueSuppression: false,
  recipientFatigueSuppressionReason: RECIPIENTS_FATIGUE_SUPPRESSION_REASONS.UNAVAILABLE
};
class RecipientsData extends Record(defaults) {
  static from(json) {
    return new RecipientsData(json);
  }
}
export default RecipientsData;