'use es6';

import { Map as ImmutableMap } from 'immutable';
import zip from 'hs-lodash/zip';
import Email from 'EmailData/models/Email';
import { RECEIVE_EARLY_MINIMAL_EMAILS, RECEIVE_EMAILS } from 'email-management-lib/refactor/actions/actionTypes';
import { ANALYZE_REFRESH_STATS } from 'EmailData/actions/actionTypes';
import { ANALYZE_FETCH_EMAILS_BY_STAT, SEARCH_FOR_EMAILS } from 'email-management-lib/refactor/actions/actionTypes';
const initialState = ImmutableMap();
export default function emails(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_EMAILS:
    case SEARCH_FOR_EMAILS:
      if (action.error !== true) {
        return state.mergeDeep(action.payload.emails);
      }
      return state;
    case RECEIVE_EARLY_MINIMAL_EMAILS:
      {
        if (action.error !== true) {
          if (action.payload.ids.length > 0 && state.has(action.payload.ids[0])) {
            // Already has full email, do not merge minimal
            return state;
          }
          return state.mergeDeep(action.payload.emails);
        }
        return state;
      }
    case ANALYZE_FETCH_EMAILS_BY_STAT:
    case ANALYZE_REFRESH_STATS:
      if (action.error !== true) {
        const ids = (action.payload.emails || []).map(el => el.id);
        const emailMap = ImmutableMap(zip(ids, action.payload.emails.map(json => Email.from(json))));
        return state.mergeDeep(emailMap);
      }
      return state;
    default:
      return state;
  }
}