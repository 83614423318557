import { SELECT_USERS_TEAMS_EMAILS } from 'email-management-lib/refactor/actions/actionTypes';
import { List } from 'immutable';
const initialState = List();
export default function selectUsersTeamsEmails(state = initialState, action) {
  switch (action.type) {
    case SELECT_USERS_TEAMS_EMAILS:
      return List(action.payload.emailIds);
    default:
      return state;
  }
}