import { makeTeamIdForSelect } from './UsersAndTeamsSearchUtils';
import { getSortedFlattenedTeamsList } from '../../utils/TeamUtils';
const makeTeamSelectOption = (team, allowedTeamIds) => {
  const {
    depth,
    id,
    name
  } = team;
  return {
    depth,
    disabled: allowedTeamIds && !allowedTeamIds.includes(id),
    value: makeTeamIdForSelect(id),
    text: name,
    icon: 'lists'
  };
};
export const makeFlatListOfTeamSelectOptions = ({
  teams,
  allowedTeamIds
}) => {
  return getSortedFlattenedTeamsList(teams).map(team => makeTeamSelectOption(team, allowedTeamIds));
};