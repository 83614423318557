import { Record } from 'immutable';
import PortalIdParser from 'PortalIdParser';
const defaults = {
  cachedUpdates: [],
  loaded: false,
  otherSettings: {},
  settings: [],
  usingCache: false
};
class PortalSettings extends Record(defaults) {
  static from(json) {
    return new PortalSettings(json);
  }
  getValue(key) {
    const setting = this.settings.find(s => s.key === key);
    if (setting) {
      return setting.value;
    }
    return null;
  }
  getIsLoaded() {
    return this.loaded;
  }
  getIsUsingCache() {
    return this.usingCache;
  }
  getCachedUpdates() {
    return this.usingCache ? this.cachedUpdates : [];
  }
  mergeCachedChangesWithPayload(settingsPayload) {
    const settings = settingsPayload.reduce((result, setting) => {
      const cachedValue = this.cachedUpdates.find(c => c.key === setting.key);
      if (cachedValue) {
        if (cachedValue.deleted) {
          return result;
        }
        return [...result, cachedValue];
      }
      return [...result, setting];
    }, []);
    return new PortalSettings({
      loaded: true,
      settings
    });
  }
  updateOtherSettings(state) {
    return this.merge({
      otherSettings: state
    });
  }
  updateSetting(key, value) {
    const updatedSettings = this.settings.filter(setting => setting.key !== key);
    const update = {
      hubId: PortalIdParser.get(),
      key,
      value
    };
    updatedSettings.push(update);
    const cachedUpdates = this.getCachedUpdates();
    if (this.usingCache) {
      cachedUpdates.push(update);
    }
    return new PortalSettings({
      usingCache: this.usingCache,
      cachedUpdates,
      loaded: this.loaded,
      settings: updatedSettings
    });
  }
  deleteSetting(key) {
    const newSettings = this.settings.filter(s => s.key !== key);
    const cachedUpdates = this.getCachedUpdates();
    if (this.usingCache) {
      cachedUpdates.push({
        key,
        deleted: true
      });
    }
    return new PortalSettings({
      usingCache: this.usingCache,
      cachedUpdates,
      loaded: this.loaded,
      settings: newSettings
    });
  }
}
export default PortalSettings;