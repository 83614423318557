'use es6';

import { Map as ImmutableMap, Record } from 'immutable';
import StatisticsUserAgent from './StatisticsUserAgent';
const defaults = {
  engagementStats: []
};
class StatisticsUAEngagement extends Record(defaults) {
  static from(arrayOfJson) {
    // Merge browser data from the same family before formatting.
    let mergedUAs = ImmutableMap({});
    arrayOfJson.forEach(UA => {
      if (!mergedUAs.has(UA.browserFamily)) {
        mergedUAs = mergedUAs.set(UA.browserFamily, UA);
      } else {
        const existingUA = mergedUAs.get(UA.browserFamily);
        existingUA.count += UA.count;
        mergedUAs = mergedUAs.set(UA.browserFamily, existingUA);
      }
    });
    const mergedJson = mergedUAs.toArray().sort((a, b) => b.count - a.count);
    return new StatisticsUAEngagement({
      engagementStats: mergedJson.map(json => StatisticsUserAgent.from(json))
    });
  }
  hasStatistics() {
    return this.engagementStats.length > 0;
  }
}
export default StatisticsUAEngagement;