import formatName from 'I18n/utils/formatName';
import { hasProperty } from 'marketing-platform-lib/utils/crmUtils';
/**
 * Returns the value of @propertyName in @crmObject.
 *
 * If @propertyName is not present:
 *   - returns undefined when @defaultValue is not provided.
 *   - returns @defaultValue otherwise.
 * @param crmObject The CRM object
 * @param propertyName The property name
 * @param defaultValue Optional default value if @propertyName is not found in @crmObject
 */
export function getPropertyValue(crmObject, propertyName, defaultValue) {
  // TODO: cast to any until we fully migrate the types to marketing-platform-lib
  if (hasProperty(crmObject, propertyName)) {
    return crmObject.properties[propertyName].value;
  }
  return defaultValue;
}

/**
 * Set property value and return a new CrmObject
 * @param crmObject The CRM Object.
 * @param propertyName Property name to be updated.
 * @param value Property value.
 */
export function setPropertyValue(crmObject, propertyName, value) {
  let result = Object.assign({}, crmObject);

  // Create property if it doesn't exist.
  // TODO: cast to any until we fully migrate the types to marketing-platform-lib
  if (!hasProperty(result, propertyName)) {
    result = Object.assign({}, result, {
      properties: Object.assign({}, result.properties, {
        [propertyName]: {
          value: ''
        }
      })
    });
  }
  result = Object.assign({}, result, {
    properties: Object.assign({}, result.properties, {
      [propertyName]: Object.assign({}, result.properties[propertyName], {
        value
      })
    })
  });
  return result;
}
export function getObjectLabel(crmObject, objectTypeDefinition) {
  if (crmObject.objectTypeId === '0-1') {
    return formatName({
      firstName: getPropertyValue(crmObject, 'firstname'),
      lastName: getPropertyValue(crmObject, 'lastname'),
      email: getPropertyValue(crmObject, 'email')
    });
  }
  return getPropertyValue(crmObject, objectTypeDefinition.primaryDisplayLabelPropertyName);
}

/**
 * Batch updates properties on a crmObject
 * @param crmObject The object to update. Will not be mutated, but also will not be entirely cloned
 * @param updates A map of property names to their new values
 * @returns A new crmObject with the property updates
 */
export function setPropertyValues(crmObject, updates) {
  const draftProperties = Object.assign({}, crmObject.properties);
  Object.entries(updates).forEach(([propertyName, value]) => {
    if (!draftProperties[propertyName]) {
      draftProperties[propertyName] = {
        value
      };
    } else if (draftProperties[propertyName].value !== value) {
      // Avoid mutating the original object
      draftProperties[propertyName] = Object.assign({}, draftProperties[propertyName], {
        value
      });
    }
  });
  return Object.assign({}, crmObject, {
    properties: draftProperties
  });
}