import { indexBy } from '../../../utils/indexBy';
import { RESTART_APPROVAL_WARNING } from './pipelineValidationTypes';
const getStageTransitionMap = stageValidation => {
  let stageTransitionMap = {};
  if (stageValidation && stageValidation.stageTransitions && stageValidation.stageTransitions.transitions) {
    const stageTransitions = stageValidation.stageTransitions.transitions;
    stageTransitionMap = indexBy(({
      targetStageId
    }) => targetStageId)(stageTransitions);
  }
  return stageTransitionMap;
};
export const isTransitionValid = (stageId, validation) => {
  const stageTransitionMap = getStageTransitionMap(validation);
  if (Object.prototype.hasOwnProperty.call(stageTransitionMap, stageId)) {
    return stageTransitionMap[stageId].validation.valid;
  }
  return true;
};
export const userCanBypassValidation = (stageId, validation) => {
  //if has validations but userCanBypassValidations at the root is not defined
  //go through and do it per stage
  //v2 will never set root level
  if (validation && (validation.userCanBypassValidations === null || validation.userCanBypassValidations === undefined) && validation.stageTransitions) {
    //try to find the per stage bypass
    const found = validation.stageTransitions.transitions.find(t => t.targetStageId === stageId);
    if (found && found.validation.userCanBypassValidation !== null && found.validation.userCanBypassValidation !== undefined) {
      return found.validation.userCanBypassValidation;
    }
  }
  return Boolean(validation && validation.userCanBypassValidations);
};
/**
 * Converts v2 API to v1 response
 *  */
export const mapValidateBatchToStageValidation = (objectTypeId, result) => {
  const mergedStageTransitions = result.reduce((stageTransitions, validationResult) => {
    if (stageTransitions === null) {
      stageTransitions = {
        originStageId: validationResult.originStageId,
        transitions: []
      };
    }
    if (validationResult.stageValidations.length && stageTransitions) {
      stageTransitions.transitions = stageTransitions.transitions.concat(validationResult.stageValidations.map(stageValidation => {
        return {
          targetStageId: stageValidation.targetStageId,
          validation: {
            valid: stageValidation.validation.valid,
            message: stageValidation.validation.statusCode,
            userCanBypassValidation: stageValidation.validation.userCanBypass
          }
        };
      }));
    }
    return stageTransitions;
  }, null);
  return {
    objectTypeId,
    stageTransitions: mergedStageTransitions
  };
};
const isApprovedRecordMovingAtOrBeforeApprovalStage = (originIndex, targetIndex, approvalStageIndex) => {
  if (originIndex === targetIndex) {
    return false;
  }
  const originStageAtOrAfterApproval = originIndex >= approvalStageIndex;
  const targetStageAtOrBeforeApproval = targetIndex <= approvalStageIndex;
  return originStageAtOrAfterApproval && targetStageAtOrBeforeApproval;
};
const defaultValidResponse = {
  isValid: true
};
export const validateStageChange = ({
  originStageId,
  originPipelineId,
  targetStageId,
  targetPipeline
}) => {
  // If a record moves out of its pipeline, the BE will cancel the created approval so no need for a warning.
  if (originPipelineId !== targetPipeline.pipelineId) {
    return defaultValidResponse;
  }
  const originIndex = targetPipeline.stages.findIndex(stage => stage.stageId === originStageId);
  const targetIndex = targetPipeline.stages.findIndex(stage => stage.stageId === targetStageId);
  if (originIndex < 0 || targetIndex < 0) {
    return defaultValidResponse;
  }

  //find the closest approval stage index before originIndex
  //avoid using findLastIndex due to JIRA https://issues.hubspotcentral.com/browse/CRM-56881
  //we must still support Safari 11 which does not support findLastIndex
  let maybeApprovalStageIndex = originIndex;
  for (; maybeApprovalStageIndex >= 0; maybeApprovalStageIndex--) {
    const stage = targetPipeline.stages[maybeApprovalStageIndex];
    if (stage.isApprovalStage) {
      break;
    }
  }

  // If there isn't an approval stage in the pipeline, there isn't any extra FE validation logic to perform.
  if (maybeApprovalStageIndex < 0) {
    return defaultValidResponse;
  }
  if (isApprovedRecordMovingAtOrBeforeApprovalStage(originIndex, targetIndex, maybeApprovalStageIndex)) {
    return Object.assign({}, defaultValidResponse, {
      code: RESTART_APPROVAL_WARNING
    });
  }
  return defaultValidResponse;
};