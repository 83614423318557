import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["children"];
import { useEffect } from 'react';
import { EMPTY_STATE_TYPE } from 'framework-listing-lib/constants/emptyState';
import useCrmObjectTypeTracking from 'framework-listing-lib/internal/hooks/useCrmObjectTypeTracking';
export default function CrmTableEmptyState(_ref) {
  let {
      children
    } = _ref,
    emptyStateProps = _objectWithoutPropertiesLoose(_ref, _excluded);
  const {
    trackCrmTableInteraction
  } = useCrmObjectTypeTracking();
  useEffect(() => {
    if (!emptyStateProps.emptyStateType) {
      return;
    }
    trackCrmTableInteraction({
      action: emptyStateProps.emptyStateType === EMPTY_STATE_TYPE.FIRST_TIME_IN_APP ? 'render-initial-empty-state' : 'render-search-empty-state'
    });
  }, [emptyStateProps.emptyStateType, trackCrmTableInteraction]);
  return children(emptyStateProps);
}