/**
 * Performs bulkUpdates in @cachedData.
 * Compares existing objectIds and replaces the entire object coming from @crmObjectOrObjects.
 * Supports single & multi updates.
 * @param {Object[]} cachedData
 * @param {Object|Object[]} crmObjectOrObjects
 */
export function bulkUpdate(cachedData, crmObjectOrObjects) {
  let crmObjectsMap = {};
  if (!Array.isArray(crmObjectOrObjects)) {
    crmObjectsMap[crmObjectOrObjects.objectId] = crmObjectOrObjects;
  } else {
    crmObjectsMap = crmObjectOrObjects.reduce((acc, object) => {
      acc[object.objectId] = object;
      return acc;
    }, {});
  }
  return cachedData.map(object => {
    if (object.objectId in crmObjectsMap) {
      return crmObjectsMap[object.objectId];
    }
    return object;
  });
}