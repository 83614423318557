import { useCallback, useEffect, useMemo, useState } from 'react';
import { getKeysFromSessionStorage, readFromSessionStorage, saveToSessionStorage } from 'framework-listing-lib/utils/sessionStorage';
import { safeParseJSON } from 'framework-listing-lib/utils/json';
import { useCurrentViewId, useViewsNamespace } from 'framework-listing-lib/internal/ViewTabs/hooks/useViewProps';
import useObjectTypeId from './useObjectTypeId';

/**
 * Stores the current filterState used in the lib in sessionStorage so changes in views (such as filters)
 * are temporarily saved.
 * The main use-case for this is:
 *
 * 1. User opens View A and applies 1 or more quick filters or filter groups
 * 2. User opens View B
 * 3. User opens View A ➡️ changes from step 1 are still taken into account
 */
export default function useSyncFiltersWithSessionStorage(filters) {
  const [shouldSyncWithSessionStorage, setShouldSyncWithSessionStorage] = useState(false);
  const currentViewId = useCurrentViewId();
  const objectTypeId = useObjectTypeId();
  const namespace = useViewsNamespace();
  const sessionStorageKey = useMemo(() => currentViewId ? `ListingLibFilterState:${objectTypeId}:${currentViewId}:${namespace}` : undefined, [currentViewId, namespace, objectTypeId]);
  useEffect(() => {
    if (!sessionStorageKey || !shouldSyncWithSessionStorage) {
      return;
    }
    saveToSessionStorage(sessionStorageKey, JSON.stringify({
      filterGroups: filters.filterGroups || [{
        filters: []
      }],
      quickFilters: filters.quickFilters || []
    }));
  }, [filters.filterGroups, filters.quickFilters, sessionStorageKey, shouldSyncWithSessionStorage]);

  /**
   * Remove all the keys that were previously stored in sessionStorage while the sync is still disabled
   * This is to prevent the case where:
   *   1. The user has previously saved a view with filters
   *   2. We saved those changes in sessionStorage
   *   3. The user is "caught by surprise" that the view is not showing what it was supposed to show
   */
  useEffect(() => {
    if (!shouldSyncWithSessionStorage) {
      getKeysFromSessionStorage().filter(key => key.startsWith(`ListingLibFilterState`)).forEach(key => {
        sessionStorage.removeItem(key);
      });
    }
  }, [shouldSyncWithSessionStorage]);
  const turnOnSessionStorage = useCallback(() => {
    setShouldSyncWithSessionStorage(true);
  }, []);
  return useMemo(() => {
    return {
      filterStateFromSessionStorage: shouldSyncWithSessionStorage && sessionStorageKey ? safeParseJSON(readFromSessionStorage(sessionStorageKey), undefined) : undefined,
      turnOnSessionStorage
    };
  }, [sessionStorageKey, shouldSyncWithSessionStorage, turnOnSessionStorage]);
}