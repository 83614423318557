import { getRelativeDateTimeLabel } from './getRelativeDateTimeLabel';
import I18n from 'I18n';
import { getDateDisplayHintLabel } from './getDateDisplayHintLabel';
export const getDisplayValueForDate = (property, value, dateFormat, relativeDate, showDateDisplayHintLabel) => {
  let displayValue = '';
  const maybeRelativeLabel = relativeDate && property.dateDisplayHint !== 'time_since' && property.dateDisplayHint !== 'time_until' ? getRelativeDateTimeLabel({
    value,
    isDateTime: false
  }) : null;
  if (maybeRelativeLabel) {
    displayValue = maybeRelativeLabel;
  } else {
    displayValue = I18n.moment.utc(Number(value)).format(dateFormat);

    // time_since and time_until properties should be treated as duration.
    // So, they should never show the date.
    // showDateDisplayHintLabel is a temporary argument to this function since this library does not have access to gates
    // once CRM:PropMgmt:TimeSinceUntil is ungated to 100% we can remove this argument
    const shouldShowDateDisplayHintLabel = showDateDisplayHintLabel || property.dateDisplayHint === 'time_since' || property.dateDisplayHint === 'time_until';
    const maybeDateDisplayHintLabel = shouldShowDateDisplayHintLabel ? getDateDisplayHintLabel({
      value,
      type: property.type,
      dateDisplayHint: property.dateDisplayHint
    }) : null;
    if (maybeDateDisplayHintLabel) {
      displayValue = property.dateDisplayHint === 'time_since' || property.dateDisplayHint === 'time_until' ? `${maybeDateDisplayHintLabel}` : `${displayValue} (${maybeDateDisplayHintLabel})`;
    }
  }
  return displayValue;
};