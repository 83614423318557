import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { HOVER_DATE_TIME_CELL, PROPERTY_VALIDATION_CHANGE, PROPERTY_VALUE_CHANGE, RESET_ALL, RESET_PROPERTY_VALUE } from 'framework-listing-lib/internal/FrameworkDataTable/constants/actionTypes';
export const initialState = {
  propertyChanges: {},
  propertyValidationResult: {},
  dateTimeHoverCell: {
    objectId: null,
    propertyName: null
  }
};
export default function inlineEditReducer(state, action) {
  switch (action.type) {
    default:
      return state;
    case PROPERTY_VALUE_CHANGE:
      {
        return Object.assign({}, state, {
          propertyChanges: Object.assign({}, state.propertyChanges, {
            [action.payload.objectId]: Object.assign({}, state.propertyChanges[action.payload.objectId], action.payload.changes)
          })
        });
      }
    case PROPERTY_VALIDATION_CHANGE:
      {
        return Object.assign({}, state, {
          propertyValidationResult: Object.assign({}, state.propertyValidationResult, {
            [action.payload.objectId]: Object.assign({}, state.propertyValidationResult[action.payload.objectId], {
              [action.payload.propertyName]: action.payload.changes
            })
          })
        });
      }
    case RESET_PROPERTY_VALUE:
      {
        if (!(action.payload.objectId in state.propertyChanges)) {
          return state;
        }
        const currentObjectIdChanges = Object.assign({}, state.propertyChanges[action.payload.objectId]);
        delete currentObjectIdChanges[action.payload.propertyName];
        if (Object.keys(currentObjectIdChanges).length === 0) {
          const _state$propertyChange = state.propertyChanges,
            _action$payload$objec = action.payload.objectId,
            rest = _objectWithoutPropertiesLoose(_state$propertyChange, [_action$payload$objec].map(_toPropertyKey));
          return Object.assign({}, state, {
            propertyChanges: rest
          });
        }
        return Object.assign({}, state, {
          propertyChanges: Object.assign({}, state.propertyChanges, {
            [action.payload.objectId]: currentObjectIdChanges
          })
        });
      }
    case HOVER_DATE_TIME_CELL:
      return Object.assign({}, state, {
        dateTimeHoverCell: action.payload
      });
    case RESET_ALL:
      {
        return Object.assign({}, state, {
          propertyChanges: {},
          propertyValidationResult: {}
        });
      }
  }
}