export const APP_IDS = {
  BATCH_EMAIL: 113,
  AUTOMATED_EMAIL: 2286,
  WORKFLOWS: 20100,
  AB_EMAIL: 20185,
  SMTP_EMAIL: 22709,
  LOCALTIME_EMAIL: 22809,
  RSS_EMAIL: 22842,
  BLOG_EMAIL: 22843,
  LEADFLOW_EMAIL: 22863,
  FEEDBACK_NPS_EMAIL: 22924,
  FEEDBACK_CES_EMAIL: 50005,
  FEEDBACK_CUSTOM_EMAIL: 50006,
  FEEDBACK_CUSTOM_SURVEY_EMAIL: 51739
};