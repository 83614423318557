'use es6';

import { Map as ImmutableMap } from 'immutable';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { REQUEST_BLOGS, RECEIVE_BLOGS } from 'email-management-lib/refactor/actions/actionTypes';
const initialState = ImmutableMap();
function blogs(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_BLOGS:
      {
        if (action.error !== true) {
          return state.mergeDeep(action.payload);
        }
        return state;
      }
    default:
      return state;
  }
}
export default asyncResource(blogs, {
  requestActions: [REQUEST_BLOGS],
  responseActions: [RECEIVE_BLOGS],
  invalidatingActions: []
});