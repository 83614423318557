'use es6';

import { RequestStatus } from 'EmailData/constants/requestStatus';
import { createSelector } from 'reselect';
const getTableFetchStatus = state => state.getIn(['manage', 'table', 'fetchStatus']);
const getFolderState = state => state.getIn(['entities', 'folders']);
export const selectTableFetchStatus = createSelector([getTableFetchStatus], tableFetchStatus => {
  return tableFetchStatus;
});
export const selectTableAndFolderFetchStatus = createSelector([selectTableFetchStatus, getFolderState], (tableFetchStatus, folderState) => {
  if (folderState.isErrored()) {
    return RequestStatus.FAILED;
  }
  if (!folderState.hasEverLoaded() || folderState.isLoading()) {
    return RequestStatus.PENDING;
  }
  return tableFetchStatus;
});