'use es6';

import { SORT_TABLE_BY_COLUMN } from 'email-management-lib/refactor/actions/actionTypes';
const initialState = 'updated';
export default function sortColumn(state = initialState, action) {
  switch (action.type) {
    case SORT_TABLE_BY_COLUMN:
      return action.payload.sortColumn;
    default:
      return state;
  }
}