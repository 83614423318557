import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import PortalIdParser from 'PortalIdParser';
import { BU_PROPERTY_NAME } from 'framework-listing-lib/constants/businessUnits';
import { EQ, combineFilters, removePropertyFilters } from 'framework-listing-lib/utils/filters';
import { getItem } from 'framework-listing-lib/utils/localStorage';
import { safeParseJSON } from 'framework-listing-lib/utils/json';
import { NAV_BU_LOCAL_STORAGE_KEY } from './constants';
export function getBUFromLocalStorage() {
  const lsValue = String(getItem(NAV_BU_LOCAL_STORAGE_KEY) || '{}');
  const parsedValue = safeParseJSON(lsValue, {});

  // legacy number value
  if (typeof parsedValue === 'number') {
    return String(parsedValue);
  }
  // @ts-expect-error legacy ALL value
  if (parsedValue === 'ALL') {
    return 'ALL';
  }
  const currentPortalId = PortalIdParser.get();
  if (currentPortalId && currentPortalId in parsedValue) {
    return String(parsedValue[currentPortalId]).replaceAll('"', '');
  }
  return '0';
}

/**
 * MO-181 | BU nav changes in listing-lib
 * This method updates filterGroups by removing all filter operators on the BU property.
 */
export function updateFilterGroupsForBUNav({
  filterGroups,
  hasBUNavIntegration
}) {
  if (!hasBUNavIntegration) {
    return filterGroups;
  }
  return removePropertyFilters(filterGroups, BU_PROPERTY_NAME);
}

/**
 * MO-181 | BU nav changes in listing-lib
 * This method updates quickFilters by removing all filter operators on the BU property.
 */
export function updateQuickFiltersForBUNav({
  hasBUNavIntegration,
  quickFilters
}) {
  if (!hasBUNavIntegration) {
    return quickFilters;
  }
  return quickFilters.filter(filterOperator => filterOperator.property !== BU_PROPERTY_NAME);
}

/**
 * MO-181 | BU nav changes in listing-lib
 *
 * This method updates the hiddenFilter based on what's been defined in the top nav.
 * Since users can't change the BU value from index pages anymore, the BU filter
 * will live in @hiddenFilters.
 */
export function updateHiddenFilterForBUNav({
  hasBUNavIntegration,
  hiddenFilter
}) {
  const nonNullHiddenFilter = hiddenFilter || {
    filterGroups: [{
      filters: []
    }]
  };
  if (!hasBUNavIntegration) {
    return nonNullHiddenFilter;
  }
  const hiddenFilterWithoutBU = {
    filterGroups: removePropertyFilters(nonNullHiddenFilter.filterGroups, BU_PROPERTY_NAME)
  };
  const buValue = getBUFromLocalStorage();
  if (buValue === 'ALL') {
    return hiddenFilterWithoutBU;
  }
  return {
    filterGroups: combineFilters(hiddenFilterWithoutBU.filterGroups, [{
      filters: [EQ(BU_PROPERTY_NAME, buValue)]
    }])
  };
}
export function removeBUPropertyFromFilterEditor(propertyMap) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const rest = _objectWithoutPropertiesLoose(propertyMap, [BU_PROPERTY_NAME].map(_toPropertyKey));
  return rest;
}