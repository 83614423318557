/**
 * Creates an object from an array using the key for each item returned by `keyMapper`.
 * Keys should be unique; items sharing the same key will overwrite (last write wins).
 *
 * @example
 * const items = [{ id: '1' }, { id: '2' }]
 * const index = indexBy(({ id }) => id)(items);
 * // => {
 * //   '1': { id: '1' },
 * //   '2': { id: '2' },
 * // }
 */
export function indexBy(keyMapper) {
  return subject => subject.reduce((acc, v) => {
    acc[keyMapper(v)] = v;
    return acc;
  }, {});
}