import { createSelector } from 'reselect';
import { createHasScopeSelector } from 'EmailData/selectors/scopes';
export const getIsApprovalsEnabled = state => state.get('approvalsConfig').getData().get('enabled');
export const getHasApprovalsReadScope = createHasScopeSelector('email-approval-read');
export const getHasApprovalsWriteScope = createHasScopeSelector('email-approval-write');
export const getHasApprovalsScope = createSelector([getHasApprovalsReadScope, getHasApprovalsWriteScope], (hasApprovalsReadScope, hasApprovalsWriteScope) => {
  return hasApprovalsReadScope || hasApprovalsWriteScope;
});
export const getCanViewApprovals = createSelector([getIsApprovalsEnabled, getHasApprovalsScope], (isApprovalsEnabled, hasApprovalsScope) => {
  return isApprovalsEnabled && hasApprovalsScope;
});
export const getFetchApprovalsConfigState = state => state.get('approvalsConfig').getData().get('isLoading');