import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["data", "refetch"];
import { useCallback, useMemo } from 'react';
import { registerQuery, useQuery } from 'data-fetching-client';
import PortalIdParser from 'PortalIdParser';
import useObjectTypeId from 'framework-listing-lib/internal/hooks/useObjectTypeId';
import { useViewsNamespace } from 'framework-listing-lib/internal/ViewTabs/hooks/useViewProps';
import { setSuperstoreValue, getSuperStoreValue } from 'framework-listing-lib/internal/utils/superstore';
const DEPRECATED_TIME = 864000000; //10 days

function updateMostUsedPropertiesAttribute(mostUsedProperties, newPropertyKey) {
  if (!newPropertyKey) {
    return mostUsedProperties;
  }
  const isNewProperty = !mostUsedProperties.some(property => property.key === newPropertyKey);
  const updatedMostUsedProperties = isNewProperty ? mostUsedProperties : mostUsedProperties.map(property => {
    if (newPropertyKey !== property.key) {
      return property;
    }
    return {
      key: property.key,
      lastUsedTimestamp: new Date().getTime(),
      usageCount: property.usageCount + 1
    };
  });
  return updatedMostUsedProperties.concat(isNewProperty ? [{
    key: newPropertyKey,
    lastUsedTimestamp: new Date().getTime(),
    usageCount: 1
  }] : []).filter(({
    lastUsedTimestamp
  }) => new Date().getTime() - lastUsedTimestamp < DEPRECATED_TIME);
}
const GET_MOST_USED_PROPERTIES_FIELD_NAME = 'mostUsedProperties';
const GET_MOST_USED_PROPERTIES_QUERY = registerQuery({
  fieldName: GET_MOST_USED_PROPERTIES_FIELD_NAME,
  fetcher: ({
    superstoreKey
  }) => getSuperStoreValue(superstoreKey).then(value => value || [])
});
export default function useCrmObjectTypeMostUsedProperties() {
  const objectType = useObjectTypeId();
  const namespace = useViewsNamespace();
  const superstoreKey = useMemo(() => `MostUsedProperties-${namespace}-${objectType}-${PortalIdParser.get()}`, [objectType, namespace]);
  const _useQuery = useQuery(GET_MOST_USED_PROPERTIES_QUERY, {
      variables: {
        superstoreKey
      }
    }),
    {
      data,
      refetch
    } = _useQuery,
    rest = _objectWithoutPropertiesLoose(_useQuery, _excluded);
  const mostUsedProperties = useMemo(() => data ? [...data.mostUsedProperties] : [], [data]);
  const updateMostUsedProperties = useCallback(newPropertyKey => {
    const newAttribute = updateMostUsedPropertiesAttribute(mostUsedProperties, newPropertyKey);
    setSuperstoreValue(superstoreKey, newAttribute).then(() => refetch()).catch(() => {});
  }, [refetch, superstoreKey, mostUsedProperties]);
  return Object.assign({
    data: mostUsedProperties,
    updateMostUsedProperties
  }, rest);
}