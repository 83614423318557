import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
export function readWriteQuery({
  apolloClient,
  query,
  updaterFn,
  variables
}) {
  const cachedQuery = apolloClient.readQuery({
    query,
    variables
  });
  if (cachedQuery) {
    apolloClient.writeQuery({
      query,
      data: updaterFn(cachedQuery),
      variables
    });
  }
}

/**
 * This is a workaround for the missing "updateQuery" in our current apollo version.
 */
export default function useReadWriteQuery() {
  const apolloClient = useApolloClient();
  return useCallback(({
    query,
    updaterFn,
    variables
  }) => {
    readWriteQuery({
      apolloClient,
      query,
      updaterFn,
      variables
    });
  }, [apolloClient]);
}