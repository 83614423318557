import { useHasScope } from '../userInfo/hooks/useHasScope';
export const useBETCanCurrentUserMakePublicView = () => {
  const {
    data: hasBetViewsPublicShareRestricted,
    loading: shareRestrictedLoading,
    error: shareRestrictedError
  } = useHasScope('bet-views-public-share-restricted');
  const {
    data: hasBetViewsPublicShare,
    loading: publicLoading,
    error: publicError
  } = useHasScope('bet-views-public-share');
  return {
    data: !hasBetViewsPublicShareRestricted || hasBetViewsPublicShare,
    loading: shareRestrictedLoading || publicLoading,
    error: shareRestrictedError || publicError
  };
};