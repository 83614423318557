'use es6';

import { Record } from 'immutable';
const defaults = {
  htmlClickTrackingEnabled: true,
  loaded: false,
  openTrackingEnabled: true,
  plainTextClickTrackingEnabled: true
};
class TrackingConfiguration extends Record(defaults) {
  static from(json) {
    json.loaded = true;
    return new TrackingConfiguration(json);
  }
  isFullyEnabled() {
    return this.isLoaded() && this.htmlClickTrackingEnabled && this.plainTextClickTrackingEnabled && this.openTrackingEnabled;
  }
  isFullyDisabled() {
    return this.isLoaded() && !this.htmlClickTrackingEnabled && !this.plainTextClickTrackingEnabled && !this.openTrackingEnabled;
  }
  isLoaded() {
    return this.loaded;
  }
}
export default TrackingConfiguration;