import { useEffect, useMemo, useState } from 'react';
import isEqual from 'hs-lodash/isEqual';
import listingLibObserver from 'framework-listing-lib/utils/listingLibObserver';
import { UPDATE_BULK_ACTIONS_STATE } from 'framework-listing-lib/constants/tableEvents';
import { BULK_SELECTION_MODE } from 'framework-listing-lib/constants/table';
import useUIMode from 'framework-listing-lib/internal/hooks/useUIMode';
export default function useBulkActions() {
  const uiModeProps = useUIMode();
  const [bulkActionsState, setBulkActionsState] = useState({
    selectedIds: [],
    selectedObjects: [],
    selectedObjectsCount: 0,
    selectionMode: BULK_SELECTION_MODE.NONE
  });
  useEffect(() => {
    const notifyChange = newState => setBulkActionsState(prevState => isEqual(prevState, newState) ? prevState : newState);
    listingLibObserver.on(UPDATE_BULK_ACTIONS_STATE, notifyChange);
    return () => {
      listingLibObserver.off(UPDATE_BULK_ACTIONS_STATE, notifyChange);
    };
  }, []);
  return useMemo(() => Object.assign({}, bulkActionsState, {
    onClearSelection: () => listingLibObserver.setBulkActionSelectionMode(BULK_SELECTION_MODE.NONE),
    uiMode: uiModeProps.currentUIMode
  }), [bulkActionsState, uiModeProps.currentUIMode]);
}