import { CONTACT_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { MARKETING_REASON, SALESFORCE_CAMPAIGN } from './CustomResolverReferenceTypes';
/**
 * The goal is eventually to completely remove this mapping but for now it
 * handles some discrepancies between what our legacy components supported and
 * what RRL currently supports.
 */
const PropertyNameToCustomReferenceType = {
  [CONTACT_TYPE_ID]: {
    hs_marketable_reason_id: MARKETING_REASON,
    salesforcecampaignids: SALESFORCE_CAMPAIGN
  }
};

/**
 * There are some reference types that the property components need to set that
 * are not defined in the base getReferenceTypeFromProperty defined in RRL. The
 * main culprits here are properties that derive their reference types from
 * the values of other properties. These reference types generally only have
 * meaning in the context of the property components so they are not declared
 * in the base getReferenceTypeFromProperty.
 *
 * This function will also work as a holdover to support some of the properties
 * the legacy components that the Property Components support that are not
 * currently implemented in RRL.
 */
export const getCustomReferenceTypeForProperty = (property, objectTypeId) => {
  const customReferenceTypeOverrides = PropertyNameToCustomReferenceType[objectTypeId] || {};
  return Object.prototype.hasOwnProperty.call(customReferenceTypeOverrides, property.name) ? customReferenceTypeOverrides[property.name] : undefined;
};