import Raven from 'raven-js';
import { sendRequestAccessNotification } from '../../_core/common/api/requestNotificationsApi';
import { sendSeatRequest } from '../../_core/common/api/seatsApi';
import * as tracker from '../../_core/common/eventTracking/tracker';
import wrapWithErrorMonitoring from '../../_core/common/reliability/wrapWithErrorMonitoring';
import { getOwnedProducts } from 'self-service-api/api/getProducts';
import { fetchAvailableSeats } from '../../_core/common/api/fetchAvailableSeats';
import { getOwnedHubs } from '../../_core/utils/getOwnedHubs';
import { getSeatSelectOptions } from '../../_core/utils/getSeatSelectOptions';
export const REQUEST_SEAT_MODAL_INITIAL_STATE = {
  availableSeatOptions: [],
  defaultSeat: null,
  isLoading: true
};
export const fetchAvailableSeatOptions = () => {
  return Promise.all([fetchAvailableSeats(), getOwnedProducts()]).then(([seats, products]) => {
    const ownedHubs = getOwnedHubs(products);
    const seatOptions = getSeatSelectOptions(seats, ownedHubs);
    return seatOptions;
  }).catch(availableSeatsAndProductsError => {
    console.error(['Error resolving seats data promise', availableSeatsAndProductsError]);
    return [];
  });
};
export const requestSeatUpgrade = wrapWithErrorMonitoring('requestSeatUpgrade', ({
  apiName,
  isLegacySeatsModel,
  requestReason,
  requestedSeatType
}) => sendSeatRequest({
  apiName,
  isLegacySeatsModel,
  requestReason,
  requestedSeatType
}).then(() => {
  if (isLegacySeatsModel) {
    sendRequestAccessNotification(apiName, requestReason, requestedSeatType).then().catch(error => {
      console.error(error);
    });
  }

  // ********** PUBLIC EVENT **********
  // Public Events help teams across HubSpot automate work and customize experiences based on user actions.
  // Speak with #product-insight and your PM before any shipping any changes to this event incl. event name, properties, values, and when it occurs.
  // Read more about Public Events on the wiki: https://wiki.hubspotcentral.net/display/PM/Public+Events+-+Amplitude+events+ready+for+HubSpot+team+use+and+automation
  tracker.track('requestUpgradeInteraction', {
    action: 'request seat succeeded',
    apiName,
    requestReason,
    requestedSeatType
  });
}).catch(e => {
  // ********** PUBLIC EVENT **********
  // Public Events help teams across HubSpot automate work and customize experiences based on user actions.
  // Speak with #product-insight and your PM before any shipping any changes to this event incl. event name, properties, values, and when it occurs.
  // Read more about Public Events on the wiki: https://wiki.hubspotcentral.net/display/PM/Public+Events+-+Amplitude+events+ready+for+HubSpot+team+use+and+automation
  tracker.track('requestUpgradeInteraction', {
    action: 'request seat failed',
    apiName,
    requestReason,
    requestedSeatType
  });
  Raven.captureException(e);
  throw e;
}));