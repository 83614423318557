'use es6';

import Email from 'EmailData/models/Email';
import Template from 'EmailData/models/Template';
import { canCancel, canForceCancel, getAbMasterCampaignId, getAbVariantCampaignId, getDefaultEmail, getDefaultWinningEmailType, getHoursToWait, getTestMetric, getTestPercentage, getTestTimes, getWinnerExecTime, getWinningCampaignId, getWinningEmail, getWinningEmailType, getWinningVariantType, hasFallback, hasWinner, isDraft, isEmailFallback, isEmailWinner, isEvenlySplitSend, isInconclusive, isProcessingCancelled, isSampleSizeInsignificant, isSampling, isSamplingInsignificant, isWaitingForWinner, isWinnerCanceled } from 'EmailData/utils/details/abEmailUtil';
import AbEmailCampaign from 'EmailData/models/details/children/AbEmailCampaign';
import BaseEmailDetail from 'EmailData/models/details/BaseEmailDetail';
const abDetailDefaults = {
  variantEmailId: null,
  variantEmail: null,
  variantTemplateInfo: Template.from({}),
  abEmailCampaign: AbEmailCampaign.from({}),
  sentWithClsIds: false
};
class AbEmailDetail extends BaseEmailDetail(Object.assign({}, abDetailDefaults)) {
  static from(json) {
    const response = super.from(json);
    if (typeof response.abEmailCampaign !== 'undefined') {
      response.abEmailCampaign = AbEmailCampaign.from(response.abEmailCampaign);
    }
    if (typeof response.variant !== 'undefined') {
      response.variantEmailId = response.variant.id;
      response.variantEmail = Email.from(Object.assign({}, response.variant));
    } else {
      response.variantEmailId = Email.from({});
    }
    if (typeof response.variantTemplateInfo !== 'undefined') {
      response.variantTemplateInfo = Template.from(Object.assign({}, response.variantTemplateInfo));
    }
    return new AbEmailDetail(response);
  }
  isSampleSizeInsignificant() {
    return isSampleSizeInsignificant(this);
  }
  isSampling() {
    return isSampling(this);
  }
  isWinnerCanceled() {
    return isWinnerCanceled(this);
  }
  isProcessingCancelled() {
    return isProcessingCancelled(this);
  }
  isDraft() {
    return isDraft(this);
  }
  isWaitingForWinner() {
    return isWaitingForWinner(this);
  }
  canCancel() {
    return canCancel(this);
  }
  canForceCancel() {
    return canForceCancel(this);
  }
  isEvenlySplitSend() {
    return isEvenlySplitSend(this);
  }
  hasWinner() {
    return hasWinner(this);
  }
  hasFallback() {
    return hasFallback(this);
  }
  getWinningCampaignId() {
    return getWinningCampaignId(this);
  }
  getAbMasterCampaignId() {
    return getAbMasterCampaignId(this);
  }
  getAbVariantCampaignId() {
    return getAbVariantCampaignId(this);
  }
  getWinnerExecTime() {
    return getWinnerExecTime(this);
  }
  isEmailWinner(campaignId) {
    return isEmailWinner(this, campaignId);
  }
  isEmailFallback(campaignId) {
    return isEmailFallback(this, campaignId);
  }
  isSamplingInsignificant() {
    return isSamplingInsignificant(this);
  }
  getWinningEmailType() {
    return getWinningEmailType(this);
  }
  getWinningVariantType() {
    return getWinningVariantType(this);
  }
  getWinningEmail() {
    return getWinningEmail(this);
  }
  getTestMetric() {
    return getTestMetric(this);
  }
  getTestPercentage() {
    return getTestPercentage(this);
  }
  getHoursToWait() {
    return getHoursToWait(this);
  }
  isInconclusive() {
    return isInconclusive(this);
  }
  getDefaultWinningEmailType() {
    return getDefaultWinningEmailType(this);
  }
  getDefaultEmail() {
    return getDefaultEmail(this);
  }
  getVariantTemplateInfo() {
    return this.variantTemplateInfo;
  }
  getLastTestPrimaryEmail() {
    return this.primaryEmail;
  }
  getLastTestPrimaryEmailCampaignId() {
    return this.getLastTestPrimaryEmail().primaryEmailCampaignId;
  }
  getLastTestVariantEmail() {
    return this.variantEmail;
  }
  getLastTestVariantEmailCampaignId() {
    return this.getLastTestVariantEmail().primaryEmailCampaignId;
  }
  getCampaignGroupId() {
    return this.abEmailCampaign.campaignGroupId;
  }
  getDeprecatedCampaignId() {
    return null;
  }
  getDeprecatedMasterCampaignId() {
    return getAbMasterCampaignId(this);
  }
  getDeprecatedVariantCampaignId() {
    return getAbVariantCampaignId(this);
  }
  getTestTimes() {
    return getTestTimes(this);
  }
}
export default AbEmailDetail;