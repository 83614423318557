import { createTracker } from 'usage-tracker';
import events from '../../events.yaml';
export const tracker = createTracker({
  events
});
function getApp() {
  return window.location.pathname.split('/')[1];
}
export const trackInteraction = interaction => {
  tracker.track('toolAccessInteraction', {
    app: getApp(),
    interaction
  });
};
export const trackRequestCreated = ({
  roleName,
  scope
}) => tracker.track('toolAccessRequestCreated', {
  app: getApp(),
  role: roleName,
  value: scope
});