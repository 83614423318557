import { useMemo } from 'react';
import { useAppSettingsOption } from 'framework-listing-lib/internal/frameworkAppSettings/hooks/useFrameworkAppSettings';
import { useCurrentUIMode } from 'framework-listing-lib/internal/hooks/useUIMode';
import { UI_MODE } from 'framework-listing-lib/constants';

/**
 * "ORs" is enabled by default but it can be disabled for teams that are using
 * a custom endpoint other than crm-search that does not support ORs
 *
 * This is done via the @disableOrConditions in Framework App Settings.
 *
 * "ORs" is also disabled in folders mode due to an unexpected UI experience when we append folder filters to
 * filters set by the user. Slack: https://hubspot.slack.com/archives/C021HGE3YNT/p1695828540485999
 */
export default function useHasOrConditions() {
  const disableOrConditions = useAppSettingsOption('disableOrConditions');
  const currentUIMode = useCurrentUIMode();
  return useMemo(() => Boolean(disableOrConditions) === false && currentUIMode !== UI_MODE.FOLDERS, [currentUIMode, disableOrConditions]);
}