'use es6';

import { FETCH_HEALTH_THRESHOLDS_ERROR, FETCH_HEALTH_THRESHOLDS_REQUEST, FETCH_HEALTH_THRESHOLDS_SUCCESS } from 'email-management-lib/refactor/actions/actionTypes';
export const INITIAL_STATE = null;
export function healthThresholds(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_HEALTH_THRESHOLDS_REQUEST:
      return INITIAL_STATE;
    case FETCH_HEALTH_THRESHOLDS_SUCCESS:
      return action.payload;
    case FETCH_HEALTH_THRESHOLDS_ERROR:
    default:
      return state;
  }
}