import devLogger from 'react-utils/devLogger';
export const validateProperty = (isValid, message, propName) => {
  if (!isValid) {
    devLogger.warn({
      message: `createUpgradeData: ${message}`,
      key: `createUpgradeData: ${propName}`
    });
    return false;
  }
  return true;
};