import { useQuery } from 'data-fetching-client';
import { FETCH_SUPPORTED_TRANSLATION_LANGUAGES } from '../queries/supportedTranslationLanguages';
import { useMemo } from 'react';
function useSupportedTranslationLanguages() {
  const {
    data,
    loading: isFetchingSupportedTranslationLanguages,
    error
  } = useQuery(FETCH_SUPPORTED_TRANSLATION_LANGUAGES);
  return useMemo(() => {
    var _data$languages;
    return {
      supportedTranslationLanguages: (_data$languages = data === null || data === void 0 ? void 0 : data.languages) !== null && _data$languages !== void 0 ? _data$languages : [],
      isFetchingSupportedTranslationLanguages,
      error
    };
  }, [data === null || data === void 0 ? void 0 : data.languages, error, isFetchingSupportedTranslationLanguages]);
}
export default useSupportedTranslationLanguages;