import http from 'hub-http/clients/apiClient';
import { HubsSettingClient } from 'frontend-preferences-client';
const hubsSettingClient = HubsSettingClient.forCaller('EmailData');
const URL_TRACKING_CONFIG = '/cosemail/v1/portal/settings/tracking';

/**
 * These keys came from manually scraping `ContentEmailUI` to find the places
 * that the getPortalSettings selector is used. New usages of the selector
 * will require updating this list.
 */
const KEYS_TO_FETCH = ['email:portalUsedClassicBeforeCreationFlowMerge', 'email:recipientFatigueSuppression:enabled', 'MarketingEmailDashboardOnboarding:Dismissed', 'MarketingEmailDashboardOnboarding:OnboardingType', 'MarketingEmailDashboardOnboarding:OptOutImport:Dismissed', 'MarketingEmailDashboardOnboarding:ContactsImport:Dismissed', 'MarketingEmailDashboardOnboarding:FirstSend:Dismissed', 'MarketingEmailDashboardOnboarding:MailchimpImport:Dismissed'];
export const fetchPortalSettingsRequest = () => {
  const requestBody = KEYS_TO_FETCH.reduce((acc, key) => {
    return Object.assign({}, acc, {
      [key]: ''
    });
  }, {});
  return hubsSettingClient.batchFetch(requestBody);
};
export const fetchDataPrivacySettings = () => {
  return http.get('data-privacy-settings/hub-settings');
};
export const fetchTrackingConfigurationRequest = () => {
  return http.get(URL_TRACKING_CONFIG);
};
export const updateSettingRequest = (key, value) => {
  return hubsSettingClient.write(key, String(value));
};
export const deleteSettingRequest = key => {
  return hubsSettingClient.del(key);
};