import { useCallback, useMemo } from 'react';
import { useCrmObjectType } from 'framework-listing-lib/internal/providers/CrmObjectTypeListingClientProvider';
import { getFilterValueByProperty } from 'framework-listing-lib/utils/filters';
import useCrmObjectTypeTracking from 'framework-listing-lib/internal/hooks/useCrmObjectTypeTracking';
import useCrmObjectTypeListingFilters from './useCrmObjectTypeListingFilters';
export default function useQuickFilters() {
  const {
    filters
  } = useCrmObjectTypeListingFilters();
  return useMemo(() => filters.quickFilters, [filters.quickFilters]);
}
export function useQuickFiltersCount() {
  const quickFilters = useQuickFilters();
  return quickFilters.length;
}
export function useSetQuickFilter() {
  const {
    trackCrmTableInteraction
  } = useCrmObjectTypeTracking();
  const {
    onSetFilters
  } = useCrmObjectType();
  return useCallback(filterOperator => {
    trackCrmTableInteraction({
      action: 'add-quick-filter',
      property: filterOperator.property
    });
    onSetFilters(prev => Object.assign({}, prev, {
      quickFilters: [...prev.quickFilters.filter(filter => filter.property !== filterOperator.property), filterOperator]
    }));
  }, [onSetFilters, trackCrmTableInteraction]);
}
export function useRemoveQuickFilter() {
  const {
    trackCrmTableInteraction
  } = useCrmObjectTypeTracking();
  const {
    onSetFilters
  } = useCrmObjectType();
  return useCallback(propertyName => {
    trackCrmTableInteraction({
      action: 'remove-quick-filter',
      property: propertyName
    });
    onSetFilters(prev => Object.assign({}, prev, {
      quickFilters: [...prev.quickFilters.filter(filter => filter.property !== propertyName)]
    }));
  }, [onSetFilters, trackCrmTableInteraction]);
}
export function useGetQuickFilterValue() {
  const quickFilters = useQuickFilters();
  return useCallback((propertyName, defaultValue = null) => {
    return getFilterValueByProperty({
      filterGroups: [{
        filters: quickFilters
      }]
    }, propertyName, defaultValue);
  }, [quickFilters]);
}
export function useSetQuickFilters() {
  const {
    trackCrmTableInteraction
  } = useCrmObjectTypeTracking();
  const {
    onSetFilters
  } = useCrmObjectType();
  return useCallback(quickFilters => {
    trackCrmTableInteraction({
      action: 'set-quick-filters'
    });
    onSetFilters(prev => Object.assign({}, prev, {
      quickFilters
    }));
  }, [onSetFilters, trackCrmTableInteraction]);
}
export function useListingLibQuickFilters() {
  const quickFilters = useQuickFilters();
  const getQuickFilterValue = useGetQuickFilterValue();
  const setQuickFilter = useSetQuickFilter();
  const removeQuickFilter = useRemoveQuickFilter();
  const setQuickFilters = useSetQuickFilters();
  return {
    getQuickFilterValue,
    quickFilters,
    removeQuickFilter,
    setQuickFilter,
    setQuickFilters
  };
}