import { useCallback } from 'react';
import { makePropertyValuesCache } from '../../values/PropertyValuesCache';
import { useHttpClient } from '../../client/HttpClientContext';
import { useAsyncFunction } from '../../utils/hooks/useAsyncFunction';
const propertyValuesCache = makePropertyValuesCache();

// this hook provides all property values from a record. Values from this hook may become stale.
// Always look for a property's value locally, then from host apps through useAdditionalPropertyValue
// Use value from this hook as a last resort
export const useAllPropertyValues = ({
  objectId,
  objectTypeId,
  skip
}) => {
  const httpClient = useHttpClient();
  const _skip = skip || !objectId;
  const getValues = useCallback(() => objectId === undefined ? Promise.resolve({}) : propertyValuesCache.get({
    objectTypeId,
    objectId
  }, httpClient), [httpClient, objectId, objectTypeId]);
  return useAsyncFunction(getValues, {
    skip: _skip
  });
};

// exported for unit test usage only
export const _resetCache = () => {
  propertyValuesCache.clear();
};