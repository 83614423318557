'use es6';

import { FETCH_PORTAL_EMAIL_SEND_CREDITS_START, FETCH_PORTAL_EMAIL_SEND_CREDITS_SUCCESS, FETCH_PORTAL_EMAIL_SEND_CREDITS_FAIL, RECEIVE_PORTAL_STATUS, REQUEST_PORTAL_STATUS } from 'EmailData/actions/actionTypes';
import PortalSendCredits from 'EmailData/data/models/PortalSendCredits';
import aysncResource from 'EmailData/reducers/helpers/asyncResource';
const initialState = PortalSendCredits.from({});
const portalSendCreditsReducer = (state = initialState, {
  type,
  payload
}) => {
  switch (type) {
    case FETCH_PORTAL_EMAIL_SEND_CREDITS_SUCCESS:
      return PortalSendCredits.from(payload);
    case RECEIVE_PORTAL_STATUS:
      if (payload.error !== true) {
        return PortalSendCredits.from(payload.sendCredits || payload.emailPortalStatus && payload.emailPortalStatus.sendCredits || {});
      }
      return state;
    default:
      return state;
  }
};
export default aysncResource(portalSendCreditsReducer, {
  requestActions: [FETCH_PORTAL_EMAIL_SEND_CREDITS_START, REQUEST_PORTAL_STATUS],
  responseActions: [FETCH_PORTAL_EMAIL_SEND_CREDITS_SUCCESS, RECEIVE_PORTAL_STATUS],
  invalidatingActions: [FETCH_PORTAL_EMAIL_SEND_CREDITS_FAIL]
});