import { useCallback } from 'react';
import { UsageTracker } from '../utils/usageTracker';
export default function useDecorateHandlerWithTracking(_ref) {
  let {
    additionalPropertiesFn,
    eventKey,
    filterFn,
    handler,
    properties
  } = _ref;
  return useCallback((...args) => {
    if (!filterFn || filterFn(...args)) {
      const additionalProperties = additionalPropertiesFn ? additionalPropertiesFn(...args) : undefined;
      UsageTracker.track(eventKey, Object.assign({}, properties, additionalProperties));
    }
    return handler(...args);
  }, [additionalPropertiesFn, eventKey, filterFn, handler, properties]);
}