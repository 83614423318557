import { useCallback, useMemo } from 'react';
import { getBusinessUnitFromResponse } from '../data/CampaignRecord';
import useCurrencySelectInfo from './useCurrencySelectInfo';
import useUserInfo from './useUserInfo';
export default function useGetCampaignCreatedTrackingProps({
  skipCurrencyFetching
} = {}) {
  const {
    hasScope
  } = useUserInfo();
  const {
    homeCurrencyCode
  } = useCurrencySelectInfo({
    skip: skipCurrencyFetching
  });
  const hasBusinessUnit = useMemo(() => hasScope('business-units-access'), [hasScope]);
  return useCallback(campaign => {
    const hasDefinedBudget = campaign.numericBudget != null;
    const trackedFields = {
      startedAt: Boolean(campaign.startedAt),
      endedAt: Boolean(campaign.endedAt),
      owner: Boolean(campaign.owner),
      goal: Boolean(campaign.goal),
      audience: Boolean(campaign.audience),
      numericBudget: hasDefinedBudget,
      notes: Boolean(campaign.notes),
      spendAmount: campaign.spendAmount != null,
      status: Boolean(campaign.status)
    };
    const fields = [...Object.keys(trackedFields).filter(key => trackedFields[key]), ...Object.keys(campaign.customProperties || [])];
    const businessUnitFromResponse = getBusinessUnitFromResponse(campaign.businessUnitsInfo);
    const businessUnitId = businessUnitFromResponse && businessUnitFromResponse.businessUnitsInfo;
    return Object.assign({
      colorSelected: campaign.colorHex || 'none'
    }, campaign.currencyCode ? {
      currencyCode: campaign.currencyCode
    } : undefined, {
      fields,
      hasStartDate: trackedFields.startedAt,
      hasEndDate: trackedFields.endedAt,
      hasOwner: trackedFields.owner,
      hasDefinedGoal: trackedFields.goal,
      hasDefinedAudience: trackedFields.audience,
      hasDefinedBudget,
      hasDefinedNotes: trackedFields.notes,
      hasDefinedStatus: trackedFields.status,
      isDefault: campaign.currencyCode ? campaign.currencyCode === homeCurrencyCode : undefined
    }, hasBusinessUnit ? {
      businessUnitId
    } : undefined);
  }, [hasBusinessUnit, homeCurrencyCode]);
}