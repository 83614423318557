'use es6';

import { createHasGateSelector } from 'EmailData/selectors/gates';
import { parseQueryParams } from 'EmailData/utils/url';
import { createSelector } from 'reselect';
import memoize from 'transmute/memoize';

// Duplicate from ./user to avoid cyclical deps
// @rsanchez: I don't think this helper is a good idea to keep around
// but doing so for the sake of the code split. Will revisit at a later time
const getUser = state => state.get('user');

/**
 * Helper that allows gates to be overridden with '?ungated=' (enabled)
 * and '?gated=' (disabled) query parameters.
 * Multiple gates can be overridden by repeating these parameters ('?ungated=...&ungated=...').
 * Available only to @hubspot.com users.
 *
 * WARNINGS:
 * - features that would be dangerous to expose to customers if the url
 *   leaked or was guessed, should not use this helper
 * - features which require the backend to be aware of the gate should not
 *   use this helper, as the override will only apply on the frontend
 * - "gated" or "ungated" will have priority over current gate configuration. Use it sparingly!
 *
 * @example
 * https://hubspot[qa].com/path/to/page?ungated=CRM:MyCoolGateFeature (will enable the feature)
 * https://hubspot[qa].com/path/to/page?gated=CRM:MyCoolGateFeature (will disable the feature)
 */
const getGateOverride = memoize((gate, query) => {
  const getArray = value => {
    if (!value) {
      return [];
    }
    return Array.isArray(value) ? value : [value];
  };
  const ungatedArr = getArray(query.ungated);
  const gatedArr = getArray(query.gated);
  return {
    isForceUngate: ungatedArr.includes(gate),
    isForceGate: gatedArr.includes(gate)
  };
});
export const createHasGateWithOverrideSelector = gate => createSelector(createHasGateSelector(gate), getUser, (hasGate, user) => {
  const query = parseQueryParams(window.location.search);
  const isHSUser = user.email.endsWith('@hubspot.com') || user.email.endsWith('.hsemailsendingtestqa.com') || user.email.endsWith('.hsemailsendingtest.com');
  const {
    isForceGate,
    isForceUngate
  } = getGateOverride(gate, query);
  if (isHSUser && isForceUngate) {
    return true;
  } else if (isHSUser && isForceGate) {
    return false;
  }
  return hasGate;
});