import { getPropertyDefinitions } from '../propertyDefinitions/getPropertyDefinitions';
import { keyBy } from '../utils/keyBy';
import memoizeOne from 'react-utils/memoizeOne';
const keyByName = memoizeOne(properties => keyBy(properties, ({
  property: {
    name
  }
}) => name,
// HACK: These types are identical in reality — the generated type has `fieldType` as string,
// but it is constrained by BE validation to the same set of values as this local type.
({
  property
}) => property));
export const fetchPropertyDefinitions = (objectTypeId, httpClient) => getPropertyDefinitions({
  objectTypeId,
  httpClient
}).then(keyByName);