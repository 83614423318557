'use es6';

import { List, is } from 'immutable';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { SELECT_FOLDER_ROW, SELECT_STATE, SET_TABLE_OFFSET, SORT_TABLE_BY_COLUMN, UPDATE_SEARCH_TERM, RECEIVE_EARLY_MINIMAL_EMAILS, RECEIVE_EMAILS, REQUEST_EMAILS, SELECT_EMAIL_TYPE, SELECT_BUSINESS_UNIT, SELECT_CAMPAIGN, SELECT_STATE_FILTER, SELECT_SUBSCRIPTION } from 'email-management-lib/refactor/actions/actionTypes';
const initialState = List();
function emailIds(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_EMAILS:
    case RECEIVE_EARLY_MINIMAL_EMAILS:
      if (action.error !== true) {
        const ids = List(action.payload.ids);
        return is(state, ids) ? state : ids;
      }
      return initialState;
    case SELECT_FOLDER_ROW:
      return initialState;
    default:
      return state;
  }
}
export default asyncResource(emailIds, {
  requestActions: [REQUEST_EMAILS],
  responseActions: [RECEIVE_EMAILS, RECEIVE_EARLY_MINIMAL_EMAILS],
  invalidatingActions: [UPDATE_SEARCH_TERM, SELECT_STATE, SELECT_STATE_FILTER, SELECT_EMAIL_TYPE, SELECT_CAMPAIGN, SET_TABLE_OFFSET, SORT_TABLE_BY_COLUMN, SELECT_FOLDER_ROW, SELECT_BUSINESS_UNIT, SELECT_SUBSCRIPTION]
});