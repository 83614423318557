import http from 'hub-http/clients/apiClient';
import { registerMutation, useMutation } from 'data-fetching-client';
import { convertInternalViewToBEFormat, transformBackendViewToInternalFormat } from '../../viewsSearch/dataOperators/backendViews';
import { VIEWS_SEARCH_QUERY_NAME } from '../../viewsSearch/hooks/useFetchViewsSearch';
export const CREATE_SINGLE_VIEW_MUTATION_NAME = 'createSingleView';
const createViewMutationArgsArgument = ['objectTypeId', 'name', 'state', 'teamId', 'private', 'namespaceName'];
export const CreateSingleViewMutation = registerMutation({
  fieldName: CREATE_SINGLE_VIEW_MUTATION_NAME,
  args: createViewMutationArgsArgument,
  fetcher: args => {
    return http.post(`/sales/v4/views?namespace=${args.namespaceName}`, {
      //we ignore this error because we are explicitly omotting the id of the view - this is the only case this should be needed
      data: convertInternalViewToBEFormat(args)
    }).then(result => {
      return transformBackendViewToInternalFormat(result);
    });
  }
});
export const useCreateSingleViewMutation = view => {
  return useMutation(CreateSingleViewMutation, {
    variables: view,
    refetchQueries: [VIEWS_SEARCH_QUERY_NAME],
    awaitRefetchQueries: true
  });
};