import { useQuery, registerQuery } from 'data-fetching-client';
import userInfo from 'hub-http/userInfo';
export const USER_INFO_QUERY_NAME = 'userInfo';
export const UserInfoQuery = registerQuery({
  fieldName: USER_INFO_QUERY_NAME,
  fetcher: userInfo
});
export const useFetchUserInfo = args => {
  return useQuery(UserInfoQuery, {
    skip: args && args.skip
  });
};