import { fromJS } from 'immutable';
function buildWorkflowByState({
  all = [],
  enabled = [],
  cancelled = [],
  disabled = [],
  deleted = []
}) {
  return fromJS({
    all,
    enabled,
    cancelled,
    disabled,
    deleted
  });
}
export const buildWorkflows = (workflowsMap, primaryEmailId, campaignsCancelled = null, filterSourceAppOptions) => {
  const enabled = [];
  const cancelled = [];
  const disabled = [];
  const deleted = [];
  const workflows = workflowsMap.get(primaryEmailId);
  if (!workflows || workflows.isEmpty()) {
    return buildWorkflowByState({});
  }
  const all = workflows.filter(workflow => {
    var _filterSourceAppOptio;
    return (filterSourceAppOptions === null || filterSourceAppOptions === void 0 ? void 0 : filterSourceAppOptions.omit) !== workflow.sourceApp && ((_filterSourceAppOptio = filterSourceAppOptions === null || filterSourceAppOptions === void 0 ? void 0 : filterSourceAppOptions.include) !== null && _filterSourceAppOptio !== void 0 ? _filterSourceAppOptio : workflow.sourceApp) === workflow.sourceApp;
  }).valueSeq();
  all.forEach(workflow => {
    if (workflow.enabled && !(campaignsCancelled && campaignsCancelled.has(workflow.campaignId))) {
      enabled.push(workflow);
    } else if (!workflow.enabled && !workflow.deleted) {
      disabled.push(workflow);
    } else if (workflow.deleted) {
      deleted.push(workflow);
    } else if (campaignsCancelled && campaignsCancelled.has(workflow.campaignId)) {
      cancelled.push(workflow);
    }
  });
  return buildWorkflowByState({
    all,
    enabled,
    cancelled,
    disabled,
    deleted
  });
};