'use es6';

import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { RECEIVE_SUSPENDED_CONTACTS, REQUEST_SUSPENDED_CONTACTS } from 'email-management-lib/refactor/actions/actionTypes';
import IncrementalResult from 'email-management-lib/refactor/models/IncrementalResult';
const initialState = new IncrementalResult();
function suspendedContacts(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_SUSPENDED_CONTACTS:
      if (action.error !== true) {
        return state.mergeDeep(action.payload);
      }
      return state;
    default:
      return state;
  }
}
export default asyncResource(suspendedContacts, {
  requestActions: [REQUEST_SUSPENDED_CONTACTS],
  responseActions: [RECEIVE_SUSPENDED_CONTACTS],
  invalidatingActions: []
});