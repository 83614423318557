import { MARKETING_PRO, SALES_PROFESSIONAL, MARKETING_ENTERPRISE, SALES_ENTERPRISE, SERVICE_PROFESSIONAL, SERVICE_ENTERPRISE, OPERATIONS_PROFESSIONAL, OPERATIONS_ENTERPRISE } from 'self-service-api/constants/UpgradeProducts';
import PortalIdParser from 'PortalIdParser';
import { MARKETING_HUB_PROFESSIONAL, MARKETING_HUB_ENTERPRISE, SALES_HUB_PROFESSIONAL, SALES_HUB_ENTERPRISE, SERVICE_HUB_PROFESSIONAL, SERVICE_HUB_ENTERPRISE, OPERATIONS_HUB_PROFESSIONAL, OPERATIONS_HUB_ENTERPRISE } from 'self-service-api/constants/ApiNames';
export const SURVEY_OPTIONS_BY_UPGRADE_PRODUCT = {
  [MARKETING_PRO]: ['landing-pages', 'email', 'marketing-workflows', 'content-strategy'],
  [SALES_PROFESSIONAL]: ['sequences', 'meetings', 'quotes', 'sales-workflows'],
  [MARKETING_ENTERPRISE]: ['landing-pages', 'email', 'marketing-workflows', 'content-strategy'],
  [SALES_ENTERPRISE]: ['sales-workflows', 'playbooks', 'sequences', 'quotes'],
  [SERVICE_PROFESSIONAL]: ['tickets', 'feedback', 'knowledge-base', 'service-workflows'],
  [SERVICE_ENTERPRISE]: ['tickets', 'feedback', 'knowledge-base', 'service-workflows'],
  [OPERATIONS_PROFESSIONAL]: ['custom-coded-workflow-actions', 'custom-coded-bot-actions', 'webhooks', 'data-quality-automation'],
  [OPERATIONS_ENTERPRISE]: ['datasets', 'snowflake-data-share']
};
export const SURVEY_OPTIONS_BY_API_NAME = {
  [MARKETING_HUB_PROFESSIONAL]: ['landing-pages', 'email', 'marketing-workflows', 'content-strategy'],
  [SALES_HUB_PROFESSIONAL]: ['sequences', 'meetings', 'quotes', 'sales-workflows'],
  [MARKETING_HUB_ENTERPRISE]: ['landing-pages', 'email', 'marketing-workflows', 'content-strategy'],
  [SALES_HUB_ENTERPRISE]: ['sales-workflows', 'playbooks', 'sequences', 'quotes'],
  [SERVICE_HUB_PROFESSIONAL]: ['tickets', 'feedback', 'knowledge-base', 'service-workflows'],
  [SERVICE_HUB_ENTERPRISE]: ['tickets', 'feedback', 'knowledge-base', 'service-workflows'],
  [OPERATIONS_HUB_PROFESSIONAL]: ['custom-coded-workflow-actions', 'custom-coded-bot-actions', 'webhooks', 'data-quality-automation'],
  [OPERATIONS_HUB_ENTERPRISE]: ['datasets', 'snowflake-data-share']
};
export const NEXT_STEPS_SURVEY_UPGRADE_PRODUCTS = [MARKETING_ENTERPRISE, SALES_ENTERPRISE, SERVICE_ENTERPRISE, OPERATIONS_ENTERPRISE];
export const NEXT_STEPS_SURVEY_API_NAMES = [MARKETING_HUB_ENTERPRISE, SALES_HUB_ENTERPRISE, SERVICE_HUB_ENTERPRISE, OPERATIONS_HUB_ENTERPRISE];
export const TRIAL_GUIDE_UPGRADE_PRODUCTS = [MARKETING_PRO, SALES_PROFESSIONAL];
export const TRIAL_GUIDE_API_NAMES = [MARKETING_HUB_PROFESSIONAL, SALES_HUB_PROFESSIONAL];
export const promptTypes = {
  DEMO_PROMPT: 'demo-prompt',
  KB_ARTICLE: 'kb-article' // KB = Knowledge Base
};
export const ContextualPromptConfig = {
  [MARKETING_PRO]: {
    campaigns: {
      href: 'https://knowledge.hubspot.com/campaigns/create-campaigns',
      promptType: promptTypes.KB_ARTICLE
    },
    seo: {
      href: `/trial-guide/${PortalIdParser.get()}/seo-intro?flowId=seo&source=seo-demo-prompt`,
      promptType: promptTypes.DEMO_PROMPT
    },
    social: {
      href: `/trial-guide/${PortalIdParser.get()}/social-intro?flowId=social&source=social-demo-prompt`,
      promptType: promptTypes.DEMO_PROMPT
    },
    workflows: {
      href: `/trial-guide/${PortalIdParser.get()}/reports-dashboard?flowId=nurture-qualify&source=workflows-demo-prompt`,
      promptType: promptTypes.DEMO_PROMPT
    }
  },
  [SALES_PROFESSIONAL]: {
    sequences: {
      href: 'https://knowledge.hubspot.com/sequences/create-and-edit-sequences',
      promptType: promptTypes.KB_ARTICLE
    },
    workflows: {
      href: 'https://knowledge.hubspot.com/workflows/get-started-with-workflows',
      promptType: promptTypes.KB_ARTICLE
    },
    products: {
      href: 'https://knowledge.hubspot.com/deals/how-do-i-use-products',
      promptType: promptTypes.KB_ARTICLE
    },
    quotes: {
      href: 'https://knowledge.hubspot.com/deals/use-quotes',
      promptType: promptTypes.KB_ARTICLE
    },
    forecasting: {
      href: 'https://knowledge.hubspot.com/forecast/set-up-the-forecast-tool',
      promptType: promptTypes.KB_ARTICLE
    }
  },
  [MARKETING_HUB_PROFESSIONAL]: {
    campaigns: {
      href: 'https://knowledge.hubspot.com/campaigns/create-campaigns',
      promptType: promptTypes.KB_ARTICLE
    },
    seo: {
      href: `/trial-guide/${PortalIdParser.get()}/seo-intro?flowId=seo&source=seo-demo-prompt`,
      promptType: promptTypes.DEMO_PROMPT
    },
    social: {
      href: `/trial-guide/${PortalIdParser.get()}/social-intro?flowId=social&source=social-demo-prompt`,
      promptType: promptTypes.DEMO_PROMPT
    },
    workflows: {
      href: `/trial-guide/${PortalIdParser.get()}/reports-dashboard?flowId=nurture-qualify&source=workflows-demo-prompt`,
      promptType: promptTypes.DEMO_PROMPT
    }
  },
  [SALES_HUB_PROFESSIONAL]: {
    sequences: {
      href: 'https://knowledge.hubspot.com/sequences/create-and-edit-sequences',
      promptType: promptTypes.KB_ARTICLE
    },
    workflows: {
      href: 'https://knowledge.hubspot.com/workflows/get-started-with-workflows',
      promptType: promptTypes.KB_ARTICLE
    },
    products: {
      href: 'https://knowledge.hubspot.com/deals/how-do-i-use-products',
      promptType: promptTypes.KB_ARTICLE
    },
    quotes: {
      href: 'https://knowledge.hubspot.com/deals/use-quotes',
      promptType: promptTypes.KB_ARTICLE
    },
    forecasting: {
      href: 'https://knowledge.hubspot.com/forecast/set-up-the-forecast-tool',
      promptType: promptTypes.KB_ARTICLE
    }
  }
};