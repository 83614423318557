import http from 'hub-http/clients/apiClient';
import { useQuery, registerQuery } from 'data-fetching-client';
import { transformBackendViewToInternalFormat } from '../../viewsSearch/dataOperators/backendViews';
import { SupportedCategories } from '../../addView/ViewCategories';
const VIEWS_BY_CATEGORY_QUERY_NAME = 'viewsByCategory';
export const getViewsByCatagoryQueryNameForCategory = category => `${VIEWS_BY_CATEGORY_QUERY_NAME}${category}`;
const VIEWS_BY_CATEGORY_ENDPOINT = 'sales/v4/views/by-category/search';
const OFFSET = 0;
const COUNT = 20;
const QUERY = '';
const getResults = (category, rawViewsByCategoryResponse) => {
  return rawViewsByCategoryResponse[category] ? rawViewsByCategoryResponse[category].results : [];
};
const viewsByCategoryQueryArgs = ['objectTypeId', 'category', 'count', 'query', 'offset'];
const transformRawViewsByCategoryResponse = ({
  results,
  category
}) => {
  const listOfViewsByCategory = getResults(category, results);
  const viewItems = listOfViewsByCategory.map(viewByCategoryItem => transformBackendViewToInternalFormat(viewByCategoryItem));
  const hasMore = results[category] ? results[category].hasMore : false;
  const offset = results[category] ? results[category].offset : 0;
  const total = results[category] ? results[category].total : 0;
  return {
    viewItems,
    hasMore,
    offset,
    total
  };
};
const viewsByCategoryFetcher = viewsByCategoryQuery => {
  return http.post(`${VIEWS_BY_CATEGORY_ENDPOINT}/${viewsByCategoryQuery.objectTypeId}`, {
    query: {
      namespace: viewsByCategoryQuery.namespace
    },
    data: {
      categories: [viewsByCategoryQuery.category],
      count: viewsByCategoryQuery.count,
      offset: viewsByCategoryQuery.offset,
      query: viewsByCategoryQuery.query
    }
  }).then(results => {
    return transformRawViewsByCategoryResponse({
      results,
      category: viewsByCategoryQuery.category
    });
  });
};
export const ViewsByCategorySearchQueries = SupportedCategories.reduce((viewsByCategorySearchQueries, category) => Object.assign({}, viewsByCategorySearchQueries, {
  [category]: registerQuery({
    fieldName: getViewsByCatagoryQueryNameForCategory(category),
    args: viewsByCategoryQueryArgs,
    fetcher: viewsByCategoryFetcher
  })
}), {});
export const useFetchViewsByCategory = ({
  objectTypeId,
  namespace,
  category,
  query = QUERY,
  count = COUNT,
  offset = OFFSET
}) => {
  return useQuery(ViewsByCategorySearchQueries[category], {
    variables: {
      objectTypeId,
      namespace,
      category,
      query,
      count,
      offset
    },
    notifyOnNetworkStatusChange: true
  });
};