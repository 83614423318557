'use es6';

import { List, Map as ImmutableMap } from 'immutable';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { FETCH_LANDING_PAGES, FETCH_LANDING_PAGES_REQUEST } from 'email-management-lib/refactor/actions/actionTypes';
import LandingPage from 'email-management-lib/refactor/models/LandingPage';
const initialState = ImmutableMap();
function workflows(state = initialState, action) {
  switch (action.type) {
    case FETCH_LANDING_PAGES:
      if (action.error !== true) {
        return List(action.payload.map(LandingPage.from));
      }
      return state;
    default:
      return state;
  }
}
export default asyncResource(workflows, {
  requestActions: [FETCH_LANDING_PAGES_REQUEST],
  responseActions: [FETCH_LANDING_PAGES],
  invalidatingActions: []
});