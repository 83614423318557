import I18n from 'I18n';
function typedToLowercase(str) {
  return str.toLowerCase();
}
export function getNextPeriodDate({
  date,
  period
}) {
  return date.clone().add(1, typedToLowercase(period)).startOf(typedToLowercase(period));
}
export function getPreviousPeriodDate({
  date,
  period
}) {
  return date.clone().subtract(1, typedToLowercase(period)).startOf(typedToLowercase(period));
}

/**
 * Facade for converting timestamps into Moments
 * Use this instead of calling I18n.moment directly so that we can
 * more safely upgrade all dates to portalTz, mock moment for tests, etc
 * @param displayTimestamp milliseconds from epoch
 * @returns A Moment object
 */
export function getCalendarMomentFromTimestamp(displayTimestamp) {
  return I18n.moment(displayTimestamp);
}