import { useState, useCallback } from 'react';
import toolAccessApi from '../data/toolAccessApi';
import { ACTIVE } from '../constants/toolAccessRequestStatus';
import { trackRequestCreated } from '../utils/usageTracker';
import { reportRequestException } from '../utils/sentryUtils';
export const useToolAccess = args => {
  const {
    scope,
    toolName
  } = args;
  const [state, setState] = useState({
    error: null,
    // Fetch error
    loading: false,
    modalOpen: false,
    request: null,
    requestCreated: false,
    // flag for when user has submitted a request in this session
    scope,
    toolName
  });
  const openModal = () => {
    setState(prevState => Object.assign({}, prevState, {
      modalOpen: true
    }));
  };
  const closeModal = () => {
    setState(prevState => Object.assign({}, prevState, {
      modalOpen: false
    }));
  };
  const fetchRequest = useCallback(() => {
    if (state.loading === true || state.request !== null) {
      return;
    }
    setState(prevState => Object.assign({}, prevState, {
      loading: true,
      error: null
    }));
    toolAccessApi.fetchRequestByScope(scope).then(response => {
      setState(prevState => Object.assign({}, prevState, {
        toolName: toolName || response.toolName,
        loading: false,
        request: response.activeToolAccessRequest,
        status: response.status
      }));
    }).catch(error => {
      setState(prevState => Object.assign({}, prevState, {
        error: error.responseJSON || true,
        loading: false
      }));
      reportRequestException('fetchRequest', error, {
        scope
      });
    });
  }, [scope, state.loading, state.request, toolName]);
  const createRequest = useCallback((data, onComplete, onError) => {
    setState(prevState => Object.assign({}, prevState, {
      loading: true
    }));
    const {
      message,
      approver
    } = data;
    toolAccessApi.createRequest({
      scope,
      approverUserId: approver.id,
      message
    }).then(response => {
      const {
        createdAt,
        id,
        expiresAt,
        portalId,
        roleName
      } = response;
      const inferredApprover = Object.assign({}, approver, {
        userId: approver.id
      });
      const request = {
        approver: inferredApprover,
        createdAt,
        id,
        expiresAt,
        portalId
      };
      setState(prevState => Object.assign({}, prevState, {
        loading: false,
        request,
        requestCreated: true,
        status: ACTIVE
      }));
      trackRequestCreated({
        roleName,
        scope
      });
      if (typeof onComplete === 'function') {
        onComplete({
          message,
          approver: inferredApprover
        });
      }
    }).catch(error => {
      if (typeof onError === 'function') {
        onError(error);
      }
      reportRequestException('createRequest', error, {
        scope
      });
    }).finally(() => setState(prevState => Object.assign({}, prevState, {
      loading: false
    })));
  }, [scope]);
  return Object.assign({
    openModal,
    closeModal,
    createRequest,
    fetchRequest
  }, state);
};