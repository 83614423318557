'use es6';

import { LOAD_FEEDBACK_CLIENT, LOAD_FEEDBACK_CONTACT, LOAD_FEEDBACK_SURVEY } from 'EmailData/actions/actionTypes';
import { Map as ImmutableMap } from 'immutable';
const INITIAL_STATE = ImmutableMap({
  client: undefined,
  contact: undefined,
  hasLoaded: false
});
export function feedbackClient(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD_FEEDBACK_CLIENT:
      return state.set('client', action.client);
    case LOAD_FEEDBACK_CONTACT:
      return state.set('contact', action.contact);
    case LOAD_FEEDBACK_SURVEY:
      return state.set('hasLoaded', true);
    default:
      return state;
  }
}