import { TEAM, USER
// @ts-expect-error not migrated to TS yet
} from 'reference-resolvers/constants/ReferenceObjectTypes';
import { userInfoSync } from 'hub-http/userInfo';
import PortalIdParser from 'PortalIdParser';
import Raven from 'raven-js';
import http from 'hub-http/clients/apiClient';
const portalId = PortalIdParser.get();
const COSEMAIL_URI = 'cosemail/v1/emails';
const COSEMAIL_EXPORT_URI = 'cosemail/v1/export';
const COSEMAIL_URI_SUFFIX_NO_TIMESTAMP = '/update-without-timestamping';
const EMAIL_URI = 'email/v1';
export function forceCancel(id) {
  const cancelURI = `${COSEMAIL_URI}/${id}/force-cancel`;
  return http.post(cancelURI, {
    data: {
      action: 'force-cancel'
    }
  });
}
export function update(id, data, ignoreTimestamp) {
  const updateURI = `${COSEMAIL_URI}/${id}`;
  const uri = ignoreTimestamp ? `${updateURI}${COSEMAIL_URI_SUFFIX_NO_TIMESTAMP}` : updateURI;
  return http.put(uri, {
    data
  });
}
export function assignPartitions(ids, data) {
  const permissions = {
    [USER]: data.users,
    [TEAM]: data.teams
  };
  return http.put(`${COSEMAIL_URI}/assign-permissions`, {
    data: {
      objectIds: ids,
      permissionRefIdsByType: permissions
    }
  });
}
export function assignPartitionsAll(data) {
  const permissions = {
    [USER]: data.users,
    [TEAM]: data.teams
  };
  return http.put(`${COSEMAIL_URI}/assign-permissions/all`, {
    data: {
      permissionRefIdsByType: permissions
    }
  });
}
export function recipientsExport(url, data) {
  const email = userInfoSync().user.email;
  const EXPORT_DEFAULTS = {
    query: {
      portalId
    },
    data: {
      portalId,
      email
    }
  };
  const dataWithDefaults = Object.assign({}, data, EXPORT_DEFAULTS.data);
  const apiOptions = Object.assign({}, EXPORT_DEFAULTS, {
    data: dataWithDefaults
  });
  return http.post(url, apiOptions).catch(e => {
    if (e.status !== 409) {
      Raven.captureException(e);
    }
  });
}
export function digestRecipientsExport(data) {
  const exportURI = `${EMAIL_URI}/export-events/digest`;
  return recipientsExport(exportURI, data);
}
export function advancedRecipientsExport(data) {
  const exportURI = `${EMAIL_URI}/export-events/campaign`;
  return recipientsExport(exportURI, data);
}
export function performanceExport(data) {
  const exportURI = `${COSEMAIL_EXPORT_URI}/details-performance`;
  return http.post(exportURI, {
    data
  }).catch(e => {
    Raven.captureException(e);
    return e;
  });
}
export function cancelWinningSend(id) {
  const cancelUrl = `${COSEMAIL_URI}/${id}/cancel-ab-winner`;
  return http.post(cancelUrl);
}