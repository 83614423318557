import { FILTERS } from 'email-health-ui-lib/filters/constants';
import { buildStateKey } from 'email-health-ui-lib/filters/utils';
import { FETCH_HEALTH_HISTORY, FETCH_HEALTH_HISTORY_REQUEST } from 'email-health-ui-lib/metrics/actions/actionTypes';
import { getFailedState, getRequestedState, getSucceededState } from 'email-health-ui-lib/shared/models/AsyncDataContainer';
import { Map as ImmutableMap } from 'immutable';
export const INITIAL_STATE = ImmutableMap();
export default function healthHistory(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_HEALTH_HISTORY_REQUEST:
      return state.set(getStateKeyFromAction(action), getRequestedState());
    case FETCH_HEALTH_HISTORY:
      if (action.error) {
        return state.set(getStateKeyFromAction(action), getFailedState());
      }
      return state.set(getStateKeyFromAction(action), getSucceededState(action.payload.data));
    default:
      return state;
  }
}
function getStateKeyFromAction({
  selectedFilters
}) {
  return buildStateKey(selectedFilters[FILTERS.BUSINESS_UNIT], selectedFilters[FILTERS.EMAIL_TYPE], selectedFilters[FILTERS.CAMPAIGN], selectedFilters[FILTERS.SUBSCRIPTIONS]);
}