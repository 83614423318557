import enviro from 'enviro';
import { createTracker } from 'usage-tracker';
import { CRM, SETTINGS, LISTS, TASKS } from '../constants/namespaces';
import { setPrimaryTracker } from 'usage-tracker-container';
import once from '../utils/once';
import { getTracker as getListsTracker, getTrackerSendImmediate as getListsTrackerSendImmediate } from '../loggers/ListsLogger';
import { getTracker as getSettingsTracker, getTrackerSendImmediate as getSettingsTrackerSendImmediate } from '../loggers/SettingsLogger';
import { getTracker as getTasksTracker, getTrackerSendImmediate as getTasksTrackerSendImmediate } from '../trackers/TasksTracker';
import events from 'customer-data-tracking/tracking/crmEvents/events.yaml';
const TRACKER = 'tracker';
const SEND_IMMEDIATE = 'trackerSendImmediate';
export const getTracker = once(() => {
  return createTracker({
    events,
    properties: {
      namespace: CRM
    },
    onError(err) {
      console.error(err);
    },
    lastKnownEventProperties: ['screen', 'subscreen'],
    debug: () => enviro.debug('customer-data-tracker')
  });
});
const getTrackerSendImmediate = once(() => getTracker().clone({
  bypassPool: true
}));
export const trackerNamespaces = [CRM, SETTINGS, LISTS, TASKS];
export const getTrackers = once(() => {
  return {
    [CRM]: {
      [TRACKER]: getTracker,
      [SEND_IMMEDIATE]: getTrackerSendImmediate
    },
    [SETTINGS]: {
      [TRACKER]: getSettingsTracker,
      [SEND_IMMEDIATE]: getSettingsTrackerSendImmediate
    },
    [LISTS]: {
      [TRACKER]: getListsTracker,
      [SEND_IMMEDIATE]: getListsTrackerSendImmediate
    },
    [TASKS]: {
      [TRACKER]: getTasksTracker,
      [SEND_IMMEDIATE]: getTasksTrackerSendImmediate
    }
  };
});
const CrmUsageTracker = {
  trackerProperties: undefined,
  init: properties => {
    if (properties) CrmUsageTracker.trackerProperties = properties;
    setPrimaryTracker(getTracker());
  },
  track: (evt, evtData, options = {}) => {
    const {
      sendImmediate = false,
      namespace = CRM
    } = options;
    const tracker =
    // @ts-expect-error needs narrower type
    getTrackers()[namespace][sendImmediate ? SEND_IMMEDIATE : TRACKER]();
    if (CrmUsageTracker.trackerProperties) {
      tracker.setProperties(CrmUsageTracker.trackerProperties);
    }
    tracker.track(evt, evtData);
  }
};
export default CrmUsageTracker;