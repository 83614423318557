import http from 'hub-http/clients/apiClient';
export const defaultPinnedViewsApi = {
  get: ({
    objectTypeId,
    namespaceName
  }) => {
    return http.get(
    //?fallbackToGlobalDefault=true will make this api return the hardcoded defaults if no custom changes have been made by the user
    `/sales/v4/views/${objectTypeId}/pinned/hub?fallbackToGlobalDefault=true&namespace=${namespaceName}`).then(result => result);
  },
  put: ({
    objectTypeId,
    viewIds,
    namespaceName
  }) => {
    return http.put(`/sales/v4/views/${objectTypeId}/pinned/hub?namespace=${namespaceName}`, {
      data: viewIds
    }).then(result => result);
  }
};