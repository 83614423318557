const LOCAL_SETTINGS_PREFIX = 'LocalSettings:Properties:';
function getPrefixedKey(key) {
  return `${LOCAL_SETTINGS_PREFIX}${key}`;
}
export function setSetting(key, value) {
  if (!window.localStorage) {
    return undefined;
  }
  try {
    window.localStorage.setItem(getPrefixedKey(key), value);
    return value;
  } catch (error) {
    return undefined;
  }
}
export function getSetting(key) {
  if (!window.localStorage) {
    return undefined;
  }
  try {
    const entry = window.localStorage.getItem(getPrefixedKey(key));
    return entry;
  } catch (error) {
    return undefined;
  }
}