import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["isRetail", "isAssignable", "repInfo"];
import Raven from 'raven-js';
import { formUrl } from 'self-service-api/core/utilities/links';
import { getUserInfo } from 'ui-addon-upgrades/_core/common/api/getUserInfo';
import { submitForm } from 'ui-addon-upgrades/_core/common/api/submitForm';
import { createPqlFormData } from 'ui-addon-upgrades/_core/common/data/pqlData/createPqlFormData';
import * as tracker from 'ui-addon-upgrades/_core/common/eventTracking/tracker';
import wrapWithErrorMonitoring from 'ui-addon-upgrades/_core/common/reliability/wrapWithErrorMonitoring';
import { getPqlFormAttributes } from 'ui-addon-upgrades/_core/pql/getPqlFormAttributes';
import { getRepName, getUpgradeDataTrackingProperties } from 'ui-addon-upgrades/ums/utils';
import { trackPqlFailure } from '../utils/metricUtils';
// to use only when additional PQL information is being submitted. i.e. email or phone number
export const submitAdditionalPqlForm = (pqlData, options = {}) => {
  const formAttributes = getPqlFormAttributes();
  const formEndpoint = formUrl(formAttributes);
  return getUserInfo().then(({
    portal,
    user
  }) => {
    const metaData = {
      timestamp: Date.now()
    };
    const pqlFormData = createPqlFormData({
      metaData,
      pqlData,
      user,
      portal,
      options
    });
    return submitForm(formEndpoint, pqlFormData);
  }).catch(error => {
    Raven.captureException(error);
  });
};
export const submitPql = wrapWithErrorMonitoring('submitPql', pqlData => {
  const {
      isRetail,
      isAssignable,
      repInfo
    } = pqlData,
    upgradeData = _objectWithoutPropertiesLoose(pqlData, _excluded);
  const formAttributes = getPqlFormAttributes();
  const formEndpoint = formUrl(formAttributes);
  return getUserInfo().then(({
    portal,
    user
  }) => {
    const metaData = {
      timestamp: Date.now()
    };
    const pqlFormData = createPqlFormData({
      metaData,
      pqlData,
      user,
      portal
    });
    return submitForm(formEndpoint, pqlFormData);
  }).then(() => {
    // ********** PUBLIC EVENT **********
    // Public Events help teams across HubSpot automate work and customize experiences based on user actions.
    // Speak with #product-insight and your PM before any shipping any changes to this event incl. event name, properties, values, and when it occurs.
    // Read more about Public Events on the wiki: https://wiki.hubspotcentral.net/display/PM/Public+Events+-+Amplitude+events+ready+for+HubSpot+team+use+and+automation
    tracker.trackBeforeUnload('pqlInteraction', Object.assign({
      action: 'submission succeeded'
    }, getUpgradeDataTrackingProperties(upgradeData), {
      isRetail,
      isAssignable,
      repName: getRepName(repInfo)
    }));
  }).catch(error => {
    // ********** PUBLIC EVENT **********
    // Public Events help teams across HubSpot automate work and customize experiences based on user actions.
    // Speak with #product-insight and your PM before any shipping any changes to this event incl. event name, properties, values, and when it occurs.
    // Read more about Public Events on the wiki: https://wiki.hubspotcentral.net/display/PM/Public+Events+-+Amplitude+events+ready+for+HubSpot+team+use+and+automation
    tracker.trackBeforeUnload('pqlInteraction', Object.assign({
      action: 'submission failed'
    }, getUpgradeDataTrackingProperties(upgradeData), {
      isRetail,
      isAssignable,
      repName: getRepName(repInfo)
    }));
    trackPqlFailure(upgradeData.app);
    throw error;
  });
});