import http from 'hub-http/clients/apiClient';
import { useQuery, registerQuery } from 'data-fetching-client';
import { useMemo } from 'react';
import formatName from 'I18n/utils/formatName';
const GET_USER_NAMES_FIELD_NAME = 'userNames';
const GET_USER_NAMES = registerQuery({
  fieldName: GET_USER_NAMES_FIELD_NAME,
  fetcher({
    uniqueIds
  }) {
    return http.get(`app-users/v1/users/batch?${uniqueIds.map(userId => `id=${userId}`).join('&')}`);
  }
});
function createNameIdMap(users) {
  return users.reduce((acc, user) => {
    acc[user.id] = formatName(user);
    return acc;
  }, {});
}
export default function useFetchUserNames(userIds) {
  const uniqueIds = useMemo(() => [...new Set(userIds)], [userIds]);
  const {
    data,
    error,
    loading
  } = useQuery(GET_USER_NAMES, {
    variables: {
      uniqueIds
    },
    skip: uniqueIds.length === 0
  });
  return useMemo(() => ({
    data: data && GET_USER_NAMES_FIELD_NAME in data && Array.isArray(data[GET_USER_NAMES_FIELD_NAME]) ? createNameIdMap(data[GET_USER_NAMES_FIELD_NAME]) : [],
    error,
    loading
  }), [data, error, loading]);
}