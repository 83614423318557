'use es6';

import Raven from 'raven-js';
import Email from 'EmailData/models/Email';
import EmailType from 'EmailData/enums/EmailType';
import AbAutomatedEmailDetail from 'EmailData/models/details/AbAutomatedEmailDetail';
import AbEmailDetail from 'EmailData/models/details/AbEmailDetail';
import AutomatedEmailDetail from 'EmailData/models/details/AutomatedEmailDetail';
import BatchEmailDetail from 'EmailData/models/details/BatchEmailDetail';
import BlogRssEmailDetail from 'EmailData/models/details/BlogRssEmailDetail';
import FollowUpEmailDetail from 'EmailData/models/details/FollowUpEmailDetail';
import LocalTimeEmailDetail from 'EmailData/models/details/LocalTimeEmailDetail';
import SmtpTokenEmailDetail from 'EmailData/models/details/SmtpTokenEmailDetail';
export default class EmailDetailsFactory {
  static fromDetails(json) {
    switch (json.primaryEmail.emailType) {
      case EmailType.AB_EMAIL:
        return AbEmailDetail.from(json);
      case EmailType.AUTOMATED_AB_EMAIL:
        return AbAutomatedEmailDetail.from(json);
      case EmailType.RSS_EMAIL:
      case EmailType.BLOG_EMAIL:
      case EmailType.RSS_EMAIL_CHILD:
      case EmailType.BLOG_EMAIL_CHILD:
        return BlogRssEmailDetail.from(json);
      case EmailType.LOCALTIME_EMAIL:
        return LocalTimeEmailDetail.from(json);
      case EmailType.FOLLOWUP_EMAIL:
        return FollowUpEmailDetail.from(json);
      case EmailType.OPTIN_EMAIL:
      case EmailType.OPTIN_FOLLOWUP_EMAIL:
      case EmailType.AUTOMATED_EMAIL:
      case EmailType.RESUBSCRIBE_EMAIL:
      case EmailType.SINGLE_SEND_API:
      case EmailType.MARKETING_SINGLE_SEND_API:
      case EmailType.LEADFLOW_EMAIL:
      case EmailType.FEEDBACK_CES_EMAIL:
      case EmailType.FEEDBACK_NPS_EMAIL:
      case EmailType.FEEDBACK_CUSTOM_EMAIL:
      case EmailType.FEEDBACK_CUSTOM_SURVEY_EMAIL:
      case EmailType.TICKET_EMAIL:
      case EmailType.MEMBERSHIP_PASSWORD_SAVED_EMAIL:
      case EmailType.MEMBERSHIP_PASSWORD_RESET_EMAIL:
      case EmailType.MEMBERSHIP_REGISTRATION_EMAIL:
      case EmailType.MEMBERSHIP_FOLLOW_UP_EMAIL:
      case EmailType.MEMBERSHIP_PASSWORDLESS_AUTH_EMAIL:
      case EmailType.MEMBERSHIP_VERIFICATION_EMAIL:
        return AutomatedEmailDetail.from(json);
      case EmailType.BATCH_EMAIL:
        return BatchEmailDetail.from(json);
      default:
        // Coerce unknown type to batch to attempt to display something
        Raven.captureException(new Error('Unknown email type, coercing as batch.'), {
          extra: {
            primaryEmailId: json.primaryEmail.id,
            type: json.primaryEmail.emailType
          }
        });
        json.primaryEmail.emailType = EmailType.BATCH_EMAIL;
        return BatchEmailDetail.from(json);
    }
  }
  static patchPrimaryEmail(json, emailDetailsState) {
    let patchedJson = json;
    if (!json.primaryEmailCampaignId) {
      const primaryEmailCampaignId = emailDetailsState && emailDetailsState.primaryEmail && emailDetailsState.primaryEmail.primaryEmailCampaignId;
      if (primaryEmailCampaignId) {
        patchedJson = Object.assign({}, json, {
          primaryEmailCampaignId
        });
      }
    }
    return emailDetailsState.set('primaryEmail', Email.from(patchedJson));
  }
  static fromSmtpToken(json) {
    return SmtpTokenEmailDetail.from(json);
  }
}