'use es6';

import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { RESET_EMAIL_DETAILS } from 'EmailData/actions/actionTypes';
import { DETAILS_MARK_STATISTICS_DIRTY, FETCH_OVER_TIME_STATISTICS, FETCH_OVER_TIME_STATISTICS_REQUEST, SELECT_TIME_RANGE } from 'email-management-lib/refactor/actions/actionTypes';
import StatisticsOverTime from 'email-management-lib/refactor/models/stats/StatisticsOverTime';
const initialState = StatisticsOverTime.from({});
function overTimeStatistics(state = initialState, action) {
  switch (action.type) {
    case FETCH_OVER_TIME_STATISTICS:
      {
        if (action.error === true) {
          return state;
        }
        return StatisticsOverTime.from(action.payload);
      }
    case RESET_EMAIL_DETAILS:
      return initialState;
    default:
      return state;
  }
}
export default asyncResource(overTimeStatistics, {
  requestActions: [FETCH_OVER_TIME_STATISTICS_REQUEST],
  responseActions: [FETCH_OVER_TIME_STATISTICS],
  invalidatingActions: [RESET_EMAIL_DETAILS, SELECT_TIME_RANGE, DETAILS_MARK_STATISTICS_DIRTY]
});