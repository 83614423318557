import Raven from 'raven-js';
import { REQUEST_PORTAL_STATUS, RECEIVE_PORTAL_STATUS, REQUEST_PORTAL_DISMISS_STATUS, RECEIVE_PORTAL_DISMISS_STATUS, RECEIVE_UPDATE_PORTAL_DISMISS_STATUS, RECEIVE_SUSPENSION_APPEAL, REQUEST_UPDATE_PORTAL_DISMISS_STATUS } from '../actions/actionTypes';
import portalStatusApi from '../api/portalStatus';
export function fetchPortalStatus() {
  return dispatch => {
    dispatch({
      type: REQUEST_PORTAL_STATUS
    });
    portalStatusApi.fetch().then(response => {
      dispatch({
        type: RECEIVE_PORTAL_STATUS,
        payload: response
      });
    }, err => {
      dispatch({
        type: RECEIVE_PORTAL_STATUS,
        error: true,
        payload: err
      });
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}
export function fetchHealthDismissStatus() {
  return dispatch => {
    dispatch({
      type: REQUEST_PORTAL_DISMISS_STATUS
    });
    portalStatusApi.fetchDismissStatus().then(response => {
      let dismissedState = {};
      if (response) {
        dismissedState = JSON.parse(response);
      }
      dispatch({
        type: RECEIVE_PORTAL_DISMISS_STATUS,
        payload: dismissedState
      });
    }, err => {
      dispatch({
        type: RECEIVE_PORTAL_DISMISS_STATUS,
        error: true,
        payload: err
      });
    }).catch(e => Raven.captureException(e));
  };
}
export function updateHealthDismissStatus(healthStatus, dismissed = true) {
  return dispatch => {
    const newDismissState = {
      level: healthStatus.getCurrentState(),
      timestamp: healthStatus.updatedAt,
      dismissed
    };
    dispatch({
      type: REQUEST_UPDATE_PORTAL_DISMISS_STATUS
    });
    portalStatusApi.updateDismissStatus(newDismissState).then(response => {
      const dismissedState = JSON.parse(response);
      dispatch({
        type: RECEIVE_UPDATE_PORTAL_DISMISS_STATUS,
        payload: dismissedState
      });
    }, err => {
      dispatch({
        type: RECEIVE_UPDATE_PORTAL_DISMISS_STATUS,
        error: true,
        payload: err
      });
    }).catch(e => Raven.captureException(e));
  };
}
export function suspensionAppealError() {
  return dispatch => {
    dispatch({
      type: RECEIVE_SUSPENSION_APPEAL,
      error: true
    });
  };
}
export function suspensionAppealSent(response) {
  return dispatch => {
    dispatch({
      type: RECEIVE_SUSPENSION_APPEAL,
      payload: response
    });
  };
}