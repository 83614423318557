import identity from 'transmute/identity';
import { InMemoryCache } from '../../cache/InMemoryCache';
import { fetchConditionalPropertyOptionsRules } from './api';
export const makeConditionalPropertyOptionsRulesCache = () => new InMemoryCache('ConditionalPropertyOptionRulesCache', fetchConditionalPropertyOptionsRules, identity);

// CPO rules are not expected to change during the lifetime of a single page in
// the app and are safe to durably cache
export const cache = makeConditionalPropertyOptionsRulesCache();

// exported for unit test usage only
export const _resetCache = () => {
  cache.clear();
};