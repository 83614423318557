import isEqual from 'hs-lodash/isEqual';
import { BULK_SELECTION_MODE } from 'framework-listing-lib/constants/table';
function exists(selectedObjects, objectId) {
  return selectedObjects.some(object => object.objectId === objectId);
}
function removeDuplicateObjects(selectedObjects) {
  const arr = [];
  return selectedObjects.reduce((acc, selectedObject) => {
    if (arr.includes(selectedObject.objectId)) {
      return acc;
    }
    arr.push(selectedObject.objectId);
    acc.push(selectedObject);
    return acc;
  }, []);
}
function removeDuplicatedIds(objectIds) {
  return [...new Set(objectIds)];
}

/**
 * Selects @crmObject and its children when enabled for nested content.
 * @returns new array of selected CRM objects
 */
export function selectCrmObject({
  crmObject,
  parentChildObjects,
  selectAllChildren,
  selectedObjects,
  selectTopLevelRowOnNestedRowSelect,
  status,
  topLevelRow
}) {
  if (!exists(selectedObjects, crmObject.objectId)) {
    selectedObjects.push(crmObject);
  }

  // select parent crmObject
  const isTopLevelRowSelected = Boolean(topLevelRow && exists(selectedObjects, topLevelRow.objectId));
  if (!isTopLevelRowSelected && selectTopLevelRowOnNestedRowSelect && topLevelRow) {
    selectedObjects.push(topLevelRow);
  }

  /**
   * - if top-level row (isRegisteredTopLevelRow is true), select all nested rows that have been registered from nested content.
   * - if nested row (isRegisteredTopLevelRowFromNestedRow is true), select all other nested rows that belong to the same top-level row.
   */
  const isRegisteredTopLevelRow = (crmObject.objectId in parentChildObjects);
  const isRegisteredTopLevelRowFromNestedRow = Boolean(topLevelRow && topLevelRow.objectId in parentChildObjects);
  const shouldSelectAllNestedRows = selectAllChildren && (isRegisteredTopLevelRow || isRegisteredTopLevelRowFromNestedRow);
  if (shouldSelectAllNestedRows) {
    const topLevelRowId = isRegisteredTopLevelRow ? crmObject.objectId : topLevelRow.objectId;
    parentChildObjects[topLevelRowId].forEach(childCrmObject => {
      if (!exists(selectedObjects, childCrmObject.objectId)) {
        const childSelectedObjects = selectCrmObject({
          crmObject: childCrmObject,
          parentChildObjects,
          selectAllChildren,
          selectedObjects,
          selectTopLevelRowOnNestedRowSelect,
          status,
          topLevelRow
        });
        selectedObjects = [].concat(selectedObjects, childSelectedObjects);
      }
    });
  }
  return removeDuplicateObjects(selectedObjects);
}

/**
 * Unselects @crmObject and its children when enabled for nested content.
 * @returns new array of selected CRM objects
 */
export function unselectCrmObject({
  crmObject,
  parentChildObjects,
  selectedObjects,
  status,
  topLevelRow,
  unselectAllChildren,
  unselectTopLevelRowOnNestedRowUnselect
}) {
  let newSelectedObjects = selectedObjects.filter(object => {
    if (object.objectId === crmObject.objectId) {
      return false;
    } else if (topLevelRow && object.objectId === topLevelRow.objectId) {
      return false;
    }
    return true;
  });
  const isRegisteredTopLevelRow = (crmObject.objectId in parentChildObjects);
  const isRegisteredTopLevelRowFromNestedRow = Boolean(topLevelRow && topLevelRow.objectId in parentChildObjects);
  const shouldDeselectAllNestedRows = unselectAllChildren && (isRegisteredTopLevelRow || isRegisteredTopLevelRowFromNestedRow);
  if (shouldDeselectAllNestedRows) {
    const topLevelRowId = isRegisteredTopLevelRow ? crmObject.objectId : topLevelRow.objectId;
    parentChildObjects[topLevelRowId].forEach(childCrmObject => {
      if (exists(selectedObjects, childCrmObject.objectId)) {
        newSelectedObjects = unselectCrmObject({
          crmObject: childCrmObject,
          parentChildObjects,
          selectedObjects: newSelectedObjects,
          status,
          topLevelRow,
          unselectAllChildren,
          unselectTopLevelRowOnNestedRowUnselect
        });
      }
    });
  }
  return removeDuplicateObjects(newSelectedObjects);
}
export function updateCountsAndSelectionMode(selectedObjects, data) {
  const selectedObjectsCount = selectedObjects.length;
  const selectedIds = selectedObjects.map(selectedObject => selectedObject.objectId);
  const objectIdsOnPage = data ? data.results.map(object => object.objectId) : [];
  const isAllOnPage = selectedIds.length && objectIdsOnPage.length && objectIdsOnPage.every(id => selectedIds.includes(id));
  let selectionMode = BULK_SELECTION_MODE.NONE;
  if (isAllOnPage) {
    selectionMode = BULK_SELECTION_MODE.ALL_ON_PAGE;
  } else if (selectedObjects.length === 0) {
    selectionMode = BULK_SELECTION_MODE.NONE;
  } else {
    selectionMode = BULK_SELECTION_MODE.PARTIAL;
  }
  return {
    selectedObjectsCount,
    selectedIds,
    selectionMode
  };
}
function getCheckboxSelector(objectId) {
  return `[data-test-id="checkbox-select-row-${objectId}"] label input[type="checkbox"]`;
}
export function toggleCheckbox({
  objectId,
  targetValue
}) {
  const checkboxEl = document.querySelector(getCheckboxSelector(objectId));
  if (checkboxEl && checkboxEl.checked !== targetValue) {
    checkboxEl.click();
  }
}
export function getShouldSelectNestedRows({
  topLevelRow,
  rowProps
}) {
  if (!topLevelRow || !rowProps || !rowProps.selectChildRowsOnParentSelect) {
    return false;
  }
  return rowProps.selectChildRowsOnParentSelect({
    crmObject: topLevelRow
  });
}
export function getShouldSelectTopLevelRow({
  rowProps
}) {
  return Boolean(rowProps && rowProps.selectTopLevelRowOnNestedRowSelect);
}
export function getShouldUnselectTopLevelRow({
  rowProps
}) {
  return Boolean(rowProps && rowProps.unselectTopLevelRowOnNestedRowUnselect);
}

/**
 * Returns whether the list of nested row ids for @topLevelRow should be selected or not.
 * In case at least one row is being currently selected (present in @selectedIds), this method should return an empty list.
 * This it to ensure we're not re-selecting rows that shouldn't be selected.
 */
export function getNestedRowIdsToBeSelected({
  nestedRows,
  selectedIds
}) {
  const nestedRowsIds = nestedRows.map(crmObject => crmObject.objectId);
  const atLeastOneNestedRowIsSelected = nestedRowsIds.some(id => selectedIds.includes(id));
  return atLeastOneNestedRowIsSelected ? [] : nestedRowsIds;
}

/**
 * Checks whether we should select a top-level row + all other nested rows in case a single nested row is selected.
 * It should return the list of all valid ids when the top-level row is not selected and only one nested row is selected.
 */
export function getRowIdsToBeSelectedFromNestedRow({
  getNestedRows,
  selectedIds,
  topLevelRow
}) {
  if (!topLevelRow) {
    return [];
  }
  const isTopLevelRowSelected = selectedIds.includes(topLevelRow.objectId);
  if (isTopLevelRowSelected) {
    return [];
  }
  const allNestedRowIds = topLevelRow ? getNestedRows(topLevelRow.objectId).map(nestedRow => nestedRow.objectId) : [];
  if (allNestedRowIds.length === 0) {
    return [];
  }
  const selectedNestedRowIds = allNestedRowIds.filter(nestedRowId => selectedIds.includes(nestedRowId));
  if (selectedNestedRowIds.length === 1) {
    return [topLevelRow.objectId, ...allNestedRowIds];
  }
  return [];
}

/**
 * Checks whether we should unselect a top-level row + all other nested rows in case a single nested row is unselected.
 * It should return the list of all valid ids when the top-level row is selected and only one nested row is unselected.
 * The list of returned ids should be unselected in the UI.
 */
export function getRowIdsToBeUnselectedFromNestedRow({
  getNestedRows,
  selectedIds,
  topLevelRow
}) {
  if (!topLevelRow) {
    return [];
  }
  const isTopLevelRowSelected = selectedIds.includes(topLevelRow.objectId);
  if (!isTopLevelRowSelected) {
    return [];
  }
  const allNestedRowIds = topLevelRow ? getNestedRows(topLevelRow.objectId).map(nestedRow => nestedRow.objectId) : [];
  if (allNestedRowIds.length === 0) {
    return [];
  }
  const selectedNestedRowIds = allNestedRowIds.filter(nestedRowId => selectedIds.includes(nestedRowId));
  if (selectedNestedRowIds.length !== allNestedRowIds.length) {
    return [topLevelRow.objectId, ...allNestedRowIds];
  }
  return [];
}

/**
 * This method is checking if we need to select nested rows after selecting a top-level row present in @selectedIds.
 * We check this via @selectChildRowsOnParentSelect from @rowProps (this is an optional configuration).
 *
 * In case NONE of its nested rows are present in @selectedIds (that's possible to happen, especially on first render):
 *
 * - We need to manually select each nested row's checkbox (we don't get this from framework-data-table).
 * - By doing so, it will re-trigger ListingLibBulkActionsComponent provided to framework-data-table and this method (handleNestedRowsSelection) will be called again.
 * - The exception for this rule is:
 *   - 1 - User selects a top-level row with two nested rows -> We manually select the two nested rows
 *   - 2 - User unselects the first nested row
 *   - 3 - We keep the top-level row + the second nested row selected
 *   - 4 - User unselects the second top-level row -> at this point, this method will be triggered only with the top-level row id
 *   - 5 - But since it has been previously selected, we don't re-select nested rows again, otherwise users will never be able to unselect a top-level row.
 *
 * In case AT LEAST ONE nested row is present in @selectedIds:
 *
 * - We SHOULD NOT manually check those checkboxes.
 * - This happens usually when the user does the following:
 *   - 1 - User selects a top-level row with two nested rows -> We manually select the two nested rows
 *   - 2 - User unselects the first nested row (same applies if unselecting only the second nested row)
 *   - 3 - We need to keep the top-level row + the second row selected
 */
export function handleNestedRowsSelection({
  getIsTopLevelRow,
  getNestedRows,
  getRow,
  previousIds,
  rowProps,
  selectedIds
}) {
  const newSelectedIds = selectedIds.filter(getIsTopLevelRow).filter(objectId => getShouldSelectNestedRows({
    topLevelRow: getRow(objectId),
    rowProps
  })).filter(objectId => {
    if (getShouldSelectTopLevelRow({
      rowProps
    })) {
      return true;
    }
    return !getHasBeenPreviouslySelected({
      objectId,
      previousIds,
      selectedIds
    });
  }).map(objectId => getNestedRowIdsToBeSelected({
    nestedRows: getNestedRows(objectId),
    selectedIds
  })).flat();
  newSelectedIds.forEach(objectId => toggleCheckbox({
    objectId,
    targetValue: true
  }));
  return removeDuplicatedIds([...selectedIds, ...newSelectedIds]);
}

/**
 * This method is checking if we need to select a top-level row as well as all other nested rows after selecting a single nested row.
 * We check this via @selectTopLevelRowOnNestedRowSelect from @rowProps (this is an optional configuration).
 */
export function handleTopLevelRowSelection({
  getIsNestedRow,
  getNestedRows,
  getTopLevelRow,
  getRow,
  rowProps,
  selectedIds
}) {
  if (!getShouldSelectTopLevelRow({
    rowProps
  })) {
    return selectedIds;
  }
  const newSelectedIds = selectedIds.map(objectId => getRowIdsToBeSelectedFromNestedRow({
    getNestedRows,
    selectedIds,
    topLevelRow: getIsNestedRow(objectId) ? getTopLevelRow(objectId) : getRow(objectId)
  })).flat();
  newSelectedIds.forEach(objectId => toggleCheckbox({
    objectId,
    targetValue: true
  }));
  return removeDuplicatedIds([...selectedIds, ...newSelectedIds]);
}

/**
 * This method is checking if we need to unselect a top-level row as well as all other nested rows after unselecting a single nested row.
 * We check this via @unselectTopLevelRowOnNestedRowUnselect from @rowProps (this is an optional configuration).
 */
export function handleTopLevelRowUnselection({
  getIsNestedRow,
  getNestedRows,
  getTopLevelRow,
  getRow,
  rowProps,
  selectedIds
}) {
  if (!getShouldUnselectTopLevelRow({
    rowProps
  })) {
    return selectedIds;
  }
  const unselectedIds = selectedIds.map(objectId => getRowIdsToBeUnselectedFromNestedRow({
    getNestedRows,
    selectedIds,
    topLevelRow: getIsNestedRow(objectId) ? getTopLevelRow(objectId) : getRow(objectId)
  })).flat();
  unselectedIds.forEach(objectId => toggleCheckbox({
    objectId,
    targetValue: false
  }));
  return selectedIds.filter(objectId => !unselectedIds.includes(objectId));
}
export function getShouldUnselectNestedRows({
  rowProps,
  topLevelRow
}) {
  if (!topLevelRow || !rowProps || !rowProps.unselectChildRowsOnParentUnselect) {
    return false;
  }
  return rowProps.unselectChildRowsOnParentUnselect({
    crmObject: topLevelRow
  });
}
export function getHasBeenPreviouslySelected({
  objectId,
  previousIds,
  selectedIds
}) {
  return (previousIds || []).includes(objectId) && !isEqual(previousIds, selectedIds);
}
export function getAreAllNestedRowsSelected({
  nestedRows,
  selectedIds,
  topLevelRow
}) {
  if (!topLevelRow) {
    return false;
  }
  const allNestedRowsAreStillPresent = nestedRows.map(nestedCrmObject => nestedCrmObject.objectId).every(nestedRowId => selectedIds.includes(nestedRowId));
  return allNestedRowsAreStillPresent && !selectedIds.includes(topLevelRow.objectId);
}

/**
 * This method is checking if we need to unselect nested rows from a previously selected top-level row.
 * We check this via @unselectChildRowsOnParentUnselect present in @rowProps (this is an optional configuration).
 *
 * This happens when:
 * 1 - User selects a top-level row with two nested rows -> We manually select the two nested rows
 * 2 - User unselects the top-level row
 * 3 - framework-data-table will propagate all nested row ids back to us
 * 4 - But since the top-level row is not longer present but has been previously selected, we need to also unselect nested rows
 */
export function handleNestedRowsUnselection({
  getNestedRows,
  getTopLevelRow,
  previousIds,
  rowProps,
  selectedIds
}) {
  const unselectedIds = selectedIds.map(getTopLevelRow).filter(Boolean).filter(topLevelRow => getShouldUnselectNestedRows({
    topLevelRow,
    rowProps
  })).filter(topLevelRow => getHasBeenPreviouslySelected({
    objectId: topLevelRow.objectId,
    previousIds,
    selectedIds
  })).filter(topLevelRow => getAreAllNestedRowsSelected({
    nestedRows: getNestedRows(topLevelRow.objectId),
    selectedIds,
    topLevelRow
  })).map(topLevelRow => getNestedRows(topLevelRow.objectId).map(nestedRow => nestedRow.objectId)).flat();
  unselectedIds.forEach(objectId => toggleCheckbox({
    objectId,
    targetValue: false
  }));
  return selectedIds.filter(objectId => !unselectedIds.includes(objectId));
}