import { getUniqueFormInputs } from './utils';
import { isDependentPropertyValid } from '../v2/conditionalPropertyOptionsRules/utils';
export const getRequiredPropertiesWithNoValue = (inputs, getFormValue) => getUniqueFormInputs(inputs).filter(input => input.isRequired && !getFormValue(input.property.name)).map(input => input.property);
export const getChangedPropertiesWithInvalidDependentOptions = (activeRules, changedPropertyNames, properties, alwaysShowConditionalPropertyOptionsWithErrors, getFormValue) => {
  const propertiesAndValidityStatus = {};
  for (const conditionalPropertyOptionsRule of activeRules) {
    const controllingFieldName = conditionalPropertyOptionsRule.controllingField.fieldName;
    if (changedPropertyNames.has(controllingFieldName) || alwaysShowConditionalPropertyOptionsWithErrors) {
      const dependentFieldName = conditionalPropertyOptionsRule.dependentFieldName;

      // relevant only for multi-select dependent fields.
      // In such cases, multiple CPO rules will be available for a single dependent field
      // and we want the dependent field to satisfy only one of those rules.
      // So, if the dependent field has already been processed and marked as valid, don't overwrite it.
      // for single select dependent fields, there will be only one CPO rule
      if (propertiesAndValidityStatus[dependentFieldName] === true) {
        continue;
      }
      propertiesAndValidityStatus[dependentFieldName] = isDependentPropertyValid({
        dependentProperty: properties[dependentFieldName],
        dependentValue: getFormValue(dependentFieldName),
        conditionalPropertyOptionsRules: activeRules
      });
    }
  }
  const propertiesWithInvalidDependentOptions = [];
  for (const [propertyName, isValid] of Object.entries(propertiesAndValidityStatus)) {
    if (!isValid) {
      propertiesWithInvalidDependentOptions.push(properties[propertyName]);
    }
  }
  return propertiesWithInvalidDependentOptions;
};

// returns properties that are invalid
export const getPropertiesWithInvalidStatus = (allValidationStates, properties) => {
  return allValidationStates.filter(([__, validationState]) => validationState.invalid).map(([propertyName]) => properties[propertyName]);
};