export const OBJECT_TYPE = {
  CONTACT: '0-1',
  COMPANY: '0-2',
  DEAL: '0-3',
  TICKET: '0-5',
  BLOG_POST: '0-39',
  CONTENT: '0-10',
  LANDING_PAGE: '0-25',
  FORM: '0-28',
  MARKETING_EMAIL: '0-29',
  AD_CAMPAIGN: '0-31',
  AD_GROUP: '0-32',
  CAMPAIGN: '0-35',
  SITE_PAGE: '0-38',
  CTA: '0-42',
  WORKFLOW: '0-44',
  OBJECT_LIST: '0-45',
  PUBLISHING_TASK: '0-50',
  MARKETING_EVENT: '0-54',
  SOCIAL_BROADCAST: '0-71',
  WEB_INTERACTIVES: '0-91',
  MARKETING_SMS: '0-124',
  AUTOMATION_PLATFORM_FLOW_ACTION: '0-143',
  AUTOMATION_JOURNEY: '0-158'
};