import { TASKS } from '../constants/namespaces';
import enviro from 'enviro';
import { createTracker } from 'usage-tracker';
import events from 'customer-data-tracking/tracking/tasks/events.yaml';
import once from '../utils/once';
const trackerArgs = {
  properties: {
    namespace: TASKS
  },
  onError(err) {
    console.error(err);
  },
  lastKnownEventProperties: ['screen', 'subscreen'],
  debug: () => enviro.debug('customer-data-tracker')
};
export const getTracker = once(() => createTracker(Object.assign({}, trackerArgs, {
  events
})));
export const getTrackerSendImmediate = once(() => createTracker(Object.assign({
  bypassPool: true,
  events
}, trackerArgs)));