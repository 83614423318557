import I18n from 'I18n';
import { getSortedFlattenedTeamsList } from '../../utils/TeamUtils';
import { FilterOptionTypes } from './Constants';
const makeTeamSelectOption = ({
  depth,
  id,
  name
}) => {
  return {
    depth,
    value: id,
    text: name,
    icon: 'lists',
    type: FilterOptionTypes.TEAM
  };
};
const getTeamOptions = ({
  teams
}) => {
  const teamOptions = getSortedFlattenedTeamsList(teams).map(team => makeTeamSelectOption({
    depth: team.depth || 0,
    id: team.id,
    name: team.name
  }));
  return [{
    text: I18n.text('ui-asset-management-lib.labels.teams'),
    options: teamOptions
  }];
};
export default getTeamOptions;