import { Record } from 'immutable';
// @ts-expect-error not migrated to TS yet
import Email from 'EmailData/models/Email';
// @ts-expect-error not migrated to TS yet
import EmailCampaign from 'EmailData/models/details/children/EmailCampaign';
const defaults = {
  attemptedAt: 0,
  contentId: null,
  email: null,
  emailCampaign: null,
  errorMessage: null,
  id: null,
  meta: null,
  minimalRssItemList: [],
  portalId: null,
  result: null,
  resultEnum: null,
  retries: 0,
  rssFetchResponseCode: null,
  rssFetchResponseMessage: null,
  stopKey: null,
  taskQueueUid: ''
};
class BlogRssSendAttempt extends Record(defaults) {
  static from(json) {
    if (json.email) {
      json.email = Email.from(json.email);
    }
    if (json.emailCampaign) {
      json.emailCampaign = EmailCampaign.from(json.emailCampaign);
    }
    return new BlogRssSendAttempt(json);
  }
}
export default BlogRssSendAttempt;