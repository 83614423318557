export const AB_EMAIL_TYPES = {
  primaryEmail: 'primaryEmail',
  variantEmail: 'variantEmail'
};
export const AB_SELECT_VALUE = {
  A: 'A',
  B: 'B',
  AB: 'AB'
};
export const AB_VARIANT_TYPES = {
  A: 'A',
  B: 'B',
  WINNER: 'WINNER',
  FALLBACK: 'FALLBACK'
};
export const WINNER_VARIANT_LABEL = 'winner';
export const AB_VARIANT_EMAIL_MAP = {
  [AB_EMAIL_TYPES.primaryEmail]: AB_VARIANT_TYPES.A,
  [AB_EMAIL_TYPES.variantEmail]: AB_VARIANT_TYPES.B
};
export const AB_METRIC_TYPES = {
  OPENS_BY_DELIVERED: 'OPENS_BY_DELIVERED',
  CLICKS_BY_DELIVERED: 'CLICKS_BY_DELIVERED',
  CLICKS_BY_OPENS: 'CLICKS_BY_OPENS',
  NONE: 'NONE'
};
export const AB_EMAIL_VARIANT_STATES = {
  WINNER: 'WINNER',
  WINNER_DEFAULT: 'WINNER_DEFAULT',
  LOSER: 'LOSER',
  INCONCLUSIVE: 'INCONCLUSIVE'
};
export const AB_TEST_FIELDS = {
  subject: 'subject',
  aifeatures: 'aifeatures',
  from: 'from',
  preview: 'preview',
  sent: 'sent',
  delivered: 'delivered',
  open: 'open',
  openratio: 'openratio',
  click: 'click',
  clickratio: 'clickratio',
  clickthroughratio: 'clickthroughratio',
  clickmap: 'clickmap'
};
export const AB_METRIC_RATIO_MAP = {
  [AB_METRIC_TYPES.OPENS_BY_DELIVERED]: AB_TEST_FIELDS.openratio,
  [AB_METRIC_TYPES.CLICKS_BY_DELIVERED]: AB_TEST_FIELDS.clickratio,
  [AB_METRIC_TYPES.CLICKS_BY_OPENS]: AB_TEST_FIELDS.clickthroughratio
};
export const AB_METRIC_FIELDS_MAP = {
  [AB_METRIC_TYPES.NONE]: [AB_TEST_FIELDS.subject, AB_TEST_FIELDS.aifeatures, AB_TEST_FIELDS.sent, AB_TEST_FIELDS.delivered, AB_TEST_FIELDS.open, AB_TEST_FIELDS.openratio, AB_TEST_FIELDS.click, AB_TEST_FIELDS.clickratio, AB_TEST_FIELDS.clickthroughratio],
  [AB_METRIC_TYPES.OPENS_BY_DELIVERED]: [AB_TEST_FIELDS.openratio, AB_TEST_FIELDS.subject, AB_TEST_FIELDS.aifeatures, AB_TEST_FIELDS.from, AB_TEST_FIELDS.preview, AB_TEST_FIELDS.delivered, AB_TEST_FIELDS.open, AB_TEST_FIELDS.sent, AB_TEST_FIELDS.click, AB_TEST_FIELDS.clickratio, AB_TEST_FIELDS.clickthroughratio],
  [AB_METRIC_TYPES.CLICKS_BY_DELIVERED]: [AB_TEST_FIELDS.clickratio, AB_TEST_FIELDS.aifeatures, AB_TEST_FIELDS.clickmap, AB_TEST_FIELDS.delivered, AB_TEST_FIELDS.click, AB_TEST_FIELDS.sent, AB_TEST_FIELDS.open, AB_TEST_FIELDS.openratio, AB_TEST_FIELDS.clickthroughratio],
  [AB_METRIC_TYPES.CLICKS_BY_OPENS]: [AB_TEST_FIELDS.clickthroughratio, AB_TEST_FIELDS.aifeatures, AB_TEST_FIELDS.clickmap, AB_TEST_FIELDS.open, AB_TEST_FIELDS.click, AB_TEST_FIELDS.delivered, AB_TEST_FIELDS.sent, AB_TEST_FIELDS.openratio, AB_TEST_FIELDS.clickratio]
};
export const AB_METRIC_FIELDS = {
  [AB_TEST_FIELDS.sent]: AB_TEST_FIELDS.sent,
  [AB_TEST_FIELDS.delivered]: AB_TEST_FIELDS.delivered,
  [AB_TEST_FIELDS.open]: AB_TEST_FIELDS.open,
  [AB_TEST_FIELDS.openratio]: AB_TEST_FIELDS.openratio,
  [AB_TEST_FIELDS.click]: AB_TEST_FIELDS.click,
  [AB_TEST_FIELDS.clickratio]: AB_TEST_FIELDS.clickratio,
  [AB_TEST_FIELDS.clickthroughratio]: AB_TEST_FIELDS.clickthroughratio
};
export const AB_RATIO_FIELDS = {
  [AB_TEST_FIELDS.openratio]: AB_TEST_FIELDS.openratio,
  [AB_TEST_FIELDS.clickratio]: AB_TEST_FIELDS.clickratio,
  [AB_TEST_FIELDS.clickthroughratio]: AB_TEST_FIELDS.clickthroughratio
};
export const AB_CLICK_MAP_TYPES = {
  EVEN_SPLIT: 'EVEN_SPLIT',
  WINNER_CANCELLED: 'WINNER_CANCELLED',
  CLICKS: 'CLICKS',
  OPENS: 'OPENS',
  INSIGNIFICANT_SAMPLE_SIZE: 'INSIGNIFICANT_SAMPLE_SIZE'
};
export const AB_RESULT_STATUS = {
  SUCCESSFUL_AB_SEND: 'SUCCESSFUL_AB_SEND',
  CONTACT_LIST_NOT_LARGE_ENOUGH: 'CONTACT_LIST_NOT_LARGE_ENOUGH',
  STILL_BEING_SENT_TO_BOTH_VARIATIONS: 'STILL_BEING_SENT_TO_BOTH_VARIATIONS',
  TEST_RESULTS_INCONCLUSIVE: 'TEST_RESULTS_INCONCLUSIVE',
  EVEN_SPLIT_SEND: 'EVEN_SPLIT_SEND',
  SCHEDULED_TEST: 'SCHEDULED_TEST',
  AUTOMATED_TEST: 'AUTOMATED_TEST',
  ENDED_AUTOMATED_TEST: 'ENDED_AUTOMATED_TEST'
};
export const AB_AUTOMATED_ACTIONS = {
  CHOOSE_WINNER: 'chooseWinner',
  RERUN: 'rerun',
  NEW_TEST: 'newTest'
};
export const AUTOMATED_MASTER = 'automated_master';
export const AUTOMATED_VARIANT = 'automated_variant';
export const AUTOMATED_LOSER_VARIANT = 'automated_loser_variant';
export const REGULAR_AB_MASTER = 'master';
export const REGULAR_AB_VARIANT = 'variant';
export const INFINITE_TEST_DURATION = 'INFINITE';