import ContentRoutes from 'ContentUtils/Routes';
import { RECIPIENT_PROPS, RECIPIENT_PROPS_WITH_ILS } from './constants';
export const allTrueFunctions = funcs => {
  return (...args) => {
    return funcs.reduce((acc, func) => {
      return acc && func(...args);
    }, true);
  };
};
export const anyTrueFunctions = funcs => {
  return (...args) => {
    return funcs.reduce((acc, func) => {
      return acc || func(...args);
    }, false);
  };
};
export const allFalseFunctions = funcs => {
  return (...args) => {
    return funcs.reduce((acc, func) => {
      return acc && !func(...args);
    }, true);
  };
};
export const generateIncludedListsURL = listsIds => {
  const filters = [];
  if (listsIds.size > 0) {
    filters.push({
      operator: 'IN',
      property: 'listMemberships.listId',
      values: listsIds.keySeq().toJS()
    });
  }
  return `${ContentRoutes.lists()}/?filters=${encodeURIComponent(JSON.stringify(filters))}`;
};
export const getInclusionKey = recipientType => {
  if (recipientType === 'lists') {
    return 'mailingIlsListsIncluded';
  } else if (recipientType === 'contacts') {
    return 'vidsIncluded';
  }
  return '';
};
export const getExclusionKey = recipientType => {
  if (recipientType === 'lists') {
    return 'mailingIlsListsExcluded';
  } else if (recipientType === 'contacts') {
    return 'vidsExcluded';
  }
  return '';
};
export const hasRecipients = props => RECIPIENT_PROPS.some(prop => props[prop].length > 0);
export const hasRecipientsWithIls = props => RECIPIENT_PROPS_WITH_ILS.some(prop => props[prop].length > 0);