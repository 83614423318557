'use es6';

import { List, Map as ImmutableMap } from 'immutable';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { RESET_EMAIL_DETAILS } from 'EmailData/actions/actionTypes';
import { FETCH_EMAIL_DETAILS_WORKFLOWS, FETCH_EMAIL_DETAILS_WORKFLOWS_REQUEST } from 'email-management-lib/refactor/actions/actionTypes';
import Workflow from 'EmailData/models/Workflow';
const initialState = ImmutableMap({});
function workflows(state = initialState, action) {
  switch (action.type) {
    case FETCH_EMAIL_DETAILS_WORKFLOWS:
      if (action.error !== true) {
        return state.set(action.payload.emailContentId, List(action.payload.workflows.map(wf => Workflow.from(wf))));
      }
      return state;
    default:
      return state;
  }
}
export default asyncResource(workflows, {
  requestActions: [FETCH_EMAIL_DETAILS_WORKFLOWS_REQUEST],
  responseActions: [FETCH_EMAIL_DETAILS_WORKFLOWS],
  invalidatingActions: [RESET_EMAIL_DETAILS]
});