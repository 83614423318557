import StateStatusGroups from 'ContentUtils/constants/StateStatusGroups';
import intersection from 'hs-lodash/intersection';
const SUBCATEGORIES_ALWAYS_EXCLUDED = ['automated_for_leadflow', 'automated_for_ticket', 'ticket_closed_kickback_email', 'ticket_opened_kickback_email'];
export const TYPE_STATE_MAP = {
  automated: {
    includedStates: ['AUTOMATED', 'AUTOMATED_DRAFT'],
    excludedSubcategories: ['single_send_api', 'marketing_single_send_api']
  },
  singleSendApi: {
    includedStates: ['AUTOMATED'],
    onlySubcategories: ['single_send_api', 'marketing_single_send_api']
  },
  automatedForDeal: {
    includedStates: ['AUTOMATED'],
    onlySubcategories: ['automated_for_deal']
  },
  batch: {
    includedStates: ['DRAFT', 'PUBLISHED', 'SCHEDULED']
  },
  followUp: {
    includedStates: ['AUTOMATED_FOR_FORM', 'AUTOMATED_FOR_FORM_DRAFT', 'AUTOMATED_FOR_FORM_BUFFER']
  },
  rssToEmail: {
    includedStates: ['RSS_TO_EMAIL_DRAFT', 'RSS_TO_EMAIL_PUBLISHED']
  },
  blogUpdate: {
    includedStates: ['BLOG_EMAIL_DRAFT', 'BLOG_EMAIL_PUBLISHED']
  },
  abTests: {
    includedStates: ['DRAFT_AB', 'PUBLISHED_AB', 'DRAFT_AB_VARIANT', 'PUBLISHED_AB_VARIANT', 'LOSER_AB_VARIANT', 'SCHEDULED_AB']
  },
  timezone: {
    onlySubcategories: ['localtime'],
    includedStates: ['DRAFT', 'PUBLISHED', 'SCHEDULED']
  },
  automatedAb: {
    includedStates: ['AUTOMATED_AB', 'AUTOMATED_DRAFT_AB']
  }
};
const SIMPLE_STATE_MAP = {
  dashboard_states: ['PRE_PROCESSING', 'PROCESSING', 'PUBLISHED', 'PUBLISHED_AB', 'BLOG_EMAIL_PUBLISHED', 'AUTOMATED', 'RSS_TO_EMAIL_PUBLISHED'],
  sent: ['PRE_PROCESSING', 'PROCESSING', 'PUBLISHED', 'PUBLISHED_AB', 'AUTOMATED_AB', 'AUTOMATED', 'BLOG_EMAIL_PUBLISHED', 'RSS_TO_EMAIL_PUBLISHED', 'AUTOMATED_FOR_FORM', 'ERROR'],
  draft: ['AUTOMATED_DRAFT', 'AUTOMATED_DRAFT_AB', 'AUTOMATED_FOR_FORM_BUFFER', 'AUTOMATED_FOR_FORM_DRAFT', 'BLOG_EMAIL_DRAFT', 'DRAFT', 'DRAFT_AB', 'RSS_TO_EMAIL_DRAFT']
};
function getStatesForSendState(sendState) {
  let validStates = {};
  switch (sendState) {
    case 'draft':
      validStates = SIMPLE_STATE_MAP.draft;
      break;
    case 'scheduled':
      validStates = StateStatusGroups.SCHEDULED;
      break;
    case 'sent':
      validStates = SIMPLE_STATE_MAP.sent;
      break;
    default:
      validStates = [...SIMPLE_STATE_MAP.draft, ...SIMPLE_STATE_MAP.sent, ...StateStatusGroups.SCHEDULED];
  }
  return validStates;
}
function getTypeStateMapData(type, blogRssChildren = false) {
  switch (type) {
    case 'ab':
      return TYPE_STATE_MAP.abTests;
    case 'automated':
      return TYPE_STATE_MAP.automated;
    case 'automatedAb':
      return TYPE_STATE_MAP.automatedAb;
    case 'batch':
      return Object.assign({}, TYPE_STATE_MAP.batch, {
        excludedSubcategories: ['blog_email_child', 'localtime', 'rss_to_email_child']
      });
    case 'blog':
      if (blogRssChildren) {
        return Object.assign({}, TYPE_STATE_MAP.batch, {
          onlySubcategories: ['blog_email_child']
        });
      }
      return TYPE_STATE_MAP.blogUpdate;
    case 'followup':
      return TYPE_STATE_MAP.followUp;
    case 'rss':
      if (blogRssChildren) {
        return Object.assign({}, TYPE_STATE_MAP.batch, {
          onlySubcategories: ['rss_to_email_child']
        });
      }
      return TYPE_STATE_MAP.rssToEmail;
    case 'singlesend':
      return TYPE_STATE_MAP.singleSendApi;
    case 'timezone':
      return TYPE_STATE_MAP.timezone;
    default:
      return {};
  }
}
export function getConstraintsForStateAndType({
  state,
  type,
  blogRssChildren
}) {
  const sendStateStates = getStatesForSendState(state);
  const typeData = getTypeStateMapData(type, blogRssChildren);
  const includedStates = typeof typeData.includedStates === 'undefined' ? sendStateStates : intersection(sendStateStates, typeData.includedStates);
  if (includedStates.length === 0) {
    return {
      validState: false
    };
  }
  const onlySubcategories = typeData.onlySubcategories || [];
  let excludedSubcategories = typeData.excludedSubcategories || [];
  excludedSubcategories = [...excludedSubcategories, ...SUBCATEGORIES_ALWAYS_EXCLUDED];
  return {
    includedStates,
    excludedSubcategories,
    onlySubcategories,
    validState: true
  };
}