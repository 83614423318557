'use es6';

import { Record } from 'immutable';
const defaults = {
  name: '',
  guid: ''
};
class CtaData extends Record(defaults) {
  static from(json) {
    return new CtaData(json);
  }
}
export default CtaData;