import { createGenericFetchAction } from 'ContentData/helpers/reduxHelpers';
import * as teamsClient from 'ContentData/api/Teams';
import { TEAMS_FETCH_TEAMS, TEAMS_UPDATE_TEAMS_DATA } from 'ContentData/actions/ActionTypes';
export const fetchAllTeams = createGenericFetchAction(TEAMS_FETCH_TEAMS, teamsClient.fetchAllTeams);
export const fetchTeamsForUser = createGenericFetchAction(TEAMS_FETCH_TEAMS, teamsClient.fetchTeamsForUser);
export const fetchTeams = userHasTeamAssignmentScope => {
  if (!userHasTeamAssignmentScope) {
    return fetchTeamsForUser();
  } else {
    return fetchAllTeams();
  }
};
export const updateTeamsData = data => dispatch => dispatch({
  type: TEAMS_UPDATE_TEAMS_DATA,
  data
});