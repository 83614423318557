import { useEffect, useMemo, useRef, useState } from 'react';
import { EMPTY_STATE_TYPE } from 'framework-listing-lib/constants/emptyState';
import useCrmObjectTypeListingFilters from 'framework-listing-lib/hooks/useCrmObjectTypeListingFilters';
import useFetchCrmObjectType from 'framework-listing-lib/hooks/useFetchCrmObjectType';
import { useFilterGroups } from 'framework-listing-lib/hooks/useListingLibFilters';
import { FOLDER_ID } from 'framework-listing-lib/internal/Folders/constants/folders';
import useFetchInitialState from 'framework-listing-lib/internal/hooks/useFetchInitialState';
import useObjectTypeId from 'framework-listing-lib/internal/hooks/useObjectTypeId';
import { useListing } from 'framework-listing-lib/internal/providers/ListingProvider';
import { getIsPending } from 'framework-listing-lib/internal/utils/emptyState';
import { areSameFilterGroups } from 'framework-listing-lib/utils/filters';
import useFetchEmptyStateParams from './useFetchEmptyStateParams';
export default function useCrmObjectTypeListingEmptyState() {
  const objectType = useObjectTypeId();
  const {
    alwaysMakeEmptyStateRequest
  } = useListing();
  const {
    data
  } = useFetchCrmObjectType();
  const {
    filters
  } = useCrmObjectTypeListingFilters();
  const filterGroups = useFilterGroups();
  const {
    data: initialStateData,
    loading: loadingInitialState
  } = useFetchInitialState(alwaysMakeEmptyStateRequest);
  const {
    variables: currentEmptyStateVariables
  } = useFetchEmptyStateParams();
  const prevEmptyStateVariables = useRef(undefined);
  const [hasEverHadResults, setHasEverHadResults] = useState(false);
  const hasInitialStateResults = Boolean(initialStateData && initialStateData.total > 0);
  const hasCurrentSearchResults = Boolean(data && data.total > 0);
  if (!hasEverHadResults && (hasInitialStateResults || hasCurrentSearchResults)) {
    setHasEverHadResults(true);
  }
  useEffect(() => {
    if (prevEmptyStateVariables.current && !areSameFilterGroups(prevEmptyStateVariables.current, currentEmptyStateVariables.fetchParams.filterGroups)) {
      setHasEverHadResults(false);
    }
    prevEmptyStateVariables.current = currentEmptyStateVariables.fetchParams.filterGroups;
  }, [currentEmptyStateVariables.fetchParams.filterGroups]);
  const hasFolderFilter = useMemo(() => {
    return filterGroups.some(filterGroup => filterGroup.filters.some(filter => filter.property === FOLDER_ID));
  }, [filterGroups]);
  const hasZeroCurrentSearchResults = useMemo(() => data ? data.total === 0 : undefined, [data]);
  const loading = Boolean(data === undefined || loadingInitialState);
  const emptyStateType = useMemo(() => {
    if (!hasZeroCurrentSearchResults || loading) {
      return undefined;
    }
    if (!hasEverHadResults) {
      return EMPTY_STATE_TYPE.FIRST_TIME_IN_APP;
    }
    if (hasFolderFilter) {
      const hasFiltersOtherThanFolder = filterGroups.some(filterGroup => filterGroup.filters.some(filter => filter.property !== FOLDER_ID));
      if (filters.query || hasFiltersOtherThanFolder) {
        return EMPTY_STATE_TYPE.NO_RESULTS_WITH_FILTER_APPLIED;
      }
      return EMPTY_STATE_TYPE.NO_RESULTS_IN_FOLDER;
    }
    return EMPTY_STATE_TYPE.NO_RESULTS_WITH_FILTER_APPLIED;
  }, [filterGroups, filters.query, hasFolderFilter, hasEverHadResults, hasZeroCurrentSearchResults, loading]);
  const value = useMemo(() => ({
    emptyStateType,
    hasEmptyState: hasZeroCurrentSearchResults,
    objectType,
    isPending: getIsPending({
      emptyStateType,
      hasEmptyState: hasZeroCurrentSearchResults
    })
  }), [emptyStateType, hasZeroCurrentSearchResults, objectType]);
  return value;
}