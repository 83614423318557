'use es6';

import Routes from 'ContentUtils/Routes';
import { Record } from 'immutable';
import ListMetaData from './ListMetaData';
const defaults = {
  id: null,
  archived: false,
  authorId: 0,
  createdAt: null,
  dynamic: false,
  listId: null,
  ilsListId: null,
  portalId: null,
  listType: '',
  metaData: ListMetaData.from({}),
  name: '',
  updatedAt: null,
  deleted: false
};
class ListRecord extends Record(defaults) {
  static from(json) {
    if (typeof json.metaData !== 'undefined') {
      json.metaData = ListMetaData.from(json.metaData);
    }
    return new ListRecord(Object.assign({
      id: json.listId
    }, json));
  }
  getComputedName() {
    return this.name;
  }
  getUrl() {
    return Routes.list(this.get('listId'));
  }
}
export default ListRecord;