module.exports = {
  "viewsTableUsage": {
    "name": "views table usage",
    "class": "usage",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": false
      },
      "name": {
        "type": "string",
        "isOptional": true
      },
      "value": {
        "type": "string",
        "isOptional": true
      },
      "objectTypeIds": {
        "type": "array",
        "isOptional": true
      },
      "currentOwnerIds": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "customer-data-views-management"
  },
  "viewsTableInteractions": {
    "name": "views table interactions",
    "class": "interaction",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": false
      },
      "name": {
        "type": "string",
        "isOptional": true
      },
      "value": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "customer-data-views-management"
  }
};