import { useMemo, useState } from 'react';
import isEqual from 'hs-lodash/isEqual';
import useCrmSearchQuery from 'framework-listing-lib/hooks/useCrmSearchQuery';
import useFetchFromCRM from 'framework-listing-lib/internal/hooks/useFetchFromCRM';
import useFetchEmptyStateParams from './useFetchEmptyStateParams';
import { areSameFilterGroups, removePropertyFilters } from 'framework-listing-lib/utils/filters';
import { useHasBUNavIntegration } from 'framework-listing-lib/internal/frameworkAppSettings/hooks/useFrameworkAppSettings';
import { BU_PROPERTY_NAME } from 'framework-listing-lib/constants/businessUnits';
export default function useMaybeFetchInitialState(alwaysMakeEmptyStateRequest) {
  const {
    variables: emptyStateVariables
  } = useFetchEmptyStateParams();
  const crmSearchQuery = useCrmSearchQuery();
  const hasBUNavIntegration = useHasBUNavIntegration();
  const [initialData, setInitialData] = useState(undefined);
  const [prevEmptyStateVariables, setPrevEmptyStateVariables] = useState(emptyStateVariables);

  // Do not take into account the BU filter if hasBUNavIntegration. We manually apply the filter which causes isSameQuery to be false
  const nonBUFilters = useMemo(() => hasBUNavIntegration ? removePropertyFilters(crmSearchQuery.filterGroups, BU_PROPERTY_NAME) : crmSearchQuery.filterGroups, [crmSearchQuery.filterGroups, hasBUNavIntegration]);
  const isSameQuery = useMemo(() => areSameFilterGroups(emptyStateVariables.fetchParams.filterGroups, nonBUFilters) && emptyStateVariables.fetchParams.query === crmSearchQuery.query && isEqual(emptyStateVariables.fetchParams.sorts, crmSearchQuery.sorts), [crmSearchQuery.query, crmSearchQuery.sorts, emptyStateVariables.fetchParams.filterGroups, emptyStateVariables.fetchParams.query, emptyStateVariables.fetchParams.sorts, nonBUFilters]);
  const isSameEmptyState = useMemo(() => isEqual(prevEmptyStateVariables.fetchParams, emptyStateVariables.fetchParams), [emptyStateVariables, prevEmptyStateVariables]);

  /**
   * Skip API call if filter conditions are the exact same as the main table data or if we've already fetched the initial state data
   */
  const options = useMemo(() => ({
    skip: !alwaysMakeEmptyStateRequest && isSameQuery && isSameEmptyState
  }), [alwaysMakeEmptyStateRequest, isSameEmptyState, isSameQuery]);
  const {
    data,
    error,
    loading
  } = useFetchFromCRM(emptyStateVariables, Object.assign({}, options));
  if (!initialData && data) {
    setInitialData(data);
  } else if (!isSameEmptyState) {
    setInitialData(undefined);
    setPrevEmptyStateVariables(emptyStateVariables);
  }
  const value = useMemo(() => ({
    data: initialData,
    error,
    loading: options.skip && isSameQuery ? false : loading
  }), [error, initialData, isSameQuery, loading, options.skip]);
  return value;
}