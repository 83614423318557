import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["data", "client"];
import { useEffect, useMemo } from 'react';
import { useAllHighlySensitivePropertyValues } from 'customer-data-properties/dataSensitivity/useAllHighlySensitivePropertyValues';
import { FOLDERS_POLLING_INTERVAL } from 'framework-listing-lib/internal/Folders/constants/folders';
import useFolderPollingState from 'framework-listing-lib/internal/Folders/hooks/useFolderPollingState';
import useIsMovingToAnotherFolder from 'framework-listing-lib/internal/Folders/hooks/useIsMovingToAnotherFolder';
import { useHasViews } from 'framework-listing-lib/internal/ViewTabs/hooks/useViewProps';
import { useViews as useViewsProvider } from 'framework-listing-lib/internal/ViewTabs/providers/ViewsProvider';
import useCrmObjectTypeFetchParams from 'framework-listing-lib/internal/hooks/useCrmObjectTypeFetchParams';
import useFetchFromCRM from 'framework-listing-lib/internal/hooks/useFetchFromCRM';
import useHandleDFCError from 'framework-listing-lib/internal/hooks/useHandleDFCError';
import useObjectTypeId from 'framework-listing-lib/internal/hooks/useObjectTypeId';
import { useIsFoldersMode } from 'framework-listing-lib/internal/hooks/useUIMode';
import { setPropertyValues } from '../utils/crmObject';

/**
 * By default, we don't use fetchPolicy and hand everything over to data-fetching-client.
 * When in folders mode, we use "cache-and-network" so we always get the latest results when entering a folder after moving objects in/out of a folder.
 */
function useFetchPolicy(fetchPolicy) {
  const isFoldersMode = useIsFoldersMode();
  return useMemo(() => {
    if (fetchPolicy) {
      return fetchPolicy;
    } else if (isFoldersMode) {
      return 'cache-and-network';
    } else {
      return undefined;
    }
  }, [fetchPolicy, isFoldersMode]);
}
function usePollInterval() {
  const isFoldersMode = useIsFoldersMode();
  const {
    pollingState
  } = useFolderPollingState();
  return useMemo(() => isFoldersMode && pollingState === 'ON' ? FOLDERS_POLLING_INTERVAL : undefined, [isFoldersMode, pollingState]);
}

/**
 * Defer fetching of CRM objects if views are still loading.
 * This is to prevent making multiple API calls to fetch for CRM objects.
 */

export function useSkip() {
  const {
    isLoading
  } = useViewsProvider();
  const hasViews = useHasViews();
  return useMemo(() => Boolean(hasViews && isLoading), [hasViews, isLoading]);
}
export default function useFetchCrmObjectType(fetchingOptions = {
  fetchPolicy: undefined
}) {
  const objectTypeId = useObjectTypeId();
  const fetchPolicy = useFetchPolicy(fetchingOptions.fetchPolicy);
  const pollInterval = usePollInterval();
  const skip = useSkip();
  const isFoldersMode = useIsFoldersMode();
  const {
    pollingState
  } = useFolderPollingState();
  const isMovingToAnotherFolder = useIsMovingToAnotherFolder();
  const variables = useCrmObjectTypeFetchParams();
  const _useFetchFromCRM = useFetchFromCRM(variables, {
      pollInterval,
      skip,
      fetchPolicy
    }),
    {
      data
    } = _useFetchFromCRM,
    rest = _objectWithoutPropertiesLoose(_useFetchFromCRM, _excluded);
  const {
    stopPolling
  } = rest;
  useHandleDFCError(rest.error);
  useEffect(() => {
    if (isFoldersMode && pollingState === 'OFF') {
      stopPolling();
    }
  }, [isFoldersMode, pollingState, stopPolling]);
  const highlySensitivePropertyValues = useAllHighlySensitivePropertyValues({
    objectTypeId
  });

  /**
   * When in folders mode, remove objects that have been moved
   * out of current folder.
   */
  const validCrmObjects = useMemo(() => {
    if (!data) {
      return data;
    }
    let validObjects = data.results.filter(crmObject => {
      /**
       * Filters out recently deleted, but not yet un-indexed objects
       * using a similar strategy to one used by the CRM team, here:
       * https://git.hubteam.com/HubSpot/crm-object-search-query-libs/blob/ecc4195d439e395d6581d279d7e1217c921266bb/crm-object-search-query-utilities/static/js/crmObjectSearch/useCrmObjectsSearch.tsx#L122
       */
      return Boolean(crmObject.properties && Object.keys(crmObject.properties).length > 0);
    });
    if (isFoldersMode) {
      validObjects = validObjects.filter(crmObject => !isMovingToAnotherFolder(crmObject.objectId));
    }

    /**
     * Overlay highly sensitive property values that have been decrypted on top of the
     * CRM search results, which will have empty values for highly sensitive properties.
     */
    const hasHighlySensitivePropertyValues = Object.keys(highlySensitivePropertyValues).length > 0;
    if (hasHighlySensitivePropertyValues) {
      validObjects = validObjects.map(crmObject => highlySensitivePropertyValues[crmObject.objectId] ? setPropertyValues(crmObject, highlySensitivePropertyValues[crmObject.objectId]) : crmObject);
    }
    const invalidObjectsCount = data.results.length - validObjects.length;
    return Object.assign({}, data, {
      results: validObjects,
      total: Math.max(0, data.total - invalidObjectsCount)
    });
  }, [data, highlySensitivePropertyValues, isFoldersMode, isMovingToAnotherFolder]);
  const value = useMemo(() => Object.assign({
    data: validCrmObjects
  }, rest), [rest, validCrmObjects]);
  return value;
}