'use es6';

import { Map as ImmutableMap } from 'immutable';
import { RESET_EMAIL_DETAILS } from 'EmailData/actions/actionTypes';
import { SELECT_TOP_CLICKED_LINKS_TAB } from 'email-management-lib/refactor/actions/actionTypes';
import { TOP_CLICKED_LINKS_TABS } from 'EmailData/utils/details/detailTypeUtil';
const initialState = ImmutableMap({
  selectedTab: TOP_CLICKED_LINKS_TABS.COMBINED
});
function topClickedLinks(state = initialState, action) {
  switch (action.type) {
    case SELECT_TOP_CLICKED_LINKS_TAB:
      return state.set('selectedTab', action.payload);
    case RESET_EMAIL_DETAILS:
      return initialState;
    default:
      return state;
  }
}
export default topClickedLinks;