import { DATA_1, DATA_2, isSourceFromEmailMarketing, isSourceFromImport, isSourceFromIntegration, isSourceFromUser, LATEST_DATA_1, LATEST_DATA_2, LATEST_SOURCE, SOURCE } from 'customer-data-objects/record/AnalyticsSourceIdentifier';
import { USER } from 'reference-resolvers-lite/constants/ReferenceTypes';
import { EMAIL_CAMPAIGN, IMPORT_NAME, INTEGRATION_NAME } from './CustomResolverReferenceTypes';
const getAnalyticsSourceReferenceType = (sourcePropertyValue, data1PropertyValue, data2PropertyValue) => {
  if (data2PropertyValue && isSourceFromUser({
    analyticsSourceData2: data2PropertyValue
  })) {
    return USER;
  } else if (sourcePropertyValue && isSourceFromEmailMarketing({
    analyticsSource: sourcePropertyValue
  })) {
    return EMAIL_CAMPAIGN;
  } else if (data1PropertyValue && isSourceFromIntegration({
    analyticsSourceData1: data1PropertyValue
  })) {
    return INTEGRATION_NAME;
  } else if (sourcePropertyValue && data1PropertyValue && isSourceFromImport({
    analyticsSource: sourcePropertyValue,
    analyticsSourceData1: data1PropertyValue
  })) {
    return IMPORT_NAME;
  }
  return undefined;
};
const getAnalyticsSourceProperties = (property, objectTypeId, objectId, value, getAdditionalPropertyValue) => {
  if (property.name === DATA_2) {
    return {
      sourceProperty: getAdditionalPropertyValue(SOURCE, objectId, objectTypeId),
      data1Property: getAdditionalPropertyValue(DATA_1, objectId, objectTypeId),
      data2Property: value
    };
  } else if (property.name === LATEST_DATA_2) {
    return {
      sourceProperty: getAdditionalPropertyValue(LATEST_SOURCE, objectId, objectTypeId),
      data1Property: getAdditionalPropertyValue(LATEST_DATA_1, objectId, objectTypeId),
      data2Property: value
    };
  }
  return {
    sourceProperty: undefined,
    data1Property: undefined,
    data2Property: undefined
  };
};
export const isResolveableAnalyticsSourceProperty = property => property.name === DATA_2 || property.name === LATEST_DATA_2;
export const getAnalyticsSourcePropertyReferenceType = (property, objectTypeId, objectId, value, getAdditionalPropertyValue) => {
  if (property.name !== DATA_2 && property.name !== LATEST_DATA_2) {
    return undefined;
  }
  const {
    sourceProperty,
    data1Property,
    data2Property
  } = getAnalyticsSourceProperties(property, objectTypeId, objectId, value, getAdditionalPropertyValue);
  return getAnalyticsSourceReferenceType(sourceProperty, data1Property, data2Property);
};