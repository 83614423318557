module.exports = {
  "view-listcreate": {
    "name": "view-listcreate",
    "class": "view",
    "properties": {
      "action": "string",
      "screen": "string",
      "subscreen": {
        "isOptional": true,
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "lists"
  },
  "view-listdetail": {
    "name": "view-listdetail",
    "class": "view",
    "properties": {
      "action": "string",
      "screen": "string",
      "subscreen": {
        "isOptional": true,
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "lists"
  },
  "view-listsindex": {
    "name": "view-listsindex",
    "class": "view",
    "properties": {
      "action": "string",
      "screen": "string",
      "subscreen": {
        "isOptional": true,
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "lists"
  },
  "createReport": {
    "name": "create report",
    "class": "usage",
    "properties": {
      "objectTypeId": "string",
      "screen": [
        "list-detail"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "lists"
  },
  "editListColumns": {
    "name": "edit columns",
    "class": "usage",
    "properties": {
      "objectTypeId": "string",
      "screen": [
        "list-detail"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "lists"
  },
  "createList": {
    "name": "create list",
    "class": "usage",
    "properties": {
      "objectTypeId": "string",
      "classicListId": {
        "isOptional": true,
        "type": "number"
      },
      "ilsListId": {
        "isOptional": true,
        "type": "number"
      },
      "fromClone": {
        "isOptional": true,
        "type": "boolean"
      },
      "listType": [
        "static",
        "dynamic"
      ],
      "screen": [
        "list-detail",
        "list-index",
        "unused-lists"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "lists"
  },
  "deleteList": {
    "name": "delete list",
    "class": "usage",
    "properties": {
      "objectTypeId": "string",
      "isBulk": {
        "isOptional": true,
        "type": "boolean"
      },
      "partialSuccess": {
        "isOptional": true,
        "type": "boolean"
      },
      "screen": [
        "list-detail",
        "list-index",
        "unused-lists"
      ],
      "subscreen": {
        "isOptional": true,
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "lists"
  },
  "updateList": {
    "name": "update list",
    "class": "usage",
    "properties": {
      "objectTypeId": "string",
      "classicListId": {
        "isOptional": true,
        "type": "number"
      },
      "ilsListId": {
        "isOptional": true,
        "type": "number"
      },
      "listType": [
        "static",
        "dynamic"
      ],
      "screen": [
        "list-detail",
        "list-index",
        "unused-lists"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "lists"
  },
  "createFolder": {
    "name": "create folder",
    "class": "usage",
    "properties": {
      "screen": [
        "list-index"
      ],
      "subscreen": "string",
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "lists"
  },
  "deleteFolder": {
    "name": "delete folder",
    "class": "usage",
    "properties": {
      "screen": [
        "list-index"
      ],
      "subscreen": "string",
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "lists"
  },
  "moveToFolder": {
    "name": "move to folder",
    "class": "usage",
    "properties": {
      "isBulk": {
        "isOptional": true,
        "type": "boolean"
      },
      "screen": [
        "list-detail",
        "list-index"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "lists"
  },
  "listsPageview": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "screen": [
        "list-detail",
        "list-index",
        "unused-lists",
        "recently-deleted",
        "empty-list",
        "settings",
        "activity"
      ],
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "lists"
  },
  "listsInteraction": {
    "name": "lists interaction",
    "class": "interaction",
    "properties": {
      "ilsListId": {
        "isOptional": true,
        "type": "number"
      },
      "objectTypeId": {
        "type": "string",
        "isOptional": true
      },
      "listType": {
        "isOptional": true,
        "type": [
          "static",
          "dynamic"
        ]
      },
      "action": "string",
      "screen": {
        "isOptional": true,
        "type": [
          "list-detail",
          "list-index",
          "unused-lists",
          "manage-registration",
          "recently-deleted",
          "empty-list",
          "settings",
          "activity"
        ]
      },
      "subAction": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      },
      "condition": {
        "type": "string",
        "isOptional": true
      },
      "filterFamily": {
        "type": "string",
        "isOptional": true
      },
      "panelKey": {
        "type": "string",
        "isOptional": true
      },
      "maxCount": {
        "type": "number",
        "isOptional": true
      },
      "targetListType": {
        "type": "string",
        "isOptional": true
      },
      "listId": {
        "type": "string",
        "isOptional": true
      },
      "notificationTypes": {
        "isOptional": true,
        "type": "array"
      },
      "listsExcluded": {
        "isOptional": true,
        "type": "number"
      },
      "recordsExcluded": {
        "isOptional": true,
        "type": "number"
      },
      "AIGeneratedListFilters": {
        "isOptional": true,
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "lists"
  },
  "addContactsToRestrictedList": {
    "name": "lists interaction",
    "class": "interaction",
    "properties": {
      "objectTypeId": "string",
      "action": "string",
      "count": "number",
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "lists"
  },
  "bulkAddToList": {
    "name": "bulk add to list",
    "class": "usage",
    "properties": {
      "objectTypeId": "string",
      "screen": "string",
      "subscreen": [
        "index"
      ],
      "subscreen2": [
        "bulk-list-modal"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "lists"
  }
};