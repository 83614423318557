import { useAccessLevels } from 'customer-data-properties/accessLevel/AccessLevelContext';
import { NOT_SPECIFIED } from 'customer-data-objects/property/AccessLevel';
import { useMemo } from 'react';
export const useCanEditValues = ({
  objectTypeId,
  propertyDefinitions,
  canEditObject,
  doPreventEdit
}) => {
  const accessLevels = useAccessLevels({
    objectType: objectTypeId,
    propertyNames: propertyDefinitions.map(propertyDefinition => propertyDefinition.name)
  });
  const anyReadOnly = propertyDefinitions.some(propertyDefinition => propertyDefinition.readOnlyValue);
  // Ensure all properties are NOT_SPECIFIED and the user has access. Other
  // options would be HIDDEN, READ_ONLY, etc
  const anySpecified = Object.values(accessLevels).some(accessLevel => accessLevel !== NOT_SPECIFIED);
  const canFLPEditProperty = !anyReadOnly && !anySpecified;
  const preventEdit = useMemo(() => propertyDefinitions.some(propertyDefinition => doPreventEdit({
    propertyDefinition
  })), [doPreventEdit, propertyDefinitions]);
  return useMemo(() => canFLPEditProperty && canEditObject && !preventEdit, [canFLPEditProperty, canEditObject, preventEdit]);
};
export const useCanEditValue = ({
  objectTypeId,
  propertyDefinition,
  canEditObject,
  doPreventEdit
}) => {
  const propertyDefinitions = useMemo(() => [propertyDefinition], [propertyDefinition]);
  return useCanEditValues({
    objectTypeId,
    propertyDefinitions,
    canEditObject,
    doPreventEdit
  });
};