'use es6';

import { Record, Set as ImmutableSet } from 'immutable';
const defaults = {
  appId: null,
  contentId: null,
  created: null,
  emailCampaignId: null,
  id: null,
  isGraymailSuppressionEnabled: false,
  mailingListsExcluded: ImmutableSet([]),
  mailingListsIncluded: ImmutableSet([]),
  mailingIlsListsExcluded: ImmutableSet([]),
  mailingIlsListsIncluded: ImmutableSet([]),
  numIncluded: 0,
  processingFinished: null,
  processingStarted: null,
  publishedById: null,
  sendAt: null,
  sendId: '',
  sendNumber: 0,
  status: '',
  updated: null,
  vidsIncluded: ImmutableSet([]),
  vidsExcluded: ImmutableSet([])
};
class EmailCampaignSend extends Record(defaults) {
  static from(json) {
    if (typeof json.mailingListsIncluded !== 'undefined') {
      json.mailingListsIncluded = ImmutableSet(json.mailingListsIncluded);
    }
    if (typeof json.mailingListsExcluded !== 'undefined') {
      json.mailingListsExcluded = ImmutableSet(json.mailingListsExcluded);
    }
    if (typeof json.mailingIlsListsIncluded !== 'undefined') {
      json.mailingIlsListsIncluded = ImmutableSet(json.mailingIlsListsIncluded);
    }
    if (typeof json.mailingIlsListsExcluded !== 'undefined') {
      json.mailingIlsListsExcluded = ImmutableSet(json.mailingIlsListsExcluded);
    }
    if (typeof json.vidsIncluded !== 'undefined') {
      json.vidsIncluded = ImmutableSet(json.vidsIncluded);
    }
    if (typeof json.vidsExcluded !== 'undefined') {
      json.vidsExcluded = ImmutableSet(json.vidsExcluded);
    }
    return new EmailCampaignSend(json);
  }
  mergeWith(primaryEmail) {
    return primaryEmail.set('created', this.created).set('updated', this.updated).set('publishDate', this.sendAt || this.processingStarted).set('publishedById', this.publishedById).set('mailingListsIncluded', this.mailingListsIncluded).set('mailingListsExcluded', this.mailingListsExcluded).set('mailingIlsListsIncluded', this.mailingIlsListsIncluded).set('mailingIlsListsExcluded', this.mailingIlsListsExcluded).set('vidsIncluded', this.vidsIncluded).set('vidsExcluded', this.vidsExcluded);
  }
}
export default EmailCampaignSend;