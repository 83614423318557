export function getIsPending({
  emptyStateType,
  hasEmptyState
}) {
  // There is data to be rendered
  if (emptyStateType === undefined && hasEmptyState === false) {
    return false;
  }
  if (emptyStateType !== undefined && hasEmptyState !== undefined) {
    return false;
  }
  return true;
}