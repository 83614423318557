import { getPropertyValidators } from './getPropertyValidators';
import { INVALID, UNKNOWN, VALID } from './PropertyValidationStatus';
const mergeValidationResults = (validationResults, validationRequest) => {
  const initialValidationResults = {
    errors: [],
    validationRequest,
    validationStatus: VALID
  };
  return validationResults.reduce((acc, singleValidationResults) => {
    if (singleValidationResults.validationStatus === INVALID) {
      acc.validationStatus = INVALID;
    }
    if (singleValidationResults.validationStatus === UNKNOWN && acc.validationStatus !== INVALID) {
      acc.validationStatus = UNKNOWN;
    }
    if (singleValidationResults.errors) {
      acc.errors = [...acc.errors, ...singleValidationResults.errors];
    }
    return acc;
  }, initialValidationResults);
};
export const validateProperty = (validationRequest, httpClient) => {
  const validators = getPropertyValidators(validationRequest);
  return Promise.all(validators.map(validator => validator(validationRequest, httpClient))).then(validationResults => {
    const ret = mergeValidationResults(validationResults, validationRequest);
    return ret;
  });
};