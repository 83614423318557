export const getProductInfoFromSkuId = (products, id) => {
  return products.find(product => {
    return product.productVersions.find(productVersion => {
      return productVersion.skuId === id;
    });
  }) || null;
};
export const getProductInfoFromQuantitySkuId = (products, id) => {
  return products.find(product => {
    return product.productVersions.find(productVersion => {
      return productVersion.quantityPackDetails.find(quantityPack => {
        return quantityPack.skuId === id;
      });
    });
  }) || null;
};
export const getSkuMap = products => {
  const skus = {};
  for (const product of products) {
    for (const version of product.productVersions) {
      const productVersion = Object.assign({}, version, {
        quantityPackDetails: version.quantityPackDetails.map(quantityPack => Object.assign({}, quantityPack, {
          productVersionSkuId: version.skuId
        }))
      });
      skus[productVersion.skuId] = productVersion;
      for (const quantityPack of productVersion.quantityPackDetails) {
        skus[quantityPack.skuId] = quantityPack;
      }
    }
  }
  return skus;
};
export const getProductMap = products => {
  const productMap = products.reduce((acc, product) => {
    const {
      id
    } = product;
    return Object.assign({}, acc, {
      [id]: Object.assign({}, product, {
        productVersions: product.productVersions.map(({
          skuId
        }) => skuId)
      })
    });
  }, {});
  return productMap;
};