import { useCallback, useEffect, useMemo, useState } from 'react';
import { SharedPipelinesCache } from '../../../panels/PipelinesCache';
import { validateStageChange } from '../utils/pipelineValidationUtils';
import { useHttpClient } from '../../../client/HttpClientContext';
import { useObjectTypeId } from '../../../v2/hooks/useObjectTypeId';
export const useGetStageChangeValidator = () => {
  const [status, setStatus] = useState({
    data: null,
    loading: true
  });
  const httpClient = useHttpClient();
  const objectTypeId = useObjectTypeId();
  useEffect(() => {
    if (status.data === null) {
      SharedPipelinesCache.get({
        objectTypeId,
        httpClient
      }).then(cachedPipelines => setStatus({
        data: cachedPipelines,
        loading: false
      })).catch(error => setStatus({
        data: {},
        loading: false,
        error
      }));
    }
  }, [objectTypeId, httpClient, status.data]);
  const stageChangeValidator = useCallback(request => {
    const {
      data: pipelines
    } = status;
    const {
      originStageId,
      originPipelineId,
      targetStageId,
      targetPipelineId
    } = request;
    if (pipelines && targetPipelineId && pipelines[targetPipelineId]) {
      const pipeline = pipelines[targetPipelineId];
      return validateStageChange({
        targetPipeline: pipeline,
        originStageId,
        targetStageId,
        originPipelineId
      });
    }

    // If any consumer decides to call the validator before pipelines have been loaded in state, validate open to not block changes
    return {
      isValid: true
    };
  }, [status]);
  return useMemo(() => ({
    loading: status.loading,
    stageChangeValidator
  }), [status.loading, stageChangeValidator]);
};