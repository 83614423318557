import PortalIdParser from 'PortalIdParser';
import http from 'hub-http/clients/apiClient';
import { getFullUrl } from 'hubspot-url-utils';
import Raven from 'raven-js';
export let SeatType;
(function (SeatType) {
  SeatType["CORE"] = "core";
  SeatType["PARTNER"] = "partner";
  SeatType["VIEW_ONLY"] = "view-only";
  SeatType["SALES_STARTER"] = "sales-starter";
  SeatType["SALES_PRO"] = "sales-pro";
  SeatType["SALES_PRO_TRIAL"] = "sales-pro-trial";
  SeatType["SALES_ENTERPRISE"] = "sales-enterprise";
  SeatType["SALES_ENTERPRISE_TRIAL"] = "sales-enterprise-trial";
  SeatType["SERVICE_STARTER"] = "service-starter";
  SeatType["SERVICE_PROFESSIONAL"] = "service-professional";
  SeatType["SERVICE_PROFESSIONAL_TRIAL"] = "service-professional-trial";
  SeatType["SERVICE_ENTERPRISE"] = "service-enterprise";
  SeatType["SERVICE_ENTERPRISE_TRIAL"] = "service-enterprise-trial";
})(SeatType || (SeatType = {}));
export const fetchAvailableSeats = () => {
  return http.get(`${getFullUrl('app-api')}/app-users/v1/seat-assignments/${PortalIdParser.get()}/seat-info`).catch(error => {
    console.error(['Error fetching seat-info data', error]);
    Raven.captureException(new Error('Error fetching seat-info data'), {
      extra: {
        error
      }
    });
    return [];
  });
};