export * from 'marketing-platform-lib/utils/filters';
import { NOT_IN } from 'marketing-platform-lib/utils/filters';
import { ASC, DESC } from 'framework-listing-lib/constants/sorts';
import { INITIAL_FILTER_STATE } from 'framework-listing-lib/constants/filters';
import { getInitialPagination } from '../internal/utils/pagination';

/**
 * Return the filter value for the most common filter operators (EQ, IN and NOT_IN).
 * @param {FiltersState} filters filters applied to the table.
 * @param {string} property property to return the filter value.
 * @param {any} defaultValue (optional): returned in case the filter is not found for property.
 */
export function getFilterValueByProperty(filters, property, defaultValue) {
  let filterOperator;
  if (!filters.filterGroups) {
    return defaultValue;
  }
  for (const filterGroup of filters.filterGroups) {
    filterOperator = filterGroup.filters.find(filter => filter.property === property);
    if (filterOperator) {
      break;
    }
  }
  if (!filterOperator) {
    return defaultValue;
  }
  if (filterOperator.operator === 'EQ') {
    return filterOperator.value;
  }
  if (filterOperator.operator === 'IN' || filterOperator.operator === 'NOT_IN') {
    return filterOperator.values;
  }
  if (filterOperator.operator === 'NOT_HAS_PROPERTY') {
    return undefined;
  }
  return defaultValue;
}
export function removeDuplicatedFilterOperators(filterOperators) {
  const result = [];
  for (const filterOperator of filterOperators) {
    if (result.length === 0) {
      result.push(filterOperator);
      continue;
    }
    if (result.some(filterOperatorInResult => isSameFilterOperator(filterOperatorInResult, filterOperator))) {
      continue;
    }
    result.push(filterOperator);
  }
  return result;
}
export function removeFilterOperator(filterGroup, filterOperatorToRemove) {
  return {
    filters: filterGroup.filters.filter(filterOperator => !isSameFilterOperator(filterOperator, filterOperatorToRemove))
  };
}

/**
 * Compare two filter operators.
 * @param filterOperatorA
 * @param filterOperatorB
 * @returns true when both are the exact same, false otherwise.
 */
export function isSameFilterOperator(filterOperatorA, filterOperatorB) {
  if (filterOperatorA.operator !== filterOperatorB.operator || filterOperatorA.property !== filterOperatorB.property) {
    return false;
  }

  /**
   * Operators that don't have @value
   */
  if (filterOperatorA.operator === 'HAS_PROPERTY' || filterOperatorA.operator === 'NOT_HAS_PROPERTY') {
    return true;
  }
  if (filterOperatorA.operator === 'BETWEEN' && filterOperatorB.operator === 'BETWEEN') {
    return filterOperatorA.value === filterOperatorB.value && filterOperatorA.highValue === filterOperatorB.highValue;
  }
  if (filterOperatorA.operator === 'ROLLING_DATE_RANGE' && filterOperatorB.operator === 'ROLLING_DATE_RANGE' || filterOperatorA.operator === 'TIME_UNIT_TO_DATE' && filterOperatorB.operator === 'TIME_UNIT_TO_DATE') {
    return filterOperatorA.inclusive === filterOperatorB.inclusive && filterOperatorA.rollForward === filterOperatorB.rollForward && filterOperatorA.rollingOffset === filterOperatorB.rollingOffset && filterOperatorA.timeUnit === filterOperatorB.timeUnit && filterOperatorA.timeUnitCount === filterOperatorB.timeUnitCount;
  }

  /**
   * Operators that have @value
   * Covers: EQ, GT, GTE, LT, LTE
   */
  if ('value' in filterOperatorA && 'value' in filterOperatorB) {
    return filterOperatorA.value === filterOperatorB.value;
  }

  /**
   * Operators that have @values
   * Covers: IN, NOT_IN
   */
  if ('values' in filterOperatorA && 'values' in filterOperatorB) {
    const {
      values: valuesA
    } = filterOperatorA;
    const {
      values: valuesB
    } = filterOperatorB;
    return valuesA.length === valuesB.length && valuesA.every(item => valuesB.includes(item)) && valuesB.every(item => valuesA.includes(item));
  }
  return false;
}
export function areSameFilterOperators(filterGroupA, filterGroupB) {
  if (filterGroupA.length !== filterGroupB.length) {
    return false;
  }
  return filterGroupA.every(filterOperatorA => filterGroupB.some(filterOperatorB => isSameFilterOperator(filterOperatorA, filterOperatorB)));
}
export function isSameFilterGroup(filterGroupA, filterGroupB) {
  if (filterGroupA.filters.length !== filterGroupB.filters.length) {
    return false;
  }
  return filterGroupA.filters.every(filterOperatorA => filterGroupB.filters.some(filterOperatorB => isSameFilterOperator(filterOperatorA, filterOperatorB)));
}
export function areSameFilterGroups(filterGroupsA, filterGroupsB) {
  if (filterGroupsA.length !== filterGroupsB.length) {
    return false;
  }
  return filterGroupsA.every(filterGroupA => filterGroupsB.some(filterGroupB => isSameFilterGroup(filterGroupA, filterGroupB)));
}

/**
 * Returns true when all filter operators in @filterGroupA exist in @filterGroupB
 */
export function containsFilterGroup(filterGroupA, filterGroupB) {
  return filterGroupA.filters.every(filterOperatorA => filterGroupB.filters.some(filterOperatorB => isSameFilterOperator(filterOperatorA, filterOperatorB)));
}

/**
 * Remove an entire filter group from the source @filterGroups.
 * Will only remove filter groups where all filter operators are present ➡️ partial filter groups won't be taken into account.
 * @param filterGroups The source filter groups
 * @param filterGroupsToRemove The filter groups to be removed
 */
export function removeFilterGroups(filterGroups, filterGroupsToRemove) {
  let result = [...filterGroups];
  for (const filterGroupToRemove of filterGroupsToRemove) {
    /**
     * Match each group with its own index so we can easily remove filter operators later on
     */
    let filterGroupsWithIndex = result.map((filterGroup, index) => ({
      index,
      filterGroup
    }));

    /**
     * Keep only groups that contain all elements in @filterGroupsToRemove
     */
    filterGroupsWithIndex = filterGroupsWithIndex.filter(filterGroupWithIndex => {
      return containsFilterGroup(filterGroupToRemove, filterGroupWithIndex.filterGroup);
    });

    /**
     * From the result above, update @result by removing all items in @filterGroupToRemove
     */
    for (const filterGroupWithIndex of filterGroupsWithIndex) {
      const {
        filterGroup,
        index
      } = filterGroupWithIndex;
      result[index] = Object.assign({}, result[index], {
        filters: filterGroup.filters.filter(filterOperator => {
          return !filterGroupToRemove.filters.some(filterOperatorToRemove => isSameFilterOperator(filterOperatorToRemove, filterOperator));
        })
      });
    }

    /**
     * Ignore empty filter groups
     */
    result = result.filter(fg => !isEmptyFilterGroup([fg]));
  }
  if (result.length === 0) {
    return [{
      filters: []
    }];
  }
  return result;
}
export function isEmptyFilterGroup(filterGroups) {
  if (filterGroups.length === 0) {
    return true;
  }
  return filterGroups.every(filterGroup => filterGroup.filters.length === 0);
}

/**
 * Combines @customFilters to @appFilters.
 * Useful when applying hiddenFilter config to all filter conditions in the UI
 * @param appFilters
 * @param customFilters
 * @returns
 */
export function combineFilters(appFilters, customFilters) {
  if (isEmptyFilterGroup(customFilters)) {
    return appFilters;
  }
  if (isEmptyFilterGroup(appFilters)) {
    return customFilters;
  }
  const result = [];
  for (const appFilterGroup of appFilters) {
    for (const customFilterGroup of customFilters) {
      result.push({
        filters: removeDuplicatedFilterOperators([...appFilterGroup.filters, ...customFilterGroup.filters])
      });
    }
  }
  return result;
}

/**
 * Returns a flat list of filterOperators in @filterGroups
 * @param filterGroups
 */
export function flattenFilterGroups(filterGroups) {
  const allFilterOperators = filterGroups.map(filterGroup => filterGroup.filters).flat();
  return removeDuplicatedFilterOperators(allFilterOperators);
}
export function removeDuplicatedFilterGroups(filterGroups) {
  if (filterGroups.length <= 1) {
    return filterGroups;
  }
  const result = [];
  for (const filterGroup of filterGroups) {
    if (result.length === 0) {
      result.push(filterGroup);
      continue;
    }
    if (result.some(filterGroupInResult => isSameFilterGroup(filterGroupInResult, filterGroup))) {
      continue;
    }
    result.push(filterGroup);
  }
  return result;
}

/**
 * Remove all filter operators for @propertyName
 * @param filterGroups
 * @param propertyName
 */
export function removePropertyFilters(filterGroups, propertyName) {
  const result = [];
  for (const filterGroup of filterGroups) {
    const filters = filterGroup.filters.filter(filter => filter.property !== propertyName);
    if (filters.length === 0) {
      continue;
    }
    result.push({
      filters
    });
  }
  if (result.length === 0) {
    result.push({
      filters: []
    });
  }
  return result;
}
function updateFilterStateWithFilterOperator(previousFilterState, updatedFilter) {
  const updatedFilterGroups = removePropertyFilters(previousFilterState.filterGroups, updatedFilter.property);
  return combineFilters(updatedFilterGroups, [{
    filters: [updatedFilter]
  }]);
}
function updateFilterStateWithFilterGroup(previousFilterState, filterGroups) {
  if (isEmptyFilterGroup(filterGroups)) {
    return previousFilterState.filterGroups;
  }
  const allPropertiesInFilter = flattenFilterGroups(filterGroups).map(filter => filter.property);
  const isSinglePropertyFilter = Array.from(new Set(allPropertiesInFilter)).length === 1;
  if (!isSinglePropertyFilter) {
    return filterGroups;
  }
  const [propertyName] = allPropertiesInFilter;
  const updatedFilterGroups = removePropertyFilters(previousFilterState.filterGroups, propertyName);
  return combineFilters(updatedFilterGroups, filterGroups);
}

/**
 * Refer to useCrmObjectTypeListingFilters (onUpdateFilters) for a complete documentation of this method.
 * @param previousFilterState
 * @param updatedFilter
 */
export function updateFilterState(previousFilterState, updatedFilter) {
  if ('operator' in updatedFilter) {
    return Object.assign({}, previousFilterState, {
      filterGroups: updateFilterStateWithFilterOperator(previousFilterState, updatedFilter),
      pagination: {
        count: previousFilterState.pagination.count,
        offset: 0
      }
    });
  } else if (Array.isArray(updatedFilter)) {
    return Object.assign({}, previousFilterState, {
      filterGroups: updateFilterStateWithFilterGroup(previousFilterState, updatedFilter),
      pagination: {
        count: previousFilterState.pagination.count,
        offset: 0
      }
    });
  }
  return Object.assign({}, previousFilterState, updatedFilter, {
    pagination: {
      count: updatedFilter.pagination ? updatedFilter.pagination.count : previousFilterState.pagination.count,
      offset: updatedFilter.pagination ? updatedFilter.pagination.offset : 0
    }
  });
}
export function makeFiltersState({
  initialFilter,
  currentView,
  uiMode
}) {
  const viewFilterState = viewToFilterState(currentView);
  return combineFilterStates({
    initialFilter,
    newFilter: viewFilterState,
    uiMode
  });
}
export function getAggregatedFilterGroups({
  deletedObjects,
  filterGroups,
  hiddenFilter,
  includeHiddenFilter,
  quickFilters
}) {
  let aggregatedFilterGroups = isEmptyFilterGroup(filterGroups) ? [{
    filters: []
  }] : [...filterGroups];

  // Merge with @deletedObjects
  aggregatedFilterGroups = combineFilters(aggregatedFilterGroups, [{
    filters: Array.isArray(deletedObjects) && deletedObjects.length > 0 ? [NOT_IN('hs_object_id', deletedObjects)] : []
  }]);

  // Merge with quickFilters
  if (quickFilters.length) {
    aggregatedFilterGroups = combineFilters(aggregatedFilterGroups, [{
      filters: quickFilters
    }]);
  }

  // Merge with hidden filters
  if (hiddenFilter && includeHiddenFilter) {
    aggregatedFilterGroups = combineFilters(aggregatedFilterGroups, hiddenFilter.filterGroups);
  }

  /**
   * Make sure we don't leak hidden filters that exist in @filterGroups
   */
  if (hiddenFilter && !includeHiddenFilter) {
    const combinedFilters = combineFilters(aggregatedFilterGroups, hiddenFilter.filterGroups);
    aggregatedFilterGroups = removeFilterGroups(combinedFilters, hiddenFilter.filterGroups);
  }
  return aggregatedFilterGroups;
}

/**
 * This function accepts both the initial filters passed in from the provider and the filters from the current
 * view. It will then remove any filters from the current view that are filtering a property that the initial filter is
 * also filtering. It then returns the new current view filters.
 * @param initialQuickFilters
 * @param currentViewQuickFilters
 */
export function excludeFiltersOfSameProperty(initialQuickFilters, currentViewQuickFilters) {
  const initialFilterProperties = initialQuickFilters.map(filterOperator => filterOperator.property);
  const newViewFilters = currentViewQuickFilters.filter(filterOperator => !initialFilterProperties.includes(filterOperator.property));
  return flattenFilterGroups(combineFilters([{
    filters: initialQuickFilters
  }], [{
    filters: newViewFilters
  }]));
}
export function viewToFilterState(view) {
  const safeSort = view.state.sortKey && view.state.order !== undefined ? {
    property: view.state.sortKey,
    order: view.state.order === 1 ? DESC : ASC
  } : INITIAL_FILTER_STATE.sort;
  return {
    filterGroups: view.filterGroups && view.filterGroups.length ? view.filterGroups : [{
      filters: view.filters
    }],
    quickFilters: view.quickFilters || [],
    sort: safeSort
  };
}
export function combineFilterStates({
  initialFilter,
  newFilter,
  uiMode
}) {
  const filterGroupsFromInitialFilter = initialFilter && initialFilter.filterGroups ? initialFilter.filterGroups : [{
    filters: []
  }];
  const newFilterGroups = newFilter.filterGroups && newFilter.filterGroups.length ? newFilter.filterGroups : [{
    filters: []
  }];
  const quickFiltersFromInitialFilter = initialFilter && initialFilter.quickFilters ? initialFilter.quickFilters : INITIAL_FILTER_STATE.quickFilters;
  const newQuickFilters = newFilter.quickFilters || [];
  const newSortOrder = newFilter.sort && newFilter.sort.order && newFilter.sort.order === 'DESC' ? DESC : ASC;
  let sortingProperty = INITIAL_FILTER_STATE.sort.property;
  if (initialFilter && initialFilter.sort) {
    sortingProperty = initialFilter.sort.property;
  } else if (newFilter && newFilter.sort) {
    sortingProperty = newFilter.sort.property;
  }
  return Object.assign({}, INITIAL_FILTER_STATE, {
    filterGroups: combineFilters(filterGroupsFromInitialFilter, newFilterGroups),
    requestOptions: initialFilter && initialFilter.requestOptions ? initialFilter.requestOptions : INITIAL_FILTER_STATE.requestOptions,
    sort: {
      property: sortingProperty,
      order: initialFilter && initialFilter.sort ? initialFilter.sort.order : newSortOrder
    },
    pagination: getInitialPagination({
      initialFilter,
      uiMode
    }),
    query: initialFilter && initialFilter.query ? initialFilter.query : INITIAL_FILTER_STATE.query,
    quickFilters: excludeFiltersOfSameProperty(quickFiltersFromInitialFilter, newQuickFilters),
    associationPreviews: initialFilter && initialFilter.associationPreviews ? initialFilter.associationPreviews : INITIAL_FILTER_STATE.associationPreviews
  });
}