export let FeatureSurveyKeys;
(function (FeatureSurveyKeys) {
  FeatureSurveyKeys["FORECASTS"] = "forecasts";
  FeatureSurveyKeys["PRODUCTS"] = "products";
  FeatureSurveyKeys["WORKFLOWS"] = "workflows";
  FeatureSurveyKeys["QUOTES"] = "quotes";
  FeatureSurveyKeys["SEQUENCES"] = "sequences";
  FeatureSurveyKeys["SALES_ANALYTICS"] = "salesAnalytics";
  FeatureSurveyKeys["CUSTOM_REPORTS_BUILDER"] = "customReportsBuilder";
  FeatureSurveyKeys["SOCIAL"] = "social";
  FeatureSurveyKeys["CAMPAIGNS"] = "campaigns";
  FeatureSurveyKeys["SERVICE_ESSENTIALS"] = "serviceEssentials";
  FeatureSurveyKeys["CUSTOMER_PORTAL"] = "customerPortal";
  FeatureSurveyKeys["KNOWLEDGE_BASE"] = "knowledgeBase";
  FeatureSurveyKeys["SLA"] = "sla";
  FeatureSurveyKeys["AUTOMATE_FORMATTING"] = "automateFormatting";
  FeatureSurveyKeys["WEBHOOKS"] = "webhooks";
  FeatureSurveyKeys["CUSTOM_CODED_WORKFLOW_ACTIONS"] = "customCodedWorkflowActions";
  FeatureSurveyKeys["TRIGGER_WORKFLOWS"] = "triggerWorkflows";
  FeatureSurveyKeys["DATA_QUATLITY_COMMAND_CENTER"] = "dataQualityCommandCenter";
  FeatureSurveyKeys["OTHER"] = "other";
  FeatureSurveyKeys["CONTENT_ESSENTIALS"] = "contentEssentials";
  FeatureSurveyKeys["CONTENT_CREATION"] = "contentCreation";
  FeatureSurveyKeys["CONTENT_STRATEGY"] = "contentStrategy";
  FeatureSurveyKeys["PERSONALIZATION"] = "personalization";
  FeatureSurveyKeys["SEO"] = "seo";
})(FeatureSurveyKeys || (FeatureSurveyKeys = {}));