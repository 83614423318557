import { useCallback, useMemo } from 'react';
import { useTracker } from 'framework-listing-lib/internal/providers/TrackerProvider';
import useUIMode from 'framework-listing-lib/internal/hooks/useUIMode';
import listingLibPerformanceTracking from 'framework-listing-lib/internal/utils/performanceTracking';
import useObjectTypeId from 'framework-listing-lib/internal/hooks/useObjectTypeId';
export default function useCrmObjectTypeTracking() {
  const objectType = useObjectTypeId();
  const uiModeProps = useUIMode();
  const {
    applicationId,
    tracker,
    onTrackEvent
  } = useTracker();
  const trackCrmTableInteraction = useCallback(eventProps => {
    if (onTrackEvent) {
      onTrackEvent(eventProps.action, eventProps);
    }
    if (!tracker) {
      return;
    }
    tracker.track('crmTableInteraction', Object.assign({
      applicationId,
      objectType,
      uiMode: uiModeProps.currentUIMode
    }, eventProps));
  }, [applicationId, objectType, onTrackEvent, tracker, uiModeProps.currentUIMode]);
  const trackFilterEditorInteraction = useCallback(eventProps => {
    if (onTrackEvent) {
      onTrackEvent(eventProps.action, eventProps);
    }
    if (!tracker) {
      return;
    }
    tracker.track('filterEditorInteraction', Object.assign({
      applicationId,
      objectType
    }, eventProps));
  }, [applicationId, objectType, onTrackEvent, tracker]);
  const trackPerformanceInteraction = useCallback(() => {
    listingLibPerformanceTracking.trackLibPerformance({
      applicationId,
      objectTypeId: objectType,
      tracker
    });
  }, [applicationId, objectType, tracker]);
  const value = useMemo(() => ({
    trackCrmTableInteraction,
    trackFilterEditorInteraction,
    trackPerformanceInteraction
  }), [trackCrmTableInteraction, trackFilterEditorInteraction, trackPerformanceInteraction]);
  return value;
}