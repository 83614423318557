import Raven from 'raven-js';
import { IGNORED_PURCHASE_ORDER_GENERATION_ERRORS } from 'self-service-api/constants/Errors';
export default function logPurchaseOrderSentry(location, err, additionalProperties) {
  const {
    errorCode,
    errorMessage,
    message,
    responseJSON,
    status,
    data
  } = err;
  const errorType = responseJSON && responseJSON.errorType;
  const shouldLogSentry = errorType ? !IGNORED_PURCHASE_ORDER_GENERATION_ERRORS[errorType] : true;
  if (shouldLogSentry) {
    Raven.captureException(new Error('Purchase order generation failed'), {
      extra: Object.assign({
        location,
        errorCode,
        errorMessage,
        message,
        responseJSON,
        status,
        data
      }, additionalProperties)
    });
  }
}