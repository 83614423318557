import { useCrmObjectType } from 'framework-listing-lib/internal/providers/CrmObjectTypeListingClientProvider';
function useSearchInputProps() {
  const {
    searchInputProps
  } = useCrmObjectType();
  return searchInputProps;
}
export function useHasSearchInput() {
  const searchInputProps = useSearchInputProps();
  return Boolean(searchInputProps.hasSearchInput);
}
export function useLegacySearchInput() {
  const searchInputProps = useSearchInputProps();
  return Boolean(searchInputProps.legacySearchInput);
}
export function useOnQueryChange() {
  const searchInputProps = useSearchInputProps();
  return searchInputProps.onQueryChange;
}
export function useSearchInputPlaceholder() {
  const searchInputProps = useSearchInputProps();
  return searchInputProps.placeholder;
}
export function useSearchInputWidth() {
  const searchInputProps = useSearchInputProps();
  return searchInputProps.width;
}
export function useIsSearchInputDisabled() {
  const searchInputProps = useSearchInputProps();
  return Boolean(searchInputProps.disabled);
}