import http from 'hub-http/clients/apiClient';
import Raven from 'raven-js';
let _cachedProducts;
export const resetProductCache = () => {
  _cachedProducts = null;
};
export const getProducts = () => {
  if (_cachedProducts) {
    return _cachedProducts;
  }
  _cachedProducts = http.get('monetization/v3/product/in-app').catch(error => {
    resetProductCache();
    if (error && error.status === 0) {
      return;
    }
    Raven.captureException(new Error('Failed to fetch products'), {
      extra: {
        error
      }
    });
  });
  return _cachedProducts;
};