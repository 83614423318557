export const BASE_EMAIL_OBJECT_TYPE = {
  AUTOMATED_EMAIL: 'automated',
  BATCH_EMAIL: 'batch',
  BLOG_EMAIL: 'blog',
  FOLLOWUP_EMAIL: 'followup',
  AB_EMAIL: 'ab',
  AUTOMATED_AB_EMAIL: 'automatedAb',
  RSS_EMAIL: 'rss',
  LOCALTIME_EMAIL: 'timezone'
};
export const HS_TYPES = {
  AUTOMATED_EMAIL: 'AUTOMATED_EMAIL',
  BATCH_EMAIL: 'BATCH_EMAIL',
  BLOG_EMAIL: 'BLOG_EMAIL',
  FOLLOWUP_EMAIL: 'FOLLOWUP_EMAIL',
  AB_EMAIL: 'AB_EMAIL',
  AUTOMATED_AB_EMAIL: 'AUTOMATED_AB_EMAIL',
  RSS_EMAIL: 'RSS_EMAIL',
  LOCALTIME_EMAIL: 'LOCALTIME_EMAIL'
};