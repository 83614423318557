'use es6';

import * as FilterContactSearch from 'customer-data-filters/converters/contactSearch/FilterContactSearch';
import * as ObjectTypeIds from 'customer-data-objects/constants/ObjectTypeIds';
import * as PropertyTypes from 'customer-data-objects/property/PropertyTypes';
import { List, OrderedMap } from 'immutable';
import And from 'customer-data-filters/filterQueryFormat/logic/And';
import MissingField from 'customer-data-filters/filterQueryFormat/MissingField';
import Or from 'customer-data-filters/filterQueryFormat/logic/Or';
import getIn from 'transmute/getIn';
import invariant from 'react-utils/invariant';
import { resolveIsUngated } from '../../utilities/UserInfoHelper';
const getFilter = (condition, getIsUngated) => FilterContactSearch.convertToContactSearch(condition, getIsUngated);
const getFilterGroup = (logicGroup, getIsUngated) => ({
  filters: logicGroup.conditions.map(condition => getFilter(condition, getIsUngated)).toArray()
});
const getFilterGroups = (logicGroup, getIsUngated) => ({
  filterGroups: logicGroup.conditions.map(condition => getFilterGroup(condition, getIsUngated)).toArray()
});
export function toCRMSearch(logicGroup, getIsUngated) {
  const {
    ObjectTypesToIds
  } = ObjectTypeIds;
  const validIsUngated = resolveIsUngated(getIsUngated);
  const filter = getFilterGroups(logicGroup, validIsUngated);
  const filterFamily = logicGroup.get('filterFamily');
  const objectTypeId = ObjectTypesToIds[filterFamily] || filterFamily;
  return Object.assign({}, filter, {
    objectTypeId
  });
}
const makeOperator = (condition, index, objectTypeId, getField, getIsUngated) => {
  const convertEntry = (filterFamily, entry) => {
    const fieldRecord = getField([filterFamily, entry.property]) || getField([entry.property]) || MissingField({
      name: entry.property,
      type: PropertyTypes[entry.dateTimeFormat] || PropertyTypes.STRING
    });

    // converts new format to old format
    if (entry.property === 'associations.labeled') {
      const {
        propertySuffix,
        operator,
        value
      } = entry;
      return convertEntry(filterFamily, {
        operator,
        property: `associations.${propertySuffix}`,
        value: `${value}`
      });
    }
    const Operator = FilterContactSearch.TypeToOperator.get(entry.operator);
    invariant(Operator !== undefined, 'contact search operator `%s` is not defined in OperatorConversion', entry.operator);
    const converter = FilterContactSearch.convertFromContactSearch(getIsUngated).get(entry.operator);
    invariant(typeof converter === 'function', 'no converter defined from `%s` to `%s` operator', entry.operator, Operator.toString());
    return converter(Operator, fieldRecord, entry);
  };
  const operator = convertEntry(objectTypeId, condition);
  return [`${operator.field.name} ${operator.name} ${index}`, operator];
};
const makeLogicGroup = (filterGroup, objectTypeId, getField, getIsUngated) => {
  return And({
    conditions: OrderedMap(filterGroup.filters.map((condition, index) => makeOperator(condition, index, objectTypeId, getField, getIsUngated)))
  }).set('filterFamily', objectTypeId);
};
export function fromCRMSearch(fields, json, defaultObjectTypeId = ObjectTypeIds.CONTACT_TYPE_ID, getIsUngated) {
  const getField = typeof fields === 'function' ? fields : keyPath => getIn(keyPath, fields);
  if (!fields || !json) {
    return Or();
  }
  const {
    objectTypeId = defaultObjectTypeId,
    filterGroups
  } = json;
  const validIsUngated = resolveIsUngated(getIsUngated);
  return Or({
    conditions: List(filterGroups.map(group => makeLogicGroup(group, objectTypeId, getField, validIsUngated)))
  }).set('filterFamily', objectTypeId);
}