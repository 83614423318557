import { useCallback } from 'react';
import { registerMutation, useMutation } from 'data-fetching-client';
import { setSuperstoreValue } from 'framework-listing-lib/internal/utils/superstore';
import { GET_USER_SETTINGS_FIELD_NAME } from './useFetchUserSettings';
const SET_USER_SETTINGS_FIELD_NAME = 'userSettingsWrite';
const SET_USER_SETTINGS = registerMutation({
  fieldName: SET_USER_SETTINGS_FIELD_NAME,
  args: ['key', 'value'],
  fetcher({
    key,
    value
  }) {
    return setSuperstoreValue(key, {
      key,
      value
    });
  }
});
export default function useSaveUserSettings() {
  const [saveUserSettingsMutation] = useMutation(SET_USER_SETTINGS, {
    update(cache, {
      data: newData
    }) {
      const {
        [SET_USER_SETTINGS_FIELD_NAME]: newUserSetting
      } = newData || {};
      if (!newUserSetting) {
        return;
      }
      cache.modify({
        fields: {
          [GET_USER_SETTINGS_FIELD_NAME]: userSettingRef => {
            if (userSettingRef.key === newUserSetting.key) {
              return newUserSetting;
            }
            return userSettingRef;
          }
        }
      });
    }
  });
  return useCallback(({
    key,
    value
  }) => {
    return saveUserSettingsMutation({
      variables: {
        key,
        value
      }
    });
  }, [saveUserSettingsMutation]);
}