'use es6';

import { Record } from 'immutable';
const defaults = {
  id: '',
  portalId: 0,
  label: ''
};
class LandingPage extends Record(defaults) {
  static from(json) {
    return new LandingPage(json);
  }
}
export default LandingPage;