import { createTracker } from 'usage-tracker';
import events from '../../events.yaml';
import { USER_INTERACTION } from '../constants/tracking';
export const tracker = createTracker({
  events
});
export const trackUserInteraction = (action, coachingTipId, tipId, subAction) => {
  if (!action || !coachingTipId || !tipId) {
    return;
  }
  const eventProps = Object.assign({
    action,
    coachingTipId,
    tipId
  }, subAction && {
    subAction
  });
  tracker.track(USER_INTERACTION, eventProps);
};