/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-misused-promises */

// @ts-expect-error not migrated to TS yet
import * as rssFeedUrlValidationApi from 'EmailData/api/rssFeedUrlValidation';
// @ts-expect-error not migrated to TS yet
import * as smartInsightsApi from 'EmailData/api/smartInsights';
// @ts-expect-error not migrated to TS yet
import { getRssFeedUrlValidationResult } from 'EmailData/selectors/rssFeedUrlValidationSelectors';
// @ts-expect-error not migrated to TS yet
import { getSmartInsightsLoaded } from 'EmailData/selectors/smartInsightsSelectors';
import http from 'hub-http/clients/apiClient';
import portalStatusApi from 'EmailData/api/portalStatus';
import * as blogsApi from 'EmailData/api/blogs';
import { hasRecipients } from 'EmailData/utils/functionUtils';
import { FETCH_PORTAL_EMAIL_SEND_CREDITS_START, FETCH_PORTAL_EMAIL_SEND_CREDITS_SUCCESS, FETCH_PORTAL_EMAIL_SEND_CREDITS_FAIL, FETCH_PORTAL_TRIALS_START, FETCH_PORTAL_TRIALS_SUCCESS, FETCH_PORTAL_TRIALS_FAIL, FETCH_BLOG_EMAILS_START, FETCH_BLOG_EMAILS_SUCCESS, FETCH_BLOG_EMAILS_FAIL, RESET_BLOG_EMAILS, FETCH_SMART_INSIGHTS_START, FETCH_SMART_INSIGHTS_FAIL, FETCH_SMART_INSIGHTS_SUCCESS, FETCH_SMART_INSIGHTS_RESET, FETCH_RSS_URL_FEED_VALIDATION_START, FETCH_RSS_URL_FEED_VALIDATION_SUCCESS, FETCH_RSS_URL_FEED_VALIDATION_FAIL, RESET_RSS_FEED_URL_VALIDATION } from './actionTypes';
import Raven from 'raven-js';
export function fetchPortalSendCredits() {
  return dispatch => {
    dispatch({
      type: FETCH_PORTAL_EMAIL_SEND_CREDITS_START
    });
    return portalStatusApi.fetchSendCredits().then(response => {
      dispatch({
        type: FETCH_PORTAL_EMAIL_SEND_CREDITS_SUCCESS,
        payload: response
      });
    }, err => {
      dispatch({
        type: FETCH_PORTAL_EMAIL_SEND_CREDITS_FAIL,
        payload: err
      });
    });
  };
}
export function fetchPortalTrials() {
  return dispatch => {
    dispatch({
      type: FETCH_PORTAL_TRIALS_START
    });
    return portalStatusApi.fetchPortalTrials().then(response => {
      dispatch({
        type: FETCH_PORTAL_TRIALS_SUCCESS,
        payload: response
      });
    }).catch(err => {
      dispatch({
        type: FETCH_PORTAL_TRIALS_FAIL,
        payload: err
      });
    });
  };
}
export const urlFeedValidationSuccess = response => ({
  type: FETCH_RSS_URL_FEED_VALIDATION_SUCCESS,
  response
});
export const urlFeedValidationFail = (error, url) => ({
  type: FETCH_RSS_URL_FEED_VALIDATION_FAIL,
  error,
  feedUrl: url
});
export const validateRssUrl = url => {
  return (dispatch, getState, {
    httpClient = http
  } = {}) => {
    const state = getState();
    const validationResult = getRssFeedUrlValidationResult(state);
    const lastValidatedUrl = validationResult.get('feedUrl');
    const lastStatus = validationResult.get('feedResultStatus');
    const shouldValidate = url !== lastValidatedUrl || lastStatus.length === 0;
    if (!shouldValidate) {
      return Promise.resolve();
    }
    if (url.length === 0) {
      dispatch({
        type: FETCH_RSS_URL_FEED_VALIDATION_SUCCESS,
        response: {
          feedResultStatus: 'REQUIRED',
          feedUrl: ''
        }
      });
      return Promise.resolve();
    }
    dispatch({
      type: FETCH_RSS_URL_FEED_VALIDATION_START
    });
    return rssFeedUrlValidationApi.validateRssUrl(url, httpClient).then(response => {
      dispatch(urlFeedValidationSuccess(response));
    }, error => {
      dispatch(urlFeedValidationFail(error, url));
    });
  };
};
export const resetRssFeedUrlValidation = () => ({
  type: RESET_RSS_FEED_URL_VALIDATION
});
export const resetBlogEmails = () => ({
  type: RESET_BLOG_EMAILS
});
export const fetchBlogEmails = blogId => {
  return dispatch => {
    dispatch({
      type: FETCH_BLOG_EMAILS_START
    });
    return blogsApi.getEmailsForBlog(blogId).then(response => {
      dispatch({
        type: FETCH_BLOG_EMAILS_SUCCESS,
        response
      });
    }, error => {
      dispatch({
        type: FETCH_BLOG_EMAILS_FAIL,
        error
      });
    });
  };
};
export const fetchSmartInsights = (props, smartType, forceReload = false) => {
  return (dispatch, getState) => {
    const state = getState();
    if (!forceReload && getSmartInsightsLoaded(state)) {
      return;
    }
    dispatch({
      type: FETCH_SMART_INSIGHTS_START
    });
    if (!hasRecipients(props)) {
      dispatch({
        type: FETCH_SMART_INSIGHTS_RESET
      });
    } else {
      const query = {
        includedVids: props.vidsIncluded,
        excludedVids: props.vidsExcluded,
        includedIlsIds: props.mailingIlsListsIncluded,
        excludedIlsIds: props.mailingIlsListsExcluded,
        smartType
      };
      smartInsightsApi.fetchRecipientsTimezones(query).then(response => {
        dispatch({
          type: FETCH_SMART_INSIGHTS_SUCCESS,
          payload: response
        });
      }, error => {
        dispatch({
          type: FETCH_SMART_INSIGHTS_FAIL,
          error
        });
      }).catch(e => Raven.captureException(e));
    }
  };
};