import { registerQuery } from 'data-fetching-client';
import { fetchTranslationInfoRequest } from 'email-management-lib/refactor/api/emails';
export const FETCH_TRANSLATION_INFO = registerQuery({
  fieldName: 'translationInfo',
  args: ['emailId'],
  fetcher({
    emailId
  }) {
    return fetchTranslationInfoRequest(emailId);
  }
});