import PropTypes from 'prop-types';

// Properties API constants (preferred values)
export const NOT_SPECIFIED = 'not_specified';
export const READ_ONLY = 'read_only';
export const HIDDEN = 'hidden';

// GQL specific constants (prefer using non-suffixed versions instead)
/** @deprecated use non-suffixed NOT_SPECIFIED instead, normalizeAccessLevel can be used to migrate */
export const NOT_SPECIFIED_GQL = 'not_specified';
/** @deprecated use non-suffixed READ_ONLY instead, normalizeAccessLevel can be used to migrate */
export const READ_ONLY_GQL = 'readOnly';
/** @deprecated use non-suffixed HIDDEN instead, normalizeAccessLevel can be used to migrate */
export const HIDDEN_GQL = 'hidden';

// CrmPermissions specific constants (prefer using non-suffixed versions instead)
/** @deprecated use non-suffixed NOT_SPECIFIED instead, normalizeAccessLevel can be used to migrate */
export const NOT_SPECIFIED_REST = '';
/** @deprecated use non-suffixed READ_ONLY instead, normalizeAccessLevel can be used to migrate */
export const READ_ONLY_REST = 'readOnly';
/** @deprecated use non-suffixed HIDDEN instead, normalizeAccessLevel can be used to migrate */
export const HIDDEN_REST = 'hidden';
export const AccessLevelPropType = PropTypes.oneOf([NOT_SPECIFIED, READ_ONLY, HIDDEN]);

// Utility type to be used while migrating to AccessLevelType

// Utility prop type to be used while migrating to AccessLevelType
export const AnyAccessLevelPropType = PropTypes.oneOf([NOT_SPECIFIED, READ_ONLY, HIDDEN, NOT_SPECIFIED_GQL, READ_ONLY_GQL, HIDDEN_GQL, NOT_SPECIFIED_REST, READ_ONLY_REST, HIDDEN_REST]);
// Converts an access level of any form into the preferred Properties API form
export function normalizeAccessLevel(accessLevel) {
  if (accessLevel === null || accessLevel === undefined) {
    return accessLevel;
  }
  switch (accessLevel) {
    case NOT_SPECIFIED:
    case NOT_SPECIFIED_GQL:
    case NOT_SPECIFIED_REST:
      return NOT_SPECIFIED;
    case READ_ONLY:
    case READ_ONLY_GQL:
    case READ_ONLY_REST:
      return READ_ONLY;
    case HIDDEN:
    case HIDDEN_GQL:
    case HIDDEN_REST:
      return HIDDEN;
    default:
      throw new Error(`Unrecognized permission level "${accessLevel}"`);
  }
}