import { useMemo } from 'react';
import useFetchUserInfo from 'framework-listing-lib/internal/hooks/useFetchUserInfo';
export default function useCrmObjectTypeScopes({
  viewAllAssetsScope = '',
  viewTeamOwnedAssetsScope = ''
}) {
  const {
    data: userInfo,
    loading: loadingUserInfo
  } = useFetchUserInfo();
  const userScopes = useMemo(() => {
    if (loadingUserInfo || !userInfo || !userInfo.user) {
      return [];
    }
    return userInfo.user.scopes;
  }, [loadingUserInfo, userInfo]);
  return useMemo(() => {
    const canViewAllAssets = userScopes.includes(viewAllAssetsScope);
    const canViewTeamOwnedAssets = canViewAllAssets || userScopes.includes(viewTeamOwnedAssetsScope);
    return {
      canFilterByTeamsOrUsers: canViewAllAssets || canViewTeamOwnedAssets,
      canViewAllAssets,
      canViewTeamOwnedAssets
    };
  }, [viewAllAssetsScope, viewTeamOwnedAssetsScope, userScopes]);
}