import { useCallback, useEffect, useState } from 'react';
import { Metrics } from 'framework-listing-lib/internal/metrics';
const EVENT_KEY = 'LISTING_LIB';
const CLOSE_DEV_TOOLS = 'CLOSE_DEV_TOOLS';
const OPEN_DEV_TOOLS = 'OPEN_DEV_TOOLS';
export default function useOpenDevTools() {
  const [openState, setOpenState] = useState(false);
  const setOpen = useCallback(open => {
    window.postMessage({
      key: EVENT_KEY,
      action: open ? OPEN_DEV_TOOLS : CLOSE_DEV_TOOLS
    });
  }, []);

  /**
   * Sample events
   *
   * window.postMessage({ key: 'LISTING_LIB', action: 'CLOSE_DEV_TOOLS' })
   * window.postMessage({ key: 'LISTING_LIB', action: 'OPEN_DEV_TOOLS' })
   */
  useEffect(() => {
    const handler = event => {
      if (!event || !event.data) {
        return;
      }
      const {
        key,
        action
      } = event.data;
      if (key !== EVENT_KEY) {
        return;
      }
      switch (action) {
        default:
          break;
        case CLOSE_DEV_TOOLS:
          setOpenState(false);
          Metrics.counter('close-dev-tools').increment();
          break;
        case OPEN_DEV_TOOLS:
          setOpenState(true);
          Metrics.counter('open-dev-tools').increment();
          break;
      }
    };
    window.addEventListener('message', handler);
    return () => window.removeEventListener('message', handler);
  }, []);
  return [openState, setOpen];
}