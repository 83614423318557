import quickFetch from 'quick-fetch';
import { ApolloLink } from '@apollo/client';
const getEarlyRequesterAsPromise = (requestName, earlyGraphQLRequest) => {
  return new Promise((resolve, reject) => {
    earlyGraphQLRequest.whenFinished(result => {
      resolve(result);
      quickFetch.removeEarlyRequest(requestName);
    });
    earlyGraphQLRequest.onError(() => {
      reject(earlyGraphQLRequest.request);
      quickFetch.removeEarlyRequest(requestName);
    });
  });
};
export default new ApolloLink((operation, forward) => {
  const {
    operationName,
    variables
  } = operation;
  const cardLocation = variables.cardLocation || '';
  // See: https://git.hubteam.com/HubSpot/CRM/blob/9621b95809e669f84172b06015331a4b42ee68f4/crm_ui/static/js/graphql-early-requester/profileGraphQLEarlyRequester.js#L51-L52
  const requestName = operationName + cardLocation + variables.objectType + variables.subjectId;
  const earlyGraphQLRequest = quickFetch.getRequestStateByName(requestName);
  if (earlyGraphQLRequest && !earlyGraphQLRequest.error) {
    operation.setContext({
      earlyRequest: getEarlyRequesterAsPromise(requestName, earlyGraphQLRequest)
    });
  }
  return forward(operation);
});