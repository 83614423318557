import { Map as ImmutableMap } from 'immutable';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { FETCH_PORTAL_BOT_STATUS_START, FETCH_PORTAL_BOT_STATUS_SUCCESS, FETCH_PORTAL_BOT_STATUS_FAIL } from 'email-management-lib/refactor/actions/actionTypes';
const initialState = ImmutableMap({
  isLoading: true,
  botFilteringEnabled: false
});
function portalBotFilterStatus(state = initialState, action) {
  switch (action.type) {
    case FETCH_PORTAL_BOT_STATUS_START:
      return state.set('isLoading', true);
    case FETCH_PORTAL_BOT_STATUS_SUCCESS:
      return state.set('isLoading', false).set('botFilteringEnabled', action.payload.botFilteringEnabled);
    case FETCH_PORTAL_BOT_STATUS_FAIL:
      return state.set('isLoading', false);
    default:
      return state;
  }
}
export default asyncResource(portalBotFilterStatus, {
  requestActions: [FETCH_PORTAL_BOT_STATUS_START],
  responseActions: [FETCH_PORTAL_BOT_STATUS_SUCCESS, FETCH_PORTAL_BOT_STATUS_FAIL],
  invalidatingActions: []
});