import { Record } from 'immutable';
const defaults = {
  name: '',
  id: ''
};
class InteractiveData extends Record(defaults) {
  static from(json) {
    return new InteractiveData(json);
  }
}
export default InteractiveData;