import I18n from 'I18n';
import http from 'hub-http/clients/apiClient';
import { useEffect, useState } from 'react';
import useUserInfo from './useUserInfo';
import useGetUnsupportedCloningAssetsAlertConfig from './useGetUnsupportedCloningAssetsAlertConfig';
export default function useGetContextualAlertConfig(campaignGuid, campaignObjectId, use, supportedAssets, associatedUnsupportedAssets) {
  const [hasPopUpForms, setHasPopUpForms] = useState(false);
  const [hasFetchedForms, setHasFetchedForms] = useState(false);
  const {
    isUngatedFor
  } = useUserInfo();
  const isUngatedForPopUpFormsDeprecation = isUngatedFor('Forms:SunsettingPopupCreation');
  const unsupportedCloningAssetsAlertConfig = useGetUnsupportedCloningAssetsAlertConfig(campaignObjectId, use, supportedAssets, associatedUnsupportedAssets);
  useEffect(() => {
    if (isUngatedForPopUpFormsDeprecation && !hasFetchedForms && campaignGuid && use === 'clone') {
      setHasFetchedForms(true);
      http.post('crm-search/search', {
        data: {
          objectTypeId: 'FORM',
          requestOptions: {
            properties: ['hs_object_id', 'hs_form_id']
          },
          count: 500,
          offset: 0,
          filterGroups: [{
            filters: [{
              property: 'hs_marketing_campaign_guid',
              operator: 'EQ',
              value: campaignGuid
            }, {
              property: 'hs_form_type',
              operator: 'EQ',
              value: 'FLOW'
            }]
          }]
        }
      }).then(({
        results
      }) => {
        setHasPopUpForms(results.length > 0);
      }).catch(() => {});
    }
  }, [campaignGuid, hasFetchedForms, isUngatedForPopUpFormsDeprecation, use]);
  if (unsupportedCloningAssetsAlertConfig) {
    return unsupportedCloningAssetsAlertConfig;
  } else if (hasPopUpForms) {
    return {
      titleText: I18n.text('campaignsLib.deprecatedPopUpFormWarning.title'),
      contentText: I18n.text('campaignsLib.deprecatedPopUpFormWarning.body'),
      type: 'warning',
      closeable: true
    };
  }
  return undefined;
}