import { FETCH_TEAM_HUB_SETTINGS } from 'EmailData/actions/actionTypes';
const initialState = {
  defaultContentAssignmentOn: false
};
export default function teamHubSettings(state = initialState, action) {
  switch (action.type) {
    case FETCH_TEAM_HUB_SETTINGS:
      return Object.assign({}, state, {
        defaultContentAssignmentOn: action.payload
      });
    default:
      return state;
  }
}