'use es6';

import { Map as ImmutableMap, OrderedMap, Record, Set as ImmutableSet } from 'immutable';
const filterDefaults = {
  index: ImmutableMap(),
  totals: ImmutableMap()
};
class IncrementalResultFilter extends Record(filterDefaults) {}
const defaults = {
  dataMap: OrderedMap(),
  filters: new IncrementalResultFilter()
};
class IncrementalFilteredResult extends Record(Object.assign({}, defaults)) {
  hasValues(filterType, filterKey) {
    return this.filters.getIn(['index', filterType, filterKey]);
  }
  getEmailAttemptData(id) {
    return this.dataMap.get(id);
  }
  inc(result, total, filterType, filterKey) {
    // Ensure there is no duplication of elements
    const filteredResults = this.filterRepeated(result);
    const filters = this.filters.getIn(['index', filterType]) || ImmutableMap({});
    const totalByType = this.filters.getIn(['totals', filterType]) || 0;
    const updatedFilters = filters.set(filterKey, result.keySeq().toArray());
    return new IncrementalFilteredResult({
      dataMap: this.dataMap.merge(filteredResults),
      filters: this.filters.merge({
        index: {
          [filterType]: updatedFilters
        },
        totals: {
          [filterType]: total > totalByType ? total : totalByType
        }
      })
    });
  }
  filterRepeated(incoming) {
    const incomingKeys = ImmutableSet(incoming.keySeq());
    const diff = incomingKeys.subtract(this.dataMap.keySeq());
    return incoming.filter((value, key) => diff.has(key));
  }
  size(filterType) {
    return this.filters.getIn(['totals', filterType]) || 0;
  }
  slice(filterType, filterKey) {
    const keys = this.filters.getIn(['index', filterType, filterKey]) || [];
    return this.dataMap.filter((value, key) => keys.indexOf(key) >= 0);
  }
  values() {
    return this.dataMap.valueSeq();
  }
}
export default IncrementalFilteredResult;