/**
 * Parses the value of a multi enum property (`type=enumeration, fieldType=checkbox`)
 * into an array of values.
 *
 * Values are semicolon-delimited lists of sub-values ('a;b;c'). Each sub-value
 * matches the value of an option in the property definition's `options` array,
 * or of an external option (if `externalOptions=true`).
 *
 * Whitespace is trimmed from values after splitting, and empty values are filtered out.
 *
 * The backend enforces that values of enums which don't use external options will
 * match the value an option in the options array (see `InboundDbPropertiesValidation`),
 * though technically it's possible for internal systems to bypass this (`?validateProperties=true`).
 *
 * See https://product.hubteam.com/docs/crm-properties/values/enumeration.html
 * See https://git.hubteam.com/HubSpot/Properties/blob/master/PropertyFormatting/src/main/java/com/hubspot/properties/formatting/formatters/EnumerationFormatter.java#L59
 * See https://git.hubteam.com/HubSpot/InboundDb/blob/master/InboundDbBase/src/main/java/com/hubspot/inbounddb/base/MultiValuePropertyHelper.java#L26
 *
 * @example
 * parseMultiEnumValue('a;b;c') // => ['a', 'b', 'c']
 * parseMultiEnumValue(null) // => []
 */
export function parseMultiEnumValue(value) {
  if (typeof value === 'string') {
    return value.split(';').map(val => val.trim()).filter(val => val !== '');
  }
  return value === null || value === undefined ? [] : [value];
}

/**
 * Formats an array of values for a multi enum property (`type=enumeration, fieldType=checkbox`)
 * into a single string appropriate to be stored as a property value.
 *
 * See https://git.hubteam.com/HubSpot/InboundDb/blob/master/InboundDbBase/src/main/java/com/hubspot/inbounddb/base/MultiValuePropertyHelper.java#L34
 *
 * @example
 * formatMultiEnumValue(['a', 'b', 'c']) // => 'a;b;c'
 * formatMultiEnumValue([]) // => ''
 */
export function formatMultiEnumValue(values) {
  return values.join(';');
}