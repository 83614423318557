import { useMemo } from 'react';
import { useFetchPortalTeams } from '../portalTeams/hooks/useFetchPortalTeams';
import { useUserTeams } from '../userInfo/hooks/useUserTeams';
/*
 * Takes the result of the userInfo api call and the portal teams api call and generates an array of teams in the format of
 * {
 *    id: number,
 *    name: string,
 *    isUserOnTeam: boolean
 * }[]
 */

export const useAllTeams = () => {
  const {
    data,
    loading,
    error
  } = useFetchPortalTeams();
  const {
    data: userTeams,
    loading: userTeamsLoading,
    error: userTeamsError
  } = useUserTeams();
  const result = useMemo(() => {
    if (loading || error || !data || !userTeams) {
      return [];
    }
    return data.portalTeams.map(portalTeam => {
      return {
        id: portalTeam.id,
        name: portalTeam.name,
        isUserOnTeam: Boolean(userTeams.find(userTeam => userTeam.id === portalTeam.id))
      };
    });
  }, [data, error, loading, userTeams]);
  return useMemo(() => ({
    loading: loading || userTeamsLoading,
    error: error || userTeamsError,
    data: result
  }), [error, loading, result, userTeamsLoading, userTeamsError]);
};