import http from 'hub-http/clients/apiClient';
import { splitArrayInChunks } from 'EmailData/utils/requestUtils';
import PortalIdParser from 'PortalIdParser';
import { LIST_PROPERTIES, INBOUND_LIST_PROPERTIES } from 'messaging-types-lib/content/recipients';
import I18n from 'I18n';
const USER_PORTAL = 'USER_PORTAL';
const STATIC = 'STATIC';
const CONTACT_OBJECT_TYPE_ID = '0-1';
const INBOUNDDB_LIST_BASE_URL = 'inbounddb-lists/v1/lists';
const INBOUND_LIST_API = 'inbounddb-lists/v1/segments-ui/searchLists';
const COUNT = 50;
const SEARCH_COUNT = 200;
const DESC = 'DESC';
const CONTACT_LIST_FILTER = {
  objectTypeId: '0-1'
};
const adaptDataForRecipientsSize = ({
  updated,
  isIls = false
}) => {
  const filterIds = ids => (ids || []).filter(id => id);
  const extraFields = {};
  if (updated.scheduledAt) {
    extraFields.scheduledAt = updated.scheduledAt;
  }
  const result = Object.assign({
    includedVids: filterIds(updated.vidsIncluded),
    excludedVids: filterIds(updated.vidsExcluded),
    recipientFatigueSuppressionEnabled: updated.fatigueSuppressionEnabled,
    grayMailSuppressionEnabled: updated.graymailEnabled
  }, extraFields);
  if (isIls) {
    result.inclusionILSLists = filterIds(updated.mailingIlsListsIncluded);
    result.exclusionILSLists = filterIds(updated.mailingIlsListsExcluded);
    result.marketableContactCheckEnabled = !!updated.marketableContactCheckEnabled;
  } else {
    result.inclusionLists = filterIds(updated.mailingListsIncluded);
    result.exclusionLists = filterIds(updated.mailingListsExcluded);
  }
  return result;
};
export function getRecipientSizePreviewWithIls(updated) {
  const data = adaptDataForRecipientsSize({
    updated,
    isIls: true
  });
  return http.post('email/contacts/v2/send-size-preview/ils', {
    data
  });
}
const fetchBatchContactsApi = (vids, options = {}) => {
  return http.post('inbounddb-objects/v1/preview', Object.assign({
    data: {
      contact: vids
    }
  }, options));
};
export const getRequestsArrayForFetchBatchContacts = (vidsArray, options = {}) => {
  const vidsChunk = splitArrayInChunks(vidsArray);
  return vidsChunk.map(vids => fetchBatchContactsApi(vids, options));
};
export function fetchInitialListsRequest() {
  return http.post(INBOUND_LIST_API, {
    data: {
      count: COUNT,
      sortProperty: LIST_PROPERTIES.UPDATED_AT.toUpperCase(),
      sortDirection: DESC,
      listFilterProperties: CONTACT_LIST_FILTER,
      properties: INBOUND_LIST_PROPERTIES
    }
  });
}
export function fetchBatchListsRequest(idArray) {
  return http.post(INBOUND_LIST_API, {
    data: {
      count: idArray.length,
      properties: INBOUND_LIST_PROPERTIES,
      listFilterProperties: {
        contactIds: idArray // naming convention for INBOUND_LIST_API is not clear
        // contactIds parameter used in listFilterProperties is use to filter list by classicListIds
      }
    }
  });
}
export function fetchBatchListsWithIlsRequest(idArray) {
  return http.post(INBOUND_LIST_API, {
    data: {
      count: idArray.length,
      properties: INBOUND_LIST_PROPERTIES,
      listFilterProperties: {
        listIds: idArray
      }
    }
  });
}
export function searchListsRequest(searchQuery) {
  return http.post(INBOUND_LIST_API, {
    data: {
      query: searchQuery,
      count: SEARCH_COUNT,
      sortProperty: LIST_PROPERTIES.UPDATED_AT.toUpperCase(),
      sortDirection: DESC,
      listFilterProperties: CONTACT_LIST_FILTER,
      properties: INBOUND_LIST_PROPERTIES
    }
  });
}
export function getListLimit(limitId) {
  return http.get(`inbounddb-lists/v1/lists/hubs/${PortalIdParser.get()}/limits/${limitId}/usage`);
}
export function fetchTotalSuppressedContactsCount() {
  return http.get('cosemail/v1/recipient-count/all-with-low-engagement');
}
export function fetchListByIlsRequest(ils) {
  return http.post(INBOUND_LIST_API, {
    data: {
      count: 1,
      properties: INBOUND_LIST_PROPERTIES,
      listFilterProperties: {
        listIds: [ils]
      }
    }
  });
}
export function getLowEngagedContactList() {
  return http.get('cosemail/v1/graymail-suppression/low-engaged-contacts-list');
}
export function createListRequest({
  filters,
  teamIds
}) {
  return http.post(INBOUNDDB_LIST_BASE_URL, {
    query: {
      public: true,
      referenceType: USER_PORTAL,
      referenceId: PortalIdParser.get(),
      assetTeamId: teamIds
    },
    data: {
      processingType: STATIC,
      name: `[Email] - ${I18n.moment().toString()}`,
      filterBranch: filters,
      objectTypeId: CONTACT_OBJECT_TYPE_ID
    }
  });
}
/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
// @ts-expect-error temporary hack
if (!!module && !!module.exports) {
  // @ts-expect-error temporary hack
  module.exports.default = Object.assign({}, module.exports);
}