import { List } from 'immutable';
import { ADD_TEST_SEND_ADDRESS, FROM_ADDRESS_VERIFIED, FETCH_FROM_ADDRESSES_START, FETCH_FROM_ADDRESSES_SUCCESS, FETCH_FROM_ADDRESSES_FAIL, STOP_POLLING_FROM_ADDRESSES, FROM_ADDRESS_VERIFICATION_EMAIL_SENT, START_POLLING_FROM_ADDRESSES, ADD_EMAIL_ADDRESS_SUCCEEDED, REMOVE_EMAIL_ADDRESS_SUCCEEDED } from 'EmailData/actions/actionTypes';
import EmailAddressModel from 'EmailData/models/EmailAddress';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { buildTypedMap } from 'EmailData/types/immutable';
const initialState = buildTypedMap({
  fromAddresses: List(),
  pollingIntervalId: null
});
const emailAddressOwnershipReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FROM_ADDRESSES_SUCCESS:
      return state.set('fromAddresses', List(action.payload.map(EmailAddressModel.from))).set('hasErrors', false).set('isFetched', true);
    case FETCH_FROM_ADDRESSES_FAIL:
      // eslint-disable-next-line no-console
      console.warn(`Error fetching from addresses ${action.payload}`);
      return state.set('isFetched', true).set('hasErrors', true);
    case ADD_EMAIL_ADDRESS_SUCCEEDED:
    case ADD_TEST_SEND_ADDRESS:
      return state.update('fromAddresses', fromAddresses => {
        return fromAddresses.push(EmailAddressModel.from(action.payload));
      });
    case REMOVE_EMAIL_ADDRESS_SUCCEEDED:
      {
        const index = state.get('fromAddresses').findIndex(addr => addr !== undefined && addr.getEmail() === action.replyTo);
        return state.deleteIn(['fromAddresses', index]);
      }
    case FROM_ADDRESS_VERIFICATION_EMAIL_SENT:
      {
        const index = state.get('fromAddresses').findIndex(a => a !== undefined && a.getEmail() === action.address);
        return state.setIn(['fromAddresses', index, 'pendingVerification'], true);
      }
    case FROM_ADDRESS_VERIFIED:
      {
        const index = state.get('fromAddresses').findIndex(addr => addr !== undefined && addr.getEmail() === action.address);
        state = state.setIn(['fromAddresses', index, 'verified'], true);
        return state.setIn(['fromAddresses', index, 'pendingVerification'], false);
      }
    case START_POLLING_FROM_ADDRESSES:
      {
        return state.set('pollingIntervalId', action.pollingIntervalId);
      }
    case STOP_POLLING_FROM_ADDRESSES:
      {
        return state.set('pollingIntervalId', null);
      }
    default:
      return state;
  }
};
export default asyncResource(emailAddressOwnershipReducer, {
  requestActions: [FETCH_FROM_ADDRESSES_START],
  responseActions: [FETCH_FROM_ADDRESSES_SUCCESS, FETCH_FROM_ADDRESSES_FAIL],
  customActions: [ADD_EMAIL_ADDRESS_SUCCEEDED, ADD_TEST_SEND_ADDRESS, REMOVE_EMAIL_ADDRESS_SUCCEEDED, FROM_ADDRESS_VERIFICATION_EMAIL_SENT, FROM_ADDRESS_VERIFIED, START_POLLING_FROM_ADDRESSES, STOP_POLLING_FROM_ADDRESSES]
});