import { TABLE_HEADER_HEIGHT } from 'HubStyleTokens/sizes';
export const COLUMN_MINIMUM_WIDTH = 100;
export const DEFAULT_COLUMN_WIDTH = 250;
export const SELECTION_COLUMN_WIDTH = 45;
export const NO_CELL_BORDER_WIDTH = 0;
export const CELL_BORDER_WIDTH = 1;
export const HOVER_CELL_BORDER_WIDTH = 2;
export const TABLE_TOP_BAR_HEIGHT = TABLE_HEADER_HEIGHT;
export const TABLE_PAGINATOR_HEIGHT = TABLE_HEADER_HEIGHT;
export const TABLE_ROW_HEIGHT = 40;
// The min-content non-empty table consists of the header plus one row
export const TABLE_NON_EMPTY_MIN_CONTENT_HEIGHT = parseInt(TABLE_HEADER_HEIGHT, 10) + TABLE_ROW_HEIGHT;