'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["metrics"];
import { Record, Map as ImmutableMap, fromJS } from 'immutable';
import I18n from 'I18n';
const defaults = {
  numberOfContacts: 0,
  metrics: ImmutableMap(),
  portalId: 0
};
const PUBLISH_BUFFER_TIME_MINUTES = 10;
class SmartInsights extends Record(defaults) {
  static from(_ref) {
    let {
        metrics = {}
      } = _ref,
      rest = _objectWithoutPropertiesLoose(_ref, _excluded);
    return new SmartInsights(Object.assign({
      metrics: fromJS(metrics)
    }, rest));
  }
  static getZoneTimestamp(zone, scheduledAt) {
    if (zone === 'PORTAL_VALUE') {
      return I18n.moment(scheduledAt);
    }
    const parsedOffset = I18n.moment(scheduledAt).utcOffset(zone);
    const publishTime = I18n.moment.utc(I18n.moment(scheduledAt).portalTz().toArray()).subtract(parsedOffset.utcOffset(), 'minutes');
    return publishTime;
  }
  static hasZonePassed(zone, scheduledAt) {
    const now = I18n.moment();
    const publishTime = SmartInsights.getZoneTimestamp(zone, scheduledAt);
    return publishTime.clone().subtract(PUBLISH_BUFFER_TIME_MINUTES, 'minutes').isBefore(now);
  }
  haveAnyZonesPassed(scheduledAt) {
    const metrics = this.getTimezoneMetrics();
    return !!(metrics && metrics.find((num, timeZone) => SmartInsights.hasZonePassed(timeZone, scheduledAt)));
  }
  getTimezoneMetrics() {
    return this.getMetrics('TIMEZONE');
  }
  getContactLifecycleMetrics() {
    return this.getMetrics('CONTACT_LIFECYCLE');
  }
  getNumberOfContacts() {
    return this.get('numberOfContacts');
  }
  getMetrics(key) {
    return this.getIn(['metrics', key], ImmutableMap());
  }
  getKnownTimezonesNumber() {
    const timezoneMetrics = this.getTimezoneMetrics();
    return timezoneMetrics && timezoneMetrics.reduce((sum, value, timeZone) => timeZone === 'ZONE_ERROR' ? sum : sum + value, 0);
  }
  getOtherTimezonesNumber() {
    return this.getNumberOfContacts() - this.getKnownTimezonesNumber();
  }
  getOtherTimezonesPercentage() {
    const numberOfContacts = this.getNumberOfContacts();
    return numberOfContacts ? Math.round(this.getOtherTimezonesNumber() / this.getNumberOfContacts() * 100) : 0;
  }
  getNumberOfContactsTreatedLikePortalTimezone() {
    return this.getTimezoneMetrics().get('PORTAL_VALUE') + this.getOtherTimezonesNumber();
  }
  getNumberOfContactsNotInPortalTimezone() {
    return this.getNumberOfContacts() - this.getNumberOfContactsTreatedLikePortalTimezone();
  }
  getPercentageOfContactsOutsidePortalTimezone() {
    const numberOfContacts = this.getNumberOfContacts();
    return numberOfContacts ? Math.round(this.getNumberOfContactsNotInPortalTimezone() / this.getNumberOfContacts() * 100) : 0;
  }
}
export default SmartInsights;