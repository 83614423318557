import { RAW_ALL_APP_SETTINGS_FETCH_SUCCESS, RAW_ALL_APP_SETTINGS_FETCH_FAILURE, RAW_ALL_APP_SETTINGS_FETCH_LOADING } from '../../types/AppSettings';
import { FetchStatuses, isIdle } from '../../types/FetchStatus';
import { useEffect, useReducer } from 'react';
import { getFrameworkDataSchemasClient } from 'framework-data-schema-resolvers';
const APP_SETTINGS_TO_FETCH = ['crmRecordsPage', 'crmPageEditor'];
const INITIAL_STATE = {
  status: FetchStatuses.IDLE,
  data: null
};
const reducer = (state, action) => {
  switch (action.type) {
    case RAW_ALL_APP_SETTINGS_FETCH_LOADING:
      return {
        status: FetchStatuses.LOADING,
        data: null
      };
    case RAW_ALL_APP_SETTINGS_FETCH_SUCCESS:
      return {
        status: FetchStatuses.RESOLVED,
        data: action.data
      };
    case RAW_ALL_APP_SETTINGS_FETCH_FAILURE:
      return {
        status: FetchStatuses.REJECTED,
        data: null,
        error: action.error
      };
    default:
      return state;
  }
};
export const useFetchRawAllAppSettings = () => {
  const [rawAllAppSettingsState, dispatch] = useReducer(reducer, INITIAL_STATE);
  useEffect(() => {
    if (!isIdle(rawAllAppSettingsState)) return;
    dispatch({
      type: RAW_ALL_APP_SETTINGS_FETCH_LOADING
    });
    getFrameworkDataSchemasClient().then(client => client.typeMetadata.get({
      appSettingNames: APP_SETTINGS_TO_FETCH
    })).then(data => {
      if (data) {
        dispatch({
          type: RAW_ALL_APP_SETTINGS_FETCH_SUCCESS,
          data: data
        });
      } else {
        throw new Error('No data returned');
      }
    }).catch(error => {
      dispatch({
        type: RAW_ALL_APP_SETTINGS_FETCH_FAILURE,
        error
      });
    });
  }, [rawAllAppSettingsState]);
  return rawAllAppSettingsState;
};