import PropTypes from 'prop-types';
export default PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
  displayOrder: PropTypes.number,
  hidden: PropTypes.bool,
  readOnly: PropTypes.bool,
  description: PropTypes.string

  // Fields we intentionally omit (from the definition in contacts.proto):
  // * doubleData - deprecated and unused
});