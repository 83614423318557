import { createFetchAllReferenceResolver } from 'reference-resolvers-lite/createFetchAllReferenceResolver';
import { PIPELINE_STAGE_IN_PIPELINE } from '../CustomResolverReferenceTypes';
import { HIDDEN } from '../../constants/PipelineLevelPermissionValues';
import PortalIdParser from 'PortalIdParser';
import { allowsPortalSpecificPipelines } from '../../input/pipeline/utils/pipelineUtils';

// This is essentially the RRL version of RR's PipelinesWithPermissionsAPI

const isHidden = pipeline => !!(pipeline.permission && pipeline.permission.accessLevel === HIDDEN);
const formatResponseWithPermissions = (objectTypeId, pipelineId, pipelines) => {
  const matchingPipelines = pipelineId ? pipelines.filter(p => p.pipelineId === pipelineId) : [...pipelines];

  // move hidden pipelines to the end since the user can't use them; otherwise sort by displayOrder
  matchingPipelines.sort((a, b) => isHidden(a) !== isHidden(b) ? isHidden(a) ? 1 : -1 : a.displayOrder - b.displayOrder);
  return matchingPipelines.flatMap(pipeline => {
    const stages = [...pipeline.stages].sort((a, b) => a.displayOrder - b.displayOrder);
    return stages.map(stage => ({
      id: stage.stageId,
      label: pipelineId || !allowsPortalSpecificPipelines(objectTypeId) ? stage.label : `${stage.label} (${pipeline.label})`,
      disabled: isHidden(pipeline),
      additionalProperties: {
        pipelineId: pipeline.pipelineId,
        label: pipeline.label
      }
    }));
  });
};
export const createPipelineStageInPipelineReferenceResolver = ({
  objectTypeId,
  pipelineId,
  httpClient
}) => {
  return createFetchAllReferenceResolver({
    fetchAll: () => httpClient.get(`pipelines/v2/pipelines/${encodeURIComponent(objectTypeId)}`, {
      query: {
        portalId: PortalIdParser.get(),
        includePermissions: true
      }
    }),
    formatFetchAllResponse: formatResponseWithPermissions.bind(null, objectTypeId, pipelineId),
    referenceType: PIPELINE_STAGE_IN_PIPELINE
  });
};