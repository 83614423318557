import { useEffect, useState } from 'react';

// Dynamically check if the growth side panel is open
export const useIsGrowthSidePanelOpen = () => {
  const [isGrowthSidePanelOpen, setIsGrowthSidePanelOpen] = useState(false);
  useEffect(() => {
    const mutationCallback = mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          const targetElement = mutation.target;
          setIsGrowthSidePanelOpen(targetElement.classList.contains('growth-side-panel-open'));
        }
      });
    };
    const observer = new MutationObserver(mutationCallback);

    // Start observing the <html> tag for changes in the class attribute
    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['class']
    });
    return () => observer.disconnect();
  }, [setIsGrowthSidePanelOpen]);
  return isGrowthSidePanelOpen;
};