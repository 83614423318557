import PortalIdParser from 'PortalIdParser';
import { Metrics } from '../../metrics/Metrics';
const PROPERTY_VALIDATION_ROOT_URI = 'property-validation/v1/property-validation';
export const validatePropertyValues = (requests, httpClient, objectTypeId, objectId) => {
  return httpClient.post(`${PROPERTY_VALIDATION_ROOT_URI}/${encodeURIComponent(objectTypeId)}/extended`, {
    data: requests,
    query: {
      customValidation: true,
      objectId,
      portalId: PortalIdParser.get()
    }
  }).then(value => {
    Metrics.counter('validatepropertyvalues-success').increment();
    return value;
  }).catch(reason => {
    Metrics.counter('validatepropertyvalues-failure').increment();
    throw reason;
  });
};