import { EXPERIMENTS_SUCCESSFUL, EXPERIMENTS_REQUESTING } from 'email-management-lib/refactor/actions/actionTypes';
const INITIAL_STATE = {
  isFetching: false,
  hasFetched: false,
  treatments: {}
};
export default function experiments(state = INITIAL_STATE, action) {
  switch (action.type) {
    case EXPERIMENTS_REQUESTING:
      {
        return Object.assign({}, state, {
          isFetching: true
        });
      }
    case EXPERIMENTS_SUCCESSFUL:
      {
        return Object.assign({}, state, {
          isFetching: false,
          hasFetched: true,
          treatments: action.payload
        });
      }
    default:
      return state;
  }
}