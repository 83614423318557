import { useMemo } from 'react';
import { gate } from 'hub-http/gates';
import { isProApiName } from '../../SurveyResponses';
import { userInfoSync } from 'hub-http/userInfo';
export const useIsEligibleForFeatureSurvey = apiName => {
  const {
    gates
  } = userInfoSync();
  const isEligibleApiName = isProApiName(apiName);
  return useMemo(() => {
    const isUngated = gates.includes(gate('FeatureSurveyModal'));
    return isUngated && isEligibleApiName;
  }, [isEligibleApiName, gates]);
};