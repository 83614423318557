import { registerQuery, useQuery } from 'data-fetching-client';
import http from 'hub-http/clients/apiClient';
export const OBJECT_TYPE_IDS_IN_VIEW_NAMESPACES_QUERY_NAME = 'objectTypeIdsInViewNamespaces';
const queryArguments = ['namespaces'];
export const ObjectTypeIdsInViewNamespacesQuery = registerQuery({
  fieldName: OBJECT_TYPE_IDS_IN_VIEW_NAMESPACES_QUERY_NAME,
  args: queryArguments,
  fetcher: args => {
    const baseUrl = `sales/v4/views/objectTypeIds`;
    const searchParams = new URLSearchParams(args.namespaces.map(namespace => ['namespace', namespace]));
    const url = `${baseUrl}?${searchParams.toString()}`;
    return http.get(url);
  }
});
export const useFetchObjectTypeIdsInViewNamespaces = ({
  namespaces,
  skip
}) => useQuery(ObjectTypeIdsInViewNamespacesQuery, {
  variables: {
    namespaces
  },
  skip
});