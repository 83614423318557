import Raven from 'raven-js';
import * as tracker from '../../_core/common/eventTracking/tracker';
import { getUpgradeDataTrackingProperties } from '../../ums/utils';
import { trackPotentialBannerInteraction } from '../../ums/utils';
export const trackViewedRequestSeatButton = ({
  upgradeData,
  level,
  usageLimitBannerData
}) => {
  tracker.track('interaction', Object.assign({
    action: 'viewed request seat',
    isPrimaryCta: level === 1,
    isSecondaryCta: level === 2
  }, usageLimitBannerData || {}, getUpgradeDataTrackingProperties(upgradeData)));
};
export const trackClickedRequestSeatButton = ({
  upgradeData,
  level,
  usageLimitBannerData
}) => {
  const trackingProperties = getUpgradeDataTrackingProperties(upgradeData);
  tracker.track('interaction', Object.assign({
    action: 'clicked request seat',
    isPrimaryCta: level === 1,
    isSecondaryCta: level === 2
  }, usageLimitBannerData, trackingProperties));
  if (upgradeData.pointType) {
    tracker.track('interaction', Object.assign({
      action: 'clicked upgrade point'
    }, usageLimitBannerData, trackingProperties));
    trackPotentialBannerInteraction(upgradeData.pointType, upgradeData.upgradeKey).catch(error => {
      Raven.captureException(new Error('Failed to submit interaction from UIRequestSeatButton'), {
        extra: {
          message: error.message,
          uniqueId: upgradeData.uniqueId
        }
      });
    });
  }
};