'use es6';

import { REHYDRATE_FROM_USER_SETTINGS } from 'EmailData/actions/actionTypes';
import { DISMISS_ONBOARDING_TOUR_ENTRY } from 'email-management-lib/refactor/actions/actionTypes';
const INITIAL_STATE = false;
export default function dismissedOnboardingTourEntry(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REHYDRATE_FROM_USER_SETTINGS:
      if (action.payload.has('dismissedOnboardingTourEntry')) {
        return action.payload.get('dismissedOnboardingTourEntry');
      }
      return state;
    case DISMISS_ONBOARDING_TOUR_ENTRY:
      return true;
    default:
      return state;
  }
}