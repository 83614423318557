'use es6';

import { APP_IDS } from 'EmailData/constants/details/appIds';
import EmailType from 'EmailData/enums/EmailType';
import BaseEmailDetail from 'EmailData/models/details/BaseEmailDetail';
const smtpTokenDefaults = {
  createContact: false,
  deleted: false,
  userName: '',
  createdAt: null,
  createdBy: '',
  campaignName: ''
};
class SmtpTokenEmailDetail extends BaseEmailDetail(Object.assign({}, smtpTokenDefaults)) {
  static from(json) {
    if (json.emailCampaignId !== null && json.emailCampaignId > 0) {
      json.allCampaignIds = [json.emailCampaignId];
      json.appCampaignIdPairs = [{
        first: APP_IDS.SMTP_EMAIL,
        second: json.emailCampaignId
      }];
    }
    json.appId = APP_IDS.SMTP_EMAIL;
    json.primaryEmail = {
      emailType: EmailType.SMTP_TOKEN,
      portalId: json.portalId,
      primaryEmailCampaignId: json.emailCampaignId,
      author: json.createdBy,
      name: json.campaignName,
      created: json.createdAt,
      publishDate: json.createdAt,
      state: 'AUTOMATED',
      userName: json.userName,
      createContact: json.createContact,
      deleted: json.deleted
    };
    const response = super.from(json);
    return new SmtpTokenEmailDetail(response);
  }
}
export default SmtpTokenEmailDetail;