'use es6';

import { Record, Set as ImmutableSet, Map as ImmutableMap, List } from 'immutable';
import I18n from 'I18n';
import { getEmailPreviewUrl } from 'EmailData/utils/getEmailPreviewUrl';
import * as emailStateUtil from '../utils/emailStateUtil';
import * as emailAttributeUtil from '../utils/emailAttributeUtil';
import * as emailWebVersionUtil from '../utils/emailWebVersionUtil';
import StatisticsData from './statistics/StatisticsData';
const CREATED_TIMESTAMP = new Date().valueOf();
const defaults = {
  abHoursToWait: null,
  abSampleSizeDefault: null,
  abSamplingDefault: null,
  abStatus: null,
  abSuccessMetric: null,
  abTestPercentage: null,
  abVariation: null,
  appId: 0,
  approvalStatus: null,
  absoluteUrl: '',
  abTestId: null,
  aifeatures: null,
  archived: false,
  author: '',
  authorName: '',
  // deprecated, do not use
  authorFullName: '',
  businessUnitId: 0,
  campaign: '',
  categoryId: 2,
  clonedFrom: 0,
  created: 0,
  createContact: false,
  // Need to defer createdMoment, can't assume I18n.moment is availble during module definition
  createdMoment: null,
  createPage: false,
  crmObjectId: null,
  customReplyTo: '',
  customReplyToEnabled: false,
  domain: '',
  deleted: false,
  deletedAt: 0,
  emailABStreamCampaignGroupId: null,
  emailABStreamCampaignSendId: null,
  emailTemplateMode: '',
  emailType: null,
  emailNote: '',
  emailPublishTag: null,
  folderId: null,
  formId: null,
  fromName: '',
  htmlTitle: '',
  id: null,
  isLocalTimezoneSend: false,
  isRecipientFatigueSuppressionEnabled: null,
  language: 'en',
  leadFlowId: null,
  maskedReplyTo: '',
  maxRssEntries: 0,
  messageTags: List(),
  metaDescription: null,
  name: '',
  pageRedirected: false,
  pageExpiryEnabled: false,
  pageExpiryDate: null,
  pageExpiryRedirectUrl: null,
  pageExpiryRedirectId: null,
  previewKey: null,
  primaryEmailCampaignId: null,
  emailCampaignGroupId: null,
  processingStatus: null,
  portalId: 0,
  publishDate: 0,
  // Need to defer publishDateMoment, can't assume I18n.moment is availble during module definition
  publishDateMoment: null,
  publishedByEmail: null,
  publishedById: null,
  publishedByName: null,
  publishedUrl: null,
  replyTo: '',
  rssEmailId: null,
  rssToEmailTiming: ImmutableMap({}),
  rssUrl: '',
  securityState: '',
  slug: '',
  stagedFrom: 0,
  state: 'DRAFT',
  stats: StatisticsData.from({}),
  subcategory: '',
  subject: '',
  subscription: 0,
  subscriptionName: '',
  subscriptionBlogId: 0,
  teamPerms: ImmutableSet([]),
  transactional: false,
  updated: 0,
  // Need to defer updatedMoment, can't assume I18n.moment is availble during module definition
  updatedMoment: null,
  useRssHeadlineAsSubject: false,
  userName: '',
  userPerms: ImmutableSet([]),
  isGraymailSuppressionEnabled: false,
  mailingListsIncluded: ImmutableSet([]),
  mailingListsExcluded: ImmutableSet([]),
  mailingIlsListsIncluded: ImmutableSet([]),
  mailingIlsListsExcluded: ImmutableSet([]),
  vidsIncluded: ImmutableSet([]),
  vidsExcluded: ImmutableSet([]),
  widgets: ImmutableMap({}),
  workflowNames: List([]),
  // Loaded only a minimal email missing many of the normal fields
  isMinimalEmail: false
};
class Email extends Record(defaults) {
  static from(json) {
    if (typeof json.stats !== 'undefined' && !(json.stats instanceof StatisticsData)) {
      json.stats = StatisticsData.from(json.stats);
    }
    if (typeof json.mailingListsIncluded !== 'undefined') {
      json.mailingListsIncluded = ImmutableSet(json.mailingListsIncluded);
    }
    if (typeof json.mailingListsExcluded !== 'undefined') {
      json.mailingListsExcluded = ImmutableSet(json.mailingListsExcluded);
    }
    if (typeof json.mailingIlsListsIncluded !== 'undefined') {
      json.mailingIlsListsIncluded = ImmutableSet(json.mailingIlsListsIncluded);
    }
    if (typeof json.mailingIlsListsExcluded !== 'undefined') {
      json.mailingIlsListsExcluded = ImmutableSet(json.mailingIlsListsExcluded);
    }
    if (typeof json.messageTags !== 'undefined') {
      json.messageTags = List(json.messageTags);
    }
    if (typeof json.vidsIncluded !== 'undefined') {
      json.vidsIncluded = ImmutableSet(json.vidsIncluded);
    }
    if (typeof json.vidsExcluded !== 'undefined') {
      json.vidsExcluded = ImmutableSet(json.vidsExcluded);
    }
    if (typeof json.widgets !== 'undefined') {
      json.widgets = ImmutableMap(json.widgets);
    }
    if (typeof json.publishDate !== 'undefined') {
      json.publishDateMoment = I18n.moment.utc(json.publishDate).portalTz();
    } else {
      json.publishDateMoment = I18n.moment(CREATED_TIMESTAMP);
    }
    if (typeof json.created !== 'undefined') {
      json.createdMoment = I18n.moment.utc(json.created).portalTz();
    } else {
      json.createdMoment = I18n.moment(CREATED_TIMESTAMP);
    }
    if (typeof json.updated !== 'undefined') {
      json.updatedMoment = I18n.moment.utc(json.updated).portalTz();
    } else {
      json.updatedMoment = I18n.moment(CREATED_TIMESTAMP);
    }
    return new Email(json);
  }
  isPublished() {
    return emailStateUtil.getIsPublished(this);
  }
  isABAutomatedPublishedEmail() {
    return this.isABAutomatedEmail() && this.isPublishedAutomated();
  }
  isScheduled() {
    return emailStateUtil.getIsScheduled(this);
  }
  isDraft() {
    return emailStateUtil.getIsDraft(this);
  }
  isErrored() {
    return emailStateUtil.getIsError(this);
  }
  isProcessing() {
    return emailStateUtil.getIsProcessing(this);
  }
  isPreProcessing() {
    return emailStateUtil.getIsPreProcessing(this);
  }
  isInitializing() {
    return emailStateUtil.getIsInitializing(this);
  }
  isArchived() {
    return this.archived;
  }
  isAutomatedForForm() {
    return emailStateUtil.getIsAutomatedForForm(this);
  }
  isBatch() {
    return emailStateUtil.getIsBatchEmail(this);
  }
  isAutomated() {
    return emailStateUtil.getIsAutomated(this);
  }
  isAutomatedLike() {
    return emailStateUtil.getIsAutomatedLike(this);
  }
  isLocalTime() {
    return emailStateUtil.getIsLocalTime(this);
  }
  isPublishedAutomated() {
    return emailStateUtil.getIsPublishedAutomated(this);
  }
  isFollowup() {
    return emailStateUtil.getIsFollowupEmail(this);
  }
  isSingleSendApi() {
    return emailStateUtil.getIsSingleSendApi(this);
  }
  isBlogEmail() {
    return emailStateUtil.getIsBlogEmail(this);
  }
  isBlogChildEmail() {
    return emailStateUtil.getIsBlogEmailChild(this);
  }
  isABEmail() {
    return emailStateUtil.getIsAbEmail(this);
  }
  isABAutomatedEmail() {
    return emailStateUtil.getIsAbAutomated(this);
  }
  isABLike() {
    return emailStateUtil.getIsAbLike(this);
  }
  isRssEmail() {
    return emailStateUtil.getIsRssEmail(this);
  }
  isRssChildEmail() {
    return emailStateUtil.getIsRssEmailChild(this);
  }
  isOptinEmail() {
    return emailStateUtil.getIsOptinEmail(this);
  }
  isLeadFlowEmail() {
    return emailStateUtil.getIsLeadflowEmail(this);
  }
  isTicketEmail() {
    return emailStateUtil.getIsTicketEmail(this);
  }
  isFeedbackEmail() {
    return emailStateUtil.getIsFeedbackLikeEmail(this);
  }
  isSmtpEmail() {
    return emailStateUtil.getIsSmtpToken(this);
  }
  isAbandonedCartEmail() {
    return emailStateUtil.getIsAutomatedForDeal(this);
  }
  isTransactionalEmail() {
    return this.transactional;
  }
  isNotAssociatedWithWorkflow() {
    return this.isAutomated() && !this.isAutomatedForForm() && this.hasWorkflows() && this.hasStatistics();
  }
  isAvailableToWorkflows() {
    return emailStateUtil.getIsAvailableToWorkflows(this);
  }
  hasWorkflows() {
    return this.workflowNames.size > 0;
  }
  hasStatistics() {
    const counters = this.stats.get('counters');
    return counters.sent !== 0 || counters.dropped !== 0;
  }
  hasWebpageVersion() {
    return emailWebVersionUtil.getHasWebPageVersion(this);
  }
  hasWebRedirect() {
    return emailWebVersionUtil.getHasWebPageRedirect(this);
  }
  hasWebVersionExpiry() {
    return emailWebVersionUtil.getHasWebVersionExpiry(this);
  }
  hasWebRedirectAfterDate(date) {
    return emailWebVersionUtil.getHasWebRedirectAfterDate(this, date);
  }
  hasWebRedirectBeforeDate(date) {
    return emailWebVersionUtil.getHasWebRedirectBeforeDate(this, date);
  }
  hasSmartObjects() {
    return emailAttributeUtil.getHasSmartObjects(this);
  }
  hasNote() {
    return emailAttributeUtil.getHasNote(this);
  }
  hasLoaded() {
    return this.emailType !== defaults.emailType;
  }
  wasForciblyCancelled() {
    return emailStateUtil.getIsForciblyCanceled(this);
  }
  wasCanceled() {
    return emailStateUtil.getIsCanceled(this);
  }
  wasCanceledForAbuse() {
    return emailStateUtil.getIsCanceledForAbuse(this);
  }
  getPreviewUrl(convertToDragDrop = false) {
    const isDraftEmail = !(this.isPublished() || this.isScheduled());
    return getEmailPreviewUrl(this.id, this.previewKey, isDraftEmail, convertToDragDrop);
  }
  canCancelOrUnpublish() {
    return emailAttributeUtil.getCanCancelOrUnpublish(this);
  }
}
export default Email;