import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
const DEFAULT_VISIBLE_COLUMN_COUNT = 10;
const DEFAULT_VISIBLE_ROW_COUNT = 25;
const makeObj = count => Array(count).fill(0).reduce((acc, __, index) => {
  acc[index] = true;
  return acc;
}, {});
const DEFAULT_VISIBLE_COLUMNS = makeObj(DEFAULT_VISIBLE_COLUMN_COUNT);
const DEFAULT_VISIBLE_ROWS = makeObj(DEFAULT_VISIBLE_ROW_COUNT);
const initialState = {
  visibleRowIndexes: DEFAULT_VISIBLE_ROWS,
  visibleColumnIndexes: DEFAULT_VISIBLE_COLUMNS
};
const visibleCellsSlice = createSlice({
  name: 'visibleCells',
  initialState,
  reducers: {
    updateVisibleIndexes: (state, {
      payload
    }) => {
      state.visibleRowIndexes = Object.assign({}, state.visibleRowIndexes, payload.rows);
      state.visibleColumnIndexes = Object.assign({}, state.visibleColumnIndexes, payload.columns);
    },
    resetToDefaultVisibility: state => {
      state.visibleRowIndexes = initialState.visibleRowIndexes;
      state.visibleColumnIndexes = initialState.visibleColumnIndexes;
    }
  }
});
export const visibleCellsReducer = visibleCellsSlice.reducer;
export const {
  updateVisibleIndexes,
  resetToDefaultVisibility
} = visibleCellsSlice.actions;
const getVisibleCellsSlice = ({
  visibleCells
}) => visibleCells;
export const makeGetIsRowVisible = rowIndex => createSelector([getVisibleCellsSlice], ({
  visibleRowIndexes
}) => Boolean(visibleRowIndexes[rowIndex]));
export const makeGetIsColumnVisible = columnIndex => createSelector([getVisibleCellsSlice], ({
  visibleColumnIndexes
}) => Boolean(visibleColumnIndexes[columnIndex]));