import { updateQueryAndPathParams } from 'email-management-lib/refactor/actions/params';
import { useHistory } from 'react-router-dom';
import { UI_MODE } from 'framework-listing-lib/constants';
import { useDispatch } from 'react-redux';
import { fetchEmails } from 'email-management-lib/refactor/actions/manage/general';
import { useCallback } from 'react';
function useIsFolders() {
  const {
    location
  } = useHistory();
  return location.pathname.includes('/manage/folder');
}
export default function useEmailUIModeProps() {
  const isFolders = useIsFolders();
  const dispatch = useDispatch();
  const onChangeUIMode = useCallback(uiMode => {
    if (uiMode === UI_MODE.FOLDERS) {
      dispatch(updateQueryAndPathParams({
        state: null,
        outageFilter: null
      }, {
        state: '',
        folderId: ''
      }, fetchEmails, '/manage/folder'));
      return;
    }
  }, [dispatch]);
  return {
    currentUIMode: isFolders ? UI_MODE.FOLDERS : UI_MODE.LIST,
    onChangeUIMode
  };
}