import PortalIdParser from 'PortalIdParser';
import memoize from 'transmute/memoize';
import { getPropertyValue } from 'marketing-platform-lib/utils/crmUtils';
import { CRM_SEARCH_GRAPHQL_CACHE_KEY } from 'framework-listing-lib/internal/graphql/constants';
export const transformGraphQLCrmObjectToCrmObject = (graphQLCrmObject, objectTypeId) => ({
  objectId: graphQLCrmObject.objectId,
  objectTypeId,
  portalId: Number(PortalIdParser.get()),
  properties: graphQLCrmObject.allProperties.reduce((acc, graphQLProperty) => {
    acc[graphQLProperty.name] = {
      value: graphQLProperty.value
    };
    return acc;
  }, {})
});
export const transformCrmObjectToGraphQLCrmObject = crmObject => ({
  id: Number(crmObject.objectId),
  objectId: Number(crmObject.objectId),
  allProperties: Object.keys(crmObject.properties).map(key => ({
    id: `${crmObject.objectId}-${key}`,
    name: key,
    value: getPropertyValue(crmObject, key) || null,
    __typename: 'PropertyValue'
  })),
  __typename: 'BasicCrmObject'
});

/**
 * This method is transforming the GraphQL response to match the REST response since both are slightly different.
 */
export const transformGraphQLResponseIntoCrmSearchResponse = memoize((graphQLData, objectTypeId) => {
  if (!graphQLData || !(CRM_SEARCH_GRAPHQL_CACHE_KEY in graphQLData)) {
    return undefined;
  }
  const {
    offset,
    results,
    total,
    hasMore
  } = graphQLData[CRM_SEARCH_GRAPHQL_CACHE_KEY];
  return {
    total,
    offset,
    results: results.map(graphqlCrmObject => transformGraphQLCrmObjectToCrmObject(graphqlCrmObject, objectTypeId)),
    hasMore
  };
});
export const transformCrmSearchResponseToGraphQLResponse = crmSearchResponse => {
  return {
    total: crmSearchResponse.total,
    offset: crmSearchResponse.offset,
    results: crmSearchResponse.results.map(transformCrmObjectToGraphQLCrmObject),
    hasMore: crmSearchResponse.hasMore
  };
};

/**
 * Add a new CRM object to the Apollo cache.
 * This method is used by optimistic updates in listing-lib.
 */
export const addCrmObjectToApolloCache = (apolloCache, crmObject) => {
  return {
    [CRM_SEARCH_GRAPHQL_CACHE_KEY]: Object.assign({}, apolloCache[CRM_SEARCH_GRAPHQL_CACHE_KEY], {
      results: [transformCrmObjectToGraphQLCrmObject(crmObject), ...apolloCache[CRM_SEARCH_GRAPHQL_CACHE_KEY].results],
      total: apolloCache[CRM_SEARCH_GRAPHQL_CACHE_KEY].total + 1
    })
  };
};

/**
 * Replace existing CRM objects in cache by the ones provided by @crmObjectOrObjects.
 * This method is used by optimistic updates in listing-lib.
 */
export const updateCrmObjectsInApolloCache = (apolloCache, crmObjectOrObjects) => {
  const objectsMap = Array.isArray(crmObjectOrObjects) ? crmObjectOrObjects.reduce((acc, object) => {
    acc[object.objectId] = object;
    return acc;
  }, {}) : {
    [crmObjectOrObjects.objectId]: crmObjectOrObjects
  };
  return {
    [CRM_SEARCH_GRAPHQL_CACHE_KEY]: Object.assign({}, apolloCache[CRM_SEARCH_GRAPHQL_CACHE_KEY], {
      results: apolloCache[CRM_SEARCH_GRAPHQL_CACHE_KEY].results.map(graphqlCrmObject => {
        if (objectsMap[graphqlCrmObject.objectId]) {
          return transformCrmObjectToGraphQLCrmObject(objectsMap[graphqlCrmObject.objectId]);
        }
        return graphqlCrmObject;
      })
    })
  };
};
function updateExistingGraphqlProperty(graphqlCrmObject, propertyValue) {
  return Object.assign({}, graphqlCrmObject, {
    allProperties: graphqlCrmObject.allProperties.map(graphQLProperty => {
      if (graphQLProperty.name !== propertyValue.name) {
        return graphQLProperty;
      }
      return Object.assign({}, graphQLProperty, {
        value: propertyValue.value === undefined ? null : propertyValue.value
      });
    })
  });
}
function addNewGraphqlProperty(graphqlCrmObject, propertyValue) {
  return Object.assign({}, graphqlCrmObject, {
    allProperties: [...graphqlCrmObject.allProperties, {
      id: `${graphqlCrmObject.id}-${propertyValue.name}`,
      name: propertyValue.name,
      value: propertyValue.value || null,
      __typename: 'PropertyValue'
    }]
  });
}

/**
 * Update property values from CRM object in cache.
 * This method is used by inline editing in listing-lib.
 */
export const updateGraphQLPropertyValues = (apolloCache, selectedIds, propertyValues) => {
  return {
    [CRM_SEARCH_GRAPHQL_CACHE_KEY]: Object.assign({}, apolloCache[CRM_SEARCH_GRAPHQL_CACHE_KEY], {
      results: apolloCache[CRM_SEARCH_GRAPHQL_CACHE_KEY].results.map(graphqlCrmObject => {
        if (selectedIds.length && !selectedIds.includes(graphqlCrmObject.id)) {
          return graphqlCrmObject;
        }
        let copyGraphQLCrmObject = Object.assign({}, graphqlCrmObject);
        for (const propertyValue of propertyValues) {
          const isExistingProperty = copyGraphQLCrmObject.allProperties.find(graphqlProperty => graphqlProperty.name === propertyValue.name);
          if (isExistingProperty) {
            copyGraphQLCrmObject = updateExistingGraphqlProperty(copyGraphQLCrmObject, propertyValue);
          } else {
            copyGraphQLCrmObject = addNewGraphqlProperty(copyGraphQLCrmObject, propertyValue);
          }
        }
        return copyGraphQLCrmObject;
      })
    })
  };
};
export const getGraphQLVariables = crmSearchVariables => ({
  count: crmSearchVariables.fetchParams.count,
  filterGroups: crmSearchVariables.fetchParams.filterGroups,
  objectTypeId: crmSearchVariables.fetchParams.objectTypeId,
  offset: crmSearchVariables.fetchParams.offset,
  query: crmSearchVariables.fetchParams.query,
  sorts: crmSearchVariables.fetchParams.sorts
});