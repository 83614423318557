import { useCallback } from 'react';
import { usePinnedViews } from './useViewProps';
export default function useReorderPinnedViews() {
  const pinnedViews = usePinnedViews();
  return useCallback((startIndex, endIndex) => {
    if (startIndex === endIndex || pinnedViews.length === 0) {
      return pinnedViews;
    }
    const newPinnedViews = pinnedViews.filter((_, index) => index !== startIndex);
    newPinnedViews.splice(endIndex, 0, pinnedViews[startIndex]);
    return newPinnedViews;
  }, [pinnedViews]);
}