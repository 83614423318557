'use es6';

import enviro from 'enviro';
import http from 'hub-http/clients/apiClient';
import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
import { hubletApi } from 'hub-http/middlewares/core';
import { isHubspot } from 'EmailData/utils/feedback';

// submissions for HubSpot surveys should always be rerouted to na1
const getHubletApi = () => isHubspot ? /* eslint-disable-next-line hubspot-dev/no-hublet-references */
hubletApi('feedback', 'hubapi', 'na1') : hubletApi('feedback', 'hubapi');
export function getCsatSurvey(surveyId) {
  return http.get(`feedback/v1/survey-config/CSAT/${surveyId}`);
}
export function submitFeedbackWithSentiment(sentiment, feedback, surveyId, contactId) {
  // Do not record feedback in QA
  if (enviro.isQa()) {
    // eslint-disable-next-line no-console
    console.log('Feedback with sentiment will not be submitted on QA env');
    return Promise.resolve({});
  }
  return submitSentiment(sentiment, surveyId, contactId).then(response => submitFeedbackRequest(sentiment, feedback, surveyId, contactId, response.submissionId));
}
export function submitFeedbackRequest(sentiment, feedback, surveyId, contactId, submissionId) {
  // Do not record feedback in QA
  if (enviro.isQa()) {
    // eslint-disable-next-line no-console
    console.log('Feedback will not be submitted on QA env');
    return Promise.resolve({});
  }
  return noAuthApiClient.post('feedback/public/v1/submission-follow-up/csat', {
    api: getHubletApi(),
    data: {
      followUp: feedback,
      portalId: '53',
      rating: sentiment,
      submissionId,
      surveyId,
      surveyType: 'CSAT'
    }
  });
}
export function submitSentiment(sentiment, surveyId, contactId) {
  // Do not record feedback in QA
  if (enviro.isQa()) {
    // eslint-disable-next-line no-console
    console.log('Sentiment will not be submitted on QA env');
    return Promise.resolve({});
  }
  return noAuthApiClient.post('feedback/public/v1/submit/csat', {
    api: getHubletApi(),
    data: {
      channel: 'WEB',
      contactId,
      portalId: '53',
      surveyChannel: 'WEB',
      surveyId,
      value: sentiment,
      webAnalytics: {
        pageTitle: document.title,
        pageUrl: window.location.href,
        userAgent: window.navigator.userAgent
      }
    }
  });
}