module.exports = {
  "/email": {
    "name": "manage-redirect",
    "success": {
      "default": [
        "MANAGE_REDIRECT"
      ]
    }
  },
  "/": {
    "name": "manage-redirect",
    "success": {
      "default": [
        "MANAGE_REDIRECT"
      ]
    }
  },
  "/dashboard": {
    "name": "manage-redirect",
    "success": {
      "default": [
        "MANAGE_REDIRECT"
      ]
    }
  },
  "/manage/state": {
    "name": "manage-redirect",
    "success": {
      "default": [
        "MANAGE_REDIRECT"
      ]
    }
  },
  "/manage": {
    "name": "manage-redirect",
    "success": {
      "default": [
        "MANAGE_REDIRECT"
      ]
    }
  },
  "/manage/state/:state": {
    "name": "manage",
    "success": {
      "dashboard new user state": [
        "CREATE_EMAIL_BUTTON_LOADED",
        "NEW_USER_STATE_LOADED"
      ],
      "dashboard table empty state": [
        "CREATE_EMAIL_BUTTON_LOADED",
        "TABLE_EMPTY_STATE"
      ],
      "dashboard table loaded": [
        "CREATE_EMAIL_BUTTON_LOADED",
        "TABLE_DATA_LOADED"
      ],
      "dashboard table prohibited": [
        "PROHIBITED_PAGE_CONTAINER"
      ]
    },
    "error": [
      "FRAMEWORK_TABLE_LOAD_FAILED",
      "TABLE_ERROR_STATE",
      "MORE_TOOLS_MODAL_LOAD_FAILED",
      "COLUMN_SWITCHER_LOAD_FAILED",
      "ASSIGN_ASSET_ACCESS_PANEL_LOAD_FAILED",
      "PORTAL_SUSPENSION_LOAD_FAILED",
      "PROBATION_BANNER_LOAD_FAILED",
      "ASSIGN_TEAMS_PANEL_LOAD_FAILED",
      "EMAIL_DETAILS_SIDEBAR_LOAD_FAILED",
      "ONBOARDING_LOAD_FAILED",
      "NEW_USER_STATE_LOAD_FAILED"
    ]
  },
  "/manage/folder": {
    "name": "manage-folder",
    "success": {
      "folder list user state": [
        "CREATE_EMAIL_BUTTON_LOADED",
        "NEW_USER_STATE_LOADED"
      ],
      "folder list empty state": [
        "CREATE_EMAIL_BUTTON_LOADED",
        "TABLE_EMPTY_STATE"
      ],
      "folder list loaded": [
        "CREATE_EMAIL_BUTTON_LOADED",
        "TABLE_DATA_LOADED"
      ],
      "folder list prohibited": [
        "PROHIBITED_PAGE_CONTAINER"
      ]
    },
    "error": [
      "TABLE_ERROR_STATE",
      "MORE_TOOLS_MODAL_LOAD_FAILED",
      "COLUMN_SWITCHER_LOAD_FAILED",
      "ASSIGN_ASSET_ACCESS_PANEL_LOAD_FAILED",
      "PORTAL_SUSPENSION_LOAD_FAILED",
      "PROBATION_BANNER_LOAD_FAILED",
      "ASSIGN_TEAMS_PANEL_LOAD_FAILED",
      "EMAIL_DETAILS_SIDEBAR_LOAD_FAILED",
      "ONBOARDING_LOAD_FAILED",
      "NEW_USER_STATE_LOAD_FAILED"
    ]
  },
  "/manage/folder/:folderId": {
    "name": "manage-folder",
    "success": {
      "folder new user state": [
        "CREATE_EMAIL_BUTTON_LOADED",
        "NEW_USER_STATE_LOADED"
      ],
      "folder empty state": [
        "CREATE_EMAIL_BUTTON_LOADED",
        "TABLE_EMPTY_STATE"
      ],
      "folder loaded": [
        "CREATE_EMAIL_BUTTON_LOADED",
        "TABLE_DATA_LOADED"
      ],
      "folder prohibited": [
        "PROHIBITED_PAGE_CONTAINER"
      ]
    },
    "error": [
      "TABLE_ERROR_STATE",
      "MORE_TOOLS_MODAL_LOAD_FAILED",
      "COLUMN_SWITCHER_LOAD_FAILED",
      "ASSIGN_ASSET_ACCESS_PANEL_LOAD_FAILED",
      "PORTAL_SUSPENSION_LOAD_FAILED",
      "PROBATION_BANNER_LOAD_FAILED",
      "ASSIGN_TEAMS_PANEL_LOAD_FAILED",
      "EMAIL_DETAILS_SIDEBAR_LOAD_FAILED",
      "ONBOARDING_LOAD_FAILED",
      "NEW_USER_STATE_LOAD_FAILED"
    ]
  },
  "/manage/whitelisting": {
    "name": "manage-whitelisting",
    "success": {
      "default": [
        "WHITELIST_IPS_LOADED"
      ]
    }
  },
  "/manage/limits": {
    "name": "manage-limits",
    "success": {
      "default": [
        "SEND_LIMITS_PANEL_LOADED"
      ]
    }
  },
  "/manage/affected-emails": {
    "name": "affected-emails",
    "success": {
      "default": [
        "AFFECTED_EMAILS_LOADED"
      ],
      "at risk emails": [
        "AT_RISK_EMAILS_LOADED"
      ],
      "modified emails": [
        "MODIFIED_EMAILS_LOADED"
      ],
      "needs review emails": [
        "NEEDS_REVIEW_EMAILS_EMAILS_LOADED"
      ]
    },
    "error": [
      "AT_RISK_EMAILS_FAILED",
      "MODIFIED_EMAILS_FAILED",
      "NEEDS_REVIEW_EMAILS_EMAILS_FAILED"
    ]
  },
  "/import/mailchimp": {
    "name": "email import mailchimp",
    "success": {
      "default": [
        "LOADED_IMPORT_MAILCHIMP"
      ]
    },
    "error": [
      "MAILCHIMP_ERROR"
    ]
  }
};