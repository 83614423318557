import { useRef } from 'react';
export const useStableFunction = fn => {
  const currentFnRef = useRef(fn);
  const stableFnRef = useRef();
  currentFnRef.current = fn;
  if (!stableFnRef.current) {
    stableFnRef.current = function (...args) {
      return currentFnRef.current.call(this, ...args);
    };
  }
  return stableFnRef.current;
};