'use es6';

import { Map as ImmutableMap, Record } from 'immutable';
import StatisticsData from 'EmailData/models/statistics/StatisticsData';
const campaignStatisticsDefaults = {
  source: null,
  campaignStatistics: ImmutableMap({}),
  totals: StatisticsData.from({})
};
class AllCampaignStatistics extends Record(campaignStatisticsDefaults) {
  static from(json) {
    const response = Object.assign({}, json);
    if (response.campaignStatistics !== undefined) {
      const rawStatistics = ImmutableMap(response.campaignStatistics);
      response.campaignStatistics = ImmutableMap(rawStatistics.map(v => StatisticsData.from(Object.assign({}, v))));
      response.campaignStatistics = response.campaignStatistics.mapKeys(k => parseInt(k, 10));
    }
    if (response.totals !== undefined) {
      response.totals = StatisticsData.from(Object.assign({}, response.totals));
    }
    return new AllCampaignStatistics(response);
  }
}
export default AllCampaignStatistics;