'use es6';

import { Record } from 'immutable';
import PORTAL_HEALTH_STATES from 'EmailData/constants/portalHealthStates';
import { SUSPENSION_REASON_TYPES, isReasonAbuseAppealRelated, SUSPENSION_APPEAL_STATUS, isReasonSetManually, isReasonPhishingRelated, PERFORMANCE_SUSPENSION_REASONS } from 'EmailData/constants/portalSuspension';
import EMAIL_PRODUCT_TIER from 'EmailData/constants/emailProductTier';
const HEALTH_STATES_SEVERITY = {
  [PORTAL_HEALTH_STATES.DEFAULT]: 0,
  [PORTAL_HEALTH_STATES.PROBATION_1]: 10,
  [PORTAL_HEALTH_STATES.PROBATION_2]: 20,
  [PORTAL_HEALTH_STATES.PROBATION_3]: 30,
  [PORTAL_HEALTH_STATES.SUSPENDED]: 40
};
const defaults = {
  acceptableRate: null,
  appealId: null,
  appealState: null,
  emailProductTier: null,
  evaluationStartTime: null,
  evaluationEndTime: null,
  overallState: PORTAL_HEALTH_STATES.DEFAULT,
  probationStatus: null,
  reason: SUSPENSION_REASON_TYPES.DEFAULT,
  recommendedRate: null,
  remediationStatus: null,
  show: false,
  violationRate: null
};
function getHealthSeverity(state) {
  return HEALTH_STATES_SEVERITY[state];
}
class PortalHealthStatus extends Record(defaults) {
  static from({
    status = {}
  } = {}) {
    if (!status.remediationStatus) {
      status.remediationStatus = {};
    }
    return new PortalHealthStatus(status);
  }
  static warnLevelDecreased(previous, current) {
    const prevLevel = getHealthSeverity(previous);
    const currentLevel = getHealthSeverity(current);
    return currentLevel < prevLevel;
  }
  isHealthy() {
    return this.overallState === PORTAL_HEALTH_STATES.DEFAULT;
  }
  isInProbation1() {
    return this.overallState === PORTAL_HEALTH_STATES.PROBATION_1;
  }
  isInProbation2() {
    return this.overallState === PORTAL_HEALTH_STATES.PROBATION_2;
  }
  isInPreSuspension() {
    return this.overallState === PORTAL_HEALTH_STATES.PROBATION_3;
  }
  isSuspended() {
    return this.overallState === PORTAL_HEALTH_STATES.SUSPENDED && !this.isAbuseAppealTypeSuspension();
  }
  getIsSuspensionReasonPerformanceRelated() {
    return PERFORMANCE_SUSPENSION_REASONS.includes(this.reason);
  }
  isForcedSuspension() {
    return this.isSuspended() && (this.reason === SUSPENSION_REASON_TYPES.OVERRIDE || this.isAbuseAppealTypeSuspension());
  }
  isAbuseAppealTypeSuspension() {
    return isReasonAbuseAppealRelated(this.reason);
  }
  isRecoverableSuspension() {
    return !this.isAbuseAppealTypeSuspension() && ![SUSPENSION_REASON_TYPES.BLACKLISTING, SUSPENSION_REASON_TYPES.ABUSE_COMPLAINT, SUSPENSION_REASON_TYPES.COMPLIANCE_TAKEOVER].includes(this.reason) && !isReasonSetManually(this.reason) && !isReasonPhishingRelated(this.reason);
  }
  getAppeal() {
    return this.remediationStatus.appeal || {};
  }
  getAppealId() {
    return this.getAppeal().ticketId;
  }
  getAppealState() {
    return this.getAppeal().state || SUSPENSION_APPEAL_STATUS.UNINITIATED;
  }
  getAllowAppealReopen() {
    return this.getAppeal().allowReopen;
  }
  getCurrentSeverityLevel() {
    return getHealthSeverity(this.overallState);
  }
  getCurrentState() {
    return this.overallState;
  }
  getReason() {
    return this.reason;
  }
  hasDescalated(portalDismissStatus) {
    const previousState = portalDismissStatus.level;
    const currentState = this.getCurrentState();
    return PortalHealthStatus.warnLevelDecreased(previousState, currentState);
  }
  shouldShowMessage(portalDismissStatus) {
    const hasDescalated = this.hasDescalated(portalDismissStatus);
    const previousDismissed = portalDismissStatus.dismissed;
    return this.show && !hasDescalated && !previousDismissed;
  }
  isEmailFreeTier() {
    return this.emailProductTier === EMAIL_PRODUCT_TIER.FREE;
  }
}
PortalHealthStatus.REASON_TYPES = SUSPENSION_REASON_TYPES;
PortalHealthStatus.HEALTH_STATES = PORTAL_HEALTH_STATES;
export default PortalHealthStatus;