import { useCallback, useMemo } from 'react';
import { BULK_SELECTION_MODE } from 'framework-listing-lib/constants/table';
import useCrmObjectTypeListingFilters from 'framework-listing-lib/hooks/useCrmObjectTypeListingFilters';
import listingLibObserver from 'framework-listing-lib/utils/listingLibObserver';
import { ASC, DESC } from 'framework-listing-lib/constants/sorts';
import useCrmObjectTypeTracking from 'framework-listing-lib/internal/hooks/useCrmObjectTypeTracking';
import { useHasViews, useViewUpdateState } from 'framework-listing-lib/internal/ViewTabs/hooks/useViewProps';
import useCurrentView from 'framework-listing-lib/hooks/useCurrentView';
function getSort({
  sort
}) {
  const {
    property,
    order
  } = sort;
  return {
    sorted: [{
      id: property,
      desc: order === DESC
    }],
    sortKey: property,
    sortDirection: order === DESC ? 1 : -1
  };
}
function useCrmObjectTypeListingSort() {
  const {
    filters,
    onUpdateFilters
  } = useCrmObjectTypeListingFilters();
  const {
    trackCrmTableInteraction
  } = useCrmObjectTypeTracking();
  const hasViews = useHasViews();
  const currentView = useCurrentView();
  const [, setViewUpdateState] = useViewUpdateState();
  const onSetSort = useCallback(tableSorts => {
    const [{
      id,
      desc
    }] = tableSorts;
    listingLibObserver.setBulkActionSelectionMode(BULK_SELECTION_MODE.NONE);
    trackCrmTableInteraction({
      action: 'sort-by-column',
      sortDirection: desc ? DESC : ASC,
      property: id
    });
    onUpdateFilters({
      sort: {
        property: id,
        order: desc ? DESC : ASC
      }
    });
    if (!hasViews || !currentView) {
      return;
    }
    const {
      state
    } = currentView;
    const changedSortKey = id !== state.sortKey;
    const changedSortOrder = state.order === 1 && !desc || state.order === -1 && desc;
    setViewUpdateState(prev => Object.assign({}, prev, {
      changedSort: changedSortKey || changedSortOrder
    }));
  }, [currentView, hasViews, onUpdateFilters, setViewUpdateState, trackCrmTableInteraction]);
  const {
    sortDirection,
    sortKey,
    sorted
  } = useMemo(() => getSort(filters), [filters]);
  return {
    onSetSort,
    sortDirection,
    sortKey,
    sorted
  };
}
export default useCrmObjectTypeListingSort;