import { useMemo } from 'react';
import useCrmObjectName from 'marketing-platform-lib/hooks/useCrmObjectName';
import useObjectTypeId from 'framework-listing-lib/internal/hooks/useObjectTypeId';
import useSkipCrm from 'framework-listing-lib/internal/hooks/useSkipCrm';
import { useProperties } from 'framework-listing-lib/internal/providers/PropertiesProvider';
export default function useCrmObjectTypeName(objectTypeIdArg) {
  const currentObjectTypeId = useObjectTypeId();
  const objectTypeId = useMemo(() => objectTypeIdArg || currentObjectTypeId, [currentObjectTypeId, objectTypeIdArg]);
  const skipCrm = useSkipCrm();
  const {
    typeDefinitionOverride
  } = useProperties();
  const name = useCrmObjectName(objectTypeId, {
    skip: skipCrm
  });
  return typeDefinitionOverride ? {
    objectTypeNamePlural: typeDefinitionOverride.pluralForm,
    objectTypeNameSingular: typeDefinitionOverride.singularForm
  } : name;
}