import { CLEARED_EXCLUSIVE_FILTERS, DEFAULT_FILTER_KEY, EXCLUSIVE_FILTERS, FILTERS, INTERSECTING_FILTERS, MULTI_SELECT_FILTERS, SUBSCRIPTION_CLASSIFICATION_TYPES } from 'email-health-ui-lib/filters/constants';
import { maybeMakeArray } from 'email-health-ui-lib/shared/utils/array';
import { getObjectEntries, getObjectFromEntries } from 'email-health-ui-lib/shared/utils/object';
import { getObjectFromLocationSearch } from 'email-health-ui-lib/shared/utils/url';
import memoize from 'transmute/memoize';
import { includes, partition, pick } from 'underscore';
export const buildStateKey = (...args) => args.filter(Boolean).join('_') || DEFAULT_FILTER_KEY;
export const getFiltersFromLocation = memoize(({
  search
}) => {
  const searchParams = getObjectFromLocationSearch(search);
  const exclusiveQueryFilters = Object.fromEntries(getObjectEntries(pick(searchParams, Object.values(EXCLUSIVE_FILTERS)))
  // For now, we only allow 1 Health filter to be active simultaneously
  // If a user adds more manually, then we only pick the first using splice below
  .splice(0, 1));
  const intersectingQueryFilters = pick(searchParams, Object.values(INTERSECTING_FILTERS));
  const updatedFilters = Object.assign({}, exclusiveQueryFilters, intersectingQueryFilters);

  // Convert the filter values which support multi select to arrays
  return getObjectFromEntries(getObjectEntries(updatedFilters).map(([name, value]) => [name, includes(MULTI_SELECT_FILTERS, name) ? maybeMakeArray(value) || undefined : value]));
});
export function getUpdatedFilters(filter, value) {
  const isExclusive = includes(Object.values(EXCLUSIVE_FILTERS), filter);
  const clearedParams = isExclusive ? CLEARED_EXCLUSIVE_FILTERS : {};
  return Object.assign({}, clearedParams, {
    // Set selected filters
    [filter]: value
  });
}
export const getFiltersQueryFromParams = params => {
  const [[marketingTransactionalType], subscriptionIds] = partition(params[FILTERS.SUBSCRIPTIONS] || [], subscription => Object.keys(SUBSCRIPTION_CLASSIFICATION_TYPES).includes(subscription));
  return {
    businessUnitId: params[FILTERS.BUSINESS_UNIT],
    emailType: params[FILTERS.EMAIL_TYPE],
    campaignGuid: params[FILTERS.CAMPAIGN],
    marketingTransactionalType,
    subscriptionIds: subscriptionIds.length ? subscriptionIds.map(Number) : undefined
  };
};