'use es6';

import { Map as ImmutableMap, Record } from 'immutable';
import I18n from 'I18n';
const defaults = {
  interval: null,
  stats: ImmutableMap({})
};
class RevenueOverTimeStats extends Record(defaults) {
  static from(json) {
    let formattedStats = defaults.stats;
    if (json.stats) {
      formattedStats = Object.keys(json.stats).reduce((accumulatedStats, key) => {
        return accumulatedStats.set(I18n.moment(key).valueOf(), json.stats[key][0].influencedDeals);
      }, ImmutableMap({}));
    }
    return new RevenueOverTimeStats({
      interval: json.interval,
      stats: formattedStats
    });
  }
}
export default RevenueOverTimeStats;