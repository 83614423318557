'use es6';

import { List, Record } from 'immutable';
import { IGNORED_EVENTS } from 'email-management-lib/refactor/utils/details/eventsUtil';
const defaults = {
  sentByEventId: 0,
  created: 0,
  isOpen: false,
  events: List([])
};
function getEventType(event) {
  return event.get('type');
}
function isNotIgnoredEvent(event) {
  const eventType = getEventType(event);
  return !IGNORED_EVENTS[eventType];
}
class RecipientEvents extends Record(defaults) {
  static from(json) {
    return new RecipientEvents(json);
  }
  getEventsWithoutIgnoredTypes() {
    return this.events.filter(isNotIgnoredEvent);
  }
}
export default RecipientEvents;