'use es6';

import { useEffect, useState } from 'react';
import { getBulkSuspensionStatus } from '../api/suspensionStatus';
import usePrevious from 'react-utils/hooks/usePrevious';
import isEqual from 'hs-lodash/isEqual';
import { BulkContentReview } from '../records/BulkContentReview';
export const useReviewData = (content, keyWord = null) => {
  const [reviewData, setReviewData] = useState(null);
  const prevContent = usePrevious(content);
  useEffect(() => {
    if (!isEqual(content, prevContent) && content && content.length > 0) {
      getBulkSuspensionStatus(content, keyWord).then(data => {
        setReviewData(BulkContentReview(data, keyWord));
      }).catch(() => setReviewData(null));
    }
  }, [keyWord, content, prevContent]);
  return reviewData;
};