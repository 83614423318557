'use es6';

import { Set as ImmutableSet, fromJS } from 'immutable';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { FETCH_AGATHA_CANCELATION_REASON, FETCH_AGATHA_CANCELATION_REASON_REQUEST } from 'email-management-lib/refactor/actions/actionTypes';
const initialState = ImmutableSet();
function campaignCancellationReasons(state = initialState, action) {
  switch (action.type) {
    case FETCH_AGATHA_CANCELATION_REASON:
      if (action.error !== true) {
        const campaignId = action.payload.campaignId;
        action.payload.campaignId = parseInt(campaignId, 10);
        return state.add(fromJS(action.payload));
      }
      return state;
    default:
      return state;
  }
}
export default asyncResource(campaignCancellationReasons, {
  requestActions: [FETCH_AGATHA_CANCELATION_REASON_REQUEST],
  responseActions: [FETCH_AGATHA_CANCELATION_REASON],
  invalidatingActions: []
});