import http from 'hub-http/clients/apiClient';
const FETCH_URI = 'inbounddb-io/imports/history/paged';
const MAILCHIMP_STATUS_URI = 'mailchimp/v1/import-contacts/status';
export function fetchHistoricalImportsRequest() {
  return http.get(FETCH_URI, {
    query: {
      includeLists: true,
      includeRequest: true
    }
  });
}
export function fetchMailchimpImportStatusRequest() {
  return http.get(MAILCHIMP_STATUS_URI);
}