'use es6';

import { INIT_PATH_PARAMS, INIT_QUERY_PARAMS, UPDATE_PATH_PARAMS, UPDATE_QUERY_PARAMS } from 'EmailData/actions/actionTypes';
import router from 'EmailData/utils/history';
import { getCurrentPathname, renderQueryParams } from 'EmailData/utils/url';
import emptyFunction from 'react-utils/emptyFunction';
export function initParams(queryParams, pathParams, fetchFunc) {
  return dispatch => {
    dispatch({
      type: INIT_QUERY_PARAMS,
      payload: queryParams
    });
    dispatch({
      type: INIT_PATH_PARAMS,
      payload: pathParams
    });
    if (fetchFunc && fetchFunc !== emptyFunction) {
      return dispatch(fetchFunc());
    }
    return Promise.resolve();
  };
}
export function updateQueryParams(queryParams, fetchFunc = emptyFunction) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_QUERY_PARAMS,
      payload: queryParams
    });
    if (fetchFunc !== emptyFunction && typeof fetchFunc === 'function') {
      dispatch(fetchFunc());
    }
    const state = getState();
    router.navigateTo(getCurrentPathname(), renderQueryParams(state.getIn(['queryParams']).toJSON()));
  };
}
export function updatePathParams(pathParams, fetchFunc, newPath) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_PATH_PARAMS,
      payload: pathParams
    });
    if (fetchFunc !== emptyFunction) {
      dispatch(fetchFunc());
    }
    if (newPath) {
      const state = getState();
      router.navigateTo(newPath, renderQueryParams(state.getIn(['queryParams']).toJSON()));
    }
  };
}
export function updateQueryAndPathParams(queryParams, pathParams, fetchFunc, newPath, resetParams = false) {
  return (dispatch, getState) => {
    if (resetParams) {
      dispatch(initParams(queryParams, pathParams, fetchFunc));
      if (newPath) {
        const state = getState();
        router.navigateTo(newPath, renderQueryParams(state.getIn(['queryParams']).toJSON()));
      }
    } else {
      dispatch(updateQueryParams(queryParams, emptyFunction));
      dispatch(updatePathParams(pathParams, fetchFunc, newPath));
    }
  };
}