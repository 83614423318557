'use es6';

import { List, Record } from 'immutable';
import CampaignCancellation from './CampaignCancellation';
import Suspension from 'EmailData/models/Suspension';
const defaults = {
  suspensionDrops: 0,
  overLimitDrops: 0,
  campaignCancelledDrops: 0,
  suspension: Suspension.from({}),
  overLimitMessage: '',
  suspensionMessage: '',
  campaignCancellations: List([])
};
class CampaignStatus extends Record(defaults) {
  static from(json) {
    if (typeof json.campaignCancellations !== 'undefined') {
      json.campaignCancellations = List(json.campaignCancellations.map(cancellation => CampaignCancellation.from(cancellation)));
    }
    if (typeof json.suspension !== 'undefined') {
      json.suspension = Suspension.from(json.suspension);
    }
    return new CampaignStatus(json);
  }
}
export default CampaignStatus;