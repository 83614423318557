'use es6';

import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { FETCH_RECIPIENTS_EVENTS, FETCH_RECIPIENTS_EVENTS_REQUEST, TOGGLE_RECIPIENT_OPEN_STATE } from 'email-management-lib/refactor/actions/actionTypes';
import RecipientEvents from 'email-management-lib/refactor/models/details/RecipientEvents';
const initialState = RecipientEvents.from({});
function events(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_RECIPIENT_OPEN_STATE:
      return state.merge(action.payload);
    case FETCH_RECIPIENTS_EVENTS_REQUEST:
      return state;
    case FETCH_RECIPIENTS_EVENTS:
      if (!action.error) {
        return state.merge(action.payload);
      }
      return state;
    default:
      return state;
  }
}
export default asyncResource(events, {
  requestActions: [FETCH_RECIPIENTS_EVENTS_REQUEST],
  responseActions: [FETCH_RECIPIENTS_EVENTS, TOGGLE_RECIPIENT_OPEN_STATE],
  invalidatingActions: []
});