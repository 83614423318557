'use es6';

import { Map as ImmutableMap } from 'immutable';
import { INIT_QUERY_PARAMS, UPDATE_QUERY_PARAMS } from 'EmailData/actions/actionTypes';
const INITIAL_STATE = ImmutableMap();
export default function queryParams(state = INITIAL_STATE, action) {
  switch (action.type) {
    case INIT_QUERY_PARAMS:
      return ImmutableMap(action.payload);
    case UPDATE_QUERY_PARAMS:
      return state.mergeWith((oldValue, newValue) => {
        return newValue;
      }, action.payload);
    default:
      return state;
  }
}