import { UNFORMATTED } from 'customer-data-objects/property/NumberDisplayHint';
import { isCurrency, isNumber, isPropertySourceUserId } from 'customer-data-objects/property/PropertyIdentifier';
import { parsePropertySourceUserId } from 'customer-data-objects/property/PropertySourceIdentifier';
import { useMemo } from 'react';
import { usePropertyContext } from '../context/PropertyContext/usePropertyContext';
export const usePropertyValue = () => {
  const {
    property,
    value
  } = usePropertyContext();
  return useMemo(() => {
    // If a value can be read in standard format, do so;
    // otherwise, try local format
    if (isNumber(property) && !isCurrency(property) && value && property.numberDisplayHint !== UNFORMATTED) {
      return isNaN(Number(value)) ?
      // @ts-expect-error I18n isn't typed yet
      I18n.parseNumber(value, {
        precision: 5
      }) : Number(value);
    }
    if (value && isPropertySourceUserId(property, value)) {
      return parsePropertySourceUserId(value);
    }
    return value;
  }, [value, property]);
};