import { isFilterOperator } from 'marketing-platform-lib/utils/filters';
import { safeParseJSON } from 'framework-listing-lib/utils/json';
import { COLUMNS_QUERY_PARAM, FILTER_GROUPS_QUERY_PARAM } from './constants';
export function getCurrentURL() {
  return new URL(window.location.href);
}
export function parseColumns(queryParams, typeDefinition) {
  /**
   * Ignore columns when @primaryDisplayLabelPropertyName is not present.
   * This is to prevent views without this property from being created or saved.
   *
   * The @primaryDisplayLabelPropertyName is the first columns in table UIs with a link
   * to the object's detail page. Not point in having views without this information.
   */
  if (!typeDefinition.primaryDisplayLabelPropertyName) {
    return [];
  }
  let columns = [];
  if (!(COLUMNS_QUERY_PARAM in queryParams)) {
    return columns;
  }
  columns = safeParseJSON(decodeURIComponent(String(queryParams[COLUMNS_QUERY_PARAM])), []);
  if (!Array.isArray(columns)) {
    return [];
  }
  columns = columns.filter(col => typeof col === 'string').filter(Boolean);
  if (columns.length === 0) {
    return [];
  }

  // make sure to always send primaryDisplayLabelPropertyName
  columns = [typeDefinition.primaryDisplayLabelPropertyName, ...columns.filter(col => col !== typeDefinition.primaryDisplayLabelPropertyName)];
  return columns;
}
export function parseFilters(queryParams, queryParamKey) {
  if (!(queryParamKey in queryParams)) {
    return [];
  }
  let filters = [];
  filters = safeParseJSON(decodeURIComponent(String(queryParams[queryParamKey])), []);
  if (!Array.isArray(filters)) {
    return [];
  }
  filters = filters.filter(isFilterOperator).filter(Boolean);
  return filters;
}
export function parseFilterGroups(queryParams) {
  if (!(FILTER_GROUPS_QUERY_PARAM in queryParams)) {
    return [];
  }
  let filterGroups = [];
  filterGroups = safeParseJSON(decodeURIComponent(String(queryParams[FILTER_GROUPS_QUERY_PARAM])), []);
  if (!Array.isArray(filterGroups)) {
    return [];
  }
  filterGroups = filterGroups.filter(filterGroup => typeof filterGroup === 'object' && 'filters' in filterGroup && Array.isArray(filterGroup.filters)).map(filterGroup => ({
    filters: filterGroup.filters.filter(isFilterOperator).filter(Boolean)
  })).filter(filterGroup => filterGroup.filters.length > 0);
  return filterGroups;
}