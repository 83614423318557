import { Metrics } from '../../metrics/Metrics';
export const fetchConditionalPropertyOptionsRules = (objectTypeId, httpClient, offset) => {
  return httpClient.get(`conditional-properties/v2/options/${objectTypeId}/unmerged/paged`, {
    query: {
      pageSize: 100,
      offset
    }
  }).then(conditionalPropertyOptionsRules => {
    const {
      results,
      hasMore,
      offset: newOffset
    } = conditionalPropertyOptionsRules;
    if (hasMore) {
      return fetchConditionalPropertyOptionsRules(objectTypeId, httpClient, newOffset).then(moreRules => results.concat(moreRules));
    }
    Metrics.counter('fetchconditionalpropertyoptionsrules-success').increment();
    return results;
  }).catch(err => {
    Metrics.counter('fetchconditionalpropertyoptionsrules-failure').increment();

    //Todo: Sentry once Raven is setup in CDP
    console.log(err);
    throw err;
  });
};