import { useEffect, useRef, useState, useMemo } from 'react';
import { deleteSuperstoreValue, getSuperStoreValue } from 'campaigns-lib/api/superstore';
import { getCrmSearchCampaignAsCampaignSelectOption } from 'campaigns-lib/data/CampaignDao';
import { EMPTY_CAMPAIGN_TO_AUTO_SELECT_VALUE, ONE_DAY_IN_MILLISECONDS, USER_ATTRIBUTE_KEYS } from 'campaigns-lib/constants/userAttributes';
function isValid({
  guid,
  timestamp
}) {
  const isExpired = !timestamp || timestamp < Date.now() - ONE_DAY_IN_MILLISECONDS;
  return guid !== EMPTY_CAMPAIGN_TO_AUTO_SELECT_VALUE && !isExpired;
}
export function fetchCampaignToAutoSelect() {
  // Reads from superstore
  return getSuperStoreValue(USER_ATTRIBUTE_KEYS.CAMPAIGN_TO_AUTO_SELECT).then(valueFromSuperStore => {
    if (valueFromSuperStore && isValid(valueFromSuperStore)) {
      // Verifies the value from superstore and deletes the value
      return deleteSuperstoreValue(USER_ATTRIBUTE_KEYS.CAMPAIGN_TO_AUTO_SELECT).then(() => {
        return valueFromSuperStore;
      });
    }
    return null;
  }).then(campaignToAutoSelect => {
    if (campaignToAutoSelect) {
      // If returns a campaign, fetches the campaign option using the campaign guid
      return getCrmSearchCampaignAsCampaignSelectOption(campaignToAutoSelect.guid).then(([campaignAsOption]) => {
        if (campaignAsOption) {
          return campaignAsOption;
        }
        return null;
      });
    }
    return null;
  });
}
export default function useCampaignToAutoSelect() {
  const hasEverLoaded = useRef(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [campaignToAutoSelect, setCampaignToAutoSelect] = useState(null);
  useEffect(() => {
    if (!hasEverLoaded.current) {
      hasEverLoaded.current = true;
      setLoading(true);
      fetchCampaignToAutoSelect().then(campaignAsOption => {
        if (campaignAsOption) {
          setCampaignToAutoSelect(campaignAsOption);
        }
      }).catch(err => setError(err)).finally(() => setLoading(false));
    }
  }, [hasEverLoaded]);
  return useMemo(() => ({
    campaignToAutoSelect,
    error,
    loading
  }), [campaignToAutoSelect, error, loading]);
}