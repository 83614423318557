import { useMemo } from 'react';
import { registerQuery, useQuery } from 'data-fetching-client';
import { getSuperStoreValue } from 'framework-listing-lib/internal/utils/superstore';
export const GET_USER_SETTINGS_FIELD_NAME = 'userSettingsRead';
export const GET_USER_SETTINGS = registerQuery({
  fieldName: GET_USER_SETTINGS_FIELD_NAME,
  args: ['settingKey', 'options'],
  fetcher({
    settingKey,
    options
  }) {
    return getSuperStoreValue(settingKey).then(userSetting => {
      if (userSetting) {
        return userSetting;
      }
      return {
        key: settingKey,
        value: options.defaultValue
      };
    });
  }
});
export default function useFetchUserSettings(settingKey, options) {
  const {
    data,
    error,
    loading
  } = useQuery(GET_USER_SETTINGS, {
    variables: {
      settingKey,
      options
    },
    skip: settingKey === ''
  });
  return useMemo(() => ({
    data: data && GET_USER_SETTINGS_FIELD_NAME in data ? data[GET_USER_SETTINGS_FIELD_NAME] : undefined,
    error,
    loading
  }), [data, error, loading]);
}