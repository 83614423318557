import { getIsEditableAutomated, getIsSubscriptionEmail } from 'EmailData/utils/emailStateUtil';
import EmailType from 'EmailData/enums/EmailType';
export const canForceCancelEmail = (email, user, emailType, emailDetails) => {
  if (!email.isPublished()) {
    return false;
  } else if (emailType === EmailType.AB_EMAIL && emailDetails.canForceCancel() && !emailDetails.isWaitingForWinner()) {
    return user.canPublish();
  } else if ((emailType === EmailType.BATCH_EMAIL || emailType === EmailType.LOCALTIME_EMAIL) && (email.isProcessing() || email.isPreProcessing() || email.isInitializing())) {
    return user.canPublish();
  }
  return false;
};
const checkForAutomationAccess = (email, hasContextualAutomationGates) => (email.isBatch() || email.isLocalTime() || email.isABEmail() || email.isScheduled()) && hasContextualAutomationGates;
const hasAutomatedABAccess = (email, user) => {
  return !(email.isABLike() && !user.hasAccessToABEmails());
};
export const hasEmailBeenCancelled = email => email.wasForciblyCancelled() || email.wasCanceledForAbuse();

// TODO: Deprecated, migrate in other places to getCanEditEmailCurrentEmail
export const canEdit = (email, user, hasContextualAutomationGates = false) => {
  if (!hasAutomatedABAccess || email.isScheduled() && !hasContextualAutomationGates) {
    return false;
  }
  return (!email.isPublished() || getIsEditableAutomated(email) || email.isRssEmail() || email.isBlogEmail() || email.isOptinEmail() || checkForAutomationAccess(email, hasContextualAutomationGates)) && !email.isLeadFlowEmail() && !email.isFeedbackEmail() && user.canWrite();
};
export const canDelete = (email, user) => {
  return !email.isLeadFlowEmail() && !email.isFeedbackEmail() && !getIsSubscriptionEmail(email) && user.canWrite();
};
export const canArchive = (email, user) => {
  return !email.isArchived() && user.canWrite();
};
export const canMakeAdditionalSends = (email, user) => {
  // TODO: { Logic considering campaign sends }
  return user.canPublish() && email.isPublished() && !hasEmailBeenCancelled(email) && !email.isPreProcessing() && !email.isProcessing();
};
export function hasIndependentEditAction(email, hasContextualAutomationGates = false) {
  return email.isAutomatedLike() || email.isRssEmail() || checkForAutomationAccess(email, hasContextualAutomationGates);
}
export function hasEditEmailButton({
  email,
  canEditEmail,
  hasContextualAutomationGates = false
}) {
  return canEditEmail && !hasEmailBeenCancelled(email) && hasIndependentEditAction(email, hasContextualAutomationGates);
}