'use es6';

import { Map as ImmutableMap, Record, Set as ImmutableSet } from 'immutable';
const defaults = {
  dataMap: ImmutableMap(),
  lookups: ImmutableSet(),
  offset: 0,
  total: 0
};
const getFilteredResults = (newResult, currentResult) => {
  const incomingKeys = ImmutableSet(newResult.keySeq());
  const diff = incomingKeys.subtract(currentResult.keySeq());
  return newResult.filter((value, key) => diff.has(key));
};
class IncrementalResult extends Record(defaults) {
  // Adds results to the dataMap without changing the offset
  add(result, lookup) {
    // Ensure there is no duplication of elements
    const filteredResults = getFilteredResults(result, this.dataMap);
    const newLookups = lookup ? this.lookups.add(lookup) : this.lookups;
    return new IncrementalResult({
      dataMap: this.dataMap.merge(filteredResults),
      lookups: newLookups,
      offset: this.offset,
      total: this.total
    });
  }
  addSingle(key, result) {
    if (this.dataMap.has(key)) {
      return this;
    }
    return new IncrementalResult({
      dataMap: this.dataMap.set(key, result),
      lookups: this.lookups,
      offset: this.offset,
      total: this.total
    });
  }

  // Increments the results adding the given elements and updates the offset
  inc(result, total) {
    // Ensure there is no duplication of elements
    const filteredResults = getFilteredResults(result, this.dataMap);
    return new IncrementalResult({
      dataMap: this.dataMap.merge(filteredResults),
      lookups: this.lookups,
      offset: this.offset + filteredResults.size,
      total: total > this.total ? total : this.total
    });
  }
  findMissingKeys(keys) {
    return keys.filter(key => !this.dataMap.has(key));
  }
  getData(key) {
    return this.dataMap.get(key);
  }
  hasKey(key) {
    return this.dataMap.has(key);
  }
  hasLookup(key) {
    return this.lookups.has(key);
  }
  hasMoreResults() {
    return this.dataMap.size < this.total;
  }
  isEmpty() {
    return this.dataMap.isEmpty();
  }
  size() {
    return this.total;
  }
}
export default IncrementalResult;