import http from 'hub-http/clients/apiClient';
import { registerQuery, useQuery } from 'data-fetching-client';
const BASE_URL = `app-users/v1/teams`;
const PORTAL_TEAMS_QUERY_NAME = 'portalTeams';
export const PortalTeamsQuery = registerQuery({
  fieldName: PORTAL_TEAMS_QUERY_NAME,
  args: [],
  fetcher: () => http.get(BASE_URL)
});
export const useFetchPortalTeams = () => {
  return useQuery(PortalTeamsQuery);
};