import { DataFetchingClient } from 'data-fetching-client';
import { DEFAULT_PINNED_VIEWS_QUERY_NAME } from '../portalViews/hooks/useGetPortalDefaultViews';
import { SingleViewByIdQuery } from '../singleView/hooks/useFetchSingleView';
import { VIEWS_SEARCH_QUERY_NAME } from '../viewsSearch/hooks/useFetchViewsSearch';
import { getViewsByCatagoryQueryNameForCategory } from '../standardViews/hooks/useFetchViewsByCategory';
import { SupportedCategories, VIEW_CATEGORY_MINE } from '../addView/ViewCategories';
import { CREATE_SINGLE_VIEW_MUTATION_NAME } from '../createView/hooks/useCreateSingleViewMutation';
export const mergeUnique = ({
  existing,
  incoming
}) => {
  if (!(existing !== null && existing !== void 0 && existing.length)) {
    return incoming;
  }
  if (!(incoming !== null && incoming !== void 0 && incoming.length)) {
    return existing;
  }
  const existingMap = new Map(existing.map(view => [view.id, view]));
  const incomingMap = new Map(incoming.map(view => [view.id, view]));
  const uniqueIdOrder = [...new Set([...existing.map(({
    id
  }) => id), ...incoming.map(({
    id
  }) => id)])];

  // If there is any overlap betwee existing and incoming,
  // maintain the order in existing
  // but prefer the incoming data as it is more up to date
  const merged = uniqueIdOrder.map(id => incomingMap.get(id) || existingMap.get(id)).filter(x => Boolean(x));
  return merged;
};
export const upsertViewIntoViewsByCategory = newView => cacheRef => {
  // If the cacheRef doesn't exist, then barring race condition issues, the
  // new view should be included in the next fetch (or on susbsequent pagination)
  // So no need to modify the cache
  if (!(cacheRef !== null && cacheRef !== void 0 && cacheRef.viewItems) || !newView) {
    return;
  }

  // This shouldn't happen unless the query gets included in refetchQueries at some point
  // but cache.modify bypasses the field policies, so this is here just to be safe
  const existingIndex = cacheRef.viewItems.findIndex(
  // This assumes a non-normalized cache, which should be safe for DFC
  ({
    id
  }) => id === newView.id);
  if (existingIndex !== -1) {
    return Object.assign({}, cacheRef, {
      viewItems: cacheRef.viewItems.slice(0, existingIndex).concat(newView, cacheRef.viewItems.slice(existingIndex + 1))
    });
  }
  return Object.assign({}, cacheRef, {
    total: (cacheRef.total || 0) + 1,
    viewItems: [newView, ...(cacheRef.viewItems || [])]
  });
};
const viewsByCategoryKeyArgs = ['query', 'objectTypeId'];
export const getDefaultDataFetchingClient = () => {
  return new DataFetchingClient({
    mutationFieldPolicies: {
      [CREATE_SINGLE_VIEW_MUTATION_NAME]: {
        merge(__, incoming, {
          cache
        }) {
          cache.modify({
            fields: {
              [getViewsByCatagoryQueryNameForCategory(VIEW_CATEGORY_MINE)]: upsertViewIntoViewsByCategory(incoming)
            }
          });
          return incoming;
        }
      }
    },
    queryFieldPolicies: Object.assign({
      [VIEWS_SEARCH_QUERY_NAME]: {
        merge(__, incoming, {
          cache
        }) {
          incoming.results.forEach(view => {
            cache.writeQuery({
              data: {
                singleViewById: view
              },
              query: SingleViewByIdQuery,
              variables: {
                id: view.id,
                objectTypeId: view.objectTypeId,
                namespaceName: view.namespaceName
              }
            });
          });
          return incoming;
        }
      },
      [DEFAULT_PINNED_VIEWS_QUERY_NAME]: {
        merge(__, incoming, {
          cache
        }) {
          (incoming || []).forEach(view => {
            cache.writeQuery({
              data: {
                singleViewById: view
              },
              query: SingleViewByIdQuery,
              variables: {
                id: view.id,
                objectTypeId: view.objectTypeId,
                namespaceName: view.namespaceName
              }
            });
          });
          return incoming;
        }
      }
    }, SupportedCategories.reduce((policies, category) => Object.assign({}, policies, {
      [getViewsByCatagoryQueryNameForCategory(category)]: {
        keyArgs: viewsByCategoryKeyArgs,
        merge(existing, incoming, {
          cache
        }) {
          (incoming && incoming.viewItems ? incoming.viewItems : []).forEach(view => {
            cache.writeQuery({
              data: {
                singleViewById: view
              },
              query: SingleViewByIdQuery,
              variables: {
                id: view.id,
                objectTypeId: view.objectTypeId,
                namespaceName: view.namespaceName
              }
            });
          });
          const uniqueMergedViewItems = mergeUnique({
            existing: existing === null || existing === void 0 ? void 0 : existing.viewItems,
            incoming: incoming === null || incoming === void 0 ? void 0 : incoming.viewItems
          });
          return Object.assign({}, incoming, {
            viewItems: uniqueMergedViewItems
          });
        }
      }
    }), {}))
  });
};