'use es6';

import { Map as ImmutableMap } from 'immutable';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { RESET_EMAIL_DETAILS } from 'EmailData/actions/actionTypes';
import { EMAIL_UPDATE, FETCH_EMAIL_DETAILS, FETCH_EMAIL_DETAILS_REQUEST, FETCH_SMTP_DETAILS, FETCH_SMTP_DETAILS_REQUEST, RESET_SMTP_PASSWORD } from 'email-management-lib/refactor/actions/actionTypes';
import EmailDetailsFactory from 'EmailData/models/details/EmailDetailsFactory';
const initialState = ImmutableMap({});
function emailDetails(state = initialState, action) {
  switch (action.type) {
    case FETCH_EMAIL_DETAILS:
      if (action.error !== true) {
        return EmailDetailsFactory.fromDetails(action.payload);
      }
      return state;
    case EMAIL_UPDATE:
      if (action.error !== true) {
        return EmailDetailsFactory.patchPrimaryEmail(action.payload, state);
      }
      return state;
    case RESET_SMTP_PASSWORD:
    case FETCH_SMTP_DETAILS:
      if (action.error !== true) {
        return EmailDetailsFactory.fromSmtpToken(action.payload, state);
      }
      return state;
    case RESET_EMAIL_DETAILS:
      return initialState;
    default:
      return state;
  }
}
export default asyncResource(emailDetails, {
  requestActions: [FETCH_EMAIL_DETAILS_REQUEST, FETCH_SMTP_DETAILS_REQUEST],
  responseActions: [FETCH_EMAIL_DETAILS, FETCH_SMTP_DETAILS],
  invalidatingActions: [RESET_EMAIL_DETAILS],
  customActions: [EMAIL_UPDATE]
});