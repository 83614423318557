import { useEffect, useState } from 'react';
import devLogger from 'react-utils/devLogger';
// eslint-disable-next-line no-restricted-imports
import userInfo, { userInfoSync } from 'hub-http/userInfo';
export const useUserInfo = () => {
  const [userInfoResponse, setUserInfoResponse] = useState(() => {
    // initialize with userInfoSync in case userInfo has already been loaded.
    // this avoids an initial render without gates/scopes, if possible.
    // userInfoSync throws an Error if userInfo has not finished loading yet;
    // in that case fall back to waiting for async userInfo
    try {
      return userInfoSync();
    } catch (__error) {
      devLogger.warn({
        message: `propertyinputv2-userinfosync: Failure reading userinfo sync`,
        key: 'propertyinputv2-userinfosync'
      });
    }
    return undefined;
  });
  useEffect(() => {
    if (userInfoResponse) {
      return;
    }
    try {
      // ignoreRedirect is important! Without it, any consumers of CDP that don't
      // support or shim userInfo can end up redirecting (the default behavior).
      // This has caused past critsits:
      // https://git.hubteam.com/HubSpot/Critsit/issues/3557
      // https://git.hubteam.com/HubSpot/Critsit/issues/1040
      userInfo({
        ignoreRedirect: true
      }).then(response => {
        setUserInfoResponse(response);
      }).catch(() => {
        devLogger.warn({
          message: `propertyinputv2-userinfo: API failure fetching userinfo`,
          key: 'propertyinputv2-userinfo-api'
        });
      });
    } catch (err) {
      devLogger.warn({
        message: `propertyinputv2-userinfo: Failure fetching userinfo`,
        key: 'propertyinputv2-userinfo'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return userInfoResponse;
};