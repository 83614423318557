import Raven from 'raven-js';
import { useEffect, useRef, useState } from 'react';
import { UpgradeMessageEventKeys } from 'ui-addon-upgrades/_core/common/constants/upgradeMessageEventKeys';
import { track } from '../_core/common/eventTracking/tracker';
import { useUpgradeModal } from './useUpgradeModal';
import { getUpgradeConfigTrackingProperties, getUpgradePointPromise, updateViewedUpgradePointsCache } from './utils';
const handleFetchUpgradePoint = (upgradeKey, isFeatureLocked) => {
  const upgradePointPromise = getUpgradePointPromise({
    upgradeKey,
    delayCall: true
  });
  upgradePointPromise.then(upgradePoint => {
    if (isFeatureLocked && upgradePoint) {
      updateViewedUpgradePointsCache(upgradeKey, () => {
        track('interaction', Object.assign({
          action: 'viewed upgrade point'
        }, getUpgradeConfigTrackingProperties(upgradePoint)));
      });
    }
  }).catch(error => {
    Raven.captureException(error);
  });
};

/**
 * `useUpgradeEventListener` should be called in the top window of your
 * application when using `useUpgradeModal` inside of an iframe. This will
 * mount an event listener to listen for upgrade events, which will in turn
 * open the upgrade modal in the parent window instead of the iframe.
 *
 * @example
 * ```tsx
 * const WrapperComponent = () => {
 *   useUpgradeEventListener();
 *
 *   return <iframe src="your-source.hubspot.com" />;
 * }
 * ```
 */
export const useUpgradeEventListener = () => {
  // We store this item in state as an object in order
  // to re-evaluate this component on every message.
  // This is because when the modal key changes,
  // we need `useUpgradeModal` to re-run so that
  // `openUpgradeModal` opens for the right upgrade point.
  const [key, setUpgradeKey] = useState({
    inner: ''
  });
  const {
    openUpgradeModal
  } = useUpgradeModal(key.inner);
  const openUpgradeModalRef = useRef(openUpgradeModal);
  useEffect(() => {
    openUpgradeModalRef.current = openUpgradeModal;
  }, [openUpgradeModal]);
  useEffect(() => {
    const handleMessage = messageEvent => {
      if (!messageEvent || !messageEvent.data) {
        return;
      }
      switch (messageEvent.data.event) {
        case UpgradeMessageEventKeys.OPEN_UPGRADE_PAGE:
          setUpgradeKey({
            inner: messageEvent.data.upgradeKey
          });
          break;
        case UpgradeMessageEventKeys.FETCH_UPGRADE_POINT:
          handleFetchUpgradePoint(messageEvent.data.upgradeKey, messageEvent.data.isFeatureLocked);
          break;
        default:
          break;
      }
    };
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);
  useEffect(() => {
    if (key.inner) {
      openUpgradeModalRef.current();
    }
  }, [key]);
};