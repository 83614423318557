/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable promise/catch-or-return */

// @ts-expect-error not migrated to TS yet
import { getCurrentFilters } from 'email-management-lib/refactor/selectors/manage/manageFilters';
// @ts-expect-error not migrated to TS yet
import { selectTableFetchStatus } from 'email-management-lib/refactor/selectors/manage/tableStatus';
import { userIdSelector, getHasUserTeamsAssignment
// @ts-expect-error not migrated to TS yet
} from 'EmailData/selectors/user';
// @ts-expect-error not migrated to TS yet
import { growl } from 'EmailComponents/errors/growl';
import I18n from 'I18n';
import isEqual from 'hs-lodash/isEqual';
import Raven from 'raven-js';
import * as storage from 'EmailData/lib/storage';
import PortalIdParser from 'PortalIdParser';
import { getEarlyEmailListingKey, getEarlyEmailListingMinimal } from 'email-management-lib/refactor/earlyRequesters/requestUtils';
import { RequestStatus } from 'EmailData/constants/requestStatus';
import { uniqueId } from 'email-management-lib/refactor/utils/uuid';
import { getEarlyRequest, readEarlyOrFetch, cleanEarlyRequests } from 'EmailData/api/earlyRequests';
import { REQUEST_EMAILS, RECEIVE_EARLY_MINIMAL_EMAILS, RECEIVE_EMAILS, REQUEST_HAS_ARCHIVED, RECEIVE_HAS_ARCHIVED, FETCH_SENT_EMAILS, REQUEST_SENT_EMAILS, DISMISS_CRITSIT_DASHBOARD_BANNER, DISMISS_SIDEBAR_TOOLS_CAROUSEL, RECEIVE_HAS_EMAIL, REQUEST_HAS_EMAIL, EXPORT_EMAILS, EXPORT_UNSUBS_AND_BOUNCES, REQUEST_EXPORT_EMAILS, REQUEST_EXPORT_UNSUBS_AND_BOUNCES, REQUEST_FOLDERS, RECEIVE_FOLDERS } from 'email-management-lib/refactor/actions/actionTypes';
import { isValidManageUrl } from 'email-management-lib/refactor/utils/url';
import { fetchHasArchived, fetchHasEmails, getEmails, tagMinimalAndParse, exportEmailsInFrameworkRequest, exportUnsubsAndBouncesRequest, fetchHasArchivedByCrmSearch, fetchHasEmailsByCrmSearch } from 'email-management-lib/refactor/api/emails';
import { getHasArchivedEmailsLoaded, getHasEmailsLoaded } from 'email-management-lib/refactor/selectors/manage/table';
import { LOCAL_STORAGE_BASE_KEY } from 'email-management-lib/refactor/utils/constants';
import { trackDashboardInteraction } from 'EmailComponents/utils/tracking';
import { ALL_CRM_COLUMNS, COLUMNS_IN_VIEW_ONLY, LIMITED_PRESET_COLUMNS } from 'email-management-lib/refactor/constants/manage/exportConstants';
import { exportEmailsRequest } from 'EmailData/api/emails';
import { fetchFolderTree } from 'email-management-lib/refactor/api/folders';
import { fetchTeams } from 'ContentData/actions/Teams';
import { applySearchCRMRequestOptionsChanges } from '../../api/crmSearch';
let lastFetchEmailsQuery = {};
let lastFetchEmailGuid;
const PORTAL_EMAILS_SENT_MORE_THAN_TEN_KEY = 'PortalHasSentMoreThanTenEmails';
export function fetchEmails(forceUpdateEmailList = false) {
  return (dispatch, getState) => {
    const state = getState();
    const query = getCurrentFilters(state);
    const emailsMutated = state.getIn(['manage', 'emailsMutated']);
    if (!emailsMutated && isEqual(query, lastFetchEmailsQuery) && !forceUpdateEmailList) {
      return Promise.resolve();
    }
    const earlyRequestKey = getEarlyEmailListingKey();
    const earlyRequestMinimalKey = getEarlyEmailListingMinimal();
    const earlyEmailRequest = getEarlyRequest(earlyRequestKey);
    const earlyEmailRequestMinimal = getEarlyRequest(earlyRequestMinimalKey);

    // Super early action in `App.js` succeeded - cleanup and resolve
    if (earlyEmailRequest && selectTableFetchStatus(state) === RequestStatus.SUCCEEDED) {
      cleanEarlyRequests(earlyRequestKey);
      cleanEarlyRequests(earlyRequestMinimalKey);
      return Promise.resolve();
    }
    const requestId = uniqueId();
    lastFetchEmailGuid = requestId;
    lastFetchEmailsQuery = query;
    dispatch({
      type: REQUEST_EMAILS,
      payload: {
        query
      }
    });
    if (earlyEmailRequest && isValidManageUrl(window.location.pathname, window.location.search)) {
      if (earlyEmailRequestMinimal) {
        readEarlyOrFetch(earlyRequestMinimalKey).then(tagMinimalAndParse({
          tagValue: true
        })).then(response => processingEarlyMinimalResponse(dispatch, requestId, response), err => Raven.captureException(err));
      }
      return readEarlyOrFetch(earlyRequestKey).then(tagMinimalAndParse({
        tagValue: false
      })).then(response => processListingResponse(dispatch, requestId, response), err => processListingError(dispatch, requestId, err));
    }
    getEmails(query).then(response => processListingResponse(dispatch, requestId, response), err => processListingError(dispatch, requestId, err)).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
    return undefined;
  };
}
export function maybeFetchHasArchivedEmails() {
  return (dispatch, getState) => {
    const state = getState();
    const hasArchivedEmailLoaded = getHasArchivedEmailsLoaded(state);
    if (hasArchivedEmailLoaded) {
      return Promise.resolve();
    }
    dispatch({
      type: REQUEST_HAS_ARCHIVED
    });
    return fetchHasArchived().then(response => Promise.resolve(dispatch({
      type: RECEIVE_HAS_ARCHIVED,
      payload: response.total > 0
    })), err => Promise.resolve(dispatch({
      type: RECEIVE_HAS_ARCHIVED,
      error: true,
      payload: err
    })));
  };
}
function processListingResponse(dispatch, requestId, response) {
  if (lastFetchEmailGuid === requestId) {
    dispatch({
      type: RECEIVE_EMAILS,
      payload: Object.assign({}, response)
    });
  }
  if (response.total === 0) {
    return dispatch(maybeFetchHasArchivedEmails());
  }
  return Promise.resolve();
}
function processListingError(dispatch, requestId, err) {
  if (lastFetchEmailGuid === requestId) {
    dispatch({
      type: RECEIVE_EMAILS,
      error: true,
      payload: err,
      metadata: {
        growlError: {
          error: err,
          feature: RECEIVE_EMAILS,
          messageKey: 'email-management.errors.email.fetch'
        }
      }
    });
  }
  return Promise.resolve();
}
function processingEarlyMinimalResponse(dispatch, requestId, response) {
  if (lastFetchEmailGuid === requestId) {
    dispatch({
      type: RECEIVE_EARLY_MINIMAL_EMAILS,
      payload: Object.assign({}, response)
    });
  }
  return Promise.resolve();
}
export function fetchSentEmailsCount() {
  return dispatch => {
    const portalId = PortalIdParser.get();
    dispatch({
      type: REQUEST_SENT_EMAILS
    });

    // Check cached results to skip api fetch
    if (storage.has(portalId, LOCAL_STORAGE_BASE_KEY, PORTAL_EMAILS_SENT_MORE_THAN_TEN_KEY) && storage.get(portalId, LOCAL_STORAGE_BASE_KEY, PORTAL_EMAILS_SENT_MORE_THAN_TEN_KEY)) {
      return Promise.resolve(dispatch({
        type: FETCH_SENT_EMAILS,
        payload: {
          totalSendCount: 11
        }
      }));
    }
    return fetchHasEmails().then(response => {
      if (response.totalCount >= 10) {
        storage.set(portalId, LOCAL_STORAGE_BASE_KEY, PORTAL_EMAILS_SENT_MORE_THAN_TEN_KEY, true);
      }
      return Promise.resolve(dispatch({
        type: FETCH_SENT_EMAILS,
        payload: response
      }));
    }, err => {
      return Promise.resolve(dispatch({
        type: FETCH_SENT_EMAILS,
        error: true,
        payload: err
      }));
    });
  };
}
export function maybeFetchHasEmails() {
  return (dispatch, getState) => {
    const state = getState();
    const hasEmailsLoaded = getHasEmailsLoaded(state);
    if (hasEmailsLoaded) {
      return Promise.resolve();
    }
    dispatch({
      type: REQUEST_HAS_EMAIL
    });
    return fetchHasEmails().then(response => Promise.resolve(dispatch({
      type: RECEIVE_HAS_EMAIL,
      payload: response.total > 0
    })), err => Promise.resolve(dispatch({
      type: RECEIVE_HAS_EMAIL,
      error: true,
      payload: err
    })));
  };
}
export function maybeFetchHasEmailsByCrmSearch() {
  return (dispatch, getState) => {
    const state = getState();
    const hasEmailsLoaded = getHasEmailsLoaded(state);
    if (hasEmailsLoaded) {
      return Promise.resolve();
    }
    dispatch({
      type: REQUEST_HAS_EMAIL
    });
    return fetchHasEmailsByCrmSearch().then(response => Promise.resolve(dispatch({
      type: RECEIVE_HAS_EMAIL,
      payload: response.total > 0
    })), err => Promise.resolve(dispatch({
      type: RECEIVE_HAS_EMAIL,
      error: true,
      payload: err
    })));
  };
}
export function maybeFetchHasArchivedEmailsByCrmSearch() {
  return (dispatch, getState) => {
    const state = getState();
    const hasArchivedEmailLoaded = getHasArchivedEmailsLoaded(state);
    if (hasArchivedEmailLoaded) {
      return Promise.resolve();
    }
    dispatch({
      type: REQUEST_HAS_ARCHIVED
    });
    return fetchHasArchivedByCrmSearch().then(response => Promise.resolve(dispatch({
      type: RECEIVE_HAS_ARCHIVED,
      payload: response.total > 0
    })), err => Promise.resolve(dispatch({
      type: RECEIVE_HAS_ARCHIVED,
      error: true,
      payload: err
    })));
  };
}
export function exportEmails({
  format,
  email,
  name,
  timeRangeSelect,
  advancedFields = {}
}) {
  return (dispatch, getState) => {
    const state = getState();
    const query = getCurrentFilters(state);
    const userId = userIdSelector(state);
    const {
      includeAdvanced,
      extraFields
    } = advancedFields;
    const finalSettings = Object.assign({
      archived: false
    }, query, {
      format,
      email,
      userId,
      extraFields,
      exportAdvancedStats: includeAdvanced || false,
      name: name || I18n.text('email-management.modals.export.exportDefaultName')
    });
    if (timeRangeSelect && timeRangeSelect.startDate && timeRangeSelect.endDate) {
      const {
        startDate,
        endDate
      } = timeRangeSelect;
      finalSettings.startTimestamp = I18n.moment(startDate).valueOf();
      finalSettings.endTimestamp = I18n.moment(endDate).endOf('day').valueOf();
    }
    trackDashboardInteraction({
      'other-actions': 'export'
    });
    dispatch({
      type: REQUEST_EXPORT_EMAILS,
      payload: {
        query
      }
    });
    const exportPromise = exportEmailsRequest(finalSettings).then(() => {
      dispatch({
        type: EXPORT_EMAILS
      });
    });
    exportPromise.catch(err => {
      dispatch({
        type: EXPORT_EMAILS,
        error: true,
        payload: err
      });
      const userFriendlyError = I18n.text('email-management.errors.email.export');
      growl('error', userFriendlyError, 'EmailExport');
    });
    return exportPromise;
  };
}
export function exportEmailsInFramework({
  advancedFields = {},
  allCrmColumns = COLUMNS_IN_VIEW_ONLY,
  crmSearchRequest,
  columns,
  email,
  format,
  name,
  timeRangeSelect,
  currentUserId
}) {
  const {
    includeAdvanced
  } = advancedFields;
  const isLegacyExport = allCrmColumns === LIMITED_PRESET_COLUMNS;
  const exportAllProperties = allCrmColumns === ALL_CRM_COLUMNS;
  const exportAdvancedStats = includeAdvanced || false;
  const finalSettings = {
    crmSearchRequest: applySearchCRMRequestOptionsChanges({
      requestOptions: crmSearchRequest,
      currentUserId
    }),
    columns,
    email,
    exportAdvancedStats,
    exportAllProperties,
    format,
    isLegacyExport,
    name: name || I18n.text('email-management.modals.export.exportDefaultName'),
    portalId: PortalIdParser.get()
  };
  if (timeRangeSelect && timeRangeSelect.startDate && timeRangeSelect.endDate) {
    const {
      startDate,
      endDate
    } = timeRangeSelect;
    finalSettings.startTimestamp = I18n.moment(startDate).valueOf();
    finalSettings.endTimestamp = I18n.moment(endDate).endOf('day').valueOf();
  }
  trackDashboardInteraction({
    'other-actions': 'exportInFramework'
  });
  trackDashboardInteraction({
    'other-actions': `isLegacyExport: ${isLegacyExport}`
  });
  trackDashboardInteraction({
    'other-actions': `exportAllProperties: ${exportAllProperties}`
  });
  trackDashboardInteraction({
    'other-actions': `exportAdvancedStats: ${exportAdvancedStats}`
  });
  const exportPromise = exportEmailsInFrameworkRequest(finalSettings).catch(err => {
    const userFriendlyError = I18n.text('email-management.errors.email.export');
    growl('error', userFriendlyError, 'EmailExportInFramework');
    Raven.captureException(err);
  });
  return exportPromise;
}
export function exportUnsubscribesAndBounces({
  email,
  includeGlobal,
  includeDeleted,
  includedExportTypes
}) {
  return dispatch => {
    const query = {
      email,
      portalId: PortalIdParser.get(),
      includeGlobalEvents: !!includeGlobal,
      includeDeletedContacts: !!includeDeleted,
      exportTypes: includedExportTypes
    };
    dispatch({
      type: REQUEST_EXPORT_UNSUBS_AND_BOUNCES,
      payload: {
        query
      }
    });
    const exportPromise = exportUnsubsAndBouncesRequest(query).then(() => {
      dispatch({
        type: EXPORT_UNSUBS_AND_BOUNCES
      });
    });
    exportPromise.catch(err => {
      dispatch({
        type: EXPORT_UNSUBS_AND_BOUNCES,
        error: true,
        payload: err
      });
      const userFriendlyError = I18n.text('email-management.errors.email.exportData');
      growl('error', userFriendlyError, 'UnsubsAndBouncesExport');
    });
    return exportPromise;
  };
}
export function dismissOutageCritsitBanner() {
  return {
    type: DISMISS_CRITSIT_DASHBOARD_BANNER
  };
}
export function dismissSidebarToolsCarousel() {
  return {
    type: DISMISS_SIDEBAR_TOOLS_CAROUSEL
  };
}
export function fetchFolders() {
  return dispatch => {
    dispatch({
      type: REQUEST_FOLDERS
    });
    fetchFolderTree().then(folders => {
      dispatch({
        type: RECEIVE_FOLDERS,
        payload: {
          folders
        }
      });
    }, err => {
      dispatch({
        type: RECEIVE_FOLDERS,
        error: true,
        payload: err,
        metadata: {
          growlError: {
            error: err,
            feature: RECEIVE_FOLDERS,
            messageKey: 'email-management.errors.folders.fetch'
          }
        }
      });
    });
  };
}
export function fetchTeamsByUser() {
  return (dispatch, getState) => {
    const state = getState();
    const isTeamAssignment = getHasUserTeamsAssignment(state);
    dispatch(fetchTeams(isTeamAssignment));
  };
}