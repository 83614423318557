'use es6';

import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { FETCH_SENT_EMAILS, REQUEST_SENT_EMAILS } from 'email-management-lib/refactor/actions/actionTypes';
const initialState = 0;
function onboardingSendStats(state = initialState, action) {
  switch (action.type) {
    case FETCH_SENT_EMAILS:
      if (action.error !== true) {
        return action.payload.totalCount;
      }
      return state;
    default:
      return state;
  }
}
export default asyncResource(onboardingSendStats, {
  requestActions: [REQUEST_SENT_EMAILS],
  responseActions: [FETCH_SENT_EMAILS],
  invalidatingActions: []
});