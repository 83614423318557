import { keyMirror } from 'email-health-ui-lib/shared/utils/object';
export const HEALTH_USER_ATTRIBUTES = {
  LAST_HEALTH_SCORE: 'last-health-score',
  NOT_FIRST_HEALTH_SCORE_USE: 'not-first-health-score-use',
  FIRST_HEALTH_SCORE_USE_DATE: 'first-health-score-use-date',
  HEALTH_INDUSTRY_BANNER_DISMISSED_TILL: 'health-industry-banner-dismissed',
  LAST_SELECTED_HEALTH_SCORE_TYPE: 'last-selected-health-score-type',
  LAST_HEALTH_BUCKET: 'last-health-bucket'
};
export const HEALTH_USER_ATTRIBUTE_TYPES = keyMirror({
  STRING: null,
  BOOLEAN: null,
  NUMBER: null
});