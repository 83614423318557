import { useEffect, useMemo, useState } from 'react';
import debounce from 'transmute/debounce';
import { ASSET_ADDED_TO_FOLDER } from 'framework-listing-lib/constants/folderEvents';
import listingLibObserver from 'framework-listing-lib/utils/listingLibObserver';
import { FOLDERS_POLLING_INTERVAL } from 'framework-listing-lib/internal/Folders/constants/folders';
import { useIsFoldersMode } from 'framework-listing-lib/internal/hooks/useUIMode';
import useFolderMoveHistoryState from './useFolderMoveHistoryState';

/**
 * This hook toggles on/off the polling state for folders.
 * Given the known latency that exists in the CRM, we poll for new information
 * 3 times every @FOLDERS_POLLING_INTERVAL seconds.
 */
export default function useFolderPollingState() {
  const [pollingState, setPollingState] = useState('OFF');
  const [affectedFolderIds, setAffectedFolderIds] = useState([]);
  const isFoldersMode = useIsFoldersMode();
  const [__, setHistoryState] = useFolderMoveHistoryState();
  const debouncedStopPolling = useMemo(() => debounce(3 * FOLDERS_POLLING_INTERVAL, () => {
    setPollingState('OFF');
    setAffectedFolderIds([]);
    setHistoryState({});
  }), [setHistoryState]);

  /**
   * In case there's multiple "moves" at the same time,
   * wait 2 seconds before start polling.
   */
  const debouncedStartPolling = useMemo(() => debounce(2000, () => {
    setPollingState('ON');
    debouncedStopPolling();
  }), [debouncedStopPolling]);
  useEffect(() => {
    const enableFolderPolling = response => {
      if (!isFoldersMode) {
        return;
      }
      const {
        newFolderId
      } = response;
      setAffectedFolderIds(prev => [...new Set([...prev, newFolderId])]);
      debouncedStartPolling();
    };
    listingLibObserver.on(ASSET_ADDED_TO_FOLDER, enableFolderPolling);
    return () => {
      listingLibObserver.off(ASSET_ADDED_TO_FOLDER, enableFolderPolling);
    };
  }, [debouncedStartPolling, isFoldersMode]);
  return {
    affectedFolderIds,
    pollingState
  };
}