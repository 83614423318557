'use es6';

import { Record } from 'immutable';
import { ALL_SUMMARY_TYPES, BASE_SUMMARY_TYPES, SENTIMENTAL_LEVELS, SENTIMENTAL_TYPES, SUMMARY_TYPES_TO_HEALTH_METRICS_MAP, SUMMARY_TYPES_TO_TABS_MAP } from 'email-management-lib/refactor/constants/details/tldrConstants';
const defaults = {
  type: '',
  typeItems: {},
  context: {},
  list: {},
  campaignId: null,
  campaignSendId: null,
  thresholds: {},
  id: null
};
function camelize(str) {
  const cleanedStr = str.replace('_', ' ').toLowerCase();
  return cleanedStr.replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) => {
    return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
  }).replace(/\s+/g, '');
}
function calculateSentimentalValues(json, typeItems, key) {
  const context = {};
  const rateKey = camelize(`${typeItems.BASE} ${key}`);
  context.rate = json.context[rateKey] * 100;

  // Get the portal average rate from context
  if (typeItems.LEVEL === SENTIMENTAL_LEVELS.PORTAL) {
    const portalRateKey = camelize(`Portal ${typeItems.BASE} ${key}`);
    context.portalRate = json.context[portalRateKey] * 100;
  } else if (typeItems.LEVEL === SENTIMENTAL_LEVELS.LIST) {
    context.listId = json.context.listId;
  }
  return context;
}
function getSentimentalContext(typeItems, json) {
  // Converts base constant name to camelized key version to access
  // named attributes in json context.
  if (typeItems.TYPE === SENTIMENTAL_TYPES.BAD) {
    return calculateSentimentalValues(json, typeItems, 'Rate');
  } else if (typeItems.TYPE === SENTIMENTAL_TYPES.GOOD) {
    return calculateSentimentalValues(json, typeItems, 'Ratio');
  } else if (typeItems.BASE === BASE_SUMMARY_TYPES.LOCAL_TIME) {
    if (json.context.localTimeClickThroughRateImprovement) {
      return {
        localTimeClickThroughRateImprovement: json.context.localTimeClickThroughRateImprovement * 100,
        localTimePercentage: json.context['percent-out-of-timezone'] * 100
      };
    }
    return {
      localTimePercentage: json.context['percent-out-of-timezone'] * 100
    };
  }
  return {};
}
const parseThresholds = (healthThresholds, typeItems) => healthThresholds[SUMMARY_TYPES_TO_HEALTH_METRICS_MAP[typeItems.BASE]];
class TldrSendSummary extends Record(defaults) {
  static from(json, healthThresholds = {}) {
    const typeItems = ALL_SUMMARY_TYPES[json.type];
    return new TldrSendSummary(Object.assign({}, json, {
      typeItems,
      context: getSentimentalContext(typeItems, json),
      thresholds: parseThresholds(healthThresholds, typeItems)
    }));
  }
  hasLevels() {
    return !!this.typeItems.LEVEL;
  }
  getRecipientTabForType() {
    if (!this.hasLevels()) {
      return '';
    }
    return SUMMARY_TYPES_TO_TABS_MAP[this.typeItems.BASE];
  }
}
export default TldrSendSummary;