import { useHistory } from 'react-router-dom';
import { getItem } from 'framework-listing-lib/utils/localStorage';

/**
 * From https://git.hubteam.com/HubSpot/onboarding-tours/blob/23308a3ba5fbc2378d2dc9ea7925131f9fd2f129/static/js/util/activeTour.ts
 * Checks if there's an onboarding tour in progress.
 */
function isTourCurrentlyActive() {
  const hasActiveTourBodyClass = document.querySelector('body.onboarding-tour--active');
  return !!hasActiveTourBodyClass;
}
export function useHasActiveOnboardingTour() {
  const {
    location
  } = useHistory();
  const hasOnboardingTourIdParam = location.search.includes('onboardingTourId');
  const hasTourIdParam = location.search.includes('tourId');
  return isTourCurrentlyActive() || hasOnboardingTourIdParam || hasTourIdParam;
}
export function useHasDisabledAllTours() {
  return getItem('selenium.disable.onboarding_tours') === 'true';
}