import Raven from 'raven-js';

// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'Emai... Remove this comment to see the full error message
import { growlError } from 'EmailComponents/errors/growl';
const NO_STATUS_CODE = 'no-status-code';

/**
 * We get a lot of noise in Sentry for status codes 401, 403, 404, timeouts...
 * For this reason, we are going to send only Sentry errors if status code is a BE error (i.e: >= 500)
 * or 400 (client error)
 * @param {String|Number} statusCode
 */
function shouldLogSentry(statusCode) {
  const isTimeoutError = statusCode === NO_STATUS_CODE;
  const isBEError = statusCode >= 500;
  const isClientError = statusCode === 400;
  return !isTimeoutError && (isBEError || isClientError);
}
const sentryErrorMiddleware = () => next => action => {
  if (!action.metadata || !action.metadata.growlError) {
    return next(action);
  }
  const {
    error,
    feature,
    hideNotification,
    messageKey
  } = action.metadata.growlError;

  /** Create and log Sentry error */
  const statusCode = error.status || NO_STATUS_CODE;
  if (shouldLogSentry(statusCode)) {
    Raven.captureException(new Error('sentryErrorMiddleware'), {
      extra: {
        feature,
        originalError: error,
        statusCode
      },
      tags: {
        statusCode
      }
    });
  }

  /** Create Growl error message */
  if (!hideNotification) {
    const errorMessage = typeof error === 'string' ? error : error.message;
    growlError(errorMessage, feature, {
      isSentryError: true,
      messageKey
    });
  }
  return next(action);
};
export default sentryErrorMiddleware;