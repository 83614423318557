'use es6';

import { Map as ImmutableMap } from 'immutable';
import { RECEIVE_CAMPAIGN_ID, REQUEST_CAMPAIGN_ID } from 'email-management-lib/refactor/actions/actionTypes';
const initialState = ImmutableMap({});
export default function campaignIds(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_CAMPAIGN_ID:
      return state.merge(ImmutableMap(action.payload));
    case REQUEST_CAMPAIGN_ID:
    default:
      return state;
  }
}