import always from 'transmute/always';
import indexBy from 'transmute/indexBy';
import protocol from 'transmute/protocol';
import toString from 'transmute/toString';
const noId = always(undefined);

/**
 * Returns the id of `subject`.
 */
export const getId = protocol({
  args: [protocol.TYPE],
  name: 'Identifiable#getId'
});
getId.implement(null, noId);
getId.implement(undefined, noId);

/**
 * Returns the string id of `subject`.
 */
export function getIdString(subject) {
  const id = getId(subject);
  return id == null ? id : toString(id);
}

/**
 * Returns an OrderedMap of subjects by id.
 */
export const indexById = indexBy(getId);

/**
 * Returns an OrderedMap of subjects by string id.
 */
export const indexByIdString = indexBy(getIdString);