import { InMemoryCache } from '@apollo/client';
import cacheKeyFields from './cacheKeyFields';
import cacheMergePolicies from './cacheMergePolicies';
import cacheQueryPolicies from './cacheQueryPolicies';
import possibleTypes from './possibleTypes.json';
function getTypePolicies() {
  return [...Object.keys(cacheMergePolicies), ...Object.keys(cacheKeyFields), ...Object.keys(cacheQueryPolicies)].reduce((typePolicies, typeName) => {
    return Object.assign({}, typePolicies, {
      [typeName]: Object.assign({}, cacheMergePolicies[typeName], cacheKeyFields[typeName], cacheQueryPolicies[typeName])
    });
  }, {});
}
export const createCache = () => new InMemoryCache({
  /*
   * From the docs:
   * If true, result objects read from the cache will be canonized, which means deeply-equal objects will also be ===
   * (literally the same object), allowing much more efficient comparison of past/present results.
   *
   * See also: https://www.apollographql.com/docs/react/api/cache/InMemoryCache/#canonizeresults
   *
   * This is incredibly important when using these query results as props to memoized components or deps in react
   * hooks, and can cause infinite loops or other strange bugs if not set. crm-index-ui depends heavily on this setting.
   */
  canonizeResults: true,
  possibleTypes,
  typePolicies: getTypePolicies()
});