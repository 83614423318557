import { useMemo } from 'react';
import useCrmSearchQuery from 'framework-listing-lib/hooks/useCrmSearchQuery';
import useFetcher from './useFetcher';
export default function useCrmObjectTypeFetchParams(args = {
  includeHiddenFilter: true
}) {
  const crmSearchQuery = useCrmSearchQuery(args);
  const fetcher = useFetcher();
  const value = useMemo(() => {
    const variables = {
      fetcher,
      fetchParams: crmSearchQuery
    };
    return variables;
  }, [crmSearchQuery, fetcher]);
  return value;
}