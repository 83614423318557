import { INITIAL_FILTER_STATE } from 'framework-listing-lib/constants/filters';
import { DEFAULT_GALLERY_PAGE_SIZE, DEFAULT_PAGE_SIZE, MAXIMUM_CRM_SEARCH_PAGE_SIZE } from 'framework-listing-lib/constants/pagination';
import { UI_MODE } from 'framework-listing-lib/constants/uiMode';
export function getInitialPagination({
  initialFilter,
  uiMode
}) {
  const defaultInitialCount = (() => {
    switch (uiMode) {
      case UI_MODE.GALLERY:
        return DEFAULT_GALLERY_PAGE_SIZE;
      case UI_MODE.CALENDAR:
        return MAXIMUM_CRM_SEARCH_PAGE_SIZE;
      case UI_MODE.LIST:
      case UI_MODE.FOLDERS:
      default:
        return DEFAULT_PAGE_SIZE;
    }
  })();
  if (!initialFilter || !initialFilter.pagination) {
    return {
      offset: INITIAL_FILTER_STATE.pagination.offset,
      count: defaultInitialCount
    };
  }
  const initialCount = initialFilter.pagination.count;
  const initialOffset = initialFilter.pagination.offset;
  const hasValidInitialCount = initialCount && initialCount % (uiMode === UI_MODE.GALLERY ? DEFAULT_GALLERY_PAGE_SIZE : DEFAULT_PAGE_SIZE) === 0;
  const count = hasValidInitialCount ? initialCount : defaultInitialCount;
  const hasValidInitialOffset = initialOffset && initialOffset % count === 0;
  return {
    offset: hasValidInitialOffset ? initialFilter.pagination.offset : INITIAL_FILTER_STATE.pagination.offset,
    count
  };
}