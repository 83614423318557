/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */

import enviro from 'enviro';
import { includeScreen } from '../includeScreen';
import { BIZOPS_INTERACTION, CRM_API_ERROR, CRM_DEBUG, CRM_ONBOARDING, INDEX_INTERACTION, INTERNAL_CRM_TRACKING, RECORD_INTERACTION, SETTINGS_INTERACTION } from '../constants/eventNames';
import requiredData from '../constants/requiredData';
import CrmUsageTracker from '../trackers/CrmUsageTracker';
import { CRM, SETTINGS, TASKS, LISTS } from '../constants/namespaces';
const validNamespaces = [CRM, SETTINGS, LISTS, TASKS];
const CrmLogger = {
  externalData: {},
  init: data => {
    CrmLogger.externalData = data;
    CrmUsageTracker.init(data);
  },
  logImmediate: (eventName, eventProps = {}, trackerOptions = {
    namespace: CRM
  }) => CrmLogger.log(eventName, eventProps, Object.assign({}, trackerOptions, {
    sendImmediate: true
  })),
  log: (eventName, eventProps = {}, trackerOptions = {
    sendImmediate: false,
    namespace: CRM
  }) => {
    if (trackerOptions.namespace && validNamespaces.indexOf(trackerOptions.namespace) === -1) {
      if (!enviro.isProd() && !enviro.deployed()) {
        console.warn(`[CrmLogger]: ${trackerOptions.namespace} is not a valid namespace. Event will not be logged`);
      }
    } else {
      CrmLogger._log(eventName, eventProps, trackerOptions);
    }
  },
  _log(eventName = '', eventProps = {}, trackerOptions = {
    sendImmediate: false,
    namespace: CRM
  }) {
    // some dynamic call sites are hard to migrate off of where_app_override
    // right now so this hydrates the namespace to make sure events go to the
    // right place
    if (eventProps.where_app_override && trackerOptions.namespace === 'crm') {
      trackerOptions.namespace = eventProps.where_app_override;
    }
    CrmUsageTracker.track(eventName, eventProps, trackerOptions);
  },
  logEventAndProps(properties) {
    const newProps = includeScreen(properties);
    if (newProps) {
      const {
        event,
        eventProps
      } = newProps;
      CrmLogger._log(event, eventProps, properties.options);
    }
  },
  // New Logging Methods
  // Refer to: https://git.hubteam.com/HubSpot/CRM/issues/6661
  logSettingsInteraction(objectType, eventProps) {
    return CrmLogger.logEventAndProps({
      event: SETTINGS_INTERACTION,
      objectType,
      eventProps,
      options: {
        namespace: SETTINGS
      }
    });
  },
  logRecordInteraction(objectType, eventProps) {
    const eventData = {
      event: RECORD_INTERACTION,
      objectType,
      eventProps
    };
    CrmLogger.logEventAndProps(eventData);
  },
  logIndexInteraction(objectType, eventProps, options) {
    return CrmLogger.logEventAndProps({
      event: INDEX_INTERACTION,
      objectType,
      eventProps,
      options
    });
  },
  logInternal(objectType, eventProps) {
    return CrmLogger.logEventAndProps({
      event: INTERNAL_CRM_TRACKING,
      objectType,
      eventProps
    });
  },
  logCRMOnboarding(objectType, eventProps) {
    return CrmLogger.logEventAndProps({
      event: CRM_ONBOARDING,
      objectType,
      eventProps
    });
  },
  logError(objectType, eventProps) {
    return CrmLogger.logEventAndProps({
      event: CRM_API_ERROR,
      objectType,
      eventProps
    });
  },
  logDebug(objectType, eventProps) {
    return CrmLogger.logEventAndProps({
      event: CRM_DEBUG,
      objectType,
      eventProps
    });
  },
  logForBizOps(objectType, eventProps) {
    return CrmLogger.logEventAndProps({
      event: BIZOPS_INTERACTION,
      objectType,
      eventProps
    });
  },
  forNewPortals(logger, objectType, eventProps) {
    if (CrmLogger.externalData && CrmLogger.externalData[requiredData.PORTAL_AGE_DAYS]) {
      CrmLogger.externalData[requiredData.PORTAL_AGE_DAYS]().then(age => {
        const isNewPortal = age < 1;
        const isDebugging = enviro.debug('firstDayLogging');
        if (isDebugging || isNewPortal) {
          logger(objectType, eventProps);
        }
      });
    }
  }
};
export default CrmLogger;