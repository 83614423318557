import PortalIdParser from 'PortalIdParser';
import EmailType from 'EmailData/enums/EmailType';
import { CREATE_EMAIL_1 } from 'EmailData/constants/onboardingTourIds';
export const DRAG_DROP_CREATION_HREF = `/content/${PortalIdParser.get()}/create/email`;
export const CLASSIC_CREATION_HREF = `/content/${PortalIdParser.get()}/create/email?subcategory=classic_email`;
export const DRAG_DROP_ONBOARDING_CREATION_HREF = `/content/${PortalIdParser.get()}/create/email?onboardingTourId=${CREATE_EMAIL_1}`;
export const TYPE_PICKER_HREF = `/email/${PortalIdParser.get()}/type-picker`;
export const EMAIL_TYPE_PANEL_WIDTH = 450;
export const EMAIL_TYPE_MODAL_WIDTH = 950;
export const EMAIL_TEMPLATE_MODE_MODAL_WIDTH = 660;
export const EMAIL_TEMPLATE_MODE_MODAL_TYPES = {
  CREATE: 'create',
  CLONE: 'clone'
};
export const CREATION_STEPS = {
  TEMPLATE_TYPE: 'TEMPLATE_TYPE',
  EMAIL_TYPE: 'EMAIL_TYPE',
  BLOG_RSS_FEED_SELECT: 'BLOG_RSS_FEED_SELECT'
};
export const FINAL_CREATION_STEPS = [CREATION_STEPS.BLOG_RSS_FEED_SELECT];
export const EMAIL_CREATION_TYPES = {
  REGULAR: 'REGULAR',
  AUTOMATED: 'AUTOMATED',
  BLOG_RSS: 'BLOG_RSS'
};
export const EMAIL_CREATION_TYPES_TO_EMAIL_TYPE = () => {
  return {
    [EMAIL_CREATION_TYPES.REGULAR]: {
      hasAll: [EmailType.BATCH_EMAIL],
      upgradeKey: 'email-optimized-content',
      canCloneTo: undefined
    },
    [EMAIL_CREATION_TYPES.AUTOMATED]: {
      hasAll: [EmailType.AUTOMATED_EMAIL],
      upgradeKey: 'email-automated-sends',
      canCloneTo: undefined
    },
    [EMAIL_CREATION_TYPES.BLOG_RSS]: {
      hasAll: [EmailType.BLOG_EMAIL],
      canCloneTo: false,
      upgradeKey: 'email-blog-rss-sends'
    }
  };
};

// TEMPORARY ILLUSTRATIONS
export const EMAIL_TYPE_ILLUSTRATIONS = {
  [EMAIL_CREATION_TYPES.BLOG_RSS]: 'forms',
  [EMAIL_CREATION_TYPES.REGULAR]: 'successfully-connected-email',
  [EMAIL_CREATION_TYPES.AUTOMATED]: 'workflows'
};