import { parseInsight } from '.';
export const SUPPORTED_FRAMEWORK_INSIGHT_CONTEXTS = ['PORTAL_CONTEXT', 'BUSINESS_UNIT_CONTEXT', 'PRODUCT_CAMPAIGN_CONTEXT', 'SAME_EMAIL_MONTH_OVER_MONTH_CONTEXT'];
export const SUPPORTED_FRAMEWORK_INSIGHT_PROPERTIES = ['OPEN_RATE'];
export const SUPPORTED_INSIGHT_TYPES = ['GOOD_OPEN_RATE', 'BAD_OPEN_RATE'];
export const SUPPORTED_INSIGHT_REASON_TYPES = ['NO_REASON', 'SUBJECT_LINE_TOO_LONG', 'SUBJECT_LINE_TOO_MANY_EMOJI', 'SUBJECT_LINE_WITHOUT_PERSONALISATION', 'SUBJECT_LINE_WITH_EMOJI', 'SENT_TO_LOW_PERFORMING_CONTACT_LIST', 'CAMPAIGN_SEND_VOLUME_SPIKE', 'PREVIEW_TEXT_MISSING', 'EMAIL_GRAYMAIL_DISABLED', 'EMAIL_CLIPPED', 'MISSING_SENDER_PERSONALISATION'];
export const SUPPORTED_INSIGHT_RECOMMENDATION_TYPES = ['READ_KB_ARTICLE', 'REVIEW_SENDING_LIST', 'SHORTEN_SUBJECT_LINE', 'USE_FEWER_EMOJIS_IN_SUBJECT_LINE', 'USE_PREVIEW_TEXT', 'REDUCE_EMAIL_SIZE', 'USE_SENDER_PERSONALISATION', 'ENABLE_GRAYMAIL', 'USE_CONSISTENT_SENDING_PATTERNS'];
export function isSupportedFrameworkInsightContext(frameworkInsightContext) {
  return SUPPORTED_FRAMEWORK_INSIGHT_CONTEXTS.includes(frameworkInsightContext);
}
export function isSupportedFrameworkInsight(frameworkInsight) {
  const {
    context,
    property
  } = parseInsight(frameworkInsight);
  return isSupportedFrameworkInsightContext(context) && SUPPORTED_FRAMEWORK_INSIGHT_PROPERTIES.includes(property);
}
export function isSupportedSpotlight(spotlight) {
  return SUPPORTED_INSIGHT_TYPES.includes(spotlight.insightType);
}
export function isSupportedInsight(insight) {
  return SUPPORTED_INSIGHT_TYPES.includes(insight.insightType);
}
export function isSupportedReason(reason) {
  return SUPPORTED_INSIGHT_REASON_TYPES.includes(reason.insightReasonType);
}
export function isSupportedRecommendation(recommendation) {
  return SUPPORTED_INSIGHT_RECOMMENDATION_TYPES.includes(recommendation.insightRecommendationType);
}