import { useCallback, useMemo } from 'react';
import { gate } from 'hub-http/gates';
import useFetchUserInfo from 'framework-listing-lib/internal/hooks/useFetchUserInfo';
export default function useUserInfo() {
  var _data$user2;
  const {
    data,
    loading
  } = useFetchUserInfo();

  /**
   * The migration to gates v3 requires us to use the gate() wrapper
   * https://product.hubteam.com/docs/gating/next-gen/usage.html#frontend-usage
   *
   * Note that they explicitly discourage passing variables to `gate`:
   *
   * > "Creating abstractions around the gate function prevents static analysis. The value passed to the gate function must always be a string literal, not a string variable. The full gate name must always appear as a single string and can't be created via string concatenation"
   *
   * We have to do it here though, because our gates can come from FAS.
   */
  const isUngatedFor = useCallback(gateKey => Boolean(
  // Approved expection to the lint rule: https://hubspot.slack.com/archives/C05VAS4J96D/p1718649012790009?thread_ts=1718644421.364749&cid=C05VAS4J96D
  // eslint-disable-next-line hubspot-dev/no-bare-gate-strings
  (data === null || data === void 0 ? void 0 : data.gates.includes(gateKey)) || (data === null || data === void 0 ? void 0 : data.gates.includes(gate(gateKey)))), [data === null || data === void 0 ? void 0 : data.gates]);
  const hasScope = useCallback(scope => {
    var _data$user;
    return Boolean(data === null || data === void 0 || (_data$user = data.user) === null || _data$user === void 0 ? void 0 : _data$user.scopes.includes(scope));
  }, [data === null || data === void 0 || (_data$user2 = data.user) === null || _data$user2 === void 0 ? void 0 : _data$user2.scopes]);
  return useMemo(() => ({
    userInfo: data,
    loading,
    hasScope,
    isUngatedFor
  }), [data, hasScope, isUngatedFor, loading]);
}