import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import isEqual from 'hs-lodash/isEqual';
import omit from 'transmute/omit';
import { INITIAL_FILTER_STATE } from 'framework-listing-lib/constants/filters';
import { UI_MODE } from 'framework-listing-lib/constants/uiMode';
import useCurrentView from 'framework-listing-lib/hooks/useCurrentView';
import { updateHiddenFilterForCalendarDisplayDate } from 'framework-listing-lib/internal/Calendar/utils/filters';
import { ASSETS_WITHOUT_FOLDERS_FILTER, FOLDER_ID } from 'framework-listing-lib/internal/Folders/constants/folders';
import { useHasViews } from 'framework-listing-lib/internal/ViewTabs/hooks/useViewProps';
import { getViewId } from 'framework-listing-lib/internal/ViewTabs/utils/views';
import { updateFilterGroupsForBUNav, updateHiddenFilterForBUNav, updateQuickFiltersForBUNav } from 'framework-listing-lib/internal/businessUnits/utils';
import useHandleQueryParams from 'framework-listing-lib/internal/deepLink/hooks/useHandleQueryParams';
import { useHasBUNavIntegration } from 'framework-listing-lib/internal/frameworkAppSettings/hooks/useFrameworkAppSettings';
import useDeepEqual from 'framework-listing-lib/internal/hooks/useDeepEqual';
import useDeepEqualEffect from 'framework-listing-lib/internal/hooks/useDeepEqualEffect';
import useObjectTypeId from 'framework-listing-lib/internal/hooks/useObjectTypeId';
import { getInitialPagination } from 'framework-listing-lib/internal/utils/pagination';
import { combineFilterStates, combineFilters, makeFiltersState, updateFilterState } from 'framework-listing-lib/utils/filters';
import useSyncFiltersWithSessionStorage from './useSyncFiltersWithSessionStorage';
import { useCurrentUIMode } from './useUIMode';

/**
 * This hook is meant to be used only by CrmObjectTypeListingClientProvider.
 * It initialises the global filter state used by all hooks & components in this codebase.
 *
 * We update filters from this hook in two scenarios:
 *  - when @initialFilter or @objectType prop changes.
 *  - when the current view changes.
 *
 * This hooks has & controls:
 *  - list of deleted object ids (due to the delay with the CRM, we manually omit deleted objects when sending request to crm-search).
 *  - filters that have been applied to the UI
 */
export default function useCrmObjectTypeListingFilterState({
  calendarProps,
  hiddenFilter: hiddenFilterProp,
  initialFilter: initialFilterProp
}) {
  const urlParams = useHandleQueryParams();
  const currentUIMode = useCurrentUIMode();
  const hasViews = useHasViews();
  const currentView = useCurrentView();
  const initialFilter = useDeepEqual(initialFilterProp);
  const hasBUNavIntegration = useHasBUNavIntegration();
  const objectType = useObjectTypeId();

  /**
   * Prevent useEffect hooks to run on first load
   */
  const initialFilterRef = useRef(initialFilter);
  const currentViewIdRef = useRef(undefined);
  const previousUIMode = useRef(currentUIMode);
  const [deletedObjects, onSetDeletedObjects] = useState([]);
  const [filters, onSetFiltersState] = useState(() => {
    if (!currentView || !hasViews) {
      return Object.assign({}, INITIAL_FILTER_STATE, initialFilter || {}, {
        pagination: getInitialPagination({
          initialFilter,
          uiMode: currentUIMode
        }),
        query: initialFilter && initialFilter.query ? initialFilter.query : ''
      });
    }
    return makeFiltersState({
      initialFilter,
      currentView,
      uiMode: currentUIMode
    });
  });
  const {
    filterStateFromSessionStorage,
    turnOnSessionStorage
  } = useSyncFiltersWithSessionStorage(filters);

  /**
   * onSetFilters sets filters in the entire lib's lifecycle (both internally and externally by lib consumers).
   * This method is re-wrapping onSetFiltersState so we know that, once onSetFilters is called, a new filter has been applied.
   * It also means that from here, we can enable the session filter sync so we "cache" WIP filters in the UI.
   */
  const onSetFilters = useCallback((...args) => {
    turnOnSessionStorage();
    onSetFiltersState(...args);
  }, [turnOnSessionStorage]);

  /**
   * Reset filters to its initial state when @initialFilter or @objectType has changed
   */
  useEffect(() => {
    const hasInitialFilter = Boolean(initialFilterRef.current && initialFilter);
    const filtersAreEqualExceptQuery = isEqual(omit(['query'], initialFilterRef.current || {}), omit(['query'], initialFilter || {}));
    const queryIsDifferent = Boolean(initialFilterRef.current && initialFilter && initialFilterRef.current.query !== initialFilter.query);
    const hasOnlyQueryChanged = hasInitialFilter && filtersAreEqualExceptQuery && queryIsDifferent;

    /**
     * If only the query changed, we keep the previous filter (with or without view) + add the new query value.
     */
    if (hasOnlyQueryChanged) {
      onSetFiltersState(prevFilter => Object.assign({}, prevFilter, {
        query: initialFilter && initialFilter.query ? initialFilter.query : ''
      }));
    } else {
      onSetFiltersState(prevFilter => {
        let newFilter;
        /**
         * We only want to use the previous filter if the initial filter changes while not in folders mode or
         * the UI mode changes from one NON folder UI mode to another NON folder UI mode. This is because Folder filters are automatically
         * applied when switching to folders mode and we don't want them to carry over when leaving folders mode. Same when entering folders mode,
         * we don't want any previous filters to be carried over.
         */
        if (previousUIMode.current !== UI_MODE.FOLDERS && currentUIMode !== UI_MODE.FOLDERS) {
          newFilter = combineFilterStates({
            initialFilter,
            newFilter: prevFilter,
            uiMode: currentUIMode
          });
        } else {
          newFilter = Object.assign({}, INITIAL_FILTER_STATE, initialFilter || {}, {
            pagination: getInitialPagination({
              initialFilter,
              uiMode: currentUIMode
            }),
            query: initialFilter && initialFilter.query ? initialFilter.query : ''
          });
        }
        previousUIMode.current = currentUIMode;
        const hasFolderFilter = newFilter.filterGroups.some(filterGroup => filterGroup.filters.some(filter => filter.property === FOLDER_ID));
        if (currentUIMode === UI_MODE.FOLDERS && !hasFolderFilter) {
          // Apply folder mode's initial filter
          newFilter = updateFilterState(newFilter, ASSETS_WITHOUT_FOLDERS_FILTER);
        }
        previousUIMode.current = currentUIMode;
        return isEqual(prevFilter, newFilter) ? prevFilter : newFilter;
      });
    }
    initialFilterRef.current = initialFilter;
  }, [currentUIMode, initialFilter, objectType]);

  /**
   * Update filters if current view has changed or loaded
   */
  useDeepEqualEffect(() => {
    //set currentViewId ref to undefined when in folders mode
    if (currentUIMode === UI_MODE.FOLDERS && previousUIMode.current !== UI_MODE.FOLDERS && currentViewIdRef.current) {
      currentViewIdRef.current = undefined;
    }
    if (!currentView) {
      return;
    }
    const viewId = getViewId(currentView);

    /**
     * This effect should run only when the current view changes to prevent listing-lib
     * from wiping the entire filters when (for example) only the query changes.
     */
    if (currentViewIdRef.current === viewId) {
      return;
    }
    currentViewIdRef.current = viewId;
    onSetFiltersState(prevFilter => {
      const newFilter = filterStateFromSessionStorage ? makeFiltersState({
        initialFilter,
        currentView: Object.assign({}, currentView, {
          filterGroups: filterStateFromSessionStorage.filterGroups || currentView.filterGroups,
          quickFilters: filterStateFromSessionStorage.quickFilters || currentView.quickFilters
        }),
        uiMode: currentUIMode
      }) : makeFiltersState({
        initialFilter,
        currentView,
        uiMode: currentUIMode
      });
      return isEqual(prevFilter, newFilter) ? prevFilter : newFilter;
    });
  }, [currentUIMode, currentView, filterStateFromSessionStorage, initialFilter]);

  /**
   * Update filters from query string
   */
  const [hasUsedQueryParams, setHasUsedQueryParams] = useState(false);
  useEffect(() => {
    const isLoadingOrInvalidView = hasViews && !currentView;
    if (isLoadingOrInvalidView || !urlParams || hasUsedQueryParams) {
      return;
    }
    onSetFiltersState(prevFilter => {
      const newFilter = currentView ? makeFiltersState({
        initialFilter,
        currentView: Object.assign({}, currentView, {
          filterGroups: combineFilters(currentView.filterGroups, urlParams.filterGroups),
          filters: [...currentView.filters, ...urlParams.filters],
          quickFilters: [...currentView.quickFilters, ...urlParams.quickFilters]
        }),
        uiMode: currentUIMode
      }) : Object.assign({}, prevFilter, {
        filterGroups: combineFilters(prevFilter.filterGroups, urlParams.filterGroups),
        quickFilters: [...prevFilter.quickFilters, ...urlParams.quickFilters]
      });
      return isEqual(prevFilter, newFilter) ? prevFilter : newFilter;
    });
    setHasUsedQueryParams(true);
  }, [currentUIMode, currentView, hasUsedQueryParams, hasViews, initialFilter, urlParams]);
  const hasPropertyFilter = useCallback(propertyName => filters.quickFilters.some(filterOperator => filterOperator.property === propertyName) || filters.filterGroups.some(filterGroup => filterGroup.filters.some(filterOperator => filterOperator.property === propertyName)), [filters.filterGroups, filters.quickFilters]);
  const filterGroups = useMemo(() => updateFilterGroupsForBUNav({
    filterGroups: filters.filterGroups,
    hasBUNavIntegration
  }), [filters.filterGroups, hasBUNavIntegration]);
  const quickFilters = useMemo(() => updateQuickFiltersForBUNav({
    quickFilters: filters.quickFilters,
    hasBUNavIntegration
  }), [filters.quickFilters, hasBUNavIntegration]);
  const hiddenFilter = useMemo(() => {
    const withHiddenBUFilter = updateHiddenFilterForBUNav({
      hiddenFilter: hiddenFilterProp,
      hasBUNavIntegration
    });
    if (!calendarProps || currentUIMode !== UI_MODE.CALENDAR) {
      return withHiddenBUFilter;
    }
    return updateHiddenFilterForCalendarDisplayDate({
      displayTimestamp: calendarProps.displayTimestamp,
      endPropertyName: calendarProps.endPropertyName,
      hiddenFilter: withHiddenBUFilter,
      startPropertyName: calendarProps.startPropertyName,
      viewType: calendarProps.use
    });
  }, [calendarProps, currentUIMode, hasBUNavIntegration, hiddenFilterProp]);
  const value = useMemo(() => ({
    deletedObjects,
    filters: Object.assign({}, filters, {
      filterGroups,
      quickFilters
    }),
    hasPropertyFilter,
    hiddenFilter,
    onSetDeletedObjects,
    onSetFilters
  }), [deletedObjects, filterGroups, filters, hasPropertyFilter, hiddenFilter, onSetFilters, quickFilters]);
  return value;
}