import { ApolloLink } from '@apollo/client';
import { createHubHttpLink, createBatchedHubHttpLink } from 'apollo-link-hub-http';
import earlyRequestLink from './earlyRequestLink';
import errorMonitoringLink from './errorMonitoringLink';
const getHttpLink = function getHttpLink({
  hubHttpLinkOptions,
  useBatched = true,
  httpStack
}) {
  if (useBatched) {
    return createBatchedHubHttpLink(httpStack)(hubHttpLinkOptions);
  }
  return createHubHttpLink(httpStack)(hubHttpLinkOptions);
};
export const getApiLink = function getApiLink({
  hubHttpLinkOptions,
  useBatched,
  httpStack
}) {
  const hubHttpLink = getHttpLink({
    hubHttpLinkOptions,
    useBatched,
    httpStack
  });
  return ApolloLink.from([earlyRequestLink, errorMonitoringLink, hubHttpLink]);
};