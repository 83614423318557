import { useQuery } from 'data-fetching-client';
import { useMemo } from 'react';
import { FETCH_TRANSLATION_INFO } from 'email-management-lib/refactor/queries/translationInfo';
function useTranslationInfo({
  emailId
}) {
  const {
    data,
    loading: isFetchingTranslationInfo,
    error
  } = useQuery(FETCH_TRANSLATION_INFO, {
    variables: {
      emailId
    }
  });
  return useMemo(() => {
    var _data$translationInfo, _data$translationInfo2;
    return {
      isConvertible: (_data$translationInfo = data === null || data === void 0 || (_data$translationInfo2 = data.translationInfo.isConvertibleToDragAndDrop) === null || _data$translationInfo2 === void 0 ? void 0 : _data$translationInfo2.isConvertible) !== null && _data$translationInfo !== void 0 ? _data$translationInfo : null,
      isFetchingTranslationInfo,
      error
    };
  }, [data === null || data === void 0 ? void 0 : data.translationInfo, error, isFetchingTranslationInfo]);
}
export default useTranslationInfo;