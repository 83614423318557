import { useEffect, useState } from 'react';
import { fetchUserInfo } from '../api/userInfo';
export default function useFetchUserInfo() {
  const [state, setState] = useState({
    error: false,
    loading: true,
    data: undefined
  });
  useEffect(() => {
    fetchUserInfo().then(userInfo => setState({
      error: false,
      loading: false,
      data: userInfo
    })).catch(() => setState({
      error: true,
      loading: false,
      data: undefined
    }));
  }, []);
  return state;
}