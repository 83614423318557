import http from 'hub-http/clients/apiClient';
import { useQuery, registerQuery } from 'data-fetching-client';
export const IS_VIEW_NAME_USED_ENDPOINT = ({
  objectTypeId,
  viewName
}) => `/sales/v4/views/is-name-used/${encodeURIComponent(objectTypeId)}/${encodeURIComponent(viewName)}`;
const IS_VIEW_NAME_USED_QUERY_NAME = 'isNameUsed';
const isViewNameUsedQueryArgsArgument = [
//this type wont require all properties, but it will at least verify the properties exist on ViewsCountsQueryType
'objectTypeId', 'viewName'];
export const IsViewNameUsedQuery = registerQuery({
  fieldName: IS_VIEW_NAME_USED_QUERY_NAME,
  args: isViewNameUsedQueryArgsArgument,
  fetcher: args => http.get(IS_VIEW_NAME_USED_ENDPOINT(args))
});
export const useFetchIsViewNameUsed = ({
  objectTypeId,
  viewName
}) => {
  return useQuery(IsViewNameUsedQuery, {
    variables: {
      objectTypeId,
      viewName
    },
    skip: viewName === ''
  });
};