// This file initally contained all the below functions. These were moved to EmailData
// so they could be used in the ContentEmailUI code split.
// Imports to this file can be updated to use EmailData/utils/tracking instead

import { init as emailDataInit, getUsageTracker as emailDataGetUsageTracker, trackPageLoad as emailDataTrackPageLoad, trackScreenChange as emailDataTrackScreenChange, trackDashboardScreenChange as emailDataTrackDashboardScreenChange, trackPostSendScreenChange as emailDataTrackPostSendScreenChange, trackPostSendInteraction as emailDataTrackPostSendInteraction, trackRemediationInteraction as emailDataTrackRemediationInteraction, trackTldrInteraction as emailDataTrackTldrInteraction, trackAnalyzeInteraction as emailDataTrackAnalyzeInteraction, trackDashboardInteraction as emailDataTrackDashboardInteraction, trackFrameworkTableInteraction as emailDataTrackFrameworkTableInteraction, trackAssetAccessInteraction as emailDataTrackAssetAccessInteraction, trackCreateEmailInteraction as emailDataTrackCreateEmailInteraction, trackDashboardUpgradeInteraction as emailDataTrackDashboardUpgradeInteraction, trackPostSendUpgradeInteraction as emailDataTrackPostSendUpgradeInteraction, trackIntegrationInteraction as emailDataTrackIntegrationInteraction, trackOnboardingTourInteraction as emailDataTrackOnboardingTourInteraction, trackEmptyStateInteraction as emailDataTrackEmptyStateInteraction, trackContextualAutomationInteraction as emailDataTrackContextualAutomationInteraction, trackTestSendInteraction as emailDataTrackTestSendInteraction, trackBeforeUnload as emailDataTrackBeforeUnload } from 'EmailData/utils/tracking';
export const init = emailDataInit;
export const getUsageTracker = emailDataGetUsageTracker;
export const trackPageLoad = emailDataTrackPageLoad;
export const trackScreenChange = emailDataTrackScreenChange;
export const trackDashboardScreenChange = emailDataTrackDashboardScreenChange;
export const trackPostSendScreenChange = emailDataTrackPostSendScreenChange;
export const trackPostSendInteraction = emailDataTrackPostSendInteraction;
export const trackRemediationInteraction = emailDataTrackRemediationInteraction;
export const trackTldrInteraction = emailDataTrackTldrInteraction;
export const trackAnalyzeInteraction = emailDataTrackAnalyzeInteraction;
export const trackDashboardInteraction = emailDataTrackDashboardInteraction;
export const trackFrameworkTableInteraction = emailDataTrackFrameworkTableInteraction;
export const trackAssetAccessInteraction = emailDataTrackAssetAccessInteraction;
export const trackCreateEmailInteraction = emailDataTrackCreateEmailInteraction;
export const trackDashboardUpgradeInteraction = emailDataTrackDashboardUpgradeInteraction;
export const trackPostSendUpgradeInteraction = emailDataTrackPostSendUpgradeInteraction;
export const trackIntegrationInteraction = emailDataTrackIntegrationInteraction;
export const trackOnboardingTourInteraction = emailDataTrackOnboardingTourInteraction;
export const trackEmptyStateInteraction = emailDataTrackEmptyStateInteraction;
export const trackContextualAutomationInteraction = emailDataTrackContextualAutomationInteraction;
export const trackTestSendInteraction = emailDataTrackTestSendInteraction;
export const trackBeforeUnload = emailDataTrackBeforeUnload;