import { useEffect, useMemo } from 'react';
import { usePinnedViewLimit } from '../../viewLimits/hooks/usePinnedViewLimit';
import { useFetchPinnedViews } from './useFetchPinnedViews';
import { useSetPinnedViews } from './useSetPinnedViews';
import Raven from 'raven-js';
/**
 * This hook is meant to solve "a user clicks a link to a view that is not already pinned"
 *
 * When run, this hook will detect if the currentViewId is not in the list of pinned views,
 * and if so, it will attempt to pin it via an api call.
 * This hook will not re-call the api for the same viewId if it failed on the last call to prevent an infinite loop
 **/
export const useAutoPinCurrentView = ({
  currentViewId,
  namespace,
  objectTypeId
}) => {
  const {
    data: pinnedViewsData,
    loading: pinnedViewsLoading,
    error: pinnedViewsError
  } = useFetchPinnedViews({
    objectTypeId,
    namespace
  });
  const {
    data: pinnedViewLimit,
    loading: pinnedViewLimitLoading,
    error: pinnedViewLimitError
  } = usePinnedViewLimit();
  const [setPinnedViews, {
    data: setPinnedViewsData,
    loading: setPinnedViewsLoading,
    error: setPinnedViewsError
  }] = useSetPinnedViews({
    objectTypeId,
    namespace
  });
  useEffect(() => {
    if (currentViewId === null) {
      return;
    }
    if (pinnedViewLimitLoading || pinnedViewsLoading || setPinnedViewsLoading) {
      return;
    }
    if (pinnedViewsError || pinnedViewLimitError || setPinnedViewsError) {
      return;
    }
    if (pinnedViewsData === undefined || pinnedViewLimit === undefined) {
      return;
    }
    if (pinnedViewsData.pinnedViews.includes(currentViewId)) {
      return;
    }
    const newViewIds = [...pinnedViewsData.pinnedViews, currentViewId];
    if (newViewIds.length > pinnedViewLimit) {
      return;
    }
    setPinnedViews(newViewIds).catch(e => {
      Raven.captureException(e);
    });
    return;
  }, [pinnedViewLimitLoading, pinnedViewsLoading, setPinnedViewsLoading, setPinnedViewsError, currentViewId, pinnedViewLimitError, pinnedViewsData, pinnedViewsError, pinnedViewLimit, setPinnedViews]);
  return useMemo(() => ({
    data: setPinnedViewsData,
    error: setPinnedViewsError,
    loading: setPinnedViewsLoading
  }), [setPinnedViewsData, setPinnedViewsError, setPinnedViewsLoading]);
};