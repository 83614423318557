export function setItem(key, value) {
  try {
    return localStorage.setItem(key, value);
  } catch (_unused) {
    return null;
  }
}
export function getItem(key) {
  try {
    return localStorage.getItem(key);
  } catch (_unused2) {
    return null;
  }
}