export default (({
  targetBounds,
  contentDimensions,
  placement
}) => {
  // Depending on the placement value, assigns to x the target element value for left or right
  const x = placement.includes('left') ? targetBounds.left : targetBounds.right;
  // Depending on the placement value, assigns to y the target element value for top or bottom
  const y = placement.includes('top') ? targetBounds.top : targetBounds.bottom;
  return {
    // We translate it to the left on the x-axis by half of its width so its center is placed properly according the chosen `placement` prop
    left: x - contentDimensions.width / 2,
    // We translate it to the top on the y-axis by half of its height so its center is placed properly according the chosen `placement` prop
    top: y - contentDimensions.height / 2
  };
});