import { useContext } from 'react';
import { useObjectTypeId } from '../v2/hooks/useObjectTypeId';
import { HighlySensitivePropertyContext } from './HighlySensitivePropertyContext';
import { useObjectId } from '../v2/hooks/useObjectId';
import { useGetIsUngated } from '../v2/hooks/useGetIsUngated';
import { PropertyDataSensitivity } from 'customer-data-objects/property/PropertyDataSensitivity';
import { usePropertyDefinition } from '../v2/hooks/usePropertyDefinition';
import { usePropertyValueTimestamp } from '../v2/hooks/usePropertyValueTimestamp';
export const useIsHidingHighlySensitiveValue = () => {
  const property = usePropertyDefinition();
  const objectTypeId = useObjectTypeId();
  const objectId = useObjectId();
  const timestamp = usePropertyValueTimestamp();
  const getIsUngated = useGetIsUngated();
  const context = useContext(HighlySensitivePropertyContext);
  if (!getIsUngated('CRM:PropertiesFrontend:HighlySensitiveData')) {
    return false;
  }
  if (property.dataSensitivity !== PropertyDataSensitivity.HIGH) {
    return false;
  }
  if (!context) {
    // TODO: we should self-wrap PropertyInput and FormattedProperty so this circumstance doesn't happen
    // https://git.hubteam.com/HubSpot/PropertiesFrontendTeam/issues/1895
    return false;
  }
  if (!objectId) {
    // TODO: we need to throw an error in this case because you can't render a highly-sensitive PropertyInput or FormattedProperty without an objectId
    // https://git.hubteam.com/HubSpot/PropertiesFrontendTeam/issues/1897
    // This depends on inhousing an ErrorBoundary component: https://git.hubteam.com/HubSpot/PropertiesFrontendTeam/issues/1889
    // And accounting for property input preview cases like in the property edit modal
    return false;
  }
  const propertyValue = context.getHighlySensitivePropertyValue({
    objectTypeId,
    objectId,
    propertyName: property.name,
    timestamp
  });
  const isHidingHighlySensitiveValue = !propertyValue || propertyValue.status !== 'SUCCEEDED';
  return isHidingHighlySensitiveValue;
};