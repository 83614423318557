'use es6';

import { Record } from 'immutable';
import { TIME_SPENT_VIEWING } from 'EmailData/utils/details/detailTypeUtil';
const defaults = {
  [TIME_SPENT_VIEWING.GLANCED]: 0,
  [TIME_SPENT_VIEWING.SKIMMED]: 0,
  [TIME_SPENT_VIEWING.READ]: 0
};
class OpenDuration extends Record(defaults) {
  static from(json) {
    return new OpenDuration({
      [TIME_SPENT_VIEWING.GLANCED]: json.glanced || json[TIME_SPENT_VIEWING.GLANCED] || 0,
      [TIME_SPENT_VIEWING.SKIMMED]: json.skimmed || json[TIME_SPENT_VIEWING.SKIMMED] || 0,
      [TIME_SPENT_VIEWING.READ]: json.read || json[TIME_SPENT_VIEWING.READ] || 0
    });
  }
  isZeroState() {
    return this.getTotal() <= 0;
  }
  getPercentage(key) {
    if (this.isZeroState()) {
      return 0;
    }
    return this[key] * 100 / this.getTotal();
  }
  getTotal() {
    return this[TIME_SPENT_VIEWING.READ] + this[TIME_SPENT_VIEWING.SKIMMED] + this[TIME_SPENT_VIEWING.GLANCED];
  }
}
export default OpenDuration;