'use es6';

import { createSelector } from 'reselect';
export const getIsSurveyVisible = createSelector([state => state, (__, {
  surveyId,
  visibleSurveys,
  visibleSurveysEvalMap
}) => ({
  surveyId,
  visibleSurveys,
  visibleSurveysEvalMap
})], (state, {
  surveyId,
  visibleSurveys,
  visibleSurveysEvalMap
}) => visibleSurveys.includes(surveyId) && (!visibleSurveysEvalMap[surveyId] || visibleSurveysEvalMap[surveyId](state)));