'use es6';

import { List, Map as ImmutableMap, Record } from 'immutable';
import Email from 'EmailData/models/Email';
import EmailCampaignSend from 'EmailData/models/details/children/EmailCampaignSend';
import Template from 'EmailData/models/Template';
export default (defaultValues => {
  const detailDefaults = {
    appId: null,
    crmObjectId: null,
    primaryEmailId: null,
    primaryEmail: null,
    primaryTemplateInfo: Template.from({}),
    campaignErrorResults: ImmutableMap({}),
    allAppIds: List([]),
    allCampaignIds: List([]),
    appCampaignIdPairs: List([]),
    emailCampaignSends: List([]),
    trackingConfiguration: {
      htmlClickTrackingEnabled: true,
      openTrackingEnabled: true,
      plainTextClickTrackingEnabled: true
    }
  };
  return class extends Record(Object.assign({}, detailDefaults, defaultValues)) {
    static from(json) {
      const response = Object.assign({}, json);
      if (typeof response.allCampaignIds !== 'undefined') {
        response.allCampaignIds = List(response.allCampaignIds);
      }
      if (typeof response.primaryEmail !== 'undefined') {
        response.primaryEmail = Email.from(Object.assign({}, response.primaryEmail));
      } else {
        response.primaryEmail = Email.from({});
      }
      if (typeof response.emailCampaignSends !== 'undefined') {
        response.emailCampaignSends = List(response.emailCampaignSends.map(send => EmailCampaignSend.from(send)));
      }
      if (typeof response.campaignErrorResults !== 'undefined') {
        response.campaignErrorResults = ImmutableMap(response.campaignErrorResults);
      }
      if (typeof response.primaryTemplateInfo !== 'undefined') {
        response.primaryTemplateInfo = Template.from(Object.assign({}, response.primaryTemplateInfo));
      }
      return response;
    }
    getEmail() {
      return this.primaryEmail;
    }
    getTemplateInfo() {
      return this.primaryTemplateInfo;
    }
    getAllCampaignSends() {
      return this.emailCampaignSends;
    }
  };
});