const PORTAL_AGE_DAYS = 'portal-age-days';
const PORTAL_AGE_MONTHS = 'portal-age-months';
const USER_AGE_DAYS = 'user-age-days';
const USER_AGE_MONTHS = 'user-age-months';
const USAGE_PORTAL_FIRST_WEEK = 'usage-portal-first-week';
const CHROME_EXTENSION_INSTALLED = 'chrome-extension-installed';
const USER_WAS_INVITED = 'user_was_invited';
const OPTED_INTO_3_COLUMN = 'opted-into-new-record';
export default {
  PORTAL_AGE_DAYS,
  PORTAL_AGE_MONTHS,
  USER_AGE_DAYS,
  USER_AGE_MONTHS,
  USAGE_PORTAL_FIRST_WEEK,
  CHROME_EXTENSION_INSTALLED,
  USER_WAS_INVITED,
  OPTED_INTO_3_COLUMN
};