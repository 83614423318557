import { useCallback, useEffect, useMemo, useState } from 'react';
import userInfo from 'hub-http/userInfo';
const INITIAL_STATE = {
  data: undefined,
  error: undefined,
  loading: false
};
export default function useUserInfo(userInfoProps) {
  const [userInfoState, setUserInfoState] = useState(INITIAL_STATE);
  const hasScope = useCallback(scope => Boolean(userInfoState.data && userInfoState.data.user.scopes.includes(scope)), [userInfoState.data]);
  const isUngatedFor = useCallback(gate => Boolean(userInfoState.data && userInfoState.data.gates.includes(gate)), [userInfoState.data]);
  useEffect(() => {
    setUserInfoState(Object.assign({}, INITIAL_STATE, {
      loading: true
    }));
    userInfo(userInfoProps).then(data => setUserInfoState(Object.assign({}, INITIAL_STATE, {
      data
    }))).catch(error => setUserInfoState(Object.assign({}, INITIAL_STATE, {
      error
    })));
  }, [userInfoProps]);
  return useMemo(() => Object.assign({}, userInfoState, {
    hasScope,
    isUngatedFor
  }), [hasScope, isUngatedFor, userInfoState]);
}