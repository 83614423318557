'use es6';

import { createSelector } from 'reselect';
import { SLACK_HAS_SEEN_NEW, HAS_SEEN_APPROVALS_ONBOARDING, HAS_SEEN_RELIABLE_OPEN_ONBOARDING, HAS_SEEN_EMAIL_INSIGHTS } from 'email-management-lib/refactor/constants/userAttributes';
const _getUserAttributes = state => state.get('userAttributes');
export const getUserAttributes = createSelector(_getUserAttributes, userAttributes => userAttributes.getData());
export const getSlackHasSeenNewAttribute = createSelector(getUserAttributes, attributes => attributes.get(SLACK_HAS_SEEN_NEW) === 'true');
export const getUserAttributesHasLoaded = createSelector(_getUserAttributes, userAttributes => userAttributes.hasEverLoaded());
export const getHasSeenReliableOpenOnboarding = createSelector([getUserAttributes, getUserAttributesHasLoaded], (attributes, isLoaded) => {
  return isLoaded && attributes.get(HAS_SEEN_RELIABLE_OPEN_ONBOARDING) === 'true';
});
export const getHasSeenApprovalsOnboarding = createSelector([getUserAttributes, getUserAttributesHasLoaded], (attributes, isLoaded) => {
  return isLoaded && attributes.get(HAS_SEEN_APPROVALS_ONBOARDING) === 'true';
});
export const getHasSeenEmailInsights = createSelector([getUserAttributes, getUserAttributesHasLoaded], (attributes, isLoaded) => {
  return isLoaded && attributes.get(HAS_SEEN_EMAIL_INSIGHTS) === 'true';
});