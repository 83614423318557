import { ARCHIVE_EMAIL, ASSIGN_USERS_TEAMS_EMAILS, CLONE_EMAIL, DELETE_EMAIL, MOVE_TO_FOLDER, MULTI_ARCHIVE, MULTI_ARCHIVE_CANCEL, MULTI_DELETE, MULTI_MOVE_TO_FOLDER, MULTI_UNARCHIVE, RECEIVE_EMAILS, REQUEST_CANCEL_EMAIL, UNARCHIVE_EMAIL } from 'email-management-lib/refactor/actions/actionTypes';
const INITIAL_STATE = false;
export default function emailsMutated(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ARCHIVE_EMAIL:
    case ASSIGN_USERS_TEAMS_EMAILS:
    case CLONE_EMAIL:
    case DELETE_EMAIL:
    case UNARCHIVE_EMAIL:
    case MOVE_TO_FOLDER:
    case MULTI_ARCHIVE:
    case MULTI_ARCHIVE_CANCEL:
    case MULTI_DELETE:
    case MULTI_MOVE_TO_FOLDER:
    case MULTI_UNARCHIVE:
    case REQUEST_CANCEL_EMAIL:
      return true;
    case RECEIVE_EMAILS:
      return false;
    default:
      return state;
  }
}