import { getPropertyValue, setPropertyValue } from 'framework-listing-lib/utils/crmObject';
import { CRM_OBJECT_TYPE_FIELD_NAME } from 'framework-listing-lib/internal/hooks/useFetchFromCRM';
import { GET_FOLDERS, GET_FOLDERS_FIELD_NAME } from '../hooks/useFetchFolders';
import { FOLDER_PROPERTIES } from '../constants/folders';
import { getParentFolderId, getTotalAssets } from './folders';
import { writeUnfiledAssetsToCache, modifyUnfiledAssets, removeDeletedUnfiledAssets, getUnfiledAssetsInCache } from 'framework-listing-lib/internal/Folders/utils/unfiledAssets';
import { UNFILED_ASSETS_ID } from 'framework-listing-lib/internal/Folders/constants/folders';
import { CRM_SEARCH_GRAPHQL_CACHE_KEY } from 'framework-listing-lib/internal/graphql/constants';
function updateFolderCounts({
  dfcClient,
  maybeApolloClient,
  newFolderId,
  previousFolderId,
  totalAffectedAssets,
  unfiledAssetVariables
}) {
  let totalAssetsInPreviousFolder = 0;
  let totalAssetsInNewFolder = 0;
  dfcClient.cache.modify({
    fields: {
      [GET_FOLDERS_FIELD_NAME]: foldersCacheRef => {
        return foldersCacheRef.map(crmFolder => {
          if (crmFolder.objectId !== previousFolderId && crmFolder.objectId !== newFolderId) {
            return crmFolder;
          }
          const currentObjectCount = parseInt(getPropertyValue(crmFolder, FOLDER_PROPERTIES.objectCount, '0'), 10);
          if (crmFolder.objectId === previousFolderId) {
            totalAssetsInPreviousFolder = Math.max(0, currentObjectCount - totalAffectedAssets);
          }
          if (crmFolder.objectId === newFolderId) {
            totalAssetsInNewFolder = currentObjectCount + totalAffectedAssets;
          }
          const objectCountPropertyValue = `${crmFolder.objectId === previousFolderId ? totalAssetsInPreviousFolder : totalAssetsInNewFolder}`;
          return setPropertyValue(crmFolder, FOLDER_PROPERTIES.objectCount, objectCountPropertyValue);
        });
      }
    }
  });
  let unfiledAssets = getUnfiledAssetsInCache({
    dfcClient,
    maybeApolloClient,
    variables: unfiledAssetVariables
  });

  /**
   * Remove stale table and folders cache
   */
  if (maybeApolloClient) {
    maybeApolloClient.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: CRM_SEARCH_GRAPHQL_CACHE_KEY
    });
    maybeApolloClient.cache.gc();
  } else {
    dfcClient.cache.evict({
      fieldName: CRM_OBJECT_TYPE_FIELD_NAME
    });
    dfcClient.cache.gc();
  }

  // If asset is being moved to or from unfiled, modify the count and then write to cache
  if (unfiledAssets) {
    if (previousFolderId === UNFILED_ASSETS_ID || newFolderId === UNFILED_ASSETS_ID) {
      unfiledAssets = modifyUnfiledAssets(previousFolderId, newFolderId, totalAffectedAssets, unfiledAssets);
    }
    writeUnfiledAssetsToCache({
      dfcClient,
      maybeApolloClient,
      variables: unfiledAssetVariables,
      newUnfiledAssets: unfiledAssets
    });
  }
  return {
    totalAssetsInPreviousFolder,
    totalAssetsInNewFolder
  };
}
export function readFoldersCache(cache, variables) {
  const childrenCachedData = cache.readQuery({
    query: GET_FOLDERS,
    variables
  });
  return childrenCachedData;
}
export function updateFoldersCache(cache, variables, newCache) {
  cache.writeQuery({
    query: GET_FOLDERS,
    data: {
      [GET_FOLDERS_FIELD_NAME]: newCache
    },
    variables
  });
}

/**
 * Adds @createdFolder to existing cache.
 * @param cache
 * @param createdFolder
 * @param variables
 */
export function handleCreateFolder(cache, createdFolder, variables) {
  const cachedData = readFoldersCache(cache, variables);
  if (!cachedData) {
    return;
  }
  cache.evict({
    fieldName: GET_FOLDERS_FIELD_NAME
  });
  cache.gc();
  updateFoldersCache(cache, variables, [...cachedData.getFolders, createdFolder]);
}

/**
 * Replaces @updatedFolder in the cache.
 * @param cache
 * @param updatedFolder
 * @param variables
 */
export function handleUpdateFolder(cache, updatedFolder, variables) {
  const cachedData = readFoldersCache(cache, variables);
  if (!cachedData) {
    return;
  }
  cache.evict({
    fieldName: GET_FOLDERS_FIELD_NAME
  });
  cache.gc();
  updateFoldersCache(cache, variables, cachedData.getFolders.map(existingFolder => existingFolder.objectId === updatedFolder.objectId ? updatedFolder : existingFolder));
}

/**
 * 1 - Removes @deletedFolder from its parent's cache.
 * 2 - Moves all child folders from @deletedFolder to the root level.
 * @param cache
 * @param deletedFolder
 * @param variables
 */
export function handleDeleteFolder({
  dfcClient,
  deletedFolder,
  maybeApolloClient,
  variables,
  unfiledAssetVariables
}) {
  /**
   * Update counts for "Unfiled <asset type> cache"
   */
  updateFolderCounts({
    dfcClient,
    maybeApolloClient,
    previousFolderId: deletedFolder.objectId,
    newFolderId: -1,
    totalAffectedAssets: getTotalAssets(deletedFolder),
    unfiledAssetVariables
  });
  const parentFolderCacheVariables = Object.assign({}, variables, {
    parentFolderId: getParentFolderId(deletedFolder)
  });
  const parentFolderCachedData = readFoldersCache(dfcClient.cache, parentFolderCacheVariables);
  if (!parentFolderCachedData) {
    return;
  }

  /**
   * Remove @deletedFolder from its parent cache
   */
  const newParentCachedData = parentFolderCachedData.getFolders.filter(existingFolder => existingFolder.objectId !== deletedFolder.objectId);
  updateFoldersCache(dfcClient.cache, parentFolderCacheVariables, newParentCachedData);
}
export function handleUpdateFolderCountsAfterMove({
  dfcClient,
  maybeApolloClient,
  moveAssetResponse,
  unfiledAssetVariables
}) {
  const folderCounts = updateFolderCounts({
    dfcClient,
    maybeApolloClient,
    previousFolderId: moveAssetResponse.previousFolderId,
    newFolderId: moveAssetResponse.newFolderId,
    totalAffectedAssets: moveAssetResponse.selectedObjectsCount != null ? moveAssetResponse.selectedObjectsCount : moveAssetResponse.assetIds.length,
    unfiledAssetVariables
  });
  const unfiledAssets = getUnfiledAssetsInCache({
    dfcClient,
    maybeApolloClient,
    variables: unfiledAssetVariables
  });

  /**
   * Remove stale table and folders cache
   */
  if (maybeApolloClient) {
    maybeApolloClient.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: CRM_SEARCH_GRAPHQL_CACHE_KEY
    });
    maybeApolloClient.cache.gc();
  } else {
    dfcClient.cache.evict({
      fieldName: CRM_OBJECT_TYPE_FIELD_NAME
    });
    dfcClient.cache.gc();
  }
  if (unfiledAssets) {
    writeUnfiledAssetsToCache({
      dfcClient,
      maybeApolloClient,
      variables: unfiledAssetVariables,
      newUnfiledAssets: unfiledAssets
    });
  }
  return folderCounts;
}
export function handleUpdateFolderCountsAfterDelete({
  dfcClient,
  maybeApolloClient,
  deletedAssetsCount,
  folderId,
  unfiledAssetVariables
}) {
  let totalAssetsInFolder;
  dfcClient.cache.modify({
    fields: {
      [GET_FOLDERS_FIELD_NAME]: foldersCacheRef => {
        return foldersCacheRef.map(crmFolder => {
          if (crmFolder.objectId !== folderId) {
            return crmFolder;
          }
          const currentObjectCount = parseInt(getPropertyValue(crmFolder, FOLDER_PROPERTIES.objectCount, '0'), 10);
          totalAssetsInFolder = Math.max(0, currentObjectCount - deletedAssetsCount);
          return setPropertyValue(crmFolder, FOLDER_PROPERTIES.objectCount, `${totalAssetsInFolder}`);
        });
      }
    }
  });
  let unfiledAssets = getUnfiledAssetsInCache({
    dfcClient,
    maybeApolloClient,
    variables: unfiledAssetVariables
  });

  /**
   * Remove stale table and folders cache
   */
  if (maybeApolloClient) {
    maybeApolloClient.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: CRM_SEARCH_GRAPHQL_CACHE_KEY
    });
    maybeApolloClient.cache.gc();
  } else {
    dfcClient.cache.evict({
      fieldName: CRM_OBJECT_TYPE_FIELD_NAME
    });
    dfcClient.cache.gc();
  }
  if (unfiledAssets && folderId === UNFILED_ASSETS_ID) {
    unfiledAssets = removeDeletedUnfiledAssets(deletedAssetsCount, unfiledAssets);
    writeUnfiledAssetsToCache({
      dfcClient,
      maybeApolloClient,
      variables: unfiledAssetVariables,
      newUnfiledAssets: unfiledAssets
    });
    return unfiledAssets.total;
  }
  return totalAssetsInFolder;
}