import { enrollInUserGuide } from 'onboarding-utils/gettingStartedEnrollment';
import { DISMISS_ZERO_STATE } from 'EmailData/actions/actionTypes';
import Raven from 'raven-js';
import { writeToUserAttributesInManage } from 'EmailData/api/hubUserAttributes';
// Allows the SimpleUserSettingsModel send the request to update the setting
// through the value set in the redux store.
// We cannot chain off what's returned
// Use this if you're continuing to use the app after the setting is updated.
function autoDismissDashboardZeroStateViaUserSettingsSync() {
  return dispatch => {
    return dispatch({
      type: DISMISS_ZERO_STATE
    });
  };
}

// Manually update the setting by sending our own request, this does not
// persist to the redux store to avoid sending the request twice.
// We can chain off what's returned
// Use this if you're redirecting to another app after it's been updated.
function manuallyDismissDashboardZeroStateAndReturnPromise() {
  return () => writeToUserAttributesInManage({
    dashboardZeroStateComplete: true
  });
}
export let dismissDashboardZeroState = (suppressManualUpdate = false) => {
  return dispatch => {
    if (suppressManualUpdate) {
      return dispatch(autoDismissDashboardZeroStateViaUserSettingsSync());
    }
    return dispatch(manuallyDismissDashboardZeroStateAndReturnPromise());
  };
};

// https://product.hubteam.com/docs/frontend/kb/concatenated-spies.html#eliminate-dependence-on-mutable-imports
export const setDismissDashboardZeroStateForTesting = mock => {
  dismissDashboardZeroState = mock;
};
export function enrollPortalForGettingStarted() {
  return () => {
    enrollInUserGuide().catch(e => Raven.captureException(e));
  };
}