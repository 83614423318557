// @ts-expect-error not migrated to TS yet
import { hasListsEverLoaded } from 'EmailData/selectors/lists';

// @ts-expect-error not migrated to TS yet
import { getHasOnboarding } from 'EmailData/selectors/user';
import { getIsPortalSettingsLoaded, getPortalSettings
// @ts-expect-error not migrated to TS yet
} from 'EmailData/portalSettings/selectors';
import I18n from 'I18n';
import { DISMISS_KEY_TO_ONBOARDING_STEP, HUB_SETTING_ONBOARDING_CONTACTS_DISMISSED, HUB_SETTING_ONBOARDING_DISMISS_KEY, HUB_SETTING_ONBOARDING_FIRST_SEND_DISMISSED, HUB_SETTING_ONBOARDING_MAILCHIMP_DISMISSED, HUB_SETTING_ONBOARDING_OPTOUT_DISMISSED, HUB_SETTING_UPDATED_ONBOARDING_TYPE, MAILCHIMP_IMPORT_STATUS, MAX_EMAILS_BEFORE_AUTO_DISMISS, ONBOARDING_STEPS, ONBOARDING_TYPES } from 'email-management-lib/refactor/constants/manage/onboarding';
import { createSelector } from 'reselect';
const DISMISS_ACTION_KEYS = [HUB_SETTING_ONBOARDING_MAILCHIMP_DISMISSED, HUB_SETTING_ONBOARDING_FIRST_SEND_DISMISSED, HUB_SETTING_ONBOARDING_CONTACTS_DISMISSED, HUB_SETTING_ONBOARDING_OPTOUT_DISMISSED];
export const getHistoricalImports = state => state.getIn(['entities', 'historicalImports']);
export const getHistoricalImportsData = createSelector([getHistoricalImports], historicalImports => {
  const historicalImportsData = historicalImports.getData();
  return historicalImportsData || [];
});
export const getOnboardingSendStats = state => state.getIn(['entities', 'onboardingSendStats']);
export const getOnboardingSendStatsData = createSelector([getOnboardingSendStats], onboardingSendStats => {
  const onboardingSendStatsData = onboardingSendStats.getData();
  return onboardingSendStatsData || 0;
});
export const getDismissedActions = createSelector([getPortalSettings], portalSettings => {
  return portalSettings.get('settings').filter(setting => DISMISS_ACTION_KEYS.indexOf(setting.key) > -1 && setting.value).map(setting => DISMISS_KEY_TO_ONBOARDING_STEP[setting.key]);
});
export const getOnboardingType = createSelector([getPortalSettings], portalSettings => {
  const onboardingType = portalSettings.get('settings').find(setting => setting.key === HUB_SETTING_UPDATED_ONBOARDING_TYPE);
  return onboardingType && onboardingType.value ? onboardingType.value : null;
});
export const getMailchimpImportStatus = state => state.getIn(['entities', 'mailchimpImport']);
export const isOnboardingLoaded = createSelector([getIsPortalSettingsLoaded, hasListsEverLoaded, getHistoricalImports, getOnboardingSendStats, getMailchimpImportStatus], (isPortalSettingsLoaded, listsLoaded, historicalImports, onboardingSendStats, mailchimpImportStatus) => {
  return Boolean(isPortalSettingsLoaded && listsLoaded && historicalImports.hasEverLoaded() && onboardingSendStats.hasEverLoaded() && mailchimpImportStatus);
});
export const currentCompletedStepsSelector = createSelector([getOnboardingSendStatsData, getHistoricalImportsData, getDismissedActions, getMailchimpImportStatus, getOnboardingType], (totalSentEmails, historicalImports, dismissedActions, mailchimpImportStatus, onboardingType) => {
  const completedSteps = [];
  if (onboardingType === ONBOARDING_TYPES.MAILCHIMP && (mailchimpImportStatus === MAILCHIMP_IMPORT_STATUS.COMPLETED || mailchimpImportStatus === MAILCHIMP_IMPORT_STATUS.IN_PROGRESS) || dismissedActions.indexOf(ONBOARDING_STEPS.MAILCHIMP_IMPORT) > -1) {
    completedSteps.push(ONBOARDING_STEPS.MAILCHIMP_IMPORT);
  }
  if (totalSentEmails > 0 || dismissedActions.indexOf(ONBOARDING_STEPS.SEND_FIRST_EMAIL) > -1) {
    completedSteps.push(ONBOARDING_STEPS.SEND_FIRST_EMAIL);
  }
  const hasContactImports = historicalImports.filter(historicalImport => !historicalImport.optOutImport && (historicalImport.objectLists && historicalImport.objectLists.some(object => object.objectType === 'CONTACT') || historicalImport.importRequestJson.createContactListFromImport)).size > 0;
  if (hasContactImports || dismissedActions.indexOf(ONBOARDING_STEPS.IMPORT_CONTACTS_AND_CREATE_LISTS) > -1) {
    completedSteps.push(ONBOARDING_STEPS.IMPORT_CONTACTS_AND_CREATE_LISTS);
  }
  const hasOptOutImport = historicalImports.find(historicalImport => historicalImport.optOutImport) !== undefined;
  if (hasOptOutImport || dismissedActions.indexOf(ONBOARDING_STEPS.IMPORT_OPT_OUT_LIST) > -1) {
    completedSteps.push(ONBOARDING_STEPS.IMPORT_OPT_OUT_LIST);
  }
  return completedSteps;
});
export const hasCompletedAllStepsSelector = createSelector([currentCompletedStepsSelector, getOnboardingType, isOnboardingLoaded], (completedSteps, onboardingType, onboardingLoaded) => {
  const stepsToComplete = [ONBOARDING_STEPS.SEND_FIRST_EMAIL, ONBOARDING_STEPS.IMPORT_CONTACTS_AND_CREATE_LISTS, ONBOARDING_STEPS.IMPORT_OPT_OUT_LIST];
  if (onboardingType === ONBOARDING_TYPES.MAILCHIMP) {
    stepsToComplete.push(ONBOARDING_STEPS.MAILCHIMP_IMPORT);
  }
  return Boolean(stepsToComplete.every(step => completedSteps.indexOf(step) > -1) && onboardingLoaded);
});
export const shouldAutoDismissOnboardingSelector = createSelector([getOnboardingSendStatsData], totalSentEmails => {
  return totalSentEmails >= MAX_EMAILS_BEFORE_AUTO_DISMISS;
});
export const getHasDismissedOnboarding = createSelector([getPortalSettings], portalSettings => {
  return !!portalSettings.get('settings').find(setting => setting.key === HUB_SETTING_ONBOARDING_DISMISS_KEY && setting.value) || false;
});
const getHasPermanentlyDismissedOnboardingTour = state => state.getIn(['manage', 'dismissedOnboardingTourEntry']);
const getOnboardingTourPromptDismissTime = state => state.getIn(['manage', 'dismissedOnboardingTourTime']);
const getHasDismissedTourToday = state => {
  const previousDismissTimestamp = getOnboardingTourPromptDismissTime(state);
  if (!previousDismissTimestamp) {
    return false;
  }
  const previousDismissMoment = I18n.moment(previousDismissTimestamp);
  const todayMoment = I18n.moment();
  return previousDismissMoment.isSame(todayMoment, 'd');
};
export const getCanShowOnboardingTourPrompt = createSelector([getHasOnboarding, getHasPermanentlyDismissedOnboardingTour], (hasOnboarding, hasPermanentlyDismissedOnboardingTour) => {
  return hasOnboarding && !hasPermanentlyDismissedOnboardingTour;
});
export const getShowOnboardingTourPrompt = state => {
  const canShowOnboardingTourPrompt = getCanShowOnboardingTourPrompt(state);
  const hasDismissedTourToday = getHasDismissedTourToday(state);
  const onboardingSendStats = getOnboardingSendStats(state);
  const hasNotSentEmails = onboardingSendStats.hasEverLoaded() && onboardingSendStats.getData() === 0;
  return canShowOnboardingTourPrompt && !hasDismissedTourToday && hasNotSentEmails;
};
export const getShouldSeeEmailFrameworkOnboarding = state => state.get('emailFrameworkOnboarding');
export default currentCompletedStepsSelector;