import env from 'enviro';
export default function AsyncComponentErrorHandler(chunkName) {
  return function handler(error) {
    const errorMessage = `Failed to load chunk ${chunkName}`;
    if (!env.deployed()) {
      console.warn(errorMessage);
      console.error(error);
      return;
    }
  };
}