import I18n from 'I18n';
function getI18nParams({
  message,
  errorTokens
}, backupMessage) {
  return Object.assign({}, errorTokens, {
    defaultValue: message || backupMessage
  });
}
export default function errorTokenMiddleware() {
  return next => action => {
    if (action.error === true && action.payload instanceof Error) {
      const {
        message: originalMessage,
        responseJSON
      } = action.payload;
      if (typeof responseJSON === 'object') {
        const {
          errorType
        } = responseJSON;
        if (errorType && errorType.length) {
          const i18nParams = getI18nParams(responseJSON, originalMessage);
          const translatedMessage = I18n.text(`errors.${errorType}`, i18nParams);
          action.payload.message = translatedMessage;
          action.payload.originalMessage = originalMessage;
        }
      }
    }
    return next(action);
  };
}