import { DrawerTypes, FileTypes } from '../constants';
import { splitNameAndExtension } from './file';
const DOCUMENT_EXTENSIONS = ['csv', 'doc', 'docx', 'dot', 'dotx', 'key', 'pdf', 'pot', 'potx', 'pps', 'ppsx', 'ppt', 'pptx', 'txt', 'wpd', 'wps', 'wri', 'xls', 'xlsb', 'xlsx', 'xlt', 'xlx', 'xml'];
export function getFileTypeFromFile(file) {
  const {
    extension
  } = splitNameAndExtension(file.name);
  const mimeTypeCategory = file.type.split('/')[0].toLowerCase();
  if (extension && DOCUMENT_EXTENSIONS.includes(extension.toLowerCase())) {
    return FileTypes.DOCUMENT;
  }
  switch (mimeTypeCategory) {
    case 'image':
      return FileTypes.IMG;
    case 'video':
      return FileTypes.MOVIE;
    case 'audio':
      return FileTypes.AUDIO;
    default:
      return FileTypes.OTHER;
  }
}
export const getDrawerType = file => {
  const fileType = getFileTypeFromFile(file);
  switch (fileType) {
    case FileTypes.IMG:
      return DrawerTypes.IMAGE;
    case FileTypes.AUDIO:
      return DrawerTypes.AUDIO;
    case FileTypes.MOVIE:
      return DrawerTypes.VIDEO;
    case FileTypes.DOCUMENT:
      return DrawerTypes.DOCUMENT;
    default:
      return DrawerTypes.FILE;
  }
};
export function getAcceptedFileInputs(type) {
  switch (type) {
    case DrawerTypes.IMAGE:
      return ['image/*'];
    case DrawerTypes.AUDIO:
      return ['audio/*'];
    case DrawerTypes.VIDEO:
      return ['video/*'];
    case DrawerTypes.DOCUMENT:
      return DOCUMENT_EXTENSIONS.map(item => `.${item}`);
    default:
      return [];
  }
}