/**
 * Returns true if popovers inputs are enabled (in other words, are inputs
 * allowed to expand and show more complex editing experiences).
 *
 * Host apps can pass out of this by passing `enablePopovers={false}`.
 *
 * Historically, omitting `objectId` would opt out of popover inputs,
 * resulting in a "simple" form UI. By replacing this implicit UI styling
 * with an explicit flag, we can allow apps who want this style to opt into
 * it while still providing an `objectId` (necessary for on-demand value
 * fetching). We can also start to evaluate what UI should and should not
 * be considered part of the "simple" form style.
 */
export const shouldShowPopovers = ({
  enablePopovers,
  objectId
}) => !!(enablePopovers && objectId);