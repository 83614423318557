'use es6';

import { Record } from 'immutable';
const defaults = {
  error: '',
  lastProcessingStateChangedAt: null,
  lastSizeChangeAt: null,
  processing: '',
  listSize: null
};
class ListMetaData extends Record(defaults) {
  static from(json) {
    if (typeof json.size !== 'undefined') {
      json.listSize = json.size;
    }
    return new ListMetaData(json);
  }
}
ListMetaData.PROCESSING_STATE = {
  UPLOADED: 'UPLOADED',
  PROCESSING: 'PROCESSING',
  PROCESSED: 'PROCESSED',
  ERROR_WILL_RETRY: 'ERROR_WILL_RETRY',
  ERRORY_WILL_NOT_RETRY: 'ERRORY_WILL_NOT_RETRY',
  RATE_LIMITED: 'RATE_LIMITED',
  DONE: 'DONE'
};
export default ListMetaData;